import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ConnectionService } from './connection.service';
import { ToastService } from '../../shared/modules/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthenticatedHttpService implements HttpInterceptor {
  constructor(
    private conectionService: ConnectionService,
    private router: Router,
    private toastService: ToastService,
    private translate: TranslateService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              const urlTest = new RegExp(/login|signup|reset|snapguest/i).test(err.url);
              const onPublic = !urlTest && window.location.href.indexOf('/public') !== -1;

              if (!urlTest && !onPublic) {
                // Remove true if testing
                if (true || environment.production) {
                  window.location.href = window.location.origin + '/logout';
                } else {
                  this.toastService.warning('Unauthorized request');
                }
              }
            }

            // Only check for 500 errors from SI and Apollo APIs
            if (
              environment.apiUrl ===
                request.url.substring(0, environment.apiUrl.length) ||
              environment.selfUrl ===
                request.url.substring(0, environment.selfUrl.length)
            ) {
              if (err.status === 503) {
                // If we are already on the 503 page do not redirect again
                if (this.router.url.substring(0, 4) !== '/503') {
                  this.router.navigate(['503'], {
                    queryParams: {
                      returnUrl: this.router.routerState.snapshot.url,
                      failedUrl: request.url,
                    },
                  });
                }
              } else if (err.status >= 500 && err.status !== 504) {
                const title = this.translate.instant('error.500.toastr.title');
                const message = this.translate.instant(
                  'error.500.toastr.message',
                );
                this.toastService.error(message, title);
              } else if (err.status === 504 || err.status === 0) {
                this.conectionService.showDisconnected();
              }

              return throwError(err);
            }
          }
        },
      ),
    );
  }
}
