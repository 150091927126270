/* tslint:disable */

declare var Object: any;
export interface PlanInterface {
  "active": boolean;
  "default": boolean;
  "name": string;
  "description"?: string;
  "identifier": string;
  "tier": number;
  "documentLimit": number;
  "priceDocument"?: number;
  "priceDocument100k"?: number;
  "priceDocument1m"?: number;
  "organizationLimit": number;
  "priceOrganization"?: number;
  "fullUI"?: boolean;
  "priceFullUI"?: number;
  "whiteLabelUI"?: boolean;
  "priceWhiteLabelUI"?: number;
  "fee"?: number;
  "id"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class Plan implements PlanInterface {
  "active": boolean;
  "default": boolean;
  "name": string;
  "description": string;
  "identifier": string;
  "tier": number;
  "documentLimit": number;
  "priceDocument": number;
  "priceDocument100k": number;
  "priceDocument1m": number;
  "organizationLimit": number;
  "priceOrganization": number;
  "fullUI": boolean;
  "priceFullUI": number;
  "whiteLabelUI": boolean;
  "priceWhiteLabelUI": number;
  "fee": number;
  "id": any;
  "createdAt": Date;
  "updatedAt": Date;
  constructor(data?: PlanInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Plan`.
   */
  public static getModelName() {
    return "Plan";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Plan for dynamic purposes.
  **/
  public static factory(data: PlanInterface): Plan{
    return new Plan(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Plan',
      plural: 'Plans',
      path: 'Plans',
      idName: 'id',
      properties: {
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "default": {
          name: 'default',
          type: 'boolean',
          default: false
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "identifier": {
          name: 'identifier',
          type: 'string'
        },
        "tier": {
          name: 'tier',
          type: 'number'
        },
        "documentLimit": {
          name: 'documentLimit',
          type: 'number',
          default: 50
        },
        "priceDocument": {
          name: 'priceDocument',
          type: 'number'
        },
        "priceDocument100k": {
          name: 'priceDocument100k',
          type: 'number'
        },
        "priceDocument1m": {
          name: 'priceDocument1m',
          type: 'number'
        },
        "organizationLimit": {
          name: 'organizationLimit',
          type: 'number',
          default: 1
        },
        "priceOrganization": {
          name: 'priceOrganization',
          type: 'number'
        },
        "fullUI": {
          name: 'fullUI',
          type: 'boolean'
        },
        "priceFullUI": {
          name: 'priceFullUI',
          type: 'number'
        },
        "whiteLabelUI": {
          name: 'whiteLabelUI',
          type: 'boolean'
        },
        "priceWhiteLabelUI": {
          name: 'priceWhiteLabelUI',
          type: 'number'
        },
        "fee": {
          name: 'fee',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
      },
      relations: {
      }
    }
  }
}
