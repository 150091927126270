/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Payment } from '../models/payment';

@Injectable({
  providedIn: 'root',
})
export class PaymentService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation paymentFindById
   */
  static readonly PaymentFindByIdPath = '/v1/Payments/{id}';

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentFindById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentFindById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentFindById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentFindById$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Payment> {

    return this.paymentFindById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentFindById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentFindById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentFindById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentFindById$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Payment> {

    return this.paymentFindById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentFindById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentFindById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentFindById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentFindById$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Payment> {

    return this.paymentFindById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Path part for operation paymentReplaceByIdPutPaymentsId
   */
  static readonly PaymentReplaceByIdPutPaymentsIdPath = '/v1/Payments/{id}';

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentReplaceByIdPutPaymentsId$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentReplaceByIdPutPaymentsId$Json$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentReplaceByIdPutPaymentsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentReplaceByIdPutPaymentsId$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentReplaceByIdPutPaymentsId$Json$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.paymentReplaceByIdPutPaymentsId$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentReplaceByIdPutPaymentsId$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentReplaceByIdPutPaymentsId$Json$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentReplaceByIdPutPaymentsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentReplaceByIdPutPaymentsId$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentReplaceByIdPutPaymentsId$Json$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.paymentReplaceByIdPutPaymentsId$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentReplaceByIdPutPaymentsId$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentReplaceByIdPutPaymentsId$Json$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentReplaceByIdPutPaymentsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentReplaceByIdPutPaymentsId$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentReplaceByIdPutPaymentsId$Json$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.paymentReplaceByIdPutPaymentsId$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentReplaceByIdPutPaymentsId$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  paymentReplaceByIdPutPaymentsId$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentReplaceByIdPutPaymentsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentReplaceByIdPutPaymentsId$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  paymentReplaceByIdPutPaymentsId$XWwwFormUrlencoded$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.paymentReplaceByIdPutPaymentsId$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentReplaceByIdPutPaymentsId$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  paymentReplaceByIdPutPaymentsId$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentReplaceByIdPutPaymentsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentReplaceByIdPutPaymentsId$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  paymentReplaceByIdPutPaymentsId$XWwwFormUrlencoded$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.paymentReplaceByIdPutPaymentsId$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentReplaceByIdPutPaymentsId$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  paymentReplaceByIdPutPaymentsId$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentReplaceByIdPutPaymentsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentReplaceByIdPutPaymentsId$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  paymentReplaceByIdPutPaymentsId$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.paymentReplaceByIdPutPaymentsId$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentReplaceByIdPutPaymentsId$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  paymentReplaceByIdPutPaymentsId$Xml$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentReplaceByIdPutPaymentsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentReplaceByIdPutPaymentsId$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  paymentReplaceByIdPutPaymentsId$Xml$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.paymentReplaceByIdPutPaymentsId$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentReplaceByIdPutPaymentsId$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  paymentReplaceByIdPutPaymentsId$Xml$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentReplaceByIdPutPaymentsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentReplaceByIdPutPaymentsId$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  paymentReplaceByIdPutPaymentsId$Xml$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.paymentReplaceByIdPutPaymentsId$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentReplaceByIdPutPaymentsId$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  paymentReplaceByIdPutPaymentsId$Xml$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentReplaceByIdPutPaymentsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentReplaceByIdPutPaymentsId$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  paymentReplaceByIdPutPaymentsId$Xml$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.paymentReplaceByIdPutPaymentsId$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Path part for operation paymentDeleteById
   */
  static readonly PaymentDeleteByIdPath = '/v1/Payments/{id}';

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentDeleteById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentDeleteById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentDeleteById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentDeleteById$Json(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.paymentDeleteById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentDeleteById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentDeleteById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentDeleteById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentDeleteById$Xml(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.paymentDeleteById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentDeleteById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentDeleteById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentDeleteById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentDeleteById$Javascript(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.paymentDeleteById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation paymentReplaceByIdPostPaymentsIdReplace
   */
  static readonly PaymentReplaceByIdPostPaymentsIdReplacePath = '/v1/Payments/{id}/replace';

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentReplaceByIdPostPaymentsIdReplace$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentReplaceByIdPostPaymentsIdReplace$Json$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentReplaceByIdPostPaymentsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentReplaceByIdPostPaymentsIdReplace$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentReplaceByIdPostPaymentsIdReplace$Json$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.paymentReplaceByIdPostPaymentsIdReplace$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentReplaceByIdPostPaymentsIdReplace$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentReplaceByIdPostPaymentsIdReplace$Json$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentReplaceByIdPostPaymentsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentReplaceByIdPostPaymentsIdReplace$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentReplaceByIdPostPaymentsIdReplace$Json$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.paymentReplaceByIdPostPaymentsIdReplace$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentReplaceByIdPostPaymentsIdReplace$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentReplaceByIdPostPaymentsIdReplace$Json$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentReplaceByIdPostPaymentsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentReplaceByIdPostPaymentsIdReplace$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentReplaceByIdPostPaymentsIdReplace$Json$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.paymentReplaceByIdPostPaymentsIdReplace$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentReplaceByIdPostPaymentsIdReplace$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  paymentReplaceByIdPostPaymentsIdReplace$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentReplaceByIdPostPaymentsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentReplaceByIdPostPaymentsIdReplace$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  paymentReplaceByIdPostPaymentsIdReplace$XWwwFormUrlencoded$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.paymentReplaceByIdPostPaymentsIdReplace$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentReplaceByIdPostPaymentsIdReplace$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  paymentReplaceByIdPostPaymentsIdReplace$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentReplaceByIdPostPaymentsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentReplaceByIdPostPaymentsIdReplace$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  paymentReplaceByIdPostPaymentsIdReplace$XWwwFormUrlencoded$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.paymentReplaceByIdPostPaymentsIdReplace$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentReplaceByIdPostPaymentsIdReplace$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  paymentReplaceByIdPostPaymentsIdReplace$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentReplaceByIdPostPaymentsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentReplaceByIdPostPaymentsIdReplace$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  paymentReplaceByIdPostPaymentsIdReplace$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.paymentReplaceByIdPostPaymentsIdReplace$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentReplaceByIdPostPaymentsIdReplace$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  paymentReplaceByIdPostPaymentsIdReplace$Xml$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentReplaceByIdPostPaymentsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentReplaceByIdPostPaymentsIdReplace$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  paymentReplaceByIdPostPaymentsIdReplace$Xml$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.paymentReplaceByIdPostPaymentsIdReplace$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentReplaceByIdPostPaymentsIdReplace$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  paymentReplaceByIdPostPaymentsIdReplace$Xml$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentReplaceByIdPostPaymentsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentReplaceByIdPostPaymentsIdReplace$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  paymentReplaceByIdPostPaymentsIdReplace$Xml$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.paymentReplaceByIdPostPaymentsIdReplace$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentReplaceByIdPostPaymentsIdReplace$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  paymentReplaceByIdPostPaymentsIdReplace$Xml$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentReplaceByIdPostPaymentsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentReplaceByIdPostPaymentsIdReplace$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  paymentReplaceByIdPostPaymentsIdReplace$Xml$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Payment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.paymentReplaceByIdPostPaymentsIdReplace$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

}
