/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccessToken } from '../models/access-token';
import { Account } from '../models/account';
import { Log } from '../models/log';
import { Organization } from '../models/organization';
import { Usage } from '../models/usage';
import { NewAccount } from '../models/new-account';
import { NewOrganization } from '../models/new-organization';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation accountPrototypeDestroyByIdAccessTokens
   */
  static readonly AccountPrototypeDestroyByIdAccessTokensPath = '/v1/Accounts/{id}/accessTokens/{fk}';

  /**
   * Delete a related item by id for accessTokens.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeDestroyByIdAccessTokens()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeDestroyByIdAccessTokens$Response(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * Foreign key for accessTokens
     */
    fk: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeDestroyByIdAccessTokensPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete a related item by id for accessTokens.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeDestroyByIdAccessTokens$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeDestroyByIdAccessTokens(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * Foreign key for accessTokens
     */
    fk: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.accountPrototypeDestroyByIdAccessTokens$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation accountPrototypeGetAccessTokens
   */
  static readonly AccountPrototypeGetAccessTokensPath = '/v1/Accounts/{id}/accessTokens';

  /**
   * Queries accessTokens of Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeGetAccessTokens$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetAccessTokens$Json$Response(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AccessToken>>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeGetAccessTokensPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccessToken>>;
      })
    );
  }

  /**
   * Queries accessTokens of Account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeGetAccessTokens$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetAccessTokens$Json(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<AccessToken>> {

    return this.accountPrototypeGetAccessTokens$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AccessToken>>) => r.body as Array<AccessToken>)
    );
  }

  /**
   * Queries accessTokens of Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeGetAccessTokens$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetAccessTokens$Xml$Response(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AccessToken>>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeGetAccessTokensPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccessToken>>;
      })
    );
  }

  /**
   * Queries accessTokens of Account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeGetAccessTokens$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetAccessTokens$Xml(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<AccessToken>> {

    return this.accountPrototypeGetAccessTokens$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AccessToken>>) => r.body as Array<AccessToken>)
    );
  }

  /**
   * Queries accessTokens of Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeGetAccessTokens$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetAccessTokens$Javascript$Response(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AccessToken>>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeGetAccessTokensPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccessToken>>;
      })
    );
  }

  /**
   * Queries accessTokens of Account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeGetAccessTokens$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetAccessTokens$Javascript(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<AccessToken>> {

    return this.accountPrototypeGetAccessTokens$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AccessToken>>) => r.body as Array<AccessToken>)
    );
  }

  /**
   * Path part for operation accountPrototypeCreateAccessTokens
   */
  static readonly AccountPrototypeCreateAccessTokensPath = '/v1/Accounts/{id}/accessTokens';

  /**
   * Creates a new instance in accessTokens of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCreateAccessTokens$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountPrototypeCreateAccessTokens$Json$Json$Response(params: {

    /**
     * Account id
     */
    id: string;
    body?: AccessToken
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AccessToken>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCreateAccessTokensPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessToken>;
      })
    );
  }

  /**
   * Creates a new instance in accessTokens of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCreateAccessTokens$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountPrototypeCreateAccessTokens$Json$Json(params: {

    /**
     * Account id
     */
    id: string;
    body?: AccessToken
  },
  context?: HttpContext

): Observable<AccessToken> {

    return this.accountPrototypeCreateAccessTokens$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<AccessToken>) => r.body as AccessToken)
    );
  }

  /**
   * Creates a new instance in accessTokens of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCreateAccessTokens$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountPrototypeCreateAccessTokens$Json$Xml$Response(params: {

    /**
     * Account id
     */
    id: string;
    body?: AccessToken
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AccessToken>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCreateAccessTokensPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessToken>;
      })
    );
  }

  /**
   * Creates a new instance in accessTokens of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCreateAccessTokens$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountPrototypeCreateAccessTokens$Json$Xml(params: {

    /**
     * Account id
     */
    id: string;
    body?: AccessToken
  },
  context?: HttpContext

): Observable<AccessToken> {

    return this.accountPrototypeCreateAccessTokens$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<AccessToken>) => r.body as AccessToken)
    );
  }

  /**
   * Creates a new instance in accessTokens of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCreateAccessTokens$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountPrototypeCreateAccessTokens$Json$Javascript$Response(params: {

    /**
     * Account id
     */
    id: string;
    body?: AccessToken
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AccessToken>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCreateAccessTokensPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessToken>;
      })
    );
  }

  /**
   * Creates a new instance in accessTokens of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCreateAccessTokens$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountPrototypeCreateAccessTokens$Json$Javascript(params: {

    /**
     * Account id
     */
    id: string;
    body?: AccessToken
  },
  context?: HttpContext

): Observable<AccessToken> {

    return this.accountPrototypeCreateAccessTokens$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<AccessToken>) => r.body as AccessToken)
    );
  }

  /**
   * Creates a new instance in accessTokens of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCreateAccessTokens$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountPrototypeCreateAccessTokens$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Account id
     */
    id: string;
    body?: AccessToken
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AccessToken>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCreateAccessTokensPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessToken>;
      })
    );
  }

  /**
   * Creates a new instance in accessTokens of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCreateAccessTokens$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountPrototypeCreateAccessTokens$XWwwFormUrlencoded$Json(params: {

    /**
     * Account id
     */
    id: string;
    body?: AccessToken
  },
  context?: HttpContext

): Observable<AccessToken> {

    return this.accountPrototypeCreateAccessTokens$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<AccessToken>) => r.body as AccessToken)
    );
  }

  /**
   * Creates a new instance in accessTokens of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCreateAccessTokens$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountPrototypeCreateAccessTokens$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Account id
     */
    id: string;
    body?: AccessToken
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AccessToken>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCreateAccessTokensPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessToken>;
      })
    );
  }

  /**
   * Creates a new instance in accessTokens of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCreateAccessTokens$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountPrototypeCreateAccessTokens$XWwwFormUrlencoded$Xml(params: {

    /**
     * Account id
     */
    id: string;
    body?: AccessToken
  },
  context?: HttpContext

): Observable<AccessToken> {

    return this.accountPrototypeCreateAccessTokens$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<AccessToken>) => r.body as AccessToken)
    );
  }

  /**
   * Creates a new instance in accessTokens of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCreateAccessTokens$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountPrototypeCreateAccessTokens$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Account id
     */
    id: string;
    body?: AccessToken
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AccessToken>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCreateAccessTokensPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessToken>;
      })
    );
  }

  /**
   * Creates a new instance in accessTokens of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCreateAccessTokens$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountPrototypeCreateAccessTokens$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Account id
     */
    id: string;
    body?: AccessToken
  },
  context?: HttpContext

): Observable<AccessToken> {

    return this.accountPrototypeCreateAccessTokens$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<AccessToken>) => r.body as AccessToken)
    );
  }

  /**
   * Creates a new instance in accessTokens of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCreateAccessTokens$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountPrototypeCreateAccessTokens$Xml$Json$Response(params: {

    /**
     * Account id
     */
    id: string;
    body?: AccessToken
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AccessToken>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCreateAccessTokensPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessToken>;
      })
    );
  }

  /**
   * Creates a new instance in accessTokens of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCreateAccessTokens$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountPrototypeCreateAccessTokens$Xml$Json(params: {

    /**
     * Account id
     */
    id: string;
    body?: AccessToken
  },
  context?: HttpContext

): Observable<AccessToken> {

    return this.accountPrototypeCreateAccessTokens$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<AccessToken>) => r.body as AccessToken)
    );
  }

  /**
   * Creates a new instance in accessTokens of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCreateAccessTokens$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountPrototypeCreateAccessTokens$Xml$Xml$Response(params: {

    /**
     * Account id
     */
    id: string;
    body?: AccessToken
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AccessToken>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCreateAccessTokensPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessToken>;
      })
    );
  }

  /**
   * Creates a new instance in accessTokens of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCreateAccessTokens$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountPrototypeCreateAccessTokens$Xml$Xml(params: {

    /**
     * Account id
     */
    id: string;
    body?: AccessToken
  },
  context?: HttpContext

): Observable<AccessToken> {

    return this.accountPrototypeCreateAccessTokens$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<AccessToken>) => r.body as AccessToken)
    );
  }

  /**
   * Creates a new instance in accessTokens of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCreateAccessTokens$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountPrototypeCreateAccessTokens$Xml$Javascript$Response(params: {

    /**
     * Account id
     */
    id: string;
    body?: AccessToken
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AccessToken>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCreateAccessTokensPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessToken>;
      })
    );
  }

  /**
   * Creates a new instance in accessTokens of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCreateAccessTokens$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountPrototypeCreateAccessTokens$Xml$Javascript(params: {

    /**
     * Account id
     */
    id: string;
    body?: AccessToken
  },
  context?: HttpContext

): Observable<AccessToken> {

    return this.accountPrototypeCreateAccessTokens$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<AccessToken>) => r.body as AccessToken)
    );
  }

  /**
   * Path part for operation accountPrototypeGetUsages
   */
  static readonly AccountPrototypeGetUsagesPath = '/v1/Accounts/{id}/usages';

  /**
   * Queries usages of Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeGetUsages$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetUsages$Json$Response(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Usage>>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeGetUsagesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Usage>>;
      })
    );
  }

  /**
   * Queries usages of Account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeGetUsages$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetUsages$Json(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Usage>> {

    return this.accountPrototypeGetUsages$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Usage>>) => r.body as Array<Usage>)
    );
  }

  /**
   * Queries usages of Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeGetUsages$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetUsages$Xml$Response(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Usage>>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeGetUsagesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Usage>>;
      })
    );
  }

  /**
   * Queries usages of Account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeGetUsages$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetUsages$Xml(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Usage>> {

    return this.accountPrototypeGetUsages$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Usage>>) => r.body as Array<Usage>)
    );
  }

  /**
   * Queries usages of Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeGetUsages$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetUsages$Javascript$Response(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Usage>>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeGetUsagesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Usage>>;
      })
    );
  }

  /**
   * Queries usages of Account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeGetUsages$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetUsages$Javascript(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Usage>> {

    return this.accountPrototypeGetUsages$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Usage>>) => r.body as Array<Usage>)
    );
  }

  /**
   * Path part for operation accountPrototypeCountUsages
   */
  static readonly AccountPrototypeCountUsagesPath = '/v1/Accounts/{id}/usages/count';

  /**
   * Counts usages of Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCountUsages$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeCountUsages$Json$Response(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCountUsagesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts usages of Account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCountUsages$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeCountUsages$Json(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.accountPrototypeCountUsages$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts usages of Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCountUsages$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeCountUsages$Xml$Response(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCountUsagesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts usages of Account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCountUsages$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeCountUsages$Xml(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.accountPrototypeCountUsages$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts usages of Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCountUsages$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeCountUsages$Javascript$Response(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCountUsagesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts usages of Account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCountUsages$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeCountUsages$Javascript(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.accountPrototypeCountUsages$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation accountPrototypeGetOrganizations
   */
  static readonly AccountPrototypeGetOrganizationsPath = '/v1/Accounts/{id}/organizations';

  /**
   * Queries organizations of Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeGetOrganizations$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetOrganizations$Json$Response(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Organization>>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeGetOrganizationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Organization>>;
      })
    );
  }

  /**
   * Queries organizations of Account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeGetOrganizations$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetOrganizations$Json(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Organization>> {

    return this.accountPrototypeGetOrganizations$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Organization>>) => r.body as Array<Organization>)
    );
  }

  /**
   * Queries organizations of Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeGetOrganizations$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetOrganizations$Xml$Response(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Organization>>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeGetOrganizationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Organization>>;
      })
    );
  }

  /**
   * Queries organizations of Account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeGetOrganizations$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetOrganizations$Xml(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Organization>> {

    return this.accountPrototypeGetOrganizations$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Organization>>) => r.body as Array<Organization>)
    );
  }

  /**
   * Queries organizations of Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeGetOrganizations$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetOrganizations$Javascript$Response(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Organization>>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeGetOrganizationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Organization>>;
      })
    );
  }

  /**
   * Queries organizations of Account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeGetOrganizations$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetOrganizations$Javascript(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Organization>> {

    return this.accountPrototypeGetOrganizations$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Organization>>) => r.body as Array<Organization>)
    );
  }

  /**
   * Path part for operation accountPrototypeCreateOrganizations
   */
  static readonly AccountPrototypeCreateOrganizationsPath = '/v1/Accounts/{id}/organizations';

  /**
   * Creates a new instance in organizations of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCreateOrganizations$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountPrototypeCreateOrganizations$Json$Json$Response(params: {

    /**
     * Account id
     */
    id: string;
    body?: NewOrganization
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCreateOrganizationsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Creates a new instance in organizations of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCreateOrganizations$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountPrototypeCreateOrganizations$Json$Json(params: {

    /**
     * Account id
     */
    id: string;
    body?: NewOrganization
  },
  context?: HttpContext

): Observable<Organization> {

    return this.accountPrototypeCreateOrganizations$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Creates a new instance in organizations of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCreateOrganizations$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountPrototypeCreateOrganizations$Json$Xml$Response(params: {

    /**
     * Account id
     */
    id: string;
    body?: NewOrganization
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCreateOrganizationsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Creates a new instance in organizations of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCreateOrganizations$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountPrototypeCreateOrganizations$Json$Xml(params: {

    /**
     * Account id
     */
    id: string;
    body?: NewOrganization
  },
  context?: HttpContext

): Observable<Organization> {

    return this.accountPrototypeCreateOrganizations$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Creates a new instance in organizations of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCreateOrganizations$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountPrototypeCreateOrganizations$Json$Javascript$Response(params: {

    /**
     * Account id
     */
    id: string;
    body?: NewOrganization
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCreateOrganizationsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Creates a new instance in organizations of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCreateOrganizations$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountPrototypeCreateOrganizations$Json$Javascript(params: {

    /**
     * Account id
     */
    id: string;
    body?: NewOrganization
  },
  context?: HttpContext

): Observable<Organization> {

    return this.accountPrototypeCreateOrganizations$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Creates a new instance in organizations of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCreateOrganizations$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountPrototypeCreateOrganizations$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Account id
     */
    id: string;
    body?: NewOrganization
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCreateOrganizationsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Creates a new instance in organizations of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCreateOrganizations$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountPrototypeCreateOrganizations$XWwwFormUrlencoded$Json(params: {

    /**
     * Account id
     */
    id: string;
    body?: NewOrganization
  },
  context?: HttpContext

): Observable<Organization> {

    return this.accountPrototypeCreateOrganizations$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Creates a new instance in organizations of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCreateOrganizations$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountPrototypeCreateOrganizations$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Account id
     */
    id: string;
    body?: NewOrganization
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCreateOrganizationsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Creates a new instance in organizations of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCreateOrganizations$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountPrototypeCreateOrganizations$XWwwFormUrlencoded$Xml(params: {

    /**
     * Account id
     */
    id: string;
    body?: NewOrganization
  },
  context?: HttpContext

): Observable<Organization> {

    return this.accountPrototypeCreateOrganizations$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Creates a new instance in organizations of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCreateOrganizations$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountPrototypeCreateOrganizations$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Account id
     */
    id: string;
    body?: NewOrganization
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCreateOrganizationsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Creates a new instance in organizations of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCreateOrganizations$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountPrototypeCreateOrganizations$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Account id
     */
    id: string;
    body?: NewOrganization
  },
  context?: HttpContext

): Observable<Organization> {

    return this.accountPrototypeCreateOrganizations$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Creates a new instance in organizations of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCreateOrganizations$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountPrototypeCreateOrganizations$Xml$Json$Response(params: {

    /**
     * Account id
     */
    id: string;
    body?: NewOrganization
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCreateOrganizationsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Creates a new instance in organizations of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCreateOrganizations$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountPrototypeCreateOrganizations$Xml$Json(params: {

    /**
     * Account id
     */
    id: string;
    body?: NewOrganization
  },
  context?: HttpContext

): Observable<Organization> {

    return this.accountPrototypeCreateOrganizations$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Creates a new instance in organizations of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCreateOrganizations$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountPrototypeCreateOrganizations$Xml$Xml$Response(params: {

    /**
     * Account id
     */
    id: string;
    body?: NewOrganization
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCreateOrganizationsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Creates a new instance in organizations of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCreateOrganizations$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountPrototypeCreateOrganizations$Xml$Xml(params: {

    /**
     * Account id
     */
    id: string;
    body?: NewOrganization
  },
  context?: HttpContext

): Observable<Organization> {

    return this.accountPrototypeCreateOrganizations$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Creates a new instance in organizations of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCreateOrganizations$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountPrototypeCreateOrganizations$Xml$Javascript$Response(params: {

    /**
     * Account id
     */
    id: string;
    body?: NewOrganization
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCreateOrganizationsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Creates a new instance in organizations of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCreateOrganizations$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountPrototypeCreateOrganizations$Xml$Javascript(params: {

    /**
     * Account id
     */
    id: string;
    body?: NewOrganization
  },
  context?: HttpContext

): Observable<Organization> {

    return this.accountPrototypeCreateOrganizations$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Path part for operation accountPrototypeCountOrganizations
   */
  static readonly AccountPrototypeCountOrganizationsPath = '/v1/Accounts/{id}/organizations/count';

  /**
   * Counts organizations of Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCountOrganizations$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeCountOrganizations$Json$Response(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCountOrganizationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts organizations of Account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCountOrganizations$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeCountOrganizations$Json(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.accountPrototypeCountOrganizations$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts organizations of Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCountOrganizations$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeCountOrganizations$Xml$Response(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCountOrganizationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts organizations of Account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCountOrganizations$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeCountOrganizations$Xml(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.accountPrototypeCountOrganizations$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts organizations of Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCountOrganizations$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeCountOrganizations$Javascript$Response(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCountOrganizationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts organizations of Account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCountOrganizations$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeCountOrganizations$Javascript(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.accountPrototypeCountOrganizations$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation accountPrototypeGetLogs
   */
  static readonly AccountPrototypeGetLogsPath = '/v1/Accounts/{id}/logs';

  /**
   * Queries logs of Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeGetLogs$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetLogs$Json$Response(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Log>>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeGetLogsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Log>>;
      })
    );
  }

  /**
   * Queries logs of Account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeGetLogs$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetLogs$Json(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Log>> {

    return this.accountPrototypeGetLogs$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Log>>) => r.body as Array<Log>)
    );
  }

  /**
   * Queries logs of Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeGetLogs$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetLogs$Xml$Response(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Log>>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeGetLogsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Log>>;
      })
    );
  }

  /**
   * Queries logs of Account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeGetLogs$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetLogs$Xml(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Log>> {

    return this.accountPrototypeGetLogs$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Log>>) => r.body as Array<Log>)
    );
  }

  /**
   * Queries logs of Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeGetLogs$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetLogs$Javascript$Response(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Log>>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeGetLogsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Log>>;
      })
    );
  }

  /**
   * Queries logs of Account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeGetLogs$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeGetLogs$Javascript(params: {

    /**
     * Account id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Log>> {

    return this.accountPrototypeGetLogs$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Log>>) => r.body as Array<Log>)
    );
  }

  /**
   * Path part for operation accountPrototypeCountLogs
   */
  static readonly AccountPrototypeCountLogsPath = '/v1/Accounts/{id}/logs/count';

  /**
   * Counts logs of Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCountLogs$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeCountLogs$Json$Response(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCountLogsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts logs of Account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCountLogs$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeCountLogs$Json(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.accountPrototypeCountLogs$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts logs of Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCountLogs$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeCountLogs$Xml$Response(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCountLogsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts logs of Account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCountLogs$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeCountLogs$Xml(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.accountPrototypeCountLogs$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts logs of Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeCountLogs$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeCountLogs$Javascript$Response(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeCountLogsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts logs of Account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeCountLogs$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeCountLogs$Javascript(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.accountPrototypeCountLogs$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation accountCreate
   */
  static readonly AccountCreatePath = '/v1/Accounts';

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountCreate$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountCreate$Json$Json$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewAccount
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountCreate$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountCreate$Json$Json(params?: {

    /**
     * Model instance data
     */
    body?: NewAccount
  },
  context?: HttpContext

): Observable<Account> {

    return this.accountCreate$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountCreate$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountCreate$Json$Xml$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewAccount
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountCreate$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountCreate$Json$Xml(params?: {

    /**
     * Model instance data
     */
    body?: NewAccount
  },
  context?: HttpContext

): Observable<Account> {

    return this.accountCreate$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountCreate$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountCreate$Json$Javascript$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewAccount
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountCreate$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountCreate$Json$Javascript(params?: {

    /**
     * Model instance data
     */
    body?: NewAccount
  },
  context?: HttpContext

): Observable<Account> {

    return this.accountCreate$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountCreate$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountCreate$XWwwFormUrlencoded$Json$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewAccount
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountCreate$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountCreate$XWwwFormUrlencoded$Json(params?: {

    /**
     * Model instance data
     */
    body?: NewAccount
  },
  context?: HttpContext

): Observable<Account> {

    return this.accountCreate$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountCreate$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountCreate$XWwwFormUrlencoded$Xml$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewAccount
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountCreate$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountCreate$XWwwFormUrlencoded$Xml(params?: {

    /**
     * Model instance data
     */
    body?: NewAccount
  },
  context?: HttpContext

): Observable<Account> {

    return this.accountCreate$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountCreate$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountCreate$XWwwFormUrlencoded$Javascript$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewAccount
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountCreate$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountCreate$XWwwFormUrlencoded$Javascript(params?: {

    /**
     * Model instance data
     */
    body?: NewAccount
  },
  context?: HttpContext

): Observable<Account> {

    return this.accountCreate$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountCreate$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountCreate$Xml$Json$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewAccount
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountCreate$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountCreate$Xml$Json(params?: {

    /**
     * Model instance data
     */
    body?: NewAccount
  },
  context?: HttpContext

): Observable<Account> {

    return this.accountCreate$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountCreate$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountCreate$Xml$Xml$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewAccount
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountCreate$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountCreate$Xml$Xml(params?: {

    /**
     * Model instance data
     */
    body?: NewAccount
  },
  context?: HttpContext

): Observable<Account> {

    return this.accountCreate$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountCreate$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountCreate$Xml$Javascript$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewAccount
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountCreate$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountCreate$Xml$Javascript(params?: {

    /**
     * Model instance data
     */
    body?: NewAccount
  },
  context?: HttpContext

): Observable<Account> {

    return this.accountCreate$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Path part for operation accountFindById
   */
  static readonly AccountFindByIdPath = '/v1/Accounts/{id}';

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountFindById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountFindById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountFindById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountFindById$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Account> {

    return this.accountFindById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountFindById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountFindById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountFindById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountFindById$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Account> {

    return this.accountFindById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountFindById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountFindById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountFindById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountFindById$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Account> {

    return this.accountFindById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Path part for operation accountPrototypePatchAttributes
   */
  static readonly AccountPrototypePatchAttributesPath = '/v1/Accounts/{id}';

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypePatchAttributes$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountPrototypePatchAttributes$Json$Json$Response(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Account
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypePatchAttributes$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountPrototypePatchAttributes$Json$Json(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Account
  },
  context?: HttpContext

): Observable<Account> {

    return this.accountPrototypePatchAttributes$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypePatchAttributes$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountPrototypePatchAttributes$Json$Xml$Response(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Account
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypePatchAttributes$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountPrototypePatchAttributes$Json$Xml(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Account
  },
  context?: HttpContext

): Observable<Account> {

    return this.accountPrototypePatchAttributes$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypePatchAttributes$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountPrototypePatchAttributes$Json$Javascript$Response(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Account
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypePatchAttributes$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountPrototypePatchAttributes$Json$Javascript(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Account
  },
  context?: HttpContext

): Observable<Account> {

    return this.accountPrototypePatchAttributes$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypePatchAttributes$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Account
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountPrototypePatchAttributes$XWwwFormUrlencoded$Json(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Account
  },
  context?: HttpContext

): Observable<Account> {

    return this.accountPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypePatchAttributes$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Account
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountPrototypePatchAttributes$XWwwFormUrlencoded$Xml(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Account
  },
  context?: HttpContext

): Observable<Account> {

    return this.accountPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypePatchAttributes$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Account
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountPrototypePatchAttributes$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Account
  },
  context?: HttpContext

): Observable<Account> {

    return this.accountPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypePatchAttributes$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountPrototypePatchAttributes$Xml$Json$Response(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Account
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypePatchAttributes$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountPrototypePatchAttributes$Xml$Json(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Account
  },
  context?: HttpContext

): Observable<Account> {

    return this.accountPrototypePatchAttributes$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypePatchAttributes$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountPrototypePatchAttributes$Xml$Xml$Response(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Account
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypePatchAttributes$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountPrototypePatchAttributes$Xml$Xml(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Account
  },
  context?: HttpContext

): Observable<Account> {

    return this.accountPrototypePatchAttributes$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypePatchAttributes$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountPrototypePatchAttributes$Xml$Javascript$Response(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Account
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypePatchAttributes$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountPrototypePatchAttributes$Xml$Javascript(params: {

    /**
     * Account id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Account
  },
  context?: HttpContext

): Observable<Account> {

    return this.accountPrototypePatchAttributes$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Path part for operation accountLogin
   */
  static readonly AccountLoginPath = '/v1/Accounts/login';

  /**
   * Login a user with username/email and password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountLogin$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountLogin$Json$Json$Response(params: {

    /**
     * Related objects to include in the response. See the description of return value for more details.
     */
    include?: string;
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountLoginPath, 'post');
    if (params) {
      rb.query('include', params.include, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Login a user with username/email and password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountLogin$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountLogin$Json$Json(params: {

    /**
     * Related objects to include in the response. See the description of return value for more details.
     */
    include?: string;
    body: {
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.accountLogin$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Login a user with username/email and password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountLogin$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountLogin$Json$Xml$Response(params: {

    /**
     * Related objects to include in the response. See the description of return value for more details.
     */
    include?: string;
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountLoginPath, 'post');
    if (params) {
      rb.query('include', params.include, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Login a user with username/email and password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountLogin$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountLogin$Json$Xml(params: {

    /**
     * Related objects to include in the response. See the description of return value for more details.
     */
    include?: string;
    body: {
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.accountLogin$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Login a user with username/email and password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountLogin$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountLogin$Json$Javascript$Response(params: {

    /**
     * Related objects to include in the response. See the description of return value for more details.
     */
    include?: string;
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountLoginPath, 'post');
    if (params) {
      rb.query('include', params.include, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Login a user with username/email and password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountLogin$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountLogin$Json$Javascript(params: {

    /**
     * Related objects to include in the response. See the description of return value for more details.
     */
    include?: string;
    body: {
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.accountLogin$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Login a user with username/email and password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountLogin$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountLogin$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Related objects to include in the response. See the description of return value for more details.
     */
    include?: string;
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountLoginPath, 'post');
    if (params) {
      rb.query('include', params.include, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Login a user with username/email and password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountLogin$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountLogin$XWwwFormUrlencoded$Json(params: {

    /**
     * Related objects to include in the response. See the description of return value for more details.
     */
    include?: string;
    body: {
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.accountLogin$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Login a user with username/email and password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountLogin$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountLogin$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Related objects to include in the response. See the description of return value for more details.
     */
    include?: string;
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountLoginPath, 'post');
    if (params) {
      rb.query('include', params.include, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Login a user with username/email and password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountLogin$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountLogin$XWwwFormUrlencoded$Xml(params: {

    /**
     * Related objects to include in the response. See the description of return value for more details.
     */
    include?: string;
    body: {
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.accountLogin$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Login a user with username/email and password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountLogin$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountLogin$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Related objects to include in the response. See the description of return value for more details.
     */
    include?: string;
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountLoginPath, 'post');
    if (params) {
      rb.query('include', params.include, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Login a user with username/email and password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountLogin$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountLogin$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Related objects to include in the response. See the description of return value for more details.
     */
    include?: string;
    body: {
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.accountLogin$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Login a user with username/email and password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountLogin$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountLogin$Xml$Json$Response(params: {

    /**
     * Related objects to include in the response. See the description of return value for more details.
     */
    include?: string;
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountLoginPath, 'post');
    if (params) {
      rb.query('include', params.include, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Login a user with username/email and password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountLogin$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountLogin$Xml$Json(params: {

    /**
     * Related objects to include in the response. See the description of return value for more details.
     */
    include?: string;
    body: {
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.accountLogin$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Login a user with username/email and password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountLogin$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountLogin$Xml$Xml$Response(params: {

    /**
     * Related objects to include in the response. See the description of return value for more details.
     */
    include?: string;
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountLoginPath, 'post');
    if (params) {
      rb.query('include', params.include, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Login a user with username/email and password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountLogin$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountLogin$Xml$Xml(params: {

    /**
     * Related objects to include in the response. See the description of return value for more details.
     */
    include?: string;
    body: {
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.accountLogin$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Login a user with username/email and password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountLogin$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountLogin$Xml$Javascript$Response(params: {

    /**
     * Related objects to include in the response. See the description of return value for more details.
     */
    include?: string;
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountLoginPath, 'post');
    if (params) {
      rb.query('include', params.include, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Login a user with username/email and password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountLogin$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountLogin$Xml$Javascript(params: {

    /**
     * Related objects to include in the response. See the description of return value for more details.
     */
    include?: string;
    body: {
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.accountLogin$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation accountLogout
   */
  static readonly AccountLogoutPath = '/v1/Accounts/logout';

  /**
   * Logout a user with access token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountLogout()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountLogout$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountLogoutPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Logout a user with access token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountLogout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountLogout(params?: {
  },
  context?: HttpContext

): Observable<void> {

    return this.accountLogout$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation accountPrototypeVerify
   */
  static readonly AccountPrototypeVerifyPath = '/v1/Accounts/{id}/verify';

  /**
   * Trigger user's identity verification with configured verifyOptions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeVerify()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeVerify$Response(params: {

    /**
     * Account id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeVerifyPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Trigger user's identity verification with configured verifyOptions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeVerify$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeVerify(params: {

    /**
     * Account id
     */
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.accountPrototypeVerify$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation accountConfirm
   */
  static readonly AccountConfirmPath = '/v1/Accounts/confirm';

  /**
   * Confirm a user registration with identity verification token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountConfirm()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountConfirm$Response(params: {
    uid: string;
    token: string;
    redirect?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountConfirmPath, 'get');
    if (params) {
      rb.query('uid', params.uid, {});
      rb.query('token', params.token, {});
      rb.query('redirect', params.redirect, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Confirm a user registration with identity verification token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountConfirm$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountConfirm(params: {
    uid: string;
    token: string;
    redirect?: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.accountConfirm$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation accountResetPassword
   */
  static readonly AccountResetPasswordPath = '/v1/Accounts/reset';

  /**
   * Reset password for a user with email.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountResetPassword$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountResetPassword$Json$Response(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountResetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Reset password for a user with email.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountResetPassword$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountResetPassword$Json(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<void> {

    return this.accountResetPassword$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Reset password for a user with email.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountResetPassword$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountResetPassword$XWwwFormUrlencoded$Response(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountResetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Reset password for a user with email.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountResetPassword$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountResetPassword$XWwwFormUrlencoded(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<void> {

    return this.accountResetPassword$XWwwFormUrlencoded$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Reset password for a user with email.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountResetPassword$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountResetPassword$Xml$Response(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountResetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Reset password for a user with email.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountResetPassword$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  accountResetPassword$Xml(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<void> {

    return this.accountResetPassword$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation accountChangePassword
   */
  static readonly AccountChangePasswordPath = '/v1/Accounts/change-password';

  /**
   * Change a user's password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountChangePassword()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountChangePassword$Response(params: {
    body: {
'oldPassword': string;
'newPassword': string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountChangePasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Change a user's password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountChangePassword$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountChangePassword(params: {
    body: {
'oldPassword': string;
'newPassword': string;
}
  },
  context?: HttpContext

): Observable<void> {

    return this.accountChangePassword$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation accountSetPassword
   */
  static readonly AccountSetPasswordPath = '/v1/Accounts/reset-password';

  /**
   * Reset user's password via a password-reset token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountSetPassword()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountSetPassword$Response(params: {
    body: {
'newPassword': string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountSetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Reset user's password via a password-reset token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountSetPassword$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountSetPassword(params: {
    body: {
'newPassword': string;
}
  },
  context?: HttpContext

): Observable<void> {

    return this.accountSetPassword$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation accountIsUnique
   */
  static readonly AccountIsUniquePath = '/v1/Accounts/is-unique';

  /**
   * Check if e-mail already exists.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountIsUnique$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountIsUnique$Json$Response(params: {

    /**
     * E-mail to check uniqueness against.
     */
    email: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'isUnique'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountIsUniquePath, 'get');
    if (params) {
      rb.query('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isUnique'?: boolean;
        }>;
      })
    );
  }

  /**
   * Check if e-mail already exists.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountIsUnique$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountIsUnique$Json(params: {

    /**
     * E-mail to check uniqueness against.
     */
    email: string;
  },
  context?: HttpContext

): Observable<{
'isUnique'?: boolean;
}> {

    return this.accountIsUnique$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'isUnique'?: boolean;
}>) => r.body as {
'isUnique'?: boolean;
})
    );
  }

  /**
   * Check if e-mail already exists.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountIsUnique$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountIsUnique$Xml$Response(params: {

    /**
     * E-mail to check uniqueness against.
     */
    email: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'isUnique'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountIsUniquePath, 'get');
    if (params) {
      rb.query('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isUnique'?: boolean;
        }>;
      })
    );
  }

  /**
   * Check if e-mail already exists.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountIsUnique$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountIsUnique$Xml(params: {

    /**
     * E-mail to check uniqueness against.
     */
    email: string;
  },
  context?: HttpContext

): Observable<{
'isUnique'?: boolean;
}> {

    return this.accountIsUnique$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'isUnique'?: boolean;
}>) => r.body as {
'isUnique'?: boolean;
})
    );
  }

  /**
   * Check if e-mail already exists.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountIsUnique$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountIsUnique$Javascript$Response(params: {

    /**
     * E-mail to check uniqueness against.
     */
    email: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'isUnique'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountIsUniquePath, 'get');
    if (params) {
      rb.query('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isUnique'?: boolean;
        }>;
      })
    );
  }

  /**
   * Check if e-mail already exists.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountIsUnique$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountIsUnique$Javascript(params: {

    /**
     * E-mail to check uniqueness against.
     */
    email: string;
  },
  context?: HttpContext

): Observable<{
'isUnique'?: boolean;
}> {

    return this.accountIsUnique$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'isUnique'?: boolean;
}>) => r.body as {
'isUnique'?: boolean;
})
    );
  }

  /**
   * Path part for operation accountImpersonate
   */
  static readonly AccountImpersonatePath = '/v1/Accounts/impersonate';

  /**
   * Login as child account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountImpersonate$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountImpersonate$Json$Response(params?: {
    body?: {

/**
 * Account's email
 */
'email'?: string;

/**
 * Account's id
 */
'id'?: string;

/**
 * TTL for generated token. Default 2 months.
 */
'ttl'?: number;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AccessToken>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountImpersonatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessToken>;
      })
    );
  }

  /**
   * Login as child account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountImpersonate$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountImpersonate$Json(params?: {
    body?: {

/**
 * Account's email
 */
'email'?: string;

/**
 * Account's id
 */
'id'?: string;

/**
 * TTL for generated token. Default 2 months.
 */
'ttl'?: number;
}
  },
  context?: HttpContext

): Observable<AccessToken> {

    return this.accountImpersonate$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<AccessToken>) => r.body as AccessToken)
    );
  }

  /**
   * Login as child account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountImpersonate$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountImpersonate$Xml$Response(params?: {
    body?: {

/**
 * Account's email
 */
'email'?: string;

/**
 * Account's id
 */
'id'?: string;

/**
 * TTL for generated token. Default 2 months.
 */
'ttl'?: number;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AccessToken>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountImpersonatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessToken>;
      })
    );
  }

  /**
   * Login as child account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountImpersonate$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountImpersonate$Xml(params?: {
    body?: {

/**
 * Account's email
 */
'email'?: string;

/**
 * Account's id
 */
'id'?: string;

/**
 * TTL for generated token. Default 2 months.
 */
'ttl'?: number;
}
  },
  context?: HttpContext

): Observable<AccessToken> {

    return this.accountImpersonate$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<AccessToken>) => r.body as AccessToken)
    );
  }

  /**
   * Login as child account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountImpersonate$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountImpersonate$Javascript$Response(params?: {
    body?: {

/**
 * Account's email
 */
'email'?: string;

/**
 * Account's id
 */
'id'?: string;

/**
 * TTL for generated token. Default 2 months.
 */
'ttl'?: number;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AccessToken>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountImpersonatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessToken>;
      })
    );
  }

  /**
   * Login as child account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountImpersonate$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountImpersonate$Javascript(params?: {
    body?: {

/**
 * Account's email
 */
'email'?: string;

/**
 * Account's id
 */
'id'?: string;

/**
 * TTL for generated token. Default 2 months.
 */
'ttl'?: number;
}
  },
  context?: HttpContext

): Observable<AccessToken> {

    return this.accountImpersonate$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<AccessToken>) => r.body as AccessToken)
    );
  }

  /**
   * Path part for operation accountPrototypeIsVerified
   */
  static readonly AccountPrototypeIsVerifiedPath = '/v1/Accounts/{id}/is-verified';

  /**
   * Check if account is verified.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeIsVerified$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeIsVerified$Json$Response(params: {

    /**
     * Account id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'isVerified'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeIsVerifiedPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isVerified'?: boolean;
        }>;
      })
    );
  }

  /**
   * Check if account is verified.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeIsVerified$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeIsVerified$Json(params: {

    /**
     * Account id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'isVerified'?: boolean;
}> {

    return this.accountPrototypeIsVerified$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'isVerified'?: boolean;
}>) => r.body as {
'isVerified'?: boolean;
})
    );
  }

  /**
   * Check if account is verified.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeIsVerified$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeIsVerified$Xml$Response(params: {

    /**
     * Account id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'isVerified'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeIsVerifiedPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isVerified'?: boolean;
        }>;
      })
    );
  }

  /**
   * Check if account is verified.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeIsVerified$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeIsVerified$Xml(params: {

    /**
     * Account id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'isVerified'?: boolean;
}> {

    return this.accountPrototypeIsVerified$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'isVerified'?: boolean;
}>) => r.body as {
'isVerified'?: boolean;
})
    );
  }

  /**
   * Check if account is verified.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeIsVerified$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeIsVerified$Javascript$Response(params: {

    /**
     * Account id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'isVerified'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeIsVerifiedPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'isVerified'?: boolean;
        }>;
      })
    );
  }

  /**
   * Check if account is verified.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeIsVerified$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeIsVerified$Javascript(params: {

    /**
     * Account id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'isVerified'?: boolean;
}> {

    return this.accountPrototypeIsVerified$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'isVerified'?: boolean;
}>) => r.body as {
'isVerified'?: boolean;
})
    );
  }

  /**
   * Path part for operation accountAuthorized
   */
  static readonly AccountAuthorizedPath = '/v1/Accounts/authorized';

  /**
   * Checks if user is authorized and returns user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountAuthorized$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountAuthorized$Json$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountAuthorizedPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Checks if user is authorized and returns user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountAuthorized$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountAuthorized$Json(params?: {
  },
  context?: HttpContext

): Observable<{
}> {

    return this.accountAuthorized$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Checks if user is authorized and returns user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountAuthorized$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountAuthorized$Xml$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountAuthorizedPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Checks if user is authorized and returns user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountAuthorized$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountAuthorized$Xml(params?: {
  },
  context?: HttpContext

): Observable<{
}> {

    return this.accountAuthorized$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Checks if user is authorized and returns user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountAuthorized$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountAuthorized$Javascript$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountAuthorizedPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Checks if user is authorized and returns user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountAuthorized$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountAuthorized$Javascript(params?: {
  },
  context?: HttpContext

): Observable<{
}> {

    return this.accountAuthorized$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation accountPrototypeSendVerificationEmail
   */
  static readonly AccountPrototypeSendVerificationEmailPath = '/v1/Accounts/{id}/send-verification';

  /**
   * Request another verification email.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeSendVerificationEmail$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeSendVerificationEmail$Json$Response(params: {

    /**
     * Account id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeSendVerificationEmailPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * Request another verification email.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeSendVerificationEmail$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeSendVerificationEmail$Json(params: {

    /**
     * Account id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.accountPrototypeSendVerificationEmail$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Request another verification email.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeSendVerificationEmail$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeSendVerificationEmail$Xml$Response(params: {

    /**
     * Account id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeSendVerificationEmailPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * Request another verification email.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeSendVerificationEmail$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeSendVerificationEmail$Xml(params: {

    /**
     * Account id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.accountPrototypeSendVerificationEmail$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Request another verification email.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountPrototypeSendVerificationEmail$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeSendVerificationEmail$Javascript$Response(params: {

    /**
     * Account id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountPrototypeSendVerificationEmailPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * Request another verification email.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountPrototypeSendVerificationEmail$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountPrototypeSendVerificationEmail$Javascript(params: {

    /**
     * Account id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.accountPrototypeSendVerificationEmail$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Path part for operation accountIdentityVerification
   */
  static readonly AccountIdentityVerificationPath = '/v1/Accounts/identity-verification';

  /**
   * Returns identity hash for user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountIdentityVerification$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountIdentityVerification$Json$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountIdentityVerificationPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Returns identity hash for user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountIdentityVerification$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountIdentityVerification$Json(params?: {
  },
  context?: HttpContext

): Observable<{
}> {

    return this.accountIdentityVerification$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Returns identity hash for user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountIdentityVerification$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountIdentityVerification$Xml$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountIdentityVerificationPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Returns identity hash for user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountIdentityVerification$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountIdentityVerification$Xml(params?: {
  },
  context?: HttpContext

): Observable<{
}> {

    return this.accountIdentityVerification$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Returns identity hash for user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountIdentityVerification$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountIdentityVerification$Javascript$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountIdentityVerificationPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Returns identity hash for user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountIdentityVerification$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountIdentityVerification$Javascript(params?: {
  },
  context?: HttpContext

): Observable<{
}> {

    return this.accountIdentityVerification$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation accountGetSubscription
   */
  static readonly AccountGetSubscriptionPath = '/v1/Accounts/subscription';

  /**
   * Retrieve Account's active subscription.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountGetSubscription$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountGetSubscription$Json$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountGetSubscriptionPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Retrieve Account's active subscription.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountGetSubscription$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountGetSubscription$Json(params?: {
  },
  context?: HttpContext

): Observable<{
}> {

    return this.accountGetSubscription$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Retrieve Account's active subscription.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountGetSubscription$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountGetSubscription$Xml$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountGetSubscriptionPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Retrieve Account's active subscription.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountGetSubscription$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountGetSubscription$Xml(params?: {
  },
  context?: HttpContext

): Observable<{
}> {

    return this.accountGetSubscription$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Retrieve Account's active subscription.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountGetSubscription$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountGetSubscription$Javascript$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountGetSubscriptionPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Retrieve Account's active subscription.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountGetSubscription$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountGetSubscription$Javascript(params?: {
  },
  context?: HttpContext

): Observable<{
}> {

    return this.accountGetSubscription$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

}
