/* tslint:disable */
/**
* @module SDKModule
* @author Jonathan Casarrubias <t:@johncasarrubias> <gh:jonathan-casarrubias>
* @license MIT 2016 Jonathan Casarrubias
* @version 2.1.0
* @description
* The SDKModule is a generated Software Development Kit automatically built by
* the LoopBack SDK Builder open source module.
*
* The SDKModule provides Angular 2 >= RC.5 support, which means that NgModules
* can import this Software Development Kit as follows:
*
*
* APP Route Module Context
* ============================================================================
* import { NgModule }       from '@angular/core';
* import { BrowserModule }  from '@angular/platform-browser';
* // App Root 
* import { AppComponent }   from './app.component';
* // Feature Modules
* import { SDK[Browser|Node|Native]Module } from './shared/sdk/sdk.module';
* // Import Routing
* import { routing }        from './app.routing';
* @NgModule({
*  imports: [
*    BrowserModule,
*    routing,
*    SDK[Browser|Node|Native]Module.forRoot()
*  ],
*  declarations: [ AppComponent ],
*  bootstrap:    [ AppComponent ]
* })
* export class AppModule { }
*
**/
import { ErrorHandler } from './services/core/error.service';
import { LoopBackAuth } from './services/core/auth.service';
import { LoggerService } from './services/custom/logger.service';
import { SDKModels } from './services/custom/SDKModels';
import { InternalStorage, SDKStorage } from './storage/storage.swaps';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CookieBrowser } from './storage/cookie.browser';
import { StorageBrowser } from './storage/storage.browser';
import { AccessTokenApi } from './services/custom/AccessToken';
import { DocumentApi } from './services/custom/Document';
import { ItemApi } from './services/custom/Item';
import { DocumentItemApi } from './services/custom/DocumentItem';
import { TaxApi } from './services/custom/Tax';
import { DocumentItemTaxApi } from './services/custom/DocumentItemTax';
import { ClientApi } from './services/custom/Client';
import { OrganizationApi } from './services/custom/Organization';
import { ShopifyApi } from './services/custom/Shopify';
import { StripeApi } from './services/custom/Stripe';
import { TicketingHubApi } from './services/custom/TicketingHub';
import { CurrencyApi } from './services/custom/Currency';
import { AccountApi } from './services/custom/Account';
import { ActivityApi } from './services/custom/Activity';
import { RecurrenceApi } from './services/custom/Recurrence';
import { PaymentApi } from './services/custom/Payment';
import { DefaultApi } from './services/custom/Default';
import { CommentApi } from './services/custom/Comment';
import { CompanyApi } from './services/custom/Company';
import { DocumentHistoryApi } from './services/custom/DocumentHistory';
import { SubscriptionApi } from './services/custom/Subscription';
import { PlanApi } from './services/custom/Plan';
import { UsageApi } from './services/custom/Usage';
import { WhiteLabelApi } from './services/custom/WhiteLabel';
import { ContainerApi } from './services/custom/Container';
import { MessageApi } from './services/custom/Message';
import { BusinessPremiseApi } from './services/custom/BusinessPremise';
import { CountryApi } from './services/custom/Country';
import { TrackingApi } from './services/custom/Tracking';
import { ElectronicDeviceApi } from './services/custom/ElectronicDevice';
import { CertificateApi } from './services/custom/Certificate';
import { GeoIPApi } from './services/custom/GeoIP';
import { EslogApi } from './services/custom/Eslog';
import { AccountOrganizationApi } from './services/custom/AccountOrganization';
import { FileMetaApi } from './services/custom/FileMeta';
import { UnitApi } from './services/custom/Unit';
import { CategoryApi } from './services/custom/Category';
import { DocumentCategoryApi } from './services/custom/DocumentCategory';
import { PriceListApi } from './services/custom/PriceList';
import { PriceApi } from './services/custom/Price';
import { WarehouseApi } from './services/custom/Warehouse';
import { ComponentApi } from './services/custom/Component';
import { WebhookApi } from './services/custom/Webhook';
import { TyplessApi } from './services/custom/Typless';
import { VIESApi } from './services/custom/VIES';
import { OrderApi } from './services/custom/Order';
import { Magento1Api } from './services/custom/Magento1';
import { WoocommerceApi } from './services/custom/Woocommerce';
import { CustomIntegrationApi } from './services/custom/CustomIntegration';
import { BokunApi } from './services/custom/Bokun';
import { ShopamineApi } from './services/custom/Shopamine';
import { DataApi } from './services/custom/Data';
import { SendgridApi } from './services/custom/Sendgrid';
import { LogApi } from './services/custom/Log';
/**
* @module SDKBrowserModule
* @description
* This module should be imported when building a Web Application in the following scenarios:
*
*  1.- Regular web application
*  2.- Angular universal application (Browser Portion)
*  3.- Progressive applications (Angular Mobile, Ionic, WebViews, etc)
**/
@NgModule({
  imports:      [ CommonModule, HttpClientModule ],
  declarations: [ ],
  exports:      [ ],
  providers:    [
    ErrorHandler
  ]
})
export class SDKBrowserModule {
  static forRoot(internalStorageProvider: any = {
    provide: InternalStorage,
    useClass: CookieBrowser
  }): ModuleWithProviders<any> {
    return {
      ngModule  : SDKBrowserModule,
      providers : [
        LoopBackAuth,
        LoggerService,
        SDKModels,
        AccessTokenApi,
        DocumentApi,
        ItemApi,
        DocumentItemApi,
        TaxApi,
        DocumentItemTaxApi,
        ClientApi,
        OrganizationApi,
        ShopifyApi,
        StripeApi,
        TicketingHubApi,
        CurrencyApi,
        AccountApi,
        ActivityApi,
        RecurrenceApi,
        PaymentApi,
        DefaultApi,
        CommentApi,
        CompanyApi,
        DocumentHistoryApi,
        SubscriptionApi,
        PlanApi,
        UsageApi,
        WhiteLabelApi,
        ContainerApi,
        MessageApi,
        BusinessPremiseApi,
        CountryApi,
        TrackingApi,
        ElectronicDeviceApi,
        CertificateApi,
        GeoIPApi,
        EslogApi,
        AccountOrganizationApi,
        FileMetaApi,
        UnitApi,
        CategoryApi,
        DocumentCategoryApi,
        PriceListApi,
        PriceApi,
        WarehouseApi,
        ComponentApi,
        WebhookApi,
        TyplessApi,
        VIESApi,
        OrderApi,
        Magento1Api,
        WoocommerceApi,
        CustomIntegrationApi,
        BokunApi,
        ShopamineApi,
        DataApi,
        SendgridApi,
        LogApi,
        internalStorageProvider,
        { provide: SDKStorage, useClass: StorageBrowser }
      ]
    };
  }
}
/**
* Have Fun!!!
* - Jon
**/
export * from './models/index';
export * from './services/index';
export * from './lb.config';
export * from './storage/storage.swaps';
export { CookieBrowser } from './storage/cookie.browser';
export { StorageBrowser } from './storage/storage.browser';

