import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { ReplaySubject } from 'rxjs';
import { LoggerService } from '../sdk';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  private activeConnectionToastr: ActiveToast<any>;
  private isOfflineObs = new ReplaySubject<boolean>(1);
  readonly isOffline = this.isOfflineObs.asObservable();

  constructor(
    private toastr: ToastrService,
    private translate: TranslateService,
    private log: LoggerService,
  ) {}

  monitor(): void {
    this.log.info('monitoring');

    window.addEventListener('offline', () => {
      this.log.log('offline');
      this.showDisconnected();

      if (this.isOfflineObs) {
        this.isOfflineObs.next(!window.navigator.onLine);
      }
    });

    window.addEventListener('online', () => {
      this.log.log('online');
      this.hideModal();

      if (this.isOfflineObs) {
        this.isOfflineObs.next(!window.navigator.onLine);
      }
    });
  }

  showDisconnected(): void {
    this.log.log(
      'showDisconnected',
      !this.activeConnectionToastr,
      window.navigator.onLine,
    );

    if (!this.activeConnectionToastr && !window.navigator.onLine) {
      this.activeConnectionToastr = this.toastr.warning(
        this.translate.instant(
          'You are currently offline, trying to reconnect...',
        ),
        '',
        {
          disableTimeOut: true,
          positionClass: 'toast-top-center',
        },
      );
    }
  }

  hideModal(): void {
    if (this.activeConnectionToastr && window.navigator.onLine) {
      this.activeConnectionToastr.toastRef.close();

      this.activeConnectionToastr = this.toastr.success(
        this.translate.instant('Succesfully reconnected...'),
        '',
        {
          positionClass: 'toast-top-center',
          timeOut: 1000,
        },
      );

      this.activeConnectionToastr.onHidden.subscribe(
        () => (this.activeConnectionToastr = null),
      );
    }
  }
}
