/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class AwssesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation awssesWebhook
   */
  static readonly AwssesWebhookPath = '/v1/AWSSES/webhook';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awssesWebhook$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  awssesWebhook$Json$Json$Response(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AwssesService.AwssesWebhookPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `awssesWebhook$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  awssesWebhook$Json$Json(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.awssesWebhook$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awssesWebhook$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  awssesWebhook$Json$Xml$Response(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AwssesService.AwssesWebhookPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `awssesWebhook$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  awssesWebhook$Json$Xml(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.awssesWebhook$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awssesWebhook$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  awssesWebhook$Json$Javascript$Response(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AwssesService.AwssesWebhookPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `awssesWebhook$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  awssesWebhook$Json$Javascript(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.awssesWebhook$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awssesWebhook$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  awssesWebhook$XWwwFormUrlencoded$Json$Response(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AwssesService.AwssesWebhookPath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `awssesWebhook$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  awssesWebhook$XWwwFormUrlencoded$Json(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.awssesWebhook$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awssesWebhook$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  awssesWebhook$XWwwFormUrlencoded$Xml$Response(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AwssesService.AwssesWebhookPath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `awssesWebhook$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  awssesWebhook$XWwwFormUrlencoded$Xml(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.awssesWebhook$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awssesWebhook$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  awssesWebhook$XWwwFormUrlencoded$Javascript$Response(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AwssesService.AwssesWebhookPath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `awssesWebhook$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  awssesWebhook$XWwwFormUrlencoded$Javascript(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.awssesWebhook$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awssesWebhook$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  awssesWebhook$Xml$Json$Response(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AwssesService.AwssesWebhookPath, 'post');
    if (params) {
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `awssesWebhook$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  awssesWebhook$Xml$Json(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.awssesWebhook$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awssesWebhook$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  awssesWebhook$Xml$Xml$Response(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AwssesService.AwssesWebhookPath, 'post');
    if (params) {
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `awssesWebhook$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  awssesWebhook$Xml$Xml(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.awssesWebhook$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `awssesWebhook$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  awssesWebhook$Xml$Javascript$Response(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AwssesService.AwssesWebhookPath, 'post');
    if (params) {
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `awssesWebhook$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  awssesWebhook$Xml$Javascript(params: {
    body: {
}
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.awssesWebhook$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

}
