/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Shopify } from '../models/shopify';

@Injectable({
  providedIn: 'root',
})
export class ShopifyService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation shopifyDeleteById
   */
  static readonly ShopifyDeleteByIdPath = '/v1/Shopify/{id}';

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyDeleteById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopifyDeleteById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyDeleteById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopifyDeleteById$Json(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopifyDeleteById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyDeleteById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopifyDeleteById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyDeleteById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopifyDeleteById$Xml(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopifyDeleteById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyDeleteById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopifyDeleteById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyDeleteById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopifyDeleteById$Javascript(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopifyDeleteById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation shopifyPrototypePatchAttributes
   */
  static readonly ShopifyPrototypePatchAttributesPath = '/v1/Shopify/{id}';

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyPrototypePatchAttributes$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopifyPrototypePatchAttributes$Json$Json$Response(params: {

    /**
     * Shopify id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopify>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopify>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyPrototypePatchAttributes$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopifyPrototypePatchAttributes$Json$Json(params: {

    /**
     * Shopify id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopify
  },
  context?: HttpContext

): Observable<Shopify> {

    return this.shopifyPrototypePatchAttributes$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopify>) => r.body as Shopify)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyPrototypePatchAttributes$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopifyPrototypePatchAttributes$Json$Xml$Response(params: {

    /**
     * Shopify id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopify>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopify>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyPrototypePatchAttributes$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopifyPrototypePatchAttributes$Json$Xml(params: {

    /**
     * Shopify id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopify
  },
  context?: HttpContext

): Observable<Shopify> {

    return this.shopifyPrototypePatchAttributes$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopify>) => r.body as Shopify)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyPrototypePatchAttributes$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopifyPrototypePatchAttributes$Json$Javascript$Response(params: {

    /**
     * Shopify id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopify>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopify>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyPrototypePatchAttributes$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopifyPrototypePatchAttributes$Json$Javascript(params: {

    /**
     * Shopify id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopify
  },
  context?: HttpContext

): Observable<Shopify> {

    return this.shopifyPrototypePatchAttributes$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopify>) => r.body as Shopify)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyPrototypePatchAttributes$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopifyPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Shopify id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopify>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopify>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopifyPrototypePatchAttributes$XWwwFormUrlencoded$Json(params: {

    /**
     * Shopify id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopify
  },
  context?: HttpContext

): Observable<Shopify> {

    return this.shopifyPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopify>) => r.body as Shopify)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyPrototypePatchAttributes$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopifyPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Shopify id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopify>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopify>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopifyPrototypePatchAttributes$XWwwFormUrlencoded$Xml(params: {

    /**
     * Shopify id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopify
  },
  context?: HttpContext

): Observable<Shopify> {

    return this.shopifyPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopify>) => r.body as Shopify)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyPrototypePatchAttributes$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopifyPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Shopify id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopify>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopify>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopifyPrototypePatchAttributes$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Shopify id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopify
  },
  context?: HttpContext

): Observable<Shopify> {

    return this.shopifyPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopify>) => r.body as Shopify)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyPrototypePatchAttributes$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopifyPrototypePatchAttributes$Xml$Json$Response(params: {

    /**
     * Shopify id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopify>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopify>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyPrototypePatchAttributes$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopifyPrototypePatchAttributes$Xml$Json(params: {

    /**
     * Shopify id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopify
  },
  context?: HttpContext

): Observable<Shopify> {

    return this.shopifyPrototypePatchAttributes$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopify>) => r.body as Shopify)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyPrototypePatchAttributes$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopifyPrototypePatchAttributes$Xml$Xml$Response(params: {

    /**
     * Shopify id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopify>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopify>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyPrototypePatchAttributes$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopifyPrototypePatchAttributes$Xml$Xml(params: {

    /**
     * Shopify id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopify
  },
  context?: HttpContext

): Observable<Shopify> {

    return this.shopifyPrototypePatchAttributes$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopify>) => r.body as Shopify)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyPrototypePatchAttributes$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopifyPrototypePatchAttributes$Xml$Javascript$Response(params: {

    /**
     * Shopify id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopify>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopify>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyPrototypePatchAttributes$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopifyPrototypePatchAttributes$Xml$Javascript(params: {

    /**
     * Shopify id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopify
  },
  context?: HttpContext

): Observable<Shopify> {

    return this.shopifyPrototypePatchAttributes$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopify>) => r.body as Shopify)
    );
  }

  /**
   * Path part for operation shopifyPrototypeProcessOrder
   */
  static readonly ShopifyPrototypeProcessOrderPath = '/v1/Shopify/{id}/order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyPrototypeProcessOrder$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopifyPrototypeProcessOrder$Json$Json$Response(params: {

    /**
     * Shopify id
     */
    id: string;
    body?: Shopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyPrototypeProcessOrder$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopifyPrototypeProcessOrder$Json$Json(params: {

    /**
     * Shopify id
     */
    id: string;
    body?: Shopify
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopifyPrototypeProcessOrder$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyPrototypeProcessOrder$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopifyPrototypeProcessOrder$Json$Xml$Response(params: {

    /**
     * Shopify id
     */
    id: string;
    body?: Shopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyPrototypeProcessOrder$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopifyPrototypeProcessOrder$Json$Xml(params: {

    /**
     * Shopify id
     */
    id: string;
    body?: Shopify
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopifyPrototypeProcessOrder$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyPrototypeProcessOrder$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopifyPrototypeProcessOrder$Json$Javascript$Response(params: {

    /**
     * Shopify id
     */
    id: string;
    body?: Shopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyPrototypeProcessOrder$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopifyPrototypeProcessOrder$Json$Javascript(params: {

    /**
     * Shopify id
     */
    id: string;
    body?: Shopify
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopifyPrototypeProcessOrder$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyPrototypeProcessOrder$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopifyPrototypeProcessOrder$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Shopify id
     */
    id: string;
    body?: Shopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyPrototypeProcessOrder$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopifyPrototypeProcessOrder$XWwwFormUrlencoded$Json(params: {

    /**
     * Shopify id
     */
    id: string;
    body?: Shopify
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopifyPrototypeProcessOrder$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyPrototypeProcessOrder$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopifyPrototypeProcessOrder$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Shopify id
     */
    id: string;
    body?: Shopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyPrototypeProcessOrder$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopifyPrototypeProcessOrder$XWwwFormUrlencoded$Xml(params: {

    /**
     * Shopify id
     */
    id: string;
    body?: Shopify
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopifyPrototypeProcessOrder$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyPrototypeProcessOrder$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopifyPrototypeProcessOrder$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Shopify id
     */
    id: string;
    body?: Shopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyPrototypeProcessOrder$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopifyPrototypeProcessOrder$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Shopify id
     */
    id: string;
    body?: Shopify
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopifyPrototypeProcessOrder$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyPrototypeProcessOrder$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopifyPrototypeProcessOrder$Xml$Json$Response(params: {

    /**
     * Shopify id
     */
    id: string;
    body?: Shopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyPrototypeProcessOrder$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopifyPrototypeProcessOrder$Xml$Json(params: {

    /**
     * Shopify id
     */
    id: string;
    body?: Shopify
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopifyPrototypeProcessOrder$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyPrototypeProcessOrder$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopifyPrototypeProcessOrder$Xml$Xml$Response(params: {

    /**
     * Shopify id
     */
    id: string;
    body?: Shopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyPrototypeProcessOrder$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopifyPrototypeProcessOrder$Xml$Xml(params: {

    /**
     * Shopify id
     */
    id: string;
    body?: Shopify
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopifyPrototypeProcessOrder$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyPrototypeProcessOrder$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopifyPrototypeProcessOrder$Xml$Javascript$Response(params: {

    /**
     * Shopify id
     */
    id: string;
    body?: Shopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyPrototypeProcessOrder$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopifyPrototypeProcessOrder$Xml$Javascript(params: {

    /**
     * Shopify id
     */
    id: string;
    body?: Shopify
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopifyPrototypeProcessOrder$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation shopifyPrototypeImport
   */
  static readonly ShopifyPrototypeImportPath = '/v1/Shopify/{id}/import';

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyPrototypeImport$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopifyPrototypeImport$Json$Response(params: {

    /**
     * Shopify id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyPrototypeImportPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyPrototypeImport$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopifyPrototypeImport$Json(params: {

    /**
     * Shopify id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopifyPrototypeImport$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyPrototypeImport$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopifyPrototypeImport$Xml$Response(params: {

    /**
     * Shopify id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyPrototypeImportPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyPrototypeImport$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopifyPrototypeImport$Xml(params: {

    /**
     * Shopify id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopifyPrototypeImport$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyPrototypeImport$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopifyPrototypeImport$Javascript$Response(params: {

    /**
     * Shopify id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyPrototypeImportPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyPrototypeImport$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopifyPrototypeImport$Javascript(params: {

    /**
     * Shopify id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopifyPrototypeImport$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation shopifyIncoming
   */
  static readonly ShopifyIncomingPath = '/v1/Shopify/incoming';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyIncoming()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopifyIncoming$Response(params: {

    /**
     * Shopify token used to authenticate the source of req.
     */
    shopifyToken: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyIncomingPath, 'post');
    if (params) {
      rb.query('shopifyToken', params.shopifyToken, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyIncoming$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopifyIncoming(params: {

    /**
     * Shopify token used to authenticate the source of req.
     */
    shopifyToken: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.shopifyIncoming$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation shopifyApplication
   */
  static readonly ShopifyApplicationPath = '/v1/Shopify/app';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyApplication$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopifyApplication$Json$Response(params?: {

    /**
     * Shop domain
     */
    shop?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyApplicationPath, 'get');
    if (params) {
      rb.query('shop', params.shop, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyApplication$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopifyApplication$Json(params?: {

    /**
     * Shop domain
     */
    shop?: string;
  },
  context?: HttpContext

): Observable<string> {

    return this.shopifyApplication$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyApplication$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopifyApplication$Xml$Response(params?: {

    /**
     * Shop domain
     */
    shop?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyApplicationPath, 'get');
    if (params) {
      rb.query('shop', params.shop, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyApplication$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopifyApplication$Xml(params?: {

    /**
     * Shop domain
     */
    shop?: string;
  },
  context?: HttpContext

): Observable<string> {

    return this.shopifyApplication$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyApplication$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopifyApplication$Javascript$Response(params?: {

    /**
     * Shop domain
     */
    shop?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyApplicationPath, 'get');
    if (params) {
      rb.query('shop', params.shop, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyApplication$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopifyApplication$Javascript(params?: {

    /**
     * Shop domain
     */
    shop?: string;
  },
  context?: HttpContext

): Observable<string> {

    return this.shopifyApplication$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation shopifyPrototypeFindOrderDocument
   */
  static readonly ShopifyPrototypeFindOrderDocumentPath = '/v1/Shopify/{id}/orders/document';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopifyPrototypeFindOrderDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopifyPrototypeFindOrderDocument$Response(params: {

    /**
     * Shopify id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ShopifyService.ShopifyPrototypeFindOrderDocumentPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopifyPrototypeFindOrderDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopifyPrototypeFindOrderDocument(params: {

    /**
     * Shopify id
     */
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.shopifyPrototypeFindOrderDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
