import { Injectable } from '@angular/core';
import { Organization, Document } from '../../shared/sdk';

import { ToastService } from '../../shared/modules/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import * as SEPA from 'sepa';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class SEPAService {
  constructor(
    private toastService: ToastService,
    private translate: TranslateService,
  ) {}

  formatTaxNum(n: string = '') {
    return n.replace(/^\D{0,4}/, '');
  }

  generatePaymentOrder(org: Organization, documents: Document[]): string {
    let errors: string[];

    if (documents.length > 350) {
      this.toastService.warning(
        this.translate.instant('sepa.max-documents-warning'),
      );
      return;
    }

    errors = this.validateOrgFileds(org);

    if (errors.length) {
      this.toastService.warning(
        this.translate.instant('sepa.missing-organization-details.message') +
          errors.join(', '),
        this.translate.instant('sepa.missing-organization-details.title'),
      );

      return;
    }

    const doc = new SEPA.Document('pain.001.001.03');
    doc.grpHdr.id = Date.now();
    doc.grpHdr.created = new Date();
    doc.grpHdr.initiatorName = org.name;
    const info = doc.createPaymentInfo();

    const due = moment(documents[0].dateDue);
    const past = due.isBefore(moment());
    info.requestedExecutionDate = (!past ? due : moment()).toDate();
    // info.batchBooking = true;
    info.debtorIBAN = org.IBAN.replace(/ /g, '');
    info.debtorBIC = org.SWIFT;
    info.debtorName = org.name;

    // https://wiki.xmldation.com/@api/deki/files/356/=EPC262-08_Creditor_Identifier_Overview_v2.2.pdf
    // 'NL00ZZZ321096320000' > 'NL64ZZZ321096320000'
    // 'DE00ZZZ09999999999' > 'DE98ZZZ09999999999'
    info.debtorId = SEPA.checksumCreditorID(
      `${org.countryAlpha2Code}00ZZZ${this.formatTaxNum(org.taxNumber)}`,
    );
    doc.addPaymentInfo(info);

    for (const document of documents) {
      if (document.paidInFull) {
        continue;
      }

      // TODO: Reference
      // <RmtInf>
      //   <Strd>
      //     <CdtrRefInf>
      //       <Tp>
      //         <CdOrPrtry>
      //           <Cd>SCOR</Cd>
      //         </CdOrPrtry>
      //       </Tp>
      //       <Ref>SI1942531357-40002</Ref>
      //     </CdtrRefInf>
      //     <AddtlRmtInf>2020-02DOHODNINA 2020-02</AddtlRmtInf>
      //   </Strd>
      // </RmtInf>

      if (
        !document._documentClient ||
        this.validateDebtorFileds(document._documentClient).length
      ) {
        this.toastService.warning(
          this.translate.instant('sepa.missing-document-client.message', {
            number: document.number,
          }),
        );

        throw new Error('Missing document client');
      }

      const tx = info.createTransaction();
      tx.creditorName = document._documentClient.name;
      tx.creditorIBAN = document._documentClient.IBAN;
      tx.creditorBIC = document._documentClient.SWIFT || '';
      // tx.mandateId = 'XMPL.CUST487.2014';
      tx.mandateSignatureDate = new Date();
      tx.amount = document.totalDue;
      tx.remittanceInfo = document.reference || document.number;
      tx.end2endId = `${org.countryAlpha2Code}99`;

      info.addTransaction(tx);
    }

    try {
      return this.toString(doc);
    } catch (e) {
      this.toastService.warning(this.translate.instant(String(e)));
      throw e;
    }
  }

  toString(doc: any) {
    // return doc.toString();
    return doc.toString().replace('encoding="null"', 'encoding="utf-8"');
  }

  validateDebtorFileds(org: any) {
    const errors = [];

    for (const e of ['name', 'IBAN']) {
      if (!org[e]) {
        errors.push(e);
      }
    }

    return errors;
  }

  validateOrgFileds(org: any) {
    const errors = [];

    for (const e of [
      'taxNumber',
      'name',
      'countryAlpha2Code',
      'IBAN',
      'SWIFT',
    ]) {
      if (!org[e]) {
        errors.push(e);
      }
    }

    return errors;
  }
}
