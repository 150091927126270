import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ToastService } from '../../shared/modules/toast/toast.service';
import { WINDOW } from '../../window.factory';
import { API_VERSION, BASE_URL } from '../base-url';
import {
  LoggerService,
  LoopBackConfig,
  WhiteLabel,
  WhiteLabelApi,
} from '../sdk';
import { ApiService } from './api.service';
import { CacheService } from './cache.service';

declare global {
  interface Window {
    whiteLabel: any;
    HelpCrunch: any;
    Intercom: any;
  }
}

/**
 * TESTING WHITE LABEL
 * Set class property localTesting to name of White Label to test
 * Must match value in name property in list of white labels returned from API
 */

@Injectable({
  providedIn: 'root',
})
export class WhiteLabelService extends ApiService<WhiteLabel> {
  DEFAULT_HOSTNAMES = [
    'app.spaceinvoices.com',
    'localhost',
    'app.getapollo.io',
    'app-test.getapollo.io',
    'app-staging.getapollo.io',
  ];

  lang: string;
  name: string;
  plural: string;
  private localTesting: string; // Name of white label to test ie. 'Snapguest'
  // private localTesting = 'Mesha';

  private _properties: any = {};
  private properties$: ReplaySubject<any> = new ReplaySubject(1);
  readonly properties: Observable<any> = this.properties$
    .asObservable()
    .pipe(startWith({}));

  private _isWhiteLabel = false;
  private isWhiteLabel$: ReplaySubject<boolean> = new ReplaySubject(1);
  readonly isWhiteLabel: Observable<boolean> =
    this.isWhiteLabel$.asObservable();

  private _whiteLabels = [];
  private whiteLabels$: ReplaySubject<WhiteLabel[]> = new ReplaySubject(1);
  readonly whiteLabels: Observable<WhiteLabel[]> =
    this.whiteLabels$.asObservable();
  private interface: any = {};
  private interfaceDefaults: any = {
    sidebar: {
      settings: {
        'dark-mode': {
          hidden: true,
        },
        integrations: {
          hidden: true,
        },
      },
    },
  };

  private updatePropsSubs$: Subscription;

  constructor(
    protected cacheService: CacheService,
    protected log: LoggerService,
    protected toastService: ToastService,
    protected translate: TranslateService,
    private whiteLabelAPI: WhiteLabelApi,
    @Inject(WINDOW) private window: Window,
  ) {
    super(cacheService, log, toastService, translate);
    this.name = 'WhiteLabel';
    this.plural = 'WhiteLabels';

    this.log.log(`${this.name}Service: contructor`);

    LoopBackConfig.setBaseURL(BASE_URL);
    LoopBackConfig.setApiVersion(API_VERSION);

    this.setIsWhiteLabel(window.location.hostname);

    this.whiteLabelAPI.find().subscribe(({ body: wls }) => {
      this._whiteLabels = wls;
      this.whiteLabels$.next(this._whiteLabels);
    });
  }

  setIsWhiteLabel(hostname: string = environment.hostname): void {
    this.setInterface();
    const currentHostname = this.window.location.hostname;

    this._isWhiteLabel = this.DEFAULT_HOSTNAMES.indexOf(hostname) === -1;
    this.isWhiteLabel$.next(this._isWhiteLabel);

    this.log.log(`${this.name}Service: WHITE LABEL?`, this._isWhiteLabel);

    if (
      currentHostname !== hostname
      // Removed as navigating to whitelabel was not working
      // currentHostname !== hostname &&
      // this.DEFAULT_HOSTNAMES.indexOf(currentHostname) === -1
    ) {
      const newUrl = this.window.location.href.replace(
        currentHostname,
        hostname,
      );

      this.log.log('Navigate to hostname:', hostname);

      if (currentHostname !== 'localhost') {
        this.window.open(newUrl, '_self');
        return;
      }
    }

    if (
      currentHostname === 'localhost' &&
      typeof this.window.whiteLabel !== 'undefined'
    ) {
      // Function added on window in index.html
      this.window.whiteLabel(this._properties.name);
    }

    if (this.localTesting) {
      this._isWhiteLabel = true;
      this.isWhiteLabel$.next(this._isWhiteLabel);
      this.window.whiteLabel(this.localTesting);
    }

    // Public page WL domain match but not know properties
    if (
      this._isWhiteLabel &&
      (!this._properties || !this._properties.subscriptionId)
    ) {
      if (this.updatePropsSubs$) {
        // Cancel active subscription and replace with new
        this.updatePropsSubs$.unsubscribe();
      }

      this.updatePropsSubs$ = this.whiteLabels.subscribe((wls) => {
        let wl = wls.find((w) => w.domain === currentHostname);

        if (this.localTesting) {
          wl = wls.find((w) => w.name === this.localTesting);
        }

        this.setProperties(wl);
      });
    }
  }

  getI(path: string): boolean | string {
    // return _.get(this.interface, path);
    return this._isWhiteLabel && _.get(this.interface, path);
  }

  setProperties(props: any = {}): void {
    this._properties = props;

    // Default interface hidden elements
    this.setInterface();

    this.log.log(this._properties);
    this.properties$.next(this._properties);
  }

  private setInterface(): void {
    let data = {};

    if (this._properties && this._properties.interface) {
      data = this._properties.interface;
    }

    this.interface = _.defaultsDeep(data, this.interfaceDefaults);
  }
}
