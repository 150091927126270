import { Injectable } from '@angular/core';
import { LoggerService } from '../sdk';

@Injectable()
export class CacheService {
  cache2: any = {};
  localStorage: any;

  constructor(protected log: LoggerService) {
    // this.clearCache();
  }

  clearCache(persisted = false): void {
    this.cache2 = {};

    if (persisted) {
      try {
        window.localStorage.clear();
      } catch (e) {}
    }
  }

  get(key: string, persisted = false): any {
    this.log.log('CacheService: get');
    const now = new Date().getTime();
    let data: any;

    if (!persisted) {
      this.log.log('CacheService: get !persisted');

      if (this.cache2[key] && this.cache2[key].ttl > now) {
        data = this.cache2[key].data;
      }
    } else {
      this.log.log('CacheService: get localStorage');
      let d: { data: any; ttl: number };
      let value: string;

      try {
        value = window.localStorage.getItem(key);
      } catch (e) {
        value = null;
      }

      if (value) {
        d = JSON.parse(value);
      }

      if (d && d.ttl > now) {
        data = d.data;
      }
    }

    return data;
  }

  set(key: string, data: any, ttl: number, persisted = false): void {
    this.log.log('CacheService: set');
    ttl = new Date().getTime() + ttl;

    if (!persisted) {
      this.log.log('CacheService: set !persisted');

      this.cache2[key] = {
        data,
        ttl,
      };
    } else {
      this.log.log('CacheService: set localStorage');

      try {
        window.localStorage.setItem(
          key,
          JSON.stringify({
            data,
            ttl,
          }),
        );
      } catch (e) {}
    }
  }
}
