/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Client } from '../models/client';

@Injectable({
  providedIn: 'root',
})
export class ClientService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation clientFindById
   */
  static readonly ClientFindByIdPath = '/v1/Clients/{id}';

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientFindById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientFindById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientFindById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientFindById$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientFindById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientFindById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientFindById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientFindById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientFindById$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientFindById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientFindById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientFindById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientFindById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientFindById$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientFindById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Path part for operation clientReplaceByIdPutClientsId
   */
  static readonly ClientReplaceByIdPutClientsIdPath = '/v1/Clients/{id}';

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientReplaceByIdPutClientsId$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientReplaceByIdPutClientsId$Json$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientReplaceByIdPutClientsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientReplaceByIdPutClientsId$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientReplaceByIdPutClientsId$Json$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientReplaceByIdPutClientsId$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientReplaceByIdPutClientsId$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientReplaceByIdPutClientsId$Json$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientReplaceByIdPutClientsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientReplaceByIdPutClientsId$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientReplaceByIdPutClientsId$Json$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientReplaceByIdPutClientsId$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientReplaceByIdPutClientsId$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientReplaceByIdPutClientsId$Json$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientReplaceByIdPutClientsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientReplaceByIdPutClientsId$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientReplaceByIdPutClientsId$Json$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientReplaceByIdPutClientsId$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientReplaceByIdPutClientsId$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientReplaceByIdPutClientsId$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientReplaceByIdPutClientsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientReplaceByIdPutClientsId$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientReplaceByIdPutClientsId$XWwwFormUrlencoded$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientReplaceByIdPutClientsId$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientReplaceByIdPutClientsId$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientReplaceByIdPutClientsId$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientReplaceByIdPutClientsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientReplaceByIdPutClientsId$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientReplaceByIdPutClientsId$XWwwFormUrlencoded$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientReplaceByIdPutClientsId$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientReplaceByIdPutClientsId$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientReplaceByIdPutClientsId$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientReplaceByIdPutClientsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientReplaceByIdPutClientsId$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientReplaceByIdPutClientsId$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientReplaceByIdPutClientsId$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientReplaceByIdPutClientsId$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  clientReplaceByIdPutClientsId$Xml$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientReplaceByIdPutClientsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientReplaceByIdPutClientsId$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  clientReplaceByIdPutClientsId$Xml$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientReplaceByIdPutClientsId$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientReplaceByIdPutClientsId$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  clientReplaceByIdPutClientsId$Xml$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientReplaceByIdPutClientsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientReplaceByIdPutClientsId$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  clientReplaceByIdPutClientsId$Xml$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientReplaceByIdPutClientsId$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientReplaceByIdPutClientsId$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  clientReplaceByIdPutClientsId$Xml$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientReplaceByIdPutClientsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientReplaceByIdPutClientsId$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  clientReplaceByIdPutClientsId$Xml$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientReplaceByIdPutClientsId$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Path part for operation clientDeleteById
   */
  static readonly ClientDeleteByIdPath = '/v1/Clients/{id}';

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientDeleteById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientDeleteById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientDeleteById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientDeleteById$Json(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.clientDeleteById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientDeleteById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientDeleteById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientDeleteById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientDeleteById$Xml(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.clientDeleteById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientDeleteById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientDeleteById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientDeleteById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientDeleteById$Javascript(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.clientDeleteById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation clientPrototypePatchAttributes
   */
  static readonly ClientPrototypePatchAttributesPath = '/v1/Clients/{id}';

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientPrototypePatchAttributes$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientPrototypePatchAttributes$Json$Json$Response(params: {

    /**
     * Client id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientPrototypePatchAttributes$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientPrototypePatchAttributes$Json$Json(params: {

    /**
     * Client id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientPrototypePatchAttributes$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientPrototypePatchAttributes$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientPrototypePatchAttributes$Json$Xml$Response(params: {

    /**
     * Client id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientPrototypePatchAttributes$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientPrototypePatchAttributes$Json$Xml(params: {

    /**
     * Client id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientPrototypePatchAttributes$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientPrototypePatchAttributes$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientPrototypePatchAttributes$Json$Javascript$Response(params: {

    /**
     * Client id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientPrototypePatchAttributes$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientPrototypePatchAttributes$Json$Javascript(params: {

    /**
     * Client id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientPrototypePatchAttributes$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientPrototypePatchAttributes$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Client id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientPrototypePatchAttributes$XWwwFormUrlencoded$Json(params: {

    /**
     * Client id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientPrototypePatchAttributes$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Client id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientPrototypePatchAttributes$XWwwFormUrlencoded$Xml(params: {

    /**
     * Client id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientPrototypePatchAttributes$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Client id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientPrototypePatchAttributes$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Client id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientPrototypePatchAttributes$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  clientPrototypePatchAttributes$Xml$Json$Response(params: {

    /**
     * Client id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientPrototypePatchAttributes$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  clientPrototypePatchAttributes$Xml$Json(params: {

    /**
     * Client id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientPrototypePatchAttributes$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientPrototypePatchAttributes$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  clientPrototypePatchAttributes$Xml$Xml$Response(params: {

    /**
     * Client id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientPrototypePatchAttributes$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  clientPrototypePatchAttributes$Xml$Xml(params: {

    /**
     * Client id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientPrototypePatchAttributes$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientPrototypePatchAttributes$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  clientPrototypePatchAttributes$Xml$Javascript$Response(params: {

    /**
     * Client id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientPrototypePatchAttributes$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  clientPrototypePatchAttributes$Xml$Javascript(params: {

    /**
     * Client id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientPrototypePatchAttributes$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Path part for operation clientReplaceByIdPostClientsIdReplace
   */
  static readonly ClientReplaceByIdPostClientsIdReplacePath = '/v1/Clients/{id}/replace';

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientReplaceByIdPostClientsIdReplace$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientReplaceByIdPostClientsIdReplace$Json$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientReplaceByIdPostClientsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientReplaceByIdPostClientsIdReplace$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientReplaceByIdPostClientsIdReplace$Json$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientReplaceByIdPostClientsIdReplace$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientReplaceByIdPostClientsIdReplace$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientReplaceByIdPostClientsIdReplace$Json$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientReplaceByIdPostClientsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientReplaceByIdPostClientsIdReplace$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientReplaceByIdPostClientsIdReplace$Json$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientReplaceByIdPostClientsIdReplace$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientReplaceByIdPostClientsIdReplace$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientReplaceByIdPostClientsIdReplace$Json$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientReplaceByIdPostClientsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientReplaceByIdPostClientsIdReplace$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientReplaceByIdPostClientsIdReplace$Json$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientReplaceByIdPostClientsIdReplace$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientReplaceByIdPostClientsIdReplace$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientReplaceByIdPostClientsIdReplace$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientReplaceByIdPostClientsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientReplaceByIdPostClientsIdReplace$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientReplaceByIdPostClientsIdReplace$XWwwFormUrlencoded$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientReplaceByIdPostClientsIdReplace$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientReplaceByIdPostClientsIdReplace$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientReplaceByIdPostClientsIdReplace$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientReplaceByIdPostClientsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientReplaceByIdPostClientsIdReplace$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientReplaceByIdPostClientsIdReplace$XWwwFormUrlencoded$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientReplaceByIdPostClientsIdReplace$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientReplaceByIdPostClientsIdReplace$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientReplaceByIdPostClientsIdReplace$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientReplaceByIdPostClientsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientReplaceByIdPostClientsIdReplace$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientReplaceByIdPostClientsIdReplace$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientReplaceByIdPostClientsIdReplace$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientReplaceByIdPostClientsIdReplace$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  clientReplaceByIdPostClientsIdReplace$Xml$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientReplaceByIdPostClientsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientReplaceByIdPostClientsIdReplace$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  clientReplaceByIdPostClientsIdReplace$Xml$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientReplaceByIdPostClientsIdReplace$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientReplaceByIdPostClientsIdReplace$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  clientReplaceByIdPostClientsIdReplace$Xml$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientReplaceByIdPostClientsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientReplaceByIdPostClientsIdReplace$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  clientReplaceByIdPostClientsIdReplace$Xml$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientReplaceByIdPostClientsIdReplace$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientReplaceByIdPostClientsIdReplace$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  clientReplaceByIdPostClientsIdReplace$Xml$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientReplaceByIdPostClientsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientReplaceByIdPostClientsIdReplace$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  clientReplaceByIdPostClientsIdReplace$Xml$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Client
  },
  context?: HttpContext

): Observable<Client> {

    return this.clientReplaceByIdPostClientsIdReplace$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Path part for operation clientPrototypeRemoteSendOverdueReminder
   */
  static readonly ClientPrototypeRemoteSendOverdueReminderPath = '/v1/Clients/{id}/send-reminder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientPrototypeRemoteSendOverdueReminder$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientPrototypeRemoteSendOverdueReminder$Json$Response(params: {

    /**
     * Client id
     */
    id: string;
    body?: {
'email'?: string;
'l'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientPrototypeRemoteSendOverdueReminderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientPrototypeRemoteSendOverdueReminder$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientPrototypeRemoteSendOverdueReminder$Json(params: {

    /**
     * Client id
     */
    id: string;
    body?: {
'email'?: string;
'l'?: string;
}
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.clientPrototypeRemoteSendOverdueReminder$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientPrototypeRemoteSendOverdueReminder$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientPrototypeRemoteSendOverdueReminder$Xml$Response(params: {

    /**
     * Client id
     */
    id: string;
    body?: {
'email'?: string;
'l'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientPrototypeRemoteSendOverdueReminderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientPrototypeRemoteSendOverdueReminder$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientPrototypeRemoteSendOverdueReminder$Xml(params: {

    /**
     * Client id
     */
    id: string;
    body?: {
'email'?: string;
'l'?: string;
}
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.clientPrototypeRemoteSendOverdueReminder$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientPrototypeRemoteSendOverdueReminder$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientPrototypeRemoteSendOverdueReminder$Javascript$Response(params: {

    /**
     * Client id
     */
    id: string;
    body?: {
'email'?: string;
'l'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ClientService.ClientPrototypeRemoteSendOverdueReminderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientPrototypeRemoteSendOverdueReminder$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  clientPrototypeRemoteSendOverdueReminder$Javascript(params: {

    /**
     * Client id
     */
    id: string;
    body?: {
'email'?: string;
'l'?: string;
}
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.clientPrototypeRemoteSendOverdueReminder$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

}
