/* tslint:disable */
import {
  Organization
} from '../index';

declare var Object: any;
export interface CertificateInterface {
  "type": string;
  "file"?: string;
  "data"?: string;
  "passphrase": string;
  "validTill"?: Date;
  "environment": string;
  "id"?: any;
  "organizationId"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  organization?: Organization;
}

export class Certificate implements CertificateInterface {
  "type": string;
  "file": string;
  "data": string;
  "passphrase": string;
  "validTill": Date;
  "environment": string;
  "id": any;
  "organizationId": any;
  "createdAt": Date;
  "updatedAt": Date;
  organization: Organization;
  constructor(data?: CertificateInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Certificate`.
   */
  public static getModelName() {
    return "Certificate";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Certificate for dynamic purposes.
  **/
  public static factory(data: CertificateInterface): Certificate{
    return new Certificate(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Certificate',
      plural: 'Certificates',
      path: 'Certificates',
      idName: 'id',
      properties: {
        "type": {
          name: 'type',
          type: 'string'
        },
        "file": {
          name: 'file',
          type: 'string'
        },
        "data": {
          name: 'data',
          type: 'string'
        },
        "passphrase": {
          name: 'passphrase',
          type: 'string'
        },
        "validTill": {
          name: 'validTill',
          type: 'Date'
        },
        "environment": {
          name: 'environment',
          type: 'string',
          default: 'production'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "organizationId": {
          name: 'organizationId',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
      },
      relations: {
        organization: {
          name: 'organization',
          type: 'Organization',
          model: 'Organization',
          relationType: 'belongsTo',
                  keyFrom: 'organizationId',
          keyTo: 'id'
        },
      }
    }
  }
}
