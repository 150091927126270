import { Injectable } from '@angular/core';
import { WhiteLabelService } from './whitelabel.service';
import { pluck, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject } from 'rxjs';
import { LanguageService } from './language.service';
import { Title } from '@angular/platform-browser';

@Injectable()
export class TitleService {
  appName: string;
  _pageName: string[];
  private pageName$: ReplaySubject<string[]> = new ReplaySubject(1);
  readonly pageName: Observable<string[]> = this.pageName$.asObservable();

  constructor(
    private languageService: LanguageService,
    private title: Title,
    private translate: TranslateService,
    private whiteLabelService: WhiteLabelService,
  ) {
    this.whiteLabelService.properties
      .pipe(
        pluck('name'),
        switchMap((name) => {
          this.appName = name || 'Apollo';
          return this.languageService.current;
        }),
        switchMap(() => {
          return this.pageName;
        }),
        switchMap((pageName) => {
          return this.translate.get([this.appName, ...pageName]);
        }),
      )
      .subscribe((res) => {
        let t = res[this.appName];

        for (const p of this._pageName) {
          if (p) {
            t += ` | ${res[p]}`;
          }
        }

        this.title.setTitle(t);
      });
  }

  set(pageName: string[]) {
    this._pageName = pageName;
    this.pageName$.next(this._pageName);
  }
}
