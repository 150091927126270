import { Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

type ToastClass = 'warning' | 'error';
export interface CustomToastMessage {
  /**
   * A string which matches against the error message
   */
  compare: string;
  /**
   * Translation key to use if a match is found
   */
  titleTranslationKey: string;
  /**
   * Translation key to use if a match is found
   */
  messageTranslationKey: string;
  /**
   * The class of toast to use to display the toast:
   * @example 'warning'
   */
  toastClass: ToastClass;
  /**
   * If isRegex is set to true, the key is matched against the error message as a regex
   */
  isRegex?: boolean;
}

export interface RemappedErrorsToast {
  err: any;
  options: {
    defaultMessageKey?: string;
    defaultTitleKey?: string;
    customToastMessages?: CustomToastMessage[];
  };
}

export interface ToastOptions {
  delay?: number;
  class?: string;
}

export interface Toast {
  header: string;
  body: string;
  options?: ToastOptions;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts$: Subject<Toast[]> = new Subject();
  defaultDelay = 7500;
  defaultOptions: ToastOptions = { delay: this.defaultDelay, class: 'primary' };
  private toasts: Toast[] = [];

  constructor(private translateService: TranslateService) {}

  remove(toast: Toast): void {
    this.toasts = this.toasts.filter((t) => t !== toast);
    this.toasts$.next(this.toasts);
  }

  error(
    body: string,
    header = this.getDefaultHeader('error'),
    options: ToastOptions = {},
  ): void {
    options = Object.assign(options, { class: 'danger' });
    this.show(body, header, options);
  }

  info(
    body: string,
    header = this.getDefaultHeader('info'),
    options: ToastOptions = {},
  ): void {
    options = Object.assign(options, { class: 'info' });
    this.show(body, header, options);
  }

  show(
    body: string,
    header = this.getDefaultHeader('primary'),
    options: ToastOptions = {},
  ): void {
    options = Object.assign({}, this.defaultOptions, options);
    const toast = { header, body, options };
    this.toasts = [...this.toasts, toast];
    this.toasts$.next(this.toasts);
  }

  success(
    body: string,
    header = this.getDefaultHeader('success'),
    options: ToastOptions = {},
  ): void {
    options = Object.assign(options, { class: 'success' });
    this.show(body, header, options);
  }

  warning(
    body: string,
    header = this.getDefaultHeader('warning'),
    options: ToastOptions = {},
  ): void {
    options = Object.assign(options, { class: 'warning' });
    this.show(body, header, options);
  }

  getDefaultHeader(type: string): string {
    return this.translateService.instant(`toast.default-header.${type}`);
  }

  /**
   * Checks if a specific error message/toast should be shown for an API error
   * and displays it otherwise falls back to default error/warning toast
   * @param err The error object
   * @param defaultTitleKey Default title translation key
   * @param defaultMessageKey Default message translation key
   * @param customToastMessages Config for matching and displaying custom errors
   * @private
   */
  public remappedErrorsToast(
    err: any,
    {
      defaultMessageKey,
      defaultTitleKey,
      customToastMessages,
    }: RemappedErrorsToast['options'],
  ): void {
    // Iterate over custom messages

    if (customToastMessages?.length) {
      for (const customToastMessage of customToastMessages) {
        // Toast message and class

        const message = this.translateService.instant(
          customToastMessage.messageTranslationKey,
        );
        const title = customToastMessage.titleTranslationKey
          ? this.translateService.instant(
              customToastMessage.titleTranslationKey,
            )
          : undefined;

        if (customToastMessage?.isRegex) {
          // Match the message as a regex
          if (err?.message.match(customToastMessage.compare)[0]) {
            this[customToastMessage.toastClass || 'error'](message, title);
            return;
          }
        } else {
          // Compare messages a strings
          if (err?.message === customToastMessage.compare) {
            this[customToastMessage.toastClass || 'error'](message, title);
            return;
          }
        }
      }
    }

    // If there were no custom messages or messages did not match,
    // fallback to default message if present
    if (defaultMessageKey) {
      const status = err?.error ? err?.error?.statusCode : null;

      this[status === 422 ? 'warning' : 'error'](
        this.translateService.instant(defaultMessageKey),
        defaultTitleKey
          ? this.translateService.instant(defaultTitleKey)
          : undefined,
      );
    }
  }
}
