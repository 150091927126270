/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AtcudSeries } from '../models/atcud-series';
import { Organization } from '../models/organization';
import { NewAtcudSeries } from '../models/new-atcud-series';

@Injectable({
  providedIn: 'root',
})
export class AtcudSeriesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation atcudSeriesPrototypeGetOrganization
   */
  static readonly AtcudSeriesPrototypeGetOrganizationPath = '/v1/AtcudSeries/{id}/organization';

  /**
   * Fetches belongsTo relation organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesPrototypeGetOrganization$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesPrototypeGetOrganization$Json$Response(params: {

    /**
     * AtcudSeries id
     */
    id: string;
    refresh?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesPrototypeGetOrganizationPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('refresh', params.refresh, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Fetches belongsTo relation organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesPrototypeGetOrganization$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesPrototypeGetOrganization$Json(params: {

    /**
     * AtcudSeries id
     */
    id: string;
    refresh?: boolean;
  },
  context?: HttpContext

): Observable<Organization> {

    return this.atcudSeriesPrototypeGetOrganization$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Fetches belongsTo relation organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesPrototypeGetOrganization$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesPrototypeGetOrganization$Xml$Response(params: {

    /**
     * AtcudSeries id
     */
    id: string;
    refresh?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesPrototypeGetOrganizationPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('refresh', params.refresh, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Fetches belongsTo relation organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesPrototypeGetOrganization$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesPrototypeGetOrganization$Xml(params: {

    /**
     * AtcudSeries id
     */
    id: string;
    refresh?: boolean;
  },
  context?: HttpContext

): Observable<Organization> {

    return this.atcudSeriesPrototypeGetOrganization$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Fetches belongsTo relation organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesPrototypeGetOrganization$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesPrototypeGetOrganization$Javascript$Response(params: {

    /**
     * AtcudSeries id
     */
    id: string;
    refresh?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesPrototypeGetOrganizationPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('refresh', params.refresh, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Fetches belongsTo relation organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesPrototypeGetOrganization$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesPrototypeGetOrganization$Javascript(params: {

    /**
     * AtcudSeries id
     */
    id: string;
    refresh?: boolean;
  },
  context?: HttpContext

): Observable<Organization> {

    return this.atcudSeriesPrototypeGetOrganization$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Path part for operation atcudSeriesFind
   */
  static readonly AtcudSeriesFindPath = '/v1/AtcudSeries';

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesFind$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesFind$Json$Response(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AtcudSeries>>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AtcudSeries>>;
      })
    );
  }

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesFind$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesFind$Json(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<AtcudSeries>> {

    return this.atcudSeriesFind$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AtcudSeries>>) => r.body as Array<AtcudSeries>)
    );
  }

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesFind$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesFind$Xml$Response(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AtcudSeries>>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AtcudSeries>>;
      })
    );
  }

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesFind$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesFind$Xml(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<AtcudSeries>> {

    return this.atcudSeriesFind$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AtcudSeries>>) => r.body as Array<AtcudSeries>)
    );
  }

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesFind$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesFind$Javascript$Response(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AtcudSeries>>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AtcudSeries>>;
      })
    );
  }

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesFind$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesFind$Javascript(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<AtcudSeries>> {

    return this.atcudSeriesFind$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AtcudSeries>>) => r.body as Array<AtcudSeries>)
    );
  }

  /**
   * Path part for operation atcudSeriesCreate
   */
  static readonly AtcudSeriesCreatePath = '/v1/AtcudSeries';

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesCreate$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesCreate$Json$Json$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewAtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesCreate$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesCreate$Json$Json(params?: {

    /**
     * Model instance data
     */
    body?: NewAtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesCreate$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesCreate$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesCreate$Json$Xml$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewAtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesCreate$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesCreate$Json$Xml(params?: {

    /**
     * Model instance data
     */
    body?: NewAtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesCreate$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesCreate$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesCreate$Json$Javascript$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewAtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesCreate$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesCreate$Json$Javascript(params?: {

    /**
     * Model instance data
     */
    body?: NewAtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesCreate$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesCreate$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesCreate$XWwwFormUrlencoded$Json$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewAtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesCreate$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesCreate$XWwwFormUrlencoded$Json(params?: {

    /**
     * Model instance data
     */
    body?: NewAtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesCreate$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesCreate$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesCreate$XWwwFormUrlencoded$Xml$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewAtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesCreate$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesCreate$XWwwFormUrlencoded$Xml(params?: {

    /**
     * Model instance data
     */
    body?: NewAtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesCreate$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesCreate$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesCreate$XWwwFormUrlencoded$Javascript$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewAtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesCreate$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesCreate$XWwwFormUrlencoded$Javascript(params?: {

    /**
     * Model instance data
     */
    body?: NewAtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesCreate$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesCreate$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesCreate$Xml$Json$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewAtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesCreate$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesCreate$Xml$Json(params?: {

    /**
     * Model instance data
     */
    body?: NewAtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesCreate$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesCreate$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesCreate$Xml$Xml$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewAtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesCreate$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesCreate$Xml$Xml(params?: {

    /**
     * Model instance data
     */
    body?: NewAtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesCreate$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesCreate$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesCreate$Xml$Javascript$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewAtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesCreate$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesCreate$Xml$Javascript(params?: {

    /**
     * Model instance data
     */
    body?: NewAtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesCreate$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Path part for operation atcudSeriesFindById
   */
  static readonly AtcudSeriesFindByIdPath = '/v1/AtcudSeries/{id}';

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesFindById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesFindById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesFindById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesFindById$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesFindById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesFindById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesFindById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesFindById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesFindById$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesFindById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesFindById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesFindById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesFindById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesFindById$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesFindById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Path part for operation atcudSeriesReplaceByIdPutAtcudSeriesId
   */
  static readonly AtcudSeriesReplaceByIdPutAtcudSeriesIdPath = '/v1/AtcudSeries/{id}';

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesReplaceByIdPutAtcudSeriesId$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesReplaceByIdPutAtcudSeriesId$Json$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesReplaceByIdPutAtcudSeriesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesReplaceByIdPutAtcudSeriesId$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesReplaceByIdPutAtcudSeriesId$Json$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesReplaceByIdPutAtcudSeriesId$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesReplaceByIdPutAtcudSeriesId$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesReplaceByIdPutAtcudSeriesId$Json$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesReplaceByIdPutAtcudSeriesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesReplaceByIdPutAtcudSeriesId$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesReplaceByIdPutAtcudSeriesId$Json$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesReplaceByIdPutAtcudSeriesId$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesReplaceByIdPutAtcudSeriesId$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesReplaceByIdPutAtcudSeriesId$Json$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesReplaceByIdPutAtcudSeriesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesReplaceByIdPutAtcudSeriesId$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesReplaceByIdPutAtcudSeriesId$Json$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesReplaceByIdPutAtcudSeriesId$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesReplaceByIdPutAtcudSeriesId$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesReplaceByIdPutAtcudSeriesId$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesReplaceByIdPutAtcudSeriesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesReplaceByIdPutAtcudSeriesId$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesReplaceByIdPutAtcudSeriesId$XWwwFormUrlencoded$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesReplaceByIdPutAtcudSeriesId$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesReplaceByIdPutAtcudSeriesId$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesReplaceByIdPutAtcudSeriesId$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesReplaceByIdPutAtcudSeriesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesReplaceByIdPutAtcudSeriesId$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesReplaceByIdPutAtcudSeriesId$XWwwFormUrlencoded$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesReplaceByIdPutAtcudSeriesId$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesReplaceByIdPutAtcudSeriesId$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesReplaceByIdPutAtcudSeriesId$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesReplaceByIdPutAtcudSeriesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesReplaceByIdPutAtcudSeriesId$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesReplaceByIdPutAtcudSeriesId$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesReplaceByIdPutAtcudSeriesId$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesReplaceByIdPutAtcudSeriesId$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesReplaceByIdPutAtcudSeriesId$Xml$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesReplaceByIdPutAtcudSeriesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesReplaceByIdPutAtcudSeriesId$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesReplaceByIdPutAtcudSeriesId$Xml$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesReplaceByIdPutAtcudSeriesId$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesReplaceByIdPutAtcudSeriesId$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesReplaceByIdPutAtcudSeriesId$Xml$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesReplaceByIdPutAtcudSeriesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesReplaceByIdPutAtcudSeriesId$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesReplaceByIdPutAtcudSeriesId$Xml$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesReplaceByIdPutAtcudSeriesId$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesReplaceByIdPutAtcudSeriesId$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesReplaceByIdPutAtcudSeriesId$Xml$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesReplaceByIdPutAtcudSeriesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesReplaceByIdPutAtcudSeriesId$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesReplaceByIdPutAtcudSeriesId$Xml$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesReplaceByIdPutAtcudSeriesId$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Path part for operation atcudSeriesDeleteById
   */
  static readonly AtcudSeriesDeleteByIdPath = '/v1/AtcudSeries/{id}';

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesDeleteById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesDeleteById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesDeleteById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesDeleteById$Json(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.atcudSeriesDeleteById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesDeleteById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesDeleteById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesDeleteById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesDeleteById$Xml(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.atcudSeriesDeleteById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesDeleteById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesDeleteById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesDeleteById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesDeleteById$Javascript(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.atcudSeriesDeleteById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation atcudSeriesPrototypePatchAttributes
   */
  static readonly AtcudSeriesPrototypePatchAttributesPath = '/v1/AtcudSeries/{id}';

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesPrototypePatchAttributes$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesPrototypePatchAttributes$Json$Json$Response(params: {

    /**
     * AtcudSeries id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesPrototypePatchAttributes$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesPrototypePatchAttributes$Json$Json(params: {

    /**
     * AtcudSeries id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesPrototypePatchAttributes$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesPrototypePatchAttributes$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesPrototypePatchAttributes$Json$Xml$Response(params: {

    /**
     * AtcudSeries id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesPrototypePatchAttributes$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesPrototypePatchAttributes$Json$Xml(params: {

    /**
     * AtcudSeries id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesPrototypePatchAttributes$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesPrototypePatchAttributes$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesPrototypePatchAttributes$Json$Javascript$Response(params: {

    /**
     * AtcudSeries id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesPrototypePatchAttributes$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesPrototypePatchAttributes$Json$Javascript(params: {

    /**
     * AtcudSeries id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesPrototypePatchAttributes$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesPrototypePatchAttributes$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * AtcudSeries id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesPrototypePatchAttributes$XWwwFormUrlencoded$Json(params: {

    /**
     * AtcudSeries id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesPrototypePatchAttributes$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * AtcudSeries id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesPrototypePatchAttributes$XWwwFormUrlencoded$Xml(params: {

    /**
     * AtcudSeries id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesPrototypePatchAttributes$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * AtcudSeries id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesPrototypePatchAttributes$XWwwFormUrlencoded$Javascript(params: {

    /**
     * AtcudSeries id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesPrototypePatchAttributes$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesPrototypePatchAttributes$Xml$Json$Response(params: {

    /**
     * AtcudSeries id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesPrototypePatchAttributes$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesPrototypePatchAttributes$Xml$Json(params: {

    /**
     * AtcudSeries id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesPrototypePatchAttributes$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesPrototypePatchAttributes$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesPrototypePatchAttributes$Xml$Xml$Response(params: {

    /**
     * AtcudSeries id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesPrototypePatchAttributes$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesPrototypePatchAttributes$Xml$Xml(params: {

    /**
     * AtcudSeries id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesPrototypePatchAttributes$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesPrototypePatchAttributes$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesPrototypePatchAttributes$Xml$Javascript$Response(params: {

    /**
     * AtcudSeries id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesPrototypePatchAttributes$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesPrototypePatchAttributes$Xml$Javascript(params: {

    /**
     * AtcudSeries id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesPrototypePatchAttributes$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Path part for operation atcudSeriesReplaceByIdPostAtcudSeriesIdReplace
   */
  static readonly AtcudSeriesReplaceByIdPostAtcudSeriesIdReplacePath = '/v1/AtcudSeries/{id}/replace';

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Json$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesReplaceByIdPostAtcudSeriesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Json$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Json$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesReplaceByIdPostAtcudSeriesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Json$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Json$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesReplaceByIdPostAtcudSeriesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Json$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesReplaceByIdPostAtcudSeriesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$XWwwFormUrlencoded$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesReplaceByIdPostAtcudSeriesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$XWwwFormUrlencoded$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesReplaceByIdPostAtcudSeriesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Xml$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesReplaceByIdPostAtcudSeriesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Xml$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Xml$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesReplaceByIdPostAtcudSeriesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Xml$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Xml$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesReplaceByIdPostAtcudSeriesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Xml$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: AtcudSeries
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesReplaceByIdPostAtcudSeriesIdReplace$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Path part for operation atcudSeriesFindOne
   */
  static readonly AtcudSeriesFindOnePath = '/v1/AtcudSeries/findOne';

  /**
   * Find first instance of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesFindOne$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesFindOne$Json$Response(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesFindOnePath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Find first instance of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesFindOne$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesFindOne$Json(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesFindOne$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Find first instance of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesFindOne$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesFindOne$Xml$Response(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesFindOnePath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Find first instance of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesFindOne$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesFindOne$Xml(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesFindOne$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Find first instance of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesFindOne$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesFindOne$Javascript$Response(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AtcudSeries>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesFindOnePath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AtcudSeries>;
      })
    );
  }

  /**
   * Find first instance of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesFindOne$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesFindOne$Javascript(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<AtcudSeries> {

    return this.atcudSeriesFindOne$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<AtcudSeries>) => r.body as AtcudSeries)
    );
  }

  /**
   * Path part for operation atcudSeriesCount
   */
  static readonly AtcudSeriesCountPath = '/v1/AtcudSeries/count';

  /**
   * Count instances of the model matched by where from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesCount$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesCount$Json$Response(params?: {

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Count instances of the model matched by where from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesCount$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesCount$Json(params?: {

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.atcudSeriesCount$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Count instances of the model matched by where from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesCount$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesCount$Xml$Response(params?: {

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Count instances of the model matched by where from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesCount$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesCount$Xml(params?: {

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.atcudSeriesCount$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Count instances of the model matched by where from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `atcudSeriesCount$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesCount$Javascript$Response(params?: {

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AtcudSeriesService.AtcudSeriesCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Count instances of the model matched by where from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `atcudSeriesCount$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  atcudSeriesCount$Javascript(params?: {

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.atcudSeriesCount$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

}
