/* tslint:disable */

declare var Object: any;
export interface CurrencyInterface {
  "isoName": string;
  "name": string;
  "namePlural"?: string;
  "symbol"?: string;
  "symbolNative"?: string;
  "decimalDigits"?: number;
  "type": string;
}

export class Currency implements CurrencyInterface {
  "isoName": string;
  "name": string;
  "namePlural": string;
  "symbol": string;
  "symbolNative": string;
  "decimalDigits": number;
  "type": string;
  constructor(data?: CurrencyInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Currency`.
   */
  public static getModelName() {
    return "Currency";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Currency for dynamic purposes.
  **/
  public static factory(data: CurrencyInterface): Currency{
    return new Currency(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Currency',
      plural: 'Currencies',
      path: 'Currencies',
      idName: 'isoName',
      properties: {
        "isoName": {
          name: 'isoName',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "namePlural": {
          name: 'namePlural',
          type: 'string'
        },
        "symbol": {
          name: 'symbol',
          type: 'string'
        },
        "symbolNative": {
          name: 'symbolNative',
          type: 'string'
        },
        "decimalDigits": {
          name: 'decimalDigits',
          type: 'number',
          default: 2
        },
        "type": {
          name: 'type',
          type: 'string'
        },
      },
      relations: {
      }
    }
  }
}
