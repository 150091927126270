import { Injectable } from '@angular/core';
import { LoopBackConfig, LoggerService } from '../../shared/sdk';
import {
  DocumentCategoryApi,
  OrganizationApi,
  DocumentApi,
} from '../../shared/sdk/services';
import { DocumentCategory } from '../../shared/sdk/models';
import { TranslateService } from '@ngx-translate/core';
import { BASE_URL, API_VERSION } from '../../shared/base-url';
import { ApiService } from '../../shared/services/api.service';
import { CacheService } from '../../shared/services/cache.service';
import { Observable } from 'rxjs';
import { ToastService } from '../../shared/modules/toast/toast.service';

@Injectable()
export class DocumentCategoryService extends ApiService<DocumentCategory> {
  constructor(
    protected cacheService: CacheService,
    protected log: LoggerService,
    protected toastService: ToastService,
    protected translate: TranslateService,
    private documentCategoryApi: DocumentCategoryApi,
    private documentApi: DocumentApi,
    private orgApi: OrganizationApi,
  ) {
    super(cacheService, log, toastService, translate);
    this.name = 'DocumentCategory';
    this.plural = 'DocumentCategories';

    LoopBackConfig.setBaseURL(BASE_URL);
    LoopBackConfig.setApiVersion(API_VERSION);
  }

  count(
    orgId: string,
    filter: any = {},
    force = false,
    toastr = true,
  ): Observable<number> {
    return super.count(orgId, filter, force, toastr, this.orgApi);
  }

  create(
    docId: string,
    data: any,
    toastr = true,
  ): Observable<DocumentCategory> {
    return super.create(docId, data, toastr, this.documentApi);
  }

  delete(id: string, toastr = true): Observable<boolean> {
    return super.delete(id, toastr, this.documentCategoryApi);
  }

  get(
    orgId: string,
    filter: any = {},
    force = false,
    toastr = true,
  ): Observable<{ body: DocumentCategory[]; totalCount: number }> {
    return super.get(orgId, filter, force, toastr, this.orgApi);
  }

  getById(
    id: string,
    filter = {},
    force = true,
    toastr = true,
  ): Observable<DocumentCategory> {
    return super.getById(id, filter, force, toastr, this.documentCategoryApi);
  }

  update(id: string, data: any, toastr = true): Observable<DocumentCategory> {
    return super.update(id, data, toastr, this.documentCategoryApi);
  }
}
