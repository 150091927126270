import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { Integrations } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: 'https://60d1c78f2e50458180cd8998bd898e2b@sentry.io/1281951',
  release: `apollo-web-app@${environment.version}`,
  environment: environment.name,
  enabled: environment.name !== 'development',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [
        'localhost',
        'https://api-test.spaceinvoices.com',
        'https://api.spaceinvoices.com',
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: environment.production ? 0.1 : 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
