import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Decimal from 'decimal.js/decimal.js';
import 'file-saver';
import {
  RemappedErrorsToast,
  ToastService,
} from '../shared/modules/toast/toast.service';
import { LoggerService, LoopBackConfig } from '../shared/sdk';
import {
  AccessToken,
  Default,
  Document,
  Organization,
  Payment,
} from '../shared/sdk/models';
import {
  AccountApi,
  DocumentApi,
  FileMetaApi,
  OrganizationApi,
} from '../shared/sdk/services';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import * as qs from 'qs';
import { forkJoin, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { API_VERSION, BASE_URL } from '../shared/base-url';
import { ApiService } from '../shared/services/api.service';
import { CacheService } from '../shared/services/cache.service';
import { FileUploadService } from '../shared/services/file-upload.service';
import { SEPAService } from '../shared/services/sepa.service';
import { ThemeService } from '../shared/services/theme.service';
Decimal.set({ precision: 13, rounding: 4 });
// const Decimal5 = Decimal.clone({ precision: 5, rounding: 4 }); // tslint:disable-line

@Injectable()
export class DocumentService extends ApiService<Document> {
  _documentTypes: any = {};
  can = {
    delete: ['estimate', 'delivery-note', 'purchase-order', 'write-off'],
    void: ['invoice', 'credit-note', 'advance'],
  };
  has = {
    payment: ['invoice', 'credit-note', 'advance'],
    dateService: ['invoice', 'credit-note'],
    dateServiceTo: ['invoice', 'credit-note'],
    dateDue: ['invoice', 'credit-note'],
    dateValidTill: ['estimate'],
    datePaid: ['advance'],
    furs: ['invoice', 'credit-note', 'advance'],
    categorization: ['invoice'],
  };
  private documentTypes$: ReplaySubject<any> = new ReplaySubject(1);
  readonly documentTypes: Observable<any> = this.documentTypes$.asObservable();
  private _numInvoicesThisMonth: number;
  private numInvoicesThisMonth$: ReplaySubject<number> = new ReplaySubject(1);
  readonly numInvoicesThisMonth: Observable<number> =
    this.numInvoicesThisMonth$.asObservable();
  private now = moment.utc().startOf('day');

  private createUpdateDocumentErrorToastOptions: RemappedErrorsToast['options'] =
    {
      defaultMessageKey:
        'documents.outgoing.document-add.validation-error.message',
      defaultTitleKey: 'documents.outgoing.document-add.validation-error.title',
      customToastMessages: [
        {
          compare:
            'instance.InvoiceRequest.Invoice.TaxNumber must have a minimum value of 10000000',
          titleTranslationKey: 'warning',
          messageTranslationKey:
            'documents.outgoing.document-add.validation-error.furs-tax-number',
          toastClass: 'warning',
        },
        {
          compare: 'Business premise missing on FURS server.',
          titleTranslationKey: 'warning',
          messageTranslationKey:
            'documents.outgoing.document-add.validation-error.furs-business-premise-missing',
          toastClass: 'warning',
        },
        {
          compare:
            'Tax number of issuer does not match tax number on certificate.',
          titleTranslationKey: 'warning',
          messageTranslationKey:
            'documents.outgoing.document-add.validation-error.certificate-tax-number-mismatch',
          toastClass: 'warning',
        },
      ],
    };

  constructor(
    public accountApi: AccountApi,
    public documentApi: DocumentApi,
    public fileMetaApi: FileMetaApi,
    public http: HttpClient,
    protected cacheService: CacheService,
    protected log: LoggerService,
    protected toastService: ToastService,
    protected translate: TranslateService,
    private toastr: ToastrService,
    private fileUploadService: FileUploadService,
    private orgApi: OrganizationApi,
    private SEPAService: SEPAService,
    private themeService: ThemeService,
  ) {
    super(cacheService, log, toastService, translate);

    this.name = 'Document';
    this.plural = 'Documents';

    LoopBackConfig.setBaseURL(BASE_URL);
    LoopBackConfig.setApiVersion(API_VERSION);

    this.translate
      .stream([
        'invoice',
        'invoices',
        'estimate',
        'quote',
        'estimates',
        'advance',
        'advances',
        'credit note',
        'credit notes',
        'delivery note',
        'delivery notes',
        'purchase order',
        'purchase orders',
        'write-off',
        'write-offs',
        'warehouse transfer',
        'warehouse transfers',
      ])
      .subscribe((t) => {
        this.log.log('DocumentService: constructor: documentTypes stream');

        this._documentTypes = {
          invoice: {
            name: t.invoice,
            plural: t.invoices,
          },
          estimate: {
            name: t.estimate,
            plural: t.estimates,
          },
          quote: {
            name: t.quote,
          },
          advance: {
            name: t.advance,
            plural: t.advances,
          },
          'credit-note': {
            name: t['credit note'],
            plural: t['credit notes'],
          },
          'delivery-note': {
            name: t['delivery note'],
            plural: t['delivery notes'],
          },
          'purchase-order': {
            name: t['purchase order'],
            plural: t['purchase orders'],
          },
          'write-off': {
            name: t['write-off'],
            plural: t['write-offs'],
          },
          'warehouse-transfer': {
            name: t['warehouse transfer'],
            plural: t['warehouse transfers'],
          },
        };

        this.documentTypes$.next(this._documentTypes);
      });
  }

  cancel(id: string): Observable<boolean> {
    return this.documentApi.cancel(id).pipe(
      map(() => {
        this.toastService.success(
          this.translate.instant('Document successfully canceled.'),
        );

        return true;
      }),
      catchError((err) => {
        this.toastService.error(
          this.translate.instant(
            'Error canceling document, please try again or contact support.',
          ),
        );

        return throwError(err);
      }),
    );
  }

  count(
    orgId: string,
    where: any = {},
    force = false,
    toastr = true,
  ): Observable<number> {
    return super.count(orgId, where, force, toastr, this.orgApi);
  }

  create(orgId: string, data: Document, toastr = true): Observable<Document> {
    return super.create(
      orgId,
      data,
      toastr,
      this.orgApi,
      true,
      this.createUpdateDocumentErrorToastOptions,
    );
  }

  createComment(id: string, data: any): Observable<any> {
    return this.documentApi
      .createComments(id, data)
      .pipe(map(({ body }) => body));
  }

  // Additionally verify document that has failed verification
  fursVerify(id: string, provider = 'furs', toast = true): Observable<any> {
    return this.documentApi.remoteVerify(id, provider).pipe(
      map(({ body }) => body),
      tap(() => {
        if (toast) {
          this.toastService.success(
            this.translate.instant(
              'documents.document-service.fursVerify.toast.success',
            ),
          );
        }
      }),
      catchError((err) => {
        if (toast) {
          this.toastService.error(
            this.translate.instant(
              'documents.document-service.fursVerify.toast.error',
            ),
          );
        }

        return throwError(err);
      }),
    );
  }

  updateComment(id: string, commentId: string, data: any): Observable<any> {
    return this.documentApi
      .updateByIdComments(id, commentId, data)
      .pipe(map(({ body }) => body));
  }

  deleteComment(id: string, commentId: string): Observable<any> {
    return this.documentApi
      .destroyByIdComments(id, commentId)
      .pipe(map(({ body }) => body));
  }

  delete(id: string, toastr = true): Observable<boolean> {
    return super.delete(id, toastr, this.documentApi);
  }

  /**
   * Create a request to download a file
   */
  download(url: string): Observable<Blob> {
    const token = this.accountApi.getCurrentToken();
    let headers: HttpHeaders;

    if (token && token.id) {
      headers = new HttpHeaders({ Authorization: token.id });
    }

    return this.http.get(url, {
      responseType: 'blob',
      headers,
    });
  }

  openEslog(document: Document): string {
    return this.getDocumentLink({ id: document.id, type: 'e-slog' });
  }

  downloadFile({
    document,
    type,
    ext,
    lang,
    UPNQR,
    toastr,
    options,
  }: {
    document: Document;
    type: string;
    ext: string;
    lang?: string;
    UPNQR?: boolean;
    toastr?: boolean;
    options?: Record<string, string>;
  }): Observable<Blob> {
    let toast: any;
    let token: string;

    if (typeof toastr === 'undefined') {
      // Default toastr to true
      toastr = true;
    }

    if (toastr) {
      toast = this.toastService.info(
        this.translate.instant('Loading file, please wait.'),
      );
    }

    if (document.id.indexOf('sid_') === -1) {
      const currentToken = this.accountApi.getCurrentToken();
      token = currentToken.id;
    }

    return this.download(
      this.getDocumentLink({
        id: document.id,
        type,
        lang,
        UPNQR,
        token,
        options,
      }),
    ).pipe(
      catchError((e) => {
        if (toastr) {
          this.toastService.error(
            this.translate.instant('document-service.download-file.error'),
          );
        }

        throw e;
      }),
      tap((file: Blob) => {
        saveAs(file, this.documentName(document, ext, lang));

        if (toast && toast.toastRef) {
          toast.toastRef.close();
        }

        if (toastr) {
          this.toastService.success(
            this.translate.instant('File successfully downloaded.'),
          );
        }
      }),
    );
  }

  downloadPDFs(
    orgId: string,
    ids: string[],
    lang?: string,
  ): Observable<{ success: boolean }> {
    return super.downloadDeferred(
      this.orgApi.remoteDocumentPDFs(orgId, ids, true, lang),
    );
  }

  downloadSEPA(org: Organization, ids: string[]): void {
    this.get(org.id, {
      where: { id: { inq: ids } },
      incoming: true,
    }).subscribe(({ body: docs }) => {
      const file = this.SEPAService.generatePaymentOrder(org, docs);

      saveAs(
        new Blob([file], { type: 'text/xml;charset=utf-8' }),
        this.translate.instant('sepa.payment-order.file-prefix') +
          `-${Date.now()}.xml`,
      );
    });
  }

  exportDocuments(
    orgId: string,
    types: string | Array<string>,
    incoming = false,
    from: string,
    to: string,
    hasFiscalization: boolean,
    lang: string,
  ): Observable<{ success: boolean }> {
    const where: any = {};
    let dateType: string;

    if (incoming) {
      where.incoming = true;
    }

    if (typeof types === 'string') {
      where.or = [{ type: types }];
      // whereType = `&where[or][0][type]=${types}`;
    } else {
      let i = 0;
      where.or = [];
      for (const type of types) {
        // whereType += `&where[or][${i}][type]=${type}`;
        where.or.push({
          type,
        });
        i++;
      }
    }

    if (!incoming) {
      dateType = !hasFiscalization ? 'date' : 'issuedAt';
    } else {
      dateType = 'dateReceived';
    }

    where[dateType] = {
      between: [from, to],
    };

    return super.downloadDeferred(
      this.orgApi.export(orgId, 'document', where, undefined, lang),
    );
  }

  get(
    orgId: string,
    filter: any = {},
    force = false,
    toastr = true,
  ): Observable<{ body: Document[]; totalCount: number }> {
    return super.get(orgId, filter, force, toastr, this.orgApi);
  }

  getById(
    id: string,
    filter = {},
    force = true,
    toastr = true,
  ): Observable<Document> {
    return super.getById(id, filter, force, toastr, this.documentApi);
  }

  getLocalizedOrDefault(
    data: any,
    locale: string,
    key: string,
    l10nKey?: string,
  ): string {
    let localized;
    l10nKey = l10nKey || key;

    if (data.l10n) {
      localized = data.l10n[`${l10nKey}_${locale}`];
    }

    localized = localized || data[key];

    return localized;
  }

  findByShareableId(
    id: string,
    force = true,
    toastr = true,
  ): Observable<Document> {
    return this.cachedReq(
      `findByShareableId_${id}`,
      this.documentApi.findByShareableId(id, {
        include: ['attachments', 'payments'],
        parseSmartcodes: true,
      }),
      force,
      toastr,
    ).pipe(map(({ body }) => body));
  }

  getLastNumber(
    orgId: any,
    type: string,
    incoming = false,
    date?: Date,
  ): Observable<{ number: string }> {
    return this.orgApi
      .lastDocNumber(orgId, type, incoming, date)
      .pipe(map(({ body }) => body));
  }

  getRemainingDue(document: Document): number {
    if (document.totalPaid !== 0) {
      return new Decimal(document.totalWithTax)
        .minus(new Decimal(document.totalPaid))
        .toNumber();
    } else {
      return document.totalWithTax;
    }
  }

  /**
   * Returns updated totalPaid increased by provided payment's total
   */

  getNewTotalPaid(document: Document, payment: Payment): number {
    const totalPaid = new Decimal(document.totalPaid).plus(
      new Decimal(payment.amount),
    );

    return totalPaid.toNumber();
  }

  getNextNumber(
    orgId: string,
    type: string,
    incoming = false,
    date?: Date,
    fvStrategy?: string,
    BPid?: string,
    EDid?: string,
  ): Observable<{ number: string }> {
    return this.orgApi
      .nextDocNumber(orgId, type, incoming, fvStrategy, BPid, EDid, date)
      .pipe(map(({ body }) => body));
  }

  getDocumentLink({
    id,
    type,
    lang,
    UPNQR,
    token,
    options,
  }: {
    id: string;
    type: string;
    lang?: string;
    UPNQR?: boolean;
    token?: string;
    options?: Record<string, string>;
  }): string {
    let fileLink = `${LoopBackConfig.getPath()}/${LoopBackConfig.getApiVersion()}/documents/`;

    // Public
    if (!token) {
      if (id.indexOf('sid_') === -1) {
        throw new Error('Missing token or invalid shareable document id');
      }

      fileLink += 'public/';
    }

    fileLink += `${id}/${type}`;

    const queryParams = qs.stringify({
      l: lang,
      upn_qr: UPNQR?.toString(),
      access_token: token,
      options: options,
    });

    return `${fileLink}?${queryParams}`;
  }

  getTotalOwed(document: Document): number {
    let due = new Decimal(document.totalWithTax || 0);
    due = due.minus(document.totalPaid || 0);
    return due.toNumber();
  }

  isSent(document: Document): boolean {
    if (!document.activities) {
      return false;
    }

    const sent = document.activities.some((a) => a.action === 'send');
    return sent;
  }

  isPast(document: Document): boolean {
    let date: Date;

    if (document && (document.dateDue || document.datePaid)) {
      date = document.dateDue || document.datePaid;
    } else {
      return false;
    }

    return moment.utc(date).isBefore(this.now);
  }

  isUniqueNumber(
    orgId: string,
    num: string,
    type: string,
    ignoreId?: string,
    incoming: boolean = false,
  ): Observable<boolean> {
    return this.orgApi
      .isUniqueDocNumber(orgId, num, type, ignoreId, incoming)
      .pipe(map(({ body }) => body));
  }

  loadNumberOfInvoicesThisMonth(orgId: any): void {
    this.orgApi.thisMonthInvoices(orgId).subscribe(({ number: num }) => {
      this._numInvoicesThisMonth = num;
      this.numInvoicesThisMonth$.next(this._numInvoicesThisMonth);
    });
  }

  markSent(id: string): Observable<boolean> {
    // TODO: Update in cache
    return this.documentApi.markSent(id).pipe(
      map((done) => {
        this.toastService.success(
          this.translate.instant('Document successfully marked as sent.'),
        );

        return done;
      }),
      catchError((err) => {
        this.toastService.error(
          this.translate.instant(
            'Error marking document as sent, please try again or contact support.',
          ),
        );

        return throwError(err);
      }),
    );
  }

  openFile(id: string, type: string, lang?: string): Observable<boolean> {
    const token = this.accountApi.getCurrentToken();
    let pdfToken;

    if (id.indexOf('sid_') === -1) {
      pdfToken = this.accountApi
        .createAccessTokens(token.userId, {
          ttl: 10,
          scope: ['read:pdf'],
        })
        .pipe(map(({ body }) => body));
    } else {
      // Shareable document id
      pdfToken = of(null);
    }

    return pdfToken.pipe(
      map((pdfToken: AccessToken | null) => {
        const url = this.getDocumentLink({
          id,
          type,
          lang,
          token: pdfToken ? pdfToken.id : null,
        });

        this.toastService.info(
          this.translate.instant('Loading file, please wait.'),
        );

        const ref = window.open(url, '_blank');

        // IE window refernce fix
        let newWinInit = false;

        while (newWinInit === false) {
          if (ref === null) {
            newWinInit = true;
          } else if (typeof ref.addEventListener === 'function') {
            newWinInit = true;
          }
        }

        // IMPORTANT: Don't remove following line otherwise IE will throw "Permission denied" error
        console.log(ref);

        // Popup blocked by browser
        // TODO: Dark mode style fixes
        if (!ref || ref.closed || typeof ref.closed === 'undefined') {
          this.toastr.warning(
            `<div class="popup-blocked-wrap p-3 ${
              this.themeService._dark ? 'text-light' : ''
            }">
                <div class="popup-blocked-bg-image mb-3"></div>
                <strong>${this.translate.instant(
                  'Your browser might be blocking popups',
                )}</strong><br />
                <p class="text-secondary popup-block-secondary-text mt-3">
                  ${this.translate.instant(
                    'Follow the steps in the image to enable showing the pdf',
                  )}
                </p>
                <p class="text-right pointer mb-0">
                  <u><small>${this.translate.instant(
                    'Click to close this message',
                  )}</small></u>
                </p>
              </div>`,
            '',
            {
              timeOut: 7500,
              disableTimeOut: true,
              toastClass:
                'popup-blocked-background border hidden-sm-down ' +
                (this.themeService._dark ? 'dark' : ''),
              enableHtml: true,
              easeTime: 300,
              tapToDismiss: true,
            },
          );
        } else {
          const startTime = new Date().getTime();

          ref.addEventListener('beforeunload', (event) => {
            const endTime = new Date().getTime();
            const time = endTime - startTime;

            // If time < 100 the new window was probably closed by adblock
            if (time < 200) {
              this.toastr.warning(
                this.translate.instant(
                  'The document preview might have been blocked by browser or ad blocking software.',
                ),
                '',
                {
                  timeOut: 10000,
                },
              );
            }
            return event;
          });
        }

        return true;
      }),
      catchError((err) => {
        this.toastService.error(
          this.translate.instant(
            'Error opening file, please try again or contact support.',
          ),
        );

        return throwError(err);
      }),
    );
  }

  uploadAttachments(id: string, files: File[]): Observable<any> {
    const ops = [];

    for (const file of files) {
      ops.push(
        this.fileUploadService.upload(
          file,
          `/documents/${id}/attachments/upload`,
        ),
      );
    }

    return forkJoin(ops);
  }

  downloadAttachment(url: string): Observable<any> {
    const token = this.accountApi.getCurrentToken();

    return this.http.get(url, {
      headers: { Authorization: token.id },
      responseType: 'blob',
    });
  }

  parseImage(orgId: string, file: File): Observable<any> {
    return this.fileUploadService.upload(
      file,
      `/organizations/${orgId}/documents/parse-image`,
    );
  }

  parseXml(file: File): Observable<any> {
    return this.fileUploadService.upload(file, '/documents/parse-xml');
  }

  uncancel(id: string): Observable<boolean> {
    return this.documentApi.uncancel(id).pipe(
      map((res: any) => {
        this.toastService.success(
          this.translate.instant('Document successfully uncanceled.'),
        );

        return res;
      }),
      catchError((err) => {
        this.toastService.error(
          this.translate.instant(
            'Error uncanceling document, please try again or contact support.',
          ),
        );

        return throwError(err);
      }),
    );
  }

  update(id: string, data: any, toastr = true): Observable<Document> {
    return super.update(
      id,
      data,
      toastr,
      this.documentApi,
      this.createUpdateDocumentErrorToastOptions,
    );
  }

  updateAmountPaid(document: Document, amount: number): Document {
    document.totalPaid = new Decimal(document.totalPaid)
      .plus(amount)
      .toNumber();

    document.totalDue = new Decimal(document.totalWithTax)
      .minus(document.totalPaid)
      .toNumber();

    // Check if overpaid
    const action = new Decimal(document.totalWithTax).gte(0) ? 'lte' : 'gte';

    if (new Decimal(document.totalDue)[action](0)) {
      document.totalDue = 0;
    }

    document.paidInFull = document.totalDue === 0;

    return document;
  }

  documentName(document: Document, ext: string, lang: string): string {
    const typeName =
      document.type.slice(0, 1).toUpperCase() +
      document.type.replace('-', ' ').slice(1);

    const docType = this.translate.instant(typeName);

    const langSuffix =
      !lang || this.translate.currentLang === lang ? '' : ` - ${lang}`;

    return `${docType} - ${document.number} - ${document._documentClient.name}${langSuffix}.${ext}`;
  }

  removeAttachment(attachmentId: string): Observable<any> {
    return this.fileMetaApi.deleteById(attachmentId);
  }

  /**
   * Sets display name from the document property. If the property is not present,
   * take it from the defaults, if there is no value in the defaults,
   * fallback to 'estimate'
   * @param doc The document for which the display name should be returned
   * @param orgDefaults The defaults of the active organization
   * @private
   */
  getDisplayName(doc: Document, orgDefaults: Default[]): string {
    return (
      doc?.displayName ||
      orgDefaults.find((el) => el.name === 'estimate_displayName')?.value ||
      doc.type
    );
  }
}
