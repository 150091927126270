import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../shared/modules/toast/toast.service';
import { LoopBackConfig } from '../sdk';
import { DocumentApi } from '../sdk/services';

import { catchError, tap } from 'rxjs/operators';
import { API_VERSION, BASE_URL } from '../base-url';

@Injectable()
export class EmailService {
  constructor(
    public toastService: ToastService,
    public translate: TranslateService,
    private documentApi: DocumentApi,
  ) {
    LoopBackConfig.setBaseURL(BASE_URL);
    LoopBackConfig.setApiVersion(API_VERSION);
  }

  sendDocument(
    id: any,
    recipients,
    message,
    subject,
    sendCopy,
    associateEmail,
    excludedAttachmentIds: string[] = [],
    eslogAttachment: boolean,
    lang?: string,
  ) {
    return this.documentApi
      .send(
        id,
        recipients,
        message,
        subject,
        sendCopy,
        associateEmail,
        excludedAttachmentIds,
        eslogAttachment,
        lang,
      )
      .pipe(
        tap(() => {
          this.toastService.success(
            this.translate.instant('Email successfully sent.'),
          );
        }),
        catchError((err) => {
          // TODO: Reject sending if not verified account
          this.toastService.error(
            this.translate.instant(
              'Error sending email, please try again or contact support.',
            ),
          );
          // throw new Error('Error sending email');
          return err;
        }),
      );
  }
}
