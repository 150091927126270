import * as moment from 'moment';

import { Account, LoggerService, Organization } from '../sdk';
import {
  ISubscription,
  Subscription,
} from '../../apollo-subscriptions/subscription';

import { Angulartics2GoogleTagManager } from 'angulartics2';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  private track = true;
  private name = 'TrackingService';

  constructor(
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private log: LoggerService,
  ) {
    this.track = environment.tracking;
  }

  completeRegistration(user: Account) {
    this.log.log(`${this.name}: completeRegistration`);
    if (!this.track) {
      return;
    }

    this.angulartics2GoogleTagManager.pushLayer({
      event: 'CompleteRegistration',
      event_category: 'User',
    });

    this.setUser(user);
  }

  initCheckout(user: Account, sub: Partial<Subscription> = { total: 0 }) {
    this.log.log(`${this.name}: initCheckout`);

    if (!this.track || !user || !sub) {
      return;
    }

    this.angulartics2GoogleTagManager.pushLayer({
      event: 'InitiateCheckout',
      event_category: 'Subscription',
      event_value: sub.total,
      user_id: user.id,
    });
  }

  initRegistration() {
    this.log.log(`${this.name}: initRegistration`);
    if (!this.track) {
      return;
    }

    this.angulartics2GoogleTagManager.pushLayer({
      event: 'InitiateRegistration',
      event_category: 'User',
    });
  }

  logout() {
    this.log.log(`${this.name}: logout`);
    if (!this.track) {
      return;
    }

    this.angulartics2GoogleTagManager.pushLayer({
      event: 'Logout',
    });
  }

  setOrganization(user: Account, org: Organization) {
    this.log.log(`${this.name}: setOrganization`);
    if (!this.track) {
      return;
    }

    this.angulartics2GoogleTagManager.pushLayer({
      event: 'SetOrganization',
      user_id: user.id,
      organization_id: org.id,
      organization_name: org.name,
      organization_created: moment(org.createdAt).unix(),
    });
  }

  setSubscription(user: Account, sub: ISubscription) {
    this.log.log(`${this.name}: setSubscription`);
    if (!this.track) {
      return;
    }

    if (!user || !sub) {
      return;
    }
    const additions = sub && sub.additions ? sub.additions : {};

    this.setUserData(user, {
      subscription: sub._subscriptionPlan ? sub._subscriptionPlan.name : '',
      integrations: !!additions.integrations,
      furs: !!additions.furs,
      units: !!additions.units,
      additional_users: additions.additionalUsers || false,
    });
  }

  setUser(user: any) {
    this.log.log(`${this.name}: setUser`);
    // if (!this.track) return;

    user = user || {};

    this.angulartics2GoogleTagManager.setUsername(user.id);

    let name = '';
    if (user.firstname) {
      name += user.firstname + ' ';
    }
    if (user.lastname) {
      name += user.lastname;
    }

    this.angulartics2GoogleTagManager.pushLayer({
      event: 'SetUser',
      user_id: user.id,
      email: user.email,
      master_id: user.masterId,
      user_name: name.trim(),
      user_created: moment(user.createdAt).unix(),
      user_locale: user.locale,
    });
  }

  setUserData(user: Account, data: any) {
    this.log.log(`${this.name}: setUserData`);
    if (!this.track) {
      return;
    }

    this.angulartics2GoogleTagManager.pushLayer({
      event: 'SetUserData',
      user_id: user.id,
      data,
    });
  }

  startTrial(user: Account) {
    this.log.log(`${this.name}: startTrial`);
    if (!this.track) {
      return;
    }

    this.angulartics2GoogleTagManager.pushLayer({
      event: 'StartTrial',
      event_category: 'Subscription',
      user_id: user.id,
    });
  }

  subscribe(user: Account, subscription: Subscription) {
    this.log.log(`${this.name}: subscribe`);

    if (!this.track || !user || !subscription) {
      return;
    }

    const subscriptionName = subscription._subscriptionPlan
      ? subscription._subscriptionPlan.name
      : '';

    this.angulartics2GoogleTagManager.pushLayer({
      event: 'Subscribe',
      event_category: 'Subscription',
      event_value: subscription.total,
      subscription: subscriptionName,
      user_id: user.id,
    });
  }
}
