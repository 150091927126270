import { trigger, transition, group, useAnimation } from '@angular/animations';
import {
  fadeInAnimation,
  fadeOutAnimation,
} from '../../../../shared/animations/fade.animation';

export const fadeInToast = trigger('fadeInToast', [
  transition(':enter', [group([useAnimation(fadeInAnimation)])]),
]);

export const fadeOutToast = trigger('fadeOutToast', [
  transition(':leave', [group([useAnimation(fadeOutAnimation)])]),
]);
