import * as moment from 'moment';

import { Observable, ReplaySubject, filter, take } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Decimal from 'decimal.js';
import { LoggerService } from '../sdk';
import { StorageService } from './storage.service';

export interface Language {
  name: string;
  symbol: string;
  flag: string;
  hidden?: boolean;
}

export const languages: { [key: string]: Language } = {
  en: {
    name: 'English',
    symbol: 'en',
    flag: 'gb',
  },
  de: {
    name: 'German',
    symbol: 'de',
    flag: 'de',
  },
  fr: {
    name: 'French',
    symbol: 'fr',
    flag: 'fr',
  },
  it: {
    name: 'Italian',
    symbol: 'it',
    flag: 'it',
  },
  sl: {
    name: 'Slovenian',
    symbol: 'sl',
    flag: 'si',
  },
  hr: {
    name: 'Croatian',
    symbol: 'hr',
    flag: 'hr',
  },
};

export const locales: Language[] = [
  ...Object.keys(languages).map((l) => languages[l]),
  {
    name: 'Portuguese',
    symbol: 'pt',
    flag: 'pt',
  },
  {
    name: 'Arabic',
    symbol: 'ar',
    flag: 'ar',
  },
  {
    name: 'Czech',
    symbol: 'cs',
    flag: 'cz',
  },
  {
    name: 'Slovak',
    symbol: 'sk',
    flag: 'sk',
  },
  {
    name: 'Latvian',
    symbol: 'lv',
    flag: 'lv',
  },
  {
    name: 'Dutch',
    symbol: 'nl',
    flag: 'nl',
  },
  {
    name: 'Estonian',
    symbol: 'es',
    flag: 'es',
  },
  {
    name: 'Romanian',
    symbol: 'ro',
    flag: 'ro',
  },
  {
    name: 'Serbian',
    symbol: 'sr',
    flag: 'sr',
  },
];

@Injectable()
export class LanguageService {
  public default = 'en';
  private storageKey = 'Apollo::Language';

  // TODO: Fix should be current locale not language
  public _current = '';
  private current$: ReplaySubject<string> = new ReplaySubject(1);
  readonly current: Observable<string> = this.current$.asObservable();

  constructor(
    private log: LoggerService,
    private route: ActivatedRoute,
    private storageService: StorageService,
  ) {
    this.log.log('LanguageService: contructor');

    // Set current language from query param
    this.route.queryParams
      .pipe(
        filter((params) => params['l'] && params['l'] !== this._current),
        take(1),
      )
      .subscribe((params) => {
        this.setCurrent(params['l'].split('-')[0], true);
      });

    const language = this.storageService.getItem(this.storageKey);
    this.log.log('LanguageService: getItem:', language);

    if (language) {
      this.setCurrent(language);
    } else {
      // Browser lang
      const langs = Object.keys(languages);
      const browserLang = navigator.language.split('-')[0];

      if (langs.indexOf(browserLang) !== -1) {
        this.default = browserLang;
      }

      this.setCurrent(this.default);
    }
  }

  formatCurrency(
    number: number,
    currency: string,
    locale: string = this._current,
    maximumFractionDigits = 4,
    minimumFractionDigits = 2,
  ) {
    if (maximumFractionDigits < minimumFractionDigits) {
      maximumFractionDigits = minimumFractionDigits;
    }

    if (!currency) {
      return number.toString();
    }

    currency = currency.replace('(', '').replace(')', '');

    let currencyFormat: any;

    try {
      currencyFormat = new Intl.NumberFormat(locale, {
        currency,
        style: 'currency',
        minimumFractionDigits,
        maximumFractionDigits,
      });
    } catch (e) {
      if (!e.message.includes('Invalid currency code')) {
        throw e;
      }

      currencyFormat = {
        format: (function () {
          const proxyCurrency = 'USD';

          const formatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: proxyCurrency,
            // minimumFractionDigits: 2,
            maximumFractionDigits: 18,
          });

          const currencySymbol = formatter
            .format(0)
            .replace(/[0-9.,]/g, '')
            .trim();

          return function (value: number) {
            let formatted = formatter.format(value);
            formatted = formatted.replace(currencySymbol, currency);
            return formatted;
          };
        })(),
      };
    }

    return currencyFormat.format(number);
  }

  formatDate(date, locale: string = this._current, options = {}) {
    return Intl.DateTimeFormat(locale, options).format(moment(date).toDate());
  }

  f(number: number | Decimal, dp: number = 2, locale: string = this._current) {
    return this.formatNumber(number, dp, locale);
  }

  formatNumber(
    number: number | Decimal,
    dp: number = 2,
    locale: string = this._current,
  ) {
    const numberFormat = new Intl.NumberFormat(locale, {
      maximumFractionDigits: dp,
      useGrouping: false,
    });

    if (Decimal.isDecimal(number)) {
      const d: Decimal = number as Decimal;
      number = d.toNumber();
    }

    return numberFormat.format(number as number);
  }

  getCurrent() {
    return this.current;
  }

  getFlag(lang: string = this._current) {
    return languages[lang].flag;
  }

  isC(value: any, ignoreTrailingZero = false) {
    return this.isCalculatable(value, ignoreTrailingZero);
  }

  isCalculatable(value: any, ignoreTrailingZero = false) {
    // this.log.log('isCalculatable', 'value', value);
    const type = typeof value;

    if (typeof value === 'undefined' || value === null || value === '-') {
      // this.log.log('isCalculatable', false, 'undefined or null');
      return false;
    }

    // No un-allowed characters
    // NOTE: Added char 8722 (fake minus) due to unFormatNumber functionality
    const regexpChar = /[^\-\−\d,.]/g;
    if (value.search && value.search(regexpChar) !== -1) {
      // this.log.log('isCalculatable', false, 'invalid character');
      // Hack as 8722 still not working on production
      if (value.charCodeAt(0) !== 8722) {
        return false;
      }
    }

    // Minus not at first position
    if (value.indexOf && value.indexOf('-') > 0) {
      // this.log.log('Minus at wrong position');
      return false;
    }

    // Allows typing fraction zeros without stripping them until 4th digit
    // Used for auto formatting numbers, should be disabled for validation
    if (!ignoreTrailingZero) {
      const regexp = /[.,]0{1,3}$|[.,][1-9]0{1,2}$|[.,][1-9]{2}0{1}$/;
      if (value.search && value.search(regexp) !== -1) {
        return false;
      }
    }

    if (type === 'number') {
      return true;
    }

    if (type !== 'string') {
      return false;
    }

    const valueLast = value.charAt(value.length - 1);

    if (valueLast === '.' || valueLast === ',') {
      return false;
    }

    return true;
  }

  readFromStorage() {
    return this.storageService.getItem(this.storageKey);
  }

  // extended used for public document view where all locales are used instead
  // of just the full UI ones (languages)
  setCurrent(lang = this.default, save = true, extended = false) {
    this.log.log('setCurrent', lang);
    let fallback = false;
    let langShort = lang.substr(0, 2);
    lang = langShort;

    if (!extended) {
      fallback = !languages[langShort];
    } else {
      fallback = !locales.find((l) => l.symbol === lang);
    }

    if (fallback) {
      lang = langShort = 'en';
    }

    this._current = lang;

    if (save) {
      this.saveToStorage(this._current);
    }

    this.current$.next(this._current);
    return this.current;
  }

  u(number: string | number): string {
    return this.unformatNumber(number);
  }

  unformatNumber(number: string | number): string {
    if (number === null) {
      return '0';
    }

    let newNumber = number;

    if (typeof newNumber === 'string') {
      const charCode0 = String(newNumber).charCodeAt(0);

      // Strip spaces
      newNumber = newNumber.replace(' ', '');

      // IMPORTANT: Intl.NumberFormat adds a strange fake minus `-` sign for
      // negative num. that breaks parsing so we remove it by character code and
      // replace it with normal one.
      if (charCode0 === 8722) {
        newNumber = '-' + newNumber.slice(1);
      }

      // newNumber = newNumber.replace(/^[^-0-9]?|[^0-9,.]/g, '');
      // newNumber = newNumber.replace(/[^-0-9,.]/g, '');
      newNumber = newNumber.replace(/[^\-0-9,.]/g, '');

      if (newNumber.search(',') !== -1) {
        newNumber = newNumber.replace('.', '').replace(/,/g, '.');
      }
    }

    if (newNumber === '') {
      newNumber = 0;
    }

    newNumber = String(newNumber);

    return String(newNumber);
  }

  private saveToStorage(lang: string) {
    this.storageService.setItem(this.storageKey, lang);
  }
}
