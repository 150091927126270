import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Angulartics2Module } from 'angulartics2';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { IsAuthenticatedResolver } from './accounts/login/is-authenticated-resolver.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ApiModule } from './shared/api/api.module';
import { ToastModule } from './shared/modules/toast/toast.module';
import { SDKBrowserModule } from './shared/sdk';
import { CookieBrowser } from './shared/sdk/storage/cookie.browser';
import { PruningTranslationLoader } from './shared/services/pruning-translate-loader.service';

export function createTranslateLoader(
  http: HttpClient,
): PruningTranslationLoader {
  const version =
    environment.version !== 'VERSION'
      ? environment.version
      : Math.ceil(Math.random() * 9999999999);

  return new PruningTranslationLoader(
    http,
    './assets/i18n/',
    '.json?cb=' + version,
  );
}

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    Angulartics2Module.forRoot(),
    SDKBrowserModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    ToastModule,
    ApiModule.forRoot({ rootUrl: environment.apiUrl }),
  ],
  providers: [CookieBrowser, IsAuthenticatedResolver],
  declarations: [AppComponent],
  exports: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
