/* tslint:disable */
import {
  PriceList,
  Item,
  Organization
} from '../index';

declare var Object: any;
export interface PriceInterface {
  "amount": number;
  "id"?: any;
  "priceListId"?: any;
  "itemId"?: any;
  "organizationId"?: any;
  "deletedAt"?: Date;
  "_isDeleted"?: boolean;
  "deletedId"?: string;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "custom"?: any;
  priceList?: PriceList;
  item?: Item;
  organization?: Organization;
}

export class Price implements PriceInterface {
  "amount": number;
  "id": any;
  "priceListId": any;
  "itemId": any;
  "organizationId": any;
  "deletedAt": Date;
  "_isDeleted": boolean;
  "deletedId": string;
  "createdAt": Date;
  "updatedAt": Date;
  "custom": any;
  priceList: PriceList;
  item: Item;
  organization: Organization;
  constructor(data?: PriceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Price`.
   */
  public static getModelName() {
    return "Price";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Price for dynamic purposes.
  **/
  public static factory(data: PriceInterface): Price{
    return new Price(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Price',
      plural: 'Prices',
      path: 'Prices',
      idName: 'id',
      properties: {
        "amount": {
          name: 'amount',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "priceListId": {
          name: 'priceListId',
          type: 'any'
        },
        "itemId": {
          name: 'itemId',
          type: 'any'
        },
        "organizationId": {
          name: 'organizationId',
          type: 'any'
        },
        "deletedAt": {
          name: 'deletedAt',
          type: 'Date'
        },
        "_isDeleted": {
          name: '_isDeleted',
          type: 'boolean'
        },
        "deletedId": {
          name: 'deletedId',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        "custom": {
          name: 'custom',
          type: 'any',
          default: <any>null
        },
      },
      relations: {
        priceList: {
          name: 'priceList',
          type: 'PriceList',
          model: 'PriceList',
          relationType: 'belongsTo',
                  keyFrom: 'priceListId',
          keyTo: 'id'
        },
        item: {
          name: 'item',
          type: 'Item',
          model: 'Item',
          relationType: 'belongsTo',
                  keyFrom: 'itemId',
          keyTo: 'id'
        },
        organization: {
          name: 'organization',
          type: 'Organization',
          model: 'Organization',
          relationType: 'belongsTo',
                  keyFrom: 'organizationId',
          keyTo: 'id'
        },
      }
    }
  }
}
