import { Injectable } from '@angular/core';
import { LoopBackConfig, LoggerService } from '../shared/sdk';
import { UnitApi, OrganizationApi, AccountApi } from '../shared/sdk/services';
import { Unit } from '../shared/sdk/models';
import { ToastService } from '../shared/modules/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';

import { ApiService } from '../shared/services/api.service';
import { BASE_URL, API_VERSION } from '../shared/base-url';
import { CacheService } from '../shared/services/cache.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class UnitService extends ApiService<Unit> {
  constructor(
    public accountApi: AccountApi,
    protected cacheService: CacheService,
    protected log: LoggerService,
    protected toastService: ToastService,
    protected translate: TranslateService,
    private unitApi: UnitApi,
    private orgApi: OrganizationApi,
  ) {
    super(cacheService, log, toastService, translate);

    this.name = 'Unit';
    this.plural = 'Units';

    LoopBackConfig.setBaseURL(BASE_URL);
    LoopBackConfig.setApiVersion(API_VERSION);
  }

  count(
    orgId: string,
    filter: any = {},
    force = false,
    toastr = true,
  ): Observable<number> {
    return super.count(orgId, filter, force, toastr, this.orgApi);
  }

  create(orgId: string, data: any, toastr = true): Observable<Unit> {
    return super.create(orgId, data, toastr, this.orgApi);
  }

  delete(id: string, toastr = true): Observable<boolean> {
    return super.delete(id, toastr, this.unitApi);
  }

  deleteImage(id: string, type: string): Observable<boolean> {
    return this.unitApi.deleteImage(id, type);
  }

  exportUnits(orgId: string, lang: string): void {
    const url =
      `${BASE_URL}/${API_VERSION}/organizations/${orgId}/export?type=unit` +
      `&access_token=${this.accountApi.getCurrentToken().id}&lang=${lang}`;

    window.open(url);
  }

  find(orgId: string, term: string): void {
    this.log.log(`${this.name}Service: find`);
    // return this.orgApi.searchUnits(orgId, term);
  }

  get(
    orgId: string,
    filter: any = {},
    force = false,
    toastr = true,
  ): Observable<{ body: Unit[]; totalCount: number }> {
    return super.get(orgId, filter, force, toastr, this.orgApi);
  }

  getById(
    id: string,
    filter = {},
    force = true,
    toastr = true,
  ): Observable<Unit> {
    return super.getById(id, filter, force, toastr, this.unitApi);
  }

  getLastNumber(
    id: string,
    type: string,
    incoming = false,
    date?: Date,
  ): Observable<{ number: string }> {
    return this.unitApi
      .lastDocNumber(id, type, incoming, date)
      .pipe(map(({ body }) => body));
  }

  getNextNumber(
    id: string,
    type: string,
    incoming = false,
    date?: Date,
  ): Observable<{ number: string }> {
    return this.unitApi
      .nextDocNumber(id, type, incoming, date)
      .pipe(map(({ body }) => body));
  }

  patch(id: string, data: any, toastr = true): Observable<Partial<Unit>> {
    return super.patch(id, data, toastr, this.unitApi);
  }
}
