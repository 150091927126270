import { Injectable } from '@angular/core';
import { LoopBackConfig, LoggerService } from '../shared/sdk';
import { PriceListApi, OrganizationApi } from '../shared/sdk/services';
import { PriceList } from '../shared/sdk/models';
import { ToastService } from '../shared/modules/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';

import { BASE_URL, API_VERSION } from '../shared/base-url';
import { ApiService } from '../shared/services/api.service';
import { CacheService } from '../shared/services/cache.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PriceListService extends ApiService<PriceList> {
  constructor(
    protected cacheService: CacheService,
    protected log: LoggerService,
    protected toastService: ToastService,
    protected translate: TranslateService,
    private priceListApi: PriceListApi,
    private orgApi: OrganizationApi,
  ) {
    super(cacheService, log, toastService, translate);

    this.name = 'PriceList';
    this.plural = 'PriceLists';

    LoopBackConfig.setBaseURL(BASE_URL);
    LoopBackConfig.setApiVersion(API_VERSION);
  }

  count(
    orgId: any,
    where: any = {},
    force = false,
    toastr = true,
  ): Observable<number> {
    return super.count(orgId, where, force, toastr, this.orgApi);
  }

  create(orgId: string, data: any, toastr = true): Observable<PriceList> {
    return super.create(orgId, data, toastr, this.orgApi);
  }

  delete(id: any, toastr = true) {
    return super.delete(id, toastr, this.priceListApi);
  }

  get(
    orgId: any,
    filter: any = {},
    // filter: any = { order: '_taxRates.rate DESC' },
    force = false,
    toastr = true,
  ): Observable<{ body: PriceList[]; totalCount: number }> {
    return super.get(orgId, filter, force, toastr, this.orgApi);
  }

  getById(
    id: string,
    filter = {},
    force = true,
    toastr = true,
  ): Observable<PriceList> {
    return super.getById(id, filter, force, toastr, this.priceListApi);
  }

  patch(
    id: string,
    data: Partial<PriceList>,
    toastr = true,
  ): Observable<Partial<PriceList>> {
    return super.patch(id, data, toastr, this.priceListApi);
  }
}
