import { Injectable, Injector } from '@angular/core';

@Injectable()
export class CookieService {
  private cookieStore = {};

  constructor(private injector: Injector) {
    this.parseCookies(document.cookie);
  }

  getItem(key: string) {
    return !!this.cookieStore[key] ? this.cookieStore[key] : null;
  }

  parseCookies(cookies) {
    this.cookieStore = {};

    if (!!cookies === false) {
      return;
    }

    const cookiesArr = cookies.split('; ');

    for (const cookie of cookiesArr) {
      const cookieArr = cookie.split('=');
      this.cookieStore[cookieArr[0]] = cookieArr[1];
    }
  }

  removeItem(key: string) {
    delete this.cookieStore[key];
    this.deleteCookie(key);
  }

  setItem(key: string, value: string, expireDays = 14) {
    this.cookieStore[key] = value;
    this.setCookie(key, value, expireDays);
  }

  private deleteCookie(name) {
    delete this.cookieStore[name];
    this.setCookie(name, '', -1);
  }

  private setCookie(
    name: string,
    value: string,
    expireDays: number,
    path: string = '',
  ) {
    let cookie = document.cookie;

    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);

    const expires = `expires=${d.toUTCString()}`;
    const cpath: string = path ? `; path=${path}` : '';
    let tld = '';
    const hostname = location.hostname;

    if (hostname.indexOf('localhost') === -1) {
      const split = hostname.split('.');
      tld = `; domain=${split[split.length - 1]}.${split[split.length - 2]}`;
    }

    cookie = `${name}=${value}; ${expires}${cpath}${tld}`;
  }
}
