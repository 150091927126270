/* tslint:disable */
import {
  Organization,
  Document,
  Account
} from '../index';

declare var Object: any;
export interface RecurrenceInterface {
  "name": string;
  "type": string;
  "dateFirst": Date;
  "numRecurrences": number;
  "autoSend": boolean;
  "notify": boolean;
  "saveAsDraft": boolean;
  "id"?: any;
  "organizationId"?: any;
  "documentId"?: any;
  "accountId"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "custom"?: any;
  "deletedAt"?: Date;
  "_isDeleted"?: boolean;
  "deletedId"?: string;
  "_recurrenceDates"?: Array<any>;
  organization?: Organization;
  document?: Document;
  account?: Account;
  recurrenceDates?: any[];
}

export class Recurrence implements RecurrenceInterface {
  "name": string;
  "type": string;
  "dateFirst": Date;
  "numRecurrences": number;
  "autoSend": boolean;
  "notify": boolean;
  "saveAsDraft": boolean;
  "id": any;
  "organizationId": any;
  "documentId": any;
  "accountId": any;
  "createdAt": Date;
  "updatedAt": Date;
  "custom": any;
  "deletedAt": Date;
  "_isDeleted": boolean;
  "deletedId": string;
  "_recurrenceDates": Array<any>;
  organization: Organization;
  document: Document;
  account: Account;
  recurrenceDates: any[];
  constructor(data?: RecurrenceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Recurrence`.
   */
  public static getModelName() {
    return "Recurrence";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Recurrence for dynamic purposes.
  **/
  public static factory(data: RecurrenceInterface): Recurrence{
    return new Recurrence(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Recurrence',
      plural: 'Recurrences',
      path: 'Recurrences',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "dateFirst": {
          name: 'dateFirst',
          type: 'Date'
        },
        "numRecurrences": {
          name: 'numRecurrences',
          type: 'number'
        },
        "autoSend": {
          name: 'autoSend',
          type: 'boolean',
          default: false
        },
        "notify": {
          name: 'notify',
          type: 'boolean',
          default: true
        },
        "saveAsDraft": {
          name: 'saveAsDraft',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "organizationId": {
          name: 'organizationId',
          type: 'any'
        },
        "documentId": {
          name: 'documentId',
          type: 'any'
        },
        "accountId": {
          name: 'accountId',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        "custom": {
          name: 'custom',
          type: 'any',
          default: <any>null
        },
        "deletedAt": {
          name: 'deletedAt',
          type: 'Date'
        },
        "_isDeleted": {
          name: '_isDeleted',
          type: 'boolean'
        },
        "deletedId": {
          name: 'deletedId',
          type: 'string'
        },
        "_recurrenceDates": {
          name: '_recurrenceDates',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
      },
      relations: {
        organization: {
          name: 'organization',
          type: 'Organization',
          model: 'Organization',
          relationType: 'belongsTo',
                  keyFrom: 'organizationId',
          keyTo: 'id'
        },
        document: {
          name: 'document',
          type: 'Document',
          model: 'Document',
          relationType: 'belongsTo',
                  keyFrom: 'documentId',
          keyTo: 'id'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'accountId',
          keyTo: 'id'
        },
        recurrenceDates: {
          name: 'recurrenceDates',
          type: 'any[]',
          model: '',
          relationType: 'embedsMany',
                  keyFrom: '_recurrenceDates',
          keyTo: 'id'
        },
      }
    }
  }
}
