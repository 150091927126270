/* tslint:disable */
import {
  Organization
} from '../index';

declare var Object: any;
export interface WebhookInterface {
  "events"?: any;
  "url": string;
  "enabled"?: boolean;
  "id"?: any;
  "organizationId"?: any;
  "deletedAt"?: Date;
  "_isDeleted"?: boolean;
  "deletedId"?: string;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  organization?: Organization;
}

export class Webhook implements WebhookInterface {
  "events": any;
  "url": string;
  "enabled": boolean;
  "id": any;
  "organizationId": any;
  "deletedAt": Date;
  "_isDeleted": boolean;
  "deletedId": string;
  "createdAt": Date;
  "updatedAt": Date;
  organization: Organization;
  constructor(data?: WebhookInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Webhook`.
   */
  public static getModelName() {
    return "Webhook";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Webhook for dynamic purposes.
  **/
  public static factory(data: WebhookInterface): Webhook{
    return new Webhook(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Webhook',
      plural: 'Webhooks',
      path: 'Webhooks',
      idName: 'id',
      properties: {
        "events": {
          name: 'events',
          type: 'any'
        },
        "url": {
          name: 'url',
          type: 'string'
        },
        "enabled": {
          name: 'enabled',
          type: 'boolean',
          default: true
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "organizationId": {
          name: 'organizationId',
          type: 'any'
        },
        "deletedAt": {
          name: 'deletedAt',
          type: 'Date'
        },
        "_isDeleted": {
          name: '_isDeleted',
          type: 'boolean'
        },
        "deletedId": {
          name: 'deletedId',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
      },
      relations: {
        organization: {
          name: 'organization',
          type: 'Organization',
          model: 'Organization',
          relationType: 'belongsTo',
                  keyFrom: 'organizationId',
          keyTo: 'id'
        },
      }
    }
  }
}
