/* tslint:disable */
import {
  Account,
  Plan,
  Usage,
  WhiteLabel,
  Organization
} from '../index';

declare var Object: any;
export interface SubscriptionInterface {
  "dateStart": Date;
  "active": boolean;
  "billingCycle"?: string;
  "canceledAt"?: Date;
  "churnedAt"?: Date;
  "comment"?: string;
  "gateway"?: string;
  "gatewayChargeRetries": number;
  "gatewayPaymentMethodId"?: string;
  "recurrenceCount"?: number;
  "cronLogs"?: any;
  "id"?: any;
  "accountId"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "planId"?: any;
  "_subscriptionPlan"?: any;
  account?: Account;
  plan?: Plan;
  subscriptionPlan?: any[];
  usages?: Usage[];
  whiteLabel?: WhiteLabel;
  organizations?: Organization[];
}

export class Subscription implements SubscriptionInterface {
  "dateStart": Date;
  "active": boolean;
  "billingCycle": string;
  "canceledAt": Date;
  "churnedAt": Date;
  "comment": string;
  "gateway": string;
  "gatewayChargeRetries": number;
  "gatewayPaymentMethodId": string;
  "recurrenceCount": number;
  "cronLogs": any;
  "id": any;
  "accountId": any;
  "createdAt": Date;
  "updatedAt": Date;
  "planId": any;
  "_subscriptionPlan": any;
  account: Account;
  plan: Plan;
  subscriptionPlan: any[];
  usages: Usage[];
  whiteLabel: WhiteLabel;
  organizations: Organization[];
  constructor(data?: SubscriptionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Subscription`.
   */
  public static getModelName() {
    return "Subscription";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Subscription for dynamic purposes.
  **/
  public static factory(data: SubscriptionInterface): Subscription{
    return new Subscription(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Subscription',
      plural: 'Subscriptions',
      path: 'Subscriptions',
      idName: 'id',
      properties: {
        "dateStart": {
          name: 'dateStart',
          type: 'Date'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "billingCycle": {
          name: 'billingCycle',
          type: 'string',
          default: 'month'
        },
        "canceledAt": {
          name: 'canceledAt',
          type: 'Date'
        },
        "churnedAt": {
          name: 'churnedAt',
          type: 'Date'
        },
        "comment": {
          name: 'comment',
          type: 'string'
        },
        "gateway": {
          name: 'gateway',
          type: 'string'
        },
        "gatewayChargeRetries": {
          name: 'gatewayChargeRetries',
          type: 'number',
          default: 0
        },
        "gatewayPaymentMethodId": {
          name: 'gatewayPaymentMethodId',
          type: 'string'
        },
        "recurrenceCount": {
          name: 'recurrenceCount',
          type: 'number'
        },
        "cronLogs": {
          name: 'cronLogs',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "accountId": {
          name: 'accountId',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        "planId": {
          name: 'planId',
          type: 'any'
        },
        "_subscriptionPlan": {
          name: '_subscriptionPlan',
          type: 'any'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'accountId',
          keyTo: 'id'
        },
        plan: {
          name: 'plan',
          type: 'Plan',
          model: 'Plan',
          relationType: 'belongsTo',
                  keyFrom: 'planId',
          keyTo: 'id'
        },
        subscriptionPlan: {
          name: 'subscriptionPlan',
          type: 'any[]',
          model: '',
          relationType: 'embedsOne',
                  keyFrom: '_subscriptionPlan',
          keyTo: 'id'
        },
        usages: {
          name: 'usages',
          type: 'Usage[]',
          model: 'Usage',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'subscriptionId'
        },
        whiteLabel: {
          name: 'whiteLabel',
          type: 'WhiteLabel',
          model: 'WhiteLabel',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'subscriptionId'
        },
        organizations: {
          name: 'organizations',
          type: 'Organization[]',
          model: 'Organization',
          relationType: 'hasMany',
          modelThrough: 'OrganizationSubscription',
          keyThrough: 'organizationId',
          keyFrom: 'id',
          keyTo: 'subscriptionId'
        },
      }
    }
  }
}
