/* tslint:disable */
import {
  Item,
  DocumentItemTax,
  Component,
  Data
} from '../index';

declare var Object: any;
export interface DocumentItemInterface {
  "id"?: string;
  "discount"?: number;
  "discountIsAmount"?: boolean;
  "quantity"?: number;
  "total"?: number;
  "totalWithTax"?: number;
  "totalTax"?: number;
  "totalDiscount"?: number;
  "isSeparator"?: boolean;
  "name"?: string;
  "description"?: string;
  "price"?: number;
  "priceGross"?: number;
  "unit"?: string;
  "SKU"?: string;
  "EAN"?: string;
  "trackInventory"?: boolean;
  "onBehalf"?: string;
  "itemId"?: any;
  "custom"?: any;
  "l10n"?: any;
  "_documentItemTaxes"?: Array<any>;
  "_components"?: Array<any>;
  "_data"?: Array<any>;
  item?: Item;
  documentItemTaxes?: DocumentItemTax[];
  components?: Component[];
  data?: Data[];
}

export class DocumentItem implements DocumentItemInterface {
  "id": string;
  "discount": number;
  "discountIsAmount": boolean;
  "quantity": number;
  "total": number;
  "totalWithTax": number;
  "totalTax": number;
  "totalDiscount": number;
  "isSeparator": boolean;
  "name": string;
  "description": string;
  "price": number;
  "priceGross": number;
  "unit": string;
  "SKU": string;
  "EAN": string;
  "trackInventory": boolean;
  "onBehalf": string;
  "itemId": any;
  "custom": any;
  "l10n": any;
  "_documentItemTaxes": Array<any>;
  "_components": Array<any>;
  "_data": Array<any>;
  item: Item;
  documentItemTaxes: DocumentItemTax[];
  components: Component[];
  data: Data[];
  constructor(data?: DocumentItemInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DocumentItem`.
   */
  public static getModelName() {
    return "DocumentItem";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of DocumentItem for dynamic purposes.
  **/
  public static factory(data: DocumentItemInterface): DocumentItem{
    return new DocumentItem(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'DocumentItem',
      plural: 'DocumentItems',
      path: 'DocumentItems',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "discount": {
          name: 'discount',
          type: 'number'
        },
        "discountIsAmount": {
          name: 'discountIsAmount',
          type: 'boolean',
          default: false
        },
        "quantity": {
          name: 'quantity',
          type: 'number'
        },
        "total": {
          name: 'total',
          type: 'number'
        },
        "totalWithTax": {
          name: 'totalWithTax',
          type: 'number'
        },
        "totalTax": {
          name: 'totalTax',
          type: 'number'
        },
        "totalDiscount": {
          name: 'totalDiscount',
          type: 'number'
        },
        "isSeparator": {
          name: 'isSeparator',
          type: 'boolean',
          default: false
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string',
          default: ''
        },
        "price": {
          name: 'price',
          type: 'number'
        },
        "priceGross": {
          name: 'priceGross',
          type: 'number'
        },
        "unit": {
          name: 'unit',
          type: 'string'
        },
        "SKU": {
          name: 'SKU',
          type: 'string'
        },
        "EAN": {
          name: 'EAN',
          type: 'string'
        },
        "trackInventory": {
          name: 'trackInventory',
          type: 'boolean'
        },
        "onBehalf": {
          name: 'onBehalf',
          type: 'string'
        },
        "itemId": {
          name: 'itemId',
          type: 'any'
        },
        "custom": {
          name: 'custom',
          type: 'any',
          default: <any>null
        },
        "l10n": {
          name: 'l10n',
          type: 'any'
        },
        "_documentItemTaxes": {
          name: '_documentItemTaxes',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "_components": {
          name: '_components',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "_data": {
          name: '_data',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
      },
      relations: {
        item: {
          name: 'item',
          type: 'Item',
          model: 'Item',
          relationType: 'belongsTo',
                  keyFrom: 'itemId',
          keyTo: 'id'
        },
        documentItemTaxes: {
          name: 'documentItemTaxes',
          type: 'DocumentItemTax[]',
          model: 'DocumentItemTax',
          relationType: 'embedsMany',
                  keyFrom: '_documentItemTaxes',
          keyTo: 'id'
        },
        components: {
          name: 'components',
          type: 'Component[]',
          model: 'Component',
          relationType: 'embedsMany',
                  keyFrom: '_components',
          keyTo: 'id'
        },
        data: {
          name: 'data',
          type: 'Data[]',
          model: 'Data',
          relationType: 'embedsMany',
                  keyFrom: '_data',
          keyTo: 'id'
        },
      }
    }
  }
}
