/* tslint:disable */
import {
  Organization
} from '../index';

declare var Object: any;
export interface TicketingHubInterface {
  "token"?: string;
  "businessPremiseId"?: string;
  "electronicDeviceId"?: string;
  "id"?: any;
  "organizationId"?: any;
  organization?: Organization;
}

export class TicketingHub implements TicketingHubInterface {
  "token": string;
  "businessPremiseId": string;
  "electronicDeviceId": string;
  "id": any;
  "organizationId": any;
  organization: Organization;
  constructor(data?: TicketingHubInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TicketingHub`.
   */
  public static getModelName() {
    return "TicketingHub";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TicketingHub for dynamic purposes.
  **/
  public static factory(data: TicketingHubInterface): TicketingHub{
    return new TicketingHub(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TicketingHub',
      plural: 'TicketingHub',
      path: 'TicketingHub',
      idName: 'id',
      properties: {
        "token": {
          name: 'token',
          type: 'string'
        },
        "businessPremiseId": {
          name: 'businessPremiseId',
          type: 'string'
        },
        "electronicDeviceId": {
          name: 'electronicDeviceId',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "organizationId": {
          name: 'organizationId',
          type: 'any'
        },
      },
      relations: {
        organization: {
          name: 'organization',
          type: 'Organization',
          model: 'Organization',
          relationType: 'belongsTo',
                  keyFrom: 'organizationId',
          keyTo: 'id'
        },
      }
    }
  }
}
