/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class AccountOrganizationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation accountOrganizationDeleteById
   */
  static readonly AccountOrganizationDeleteByIdPath = '/v1/AccountOrganizations/{id}';

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountOrganizationDeleteById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationDeleteById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountOrganizationService.AccountOrganizationDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountOrganizationDeleteById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationDeleteById$Json(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.accountOrganizationDeleteById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountOrganizationDeleteById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationDeleteById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountOrganizationService.AccountOrganizationDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountOrganizationDeleteById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationDeleteById$Xml(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.accountOrganizationDeleteById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountOrganizationDeleteById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationDeleteById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountOrganizationService.AccountOrganizationDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountOrganizationDeleteById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationDeleteById$Javascript(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.accountOrganizationDeleteById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation accountOrganizationPrototypeAccept
   */
  static readonly AccountOrganizationPrototypeAcceptPath = '/v1/AccountOrganizations/{id}/accept';

  /**
   * Confirm invitation to join organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountOrganizationPrototypeAccept$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeAccept$Json$Response(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountOrganizationService.AccountOrganizationPrototypeAcceptPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * Confirm invitation to join organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountOrganizationPrototypeAccept$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeAccept$Json(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.accountOrganizationPrototypeAccept$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Confirm invitation to join organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountOrganizationPrototypeAccept$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeAccept$Xml$Response(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountOrganizationService.AccountOrganizationPrototypeAcceptPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * Confirm invitation to join organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountOrganizationPrototypeAccept$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeAccept$Xml(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.accountOrganizationPrototypeAccept$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Confirm invitation to join organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountOrganizationPrototypeAccept$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeAccept$Javascript$Response(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountOrganizationService.AccountOrganizationPrototypeAcceptPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * Confirm invitation to join organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountOrganizationPrototypeAccept$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeAccept$Javascript(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.accountOrganizationPrototypeAccept$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Path part for operation accountOrganizationPrototypeReject
   */
  static readonly AccountOrganizationPrototypeRejectPath = '/v1/AccountOrganizations/{id}/reject';

  /**
   * Deny invitation to join organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountOrganizationPrototypeReject$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeReject$Json$Response(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountOrganizationService.AccountOrganizationPrototypeRejectPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * Deny invitation to join organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountOrganizationPrototypeReject$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeReject$Json(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.accountOrganizationPrototypeReject$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Deny invitation to join organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountOrganizationPrototypeReject$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeReject$Xml$Response(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountOrganizationService.AccountOrganizationPrototypeRejectPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * Deny invitation to join organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountOrganizationPrototypeReject$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeReject$Xml(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.accountOrganizationPrototypeReject$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Deny invitation to join organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountOrganizationPrototypeReject$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeReject$Javascript$Response(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountOrganizationService.AccountOrganizationPrototypeRejectPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * Deny invitation to join organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountOrganizationPrototypeReject$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeReject$Javascript(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.accountOrganizationPrototypeReject$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Path part for operation accountOrganizationPrototypeEnable
   */
  static readonly AccountOrganizationPrototypeEnablePath = '/v1/AccountOrganizations/{id}/enable';

  /**
   * Enable users access to organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountOrganizationPrototypeEnable$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeEnable$Json$Response(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountOrganizationService.AccountOrganizationPrototypeEnablePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * Enable users access to organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountOrganizationPrototypeEnable$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeEnable$Json(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.accountOrganizationPrototypeEnable$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Enable users access to organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountOrganizationPrototypeEnable$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeEnable$Xml$Response(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountOrganizationService.AccountOrganizationPrototypeEnablePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * Enable users access to organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountOrganizationPrototypeEnable$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeEnable$Xml(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.accountOrganizationPrototypeEnable$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Enable users access to organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountOrganizationPrototypeEnable$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeEnable$Javascript$Response(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountOrganizationService.AccountOrganizationPrototypeEnablePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * Enable users access to organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountOrganizationPrototypeEnable$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeEnable$Javascript(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.accountOrganizationPrototypeEnable$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Path part for operation accountOrganizationPrototypeDisable
   */
  static readonly AccountOrganizationPrototypeDisablePath = '/v1/AccountOrganizations/{id}/disable';

  /**
   * Disable users access to organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountOrganizationPrototypeDisable$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeDisable$Json$Response(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountOrganizationService.AccountOrganizationPrototypeDisablePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * Disable users access to organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountOrganizationPrototypeDisable$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeDisable$Json(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.accountOrganizationPrototypeDisable$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Disable users access to organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountOrganizationPrototypeDisable$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeDisable$Xml$Response(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountOrganizationService.AccountOrganizationPrototypeDisablePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * Disable users access to organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountOrganizationPrototypeDisable$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeDisable$Xml(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.accountOrganizationPrototypeDisable$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Disable users access to organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountOrganizationPrototypeDisable$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeDisable$Javascript$Response(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountOrganizationService.AccountOrganizationPrototypeDisablePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * Disable users access to organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountOrganizationPrototypeDisable$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountOrganizationPrototypeDisable$Javascript(params: {

    /**
     * AccountOrganization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.accountOrganizationPrototypeDisable$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Path part for operation accountOrganizationPrototypeChangeRole
   */
  static readonly AccountOrganizationPrototypeChangeRolePath = '/v1/AccountOrganizations/{id}/change-role';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountOrganizationPrototypeChangeRole$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountOrganizationPrototypeChangeRole$Json$Response(params: {

    /**
     * AccountOrganization id
     */
    id: string;
    body?: {

/**
 * Role of invitee, one of 'default', 'readonly' and 'admin'. Defaults to 'default'.
 */
'role'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountOrganizationService.AccountOrganizationPrototypeChangeRolePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountOrganizationPrototypeChangeRole$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountOrganizationPrototypeChangeRole$Json(params: {

    /**
     * AccountOrganization id
     */
    id: string;
    body?: {

/**
 * Role of invitee, one of 'default', 'readonly' and 'admin'. Defaults to 'default'.
 */
'role'?: string;
}
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.accountOrganizationPrototypeChangeRole$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountOrganizationPrototypeChangeRole$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountOrganizationPrototypeChangeRole$Xml$Response(params: {

    /**
     * AccountOrganization id
     */
    id: string;
    body?: {

/**
 * Role of invitee, one of 'default', 'readonly' and 'admin'. Defaults to 'default'.
 */
'role'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountOrganizationService.AccountOrganizationPrototypeChangeRolePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountOrganizationPrototypeChangeRole$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountOrganizationPrototypeChangeRole$Xml(params: {

    /**
     * AccountOrganization id
     */
    id: string;
    body?: {

/**
 * Role of invitee, one of 'default', 'readonly' and 'admin'. Defaults to 'default'.
 */
'role'?: string;
}
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.accountOrganizationPrototypeChangeRole$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountOrganizationPrototypeChangeRole$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountOrganizationPrototypeChangeRole$Javascript$Response(params: {

    /**
     * AccountOrganization id
     */
    id: string;
    body?: {

/**
 * Role of invitee, one of 'default', 'readonly' and 'admin'. Defaults to 'default'.
 */
'role'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccountOrganizationService.AccountOrganizationPrototypeChangeRolePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountOrganizationPrototypeChangeRole$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  accountOrganizationPrototypeChangeRole$Javascript(params: {

    /**
     * AccountOrganization id
     */
    id: string;
    body?: {

/**
 * Role of invitee, one of 'default', 'readonly' and 'admin'. Defaults to 'default'.
 */
'role'?: string;
}
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.accountOrganizationPrototypeChangeRole$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

}
