/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Recurrence } from '../models/recurrence';

@Injectable({
  providedIn: 'root',
})
export class RecurrenceService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation recurrenceFindById
   */
  static readonly RecurrenceFindByIdPath = '/v1/Recurrences/{id}';

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceFindById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  recurrenceFindById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceFindById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recurrenceFindById$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.recurrenceFindById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceFindById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  recurrenceFindById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceFindById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recurrenceFindById$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.recurrenceFindById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceFindById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  recurrenceFindById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceFindById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recurrenceFindById$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.recurrenceFindById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Path part for operation recurrenceReplaceByIdPutRecurrencesId
   */
  static readonly RecurrenceReplaceByIdPutRecurrencesIdPath = '/v1/Recurrences/{id}';

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceReplaceByIdPutRecurrencesId$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  recurrenceReplaceByIdPutRecurrencesId$Json$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceReplaceByIdPutRecurrencesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceReplaceByIdPutRecurrencesId$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  recurrenceReplaceByIdPutRecurrencesId$Json$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.recurrenceReplaceByIdPutRecurrencesId$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceReplaceByIdPutRecurrencesId$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  recurrenceReplaceByIdPutRecurrencesId$Json$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceReplaceByIdPutRecurrencesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceReplaceByIdPutRecurrencesId$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  recurrenceReplaceByIdPutRecurrencesId$Json$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.recurrenceReplaceByIdPutRecurrencesId$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceReplaceByIdPutRecurrencesId$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  recurrenceReplaceByIdPutRecurrencesId$Json$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceReplaceByIdPutRecurrencesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceReplaceByIdPutRecurrencesId$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  recurrenceReplaceByIdPutRecurrencesId$Json$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.recurrenceReplaceByIdPutRecurrencesId$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceReplaceByIdPutRecurrencesId$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  recurrenceReplaceByIdPutRecurrencesId$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceReplaceByIdPutRecurrencesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceReplaceByIdPutRecurrencesId$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  recurrenceReplaceByIdPutRecurrencesId$XWwwFormUrlencoded$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.recurrenceReplaceByIdPutRecurrencesId$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceReplaceByIdPutRecurrencesId$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  recurrenceReplaceByIdPutRecurrencesId$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceReplaceByIdPutRecurrencesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceReplaceByIdPutRecurrencesId$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  recurrenceReplaceByIdPutRecurrencesId$XWwwFormUrlencoded$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.recurrenceReplaceByIdPutRecurrencesId$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceReplaceByIdPutRecurrencesId$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  recurrenceReplaceByIdPutRecurrencesId$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceReplaceByIdPutRecurrencesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceReplaceByIdPutRecurrencesId$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  recurrenceReplaceByIdPutRecurrencesId$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.recurrenceReplaceByIdPutRecurrencesId$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceReplaceByIdPutRecurrencesId$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  recurrenceReplaceByIdPutRecurrencesId$Xml$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceReplaceByIdPutRecurrencesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceReplaceByIdPutRecurrencesId$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  recurrenceReplaceByIdPutRecurrencesId$Xml$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.recurrenceReplaceByIdPutRecurrencesId$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceReplaceByIdPutRecurrencesId$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  recurrenceReplaceByIdPutRecurrencesId$Xml$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceReplaceByIdPutRecurrencesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceReplaceByIdPutRecurrencesId$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  recurrenceReplaceByIdPutRecurrencesId$Xml$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.recurrenceReplaceByIdPutRecurrencesId$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceReplaceByIdPutRecurrencesId$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  recurrenceReplaceByIdPutRecurrencesId$Xml$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceReplaceByIdPutRecurrencesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceReplaceByIdPutRecurrencesId$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  recurrenceReplaceByIdPutRecurrencesId$Xml$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.recurrenceReplaceByIdPutRecurrencesId$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Path part for operation recurrenceDeleteById
   */
  static readonly RecurrenceDeleteByIdPath = '/v1/Recurrences/{id}';

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceDeleteById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  recurrenceDeleteById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceDeleteById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recurrenceDeleteById$Json(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.recurrenceDeleteById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceDeleteById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  recurrenceDeleteById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceDeleteById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recurrenceDeleteById$Xml(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.recurrenceDeleteById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceDeleteById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  recurrenceDeleteById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceDeleteById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recurrenceDeleteById$Javascript(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.recurrenceDeleteById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation recurrenceReplaceByIdPostRecurrencesIdReplace
   */
  static readonly RecurrenceReplaceByIdPostRecurrencesIdReplacePath = '/v1/Recurrences/{id}/replace';

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceReplaceByIdPostRecurrencesIdReplace$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  recurrenceReplaceByIdPostRecurrencesIdReplace$Json$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceReplaceByIdPostRecurrencesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceReplaceByIdPostRecurrencesIdReplace$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  recurrenceReplaceByIdPostRecurrencesIdReplace$Json$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.recurrenceReplaceByIdPostRecurrencesIdReplace$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceReplaceByIdPostRecurrencesIdReplace$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  recurrenceReplaceByIdPostRecurrencesIdReplace$Json$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceReplaceByIdPostRecurrencesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceReplaceByIdPostRecurrencesIdReplace$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  recurrenceReplaceByIdPostRecurrencesIdReplace$Json$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.recurrenceReplaceByIdPostRecurrencesIdReplace$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceReplaceByIdPostRecurrencesIdReplace$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  recurrenceReplaceByIdPostRecurrencesIdReplace$Json$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceReplaceByIdPostRecurrencesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceReplaceByIdPostRecurrencesIdReplace$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  recurrenceReplaceByIdPostRecurrencesIdReplace$Json$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.recurrenceReplaceByIdPostRecurrencesIdReplace$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceReplaceByIdPostRecurrencesIdReplace$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  recurrenceReplaceByIdPostRecurrencesIdReplace$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceReplaceByIdPostRecurrencesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceReplaceByIdPostRecurrencesIdReplace$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  recurrenceReplaceByIdPostRecurrencesIdReplace$XWwwFormUrlencoded$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.recurrenceReplaceByIdPostRecurrencesIdReplace$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceReplaceByIdPostRecurrencesIdReplace$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  recurrenceReplaceByIdPostRecurrencesIdReplace$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceReplaceByIdPostRecurrencesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceReplaceByIdPostRecurrencesIdReplace$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  recurrenceReplaceByIdPostRecurrencesIdReplace$XWwwFormUrlencoded$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.recurrenceReplaceByIdPostRecurrencesIdReplace$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceReplaceByIdPostRecurrencesIdReplace$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  recurrenceReplaceByIdPostRecurrencesIdReplace$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceReplaceByIdPostRecurrencesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceReplaceByIdPostRecurrencesIdReplace$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  recurrenceReplaceByIdPostRecurrencesIdReplace$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.recurrenceReplaceByIdPostRecurrencesIdReplace$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceReplaceByIdPostRecurrencesIdReplace$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  recurrenceReplaceByIdPostRecurrencesIdReplace$Xml$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceReplaceByIdPostRecurrencesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceReplaceByIdPostRecurrencesIdReplace$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  recurrenceReplaceByIdPostRecurrencesIdReplace$Xml$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.recurrenceReplaceByIdPostRecurrencesIdReplace$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceReplaceByIdPostRecurrencesIdReplace$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  recurrenceReplaceByIdPostRecurrencesIdReplace$Xml$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceReplaceByIdPostRecurrencesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceReplaceByIdPostRecurrencesIdReplace$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  recurrenceReplaceByIdPostRecurrencesIdReplace$Xml$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.recurrenceReplaceByIdPostRecurrencesIdReplace$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceReplaceByIdPostRecurrencesIdReplace$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  recurrenceReplaceByIdPostRecurrencesIdReplace$Xml$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceReplaceByIdPostRecurrencesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceReplaceByIdPostRecurrencesIdReplace$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  recurrenceReplaceByIdPostRecurrencesIdReplace$Xml$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.recurrenceReplaceByIdPostRecurrencesIdReplace$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Path part for operation recurrenceRunCron
   */
  static readonly RecurrenceRunCronPath = '/v1/Recurrences/cron';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceRunCron$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  recurrenceRunCron$Json$Response(params?: {
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceRunCronPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceRunCron$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recurrenceRunCron$Json(params?: {
    date?: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.recurrenceRunCron$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceRunCron$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  recurrenceRunCron$Xml$Response(params?: {
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceRunCronPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceRunCron$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recurrenceRunCron$Xml(params?: {
    date?: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.recurrenceRunCron$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recurrenceRunCron$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  recurrenceRunCron$Javascript$Response(params?: {
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, RecurrenceService.RecurrenceRunCronPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recurrenceRunCron$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recurrenceRunCron$Javascript(params?: {
    date?: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.recurrenceRunCron$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

}
