/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ClientOrganizationControllerService } from './services/client-organization-controller.service';
import { HealthControllerService } from './services/health-controller.service';
import { OrganizationClientControllerService } from './services/organization-client-controller.service';
import { AccountControllerService } from './services/account-controller.service';
import { DocumentPaymentIntentControllerService } from './services/document-payment-intent-controller.service';
import { DocumentStripeConnectPaymentIntentControllerService } from './services/document-stripe-connect-payment-intent-controller.service';
import { AtcudSeriesControllerService } from './services/atcud-series-controller.service';
import { OrganizationMerchantLinkControllerService } from './services/organization-merchant-link-controller.service';
import { OrganizationMerchantControllerService } from './services/organization-merchant-controller.service';
import { SaftPtControllerService } from './services/saft-pt-controller.service';
import { OrganizationStripeConnectAccountLinkControllerService } from './services/organization-stripe-connect-account-link-controller.service';
import { OrganizationStripeConnectAccountControllerService } from './services/organization-stripe-connect-account-controller.service';
import { PaymentProcessingControllerService } from './services/payment-processing-controller.service';
import { AccountPaymentProcessingControllerService } from './services/account-payment-processing-controller.service';
import { StripeConnectAccountControllerService } from './services/stripe-connect-account-controller.service';
import { StripeConnectControllerService } from './services/stripe-connect-controller.service';
import { AccountStripeConnectControllerService } from './services/account-stripe-connect-controller.service';
import { InvoiceControllerService } from './services/invoice-controller.service';
import { OrganizationInvoiceControllerService } from './services/organization-invoice-controller.service';
import { DocumentService } from './services/document.service';
import { ItemService } from './services/item.service';
import { TaxService } from './services/tax.service';
import { ClientService } from './services/client.service';
import { OrganizationService } from './services/organization.service';
import { ShopifyService } from './services/shopify.service';
import { StripeService } from './services/stripe.service';
import { TicketingHubService } from './services/ticketing-hub.service';
import { CurrencyService } from './services/currency.service';
import { AccountService } from './services/account.service';
import { RecurrenceService } from './services/recurrence.service';
import { PaymentService } from './services/payment.service';
import { DefaultService } from './services/default.service';
import { CompanyService } from './services/company.service';
import { SubscriptionService } from './services/subscription.service';
import { PlanService } from './services/plan.service';
import { WhiteLabelService } from './services/white-label.service';
import { ContainerService } from './services/container.service';
import { MessageService } from './services/message.service';
import { BusinessPremiseService } from './services/business-premise.service';
import { AtcudSeriesService } from './services/atcud-series.service';
import { CountryService } from './services/country.service';
import { TrackingService } from './services/tracking.service';
import { ElectronicDeviceService } from './services/electronic-device.service';
import { CertificateService } from './services/certificate.service';
import { GeoIpService } from './services/geo-ip.service';
import { AccountOrganizationService } from './services/account-organization.service';
import { FileMetaService } from './services/file-meta.service';
import { UnitService } from './services/unit.service';
import { CategoryService } from './services/category.service';
import { DocumentCategoryService } from './services/document-category.service';
import { PriceListService } from './services/price-list.service';
import { PriceService } from './services/price.service';
import { WarehouseService } from './services/warehouse.service';
import { WebhookService } from './services/webhook.service';
import { TyplessService } from './services/typless.service';
import { OrderService } from './services/order.service';
import { Magento1Service } from './services/magento-1.service';
import { WoocommerceService } from './services/woocommerce.service';
import { CustomIntegrationService } from './services/custom-integration.service';
import { ThrivecartService } from './services/thrivecart.service';
import { BokunService } from './services/bokun.service';
import { ShopamineService } from './services/shopamine.service';
import { SendgridService } from './services/sendgrid.service';
import { LogService } from './services/log.service';
import { AwssesService } from './services/awsses.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ClientOrganizationControllerService,
    HealthControllerService,
    OrganizationClientControllerService,
    AccountControllerService,
    DocumentPaymentIntentControllerService,
    DocumentStripeConnectPaymentIntentControllerService,
    AtcudSeriesControllerService,
    OrganizationMerchantLinkControllerService,
    OrganizationMerchantControllerService,
    SaftPtControllerService,
    OrganizationStripeConnectAccountLinkControllerService,
    OrganizationStripeConnectAccountControllerService,
    PaymentProcessingControllerService,
    AccountPaymentProcessingControllerService,
    StripeConnectAccountControllerService,
    StripeConnectControllerService,
    AccountStripeConnectControllerService,
    InvoiceControllerService,
    OrganizationInvoiceControllerService,
    DocumentService,
    ItemService,
    TaxService,
    ClientService,
    OrganizationService,
    ShopifyService,
    StripeService,
    TicketingHubService,
    CurrencyService,
    AccountService,
    RecurrenceService,
    PaymentService,
    DefaultService,
    CompanyService,
    SubscriptionService,
    PlanService,
    WhiteLabelService,
    ContainerService,
    MessageService,
    BusinessPremiseService,
    AtcudSeriesService,
    CountryService,
    TrackingService,
    ElectronicDeviceService,
    CertificateService,
    GeoIpService,
    AccountOrganizationService,
    FileMetaService,
    UnitService,
    CategoryService,
    DocumentCategoryService,
    PriceListService,
    PriceService,
    WarehouseService,
    WebhookService,
    TyplessService,
    OrderService,
    Magento1Service,
    WoocommerceService,
    CustomIntegrationService,
    ThrivecartService,
    BokunService,
    ShopamineService,
    SendgridService,
    LogService,
    AwssesService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
