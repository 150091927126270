/* tslint:disable */
import {
  Document,
  Client,
  Item,
  Tax,
  Shopify,
  Stripe,
  TicketingHub,
  Activity,
  Recurrence,
  Payment,
  Default,
  BusinessPremise,
  ElectronicDevice,
  Certificate,
  Account,
  AccountOrganization,
  Subscription,
  Unit,
  Category,
  DocumentCategory,
  PriceList,
  Warehouse,
  Webhook,
  Typless,
  Order,
  Magento1,
  Woocommerce,
  CustomIntegration,
  Bokun,
  Shopamine,
  Data
} from '../index';

declare var Object: any;
export interface OrganizationInterface {
  "locale": string;
  "timezone"?: string;
  "supportPin"?: number;
  "size"?: string;
  "industry"?: string;
  "activatedAt"?: Date;
  "deactivatedAt"?: Date;
  "sandbox"?: boolean;
  "name": string;
  "address"?: string;
  "address2"?: string;
  "city"?: string;
  "zip"?: string;
  "state"?: string;
  "country"?: string;
  "countryAlpha2Code"?: string;
  "taxNumber"?: string;
  "taxSubject"?: boolean;
  "companyNumber"?: string;
  "IBAN"?: string;
  "bank"?: string;
  "SWIFT"?: string;
  "website"?: string;
  "email"?: string;
  "phone"?: string;
  "id"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "companyId"?: string;
  "custom"?: any;
  "_data"?: Array<any>;
  documents?: Document[];
  clients?: Client[];
  items?: Item[];
  taxes?: Tax[];
  shopify?: Shopify[];
  stripe?: Stripe[];
  ticketingHub?: TicketingHub[];
  activities?: Activity[];
  recurrences?: Recurrence[];
  payments?: Payment[];
  _defaults?: Default[];
  businessPremises?: BusinessPremise[];
  electronicDevices?: ElectronicDevice[];
  certificates?: Certificate[];
  accounts?: Account[];
  accountOrganizations?: AccountOrganization[];
  subscriptions?: Subscription[];
  organizationSubscription?: any;
  units?: Unit[];
  categories?: Category[];
  documentCategories?: DocumentCategory[];
  priceLists?: PriceList[];
  warehouses?: Warehouse[];
  webhooks?: Webhook[];
  typlesses?: Typless[];
  orders?: Order[];
  magento1?: Magento1[];
  woocommerce?: Woocommerce[];
  customIntegrations?: CustomIntegration[];
  bokun?: Bokun[];
  shopamine?: Shopamine[];
  data?: Data[];
}

export class Organization implements OrganizationInterface {
  "locale": string;
  "timezone": string;
  "supportPin": number;
  "size": string;
  "industry": string;
  "activatedAt": Date;
  "deactivatedAt": Date;
  "sandbox": boolean;
  "name": string;
  "address": string;
  "address2": string;
  "city": string;
  "zip": string;
  "state": string;
  "country": string;
  "countryAlpha2Code": string;
  "taxNumber": string;
  "taxSubject": boolean;
  "companyNumber": string;
  "IBAN": string;
  "bank": string;
  "SWIFT": string;
  "website": string;
  "email": string;
  "phone": string;
  "id": any;
  "createdAt": Date;
  "updatedAt": Date;
  "companyId": string;
  "custom": any;
  "_data": Array<any>;
  documents: Document[];
  clients: Client[];
  items: Item[];
  taxes: Tax[];
  shopify: Shopify[];
  stripe: Stripe[];
  ticketingHub: TicketingHub[];
  activities: Activity[];
  recurrences: Recurrence[];
  payments: Payment[];
  _defaults: Default[];
  businessPremises: BusinessPremise[];
  electronicDevices: ElectronicDevice[];
  certificates: Certificate[];
  accounts: Account[];
  accountOrganizations: AccountOrganization[];
  subscriptions: Subscription[];
  organizationSubscription: any;
  units: Unit[];
  categories: Category[];
  documentCategories: DocumentCategory[];
  priceLists: PriceList[];
  warehouses: Warehouse[];
  webhooks: Webhook[];
  typlesses: Typless[];
  orders: Order[];
  magento1: Magento1[];
  woocommerce: Woocommerce[];
  customIntegrations: CustomIntegration[];
  bokun: Bokun[];
  shopamine: Shopamine[];
  data: Data[];
  tax_clause: any; // For data preview only
  constructor(data?: OrganizationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Organization`.
   */
  public static getModelName() {
    return "Organization";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Organization for dynamic purposes.
  **/
  public static factory(data: OrganizationInterface): Organization{
    return new Organization(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Organization',
      plural: 'Organizations',
      path: 'Organizations',
      idName: 'id',
      properties: {
        "locale": {
          name: 'locale',
          type: 'string'
        },
        "timezone": {
          name: 'timezone',
          type: 'string'
        },
        "supportPin": {
          name: 'supportPin',
          type: 'number'
        },
        "size": {
          name: 'size',
          type: 'string'
        },
        "industry": {
          name: 'industry',
          type: 'string'
        },
        "activatedAt": {
          name: 'activatedAt',
          type: 'Date'
        },
        "deactivatedAt": {
          name: 'deactivatedAt',
          type: 'Date'
        },
        "sandbox": {
          name: 'sandbox',
          type: 'boolean'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "address": {
          name: 'address',
          type: 'string'
        },
        "address2": {
          name: 'address2',
          type: 'string'
        },
        "city": {
          name: 'city',
          type: 'string'
        },
        "zip": {
          name: 'zip',
          type: 'string'
        },
        "state": {
          name: 'state',
          type: 'string'
        },
        "country": {
          name: 'country',
          type: 'string'
        },
        "countryAlpha2Code": {
          name: 'countryAlpha2Code',
          type: 'string'
        },
        "taxNumber": {
          name: 'taxNumber',
          type: 'string'
        },
        "taxSubject": {
          name: 'taxSubject',
          type: 'boolean'
        },
        "companyNumber": {
          name: 'companyNumber',
          type: 'string'
        },
        "IBAN": {
          name: 'IBAN',
          type: 'string'
        },
        "bank": {
          name: 'bank',
          type: 'string'
        },
        "SWIFT": {
          name: 'SWIFT',
          type: 'string'
        },
        "website": {
          name: 'website',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "phone": {
          name: 'phone',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        companyId: {
          name: 'companyId',
          type: 'string',
        },
        "custom": {
          name: 'custom',
          type: 'any',
          default: <any>null
        },
        "_data": {
          name: '_data',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
      },
      relations: {
        documents: {
          name: 'documents',
          type: 'Document[]',
          model: 'Document',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        clients: {
          name: 'clients',
          type: 'Client[]',
          model: 'Client',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        items: {
          name: 'items',
          type: 'Item[]',
          model: 'Item',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        taxes: {
          name: 'taxes',
          type: 'Tax[]',
          model: 'Tax',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        shopify: {
          name: 'shopify',
          type: 'Shopify[]',
          model: 'Shopify',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        stripe: {
          name: 'stripe',
          type: 'Stripe[]',
          model: 'Stripe',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        ticketingHub: {
          name: 'ticketingHub',
          type: 'TicketingHub[]',
          model: 'TicketingHub',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        activities: {
          name: 'activities',
          type: 'Activity[]',
          model: 'Activity',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        recurrences: {
          name: 'recurrences',
          type: 'Recurrence[]',
          model: 'Recurrence',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        payments: {
          name: 'payments',
          type: 'Payment[]',
          model: 'Payment',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        _defaults: {
          name: '_defaults',
          type: 'Default[]',
          model: 'Default',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        businessPremises: {
          name: 'businessPremises',
          type: 'BusinessPremise[]',
          model: 'BusinessPremise',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        electronicDevices: {
          name: 'electronicDevices',
          type: 'ElectronicDevice[]',
          model: 'ElectronicDevice',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        certificates: {
          name: 'certificates',
          type: 'Certificate[]',
          model: 'Certificate',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        accounts: {
          name: 'accounts',
          type: 'Account[]',
          model: 'Account',
          relationType: 'hasMany',
          modelThrough: 'AccountOrganization',
          keyThrough: 'accountId',
          keyFrom: 'id',
          keyTo: 'organizationId'
        },
        accountOrganizations: {
          name: 'accountOrganizations',
          type: 'AccountOrganization[]',
          model: 'AccountOrganization',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        subscriptions: {
          name: 'subscriptions',
          type: 'Subscription[]',
          model: 'Subscription',
          relationType: 'hasMany',
          modelThrough: 'OrganizationSubscription',
          keyThrough: 'subscriptionId',
          keyFrom: 'id',
          keyTo: 'organizationId'
        },
        organizationSubscription: {
          name: 'organizationSubscription',
          type: 'any',
          model: '',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        units: {
          name: 'units',
          type: 'Unit[]',
          model: 'Unit',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        categories: {
          name: 'categories',
          type: 'Category[]',
          model: 'Category',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        documentCategories: {
          name: 'documentCategories',
          type: 'DocumentCategory[]',
          model: 'DocumentCategory',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        priceLists: {
          name: 'priceLists',
          type: 'PriceList[]',
          model: 'PriceList',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        warehouses: {
          name: 'warehouses',
          type: 'Warehouse[]',
          model: 'Warehouse',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        webhooks: {
          name: 'webhooks',
          type: 'Webhook[]',
          model: 'Webhook',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        typlesses: {
          name: 'typlesses',
          type: 'Typless[]',
          model: 'Typless',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        orders: {
          name: 'orders',
          type: 'Order[]',
          model: 'Order',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        magento1: {
          name: 'magento1',
          type: 'Magento1[]',
          model: 'Magento1',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        woocommerce: {
          name: 'woocommerce',
          type: 'Woocommerce[]',
          model: 'Woocommerce',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        customIntegrations: {
          name: 'customIntegrations',
          type: 'CustomIntegration[]',
          model: 'CustomIntegration',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        bokun: {
          name: 'bokun',
          type: 'Bokun[]',
          model: 'Bokun',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        shopamine: {
          name: 'shopamine',
          type: 'Shopamine[]',
          model: 'Shopamine',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organizationId'
        },
        data: {
          name: 'data',
          type: 'Data[]',
          model: 'Data',
          relationType: 'embedsMany',
                  keyFrom: '_data',
          keyTo: 'id'
        },
      }
    }
  }
}
