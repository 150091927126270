/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Comment } from '../models/comment';
import { Document } from '../models/document';
import { DocumentCategory } from '../models/document-category';
import { Payment } from '../models/payment';
import { Recurrence } from '../models/recurrence';
import { NewDocumentCategory } from '../models/new-document-category';
import { NewPayment } from '../models/new-payment';

@Injectable({
  providedIn: 'root',
})
export class DocumentService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation documentPrototypeGetRecurrence
   */
  static readonly DocumentPrototypeGetRecurrencePath = '/v1/Documents/{id}/recurrence';

  /**
   * Fetches hasOne relation recurrence.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeGetRecurrence$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeGetRecurrence$Json$Response(params: {

    /**
     * Document id
     */
    id: string;
    refresh?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeGetRecurrencePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('refresh', params.refresh, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Fetches hasOne relation recurrence.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeGetRecurrence$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeGetRecurrence$Json(params: {

    /**
     * Document id
     */
    id: string;
    refresh?: boolean;
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.documentPrototypeGetRecurrence$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Fetches hasOne relation recurrence.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeGetRecurrence$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeGetRecurrence$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;
    refresh?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeGetRecurrencePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('refresh', params.refresh, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Fetches hasOne relation recurrence.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeGetRecurrence$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeGetRecurrence$Xml(params: {

    /**
     * Document id
     */
    id: string;
    refresh?: boolean;
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.documentPrototypeGetRecurrence$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Fetches hasOne relation recurrence.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeGetRecurrence$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeGetRecurrence$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;
    refresh?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeGetRecurrencePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('refresh', params.refresh, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Fetches hasOne relation recurrence.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeGetRecurrence$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeGetRecurrence$Javascript(params: {

    /**
     * Document id
     */
    id: string;
    refresh?: boolean;
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.documentPrototypeGetRecurrence$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Path part for operation documentPrototypeCreateRecurrence
   */
  static readonly DocumentPrototypeCreateRecurrencePath = '/v1/Documents/{id}/recurrence';

  /**
   * Creates a new instance in recurrence of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateRecurrence$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreateRecurrence$Json$Json$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateRecurrencePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Creates a new instance in recurrence of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateRecurrence$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreateRecurrence$Json$Json(params: {

    /**
     * Document id
     */
    id: string;
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.documentPrototypeCreateRecurrence$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Creates a new instance in recurrence of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateRecurrence$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreateRecurrence$Json$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateRecurrencePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Creates a new instance in recurrence of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateRecurrence$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreateRecurrence$Json$Xml(params: {

    /**
     * Document id
     */
    id: string;
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.documentPrototypeCreateRecurrence$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Creates a new instance in recurrence of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateRecurrence$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreateRecurrence$Json$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateRecurrencePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Creates a new instance in recurrence of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateRecurrence$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreateRecurrence$Json$Javascript(params: {

    /**
     * Document id
     */
    id: string;
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.documentPrototypeCreateRecurrence$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Creates a new instance in recurrence of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateRecurrence$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreateRecurrence$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateRecurrencePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Creates a new instance in recurrence of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateRecurrence$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreateRecurrence$XWwwFormUrlencoded$Json(params: {

    /**
     * Document id
     */
    id: string;
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.documentPrototypeCreateRecurrence$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Creates a new instance in recurrence of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateRecurrence$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreateRecurrence$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateRecurrencePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Creates a new instance in recurrence of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateRecurrence$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreateRecurrence$XWwwFormUrlencoded$Xml(params: {

    /**
     * Document id
     */
    id: string;
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.documentPrototypeCreateRecurrence$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Creates a new instance in recurrence of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateRecurrence$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreateRecurrence$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateRecurrencePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Creates a new instance in recurrence of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateRecurrence$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreateRecurrence$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Document id
     */
    id: string;
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.documentPrototypeCreateRecurrence$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Creates a new instance in recurrence of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateRecurrence$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreateRecurrence$Xml$Json$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateRecurrencePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Creates a new instance in recurrence of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateRecurrence$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreateRecurrence$Xml$Json(params: {

    /**
     * Document id
     */
    id: string;
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.documentPrototypeCreateRecurrence$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Creates a new instance in recurrence of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateRecurrence$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreateRecurrence$Xml$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateRecurrencePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Creates a new instance in recurrence of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateRecurrence$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreateRecurrence$Xml$Xml(params: {

    /**
     * Document id
     */
    id: string;
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.documentPrototypeCreateRecurrence$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Creates a new instance in recurrence of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateRecurrence$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreateRecurrence$Xml$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: Recurrence
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Recurrence>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateRecurrencePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Recurrence>;
      })
    );
  }

  /**
   * Creates a new instance in recurrence of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateRecurrence$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreateRecurrence$Xml$Javascript(params: {

    /**
     * Document id
     */
    id: string;
    body?: Recurrence
  },
  context?: HttpContext

): Observable<Recurrence> {

    return this.documentPrototypeCreateRecurrence$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Recurrence>) => r.body as Recurrence)
    );
  }

  /**
   * Path part for operation documentPrototypeUpdateByIdComments
   */
  static readonly DocumentPrototypeUpdateByIdCommentsPath = '/v1/Documents/{id}/comments/{fk}';

  /**
   * Update a related item by id for comments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeUpdateByIdComments$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeUpdateByIdComments$Json$Json$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Foreign key for comments
     */
    fk: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Comment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeUpdateByIdCommentsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Comment>;
      })
    );
  }

  /**
   * Update a related item by id for comments.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeUpdateByIdComments$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeUpdateByIdComments$Json$Json(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Foreign key for comments
     */
    fk: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<Comment> {

    return this.documentPrototypeUpdateByIdComments$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Comment>) => r.body as Comment)
    );
  }

  /**
   * Update a related item by id for comments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeUpdateByIdComments$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeUpdateByIdComments$Json$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Foreign key for comments
     */
    fk: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Comment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeUpdateByIdCommentsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Comment>;
      })
    );
  }

  /**
   * Update a related item by id for comments.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeUpdateByIdComments$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeUpdateByIdComments$Json$Xml(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Foreign key for comments
     */
    fk: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<Comment> {

    return this.documentPrototypeUpdateByIdComments$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Comment>) => r.body as Comment)
    );
  }

  /**
   * Update a related item by id for comments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeUpdateByIdComments$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeUpdateByIdComments$Json$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Foreign key for comments
     */
    fk: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Comment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeUpdateByIdCommentsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Comment>;
      })
    );
  }

  /**
   * Update a related item by id for comments.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeUpdateByIdComments$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeUpdateByIdComments$Json$Javascript(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Foreign key for comments
     */
    fk: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<Comment> {

    return this.documentPrototypeUpdateByIdComments$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Comment>) => r.body as Comment)
    );
  }

  /**
   * Update a related item by id for comments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeUpdateByIdComments$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeUpdateByIdComments$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Foreign key for comments
     */
    fk: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Comment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeUpdateByIdCommentsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Comment>;
      })
    );
  }

  /**
   * Update a related item by id for comments.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeUpdateByIdComments$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeUpdateByIdComments$XWwwFormUrlencoded$Json(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Foreign key for comments
     */
    fk: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<Comment> {

    return this.documentPrototypeUpdateByIdComments$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Comment>) => r.body as Comment)
    );
  }

  /**
   * Update a related item by id for comments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeUpdateByIdComments$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeUpdateByIdComments$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Foreign key for comments
     */
    fk: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Comment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeUpdateByIdCommentsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Comment>;
      })
    );
  }

  /**
   * Update a related item by id for comments.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeUpdateByIdComments$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeUpdateByIdComments$XWwwFormUrlencoded$Xml(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Foreign key for comments
     */
    fk: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<Comment> {

    return this.documentPrototypeUpdateByIdComments$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Comment>) => r.body as Comment)
    );
  }

  /**
   * Update a related item by id for comments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeUpdateByIdComments$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeUpdateByIdComments$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Foreign key for comments
     */
    fk: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Comment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeUpdateByIdCommentsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Comment>;
      })
    );
  }

  /**
   * Update a related item by id for comments.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeUpdateByIdComments$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeUpdateByIdComments$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Foreign key for comments
     */
    fk: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<Comment> {

    return this.documentPrototypeUpdateByIdComments$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Comment>) => r.body as Comment)
    );
  }

  /**
   * Update a related item by id for comments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeUpdateByIdComments$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeUpdateByIdComments$Xml$Json$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Foreign key for comments
     */
    fk: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Comment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeUpdateByIdCommentsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Comment>;
      })
    );
  }

  /**
   * Update a related item by id for comments.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeUpdateByIdComments$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeUpdateByIdComments$Xml$Json(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Foreign key for comments
     */
    fk: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<Comment> {

    return this.documentPrototypeUpdateByIdComments$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Comment>) => r.body as Comment)
    );
  }

  /**
   * Update a related item by id for comments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeUpdateByIdComments$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeUpdateByIdComments$Xml$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Foreign key for comments
     */
    fk: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Comment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeUpdateByIdCommentsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Comment>;
      })
    );
  }

  /**
   * Update a related item by id for comments.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeUpdateByIdComments$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeUpdateByIdComments$Xml$Xml(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Foreign key for comments
     */
    fk: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<Comment> {

    return this.documentPrototypeUpdateByIdComments$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Comment>) => r.body as Comment)
    );
  }

  /**
   * Update a related item by id for comments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeUpdateByIdComments$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeUpdateByIdComments$Xml$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Foreign key for comments
     */
    fk: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Comment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeUpdateByIdCommentsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Comment>;
      })
    );
  }

  /**
   * Update a related item by id for comments.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeUpdateByIdComments$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeUpdateByIdComments$Xml$Javascript(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Foreign key for comments
     */
    fk: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<Comment> {

    return this.documentPrototypeUpdateByIdComments$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Comment>) => r.body as Comment)
    );
  }

  /**
   * Path part for operation documentPrototypeDestroyByIdComments
   */
  static readonly DocumentPrototypeDestroyByIdCommentsPath = '/v1/Documents/{id}/comments/{fk}';

  /**
   * Delete a related item by id for comments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeDestroyByIdComments()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeDestroyByIdComments$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Foreign key for comments
     */
    fk: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeDestroyByIdCommentsPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete a related item by id for comments.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeDestroyByIdComments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeDestroyByIdComments(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Foreign key for comments
     */
    fk: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.documentPrototypeDestroyByIdComments$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation documentPrototypeGetPayments
   */
  static readonly DocumentPrototypeGetPaymentsPath = '/v1/Documents/{id}/payments';

  /**
   * Queries payments of Document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeGetPayments$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeGetPayments$Json$Response(params: {

    /**
     * Document id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Payment>>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeGetPaymentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Payment>>;
      })
    );
  }

  /**
   * Queries payments of Document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeGetPayments$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeGetPayments$Json(params: {

    /**
     * Document id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Payment>> {

    return this.documentPrototypeGetPayments$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Payment>>) => r.body as Array<Payment>)
    );
  }

  /**
   * Queries payments of Document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeGetPayments$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeGetPayments$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Payment>>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeGetPaymentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Payment>>;
      })
    );
  }

  /**
   * Queries payments of Document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeGetPayments$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeGetPayments$Xml(params: {

    /**
     * Document id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Payment>> {

    return this.documentPrototypeGetPayments$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Payment>>) => r.body as Array<Payment>)
    );
  }

  /**
   * Queries payments of Document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeGetPayments$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeGetPayments$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Payment>>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeGetPaymentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Payment>>;
      })
    );
  }

  /**
   * Queries payments of Document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeGetPayments$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeGetPayments$Javascript(params: {

    /**
     * Document id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Payment>> {

    return this.documentPrototypeGetPayments$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Payment>>) => r.body as Array<Payment>)
    );
  }

  /**
   * Path part for operation documentPrototypeCreatePayments
   */
  static readonly DocumentPrototypeCreatePaymentsPath = '/v1/Documents/{id}/payments';

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreatePayments$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreatePayments$Json$Json$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreatePaymentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreatePayments$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreatePayments$Json$Json(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.documentPrototypeCreatePayments$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreatePayments$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreatePayments$Json$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreatePaymentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreatePayments$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreatePayments$Json$Xml(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.documentPrototypeCreatePayments$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreatePayments$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreatePayments$Json$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreatePaymentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreatePayments$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreatePayments$Json$Javascript(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.documentPrototypeCreatePayments$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreatePayments$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreatePayments$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreatePaymentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreatePayments$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreatePayments$XWwwFormUrlencoded$Json(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.documentPrototypeCreatePayments$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreatePayments$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreatePayments$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreatePaymentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreatePayments$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreatePayments$XWwwFormUrlencoded$Xml(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.documentPrototypeCreatePayments$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreatePayments$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreatePayments$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreatePaymentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreatePayments$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreatePayments$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.documentPrototypeCreatePayments$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreatePayments$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreatePayments$Xml$Json$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreatePaymentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreatePayments$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreatePayments$Xml$Json(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.documentPrototypeCreatePayments$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreatePayments$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreatePayments$Xml$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreatePaymentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreatePayments$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreatePayments$Xml$Xml(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.documentPrototypeCreatePayments$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreatePayments$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreatePayments$Xml$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreatePaymentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreatePayments$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreatePayments$Xml$Javascript(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.documentPrototypeCreatePayments$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Path part for operation documentPrototypeCountPayments
   */
  static readonly DocumentPrototypeCountPaymentsPath = '/v1/Documents/{id}/payments/count';

  /**
   * Counts payments of Document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCountPayments$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeCountPayments$Json$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCountPaymentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts payments of Document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCountPayments$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeCountPayments$Json(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.documentPrototypeCountPayments$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts payments of Document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCountPayments$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeCountPayments$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCountPaymentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts payments of Document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCountPayments$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeCountPayments$Xml(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.documentPrototypeCountPayments$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts payments of Document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCountPayments$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeCountPayments$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCountPaymentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts payments of Document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCountPayments$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeCountPayments$Javascript(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.documentPrototypeCountPayments$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation documentPrototypeCreateComments
   */
  static readonly DocumentPrototypeCreateCommentsPath = '/v1/Documents/{id}/comments';

  /**
   * Creates a new instance in comments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateComments$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreateComments$Json$Json$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Comment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateCommentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Comment>;
      })
    );
  }

  /**
   * Creates a new instance in comments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateComments$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreateComments$Json$Json(params: {

    /**
     * Document id
     */
    id: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<Comment> {

    return this.documentPrototypeCreateComments$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Comment>) => r.body as Comment)
    );
  }

  /**
   * Creates a new instance in comments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateComments$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreateComments$Json$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Comment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateCommentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Comment>;
      })
    );
  }

  /**
   * Creates a new instance in comments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateComments$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreateComments$Json$Xml(params: {

    /**
     * Document id
     */
    id: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<Comment> {

    return this.documentPrototypeCreateComments$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Comment>) => r.body as Comment)
    );
  }

  /**
   * Creates a new instance in comments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateComments$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreateComments$Json$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Comment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateCommentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Comment>;
      })
    );
  }

  /**
   * Creates a new instance in comments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateComments$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreateComments$Json$Javascript(params: {

    /**
     * Document id
     */
    id: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<Comment> {

    return this.documentPrototypeCreateComments$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Comment>) => r.body as Comment)
    );
  }

  /**
   * Creates a new instance in comments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateComments$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreateComments$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Comment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateCommentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Comment>;
      })
    );
  }

  /**
   * Creates a new instance in comments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateComments$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreateComments$XWwwFormUrlencoded$Json(params: {

    /**
     * Document id
     */
    id: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<Comment> {

    return this.documentPrototypeCreateComments$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Comment>) => r.body as Comment)
    );
  }

  /**
   * Creates a new instance in comments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateComments$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreateComments$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Comment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateCommentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Comment>;
      })
    );
  }

  /**
   * Creates a new instance in comments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateComments$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreateComments$XWwwFormUrlencoded$Xml(params: {

    /**
     * Document id
     */
    id: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<Comment> {

    return this.documentPrototypeCreateComments$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Comment>) => r.body as Comment)
    );
  }

  /**
   * Creates a new instance in comments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateComments$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreateComments$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Comment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateCommentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Comment>;
      })
    );
  }

  /**
   * Creates a new instance in comments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateComments$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreateComments$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Document id
     */
    id: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<Comment> {

    return this.documentPrototypeCreateComments$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Comment>) => r.body as Comment)
    );
  }

  /**
   * Creates a new instance in comments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateComments$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreateComments$Xml$Json$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Comment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateCommentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Comment>;
      })
    );
  }

  /**
   * Creates a new instance in comments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateComments$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreateComments$Xml$Json(params: {

    /**
     * Document id
     */
    id: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<Comment> {

    return this.documentPrototypeCreateComments$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Comment>) => r.body as Comment)
    );
  }

  /**
   * Creates a new instance in comments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateComments$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreateComments$Xml$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Comment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateCommentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Comment>;
      })
    );
  }

  /**
   * Creates a new instance in comments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateComments$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreateComments$Xml$Xml(params: {

    /**
     * Document id
     */
    id: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<Comment> {

    return this.documentPrototypeCreateComments$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Comment>) => r.body as Comment)
    );
  }

  /**
   * Creates a new instance in comments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateComments$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreateComments$Xml$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Comment>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateCommentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Comment>;
      })
    );
  }

  /**
   * Creates a new instance in comments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateComments$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreateComments$Xml$Javascript(params: {

    /**
     * Document id
     */
    id: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<Comment> {

    return this.documentPrototypeCreateComments$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Comment>) => r.body as Comment)
    );
  }

  /**
   * Path part for operation documentPrototypeCreateDocumentCategories
   */
  static readonly DocumentPrototypeCreateDocumentCategoriesPath = '/v1/Documents/{id}/documentCategories';

  /**
   * Creates a new instance in documentCategories of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateDocumentCategories$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreateDocumentCategories$Json$Json$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewDocumentCategory
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DocumentCategory>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateDocumentCategoriesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentCategory>;
      })
    );
  }

  /**
   * Creates a new instance in documentCategories of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateDocumentCategories$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreateDocumentCategories$Json$Json(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewDocumentCategory
  },
  context?: HttpContext

): Observable<DocumentCategory> {

    return this.documentPrototypeCreateDocumentCategories$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<DocumentCategory>) => r.body as DocumentCategory)
    );
  }

  /**
   * Creates a new instance in documentCategories of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateDocumentCategories$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreateDocumentCategories$Json$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewDocumentCategory
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DocumentCategory>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateDocumentCategoriesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentCategory>;
      })
    );
  }

  /**
   * Creates a new instance in documentCategories of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateDocumentCategories$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreateDocumentCategories$Json$Xml(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewDocumentCategory
  },
  context?: HttpContext

): Observable<DocumentCategory> {

    return this.documentPrototypeCreateDocumentCategories$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<DocumentCategory>) => r.body as DocumentCategory)
    );
  }

  /**
   * Creates a new instance in documentCategories of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateDocumentCategories$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreateDocumentCategories$Json$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewDocumentCategory
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DocumentCategory>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateDocumentCategoriesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentCategory>;
      })
    );
  }

  /**
   * Creates a new instance in documentCategories of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateDocumentCategories$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypeCreateDocumentCategories$Json$Javascript(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewDocumentCategory
  },
  context?: HttpContext

): Observable<DocumentCategory> {

    return this.documentPrototypeCreateDocumentCategories$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<DocumentCategory>) => r.body as DocumentCategory)
    );
  }

  /**
   * Creates a new instance in documentCategories of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateDocumentCategories$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreateDocumentCategories$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewDocumentCategory
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DocumentCategory>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateDocumentCategoriesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentCategory>;
      })
    );
  }

  /**
   * Creates a new instance in documentCategories of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateDocumentCategories$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreateDocumentCategories$XWwwFormUrlencoded$Json(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewDocumentCategory
  },
  context?: HttpContext

): Observable<DocumentCategory> {

    return this.documentPrototypeCreateDocumentCategories$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<DocumentCategory>) => r.body as DocumentCategory)
    );
  }

  /**
   * Creates a new instance in documentCategories of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateDocumentCategories$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreateDocumentCategories$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewDocumentCategory
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DocumentCategory>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateDocumentCategoriesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentCategory>;
      })
    );
  }

  /**
   * Creates a new instance in documentCategories of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateDocumentCategories$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreateDocumentCategories$XWwwFormUrlencoded$Xml(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewDocumentCategory
  },
  context?: HttpContext

): Observable<DocumentCategory> {

    return this.documentPrototypeCreateDocumentCategories$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<DocumentCategory>) => r.body as DocumentCategory)
    );
  }

  /**
   * Creates a new instance in documentCategories of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateDocumentCategories$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreateDocumentCategories$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewDocumentCategory
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DocumentCategory>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateDocumentCategoriesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentCategory>;
      })
    );
  }

  /**
   * Creates a new instance in documentCategories of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateDocumentCategories$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeCreateDocumentCategories$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewDocumentCategory
  },
  context?: HttpContext

): Observable<DocumentCategory> {

    return this.documentPrototypeCreateDocumentCategories$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<DocumentCategory>) => r.body as DocumentCategory)
    );
  }

  /**
   * Creates a new instance in documentCategories of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateDocumentCategories$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreateDocumentCategories$Xml$Json$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewDocumentCategory
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DocumentCategory>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateDocumentCategoriesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentCategory>;
      })
    );
  }

  /**
   * Creates a new instance in documentCategories of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateDocumentCategories$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreateDocumentCategories$Xml$Json(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewDocumentCategory
  },
  context?: HttpContext

): Observable<DocumentCategory> {

    return this.documentPrototypeCreateDocumentCategories$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<DocumentCategory>) => r.body as DocumentCategory)
    );
  }

  /**
   * Creates a new instance in documentCategories of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateDocumentCategories$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreateDocumentCategories$Xml$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewDocumentCategory
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DocumentCategory>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateDocumentCategoriesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentCategory>;
      })
    );
  }

  /**
   * Creates a new instance in documentCategories of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateDocumentCategories$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreateDocumentCategories$Xml$Xml(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewDocumentCategory
  },
  context?: HttpContext

): Observable<DocumentCategory> {

    return this.documentPrototypeCreateDocumentCategories$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<DocumentCategory>) => r.body as DocumentCategory)
    );
  }

  /**
   * Creates a new instance in documentCategories of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCreateDocumentCategories$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreateDocumentCategories$Xml$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewDocumentCategory
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DocumentCategory>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCreateDocumentCategoriesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentCategory>;
      })
    );
  }

  /**
   * Creates a new instance in documentCategories of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCreateDocumentCategories$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypeCreateDocumentCategories$Xml$Javascript(params: {

    /**
     * Document id
     */
    id: string;
    body?: NewDocumentCategory
  },
  context?: HttpContext

): Observable<DocumentCategory> {

    return this.documentPrototypeCreateDocumentCategories$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<DocumentCategory>) => r.body as DocumentCategory)
    );
  }

  /**
   * Path part for operation documentFindById
   */
  static readonly DocumentFindByIdPath = '/v1/Documents/{id}';

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentFindById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentFindById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentFindById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentFindById$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentFindById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentFindById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentFindById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentFindById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentFindById$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentFindById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentFindById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentFindById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentFindById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentFindById$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentFindById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Path part for operation documentReplaceByIdPutDocumentsId
   */
  static readonly DocumentReplaceByIdPutDocumentsIdPath = '/v1/Documents/{id}';

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentReplaceByIdPutDocumentsId$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentReplaceByIdPutDocumentsId$Json$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentReplaceByIdPutDocumentsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentReplaceByIdPutDocumentsId$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentReplaceByIdPutDocumentsId$Json$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentReplaceByIdPutDocumentsId$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentReplaceByIdPutDocumentsId$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentReplaceByIdPutDocumentsId$Json$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentReplaceByIdPutDocumentsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentReplaceByIdPutDocumentsId$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentReplaceByIdPutDocumentsId$Json$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentReplaceByIdPutDocumentsId$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentReplaceByIdPutDocumentsId$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentReplaceByIdPutDocumentsId$Json$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentReplaceByIdPutDocumentsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentReplaceByIdPutDocumentsId$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentReplaceByIdPutDocumentsId$Json$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentReplaceByIdPutDocumentsId$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentReplaceByIdPutDocumentsId$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentReplaceByIdPutDocumentsId$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentReplaceByIdPutDocumentsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentReplaceByIdPutDocumentsId$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentReplaceByIdPutDocumentsId$XWwwFormUrlencoded$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentReplaceByIdPutDocumentsId$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentReplaceByIdPutDocumentsId$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentReplaceByIdPutDocumentsId$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentReplaceByIdPutDocumentsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentReplaceByIdPutDocumentsId$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentReplaceByIdPutDocumentsId$XWwwFormUrlencoded$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentReplaceByIdPutDocumentsId$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentReplaceByIdPutDocumentsId$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentReplaceByIdPutDocumentsId$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentReplaceByIdPutDocumentsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentReplaceByIdPutDocumentsId$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentReplaceByIdPutDocumentsId$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentReplaceByIdPutDocumentsId$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentReplaceByIdPutDocumentsId$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentReplaceByIdPutDocumentsId$Xml$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentReplaceByIdPutDocumentsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentReplaceByIdPutDocumentsId$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentReplaceByIdPutDocumentsId$Xml$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentReplaceByIdPutDocumentsId$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentReplaceByIdPutDocumentsId$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentReplaceByIdPutDocumentsId$Xml$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentReplaceByIdPutDocumentsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentReplaceByIdPutDocumentsId$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentReplaceByIdPutDocumentsId$Xml$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentReplaceByIdPutDocumentsId$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentReplaceByIdPutDocumentsId$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentReplaceByIdPutDocumentsId$Xml$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentReplaceByIdPutDocumentsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentReplaceByIdPutDocumentsId$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentReplaceByIdPutDocumentsId$Xml$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentReplaceByIdPutDocumentsId$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Path part for operation documentDeleteById
   */
  static readonly DocumentDeleteByIdPath = '/v1/Documents/{id}';

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentDeleteById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentDeleteById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentDeleteById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentDeleteById$Json(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.documentDeleteById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentDeleteById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentDeleteById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentDeleteById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentDeleteById$Xml(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.documentDeleteById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentDeleteById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentDeleteById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentDeleteById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentDeleteById$Javascript(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.documentDeleteById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation documentPrototypePatchAttributes
   */
  static readonly DocumentPrototypePatchAttributesPath = '/v1/Documents/{id}';

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypePatchAttributes$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypePatchAttributes$Json$Json$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypePatchAttributes$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypePatchAttributes$Json$Json(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentPrototypePatchAttributes$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypePatchAttributes$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypePatchAttributes$Json$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypePatchAttributes$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypePatchAttributes$Json$Xml(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentPrototypePatchAttributes$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypePatchAttributes$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypePatchAttributes$Json$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypePatchAttributes$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrototypePatchAttributes$Json$Javascript(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentPrototypePatchAttributes$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypePatchAttributes$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypePatchAttributes$XWwwFormUrlencoded$Json(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypePatchAttributes$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypePatchAttributes$XWwwFormUrlencoded$Xml(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypePatchAttributes$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypePatchAttributes$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypePatchAttributes$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypePatchAttributes$Xml$Json$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypePatchAttributes$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypePatchAttributes$Xml$Json(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentPrototypePatchAttributes$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypePatchAttributes$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypePatchAttributes$Xml$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypePatchAttributes$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypePatchAttributes$Xml$Xml(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentPrototypePatchAttributes$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypePatchAttributes$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypePatchAttributes$Xml$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypePatchAttributes$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentPrototypePatchAttributes$Xml$Javascript(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentPrototypePatchAttributes$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Path part for operation documentReplaceByIdPostDocumentsIdReplace
   */
  static readonly DocumentReplaceByIdPostDocumentsIdReplacePath = '/v1/Documents/{id}/replace';

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentReplaceByIdPostDocumentsIdReplace$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentReplaceByIdPostDocumentsIdReplace$Json$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentReplaceByIdPostDocumentsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentReplaceByIdPostDocumentsIdReplace$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentReplaceByIdPostDocumentsIdReplace$Json$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentReplaceByIdPostDocumentsIdReplace$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentReplaceByIdPostDocumentsIdReplace$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentReplaceByIdPostDocumentsIdReplace$Json$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentReplaceByIdPostDocumentsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentReplaceByIdPostDocumentsIdReplace$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentReplaceByIdPostDocumentsIdReplace$Json$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentReplaceByIdPostDocumentsIdReplace$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentReplaceByIdPostDocumentsIdReplace$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentReplaceByIdPostDocumentsIdReplace$Json$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentReplaceByIdPostDocumentsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentReplaceByIdPostDocumentsIdReplace$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentReplaceByIdPostDocumentsIdReplace$Json$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentReplaceByIdPostDocumentsIdReplace$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentReplaceByIdPostDocumentsIdReplace$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentReplaceByIdPostDocumentsIdReplace$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentReplaceByIdPostDocumentsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentReplaceByIdPostDocumentsIdReplace$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentReplaceByIdPostDocumentsIdReplace$XWwwFormUrlencoded$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentReplaceByIdPostDocumentsIdReplace$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentReplaceByIdPostDocumentsIdReplace$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentReplaceByIdPostDocumentsIdReplace$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentReplaceByIdPostDocumentsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentReplaceByIdPostDocumentsIdReplace$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentReplaceByIdPostDocumentsIdReplace$XWwwFormUrlencoded$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentReplaceByIdPostDocumentsIdReplace$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentReplaceByIdPostDocumentsIdReplace$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentReplaceByIdPostDocumentsIdReplace$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentReplaceByIdPostDocumentsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentReplaceByIdPostDocumentsIdReplace$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentReplaceByIdPostDocumentsIdReplace$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentReplaceByIdPostDocumentsIdReplace$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentReplaceByIdPostDocumentsIdReplace$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentReplaceByIdPostDocumentsIdReplace$Xml$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentReplaceByIdPostDocumentsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentReplaceByIdPostDocumentsIdReplace$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentReplaceByIdPostDocumentsIdReplace$Xml$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentReplaceByIdPostDocumentsIdReplace$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentReplaceByIdPostDocumentsIdReplace$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentReplaceByIdPostDocumentsIdReplace$Xml$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentReplaceByIdPostDocumentsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentReplaceByIdPostDocumentsIdReplace$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentReplaceByIdPostDocumentsIdReplace$Xml$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentReplaceByIdPostDocumentsIdReplace$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentReplaceByIdPostDocumentsIdReplace$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentReplaceByIdPostDocumentsIdReplace$Xml$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentReplaceByIdPostDocumentsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentReplaceByIdPostDocumentsIdReplace$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  documentReplaceByIdPostDocumentsIdReplace$Xml$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Document
  },
  context?: HttpContext

): Observable<Document> {

    return this.documentReplaceByIdPostDocumentsIdReplace$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Path part for operation documentPrototypeSend
   */
  static readonly DocumentPrototypeSendPath = '/v1/Documents/{id}/send';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeSend$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeSend$Json$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Language flag
     */
    l?: string;
    body: {

/**
 * Comma delimited list of emails
 */
'recipients': string;

/**
 * Comma delimited list of emails
 */
'message'?: string;
'subject'?: string;

/**
 * Indicates if copy should be sent to sender
 */
'sendCopy'?: boolean;

/**
 * Indicates if email should be saved to client.
 */
'associateEmail'?: boolean;

/**
 * Specifies which attachments, besides document, should be sent along with the document.
 */
'excludedAttachmentIds'?: string;

/**
 * If this flag is set to true, eslog will be added to attachments.
 */
'sendEslogAttachment'?: boolean;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeSendPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('l', params.l, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeSend$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeSend$Json(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Language flag
     */
    l?: string;
    body: {

/**
 * Comma delimited list of emails
 */
'recipients': string;

/**
 * Comma delimited list of emails
 */
'message'?: string;
'subject'?: string;

/**
 * Indicates if copy should be sent to sender
 */
'sendCopy'?: boolean;

/**
 * Indicates if email should be saved to client.
 */
'associateEmail'?: boolean;

/**
 * Specifies which attachments, besides document, should be sent along with the document.
 */
'excludedAttachmentIds'?: string;

/**
 * If this flag is set to true, eslog will be added to attachments.
 */
'sendEslogAttachment'?: boolean;
}
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.documentPrototypeSend$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeSend$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeSend$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Language flag
     */
    l?: string;
    body: {

/**
 * Comma delimited list of emails
 */
'recipients': string;

/**
 * Comma delimited list of emails
 */
'message'?: string;
'subject'?: string;

/**
 * Indicates if copy should be sent to sender
 */
'sendCopy'?: boolean;

/**
 * Indicates if email should be saved to client.
 */
'associateEmail'?: boolean;

/**
 * Specifies which attachments, besides document, should be sent along with the document.
 */
'excludedAttachmentIds'?: string;

/**
 * If this flag is set to true, eslog will be added to attachments.
 */
'sendEslogAttachment'?: boolean;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeSendPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('l', params.l, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeSend$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeSend$Xml(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Language flag
     */
    l?: string;
    body: {

/**
 * Comma delimited list of emails
 */
'recipients': string;

/**
 * Comma delimited list of emails
 */
'message'?: string;
'subject'?: string;

/**
 * Indicates if copy should be sent to sender
 */
'sendCopy'?: boolean;

/**
 * Indicates if email should be saved to client.
 */
'associateEmail'?: boolean;

/**
 * Specifies which attachments, besides document, should be sent along with the document.
 */
'excludedAttachmentIds'?: string;

/**
 * If this flag is set to true, eslog will be added to attachments.
 */
'sendEslogAttachment'?: boolean;
}
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.documentPrototypeSend$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeSend$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeSend$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Language flag
     */
    l?: string;
    body: {

/**
 * Comma delimited list of emails
 */
'recipients': string;

/**
 * Comma delimited list of emails
 */
'message'?: string;
'subject'?: string;

/**
 * Indicates if copy should be sent to sender
 */
'sendCopy'?: boolean;

/**
 * Indicates if email should be saved to client.
 */
'associateEmail'?: boolean;

/**
 * Specifies which attachments, besides document, should be sent along with the document.
 */
'excludedAttachmentIds'?: string;

/**
 * If this flag is set to true, eslog will be added to attachments.
 */
'sendEslogAttachment'?: boolean;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeSendPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('l', params.l, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeSend$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeSend$Javascript(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Language flag
     */
    l?: string;
    body: {

/**
 * Comma delimited list of emails
 */
'recipients': string;

/**
 * Comma delimited list of emails
 */
'message'?: string;
'subject'?: string;

/**
 * Indicates if copy should be sent to sender
 */
'sendCopy'?: boolean;

/**
 * Indicates if email should be saved to client.
 */
'associateEmail'?: boolean;

/**
 * Specifies which attachments, besides document, should be sent along with the document.
 */
'excludedAttachmentIds'?: string;

/**
 * If this flag is set to true, eslog will be added to attachments.
 */
'sendEslogAttachment'?: boolean;
}
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.documentPrototypeSend$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Path part for operation documentPrototypeCancel
   */
  static readonly DocumentPrototypeCancelPath = '/v1/Documents/{id}/cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCancel$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeCancel$Json$Response(params: {

    /**
     * Document id
     */
    id: string;
    technical?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCancelPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('technical', params.technical, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCancel$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeCancel$Json(params: {

    /**
     * Document id
     */
    id: string;
    technical?: boolean;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.documentPrototypeCancel$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCancel$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeCancel$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;
    technical?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCancelPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('technical', params.technical, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCancel$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeCancel$Xml(params: {

    /**
     * Document id
     */
    id: string;
    technical?: boolean;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.documentPrototypeCancel$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeCancel$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeCancel$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;
    technical?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeCancelPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('technical', params.technical, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeCancel$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeCancel$Javascript(params: {

    /**
     * Document id
     */
    id: string;
    technical?: boolean;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.documentPrototypeCancel$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Path part for operation documentPrototypeUncancel
   */
  static readonly DocumentPrototypeUncancelPath = '/v1/Documents/{id}/uncancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeUncancel$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeUncancel$Json$Response(params: {

    /**
     * Document id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeUncancelPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeUncancel$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeUncancel$Json(params: {

    /**
     * Document id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.documentPrototypeUncancel$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeUncancel$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeUncancel$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeUncancelPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeUncancel$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeUncancel$Xml(params: {

    /**
     * Document id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.documentPrototypeUncancel$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeUncancel$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeUncancel$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeUncancelPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeUncancel$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeUncancel$Javascript(params: {

    /**
     * Document id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.documentPrototypeUncancel$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Path part for operation documentPrototypeRemoteVerify
   */
  static readonly DocumentPrototypeRemoteVerifyPath = '/v1/Documents/{id}/fiscally-verify';

  /**
   * Fiscally verify a document that previously failed verification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeRemoteVerify$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeRemoteVerify$Json$Response(params: {

    /**
     * Document id
     */
    id: string;
    provider?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeRemoteVerifyPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('provider', params.provider, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Fiscally verify a document that previously failed verification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeRemoteVerify$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeRemoteVerify$Json(params: {

    /**
     * Document id
     */
    id: string;
    provider?: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.documentPrototypeRemoteVerify$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Fiscally verify a document that previously failed verification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeRemoteVerify$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeRemoteVerify$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;
    provider?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeRemoteVerifyPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('provider', params.provider, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Fiscally verify a document that previously failed verification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeRemoteVerify$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeRemoteVerify$Xml(params: {

    /**
     * Document id
     */
    id: string;
    provider?: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.documentPrototypeRemoteVerify$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Fiscally verify a document that previously failed verification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeRemoteVerify$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeRemoteVerify$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;
    provider?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeRemoteVerifyPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('provider', params.provider, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Fiscally verify a document that previously failed verification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeRemoteVerify$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeRemoteVerify$Javascript(params: {

    /**
     * Document id
     */
    id: string;
    provider?: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.documentPrototypeRemoteVerify$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation documentPrototypeEslog
   */
  static readonly DocumentPrototypeEslogPath = '/v1/Documents/{id}/e-slog';

  /**
   * Generates eslog xml and returns it.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeEslog$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeEslog$Json$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Additional settings for generating the eslog format
     */
    options?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeEslogPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('options', params.options, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Generates eslog xml and returns it.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeEslog$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeEslog$Json(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Additional settings for generating the eslog format
     */
    options?: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.documentPrototypeEslog$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Generates eslog xml and returns it.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeEslog$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeEslog$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Additional settings for generating the eslog format
     */
    options?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeEslogPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('options', params.options, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Generates eslog xml and returns it.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeEslog$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeEslog$Xml(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Additional settings for generating the eslog format
     */
    options?: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.documentPrototypeEslog$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Generates eslog xml and returns it.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeEslog$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeEslog$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Additional settings for generating the eslog format
     */
    options?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeEslogPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('options', params.options, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Generates eslog xml and returns it.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeEslog$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeEslog$Javascript(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Additional settings for generating the eslog format
     */
    options?: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.documentPrototypeEslog$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation documentPrototypePdf
   */
  static readonly DocumentPrototypePdfPath = '/v1/Documents/{id}/pdf';

  /**
   * Retrieve PDF of document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypePdf$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypePdf$Json$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Language flag
     */
    l?: string;

    /**
     * Add UPN QR to PDF
     */
    upn_qr?: boolean;

    /**
     * Hide header and footer on PDF
     */
    hide_header_footer?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypePdfPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('l', params.l, {});
      rb.query('upn_qr', params.upn_qr, {});
      rb.query('hide_header_footer', params.hide_header_footer, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Retrieve PDF of document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypePdf$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypePdf$Json(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Language flag
     */
    l?: string;

    /**
     * Add UPN QR to PDF
     */
    upn_qr?: boolean;

    /**
     * Hide header and footer on PDF
     */
    hide_header_footer?: boolean;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.documentPrototypePdf$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Retrieve PDF of document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypePdf$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypePdf$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Language flag
     */
    l?: string;

    /**
     * Add UPN QR to PDF
     */
    upn_qr?: boolean;

    /**
     * Hide header and footer on PDF
     */
    hide_header_footer?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypePdfPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('l', params.l, {});
      rb.query('upn_qr', params.upn_qr, {});
      rb.query('hide_header_footer', params.hide_header_footer, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Retrieve PDF of document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypePdf$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypePdf$Xml(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Language flag
     */
    l?: string;

    /**
     * Add UPN QR to PDF
     */
    upn_qr?: boolean;

    /**
     * Hide header and footer on PDF
     */
    hide_header_footer?: boolean;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.documentPrototypePdf$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Retrieve PDF of document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypePdf$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypePdf$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Language flag
     */
    l?: string;

    /**
     * Add UPN QR to PDF
     */
    upn_qr?: boolean;

    /**
     * Hide header and footer on PDF
     */
    hide_header_footer?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypePdfPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('l', params.l, {});
      rb.query('upn_qr', params.upn_qr, {});
      rb.query('hide_header_footer', params.hide_header_footer, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Retrieve PDF of document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypePdf$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypePdf$Javascript(params: {

    /**
     * Document id
     */
    id: string;

    /**
     * Language flag
     */
    l?: string;

    /**
     * Add UPN QR to PDF
     */
    upn_qr?: boolean;

    /**
     * Hide header and footer on PDF
     */
    hide_header_footer?: boolean;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.documentPrototypePdf$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation documentPrototypeMarkSent
   */
  static readonly DocumentPrototypeMarkSentPath = '/v1/Documents/{id}/mark-sent';

  /**
   * Mark document as sent via snail mail.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeMarkSent$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeMarkSent$Json$Response(params: {

    /**
     * Document id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeMarkSentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * Mark document as sent via snail mail.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeMarkSent$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeMarkSent$Json(params: {

    /**
     * Document id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.documentPrototypeMarkSent$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Mark document as sent via snail mail.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeMarkSent$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeMarkSent$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeMarkSentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * Mark document as sent via snail mail.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeMarkSent$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeMarkSent$Xml(params: {

    /**
     * Document id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.documentPrototypeMarkSent$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Mark document as sent via snail mail.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeMarkSent$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeMarkSent$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeMarkSentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * Mark document as sent via snail mail.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeMarkSent$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeMarkSent$Javascript(params: {

    /**
     * Document id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.documentPrototypeMarkSent$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Path part for operation documentPrototypeUploadAttachment
   */
  static readonly DocumentPrototypeUploadAttachmentPath = '/v1/Documents/{id}/attachments/upload';

  /**
   * Uploads an attachment for a document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeUploadAttachment$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeUploadAttachment$Json$Response(params: {

    /**
     * Document id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeUploadAttachmentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Uploads an attachment for a document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeUploadAttachment$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeUploadAttachment$Json(params: {

    /**
     * Document id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.documentPrototypeUploadAttachment$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Uploads an attachment for a document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeUploadAttachment$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeUploadAttachment$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeUploadAttachmentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Uploads an attachment for a document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeUploadAttachment$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeUploadAttachment$Xml(params: {

    /**
     * Document id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.documentPrototypeUploadAttachment$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Uploads an attachment for a document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeUploadAttachment$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeUploadAttachment$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeUploadAttachmentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Uploads an attachment for a document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeUploadAttachment$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrototypeUploadAttachment$Javascript(params: {

    /**
     * Document id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.documentPrototypeUploadAttachment$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation documentRemoteParseXml
   */
  static readonly DocumentRemoteParseXmlPath = '/v1/Documents/parse-xml';

  /**
   * Takes an xml file and returns a json object prepared for saving a document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentRemoteParseXml$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteParseXml$Json$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentRemoteParseXmlPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Takes an xml file and returns a json object prepared for saving a document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentRemoteParseXml$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteParseXml$Json(params?: {
  },
  context?: HttpContext

): Observable<{
}> {

    return this.documentRemoteParseXml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Takes an xml file and returns a json object prepared for saving a document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentRemoteParseXml$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteParseXml$Xml$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentRemoteParseXmlPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Takes an xml file and returns a json object prepared for saving a document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentRemoteParseXml$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteParseXml$Xml(params?: {
  },
  context?: HttpContext

): Observable<{
}> {

    return this.documentRemoteParseXml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Takes an xml file and returns a json object prepared for saving a document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentRemoteParseXml$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteParseXml$Javascript$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentRemoteParseXmlPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Takes an xml file and returns a json object prepared for saving a document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentRemoteParseXml$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteParseXml$Javascript(params?: {
  },
  context?: HttpContext

): Observable<{
}> {

    return this.documentRemoteParseXml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation documentRemoteOverdueOutgoingCron
   */
  static readonly DocumentRemoteOverdueOutgoingCronPath = '/v1/Documents/cron/outgoing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentRemoteOverdueOutgoingCron$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteOverdueOutgoingCron$Json$Response(params?: {
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentRemoteOverdueOutgoingCronPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentRemoteOverdueOutgoingCron$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteOverdueOutgoingCron$Json(params?: {
    date?: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.documentRemoteOverdueOutgoingCron$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentRemoteOverdueOutgoingCron$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteOverdueOutgoingCron$Xml$Response(params?: {
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentRemoteOverdueOutgoingCronPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentRemoteOverdueOutgoingCron$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteOverdueOutgoingCron$Xml(params?: {
    date?: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.documentRemoteOverdueOutgoingCron$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentRemoteOverdueOutgoingCron$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteOverdueOutgoingCron$Javascript$Response(params?: {
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentRemoteOverdueOutgoingCronPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentRemoteOverdueOutgoingCron$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteOverdueOutgoingCron$Javascript(params?: {
    date?: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.documentRemoteOverdueOutgoingCron$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Path part for operation documentRemoteOverdueIncomingCron
   */
  static readonly DocumentRemoteOverdueIncomingCronPath = '/v1/Documents/cron/incoming';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentRemoteOverdueIncomingCron$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteOverdueIncomingCron$Json$Response(params?: {
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentRemoteOverdueIncomingCronPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentRemoteOverdueIncomingCron$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteOverdueIncomingCron$Json(params?: {
    date?: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.documentRemoteOverdueIncomingCron$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentRemoteOverdueIncomingCron$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteOverdueIncomingCron$Xml$Response(params?: {
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentRemoteOverdueIncomingCronPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentRemoteOverdueIncomingCron$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteOverdueIncomingCron$Xml(params?: {
    date?: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.documentRemoteOverdueIncomingCron$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentRemoteOverdueIncomingCron$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteOverdueIncomingCron$Javascript$Response(params?: {
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentRemoteOverdueIncomingCronPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentRemoteOverdueIncomingCron$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteOverdueIncomingCron$Javascript(params?: {
    date?: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.documentRemoteOverdueIncomingCron$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Path part for operation documentPrototypeShare
   */
  static readonly DocumentPrototypeSharePath = '/v1/Documents/{id}/share';

  /**
   * Generates and returns shareable document id that allows access to document without authentication.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeShare$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeShare$Json$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: {

/**
 * Regenerate shareable id
 */
'regenerate'?: boolean;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{

/**
 * Shareable document id
 */
'shareableId'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeSharePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Shareable document id
         */
        'shareableId'?: string;
        }>;
      })
    );
  }

  /**
   * Generates and returns shareable document id that allows access to document without authentication.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeShare$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeShare$Json(params: {

    /**
     * Document id
     */
    id: string;
    body?: {

/**
 * Regenerate shareable id
 */
'regenerate'?: boolean;
}
  },
  context?: HttpContext

): Observable<{

/**
 * Shareable document id
 */
'shareableId'?: string;
}> {

    return this.documentPrototypeShare$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{

/**
 * Shareable document id
 */
'shareableId'?: string;
}>) => r.body as {

/**
 * Shareable document id
 */
'shareableId'?: string;
})
    );
  }

  /**
   * Generates and returns shareable document id that allows access to document without authentication.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeShare$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeShare$Xml$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: {

/**
 * Regenerate shareable id
 */
'regenerate'?: boolean;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{

/**
 * Shareable document id
 */
'shareableId'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeSharePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Shareable document id
         */
        'shareableId'?: string;
        }>;
      })
    );
  }

  /**
   * Generates and returns shareable document id that allows access to document without authentication.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeShare$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeShare$Xml(params: {

    /**
     * Document id
     */
    id: string;
    body?: {

/**
 * Regenerate shareable id
 */
'regenerate'?: boolean;
}
  },
  context?: HttpContext

): Observable<{

/**
 * Shareable document id
 */
'shareableId'?: string;
}> {

    return this.documentPrototypeShare$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{

/**
 * Shareable document id
 */
'shareableId'?: string;
}>) => r.body as {

/**
 * Shareable document id
 */
'shareableId'?: string;
})
    );
  }

  /**
   * Generates and returns shareable document id that allows access to document without authentication.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrototypeShare$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeShare$Javascript$Response(params: {

    /**
     * Document id
     */
    id: string;
    body?: {

/**
 * Regenerate shareable id
 */
'regenerate'?: boolean;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{

/**
 * Shareable document id
 */
'shareableId'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPrototypeSharePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Shareable document id
         */
        'shareableId'?: string;
        }>;
      })
    );
  }

  /**
   * Generates and returns shareable document id that allows access to document without authentication.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrototypeShare$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  documentPrototypeShare$Javascript(params: {

    /**
     * Document id
     */
    id: string;
    body?: {

/**
 * Regenerate shareable id
 */
'regenerate'?: boolean;
}
  },
  context?: HttpContext

): Observable<{

/**
 * Shareable document id
 */
'shareableId'?: string;
}> {

    return this.documentPrototypeShare$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{

/**
 * Shareable document id
 */
'shareableId'?: string;
}>) => r.body as {

/**
 * Shareable document id
 */
'shareableId'?: string;
})
    );
  }

  /**
   * Path part for operation documentRemoteFindByShareableId
   */
  static readonly DocumentRemoteFindByShareableIdPath = '/v1/Documents/public/{id}';

  /**
   * Retrieve document using shareable id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentRemoteFindByShareableId$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteFindByShareableId$Json$Response(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentRemoteFindByShareableIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Retrieve document using shareable id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentRemoteFindByShareableId$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteFindByShareableId$Json(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.documentRemoteFindByShareableId$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Retrieve document using shareable id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentRemoteFindByShareableId$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteFindByShareableId$Xml$Response(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentRemoteFindByShareableIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Retrieve document using shareable id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentRemoteFindByShareableId$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteFindByShareableId$Xml(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.documentRemoteFindByShareableId$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Retrieve document using shareable id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentRemoteFindByShareableId$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteFindByShareableId$Javascript$Response(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentRemoteFindByShareableIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Retrieve document using shareable id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentRemoteFindByShareableId$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentRemoteFindByShareableId$Javascript(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.documentRemoteFindByShareableId$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation documentEslogByShareableId
   */
  static readonly DocumentEslogByShareableIdPath = '/v1/Documents/public/{id}/e-slog';

  /**
   * Generates eslog xml from shareableId and returns it.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentEslogByShareableId$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentEslogByShareableId$Json$Response(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentEslogByShareableIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Generates eslog xml from shareableId and returns it.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentEslogByShareableId$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentEslogByShareableId$Json(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.documentEslogByShareableId$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Generates eslog xml from shareableId and returns it.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentEslogByShareableId$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentEslogByShareableId$Xml$Response(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentEslogByShareableIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Generates eslog xml from shareableId and returns it.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentEslogByShareableId$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentEslogByShareableId$Xml(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.documentEslogByShareableId$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Generates eslog xml from shareableId and returns it.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentEslogByShareableId$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentEslogByShareableId$Javascript$Response(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentEslogByShareableIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Generates eslog xml from shareableId and returns it.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentEslogByShareableId$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentEslogByShareableId$Javascript(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.documentEslogByShareableId$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation documentPdfByShareableId
   */
  static readonly DocumentPdfByShareableIdPath = '/v1/Documents/public/{id}/pdf';

  /**
   * Retrieve PDF of document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPdfByShareableId$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPdfByShareableId$Json$Response(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;

    /**
     * Language flag
     */
    l?: string;

    /**
     * Add UPN QR to PDF
     */
    upn_qr?: boolean;

    /**
     * Hide header and footer on PDF
     */
    hide_header_footer?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPdfByShareableIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('l', params.l, {});
      rb.query('upn_qr', params.upn_qr, {});
      rb.query('hide_header_footer', params.hide_header_footer, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Retrieve PDF of document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPdfByShareableId$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPdfByShareableId$Json(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;

    /**
     * Language flag
     */
    l?: string;

    /**
     * Add UPN QR to PDF
     */
    upn_qr?: boolean;

    /**
     * Hide header and footer on PDF
     */
    hide_header_footer?: boolean;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.documentPdfByShareableId$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Retrieve PDF of document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPdfByShareableId$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPdfByShareableId$Xml$Response(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;

    /**
     * Language flag
     */
    l?: string;

    /**
     * Add UPN QR to PDF
     */
    upn_qr?: boolean;

    /**
     * Hide header and footer on PDF
     */
    hide_header_footer?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPdfByShareableIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('l', params.l, {});
      rb.query('upn_qr', params.upn_qr, {});
      rb.query('hide_header_footer', params.hide_header_footer, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Retrieve PDF of document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPdfByShareableId$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPdfByShareableId$Xml(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;

    /**
     * Language flag
     */
    l?: string;

    /**
     * Add UPN QR to PDF
     */
    upn_qr?: boolean;

    /**
     * Hide header and footer on PDF
     */
    hide_header_footer?: boolean;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.documentPdfByShareableId$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Retrieve PDF of document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPdfByShareableId$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPdfByShareableId$Javascript$Response(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;

    /**
     * Language flag
     */
    l?: string;

    /**
     * Add UPN QR to PDF
     */
    upn_qr?: boolean;

    /**
     * Hide header and footer on PDF
     */
    hide_header_footer?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentPdfByShareableIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('l', params.l, {});
      rb.query('upn_qr', params.upn_qr, {});
      rb.query('hide_header_footer', params.hide_header_footer, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Retrieve PDF of document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPdfByShareableId$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPdfByShareableId$Javascript(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;

    /**
     * Language flag
     */
    l?: string;

    /**
     * Add UPN QR to PDF
     */
    upn_qr?: boolean;

    /**
     * Hide header and footer on PDF
     */
    hide_header_footer?: boolean;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.documentPdfByShareableId$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation documentAttachmentByShareableId
   */
  static readonly DocumentAttachmentByShareableIdPath = '/v1/Documents/public/{id}/attachments/{attachmentId}/download';

  /**
   * Retrieve attachment of document by shareable id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentAttachmentByShareableId$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentAttachmentByShareableId$Json$Response(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;

    /**
     * Id of attachment to retrieve
     */
    attachmentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentAttachmentByShareableIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('attachmentId', params.attachmentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Retrieve attachment of document by shareable id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentAttachmentByShareableId$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentAttachmentByShareableId$Json(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;

    /**
     * Id of attachment to retrieve
     */
    attachmentId: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.documentAttachmentByShareableId$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Retrieve attachment of document by shareable id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentAttachmentByShareableId$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentAttachmentByShareableId$Xml$Response(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;

    /**
     * Id of attachment to retrieve
     */
    attachmentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentAttachmentByShareableIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('attachmentId', params.attachmentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Retrieve attachment of document by shareable id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentAttachmentByShareableId$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentAttachmentByShareableId$Xml(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;

    /**
     * Id of attachment to retrieve
     */
    attachmentId: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.documentAttachmentByShareableId$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Retrieve attachment of document by shareable id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentAttachmentByShareableId$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentAttachmentByShareableId$Javascript$Response(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;

    /**
     * Id of attachment to retrieve
     */
    attachmentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentService.DocumentAttachmentByShareableIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('attachmentId', params.attachmentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Retrieve attachment of document by shareable id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentAttachmentByShareableId$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentAttachmentByShareableId$Javascript(params: {

    /**
     * Shareable id of document to retrieve
     */
    id: string;

    /**
     * Id of attachment to retrieve
     */
    attachmentId: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.documentAttachmentByShareableId$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

}
