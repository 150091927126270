import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class FormService {
  // TODO: Avoid using this

  constructor() { }

  public getCtrlChangeSub(group: FormGroup, controls: string[]) {
    let subscriptions = [];

    controls.forEach((c) => {
      subscriptions = [
        ...subscriptions,
        group.controls[c].valueChanges,
      ];
    });

    return subscriptions;
  }
}
