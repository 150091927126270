/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Magento1 } from '../models/magento-1';

@Injectable({
  providedIn: 'root',
})
export class Magento1Service extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation magento1DeleteById
   */
  static readonly Magento1DeleteByIdPath = '/v1/Magento1/{id}';

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1DeleteById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  magento1DeleteById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1DeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1DeleteById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  magento1DeleteById$Json(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.magento1DeleteById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1DeleteById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  magento1DeleteById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1DeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1DeleteById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  magento1DeleteById$Xml(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.magento1DeleteById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1DeleteById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  magento1DeleteById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1DeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1DeleteById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  magento1DeleteById$Javascript(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.magento1DeleteById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation magento1PrototypePatchAttributes
   */
  static readonly Magento1PrototypePatchAttributesPath = '/v1/Magento1/{id}';

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1PrototypePatchAttributes$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  magento1PrototypePatchAttributes$Json$Json$Response(params: {

    /**
     * Magento1 id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Magento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Magento1>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1PrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magento1>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1PrototypePatchAttributes$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  magento1PrototypePatchAttributes$Json$Json(params: {

    /**
     * Magento1 id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Magento1
  },
  context?: HttpContext

): Observable<Magento1> {

    return this.magento1PrototypePatchAttributes$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Magento1>) => r.body as Magento1)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1PrototypePatchAttributes$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  magento1PrototypePatchAttributes$Json$Xml$Response(params: {

    /**
     * Magento1 id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Magento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Magento1>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1PrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magento1>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1PrototypePatchAttributes$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  magento1PrototypePatchAttributes$Json$Xml(params: {

    /**
     * Magento1 id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Magento1
  },
  context?: HttpContext

): Observable<Magento1> {

    return this.magento1PrototypePatchAttributes$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Magento1>) => r.body as Magento1)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1PrototypePatchAttributes$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  magento1PrototypePatchAttributes$Json$Javascript$Response(params: {

    /**
     * Magento1 id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Magento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Magento1>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1PrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magento1>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1PrototypePatchAttributes$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  magento1PrototypePatchAttributes$Json$Javascript(params: {

    /**
     * Magento1 id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Magento1
  },
  context?: HttpContext

): Observable<Magento1> {

    return this.magento1PrototypePatchAttributes$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Magento1>) => r.body as Magento1)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1PrototypePatchAttributes$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  magento1PrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Magento1 id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Magento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Magento1>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1PrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magento1>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1PrototypePatchAttributes$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  magento1PrototypePatchAttributes$XWwwFormUrlencoded$Json(params: {

    /**
     * Magento1 id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Magento1
  },
  context?: HttpContext

): Observable<Magento1> {

    return this.magento1PrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Magento1>) => r.body as Magento1)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1PrototypePatchAttributes$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  magento1PrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Magento1 id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Magento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Magento1>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1PrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magento1>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1PrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  magento1PrototypePatchAttributes$XWwwFormUrlencoded$Xml(params: {

    /**
     * Magento1 id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Magento1
  },
  context?: HttpContext

): Observable<Magento1> {

    return this.magento1PrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Magento1>) => r.body as Magento1)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1PrototypePatchAttributes$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  magento1PrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Magento1 id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Magento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Magento1>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1PrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magento1>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1PrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  magento1PrototypePatchAttributes$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Magento1 id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Magento1
  },
  context?: HttpContext

): Observable<Magento1> {

    return this.magento1PrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Magento1>) => r.body as Magento1)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1PrototypePatchAttributes$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  magento1PrototypePatchAttributes$Xml$Json$Response(params: {

    /**
     * Magento1 id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Magento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Magento1>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1PrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magento1>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1PrototypePatchAttributes$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  magento1PrototypePatchAttributes$Xml$Json(params: {

    /**
     * Magento1 id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Magento1
  },
  context?: HttpContext

): Observable<Magento1> {

    return this.magento1PrototypePatchAttributes$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Magento1>) => r.body as Magento1)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1PrototypePatchAttributes$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  magento1PrototypePatchAttributes$Xml$Xml$Response(params: {

    /**
     * Magento1 id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Magento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Magento1>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1PrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magento1>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1PrototypePatchAttributes$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  magento1PrototypePatchAttributes$Xml$Xml(params: {

    /**
     * Magento1 id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Magento1
  },
  context?: HttpContext

): Observable<Magento1> {

    return this.magento1PrototypePatchAttributes$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Magento1>) => r.body as Magento1)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1PrototypePatchAttributes$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  magento1PrototypePatchAttributes$Xml$Javascript$Response(params: {

    /**
     * Magento1 id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Magento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Magento1>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1PrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magento1>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1PrototypePatchAttributes$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  magento1PrototypePatchAttributes$Xml$Javascript(params: {

    /**
     * Magento1 id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Magento1
  },
  context?: HttpContext

): Observable<Magento1> {

    return this.magento1PrototypePatchAttributes$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Magento1>) => r.body as Magento1)
    );
  }

  /**
   * Path part for operation magento1PrototypeProcessOrder
   */
  static readonly Magento1PrototypeProcessOrderPath = '/v1/Magento1/{id}/order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1PrototypeProcessOrder$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  magento1PrototypeProcessOrder$Json$Json$Response(params: {

    /**
     * Magento1 id
     */
    id: string;
    body?: Magento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1PrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1PrototypeProcessOrder$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  magento1PrototypeProcessOrder$Json$Json(params: {

    /**
     * Magento1 id
     */
    id: string;
    body?: Magento1
  },
  context?: HttpContext

): Observable<{
}> {

    return this.magento1PrototypeProcessOrder$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1PrototypeProcessOrder$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  magento1PrototypeProcessOrder$Json$Xml$Response(params: {

    /**
     * Magento1 id
     */
    id: string;
    body?: Magento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1PrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1PrototypeProcessOrder$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  magento1PrototypeProcessOrder$Json$Xml(params: {

    /**
     * Magento1 id
     */
    id: string;
    body?: Magento1
  },
  context?: HttpContext

): Observable<{
}> {

    return this.magento1PrototypeProcessOrder$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1PrototypeProcessOrder$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  magento1PrototypeProcessOrder$Json$Javascript$Response(params: {

    /**
     * Magento1 id
     */
    id: string;
    body?: Magento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1PrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1PrototypeProcessOrder$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  magento1PrototypeProcessOrder$Json$Javascript(params: {

    /**
     * Magento1 id
     */
    id: string;
    body?: Magento1
  },
  context?: HttpContext

): Observable<{
}> {

    return this.magento1PrototypeProcessOrder$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1PrototypeProcessOrder$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  magento1PrototypeProcessOrder$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Magento1 id
     */
    id: string;
    body?: Magento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1PrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1PrototypeProcessOrder$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  magento1PrototypeProcessOrder$XWwwFormUrlencoded$Json(params: {

    /**
     * Magento1 id
     */
    id: string;
    body?: Magento1
  },
  context?: HttpContext

): Observable<{
}> {

    return this.magento1PrototypeProcessOrder$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1PrototypeProcessOrder$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  magento1PrototypeProcessOrder$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Magento1 id
     */
    id: string;
    body?: Magento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1PrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1PrototypeProcessOrder$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  magento1PrototypeProcessOrder$XWwwFormUrlencoded$Xml(params: {

    /**
     * Magento1 id
     */
    id: string;
    body?: Magento1
  },
  context?: HttpContext

): Observable<{
}> {

    return this.magento1PrototypeProcessOrder$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1PrototypeProcessOrder$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  magento1PrototypeProcessOrder$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Magento1 id
     */
    id: string;
    body?: Magento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1PrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1PrototypeProcessOrder$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  magento1PrototypeProcessOrder$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Magento1 id
     */
    id: string;
    body?: Magento1
  },
  context?: HttpContext

): Observable<{
}> {

    return this.magento1PrototypeProcessOrder$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1PrototypeProcessOrder$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  magento1PrototypeProcessOrder$Xml$Json$Response(params: {

    /**
     * Magento1 id
     */
    id: string;
    body?: Magento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1PrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1PrototypeProcessOrder$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  magento1PrototypeProcessOrder$Xml$Json(params: {

    /**
     * Magento1 id
     */
    id: string;
    body?: Magento1
  },
  context?: HttpContext

): Observable<{
}> {

    return this.magento1PrototypeProcessOrder$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1PrototypeProcessOrder$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  magento1PrototypeProcessOrder$Xml$Xml$Response(params: {

    /**
     * Magento1 id
     */
    id: string;
    body?: Magento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1PrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1PrototypeProcessOrder$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  magento1PrototypeProcessOrder$Xml$Xml(params: {

    /**
     * Magento1 id
     */
    id: string;
    body?: Magento1
  },
  context?: HttpContext

): Observable<{
}> {

    return this.magento1PrototypeProcessOrder$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1PrototypeProcessOrder$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  magento1PrototypeProcessOrder$Xml$Javascript$Response(params: {

    /**
     * Magento1 id
     */
    id: string;
    body?: Magento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1PrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1PrototypeProcessOrder$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  magento1PrototypeProcessOrder$Xml$Javascript(params: {

    /**
     * Magento1 id
     */
    id: string;
    body?: Magento1
  },
  context?: HttpContext

): Observable<{
}> {

    return this.magento1PrototypeProcessOrder$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation magento1PrototypeImport
   */
  static readonly Magento1PrototypeImportPath = '/v1/Magento1/{id}/import';

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1PrototypeImport$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  magento1PrototypeImport$Json$Response(params: {

    /**
     * Magento1 id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1PrototypeImportPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1PrototypeImport$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  magento1PrototypeImport$Json(params: {

    /**
     * Magento1 id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.magento1PrototypeImport$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1PrototypeImport$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  magento1PrototypeImport$Xml$Response(params: {

    /**
     * Magento1 id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1PrototypeImportPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1PrototypeImport$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  magento1PrototypeImport$Xml(params: {

    /**
     * Magento1 id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.magento1PrototypeImport$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `magento1PrototypeImport$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  magento1PrototypeImport$Javascript$Response(params: {

    /**
     * Magento1 id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, Magento1Service.Magento1PrototypeImportPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `magento1PrototypeImport$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  magento1PrototypeImport$Javascript(params: {

    /**
     * Magento1 id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.magento1PrototypeImport$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

}
