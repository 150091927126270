/* tslint:disable */
import {
  Data
} from '../index';

declare var Object: any;
export interface CompanyInterface {
  "dateFounded"?: Date;
  "serviceClassification"?: string;
  "source"?: string;
  "name": string;
  "address"?: string;
  "address2"?: string;
  "city"?: string;
  "zip"?: string;
  "state"?: string;
  "country"?: string;
  "countryAlpha2Code"?: string;
  "taxNumber"?: string;
  "taxSubject"?: boolean;
  "companyNumber"?: string;
  "IBAN"?: string;
  "bank"?: string;
  "SWIFT"?: string;
  "website"?: string;
  "email"?: string;
  "phone"?: string;
  "id"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "custom"?: any;
  "_data"?: Array<any>;
  data?: Data[];
}

export class Company implements CompanyInterface {
  "dateFounded": Date;
  "serviceClassification": string;
  "source": string;
  "name": string;
  "address": string;
  "address2": string;
  "city": string;
  "zip": string;
  "state": string;
  "country": string;
  "countryAlpha2Code": string;
  "taxNumber": string;
  "taxSubject": boolean;
  "companyNumber": string;
  "IBAN": string;
  "bank": string;
  "SWIFT": string;
  "website": string;
  "email": string;
  "phone": string;
  "id": any;
  "createdAt": Date;
  "updatedAt": Date;
  "custom": any;
  "_data": Array<any>;
  data: Data[];
  constructor(data?: CompanyInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Company`.
   */
  public static getModelName() {
    return "Company";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Company for dynamic purposes.
  **/
  public static factory(data: CompanyInterface): Company{
    return new Company(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Company',
      plural: 'Companies',
      path: 'Companies',
      idName: 'id',
      properties: {
        "dateFounded": {
          name: 'dateFounded',
          type: 'Date'
        },
        "serviceClassification": {
          name: 'serviceClassification',
          type: 'string'
        },
        "source": {
          name: 'source',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "address": {
          name: 'address',
          type: 'string'
        },
        "address2": {
          name: 'address2',
          type: 'string'
        },
        "city": {
          name: 'city',
          type: 'string'
        },
        "zip": {
          name: 'zip',
          type: 'string'
        },
        "state": {
          name: 'state',
          type: 'string'
        },
        "country": {
          name: 'country',
          type: 'string'
        },
        "countryAlpha2Code": {
          name: 'countryAlpha2Code',
          type: 'string'
        },
        "taxNumber": {
          name: 'taxNumber',
          type: 'string'
        },
        "taxSubject": {
          name: 'taxSubject',
          type: 'boolean'
        },
        "companyNumber": {
          name: 'companyNumber',
          type: 'string'
        },
        "IBAN": {
          name: 'IBAN',
          type: 'string'
        },
        "bank": {
          name: 'bank',
          type: 'string'
        },
        "SWIFT": {
          name: 'SWIFT',
          type: 'string'
        },
        "website": {
          name: 'website',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "phone": {
          name: 'phone',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        "custom": {
          name: 'custom',
          type: 'any',
          default: <any>null
        },
        "_data": {
          name: '_data',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
      },
      relations: {
        data: {
          name: 'data',
          type: 'Data[]',
          model: 'Data',
          relationType: 'embedsMany',
                  keyFrom: '_data',
          keyTo: 'id'
        },
      }
    }
  }
}
