/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Unit } from '../models/unit';

@Injectable({
  providedIn: 'root',
})
export class UnitService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation unitDeleteById
   */
  static readonly UnitDeleteByIdPath = '/v1/Units/{id}';

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitDeleteById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitDeleteById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitDeleteById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitDeleteById$Json(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.unitDeleteById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitDeleteById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitDeleteById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitDeleteById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitDeleteById$Xml(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.unitDeleteById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitDeleteById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitDeleteById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitDeleteById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitDeleteById$Javascript(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.unitDeleteById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation unitPrototypePatchAttributes
   */
  static readonly UnitPrototypePatchAttributesPath = '/v1/Units/{id}';

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypePatchAttributes$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unitPrototypePatchAttributes$Json$Json$Response(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Unit
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Unit>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypePatchAttributes$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unitPrototypePatchAttributes$Json$Json(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Unit
  },
  context?: HttpContext

): Observable<Unit> {

    return this.unitPrototypePatchAttributes$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Unit>) => r.body as Unit)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypePatchAttributes$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unitPrototypePatchAttributes$Json$Xml$Response(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Unit
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Unit>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypePatchAttributes$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unitPrototypePatchAttributes$Json$Xml(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Unit
  },
  context?: HttpContext

): Observable<Unit> {

    return this.unitPrototypePatchAttributes$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Unit>) => r.body as Unit)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypePatchAttributes$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unitPrototypePatchAttributes$Json$Javascript$Response(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Unit
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Unit>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypePatchAttributes$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unitPrototypePatchAttributes$Json$Javascript(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Unit
  },
  context?: HttpContext

): Observable<Unit> {

    return this.unitPrototypePatchAttributes$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Unit>) => r.body as Unit)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypePatchAttributes$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  unitPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Unit
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Unit>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  unitPrototypePatchAttributes$XWwwFormUrlencoded$Json(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Unit
  },
  context?: HttpContext

): Observable<Unit> {

    return this.unitPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Unit>) => r.body as Unit)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypePatchAttributes$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  unitPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Unit
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Unit>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  unitPrototypePatchAttributes$XWwwFormUrlencoded$Xml(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Unit
  },
  context?: HttpContext

): Observable<Unit> {

    return this.unitPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Unit>) => r.body as Unit)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypePatchAttributes$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  unitPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Unit
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Unit>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  unitPrototypePatchAttributes$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Unit
  },
  context?: HttpContext

): Observable<Unit> {

    return this.unitPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Unit>) => r.body as Unit)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypePatchAttributes$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  unitPrototypePatchAttributes$Xml$Json$Response(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Unit
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Unit>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypePatchAttributes$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  unitPrototypePatchAttributes$Xml$Json(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Unit
  },
  context?: HttpContext

): Observable<Unit> {

    return this.unitPrototypePatchAttributes$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Unit>) => r.body as Unit)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypePatchAttributes$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  unitPrototypePatchAttributes$Xml$Xml$Response(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Unit
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Unit>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypePatchAttributes$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  unitPrototypePatchAttributes$Xml$Xml(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Unit
  },
  context?: HttpContext

): Observable<Unit> {

    return this.unitPrototypePatchAttributes$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Unit>) => r.body as Unit)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypePatchAttributes$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  unitPrototypePatchAttributes$Xml$Javascript$Response(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Unit
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Unit>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypePatchAttributes$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  unitPrototypePatchAttributes$Xml$Javascript(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Unit
  },
  context?: HttpContext

): Observable<Unit> {

    return this.unitPrototypePatchAttributes$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Unit>) => r.body as Unit)
    );
  }

  /**
   * Path part for operation unitPrototypeLastDocNumber
   */
  static readonly UnitPrototypeLastDocNumberPath = '/v1/Units/{id}/last-doc-number';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeLastDocNumber$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeLastDocNumber$Json$Response(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'number'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeLastDocNumberPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('incoming', params.incoming, {});
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'number'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeLastDocNumber$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeLastDocNumber$Json(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<{
'number'?: string;
}> {

    return this.unitPrototypeLastDocNumber$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'number'?: string;
}>) => r.body as {
'number'?: string;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeLastDocNumber$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeLastDocNumber$Xml$Response(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'number'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeLastDocNumberPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('incoming', params.incoming, {});
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'number'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeLastDocNumber$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeLastDocNumber$Xml(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<{
'number'?: string;
}> {

    return this.unitPrototypeLastDocNumber$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'number'?: string;
}>) => r.body as {
'number'?: string;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeLastDocNumber$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeLastDocNumber$Javascript$Response(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'number'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeLastDocNumberPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('incoming', params.incoming, {});
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'number'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeLastDocNumber$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeLastDocNumber$Javascript(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<{
'number'?: string;
}> {

    return this.unitPrototypeLastDocNumber$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'number'?: string;
}>) => r.body as {
'number'?: string;
})
    );
  }

  /**
   * Path part for operation unitPrototypeNextDocNumber
   */
  static readonly UnitPrototypeNextDocNumberPath = '/v1/Units/{id}/next-doc-number';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeNextDocNumber$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeNextDocNumber$Json$Response(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'number'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeNextDocNumberPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('incoming', params.incoming, {});
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'number'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeNextDocNumber$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeNextDocNumber$Json(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<{
'number'?: string;
}> {

    return this.unitPrototypeNextDocNumber$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'number'?: string;
}>) => r.body as {
'number'?: string;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeNextDocNumber$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeNextDocNumber$Xml$Response(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'number'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeNextDocNumberPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('incoming', params.incoming, {});
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'number'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeNextDocNumber$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeNextDocNumber$Xml(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<{
'number'?: string;
}> {

    return this.unitPrototypeNextDocNumber$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'number'?: string;
}>) => r.body as {
'number'?: string;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeNextDocNumber$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeNextDocNumber$Javascript$Response(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'number'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeNextDocNumberPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('incoming', params.incoming, {});
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'number'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeNextDocNumber$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeNextDocNumber$Javascript(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<{
'number'?: string;
}> {

    return this.unitPrototypeNextDocNumber$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'number'?: string;
}>) => r.body as {
'number'?: string;
})
    );
  }

  /**
   * Path part for operation unitPrototypeUploadImagePostUnitsIdUploadImage
   */
  static readonly UnitPrototypeUploadImagePostUnitsIdUploadImagePath = '/v1/Units/{id}/upload-image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeUploadImagePostUnitsIdUploadImage$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  unitPrototypeUploadImagePostUnitsIdUploadImage$Json$Response(params: {

    /**
     * Unit id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeUploadImagePostUnitsIdUploadImagePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeUploadImagePostUnitsIdUploadImage$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  unitPrototypeUploadImagePostUnitsIdUploadImage$Json(params: {

    /**
     * Unit id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<boolean> {

    return this.unitPrototypeUploadImagePostUnitsIdUploadImage$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeUploadImagePostUnitsIdUploadImage$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  unitPrototypeUploadImagePostUnitsIdUploadImage$Xml$Response(params: {

    /**
     * Unit id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeUploadImagePostUnitsIdUploadImagePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeUploadImagePostUnitsIdUploadImage$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  unitPrototypeUploadImagePostUnitsIdUploadImage$Xml(params: {

    /**
     * Unit id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<boolean> {

    return this.unitPrototypeUploadImagePostUnitsIdUploadImage$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeUploadImagePostUnitsIdUploadImage$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  unitPrototypeUploadImagePostUnitsIdUploadImage$Javascript$Response(params: {

    /**
     * Unit id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeUploadImagePostUnitsIdUploadImagePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeUploadImagePostUnitsIdUploadImage$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  unitPrototypeUploadImagePostUnitsIdUploadImage$Javascript(params: {

    /**
     * Unit id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<boolean> {

    return this.unitPrototypeUploadImagePostUnitsIdUploadImage$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation unitPrototypeDownloadImageGetUnitsIdImages
   */
  static readonly UnitPrototypeDownloadImageGetUnitsIdImagesPath = '/v1/Units/{id}/images';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeDownloadImageGetUnitsIdImages$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDownloadImageGetUnitsIdImages$Json$Response(params: {

    /**
     * Unit id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeDownloadImageGetUnitsIdImagesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeDownloadImageGetUnitsIdImages$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDownloadImageGetUnitsIdImages$Json(params: {

    /**
     * Unit id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<string> {

    return this.unitPrototypeDownloadImageGetUnitsIdImages$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeDownloadImageGetUnitsIdImages$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDownloadImageGetUnitsIdImages$Xml$Response(params: {

    /**
     * Unit id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeDownloadImageGetUnitsIdImagesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeDownloadImageGetUnitsIdImages$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDownloadImageGetUnitsIdImages$Xml(params: {

    /**
     * Unit id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<string> {

    return this.unitPrototypeDownloadImageGetUnitsIdImages$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeDownloadImageGetUnitsIdImages$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDownloadImageGetUnitsIdImages$Javascript$Response(params: {

    /**
     * Unit id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeDownloadImageGetUnitsIdImagesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeDownloadImageGetUnitsIdImages$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDownloadImageGetUnitsIdImages$Javascript(params: {

    /**
     * Unit id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<string> {

    return this.unitPrototypeDownloadImageGetUnitsIdImages$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation unitPrototypeUploadImagePostUnitsIdImages
   */
  static readonly UnitPrototypeUploadImagePostUnitsIdImagesPath = '/v1/Units/{id}/images';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeUploadImagePostUnitsIdImages$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  unitPrototypeUploadImagePostUnitsIdImages$Json$Response(params: {

    /**
     * Unit id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeUploadImagePostUnitsIdImagesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeUploadImagePostUnitsIdImages$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  unitPrototypeUploadImagePostUnitsIdImages$Json(params: {

    /**
     * Unit id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<boolean> {

    return this.unitPrototypeUploadImagePostUnitsIdImages$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeUploadImagePostUnitsIdImages$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  unitPrototypeUploadImagePostUnitsIdImages$Xml$Response(params: {

    /**
     * Unit id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeUploadImagePostUnitsIdImagesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeUploadImagePostUnitsIdImages$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  unitPrototypeUploadImagePostUnitsIdImages$Xml(params: {

    /**
     * Unit id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<boolean> {

    return this.unitPrototypeUploadImagePostUnitsIdImages$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeUploadImagePostUnitsIdImages$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  unitPrototypeUploadImagePostUnitsIdImages$Javascript$Response(params: {

    /**
     * Unit id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeUploadImagePostUnitsIdImagesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeUploadImagePostUnitsIdImages$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  unitPrototypeUploadImagePostUnitsIdImages$Javascript(params: {

    /**
     * Unit id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<boolean> {

    return this.unitPrototypeUploadImagePostUnitsIdImages$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation unitPrototypeDeleteImageDeleteUnitsIdImages
   */
  static readonly UnitPrototypeDeleteImageDeleteUnitsIdImagesPath = '/v1/Units/{id}/images';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeDeleteImageDeleteUnitsIdImages$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDeleteImageDeleteUnitsIdImages$Json$Response(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeDeleteImageDeleteUnitsIdImagesPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeDeleteImageDeleteUnitsIdImages$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDeleteImageDeleteUnitsIdImages$Json(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.unitPrototypeDeleteImageDeleteUnitsIdImages$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeDeleteImageDeleteUnitsIdImages$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDeleteImageDeleteUnitsIdImages$Xml$Response(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeDeleteImageDeleteUnitsIdImagesPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeDeleteImageDeleteUnitsIdImages$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDeleteImageDeleteUnitsIdImages$Xml(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.unitPrototypeDeleteImageDeleteUnitsIdImages$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeDeleteImageDeleteUnitsIdImages$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDeleteImageDeleteUnitsIdImages$Javascript$Response(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeDeleteImageDeleteUnitsIdImagesPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeDeleteImageDeleteUnitsIdImages$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDeleteImageDeleteUnitsIdImages$Javascript(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.unitPrototypeDeleteImageDeleteUnitsIdImages$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation unitPrototypeDeleteImageDeleteUnitsIdDeleteImage
   */
  static readonly UnitPrototypeDeleteImageDeleteUnitsIdDeleteImagePath = '/v1/Units/{id}/delete-image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeDeleteImageDeleteUnitsIdDeleteImage$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDeleteImageDeleteUnitsIdDeleteImage$Json$Response(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeDeleteImageDeleteUnitsIdDeleteImagePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeDeleteImageDeleteUnitsIdDeleteImage$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDeleteImageDeleteUnitsIdDeleteImage$Json(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.unitPrototypeDeleteImageDeleteUnitsIdDeleteImage$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeDeleteImageDeleteUnitsIdDeleteImage$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDeleteImageDeleteUnitsIdDeleteImage$Xml$Response(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeDeleteImageDeleteUnitsIdDeleteImagePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeDeleteImageDeleteUnitsIdDeleteImage$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDeleteImageDeleteUnitsIdDeleteImage$Xml(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.unitPrototypeDeleteImageDeleteUnitsIdDeleteImage$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeDeleteImageDeleteUnitsIdDeleteImage$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDeleteImageDeleteUnitsIdDeleteImage$Javascript$Response(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeDeleteImageDeleteUnitsIdDeleteImagePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeDeleteImageDeleteUnitsIdDeleteImage$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDeleteImageDeleteUnitsIdDeleteImage$Javascript(params: {

    /**
     * Unit id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.unitPrototypeDeleteImageDeleteUnitsIdDeleteImage$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation unitPrototypeDownloadImageGetUnitsIdDownloadImage
   */
  static readonly UnitPrototypeDownloadImageGetUnitsIdDownloadImagePath = '/v1/Units/{id}/download-image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeDownloadImageGetUnitsIdDownloadImage$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDownloadImageGetUnitsIdDownloadImage$Json$Response(params: {

    /**
     * Unit id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeDownloadImageGetUnitsIdDownloadImagePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeDownloadImageGetUnitsIdDownloadImage$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDownloadImageGetUnitsIdDownloadImage$Json(params: {

    /**
     * Unit id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<string> {

    return this.unitPrototypeDownloadImageGetUnitsIdDownloadImage$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeDownloadImageGetUnitsIdDownloadImage$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDownloadImageGetUnitsIdDownloadImage$Xml$Response(params: {

    /**
     * Unit id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeDownloadImageGetUnitsIdDownloadImagePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeDownloadImageGetUnitsIdDownloadImage$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDownloadImageGetUnitsIdDownloadImage$Xml(params: {

    /**
     * Unit id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<string> {

    return this.unitPrototypeDownloadImageGetUnitsIdDownloadImage$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitPrototypeDownloadImageGetUnitsIdDownloadImage$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDownloadImageGetUnitsIdDownloadImage$Javascript$Response(params: {

    /**
     * Unit id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UnitService.UnitPrototypeDownloadImageGetUnitsIdDownloadImagePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitPrototypeDownloadImageGetUnitsIdDownloadImage$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitPrototypeDownloadImageGetUnitsIdDownloadImage$Javascript(params: {

    /**
     * Unit id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<string> {

    return this.unitPrototypeDownloadImageGetUnitsIdDownloadImage$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
