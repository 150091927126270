import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable()
export class LoadingService {
  public _loading: number;
  private loading$: ReplaySubject<boolean> = new ReplaySubject(1);
  readonly loading: Observable<boolean> = this.loading$.asObservable();

  constructor() {
    this._loading = 0;
    this.loading$.next(!!this._loading);
  }

  setLoading(step = 1) {
    this._loading += step;
    this.loading$.next(!!this._loading);
  }

  unsetLoading(step = 1) {
    this._loading -= step;
    if (this._loading <= 0) this._loading = 0;
    this.loading$.next(!!this._loading);
  }
}
