import { Injectable } from '@angular/core';
import { CookieBrowser } from '../sdk/storage/cookie.browser';

@Injectable()
export class StorageService {

  constructor(
    private cookieService: CookieBrowser,
  ) { }

  getItem(key: string) {
    let data = this.driver.get(key);

    if (!!data) {
      try {
        data = JSON.parse(data);
      } catch (e) { }
    }

    return data;
  }

  removeItem(key: string) {
    this.driver.remove(key);
  }

  setItem(key: string, value: string | object, expires?: Date) {
    if (!expires) {
      const now = new Date();
      expires = new Date();
      expires.setFullYear(now.getFullYear() + 1);
    }

    this.driver.set(key, JSON.stringify(value), expires);
  }

  private get driver() {
    return this.cookieService;
  }
}
