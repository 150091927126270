import { Injectable } from '@angular/core';
import { LoopBackConfig, LoggerService } from '../shared/sdk';
import { AccountApi } from '../shared/sdk/services';
import { ISubscription } from './subscription';

import { BASE_URL, API_VERSION } from '../shared/base-url';
import { ApiService } from '../shared/services/api.service';
import { ToastService } from '../shared/modules/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { CacheService } from '../shared/services/cache.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LanguageService } from '../shared/services/language.service';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { SubscriptionChange } from './subscription-change';
import { StorageService } from '../shared/services/storage.service';

export const availableAdditions = {
  furs: [5, 50],
  integrations: [5, 50],
  inventory: [5, 50],
  units: [10, 100],
  additionalUsers: [5, 50],
};

export const gateways = [
  {
    name: 'Saved payment method',
    value: 'saved',
  },
  {
    name: 'Credit Card',
    value: 'creditcard',
  },
  {
    name: 'PayPal',
    value: 'paypal',
  },
  {
    name: 'Bank Transfer',
    value: 'bank',
  },
];

@Injectable()
export class ApolloSubscriptionService extends ApiService<ISubscription> {
  lang: string;
  name = 'Subscription';
  plural = 'Subscriptions';
  trial = false;
  trialEnds = -1;
  trialFull = false;
  ttl = 600001;

  public _active: ISubscription;
  private active$: ReplaySubject<ISubscription> = new ReplaySubject(1);
  readonly active: Observable<ISubscription> = this.active$.asObservable();

  constructor(
    protected cacheService: CacheService,
    protected log: LoggerService,
    protected toastService: ToastService,
    protected translate: TranslateService,
    private accountApi: AccountApi,
    private http: HttpClient,
    private languageService: LanguageService,
    private storageService: StorageService,
  ) {
    super(cacheService, log, toastService, translate);

    LoopBackConfig.setBaseURL(BASE_URL);
    LoopBackConfig.setApiVersion(API_VERSION);

    this.languageService.current.subscribe((lang) => (this.lang = lang));
  }

  cancel(orgId: string) {
    this.http
      .post(
        `${
          environment.selfUrl || ''
        }/organizations/${orgId}/subscription/cancel`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.accountApi.getCurrentToken().id}`,
          },
        },
      )
      .subscribe(
        (subs: ISubscription) => {
          this.toastService.success(
            this.translate.instant('Subscription successfully canceled.'),
          );

          this._active = subs;
          this.active$.next(this._active);
          return subs;
        },
        () => {
          this.toastService.error(
            this.translate.instant(
              'Error canceling subscription, please try again or contact support.',
            ),
          );
        },
      );
  }

  change(orgId: string, sub: SubscriptionChange): Observable<ISubscription> {
    sub.source = this.getSource();

    return this.http
      .post(
        `${environment.selfUrl || ''}/organizations/${orgId}/subscription?l=${
          this.lang
        }`,
        sub,
        {
          headers: {
            Authorization: `Bearer ${this.accountApi.getCurrentToken().id}`,
          },
        },
      )
      .pipe(
        catchError((e) => {
          if (
            !sub.paymentMethodNonce &&
            ['creditcard', 'paypal'].includes(sub.gateway)
          ) {
            // Failed charging saved payment method
            this.toastService.info(
              this.translate.instant(
                'subscription-service.charge.error-charging-saved-method.toast.message',
              ),
              this.translate.instant(
                'subscription-service.charge.error-charging-saved-method.toast.title',
              ),
              {
                delay: 10000,
              },
            );
            throw new Error('Failed processing saved payment method');
          } else {
            throw e;
          }
        }),
        map((subs: ISubscription | any) => {
          if (subs && typeof subs.success !== 'undefined' && !subs.success) {
            throw new Error('Failed changing subscription');
          }

          if (subs.active) {
            this.setActive(subs);
          }

          return subs;
        }),
      );
  }

  getActive(orgId: string): Observable<ISubscription> {
    this.log.log(`Apollo${this.name}Service: getActive`);
    const cacheKey = `${orgId}_Apollo${this.name}_getActive`;

    let source = this.getSource();
    if (source) {
      source = JSON.stringify(source);
    }

    const method = this.http.get(
      `${environment.selfUrl || ''}/organizations/${orgId}/subscription`,
      {
        headers: {
          Authorization: `Bearer ${this.accountApi.getCurrentToken().id}`,
        },
        params: {
          source,
        },
      },
    );

    return this.cachedReq(cacheKey, method, true).pipe(
      tap((subs) => this.setActive(subs)),
    );
  }

  getClientToken(): Observable<string> {
    return this.http.get<string>(
      `${environment.selfUrl || ''}/subscriptions/client-token`,
      {
        headers: {
          Authorization: `Bearer ${this.accountApi.getCurrentToken().id}`,
        },
      },
    );
  }

  getPlans(force = false, toastr = false) {
    this.log.log(`Apollo${this.name}Service: getPlans`);
    const cacheKey = `Apollo${this.name}_getPlans`;

    return this.cachedReq(
      cacheKey,
      this.http.get(`${environment.selfUrl || ''}/plans`),
      force,
      toastr,
    );
  }

  isTrial(): Observable<boolean> {
    return this.active.pipe(
      map((sub) => !!sub && sub._subscriptionPlan.tier === 0),
    );
  }

  setActive(sub: ISubscription) {
    this._active = sub as ISubscription;
    this.active$.next(this._active);
  }

  getSource(): any {
    // Get referral source from cookies
    const utm_source = this.storageService.getItem('a:utm_source');
    let source: any;

    if (utm_source) {
      const utm_medium = String(this.storageService.getItem('a:utm_medium'));
      const utm_campaign = String(
        this.storageService.getItem('a:utm_campaign'),
      );
      const utm_content = String(this.storageService.getItem('a:utm_content'));
      const utm_term = decodeURIComponent(
        this.storageService.getItem('a:utm_term'),
      );

      source = {
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term,
      };
    }

    return source;
  }
}
