/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Country } from '../models/country';

@Injectable({
  providedIn: 'root',
})
export class CountryService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation countryFind
   */
  static readonly CountryFindPath = '/v1/Countries';

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countryFind$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  countryFind$Json$Response(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Country>>> {

    const rb = new RequestBuilder(this.rootUrl, CountryService.CountryFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Country>>;
      })
    );
  }

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countryFind$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countryFind$Json(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Country>> {

    return this.countryFind$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Country>>) => r.body as Array<Country>)
    );
  }

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countryFind$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  countryFind$Xml$Response(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Country>>> {

    const rb = new RequestBuilder(this.rootUrl, CountryService.CountryFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Country>>;
      })
    );
  }

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countryFind$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countryFind$Xml(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Country>> {

    return this.countryFind$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Country>>) => r.body as Array<Country>)
    );
  }

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countryFind$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  countryFind$Javascript$Response(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Country>>> {

    const rb = new RequestBuilder(this.rootUrl, CountryService.CountryFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Country>>;
      })
    );
  }

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countryFind$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countryFind$Javascript(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Country>> {

    return this.countryFind$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Country>>) => r.body as Array<Country>)
    );
  }

}
