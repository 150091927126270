import { Injectable } from '@angular/core';
import { StorageService } from '../shared/services/storage.service';
import { LoggerService } from '../shared/sdk';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AccountService } from '../accounts/account.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReferralService {
  referrerId: string;
  private name = 'ReferralService';
  private expiresDays: number = 45;

  constructor(
    private accountService: AccountService,
    private log: LoggerService,
    private storageService: StorageService,
    private http: HttpClient,
  ) {
  }

  init(referrerId: string) {
    let inCookie: boolean;
    this.referrerId = referrerId;

    if (!this.referrerId) {
      // If saved by website to cookie
      this.log.log(`${this.name}: no referral code in url`);
      this.referrerId = this.storageService.getItem('a:ref');
      inCookie = true;
    }

    if (this.referrerId && !inCookie) {
      this.log.log(`${this.name}: saving referrerId to cookie`);
      this.storageService.setItem('a:ref', this.referrerId, moment().add(this.expiresDays, 'days').toDate());
    }

    this.log.log(`${this.name}: ${this.referrerId}`)
  }

  used() {
    this.referrerId = null;
    this.storageService.removeItem('a:ref');
  }

  get(): Observable<any[]> {
    return this.http
      .get<any[]>(
        `${environment.selfUrl ||
        ''}/referrals`,
        {
          headers: {
            Authorization: `Bearer ${this.accountService.getToken()['id']}`,
          },
        },
      );
  }

  getCommissions(): Observable<any[]> {
    return this.http
      .get<any[]>(
        `${environment.selfUrl ||
        ''}/referrals/commissions`,
        {
          headers: {
            Authorization: `Bearer ${this.accountService.getToken()['id']}`,
          },
        },
      );
  }
}
