import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map, Observable, tap } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  updateAvailable = false;
  isUpdateAvailable: Observable<boolean>;
  // private updateAvailableToastr: ActiveToast<any>;
  private isUpdateAvailableObs: any;

  constructor(private swUpdate: SwUpdate) {
    this.isUpdateAvailable = new Observable(
      (obs) => (this.isUpdateAvailableObs = obs),
    );

    if (environment.production) {
      this.isUpdateAvailableObs = this.swUpdate.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map((evt) => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        })),
        tap(() => (this.updateAvailable = true)),
      );
    }
  }

  onActivateUpdate() {
    if (environment.production) {
      this.swUpdate.activateUpdate().then(() => document.location.reload());
    }
  }
}
