/* tslint:disable */
import {
  Organization,
  Document,
  Item,
  Client,
  Data
} from '../index';

declare var Object: any;
export interface UnitInterface {
  "name": string;
  "address"?: string;
  "address2"?: string;
  "city"?: string;
  "zip"?: string;
  "state"?: string;
  "country"?: string;
  "countryAlpha2Code"?: string;
  "taxNumber"?: string;
  "taxSubject"?: boolean;
  "companyNumber"?: string;
  "IBAN"?: string;
  "bank"?: string;
  "SWIFT"?: string;
  "website"?: string;
  "email"?: string;
  "phone"?: string;
  "id"?: any;
  "organizationId"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "custom"?: any;
  "deletedAt"?: Date;
  "_isDeleted"?: boolean;
  "deletedId"?: string;
  "_data"?: Array<any>;
  organization?: Organization;
  documents?: Document[];
  items?: Item[];
  clients?: Client[];
  data?: Data[];
}

export class Unit implements UnitInterface {
  "name": string;
  "address": string;
  "address2": string;
  "city": string;
  "zip": string;
  "state": string;
  "country": string;
  "countryAlpha2Code": string;
  "taxNumber": string;
  "taxSubject": boolean;
  "companyNumber": string;
  "IBAN": string;
  "bank": string;
  "SWIFT": string;
  "website": string;
  "email": string;
  "phone": string;
  "id": any;
  "organizationId": any;
  "createdAt": Date;
  "updatedAt": Date;
  "custom": any;
  "deletedAt": Date;
  "_isDeleted": boolean;
  "deletedId": string;
  "_data": Array<any>;
  organization: Organization;
  documents: Document[];
  items: Item[];
  clients: Client[];
  data: Data[];
  constructor(data?: UnitInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Unit`.
   */
  public static getModelName() {
    return "Unit";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Unit for dynamic purposes.
  **/
  public static factory(data: UnitInterface): Unit{
    return new Unit(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Unit',
      plural: 'Units',
      path: 'Units',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "address": {
          name: 'address',
          type: 'string'
        },
        "address2": {
          name: 'address2',
          type: 'string'
        },
        "city": {
          name: 'city',
          type: 'string'
        },
        "zip": {
          name: 'zip',
          type: 'string'
        },
        "state": {
          name: 'state',
          type: 'string'
        },
        "country": {
          name: 'country',
          type: 'string'
        },
        "countryAlpha2Code": {
          name: 'countryAlpha2Code',
          type: 'string'
        },
        "taxNumber": {
          name: 'taxNumber',
          type: 'string'
        },
        "taxSubject": {
          name: 'taxSubject',
          type: 'boolean'
        },
        "companyNumber": {
          name: 'companyNumber',
          type: 'string'
        },
        "IBAN": {
          name: 'IBAN',
          type: 'string'
        },
        "bank": {
          name: 'bank',
          type: 'string'
        },
        "SWIFT": {
          name: 'SWIFT',
          type: 'string'
        },
        "website": {
          name: 'website',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "phone": {
          name: 'phone',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "organizationId": {
          name: 'organizationId',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        "custom": {
          name: 'custom',
          type: 'any',
          default: <any>null
        },
        "deletedAt": {
          name: 'deletedAt',
          type: 'Date'
        },
        "_isDeleted": {
          name: '_isDeleted',
          type: 'boolean'
        },
        "deletedId": {
          name: 'deletedId',
          type: 'string'
        },
        "_data": {
          name: '_data',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
      },
      relations: {
        organization: {
          name: 'organization',
          type: 'Organization',
          model: 'Organization',
          relationType: 'belongsTo',
                  keyFrom: 'organizationId',
          keyTo: 'id'
        },
        documents: {
          name: 'documents',
          type: 'Document[]',
          model: 'Document',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'unitId'
        },
        items: {
          name: 'items',
          type: 'Item[]',
          model: 'Item',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'unitId'
        },
        clients: {
          name: 'clients',
          type: 'Client[]',
          model: 'Client',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'unitId'
        },
        data: {
          name: 'data',
          type: 'Data[]',
          model: 'Data',
          relationType: 'embedsMany',
                  keyFrom: '_data',
          keyTo: 'id'
        },
      }
    }
  }
}
