<!-- IE 11 warning -->
<!--[if lte IE 11]>
  <div class="update-notice d-flex justify-content-center">
    <div
      class="alert alert-warning text-center small ng-cloak mx-auto mb-0 mt-1"
    >
      <a href="https://browsehappy.com" class="alert-link">
        {{ 'app.ie11-warning' | translate }}
      </a>
    </div>
  </div>
<![endif]-->

<!-- App update alert -->
<div class="update-notice d-flex justify-content-center" *ngIf="hasUpdate">
  <div class="alert alert-primary text-center small ng-cloak mx-auto mb-0 mt-1">
    <a href (click)="onActivateUpdate(); (false)" class="alert-link">
      {{ 'app.update-notice' | translate }}
    </a>
  </div>
</div>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

<div
  class="loading is-loading is-loading-lg d-none"
  #spinnerElement
  [class.theme-dark]="themeService.dark | async"
></div>

<div [class.theme-dark]="themeService.dark | async" appIframeHeight>
  <router-outlet></router-outlet>
</div>
