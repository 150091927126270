/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class HealthControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation healthControllerLive
   */
  static readonly HealthControllerLivePath = '/live';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `live()` instead.
   *
   * This method doesn't expect any request body.
   */
  live$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'status'?: string;
'checks'?: Array<{
'name'?: string;
'state'?: string;
'data'?: {
'reason'?: string;
};
}>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HealthControllerService.HealthControllerLivePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'status'?: string;
        'checks'?: Array<{
        'name'?: string;
        'state'?: string;
        'data'?: {
        'reason'?: string;
        };
        }>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `live$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  live(params?: {
  },
  context?: HttpContext

): Observable<{
'status'?: string;
'checks'?: Array<{
'name'?: string;
'state'?: string;
'data'?: {
'reason'?: string;
};
}>;
}> {

    return this.live$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'status'?: string;
'checks'?: Array<{
'name'?: string;
'state'?: string;
'data'?: {
'reason'?: string;
};
}>;
}>) => r.body as {
'status'?: string;
'checks'?: Array<{
'name'?: string;
'state'?: string;
'data'?: {
'reason'?: string;
};
}>;
})
    );
  }

  /**
   * Path part for operation healthControllerReady
   */
  static readonly HealthControllerReadyPath = '/ready';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ready()` instead.
   *
   * This method doesn't expect any request body.
   */
  ready$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'status'?: string;
'checks'?: Array<{
'name'?: string;
'state'?: string;
'data'?: {
'reason'?: string;
};
}>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HealthControllerService.HealthControllerReadyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'status'?: string;
        'checks'?: Array<{
        'name'?: string;
        'state'?: string;
        'data'?: {
        'reason'?: string;
        };
        }>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ready$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ready(params?: {
  },
  context?: HttpContext

): Observable<{
'status'?: string;
'checks'?: Array<{
'name'?: string;
'state'?: string;
'data'?: {
'reason'?: string;
};
}>;
}> {

    return this.ready$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'status'?: string;
'checks'?: Array<{
'name'?: string;
'state'?: string;
'data'?: {
'reason'?: string;
};
}>;
}>) => r.body as {
'status'?: string;
'checks'?: Array<{
'name'?: string;
'state'?: string;
'data'?: {
'reason'?: string;
};
}>;
})
    );
  }

  /**
   * Path part for operation healthControllerHealth
   */
  static readonly HealthControllerHealthPath = '/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `health()` instead.
   *
   * This method doesn't expect any request body.
   */
  health$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'status'?: string;
'checks'?: Array<{
'name'?: string;
'state'?: string;
'data'?: {
'reason'?: string;
};
}>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HealthControllerService.HealthControllerHealthPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'status'?: string;
        'checks'?: Array<{
        'name'?: string;
        'state'?: string;
        'data'?: {
        'reason'?: string;
        };
        }>;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `health$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  health(params?: {
  },
  context?: HttpContext

): Observable<{
'status'?: string;
'checks'?: Array<{
'name'?: string;
'state'?: string;
'data'?: {
'reason'?: string;
};
}>;
}> {

    return this.health$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'status'?: string;
'checks'?: Array<{
'name'?: string;
'state'?: string;
'data'?: {
'reason'?: string;
};
}>;
}>) => r.body as {
'status'?: string;
'checks'?: Array<{
'name'?: string;
'state'?: string;
'data'?: {
'reason'?: string;
};
}>;
})
    );
  }

}
