/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BusinessPremise } from '../models/business-premise';
import { ElectronicDevice } from '../models/electronic-device';
import { NewElectronicDevice } from '../models/new-electronic-device';

@Injectable({
  providedIn: 'root',
})
export class BusinessPremiseService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation businessPremisePrototypeCreateElectronicDevices
   */
  static readonly BusinessPremisePrototypeCreateElectronicDevicesPath = '/v1/BusinessPremises/{id}/electronicDevices';

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessPremisePrototypeCreateElectronicDevices$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  businessPremisePrototypeCreateElectronicDevices$Json$Json$Response(params: {

    /**
     * BusinessPremise id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPremiseService.BusinessPremisePrototypeCreateElectronicDevicesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessPremisePrototypeCreateElectronicDevices$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  businessPremisePrototypeCreateElectronicDevices$Json$Json(params: {

    /**
     * BusinessPremise id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.businessPremisePrototypeCreateElectronicDevices$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessPremisePrototypeCreateElectronicDevices$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  businessPremisePrototypeCreateElectronicDevices$Json$Xml$Response(params: {

    /**
     * BusinessPremise id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPremiseService.BusinessPremisePrototypeCreateElectronicDevicesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessPremisePrototypeCreateElectronicDevices$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  businessPremisePrototypeCreateElectronicDevices$Json$Xml(params: {

    /**
     * BusinessPremise id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.businessPremisePrototypeCreateElectronicDevices$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessPremisePrototypeCreateElectronicDevices$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  businessPremisePrototypeCreateElectronicDevices$Json$Javascript$Response(params: {

    /**
     * BusinessPremise id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPremiseService.BusinessPremisePrototypeCreateElectronicDevicesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessPremisePrototypeCreateElectronicDevices$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  businessPremisePrototypeCreateElectronicDevices$Json$Javascript(params: {

    /**
     * BusinessPremise id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.businessPremisePrototypeCreateElectronicDevices$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessPremisePrototypeCreateElectronicDevices$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  businessPremisePrototypeCreateElectronicDevices$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * BusinessPremise id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPremiseService.BusinessPremisePrototypeCreateElectronicDevicesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessPremisePrototypeCreateElectronicDevices$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  businessPremisePrototypeCreateElectronicDevices$XWwwFormUrlencoded$Json(params: {

    /**
     * BusinessPremise id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.businessPremisePrototypeCreateElectronicDevices$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessPremisePrototypeCreateElectronicDevices$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  businessPremisePrototypeCreateElectronicDevices$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * BusinessPremise id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPremiseService.BusinessPremisePrototypeCreateElectronicDevicesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessPremisePrototypeCreateElectronicDevices$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  businessPremisePrototypeCreateElectronicDevices$XWwwFormUrlencoded$Xml(params: {

    /**
     * BusinessPremise id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.businessPremisePrototypeCreateElectronicDevices$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessPremisePrototypeCreateElectronicDevices$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  businessPremisePrototypeCreateElectronicDevices$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * BusinessPremise id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPremiseService.BusinessPremisePrototypeCreateElectronicDevicesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessPremisePrototypeCreateElectronicDevices$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  businessPremisePrototypeCreateElectronicDevices$XWwwFormUrlencoded$Javascript(params: {

    /**
     * BusinessPremise id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.businessPremisePrototypeCreateElectronicDevices$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessPremisePrototypeCreateElectronicDevices$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  businessPremisePrototypeCreateElectronicDevices$Xml$Json$Response(params: {

    /**
     * BusinessPremise id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPremiseService.BusinessPremisePrototypeCreateElectronicDevicesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessPremisePrototypeCreateElectronicDevices$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  businessPremisePrototypeCreateElectronicDevices$Xml$Json(params: {

    /**
     * BusinessPremise id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.businessPremisePrototypeCreateElectronicDevices$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessPremisePrototypeCreateElectronicDevices$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  businessPremisePrototypeCreateElectronicDevices$Xml$Xml$Response(params: {

    /**
     * BusinessPremise id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPremiseService.BusinessPremisePrototypeCreateElectronicDevicesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessPremisePrototypeCreateElectronicDevices$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  businessPremisePrototypeCreateElectronicDevices$Xml$Xml(params: {

    /**
     * BusinessPremise id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.businessPremisePrototypeCreateElectronicDevices$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessPremisePrototypeCreateElectronicDevices$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  businessPremisePrototypeCreateElectronicDevices$Xml$Javascript$Response(params: {

    /**
     * BusinessPremise id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPremiseService.BusinessPremisePrototypeCreateElectronicDevicesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessPremisePrototypeCreateElectronicDevices$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  businessPremisePrototypeCreateElectronicDevices$Xml$Javascript(params: {

    /**
     * BusinessPremise id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.businessPremisePrototypeCreateElectronicDevices$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Path part for operation businessPremiseDeleteById
   */
  static readonly BusinessPremiseDeleteByIdPath = '/v1/BusinessPremises/{id}';

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessPremiseDeleteById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  businessPremiseDeleteById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPremiseService.BusinessPremiseDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessPremiseDeleteById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  businessPremiseDeleteById$Json(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.businessPremiseDeleteById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessPremiseDeleteById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  businessPremiseDeleteById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPremiseService.BusinessPremiseDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessPremiseDeleteById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  businessPremiseDeleteById$Xml(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.businessPremiseDeleteById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessPremiseDeleteById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  businessPremiseDeleteById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPremiseService.BusinessPremiseDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessPremiseDeleteById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  businessPremiseDeleteById$Javascript(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.businessPremiseDeleteById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation businessPremisePrototypePatchAttributes
   */
  static readonly BusinessPremisePrototypePatchAttributesPath = '/v1/BusinessPremises/{id}';

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessPremisePrototypePatchAttributes$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  businessPremisePrototypePatchAttributes$Json$Json$Response(params: {

    /**
     * BusinessPremise id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: BusinessPremise
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BusinessPremise>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPremiseService.BusinessPremisePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BusinessPremise>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessPremisePrototypePatchAttributes$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  businessPremisePrototypePatchAttributes$Json$Json(params: {

    /**
     * BusinessPremise id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: BusinessPremise
  },
  context?: HttpContext

): Observable<BusinessPremise> {

    return this.businessPremisePrototypePatchAttributes$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<BusinessPremise>) => r.body as BusinessPremise)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessPremisePrototypePatchAttributes$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  businessPremisePrototypePatchAttributes$Json$Xml$Response(params: {

    /**
     * BusinessPremise id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: BusinessPremise
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BusinessPremise>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPremiseService.BusinessPremisePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BusinessPremise>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessPremisePrototypePatchAttributes$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  businessPremisePrototypePatchAttributes$Json$Xml(params: {

    /**
     * BusinessPremise id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: BusinessPremise
  },
  context?: HttpContext

): Observable<BusinessPremise> {

    return this.businessPremisePrototypePatchAttributes$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<BusinessPremise>) => r.body as BusinessPremise)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessPremisePrototypePatchAttributes$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  businessPremisePrototypePatchAttributes$Json$Javascript$Response(params: {

    /**
     * BusinessPremise id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: BusinessPremise
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BusinessPremise>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPremiseService.BusinessPremisePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BusinessPremise>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessPremisePrototypePatchAttributes$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  businessPremisePrototypePatchAttributes$Json$Javascript(params: {

    /**
     * BusinessPremise id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: BusinessPremise
  },
  context?: HttpContext

): Observable<BusinessPremise> {

    return this.businessPremisePrototypePatchAttributes$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<BusinessPremise>) => r.body as BusinessPremise)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessPremisePrototypePatchAttributes$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  businessPremisePrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * BusinessPremise id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: BusinessPremise
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BusinessPremise>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPremiseService.BusinessPremisePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BusinessPremise>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessPremisePrototypePatchAttributes$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  businessPremisePrototypePatchAttributes$XWwwFormUrlencoded$Json(params: {

    /**
     * BusinessPremise id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: BusinessPremise
  },
  context?: HttpContext

): Observable<BusinessPremise> {

    return this.businessPremisePrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<BusinessPremise>) => r.body as BusinessPremise)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessPremisePrototypePatchAttributes$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  businessPremisePrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * BusinessPremise id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: BusinessPremise
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BusinessPremise>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPremiseService.BusinessPremisePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BusinessPremise>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessPremisePrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  businessPremisePrototypePatchAttributes$XWwwFormUrlencoded$Xml(params: {

    /**
     * BusinessPremise id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: BusinessPremise
  },
  context?: HttpContext

): Observable<BusinessPremise> {

    return this.businessPremisePrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<BusinessPremise>) => r.body as BusinessPremise)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessPremisePrototypePatchAttributes$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  businessPremisePrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * BusinessPremise id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: BusinessPremise
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BusinessPremise>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPremiseService.BusinessPremisePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BusinessPremise>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessPremisePrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  businessPremisePrototypePatchAttributes$XWwwFormUrlencoded$Javascript(params: {

    /**
     * BusinessPremise id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: BusinessPremise
  },
  context?: HttpContext

): Observable<BusinessPremise> {

    return this.businessPremisePrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<BusinessPremise>) => r.body as BusinessPremise)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessPremisePrototypePatchAttributes$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  businessPremisePrototypePatchAttributes$Xml$Json$Response(params: {

    /**
     * BusinessPremise id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: BusinessPremise
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BusinessPremise>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPremiseService.BusinessPremisePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BusinessPremise>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessPremisePrototypePatchAttributes$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  businessPremisePrototypePatchAttributes$Xml$Json(params: {

    /**
     * BusinessPremise id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: BusinessPremise
  },
  context?: HttpContext

): Observable<BusinessPremise> {

    return this.businessPremisePrototypePatchAttributes$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<BusinessPremise>) => r.body as BusinessPremise)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessPremisePrototypePatchAttributes$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  businessPremisePrototypePatchAttributes$Xml$Xml$Response(params: {

    /**
     * BusinessPremise id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: BusinessPremise
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BusinessPremise>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPremiseService.BusinessPremisePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BusinessPremise>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessPremisePrototypePatchAttributes$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  businessPremisePrototypePatchAttributes$Xml$Xml(params: {

    /**
     * BusinessPremise id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: BusinessPremise
  },
  context?: HttpContext

): Observable<BusinessPremise> {

    return this.businessPremisePrototypePatchAttributes$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<BusinessPremise>) => r.body as BusinessPremise)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessPremisePrototypePatchAttributes$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  businessPremisePrototypePatchAttributes$Xml$Javascript$Response(params: {

    /**
     * BusinessPremise id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: BusinessPremise
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BusinessPremise>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPremiseService.BusinessPremisePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BusinessPremise>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessPremisePrototypePatchAttributes$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  businessPremisePrototypePatchAttributes$Xml$Javascript(params: {

    /**
     * BusinessPremise id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: BusinessPremise
  },
  context?: HttpContext

): Observable<BusinessPremise> {

    return this.businessPremisePrototypePatchAttributes$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<BusinessPremise>) => r.body as BusinessPremise)
    );
  }

}
