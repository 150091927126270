/* tslint:disable */
import {
  BusinessPremise,
  Organization
} from '../index';

declare var Object: any;
export interface ElectronicDeviceInterface {
  "electronicDeviceId": string;
  "numbers"?: any;
  "provider": string;
  "environment": string;
  "id"?: any;
  "businessPremiseId"?: any;
  "organizationId"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "custom"?: any;
  businessPremise?: BusinessPremise;
  organization?: Organization;
}

export class ElectronicDevice implements ElectronicDeviceInterface {
  "electronicDeviceId": string;
  "numbers": any;
  "provider": string;
  "environment": string;
  "id": any;
  "businessPremiseId": any;
  "organizationId": any;
  "createdAt": Date;
  "updatedAt": Date;
  "custom": any;
  businessPremise: BusinessPremise;
  organization: Organization;
  constructor(data?: ElectronicDeviceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ElectronicDevice`.
   */
  public static getModelName() {
    return "ElectronicDevice";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ElectronicDevice for dynamic purposes.
  **/
  public static factory(data: ElectronicDeviceInterface): ElectronicDevice{
    return new ElectronicDevice(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ElectronicDevice',
      plural: 'ElectronicDevices',
      path: 'ElectronicDevices',
      idName: 'id',
      properties: {
        "electronicDeviceId": {
          name: 'electronicDeviceId',
          type: 'string'
        },
        "numbers": {
          name: 'numbers',
          type: 'any'
        },
        "provider": {
          name: 'provider',
          type: 'string',
          default: 'furs'
        },
        "environment": {
          name: 'environment',
          type: 'string',
          default: 'production'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "businessPremiseId": {
          name: 'businessPremiseId',
          type: 'any'
        },
        "organizationId": {
          name: 'organizationId',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        "custom": {
          name: 'custom',
          type: 'any',
          default: <any>null
        },
      },
      relations: {
        businessPremise: {
          name: 'businessPremise',
          type: 'BusinessPremise',
          model: 'BusinessPremise',
          relationType: 'belongsTo',
                  keyFrom: 'businessPremiseId',
          keyTo: 'id'
        },
        organization: {
          name: 'organization',
          type: 'Organization',
          model: 'Organization',
          relationType: 'belongsTo',
                  keyFrom: 'organizationId',
          keyTo: 'id'
        },
      }
    }
  }
}
