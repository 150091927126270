/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Merchant } from '../models/merchant';
import { MerchantPartialExcludingIdPaymentProcessingIdMerchantIdApplicationIdOrganizationIdChargesEnabledCreatedAtUpdatedAt } from '../models/merchant-partial-excluding-id-payment-processing-id-merchant-id-application-id-organization-id-charges-enabled-created-at-updated-at';
import { NewMerchantInOrganization } from '../models/new-merchant-in-organization';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class OrganizationMerchantControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation organizationMerchantControllerGet
   */
  static readonly OrganizationMerchantControllerGetPath = '/v1/organizations/{id}/merchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Response(params: {
    id: string;
    filter?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Merchant>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationMerchantControllerService.OrganizationMerchantControllerGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Merchant>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `get$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get(params: {
    id: string;
    filter?: any;
  },
  context?: HttpContext

): Observable<Merchant> {

    return this.get$Response(params,context).pipe(
      map((r: StrictHttpResponse<Merchant>) => r.body as Merchant)
    );
  }

  /**
   * Path part for operation organizationMerchantControllerCreate
   */
  static readonly OrganizationMerchantControllerCreatePath = '/v1/organizations/{id}/merchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: {
    id: string;
    body?: NewMerchantInOrganization
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Merchant>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationMerchantControllerService.OrganizationMerchantControllerCreatePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Merchant>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: {
    id: string;
    body?: NewMerchantInOrganization
  },
  context?: HttpContext

): Observable<Merchant> {

    return this.create$Response(params,context).pipe(
      map((r: StrictHttpResponse<Merchant>) => r.body as Merchant)
    );
  }

  /**
   * Path part for operation organizationMerchantControllerPatch
   */
  static readonly OrganizationMerchantControllerPatchPath = '/v1/organizations/{id}/merchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patch$Response(params: {
    id: string;
    where?: any;
    body?: MerchantPartialExcludingIdPaymentProcessingIdMerchantIdApplicationIdOrganizationIdChargesEnabledCreatedAtUpdatedAt
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationMerchantControllerService.OrganizationMerchantControllerPatchPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patch(params: {
    id: string;
    where?: any;
    body?: MerchantPartialExcludingIdPaymentProcessingIdMerchantIdApplicationIdOrganizationIdChargesEnabledCreatedAtUpdatedAt
  },
  context?: HttpContext

): Observable<LoopbackCount> {

    return this.patch$Response(params,context).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

}
