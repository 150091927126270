/* tslint:disable */
import {
  Account,
  Subscription,
  Document
} from '../index';

declare var Object: any;
export interface UsageInterface {
  "month": number;
  "year": number;
  "documentsPerOrganization"?: any;
  "documentsCount": number;
  "documentsTotal"?: number;
  "organizations"?: any;
  "organizationsBillableCount"?: number;
  "billingCycle"?: string;
  "id"?: any;
  "accountId"?: any;
  "subscriptionId"?: any;
  "invoiceId"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  account?: Account;
  subscription?: Subscription;
  invoice?: Document;
}

export class Usage implements UsageInterface {
  "month": number;
  "year": number;
  "documentsPerOrganization": any;
  "documentsCount": number;
  "documentsTotal": number;
  "organizations": any;
  "organizationsBillableCount": number;
  "billingCycle": string;
  "id": any;
  "accountId": any;
  "subscriptionId": any;
  "invoiceId": any;
  "createdAt": Date;
  "updatedAt": Date;
  account: Account;
  subscription: Subscription;
  invoice: Document;
  constructor(data?: UsageInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Usage`.
   */
  public static getModelName() {
    return "Usage";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Usage for dynamic purposes.
  **/
  public static factory(data: UsageInterface): Usage{
    return new Usage(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Usage',
      plural: 'Usages',
      path: 'Usages',
      idName: 'id',
      properties: {
        "month": {
          name: 'month',
          type: 'number'
        },
        "year": {
          name: 'year',
          type: 'number'
        },
        "documentsPerOrganization": {
          name: 'documentsPerOrganization',
          type: 'any'
        },
        "documentsCount": {
          name: 'documentsCount',
          type: 'number'
        },
        "documentsTotal": {
          name: 'documentsTotal',
          type: 'number'
        },
        "organizations": {
          name: 'organizations',
          type: 'any'
        },
        "organizationsBillableCount": {
          name: 'organizationsBillableCount',
          type: 'number',
          default: 0
        },
        "billingCycle": {
          name: 'billingCycle',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "accountId": {
          name: 'accountId',
          type: 'any'
        },
        "subscriptionId": {
          name: 'subscriptionId',
          type: 'any'
        },
        "invoiceId": {
          name: 'invoiceId',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'accountId',
          keyTo: 'id'
        },
        subscription: {
          name: 'subscription',
          type: 'Subscription',
          model: 'Subscription',
          relationType: 'belongsTo',
                  keyFrom: 'subscriptionId',
          keyTo: 'id'
        },
        invoice: {
          name: 'invoice',
          type: 'Document',
          model: 'Document',
          relationType: 'belongsTo',
                  keyFrom: 'invoiceId',
          keyTo: 'id'
        },
      }
    }
  }
}
