/* tslint:disable */
import {
  AccessToken,
  Organization,
  Subscription,
  Usage,
  AccountOrganization,
  Log
} from '../index';

declare var Object: any;
export interface AccountInterface {
  "firstname"?: string;
  "lastname"?: string;
  "taxNumber"?: string;
  "role"?: string;
  "locale": string;
  "label"?: string;
  "softwareSupplierTaxNumber"?: string;
  "activateRedirect"?: string;
  "invitationLink"?: string;
  "invitationNewLink"?: string;
  "documentShareableLink"?: string;
  "resetRedirect"?: string;
  "newsletter"?: boolean;
  "productUpdates"?: boolean;
  "realm"?: string;
  "username"?: string;
  "email": string;
  "emailVerified"?: boolean;
  "id"?: any;
  "masterId"?: any;
  "parentId"?: any;
  "billingOrganizationId"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "custom"?: any;
  "password"?: string;
  accessTokens?: AccessToken[];
  master?: Account;
  parent?: Account;
  billingOrganization?: Organization;
  subscriptions?: Subscription[];
  usages?: Usage[];
  organizations?: Organization[];
  accountOrganizations?: AccountOrganization[];
  logs?: Log[];
}

export class Account implements AccountInterface {
  "firstname": string;
  "lastname": string;
  "taxNumber": string;
  "role": string;
  "locale": string;
  "label": string;
  "softwareSupplierTaxNumber": string;
  "activateRedirect": string;
  "invitationLink": string;
  "invitationNewLink": string;
  "documentShareableLink": string;
  "resetRedirect": string;
  "newsletter": boolean;
  "productUpdates": boolean;
  "realm": string;
  "username": string;
  "email": string;
  "emailVerified": boolean;
  "id": any;
  "masterId": any;
  "parentId": any;
  "billingOrganizationId": any;
  "createdAt": Date;
  "updatedAt": Date;
  "custom": any;
  "password": string;
  accessTokens: AccessToken[];
  master: Account;
  parent: Account;
  billingOrganization: Organization;
  subscriptions: Subscription[];
  usages: Usage[];
  organizations: Organization[];
  accountOrganizations: AccountOrganization[];
  logs: Log[];
  constructor(data?: AccountInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Account`.
   */
  public static getModelName() {
    return "Account";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Account for dynamic purposes.
  **/
  public static factory(data: AccountInterface): Account{
    return new Account(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Account',
      plural: 'Accounts',
      path: 'Accounts',
      idName: 'id',
      properties: {
        "firstname": {
          name: 'firstname',
          type: 'string'
        },
        "lastname": {
          name: 'lastname',
          type: 'string'
        },
        "taxNumber": {
          name: 'taxNumber',
          type: 'string'
        },
        "role": {
          name: 'role',
          type: 'string'
        },
        "locale": {
          name: 'locale',
          type: 'string',
          default: 'en'
        },
        "label": {
          name: 'label',
          type: 'string'
        },
        "softwareSupplierTaxNumber": {
          name: 'softwareSupplierTaxNumber',
          type: 'string'
        },
        "activateRedirect": {
          name: 'activateRedirect',
          type: 'string'
        },
        "invitationLink": {
          name: 'invitationLink',
          type: 'string'
        },
        "invitationNewLink": {
          name: 'invitationNewLink',
          type: 'string'
        },
        "documentShareableLink": {
          name: 'documentShareableLink',
          type: 'string'
        },
        "resetRedirect": {
          name: 'resetRedirect',
          type: 'string'
        },
        "newsletter": {
          name: 'newsletter',
          type: 'boolean'
        },
        "productUpdates": {
          name: 'productUpdates',
          type: 'boolean'
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "masterId": {
          name: 'masterId',
          type: 'any'
        },
        "parentId": {
          name: 'parentId',
          type: 'any'
        },
        "billingOrganizationId": {
          name: 'billingOrganizationId',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        "custom": {
          name: 'custom',
          type: 'any',
          default: <any>null
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'AccessToken[]',
          model: 'AccessToken',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        master: {
          name: 'master',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'masterId',
          keyTo: 'id'
        },
        parent: {
          name: 'parent',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'parentId',
          keyTo: 'id'
        },
        billingOrganization: {
          name: 'billingOrganization',
          type: 'Organization',
          model: 'Organization',
          relationType: 'belongsTo',
                  keyFrom: 'billingOrganizationId',
          keyTo: 'id'
        },
        subscriptions: {
          name: 'subscriptions',
          type: 'Subscription[]',
          model: 'Subscription',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
        usages: {
          name: 'usages',
          type: 'Usage[]',
          model: 'Usage',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
        organizations: {
          name: 'organizations',
          type: 'Organization[]',
          model: 'Organization',
          relationType: 'hasMany',
          modelThrough: 'AccountOrganization',
          keyThrough: 'organizationId',
          keyFrom: 'id',
          keyTo: 'accountId'
        },
        accountOrganizations: {
          name: 'accountOrganizations',
          type: 'AccountOrganization[]',
          model: 'AccountOrganization',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
        logs: {
          name: 'logs',
          type: 'Log[]',
          model: 'Log',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
      }
    }
  }
}
