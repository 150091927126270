import { Injectable } from '@angular/core';
import { LoopBackConfig } from '../sdk/lb.config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AccountApi } from '../sdk';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private accountApi: AccountApi, private http: HttpClient) {}

  upload(file: File, endpoint: string): Observable<any> {
    const url: string =
      LoopBackConfig.getPath() +
      '/' +
      LoopBackConfig.getApiVersion() +
      endpoint;

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    let headers = new HttpHeaders();
    // headers = headers.append('Content-Type', 'multipart/form-data');
    headers = headers.append('Accept', 'application/json');
    headers = headers.set(
      'Authorization',
      this.accountApi.getCurrentToken().id,
    );

    return this.http.post(url, formData, { headers });
  }
}
