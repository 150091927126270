/* tslint:disable */
import {
  Organization,
  Unit,
  Warehouse,
  Order,
  Account
} from '../index';

declare var Object: any;
export interface WoocommerceInterface {
  "issueInvoiceOnComplete"?: boolean;
  "name": string;
  "active": boolean;
  "locale"?: string;
  "sendInvoiceAutomatically": boolean;
  "sendEstimateAutomatically": boolean;
  "issueInvoiceForBankPayment"?: boolean;
  "businessPremiseId"?: string;
  "electronicDeviceId"?: string;
  "id"?: any;
  "organizationId"?: any;
  "unitId"?: any;
  "warehouseId"?: any;
  "accountId"?: any;
  "custom"?: any;
  "deletedAt"?: Date;
  "_isDeleted"?: boolean;
  "deletedId"?: string;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  organization?: Organization;
  unit?: Unit;
  warehouse?: Warehouse;
  orders?: Order[];
  account?: Account;
}

export class Woocommerce implements WoocommerceInterface {
  "issueInvoiceOnComplete": boolean;
  "name": string;
  "active": boolean;
  "locale": string;
  "sendInvoiceAutomatically": boolean;
  "sendEstimateAutomatically": boolean;
  "issueInvoiceForBankPayment": boolean;
  "businessPremiseId": string;
  "electronicDeviceId": string;
  "id": any;
  "organizationId": any;
  "unitId": any;
  "warehouseId": any;
  "accountId": any;
  "custom": any;
  "deletedAt": Date;
  "_isDeleted": boolean;
  "deletedId": string;
  "createdAt": Date;
  "updatedAt": Date;
  organization: Organization;
  unit: Unit;
  warehouse: Warehouse;
  orders: Order[];
  account: Account;
  constructor(data?: WoocommerceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Woocommerce`.
   */
  public static getModelName() {
    return "Woocommerce";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Woocommerce for dynamic purposes.
  **/
  public static factory(data: WoocommerceInterface): Woocommerce{
    return new Woocommerce(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Woocommerce',
      plural: 'Woocommerce',
      path: 'Woocommerce',
      idName: 'id',
      properties: {
        "issueInvoiceOnComplete": {
          name: 'issueInvoiceOnComplete',
          type: 'boolean'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "locale": {
          name: 'locale',
          type: 'string'
        },
        "sendInvoiceAutomatically": {
          name: 'sendInvoiceAutomatically',
          type: 'boolean',
          default: true
        },
        "sendEstimateAutomatically": {
          name: 'sendEstimateAutomatically',
          type: 'boolean',
          default: true
        },
        "issueInvoiceForBankPayment": {
          name: 'issueInvoiceForBankPayment',
          type: 'boolean'
        },
        "businessPremiseId": {
          name: 'businessPremiseId',
          type: 'string'
        },
        "electronicDeviceId": {
          name: 'electronicDeviceId',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "organizationId": {
          name: 'organizationId',
          type: 'any'
        },
        "unitId": {
          name: 'unitId',
          type: 'any'
        },
        "warehouseId": {
          name: 'warehouseId',
          type: 'any'
        },
        "accountId": {
          name: 'accountId',
          type: 'any'
        },
        "custom": {
          name: 'custom',
          type: 'any',
          default: <any>null
        },
        "deletedAt": {
          name: 'deletedAt',
          type: 'Date'
        },
        "_isDeleted": {
          name: '_isDeleted',
          type: 'boolean'
        },
        "deletedId": {
          name: 'deletedId',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
      },
      relations: {
        organization: {
          name: 'organization',
          type: 'Organization',
          model: 'Organization',
          relationType: 'belongsTo',
                  keyFrom: 'organizationId',
          keyTo: 'id'
        },
        unit: {
          name: 'unit',
          type: 'Unit',
          model: 'Unit',
          relationType: 'belongsTo',
                  keyFrom: 'unitId',
          keyTo: 'id'
        },
        warehouse: {
          name: 'warehouse',
          type: 'Warehouse',
          model: 'Warehouse',
          relationType: 'belongsTo',
                  keyFrom: 'warehouseId',
          keyTo: 'id'
        },
        orders: {
          name: 'orders',
          type: 'Order[]',
          model: 'Order',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'woocommerceId'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'accountId',
          keyTo: 'id'
        },
      }
    }
  }
}
