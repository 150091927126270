import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CsvService {

  constructor() { }

  /**
   * Create CSV string from array
   * First row is header
   * Content arrays must match header length
   */
  create(rows: any[]): string {
    const csvContent = 'data:text/csv;charset=utf-8,'
      + rows
        .map(r => r.join(','))
        .join('\n');

    return csvContent;
  }

  /**
   * Download CSV string as file with name
   */
  download(content: string, name: string): void {
    const encodedUri = encodeURI(content);
    const link = document.createElement('a');

    link.setAttribute('href', encodedUri);
    link.setAttribute('download', name + '.csv');
    link.setAttribute('target', '_blank');

    document.body.appendChild(link); // Required for FF

    link.click();
  }

  /**
   * Create and download CSV file with specified name from provided array
   */
  get(rows: any[], name: string): void {
    this.download(this.create(rows), name);
  }
}
