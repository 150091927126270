import { OrganizationService } from '../organizations/organization.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { DocumentService } from '../documents/document.service';
import * as moment from 'moment';
import { Subscription, of, Observable, EMPTY } from 'rxjs';
import { map, switchMap, filter, take } from 'rxjs/operators';
import { GlobalSubscriptionService } from '../subscriptions/global-subscription.service';

@Injectable()
export class InvoiceLimitResolver implements Resolve<number> {
  activeOrgSub: Subscription;
  subscriptionSub: Subscription;
  countSub: Subscription;

  constructor(
    public documentService: DocumentService,
    public globalSubscriptionService: GlobalSubscriptionService,
    public orgService: OrganizationService,
    private router: Router,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<number> | Observable<never> {
    return new Observable((observer) => {
      let limit = 29999999;

      this.orgService.active
        .pipe(
          filter((organization) => !!organization),
          switchMap((organization) =>
            this.globalSubscriptionService.active.pipe(
              map((subscription) => ({ organization, subscription })),
            ),
          ),
          switchMap(({ organization, subscription }) => {
            let count: Observable<number>;

            if (subscription.type === 'SI') {
              if (subscription.subscription?._subscriptionPlan?.tier > 1) {
                count = of(0);
              } else {
                // Free plan
                count = this.documentService.count(
                  organization.id,
                  {
                    createdAt: {
                      gte: moment.utc().startOf('month').format(),
                    },
                  },
                  true,
                );
              }
            } else {
              // Apollo
              if (route.params.type !== 'invoice') {
                observer.next(limit);
                observer.complete();
                return;
              }

              count = this.documentService.count(
                organization.id,
                {
                  type: 'invoice',
                  createdAt: {
                    gte: moment.utc().startOf('month').format(),
                  },
                },
                true,
              );
            }

            return count.pipe(
              map((count) => ({ organization, subscription, count })),
            );
          }),
          take(1),
        )
        .subscribe(
          ({ organization, subscription, count }) => {
            if (subscription.subscription) {
              const documentLimit =
                subscription.features?.documentLimit || limit;

              if (count < documentLimit) {
                observer.next(limit);
                observer.complete();
                return;
              }

              if (subscription.type === 'SI') {
                // TODO: Show document limit reached banner
                this.router.navigate([
                  organization.id,
                  'dashboard',
                  { limit: true },
                ]);
              } else {
                this.router.navigate([
                  organization.id,
                  'subscriptions',
                  'plans',
                  { limit: true },
                ]);
              }

              observer.complete();
              return EMPTY;
            } else {
              // No subscription
              this.router.navigate([
                organization.id,
                'subscriptions',
                'plans',
                { expired: true },
              ]);

              observer.complete();
              return EMPTY;
            }
          },
          () => {
            observer.next(limit);
            observer.complete();
            return;
          },
        );
    });
  }
}
