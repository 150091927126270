/* tslint:disable */
import {
  DocumentItem,
  Tax,
  Organization,
  Unit,
  Price,
  PriceList,
  Warehouse,
  Component
} from '../index';

declare var Object: any;
export interface ItemInterface {
  "type"?: string;
  "purchasePrice"?: number;
  "purchasePriceGross"?: number;
  "name"?: string;
  "description"?: string;
  "price"?: number;
  "priceGross"?: number;
  "unit"?: string;
  "SKU"?: string;
  "EAN"?: string;
  "trackInventory"?: boolean;
  "onBehalf"?: string;
  "id"?: any;
  "custom"?: any;
  "l10n"?: any;
  "deletedAt"?: Date;
  "_isDeleted"?: boolean;
  "deletedId"?: string;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "taxIds"?: Array<any>;
  "purchaseTaxIds"?: Array<any>;
  "organizationId"?: any;
  "unitId"?: any;
  "unitIds"?: Array<any>;
  "_components"?: Array<any>;
  documentItems?: DocumentItem[];
  taxes?: Tax[];
  organization?: Organization;
  units?: Unit[];
  prices?: Price[];
  priceLists?: PriceList[];
  inventories?: any[];
  warehouses?: Warehouse[];
  components?: Component[];
}

export class Item implements ItemInterface {
  "type": string;
  "purchasePrice": number;
  "purchasePriceGross": number;
  "name": string;
  "description": string;
  "price": number;
  "priceGross": number;
  "unit": string;
  "SKU": string;
  "EAN": string;
  "trackInventory": boolean;
  "onBehalf": string;
  "id": any;
  "custom": any;
  "l10n": any;
  "deletedAt": Date;
  "_isDeleted": boolean;
  "deletedId": string;
  "createdAt": Date;
  "updatedAt": Date;
  "taxIds": Array<any>;
  "purchaseTaxIds": Array<any>;
  "organizationId": any;
  "unitId": any;
  "unitIds": Array<any>;
  "_components": Array<any>;
  documentItems: DocumentItem[];
  taxes: Tax[];
  organization: Organization;
  units: Unit[];
  prices: Price[];
  priceLists: PriceList[];
  inventories: any[];
  warehouses: Warehouse[];
  components: Component[];
  constructor(data?: ItemInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Item`.
   */
  public static getModelName() {
    return "Item";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Item for dynamic purposes.
  **/
  public static factory(data: ItemInterface): Item{
    return new Item(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Item',
      plural: 'Items',
      path: 'Items',
      idName: 'id',
      properties: {
        "type": {
          name: 'type',
          type: 'string'
        },
        "purchasePrice": {
          name: 'purchasePrice',
          type: 'number'
        },
        "purchasePriceGross": {
          name: 'purchasePriceGross',
          type: 'number'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string',
          default: ''
        },
        "price": {
          name: 'price',
          type: 'number'
        },
        "priceGross": {
          name: 'priceGross',
          type: 'number'
        },
        "unit": {
          name: 'unit',
          type: 'string'
        },
        "SKU": {
          name: 'SKU',
          type: 'string'
        },
        "EAN": {
          name: 'EAN',
          type: 'string'
        },
        "trackInventory": {
          name: 'trackInventory',
          type: 'boolean'
        },
        "onBehalf": {
          name: 'onBehalf',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "custom": {
          name: 'custom',
          type: 'any',
          default: <any>null
        },
        "l10n": {
          name: 'l10n',
          type: 'any'
        },
        "deletedAt": {
          name: 'deletedAt',
          type: 'Date'
        },
        "_isDeleted": {
          name: '_isDeleted',
          type: 'boolean'
        },
        "deletedId": {
          name: 'deletedId',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        "taxIds": {
          name: 'taxIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "purchaseTaxIds": {
          name: 'purchaseTaxIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "organizationId": {
          name: 'organizationId',
          type: 'any'
        },
        "unitId": {
          name: 'unitId',
          type: 'any'
        },
        "unitIds": {
          name: 'unitIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "_components": {
          name: '_components',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
      },
      relations: {
        documentItems: {
          name: 'documentItems',
          type: 'DocumentItem[]',
          model: 'DocumentItem',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'itemId'
        },
        taxes: {
          name: 'taxes',
          type: 'Tax[]',
          model: 'Tax',
          relationType: 'referencesMany',
                  keyFrom: 'taxIds',
          keyTo: 'id'
        },
        purchaseTaxes: {
          name: 'purchaseTaxes',
          type: 'Tax[]',
          model: 'Tax',
          relationType: 'referencesMany',
                  keyFrom: 'purchaseTaxIds',
          keyTo: 'id'
        },
        organization: {
          name: 'organization',
          type: 'Organization',
          model: 'Organization',
          relationType: 'belongsTo',
                  keyFrom: 'organizationId',
          keyTo: 'id'
        },
        units: {
          name: 'units',
          type: 'Unit[]',
          model: 'Unit',
          relationType: 'referencesMany',
                  keyFrom: 'unitIds',
          keyTo: 'id'
        },
        prices: {
          name: 'prices',
          type: 'Price[]',
          model: 'Price',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'itemId'
        },
        priceLists: {
          name: 'priceLists',
          type: 'PriceList[]',
          model: 'PriceList',
          relationType: 'hasMany',
          modelThrough: 'Price',
          keyThrough: 'priceListId',
          keyFrom: 'id',
          keyTo: 'itemId'
        },
        inventories: {
          name: 'inventories',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'itemId'
        },
        warehouses: {
          name: 'warehouses',
          type: 'Warehouse[]',
          model: 'Warehouse',
          relationType: 'hasMany',
          modelThrough: 'Inventory',
          keyThrough: 'warehouseId',
          keyFrom: 'id',
          keyTo: 'itemId'
        },
        components: {
          name: 'components',
          type: 'Component[]',
          model: 'Component',
          relationType: 'embedsMany',
                  keyFrom: '_components',
          keyTo: 'id'
        },
      }
    }
  }
}
