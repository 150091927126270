import { Injectable } from '@angular/core';
import { LoopBackConfig, LoggerService } from '../shared/sdk';
import { CategoryApi, OrganizationApi } from '../shared/sdk/services';
import { Category } from '../shared/sdk/models';
import { ToastService } from '../shared/modules/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { BASE_URL, API_VERSION } from '../shared/base-url';
import { ApiService } from '../shared/services/api.service';
import { CacheService } from '../shared/services/cache.service';
import { Observable } from 'rxjs';
import { CsvService } from '../shared/csv.service';
import { take } from 'rxjs/operators';

export type profitAndLossRow = profitAndLossCol[];

export interface profitAndLossCol {
  key: string,
  value: string,
  outgoing?: boolean,
  drilldown?: boolean,
  documentId?: string,
}

@Injectable()
export class CategoryService extends ApiService<Category> {
  constructor(
    protected cacheService: CacheService,
    protected log: LoggerService,
    protected toastService: ToastService,
    protected translate: TranslateService,
    private categoryApi: CategoryApi,
    private orgApi: OrganizationApi,
    private csvService: CsvService,
  ) {
    super(cacheService, log, toastService, translate);
    this.name = 'Category';
    this.plural = 'Categories';

    LoopBackConfig.setBaseURL(BASE_URL);
    LoopBackConfig.setApiVersion(API_VERSION);
  }

  count(
    orgId: string,
    filter: any = {},
    force = false,
    toastr = true,
  ): Observable<number> {
    return super.count(orgId, filter, force, toastr, this.orgApi);
  }

  create(orgId: string, data: any, toastr = true) {
    return super.create(orgId, data, toastr, this.orgApi);
  }

  delete(id: string, toastr = true): Observable<boolean> {
    return super.delete(id, toastr, this.categoryApi);
  }

  get(
    orgId: string,
    filter: any = {},
    force = false,
    toastr = true,
  ): Observable<{ body: Category[]; totalCount: number }> {
    return super.get(orgId, filter, force, toastr, this.orgApi);
  }

  getById(
    id: string,
    filter = {},
    force = true,
    toastr = true,
  ): Observable<Category> {
    return super.getById(id, filter, force, toastr, this.categoryApi);
  }

  update(id: string, data: any, toastr = true): Observable<Category> {
    return super.update(id, data, toastr, this.categoryApi);
  }

  downloadPnLCSV(report: Observable<any[]>, name: string): void {
    report
      .pipe(take(1))
      .subscribe((data: profitAndLossRow[]) => {
        const csvContent = 'data:text/csv;charset=utf-8,'
          + data.map(r => r.map((c: profitAndLossCol) => {
            if (!this.isNumber(c.value)) {
              let v = this.getValue(c);

              if (v && v.indexOf('.') !== -1 && !c.drilldown) {
                v = this.translate.instant(v);
              }

              if (c.drilldown) {
                v = ` - ${v}`;
              }

              return `"${v}"`;
            } else {
              return c.value;
            }
          }))
            .join('\n');
        
        this.csvService.download(csvContent, name);
      });
  }

  getValue(col: profitAndLossCol): string {
    if (col.key.indexOf('header_') !== -1 || col.key.indexOf('_total') !== -1 || col.key === 'profit') {
      return `profit-and-loss.header.${col.key}`;
    } else {
      return col.value;
    }
  }

  isNumber(value: number | string): boolean {
    return typeof value === 'number';
  }
}
