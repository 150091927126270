/* tslint:disable */

declare var Object: any;
export interface GeoIPInterface {
  "id"?: number;
}

export class GeoIP implements GeoIPInterface {
  "id": number;
  constructor(data?: GeoIPInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `GeoIP`.
   */
  public static getModelName() {
    return "GeoIP";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of GeoIP for dynamic purposes.
  **/
  public static factory(data: GeoIPInterface): GeoIP{
    return new GeoIP(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'GeoIP',
      plural: 'geoip',
      path: 'geoip',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
