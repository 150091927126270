import { Injectable } from '@angular/core';
import { LoopBackConfig, LoggerService } from '../shared/sdk';
import {
  WarehouseApi,
  OrganizationApi,
  AccountApi,
} from '../shared/sdk/services';
import { Warehouse } from '../shared/sdk/models';
import { ToastService } from '../shared/modules/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';

import { ApiService } from '../shared/services/api.service';
import { BASE_URL, API_VERSION } from '../shared/base-url';
import { CacheService } from '../shared/services/cache.service';
import { Observable } from 'rxjs';

@Injectable()
export class WarehouseService extends ApiService<Warehouse> {
  constructor(
    public accountApi: AccountApi,
    protected cacheService: CacheService,
    protected log: LoggerService,
    protected toastService: ToastService,
    protected translate: TranslateService,
    private warehouseApi: WarehouseApi,
    private orgApi: OrganizationApi,
  ) {
    super(cacheService, log, toastService, translate);

    this.name = 'Warehouse';
    this.plural = 'Warehouses';

    LoopBackConfig.setBaseURL(BASE_URL);
    LoopBackConfig.setApiVersion(API_VERSION);
  }

  count(
    orgId: string,
    filter: any = {},
    force = false,
    toastr = true,
  ): Observable<number> {
    return super.count(orgId, filter, force, toastr, this.orgApi);
  }

  create(orgId: string, data: any, toastr = true): Observable<Warehouse> {
    return super.create(orgId, data, toastr, this.orgApi);
  }

  delete(id: string, toastr = true): Observable<boolean> {
    return super.delete(id, toastr, this.warehouseApi);
  }

  get(
    orgId: string,
    filter: any = {},
    force = false,
    toastr = true,
  ): Observable<{ body: Warehouse[]; totalCount: number }> {
    return super.get(orgId, filter, force, toastr, this.orgApi);
  }

  getById(
    id: string,
    filter = {},
    force = true,
    toastr = true,
  ): Observable<Warehouse> {
    return super.getById(id, filter, force, toastr, this.warehouseApi);
  }

  getDefault(warehouses: Warehouse[]): Warehouse {
    if (!warehouses) {
      return;
    }
    return warehouses.find((wh) => !!wh.default);
  }

  patch(
    id: string,
    data: Partial<Warehouse>,
    toastr = true,
  ): Observable<Partial<Warehouse>> {
    return super.patch(id, data, toastr, this.warehouseApi);
  }
}
