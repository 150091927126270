/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Order } from '../models/order';

@Injectable({
  providedIn: 'root',
})
export class OrderService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation orderFindById
   */
  static readonly OrderFindByIdPath = '/v1/Orders/{id}';

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderFindById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderFindById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Order>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.OrderFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Order>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orderFindById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderFindById$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Order> {

    return this.orderFindById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Order>) => r.body as Order)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderFindById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderFindById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Order>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.OrderFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Order>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orderFindById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderFindById$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Order> {

    return this.orderFindById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Order>) => r.body as Order)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderFindById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderFindById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Order>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.OrderFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Order>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orderFindById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderFindById$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Order> {

    return this.orderFindById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Order>) => r.body as Order)
    );
  }

  /**
   * Path part for operation orderPrototypeRemoteCreateDocument
   */
  static readonly OrderPrototypeRemoteCreateDocumentPath = '/v1/Orders/{id}/documents/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderPrototypeRemoteCreateDocument$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderPrototypeRemoteCreateDocument$Json$Response(params: {

    /**
     * Order id
     */
    id: string;

    /**
     * Type of document to create
     */
    type: string;

    /**
     * Force set if document should be sent, if not provided integration setting is used.
     */
    send?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.OrderPrototypeRemoteCreateDocumentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('send', params.send, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orderPrototypeRemoteCreateDocument$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderPrototypeRemoteCreateDocument$Json(params: {

    /**
     * Order id
     */
    id: string;

    /**
     * Type of document to create
     */
    type: string;

    /**
     * Force set if document should be sent, if not provided integration setting is used.
     */
    send?: boolean;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.orderPrototypeRemoteCreateDocument$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderPrototypeRemoteCreateDocument$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderPrototypeRemoteCreateDocument$Xml$Response(params: {

    /**
     * Order id
     */
    id: string;

    /**
     * Type of document to create
     */
    type: string;

    /**
     * Force set if document should be sent, if not provided integration setting is used.
     */
    send?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.OrderPrototypeRemoteCreateDocumentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('send', params.send, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orderPrototypeRemoteCreateDocument$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderPrototypeRemoteCreateDocument$Xml(params: {

    /**
     * Order id
     */
    id: string;

    /**
     * Type of document to create
     */
    type: string;

    /**
     * Force set if document should be sent, if not provided integration setting is used.
     */
    send?: boolean;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.orderPrototypeRemoteCreateDocument$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderPrototypeRemoteCreateDocument$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderPrototypeRemoteCreateDocument$Javascript$Response(params: {

    /**
     * Order id
     */
    id: string;

    /**
     * Type of document to create
     */
    type: string;

    /**
     * Force set if document should be sent, if not provided integration setting is used.
     */
    send?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrderService.OrderPrototypeRemoteCreateDocumentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('send', params.send, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orderPrototypeRemoteCreateDocument$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderPrototypeRemoteCreateDocument$Javascript(params: {

    /**
     * Order id
     */
    id: string;

    /**
     * Type of document to create
     */
    type: string;

    /**
     * Force set if document should be sent, if not provided integration setting is used.
     */
    send?: boolean;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.orderPrototypeRemoteCreateDocument$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

}
