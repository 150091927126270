/* tslint:disable */
import {
  Organization,
  Document,
  Typless
} from '../index';

declare var Object: any;
export interface FileMetaInterface {
  "name"?: string;
  "type"?: string;
  "role": string;
  "url"?: string;
  "container"?: string;
  "nameOriginal"?: string;
  "id"?: any;
  "organizationId"?: any;
  "documentId"?: any;
  "deletedAt"?: Date;
  "_isDeleted"?: boolean;
  "deletedId"?: string;
  organization?: Organization;
  document?: Document;
  typless?: Typless;
}

export class FileMeta implements FileMetaInterface {
  "name": string;
  "type": string;
  "role": string;
  "url": string;
  "container": string;
  "nameOriginal": string;
  "id": any;
  "organizationId": any;
  "documentId": any;
  "deletedAt": Date;
  "_isDeleted": boolean;
  "deletedId": string;
  organization: Organization;
  document: Document;
  typless: Typless;
  constructor(data?: FileMetaInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FileMeta`.
   */
  public static getModelName() {
    return "FileMeta";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FileMeta for dynamic purposes.
  **/
  public static factory(data: FileMetaInterface): FileMeta{
    return new FileMeta(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FileMeta',
      plural: 'FileMeta',
      path: 'FileMeta',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "role": {
          name: 'role',
          type: 'string'
        },
        "url": {
          name: 'url',
          type: 'string'
        },
        "container": {
          name: 'container',
          type: 'string'
        },
        "nameOriginal": {
          name: 'nameOriginal',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "organizationId": {
          name: 'organizationId',
          type: 'any'
        },
        "documentId": {
          name: 'documentId',
          type: 'any'
        },
        "deletedAt": {
          name: 'deletedAt',
          type: 'Date'
        },
        "_isDeleted": {
          name: '_isDeleted',
          type: 'boolean'
        },
        "deletedId": {
          name: 'deletedId',
          type: 'string'
        },
      },
      relations: {
        organization: {
          name: 'organization',
          type: 'Organization',
          model: 'Organization',
          relationType: 'belongsTo',
                  keyFrom: 'organizationId',
          keyTo: 'id'
        },
        document: {
          name: 'document',
          type: 'Document',
          model: 'Document',
          relationType: 'belongsTo',
                  keyFrom: 'documentId',
          keyTo: 'id'
        },
        typless: {
          name: 'typless',
          type: 'Typless',
          model: 'Typless',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'fileMetaId'
        },
      }
    }
  }
}
