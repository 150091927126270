/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Item } from '../models/item';
import { Price } from '../models/price';
import { PriceList } from '../models/price-list';
import { NewPrice } from '../models/new-price';

@Injectable({
  providedIn: 'root',
})
export class PriceListService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation priceListPrototypeGetItems
   */
  static readonly PriceListPrototypeGetItemsPath = '/v1/PriceLists/{id}/items';

  /**
   * Queries items of PriceList.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypeGetItems$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeGetItems$Json$Response(params: {

    /**
     * PriceList id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Item>>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypeGetItemsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Item>>;
      })
    );
  }

  /**
   * Queries items of PriceList.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypeGetItems$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeGetItems$Json(params: {

    /**
     * PriceList id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Item>> {

    return this.priceListPrototypeGetItems$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Item>>) => r.body as Array<Item>)
    );
  }

  /**
   * Queries items of PriceList.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypeGetItems$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeGetItems$Xml$Response(params: {

    /**
     * PriceList id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Item>>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypeGetItemsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Item>>;
      })
    );
  }

  /**
   * Queries items of PriceList.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypeGetItems$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeGetItems$Xml(params: {

    /**
     * PriceList id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Item>> {

    return this.priceListPrototypeGetItems$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Item>>) => r.body as Array<Item>)
    );
  }

  /**
   * Queries items of PriceList.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypeGetItems$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeGetItems$Javascript$Response(params: {

    /**
     * PriceList id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Item>>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypeGetItemsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Item>>;
      })
    );
  }

  /**
   * Queries items of PriceList.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypeGetItems$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeGetItems$Javascript(params: {

    /**
     * PriceList id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Item>> {

    return this.priceListPrototypeGetItems$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Item>>) => r.body as Array<Item>)
    );
  }

  /**
   * Path part for operation priceListPrototypeCountItems
   */
  static readonly PriceListPrototypeCountItemsPath = '/v1/PriceLists/{id}/items/count';

  /**
   * Counts items of PriceList.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypeCountItems$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeCountItems$Json$Response(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypeCountItemsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts items of PriceList.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypeCountItems$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeCountItems$Json(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.priceListPrototypeCountItems$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts items of PriceList.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypeCountItems$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeCountItems$Xml$Response(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypeCountItemsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts items of PriceList.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypeCountItems$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeCountItems$Xml(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.priceListPrototypeCountItems$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts items of PriceList.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypeCountItems$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeCountItems$Javascript$Response(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypeCountItemsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts items of PriceList.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypeCountItems$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeCountItems$Javascript(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.priceListPrototypeCountItems$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation priceListPrototypeGetPrices
   */
  static readonly PriceListPrototypeGetPricesPath = '/v1/PriceLists/{id}/prices';

  /**
   * Queries prices of PriceList.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypeGetPrices$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeGetPrices$Json$Response(params: {

    /**
     * PriceList id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Price>>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypeGetPricesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Price>>;
      })
    );
  }

  /**
   * Queries prices of PriceList.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypeGetPrices$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeGetPrices$Json(params: {

    /**
     * PriceList id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Price>> {

    return this.priceListPrototypeGetPrices$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Price>>) => r.body as Array<Price>)
    );
  }

  /**
   * Queries prices of PriceList.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypeGetPrices$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeGetPrices$Xml$Response(params: {

    /**
     * PriceList id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Price>>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypeGetPricesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Price>>;
      })
    );
  }

  /**
   * Queries prices of PriceList.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypeGetPrices$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeGetPrices$Xml(params: {

    /**
     * PriceList id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Price>> {

    return this.priceListPrototypeGetPrices$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Price>>) => r.body as Array<Price>)
    );
  }

  /**
   * Queries prices of PriceList.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypeGetPrices$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeGetPrices$Javascript$Response(params: {

    /**
     * PriceList id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Price>>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypeGetPricesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Price>>;
      })
    );
  }

  /**
   * Queries prices of PriceList.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypeGetPrices$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeGetPrices$Javascript(params: {

    /**
     * PriceList id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Price>> {

    return this.priceListPrototypeGetPrices$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Price>>) => r.body as Array<Price>)
    );
  }

  /**
   * Path part for operation priceListPrototypeCreatePrices
   */
  static readonly PriceListPrototypeCreatePricesPath = '/v1/PriceLists/{id}/prices';

  /**
   * Creates a new instance in prices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypeCreatePrices$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  priceListPrototypeCreatePrices$Json$Json$Response(params: {

    /**
     * PriceList id
     */
    id: string;
    body?: NewPrice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Price>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypeCreatePricesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Price>;
      })
    );
  }

  /**
   * Creates a new instance in prices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypeCreatePrices$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  priceListPrototypeCreatePrices$Json$Json(params: {

    /**
     * PriceList id
     */
    id: string;
    body?: NewPrice
  },
  context?: HttpContext

): Observable<Price> {

    return this.priceListPrototypeCreatePrices$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Price>) => r.body as Price)
    );
  }

  /**
   * Creates a new instance in prices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypeCreatePrices$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  priceListPrototypeCreatePrices$Json$Xml$Response(params: {

    /**
     * PriceList id
     */
    id: string;
    body?: NewPrice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Price>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypeCreatePricesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Price>;
      })
    );
  }

  /**
   * Creates a new instance in prices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypeCreatePrices$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  priceListPrototypeCreatePrices$Json$Xml(params: {

    /**
     * PriceList id
     */
    id: string;
    body?: NewPrice
  },
  context?: HttpContext

): Observable<Price> {

    return this.priceListPrototypeCreatePrices$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Price>) => r.body as Price)
    );
  }

  /**
   * Creates a new instance in prices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypeCreatePrices$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  priceListPrototypeCreatePrices$Json$Javascript$Response(params: {

    /**
     * PriceList id
     */
    id: string;
    body?: NewPrice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Price>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypeCreatePricesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Price>;
      })
    );
  }

  /**
   * Creates a new instance in prices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypeCreatePrices$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  priceListPrototypeCreatePrices$Json$Javascript(params: {

    /**
     * PriceList id
     */
    id: string;
    body?: NewPrice
  },
  context?: HttpContext

): Observable<Price> {

    return this.priceListPrototypeCreatePrices$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Price>) => r.body as Price)
    );
  }

  /**
   * Creates a new instance in prices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypeCreatePrices$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  priceListPrototypeCreatePrices$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * PriceList id
     */
    id: string;
    body?: NewPrice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Price>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypeCreatePricesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Price>;
      })
    );
  }

  /**
   * Creates a new instance in prices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypeCreatePrices$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  priceListPrototypeCreatePrices$XWwwFormUrlencoded$Json(params: {

    /**
     * PriceList id
     */
    id: string;
    body?: NewPrice
  },
  context?: HttpContext

): Observable<Price> {

    return this.priceListPrototypeCreatePrices$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Price>) => r.body as Price)
    );
  }

  /**
   * Creates a new instance in prices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypeCreatePrices$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  priceListPrototypeCreatePrices$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * PriceList id
     */
    id: string;
    body?: NewPrice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Price>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypeCreatePricesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Price>;
      })
    );
  }

  /**
   * Creates a new instance in prices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypeCreatePrices$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  priceListPrototypeCreatePrices$XWwwFormUrlencoded$Xml(params: {

    /**
     * PriceList id
     */
    id: string;
    body?: NewPrice
  },
  context?: HttpContext

): Observable<Price> {

    return this.priceListPrototypeCreatePrices$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Price>) => r.body as Price)
    );
  }

  /**
   * Creates a new instance in prices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypeCreatePrices$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  priceListPrototypeCreatePrices$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * PriceList id
     */
    id: string;
    body?: NewPrice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Price>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypeCreatePricesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Price>;
      })
    );
  }

  /**
   * Creates a new instance in prices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypeCreatePrices$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  priceListPrototypeCreatePrices$XWwwFormUrlencoded$Javascript(params: {

    /**
     * PriceList id
     */
    id: string;
    body?: NewPrice
  },
  context?: HttpContext

): Observable<Price> {

    return this.priceListPrototypeCreatePrices$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Price>) => r.body as Price)
    );
  }

  /**
   * Creates a new instance in prices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypeCreatePrices$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  priceListPrototypeCreatePrices$Xml$Json$Response(params: {

    /**
     * PriceList id
     */
    id: string;
    body?: NewPrice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Price>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypeCreatePricesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Price>;
      })
    );
  }

  /**
   * Creates a new instance in prices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypeCreatePrices$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  priceListPrototypeCreatePrices$Xml$Json(params: {

    /**
     * PriceList id
     */
    id: string;
    body?: NewPrice
  },
  context?: HttpContext

): Observable<Price> {

    return this.priceListPrototypeCreatePrices$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Price>) => r.body as Price)
    );
  }

  /**
   * Creates a new instance in prices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypeCreatePrices$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  priceListPrototypeCreatePrices$Xml$Xml$Response(params: {

    /**
     * PriceList id
     */
    id: string;
    body?: NewPrice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Price>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypeCreatePricesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Price>;
      })
    );
  }

  /**
   * Creates a new instance in prices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypeCreatePrices$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  priceListPrototypeCreatePrices$Xml$Xml(params: {

    /**
     * PriceList id
     */
    id: string;
    body?: NewPrice
  },
  context?: HttpContext

): Observable<Price> {

    return this.priceListPrototypeCreatePrices$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Price>) => r.body as Price)
    );
  }

  /**
   * Creates a new instance in prices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypeCreatePrices$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  priceListPrototypeCreatePrices$Xml$Javascript$Response(params: {

    /**
     * PriceList id
     */
    id: string;
    body?: NewPrice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Price>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypeCreatePricesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Price>;
      })
    );
  }

  /**
   * Creates a new instance in prices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypeCreatePrices$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  priceListPrototypeCreatePrices$Xml$Javascript(params: {

    /**
     * PriceList id
     */
    id: string;
    body?: NewPrice
  },
  context?: HttpContext

): Observable<Price> {

    return this.priceListPrototypeCreatePrices$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Price>) => r.body as Price)
    );
  }

  /**
   * Path part for operation priceListPrototypeCountPrices
   */
  static readonly PriceListPrototypeCountPricesPath = '/v1/PriceLists/{id}/prices/count';

  /**
   * Counts prices of PriceList.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypeCountPrices$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeCountPrices$Json$Response(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypeCountPricesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts prices of PriceList.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypeCountPrices$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeCountPrices$Json(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.priceListPrototypeCountPrices$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts prices of PriceList.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypeCountPrices$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeCountPrices$Xml$Response(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypeCountPricesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts prices of PriceList.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypeCountPrices$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeCountPrices$Xml(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.priceListPrototypeCountPrices$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts prices of PriceList.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypeCountPrices$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeCountPrices$Javascript$Response(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypeCountPricesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts prices of PriceList.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypeCountPrices$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListPrototypeCountPrices$Javascript(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.priceListPrototypeCountPrices$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation priceListFindById
   */
  static readonly PriceListFindByIdPath = '/v1/PriceLists/{id}';

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListFindById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListFindById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PriceList>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PriceList>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListFindById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListFindById$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<PriceList> {

    return this.priceListFindById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<PriceList>) => r.body as PriceList)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListFindById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListFindById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PriceList>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PriceList>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListFindById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListFindById$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<PriceList> {

    return this.priceListFindById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<PriceList>) => r.body as PriceList)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListFindById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListFindById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PriceList>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PriceList>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListFindById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListFindById$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<PriceList> {

    return this.priceListFindById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<PriceList>) => r.body as PriceList)
    );
  }

  /**
   * Path part for operation priceListDeleteById
   */
  static readonly PriceListDeleteByIdPath = '/v1/PriceLists/{id}';

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListDeleteById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListDeleteById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListDeleteById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListDeleteById$Json(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.priceListDeleteById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListDeleteById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListDeleteById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListDeleteById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListDeleteById$Xml(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.priceListDeleteById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListDeleteById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListDeleteById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListDeleteById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceListDeleteById$Javascript(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.priceListDeleteById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation priceListPrototypePatchAttributes
   */
  static readonly PriceListPrototypePatchAttributesPath = '/v1/PriceLists/{id}';

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypePatchAttributes$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  priceListPrototypePatchAttributes$Json$Json$Response(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: PriceList
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PriceList>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PriceList>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypePatchAttributes$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  priceListPrototypePatchAttributes$Json$Json(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: PriceList
  },
  context?: HttpContext

): Observable<PriceList> {

    return this.priceListPrototypePatchAttributes$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<PriceList>) => r.body as PriceList)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypePatchAttributes$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  priceListPrototypePatchAttributes$Json$Xml$Response(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: PriceList
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PriceList>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PriceList>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypePatchAttributes$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  priceListPrototypePatchAttributes$Json$Xml(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: PriceList
  },
  context?: HttpContext

): Observable<PriceList> {

    return this.priceListPrototypePatchAttributes$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<PriceList>) => r.body as PriceList)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypePatchAttributes$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  priceListPrototypePatchAttributes$Json$Javascript$Response(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: PriceList
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PriceList>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PriceList>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypePatchAttributes$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  priceListPrototypePatchAttributes$Json$Javascript(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: PriceList
  },
  context?: HttpContext

): Observable<PriceList> {

    return this.priceListPrototypePatchAttributes$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<PriceList>) => r.body as PriceList)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypePatchAttributes$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  priceListPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: PriceList
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PriceList>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PriceList>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  priceListPrototypePatchAttributes$XWwwFormUrlencoded$Json(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: PriceList
  },
  context?: HttpContext

): Observable<PriceList> {

    return this.priceListPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<PriceList>) => r.body as PriceList)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypePatchAttributes$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  priceListPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: PriceList
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PriceList>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PriceList>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  priceListPrototypePatchAttributes$XWwwFormUrlencoded$Xml(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: PriceList
  },
  context?: HttpContext

): Observable<PriceList> {

    return this.priceListPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<PriceList>) => r.body as PriceList)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypePatchAttributes$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  priceListPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: PriceList
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PriceList>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PriceList>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  priceListPrototypePatchAttributes$XWwwFormUrlencoded$Javascript(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: PriceList
  },
  context?: HttpContext

): Observable<PriceList> {

    return this.priceListPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<PriceList>) => r.body as PriceList)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypePatchAttributes$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  priceListPrototypePatchAttributes$Xml$Json$Response(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: PriceList
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PriceList>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PriceList>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypePatchAttributes$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  priceListPrototypePatchAttributes$Xml$Json(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: PriceList
  },
  context?: HttpContext

): Observable<PriceList> {

    return this.priceListPrototypePatchAttributes$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<PriceList>) => r.body as PriceList)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypePatchAttributes$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  priceListPrototypePatchAttributes$Xml$Xml$Response(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: PriceList
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PriceList>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PriceList>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypePatchAttributes$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  priceListPrototypePatchAttributes$Xml$Xml(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: PriceList
  },
  context?: HttpContext

): Observable<PriceList> {

    return this.priceListPrototypePatchAttributes$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<PriceList>) => r.body as PriceList)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceListPrototypePatchAttributes$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  priceListPrototypePatchAttributes$Xml$Javascript$Response(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: PriceList
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PriceList>> {

    const rb = new RequestBuilder(this.rootUrl, PriceListService.PriceListPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PriceList>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceListPrototypePatchAttributes$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  priceListPrototypePatchAttributes$Xml$Javascript(params: {

    /**
     * PriceList id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: PriceList
  },
  context?: HttpContext

): Observable<PriceList> {

    return this.priceListPrototypePatchAttributes$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<PriceList>) => r.body as PriceList)
    );
  }

}
