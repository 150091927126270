/* tslint:disable */
import {
  Account,
  Organization,
  Client,
  Document,
  Item,
  Tax,
  Stripe,
  TicketingHub,
  Recurrence,
  Payment,
  Default,
  Comment,
  Subscription,
  BusinessPremise,
  ElectronicDevice,
  FileMeta,
  Unit,
  Category,
  DocumentCategory,
  PriceList,
  Price,
  Order
} from '../index';

declare var Object: any;
export interface ActivityInterface {
  "action"?: string;
  "data"?: any;
  "id"?: any;
  "accountId"?: any;
  "organizationId"?: any;
  "clientId"?: any;
  "documentId"?: any;
  "itemId"?: any;
  "taxId"?: any;
  "stripeId"?: any;
  "ticketingHubId"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "recurrenceId"?: any;
  "paymentId"?: any;
  "defaultId"?: any;
  "commentId"?: string;
  "subscriptionId"?: any;
  "businessPremiseId"?: any;
  "electronicDeviceId"?: any;
  "fileMetaId"?: any;
  "unitId"?: any;
  "categoryId"?: any;
  "documentCategoryId"?: any;
  "priceListId"?: any;
  "priceId"?: any;
  "orderId"?: any;
  account?: Account;
  organization?: Organization;
  client?: Client;
  document?: Document;
  item?: Item;
  tax?: Tax;
  stripe?: Stripe;
  ticketingHub?: TicketingHub;
  recurrence?: Recurrence;
  payment?: Payment;
  default?: Default;
  comment?: Comment;
  subscription?: Subscription;
  businessPremise?: BusinessPremise;
  electronicDevice?: ElectronicDevice;
  fileMeta?: FileMeta;
  unit?: Unit;
  category?: Category;
  documentCategory?: DocumentCategory;
  priceList?: PriceList;
  price?: Price;
  order?: Order;
}

export class Activity implements ActivityInterface {
  "action": string;
  "data": any;
  "id": any;
  "accountId": any;
  "organizationId": any;
  "clientId": any;
  "documentId": any;
  "itemId": any;
  "taxId": any;
  "stripeId": any;
  "ticketingHubId": any;
  "createdAt": Date;
  "updatedAt": Date;
  "recurrenceId": any;
  "paymentId": any;
  "defaultId": any;
  "commentId": string;
  "subscriptionId": any;
  "businessPremiseId": any;
  "electronicDeviceId": any;
  "fileMetaId": any;
  "unitId": any;
  "categoryId": any;
  "documentCategoryId": any;
  "priceListId": any;
  "priceId": any;
  "orderId": any;
  account: Account;
  organization: Organization;
  client: Client;
  document: Document;
  item: Item;
  tax: Tax;
  stripe: Stripe;
  ticketingHub: TicketingHub;
  recurrence: Recurrence;
  payment: Payment;
  default: Default;
  comment: Comment;
  subscription: Subscription;
  businessPremise: BusinessPremise;
  electronicDevice: ElectronicDevice;
  fileMeta: FileMeta;
  unit: Unit;
  category: Category;
  documentCategory: DocumentCategory;
  priceList: PriceList;
  price: Price;
  order: Order;
  constructor(data?: ActivityInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Activity`.
   */
  public static getModelName() {
    return "Activity";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Activity for dynamic purposes.
  **/
  public static factory(data: ActivityInterface): Activity{
    return new Activity(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Activity',
      plural: 'Activities',
      path: 'Activities',
      idName: 'id',
      properties: {
        "action": {
          name: 'action',
          type: 'string'
        },
        "data": {
          name: 'data',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "accountId": {
          name: 'accountId',
          type: 'any'
        },
        "organizationId": {
          name: 'organizationId',
          type: 'any'
        },
        "clientId": {
          name: 'clientId',
          type: 'any'
        },
        "documentId": {
          name: 'documentId',
          type: 'any'
        },
        "itemId": {
          name: 'itemId',
          type: 'any'
        },
        "taxId": {
          name: 'taxId',
          type: 'any'
        },
        "stripeId": {
          name: 'stripeId',
          type: 'any'
        },
        "ticketingHubId": {
          name: 'ticketingHubId',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        "recurrenceId": {
          name: 'recurrenceId',
          type: 'any'
        },
        "paymentId": {
          name: 'paymentId',
          type: 'any'
        },
        "defaultId": {
          name: 'defaultId',
          type: 'any'
        },
        "commentId": {
          name: 'commentId',
          type: 'string'
        },
        "subscriptionId": {
          name: 'subscriptionId',
          type: 'any'
        },
        "businessPremiseId": {
          name: 'businessPremiseId',
          type: 'any'
        },
        "electronicDeviceId": {
          name: 'electronicDeviceId',
          type: 'any'
        },
        "fileMetaId": {
          name: 'fileMetaId',
          type: 'any'
        },
        "unitId": {
          name: 'unitId',
          type: 'any'
        },
        "categoryId": {
          name: 'categoryId',
          type: 'any'
        },
        "documentCategoryId": {
          name: 'documentCategoryId',
          type: 'any'
        },
        "priceListId": {
          name: 'priceListId',
          type: 'any'
        },
        "priceId": {
          name: 'priceId',
          type: 'any'
        },
        "orderId": {
          name: 'orderId',
          type: 'any'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'accountId',
          keyTo: 'id'
        },
        organization: {
          name: 'organization',
          type: 'Organization',
          model: 'Organization',
          relationType: 'belongsTo',
                  keyFrom: 'organizationId',
          keyTo: 'id'
        },
        client: {
          name: 'client',
          type: 'Client',
          model: 'Client',
          relationType: 'belongsTo',
                  keyFrom: 'clientId',
          keyTo: 'id'
        },
        document: {
          name: 'document',
          type: 'Document',
          model: 'Document',
          relationType: 'belongsTo',
                  keyFrom: 'documentId',
          keyTo: 'id'
        },
        item: {
          name: 'item',
          type: 'Item',
          model: 'Item',
          relationType: 'belongsTo',
                  keyFrom: 'itemId',
          keyTo: 'id'
        },
        tax: {
          name: 'tax',
          type: 'Tax',
          model: 'Tax',
          relationType: 'belongsTo',
                  keyFrom: 'taxId',
          keyTo: 'id'
        },
        stripe: {
          name: 'stripe',
          type: 'Stripe',
          model: 'Stripe',
          relationType: 'belongsTo',
                  keyFrom: 'stripeId',
          keyTo: 'id'
        },
        ticketingHub: {
          name: 'ticketingHub',
          type: 'TicketingHub',
          model: 'TicketingHub',
          relationType: 'belongsTo',
                  keyFrom: 'ticketingHubId',
          keyTo: 'id'
        },
        recurrence: {
          name: 'recurrence',
          type: 'Recurrence',
          model: 'Recurrence',
          relationType: 'belongsTo',
                  keyFrom: 'recurrenceId',
          keyTo: 'id'
        },
        payment: {
          name: 'payment',
          type: 'Payment',
          model: 'Payment',
          relationType: 'belongsTo',
                  keyFrom: 'paymentId',
          keyTo: 'id'
        },
        default: {
          name: 'default',
          type: 'Default',
          model: 'Default',
          relationType: 'belongsTo',
                  keyFrom: 'defaultId',
          keyTo: 'id'
        },
        comment: {
          name: 'comment',
          type: 'Comment',
          model: 'Comment',
          relationType: 'belongsTo',
                  keyFrom: 'commentId',
          keyTo: 'id'
        },
        subscription: {
          name: 'subscription',
          type: 'Subscription',
          model: 'Subscription',
          relationType: 'belongsTo',
                  keyFrom: 'subscriptionId',
          keyTo: 'id'
        },
        businessPremise: {
          name: 'businessPremise',
          type: 'BusinessPremise',
          model: 'BusinessPremise',
          relationType: 'belongsTo',
                  keyFrom: 'businessPremiseId',
          keyTo: 'id'
        },
        electronicDevice: {
          name: 'electronicDevice',
          type: 'ElectronicDevice',
          model: 'ElectronicDevice',
          relationType: 'belongsTo',
                  keyFrom: 'electronicDeviceId',
          keyTo: 'id'
        },
        fileMeta: {
          name: 'fileMeta',
          type: 'FileMeta',
          model: 'FileMeta',
          relationType: 'belongsTo',
                  keyFrom: 'fileMetaId',
          keyTo: 'id'
        },
        unit: {
          name: 'unit',
          type: 'Unit',
          model: 'Unit',
          relationType: 'belongsTo',
                  keyFrom: 'unitId',
          keyTo: 'id'
        },
        category: {
          name: 'category',
          type: 'Category',
          model: 'Category',
          relationType: 'belongsTo',
                  keyFrom: 'categoryId',
          keyTo: 'id'
        },
        documentCategory: {
          name: 'documentCategory',
          type: 'DocumentCategory',
          model: 'DocumentCategory',
          relationType: 'belongsTo',
                  keyFrom: 'documentCategoryId',
          keyTo: 'id'
        },
        priceList: {
          name: 'priceList',
          type: 'PriceList',
          model: 'PriceList',
          relationType: 'belongsTo',
                  keyFrom: 'priceListId',
          keyTo: 'id'
        },
        price: {
          name: 'price',
          type: 'Price',
          model: 'Price',
          relationType: 'belongsTo',
                  keyFrom: 'priceId',
          keyTo: 'id'
        },
        order: {
          name: 'order',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
                  keyFrom: 'orderId',
          keyTo: 'id'
        },
      }
    }
  }
}
