import { Account, LoopBackFilter, AccessToken } from '../shared/sdk/models';
import { AccountApi, LoopBackAuth } from '../shared/sdk/services';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoopBackConfig, LoggerService } from '../shared/sdk';
import { map, catchError, tap, filter } from 'rxjs/operators';
import { throwError, ReplaySubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { BASE_URL, API_VERSION } from '../shared/base-url';
import { environment } from '../../environments/environment';
import { Token } from './token';
import { TrackingService } from '../shared/services/tracking.service';
import { ToastService } from '../shared/modules/toast/toast.service';

@Injectable()
export class AccountService {
  public _active: Account;
  private active$: ReplaySubject<Account> = new ReplaySubject(1);
  readonly active: Observable<Account> = this.active$.asObservable();

  constructor(
    private accountApi: AccountApi,
    private lbAuth: LoopBackAuth,
    private log: LoggerService,
    private trackingService: TrackingService,
    protected toastService: ToastService,
    protected translate: TranslateService,
    protected http: HttpClient,
  ) {
    LoopBackConfig.setBaseURL(BASE_URL);
    LoopBackConfig.setApiVersion(API_VERSION);

    this.setActive(null);
  }

  clear() {
    this.lbAuth.clear();
  }

  createToken(id: string, data?: any) {
    return this.accountApi
      .createAccessTokens(id, data)
      .pipe(map(({ body }) => body));
  }

  deleteToken(id: string, fkId: string) {
    return this.accountApi
      .destroyByIdAccessTokens(id, fkId)
      .pipe(map(({ body }) => body));
  }

  getCurrent(filter?: LoopBackFilter) {
    this.log.log('AccountService: getCurrent');
    return this.accountApi.getCurrent(filter).pipe(map(({ body }) => body));
  }

  createAccessTokens(
    tokenId: string,
    data: { ttl: number; scope: string[] },
  ): Observable<Token> {
    return this.accountApi
      .createAccessTokens(tokenId, data)
      .pipe(map(({ body }) => body));
  }

  getIdentityVerification() {
    return this.accountApi.identityVerification().pipe(map(({ body }) => body));
  }

  isVerified(id) {
    return this.accountApi.isVerified(id).pipe(map(({ body }) => body));
  }

  getToken() {
    return this.accountApi.getCurrentToken();
  }

  getTokens(id: string, filter?: LoopBackFilter) {
    return this.accountApi
      .getAccessTokens(id, filter)
      .pipe(map(({ body }) => body));
  }

  // TODO: On page refresh already authenticated account doesn't have
  // accountOrganizations relation loaded whith it
  is(role: string | string[]): Observable<boolean> {
    return this.active.pipe(
      filter((account) => !!account && !!account.accountOrganizations),
      map((account) =>
        account.accountOrganizations.some((ao) => {
          if (String(ao.accountId) !== String(account.id)) {
            return false;
          }

          if (!ao.active || ao.disabled || ao.rejected) {
            return false;
          }

          if (Array.isArray(role)) {
            if (role.indexOf(ao.role) === -1) {
              return false;
            }
          } else {
            if (role !== ao.role) {
              return false;
            }
          }

          return true;
        }),
      ),
      // take(1),
    );
  }

  isAuthenticated(): boolean {
    this.log.log('AccountService: isAuthenticated');
    const authenticated = this.accountApi.isAuthenticated();

    if (authenticated) {
      // Hack to prevent double loading
      if (this._active === null) {
        this._active = {} as Account;

        this.isAuthorized().subscribe((acc) => {
          this.setActive(acc);
        });
      }
    } else {
      this.setActive(null);
    }

    return authenticated;
  }

  isAuthorized(): Observable<Account> {
    return this.accountApi.authorized().pipe(
      map(({ body }) => body));
  }

  login(
    account: Account,
    include: any = 'user',
    rememberMe?: boolean,
  ): Observable<any> {
    this.log.log('AccountService: login');

    const credentials = account as any;
    credentials.ttl = 15552000; // 6m

    return this.accountApi.login(account, include, rememberMe).pipe(
      map((token: AccessToken) => {
        this.setActive(token.user);
        return token;
      }),
    );
  }

  logout() {
    this.log.log('AccountService: logout');
    this.setActive(null);
    this.trackingService.logout();
    return this.accountApi.logout().pipe(map(({ body }) => body));
  }

  sendVerificationEmail(id: string) {
    return this.accountApi
      .sendVerificationEmail(id)
      .pipe(map(({ body }) => body));
  }

  setActive(account) {
    this._active = account;
    this.active$.next(this._active);

    if (this._active) {
      this.trackingService.setUser(this._active);
    } else {
      this.trackingService.logout();
    }
  }

  register(account: Account): Observable<any> {
    this.log.log('AccountService: register');
    return this.http.post<Account>(
      `${environment.selfUrl || ''}/accounts`,
      account,
    );
  }

  isUnique(email: string) {
    return this.accountApi.isUnique(email).pipe(map(({ body }) => body));
  }

  changePassword(
    oldPassword: string,
    newPassword: string,
    toastr = true,
  ): Observable<any> {
    return this.accountApi.changePassword(oldPassword, newPassword).pipe(
      map(({ body }) => body),
      tap(() => {
        if (toastr) {
          this.toastService.success(
            this.translate.instant(`Password successfully changed.`),
          );
        }
      }),
      catchError((err) => {
        if (toastr) {
          if (err.error.code === 'INVALID_PASSWORD') {
            this.toastService.error(
              this.translate.instant(`Current password is invalid!`),
            );
          } else {
            this.toastService.error(
              this.translate.instant(
                `Error changing password, please try again or contact support.`,
              ),
            );
          }
        }

        return throwError(err);
      }),
    );
  }

  patch(id: string, data: any, toastr = true): Observable<Partial<Account>> {
    this.log.log('AccountService: patch');

    return this.accountApi.patchAttributes(id, data).pipe(
      map(({ body }) => body),
      tap((account) => {
        if (toastr) {
          this.toastService.success(
            this.translate.instant(`Account settings successfully saved.`),
          );
        }

        // If updated active account emit
        if (this._active && id === this._active.id) {
          this.setActive(account);
        }
      }),
      catchError((err) => {
        if (toastr) {
          this.toastService.error(
            this.translate.instant(
              `Error updating account settings, please try again or contact support.`,
            ),
          );
        }

        return throwError(err);
      }),
    );
  }

  setPassword(newPassword: string, token: string) {
    this.log.log('AccountService: setNewPasswordWithToken');

    return this.accountApi
      .setPassword(newPassword, (headers: HttpHeaders) => {
        headers = headers.set('Authorization', token);
        return headers;
      })
      .pipe(map(({ body }) => body));
  }

  reset(account: Account): Observable<any> {
    this.log.log('AccountService: reset');
    return this.accountApi.resetPassword(account).pipe(map(({ body }) => body));
  }
}
