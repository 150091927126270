import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_URL, API_VERSION } from '../base-url';
import { LoopBackConfig, LoggerService } from '../sdk';
import { CurrencyApi } from '../sdk/services';
import { Currency, LoopBackFilter } from '../sdk/models';
import { ApiService } from './api.service';
import { CacheService } from './cache.service';
import { ToastService } from '../../shared/modules/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CurrencyService extends ApiService<Currency> {
  name: 'Currency';
  plural: 'Currencies';
  ttl: 31000000000;

  constructor(
    cacheService: CacheService,
    log: LoggerService,
    toastService: ToastService,
    translate: TranslateService,
    private currencyApi: CurrencyApi,
  ) {
    super(cacheService, log, toastService, translate);
    LoopBackConfig.setBaseURL(BASE_URL);
    LoopBackConfig.setApiVersion(API_VERSION);
  }

  get(
    filter: LoopBackFilter = {},
  ): Observable<{ body: Currency[]; totalCount: number }> {
    this.log.log('CurrencyService: get');
    return super.cachedReq(
      `currencies_2_${JSON.stringify(filter)}`,
      this.currencyApi.find(filter),
      false,
      false,
      true,
    );
  }
}
