/* tslint:disable */
import {
  Account
} from '../index';

declare var Object: any;
export interface LogInterface {
  "uniqueId"?: string;
  "host"?: string;
  "origin"?: string;
  "url"?: string;
  "ip"?: string;
  "reqBody"?: any;
  "method"?: string;
  "query"?: any;
  "resBody"?: any;
  "status"?: number;
  "id"?: any;
  "accountId"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  account?: Account;
}

export class Log implements LogInterface {
  "uniqueId": string;
  "host": string;
  "origin": string;
  "url": string;
  "ip": string;
  "reqBody": any;
  "method": string;
  "query": any;
  "resBody": any;
  "status": number;
  "id": any;
  "accountId": any;
  "createdAt": Date;
  "updatedAt": Date;
  account: Account;
  constructor(data?: LogInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Log`.
   */
  public static getModelName() {
    return "Log";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Log for dynamic purposes.
  **/
  public static factory(data: LogInterface): Log{
    return new Log(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Log',
      plural: 'Logs',
      path: 'Logs',
      idName: 'id',
      properties: {
        "uniqueId": {
          name: 'uniqueId',
          type: 'string'
        },
        "host": {
          name: 'host',
          type: 'string'
        },
        "origin": {
          name: 'origin',
          type: 'string'
        },
        "url": {
          name: 'url',
          type: 'string'
        },
        "ip": {
          name: 'ip',
          type: 'string'
        },
        "reqBody": {
          name: 'reqBody',
          type: 'any'
        },
        "method": {
          name: 'method',
          type: 'string'
        },
        "query": {
          name: 'query',
          type: 'any'
        },
        "resBody": {
          name: 'resBody',
          type: 'any'
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "accountId": {
          name: 'accountId',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'accountId',
          keyTo: 'id'
        },
      }
    }
  }
}
