import {
  animate,
  animation,
  style,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';
import { cubicBezier } from './animations';

export const fadeInAnimation = animation(
  [style({ opacity: 0 }), animate('{{ duration }} {{ easing }}')],
  {
    params: {
      duration: '250ms',
      easing: cubicBezier,
    },
  },
);

export const fadeOutAnimation = animation(
  [animate('{{ duration }} {{ easing }}', style({ opacity: 0 }))],
  {
    params: {
      duration: '150ms',
      easing: cubicBezier,
    },
  },
);

export const fade = trigger('fade', [
  transition(':enter', useAnimation(fadeInAnimation)),
  transition(':leave', useAnimation(fadeOutAnimation)),
]);
