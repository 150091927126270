import { Injectable } from '@angular/core';
import { ToastService } from '../../shared/modules/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  constructor(
    private toastService: ToastService,
    private translate: TranslateService,
  ) {}

  copy(text: string, showToast = true): void {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    if (showToast) {
      this.toastService.success(this.translate.instant('Copied to clipboard'));
    }
  }
}
