import { Injectable } from '@angular/core';
import { LoopBackConfig, LoggerService } from '../shared/sdk';
import { ClientApi, OrganizationApi, AccountApi } from '../shared/sdk/services';
import { Client } from '../shared/sdk/models';
import { ToastService } from '../shared/modules/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';

import { ApiService } from '../shared/services/api.service';
import { BASE_URL, API_VERSION } from '../shared/base-url';
import { CacheService } from '../shared/services/cache.service';
import { Observable } from 'rxjs';
import { FileUploadService } from '../shared/services/file-upload.service';
import { tap, catchError, map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ClientService extends ApiService<Client> {
  constructor(
    public accountApi: AccountApi,
    protected cacheService: CacheService,
    protected log: LoggerService,
    protected toastService: ToastService,
    protected translate: TranslateService,
    private clientApi: ClientApi,
    private fileUploadService: FileUploadService,
    private orgApi: OrganizationApi,
  ) {
    super(cacheService, log, toastService, translate);

    this.name = 'Client';
    this.plural = 'Clients';

    LoopBackConfig.setBaseURL(BASE_URL);
    LoopBackConfig.setApiVersion(API_VERSION);
  }

  count(
    orgId: string,
    filter: any = {},
    force = false,
    toastr = true,
  ): Observable<number> {
    return super.count(orgId, filter, force, toastr, this.orgApi);
  }

  create(orgId: string, data: any, toastr = true): Observable<Client> {
    return super.create(orgId, data, toastr, this.orgApi);
  }

  delete(id: string, toastr = true): Observable<boolean> {
    return super.delete(id, toastr, this.clientApi);
  }

  exportClients(orgId: string, lang: string): Observable<{ success: boolean }> {
    return super.downloadDeferred(this.orgApi.export(orgId, 'client'));
  }

  find(
    orgId: string,
    term: string,
    type: string = null,
  ): Observable<{ body: Client[]; totalCount: number }> {
    this.log.log(`${this.name}Service: find`);
    return this.orgApi.searchClients(orgId, term, type);
  }

  get(
    orgId: string,
    filter: any = {},
    force = false,
    toastr = true,
  ): Observable<{ body: Client[]; totalCount: number }> {
    return super.get(orgId, filter, force, toastr, this.orgApi);
  }

  getById(
    id: string,
    filter = {},
    force = true,
    toastr = true,
  ): Observable<Client> {
    return super.getById(id, filter, force, toastr, this.clientApi);
  }

  import(orgId: string, file: File): Observable<any> {
    return this.fileUploadService
      .upload(file, `/organizations/${orgId}/import?type=client`)
      .pipe(
        tap((res) => {
          if (!res.success) {
            throw new Error('import failed');
          }

          this.toastService.success(
            this.translate.instant('client-service.import.success'),
          );
        }),
        catchError((err) => {
          return err;
        }),
      );
  }

  sendReminder(modalRef: any): void {
    modalRef.result
      .then(({ email, clientId, l }) => {
        this.clientApi.remoteSendOverdueReminder(clientId, email, l).subscribe(
          () => {},
          () => {
            this.toastService.success(
              this.translate.instant('client.reminder.sent.error'),
            );
          },
        );
        this.toastService.success(
          this.translate.instant('client.reminder.sent.success'),
        );
      })
      .catch(() => {});
  }

  update(id: string, data: any, toastr = true): Observable<Client> {
    return super.update(id, data, toastr, this.clientApi);
  }
}
