import { TranslateService } from '@ngx-translate/core';
import {
  RemappedErrorsToast,
  ToastService,
} from '../../shared/modules/toast/toast.service';
import { Injectable } from '@angular/core';
import { LoggerService } from '../sdk';
import { CacheService } from './cache.service';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, map, catchError, take, tap, share } from 'rxjs/operators';

@Injectable()
export abstract class ApiService<T> {
  protected ttl = 60000; // One minute
  public name: string;
  public plural: string;

  constructor(
    protected cacheService: CacheService,
    protected log: LoggerService,
    protected toastService: ToastService,
    protected translate: TranslateService,
  ) {
    this.name = 'Api';
    this.plural = 'Apis';
  }

  count(
    orgId: any,
    where: any = {},
    force = false,
    toastr = false,
    api,
  ): Observable<number> {
    this.log.info(`${this.name}Service: count`);
    const cacheKey = `${orgId}_${this.name}_count_${JSON.stringify(where)}`;

    return this.cachedReq(
      cacheKey,
      api[`count${this.plural}`](orgId, where),
      force,
      toastr,
    ).pipe(map(({ body }) => body.count));
  }

  create(
    parentId: string,
    data: any,
    toastr = true,
    api,
    plural = true,
    remappedErrorsToastOptions?: RemappedErrorsToast['options'],
  ): Observable<T> {
    return api[`create${!!plural ? this.plural : this.name}`](
      parentId,
      data,
    ).pipe(
      map(({ body }) => body),
      map((created: any) => {
        this.cacheService.clearCache();

        if (toastr) {
          this.toastService.success(
            this.translate.instant(`${this.name} successfully saved.`),
          );
        }

        return created;
      }),
      catchError((err) => {
        if (
          toastr &&
          ((err.currentTarget && err.currentTarget.status !== 0) ||
            (err.error && err.error.statusCode !== 0) ||
            err.statusCode !== 0)
        ) {
          if (!remappedErrorsToastOptions?.defaultMessageKey) {
            remappedErrorsToastOptions = {
              defaultMessageKey: `Error creating ${this.plural}, please try again or contact support.`,
              ...remappedErrorsToastOptions,
            };
          }

          this.toastService.remappedErrorsToast(
            err,
            remappedErrorsToastOptions,
          );
        } else {
          this.toastService.error(
            this.translate.instant(
              `Error creating ${this.plural}, please try again or contact support.`,
            ),
          );
        }
        return throwError(err);
      }),
    );
  }

  delete(id: string, toastr = true, api): Observable<boolean> {
    // TODO: deletedAt attribute
    return api.deleteById(id).pipe(
      map(({ body }) => body),
      map((done) => {
        this.cacheService.clearCache();

        if (toastr) {
          this.toastService.success(
            this.translate.instant(`${this.name} successfully deleted.`),
          );
        }

        return done;
      }),
      catchError((err) => {
        if (
          toastr &&
          ((err.currentTarget && err.currentTarget.status !== 0) ||
            (err.error && err.error.statusCode !== 0))
        ) {
          const status = err.error ? err.error.statusCode : null;

          this.toastService[status === 422 ? 'warning' : 'error'](
            this.translate.instant(
              `Error deleting ${this.name}, please try again or contact support.`,
            ),
          );
        }

        return throwError(err);
      }),
    );
  }

  get(
    orgId: any,
    filter: any = {},
    force = false,
    toastr = true,
    api,
  ): Observable<{ body: T[]; totalCount: number }> {
    this.log.info(`${this.name}Service: get`);
    const cacheKey = `${orgId}_${this.name}_get_${JSON.stringify(filter)}`;

    return this.cachedReq(
      cacheKey,
      api[`get${this.plural}`](orgId, filter),
      force,
      toastr,
    );
  }

  cachedReq(
    cacheKey: string,
    method: any,
    force = false,
    toastr = true,
    persisted = false,
  ): Observable<any> {
    this.log.info(`${this.name}Service: cachedReq`);
    let cache: any;

    if (!force) {
      cache = this.cacheService.get(cacheKey, persisted);
    }

    return of(!!cache).pipe(
      switchMap((useCache: boolean) => {
        if (useCache) {
          this.log.info(`${this.name}Service: cachedReq Loading from cache`);
          return of(cache);
        } else {
          this.log.info(`${this.name}Service: cachedReq Loading from API`);
          return method.pipe(
            map((res: any) => {
              if (res && typeof res.body !== 'undefined') {
                const xTotalCount = res.headers.get('x-total-count');
                const totalCount = xTotalCount ? Number(xTotalCount) : null;

                return {
                  body: res.body,
                  totalCount,
                };
              } else {
                return res;
              }
            }),
          );
        }
      }),
      take(1),
      map((data: any) => {
        this.log.info(`${this.name}Service: cachedReq: Loaded`);

        if (!cache) {
          this.log.info(`${this.name}Service: cachedReq: Set TTL: ${this.ttl}`);
          this.cacheService.set(cacheKey, data, this.ttl, persisted);
        }

        return data;
      }),
      catchError((err) => {
        if (
          toastr &&
          ((err.currentTarget && err.currentTarget.status !== 0) ||
            (err.error && err.error.statusCode !== 0))
        ) {
          const status = err.error ? err.error.statusCode : null;

          this.toastService[status === 422 ? 'warning' : 'error'](
            this.translate.instant(
              `Error loading ${this.plural}, please try again or contact support.`,
            ),
          );
        }

        return throwError(`Error cachedReq ${this.plural}`);
      }),
    );
  }

  downloadDeferred(
    obs: Observable<{ body: { success: boolean } }>,
  ): Observable<{ success: boolean }> {
    const mapped = obs.pipe(
      map(({ body }) => body),
      share(),
    );

    mapped.subscribe(
      (res) => {
        if (!res.success) {
          throw new Error('export failed');
        }

        this.toastService.success(
          this.translate.instant('document-service.export.success'),
        );
      },
      (err) => {
        this.toastService.error(
          this.translate.instant('document-service.export.error'),
        );
      },
    );

    return mapped;
  }

  getById(
    id: any,
    filter = {},
    force = true,
    toastr = true,
    api,
  ): Observable<T> {
    this.log.log(`${this.name}Service: findById`);
    const cacheKey = `${this.name}_${id}_${JSON.stringify(filter)}`;

    return this.cachedReq(
      cacheKey,
      api.findById(id, filter),
      force,
      toastr,
      // ).pipe(
      // map(({ body }) => body),
    );
  }

  patch(id: string, data: any, toastr = true, api): Observable<Partial<T>> {
    // TODO: Clear or update cache from update response
    return api.patchAttributes(id, data).pipe(
      map(({ body }) => body),
      map((result: any) => {
        this.cacheService.clearCache();

        if (toastr) {
          this.toastService.success(
            this.translate.instant(`${this.name} successfully updated.`),
          );
        }

        return result;
      }),
      catchError((err) => {
        if (
          toastr &&
          ((err.currentTarget && err.currentTarget.status !== 0) ||
            (err.error && err.error.statusCode !== 0))
        ) {
          const status = err.error ? err.error.statusCode : null;

          this.toastService[status === 422 ? 'warning' : 'error'](
            this.translate.instant(
              `Error updating ${this.plural}, please try again or contact support.`,
            ),
          );
        }

        return throwError(`Error patching ${this.name}`);
      }),
    );
  }

  update(
    id: string,
    data: any,
    toastr = true,
    api,
    remappedErrorsToastOptions?: RemappedErrorsToast['options'],
  ): Observable<T> {
    // TODO: Clear or update cache
    return api.replaceById(id, data).pipe(
      // map(({ body }) => body),
      tap(() => {
        this.cacheService.clearCache();

        if (toastr) {
          this.toastService.success(
            this.translate.instant(`${this.name} successfully updated.`),
          );
        }
      }),
      catchError((err) => {
        if (
          toastr &&
          ((err.currentTarget && err.currentTarget.status !== 0) ||
            (err.error && err.error.statusCode !== 0) ||
            err.statusCode !== 0)
        ) {
          // Only add default message if it has not been set
          if (!remappedErrorsToastOptions?.defaultMessageKey) {
            remappedErrorsToastOptions = {
              defaultMessageKey: `Error creating ${this.plural}, please try again or contact support.`,
              ...remappedErrorsToastOptions
            };
          }
          this.toastService.remappedErrorsToast(
            err,
            remappedErrorsToastOptions,
          );
        } else {
          this.toastService.error(
            this.translate.instant(
              `Error updating ${this.plural}, please try again or contact support.`,
            ),
          );
        }
        return throwError(`Error updating ${this.name}`);
      }),
    );
  }
}
