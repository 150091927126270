import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ToastService, Toast } from '../toast.service';
import { fadeInToast, fadeOutToast } from './toasts.component.animations';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInToast, fadeOutToast],
})
export class ToastsComponent implements OnInit {
  toasts: Observable<Toast[]>;

  constructor(public toastService: ToastService) {}

  ngOnInit(): void {
    this.toasts = this.toastService.toasts$;
  }
}
