/* tslint:disable */
import { Injectable } from '@angular/core';
import { AccessToken } from '../../models/AccessToken';
import { Document } from '../../models/Document';
import { Item } from '../../models/Item';
import { DocumentItem } from '../../models/DocumentItem';
import { Tax } from '../../models/Tax';
import { DocumentItemTax } from '../../models/DocumentItemTax';
import { Client } from '../../models/Client';
import { Organization } from '../../models/Organization';
import { Shopify } from '../../models/Shopify';
import { Stripe } from '../../models/Stripe';
import { TicketingHub } from '../../models/TicketingHub';
import { Currency } from '../../models/Currency';
import { Account } from '../../models/Account';
import { Activity } from '../../models/Activity';
import { Recurrence } from '../../models/Recurrence';
import { Payment } from '../../models/Payment';
import { Default } from '../../models/Default';
import { Comment } from '../../models/Comment';
import { Company } from '../../models/Company';
import { DocumentHistory } from '../../models/DocumentHistory';
import { Subscription } from '../../models/Subscription';
import { Plan } from '../../models/Plan';
import { Usage } from '../../models/Usage';
import { WhiteLabel } from '../../models/WhiteLabel';
import { Container } from '../../models/Container';
import { Message } from '../../models/Message';
import { BusinessPremise } from '../../models/BusinessPremise';
import { Country } from '../../models/Country';
import { Tracking } from '../../models/Tracking';
import { ElectronicDevice } from '../../models/ElectronicDevice';
import { Certificate } from '../../models/Certificate';
import { GeoIP } from '../../models/GeoIP';
import { Eslog } from '../../models/Eslog';
import { AccountOrganization } from '../../models/AccountOrganization';
import { FileMeta } from '../../models/FileMeta';
import { Unit } from '../../models/Unit';
import { Category } from '../../models/Category';
import { DocumentCategory } from '../../models/DocumentCategory';
import { PriceList } from '../../models/PriceList';
import { Price } from '../../models/Price';
import { Warehouse } from '../../models/Warehouse';
import { Component } from '../../models/Component';
import { Webhook } from '../../models/Webhook';
import { Typless } from '../../models/Typless';
import { VIES } from '../../models/VIES';
import { Order } from '../../models/Order';
import { Magento1 } from '../../models/Magento1';
import { Woocommerce } from '../../models/Woocommerce';
import { CustomIntegration } from '../../models/CustomIntegration';
import { Bokun } from '../../models/Bokun';
import { Shopamine } from '../../models/Shopamine';
import { Data } from '../../models/Data';
import { Sendgrid } from '../../models/Sendgrid';
import { Log } from '../../models/Log';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    AccessToken: AccessToken,
    Document: Document,
    Item: Item,
    DocumentItem: DocumentItem,
    Tax: Tax,
    DocumentItemTax: DocumentItemTax,
    Client: Client,
    Organization: Organization,
    Shopify: Shopify,
    Stripe: Stripe,
    TicketingHub: TicketingHub,
    Currency: Currency,
    Account: Account,
    Activity: Activity,
    Recurrence: Recurrence,
    Payment: Payment,
    Default: Default,
    Comment: Comment,
    Company: Company,
    DocumentHistory: DocumentHistory,
    Subscription: Subscription,
    Plan: Plan,
    Usage: Usage,
    WhiteLabel: WhiteLabel,
    Container: Container,
    Message: Message,
    BusinessPremise: BusinessPremise,
    Country: Country,
    Tracking: Tracking,
    ElectronicDevice: ElectronicDevice,
    Certificate: Certificate,
    GeoIP: GeoIP,
    Eslog: Eslog,
    AccountOrganization: AccountOrganization,
    FileMeta: FileMeta,
    Unit: Unit,
    Category: Category,
    DocumentCategory: DocumentCategory,
    PriceList: PriceList,
    Price: Price,
    Warehouse: Warehouse,
    Component: Component,
    Webhook: Webhook,
    Typless: Typless,
    VIES: VIES,
    Order: Order,
    Magento1: Magento1,
    Woocommerce: Woocommerce,
    CustomIntegration: CustomIntegration,
    Bokun: Bokun,
    Shopamine: Shopamine,
    Data: Data,
    Sendgrid: Sendgrid,
    Log: Log,
    
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
