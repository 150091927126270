import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../shared/modules/toast/toast.service';
import { Injectable } from '@angular/core';
import {
  LoopBackConfig,
  LoggerService,
  TicketingHubApi,
  OrganizationApi,
  TicketingHub,
} from '../shared/sdk';

import { BASE_URL, API_VERSION } from '../shared/base-url';
import { ApiService } from '../shared/services/api.service';
import { CacheService } from '../shared/services/cache.service';
import { Observable } from 'rxjs';

@Injectable()
export class TicketingHubService extends ApiService<TicketingHub> {
  constructor(
    protected cacheService: CacheService,
    protected log: LoggerService,
    protected toastService: ToastService,
    protected language: TranslateService,
    private orgApi: OrganizationApi,
    private thApi: TicketingHubApi,
  ) {
    super(cacheService, log, toastService, language);

    this.name = 'TicketingHub';
    this.plural = 'TicketingHub';

    LoopBackConfig.setBaseURL(BASE_URL);
    LoopBackConfig.setApiVersion(API_VERSION);

    this.clearCache();
  }

  clearCache(): void {
    this.cacheService.clearCache();
  }

  create(orgId: string, data: any, toastr = true): Observable<TicketingHub> {
    return super.create(orgId, data, toastr, this.orgApi);
  }

  delete(id: string, toastr = true): Observable<boolean> {
    return super.delete(id, toastr, this.thApi);
  }

  patch(
    id: string,
    data: any,
    toastr = true,
  ): Observable<Partial<TicketingHub>> {
    this.log.log('TicketingHub: patch');

    return super.update(id, data, toastr, this.thApi);
  }
}
