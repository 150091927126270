/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class OrganizationMerchantLinkControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation organizationMerchantLinkControllerGet
   */
  static readonly OrganizationMerchantLinkControllerGetPath = '/v1/organizations/{id}/merchant/link';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'url': string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationMerchantLinkControllerService.OrganizationMerchantLinkControllerGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'url': string;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `get$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get(params: {
    id: string;
  },
  context?: HttpContext

): Observable<{
'url': string;
}> {

    return this.get$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'url': string;
}>) => r.body as {
'url': string;
})
    );
  }

}
