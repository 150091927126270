/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Bokun } from '../models/bokun';

@Injectable({
  providedIn: 'root',
})
export class BokunService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation bokunDeleteById
   */
  static readonly BokunDeleteByIdPath = '/v1/Bokun/{id}';

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunDeleteById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  bokunDeleteById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunDeleteById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bokunDeleteById$Json(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.bokunDeleteById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunDeleteById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  bokunDeleteById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunDeleteById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bokunDeleteById$Xml(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.bokunDeleteById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunDeleteById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  bokunDeleteById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunDeleteById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bokunDeleteById$Javascript(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.bokunDeleteById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation bokunPrototypePatchAttributes
   */
  static readonly BokunPrototypePatchAttributesPath = '/v1/Bokun/{id}';

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunPrototypePatchAttributes$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bokunPrototypePatchAttributes$Json$Json$Response(params: {

    /**
     * Bokun id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Bokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Bokun>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Bokun>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunPrototypePatchAttributes$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bokunPrototypePatchAttributes$Json$Json(params: {

    /**
     * Bokun id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Bokun
  },
  context?: HttpContext

): Observable<Bokun> {

    return this.bokunPrototypePatchAttributes$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Bokun>) => r.body as Bokun)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunPrototypePatchAttributes$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bokunPrototypePatchAttributes$Json$Xml$Response(params: {

    /**
     * Bokun id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Bokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Bokun>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Bokun>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunPrototypePatchAttributes$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bokunPrototypePatchAttributes$Json$Xml(params: {

    /**
     * Bokun id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Bokun
  },
  context?: HttpContext

): Observable<Bokun> {

    return this.bokunPrototypePatchAttributes$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Bokun>) => r.body as Bokun)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunPrototypePatchAttributes$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bokunPrototypePatchAttributes$Json$Javascript$Response(params: {

    /**
     * Bokun id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Bokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Bokun>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Bokun>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunPrototypePatchAttributes$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bokunPrototypePatchAttributes$Json$Javascript(params: {

    /**
     * Bokun id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Bokun
  },
  context?: HttpContext

): Observable<Bokun> {

    return this.bokunPrototypePatchAttributes$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Bokun>) => r.body as Bokun)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunPrototypePatchAttributes$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  bokunPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Bokun id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Bokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Bokun>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Bokun>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  bokunPrototypePatchAttributes$XWwwFormUrlencoded$Json(params: {

    /**
     * Bokun id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Bokun
  },
  context?: HttpContext

): Observable<Bokun> {

    return this.bokunPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Bokun>) => r.body as Bokun)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunPrototypePatchAttributes$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  bokunPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Bokun id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Bokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Bokun>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Bokun>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  bokunPrototypePatchAttributes$XWwwFormUrlencoded$Xml(params: {

    /**
     * Bokun id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Bokun
  },
  context?: HttpContext

): Observable<Bokun> {

    return this.bokunPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Bokun>) => r.body as Bokun)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunPrototypePatchAttributes$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  bokunPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Bokun id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Bokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Bokun>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Bokun>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  bokunPrototypePatchAttributes$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Bokun id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Bokun
  },
  context?: HttpContext

): Observable<Bokun> {

    return this.bokunPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Bokun>) => r.body as Bokun)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunPrototypePatchAttributes$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  bokunPrototypePatchAttributes$Xml$Json$Response(params: {

    /**
     * Bokun id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Bokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Bokun>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Bokun>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunPrototypePatchAttributes$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  bokunPrototypePatchAttributes$Xml$Json(params: {

    /**
     * Bokun id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Bokun
  },
  context?: HttpContext

): Observable<Bokun> {

    return this.bokunPrototypePatchAttributes$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Bokun>) => r.body as Bokun)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunPrototypePatchAttributes$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  bokunPrototypePatchAttributes$Xml$Xml$Response(params: {

    /**
     * Bokun id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Bokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Bokun>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Bokun>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunPrototypePatchAttributes$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  bokunPrototypePatchAttributes$Xml$Xml(params: {

    /**
     * Bokun id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Bokun
  },
  context?: HttpContext

): Observable<Bokun> {

    return this.bokunPrototypePatchAttributes$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Bokun>) => r.body as Bokun)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunPrototypePatchAttributes$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  bokunPrototypePatchAttributes$Xml$Javascript$Response(params: {

    /**
     * Bokun id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Bokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Bokun>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Bokun>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunPrototypePatchAttributes$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  bokunPrototypePatchAttributes$Xml$Javascript(params: {

    /**
     * Bokun id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Bokun
  },
  context?: HttpContext

): Observable<Bokun> {

    return this.bokunPrototypePatchAttributes$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Bokun>) => r.body as Bokun)
    );
  }

  /**
   * Path part for operation bokunPrototypeProcessOrder
   */
  static readonly BokunPrototypeProcessOrderPath = '/v1/Bokun/{id}/order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunPrototypeProcessOrder$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bokunPrototypeProcessOrder$Json$Json$Response(params: {

    /**
     * Bokun id
     */
    id: string;
    body?: Bokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunPrototypeProcessOrder$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bokunPrototypeProcessOrder$Json$Json(params: {

    /**
     * Bokun id
     */
    id: string;
    body?: Bokun
  },
  context?: HttpContext

): Observable<{
}> {

    return this.bokunPrototypeProcessOrder$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunPrototypeProcessOrder$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bokunPrototypeProcessOrder$Json$Xml$Response(params: {

    /**
     * Bokun id
     */
    id: string;
    body?: Bokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunPrototypeProcessOrder$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bokunPrototypeProcessOrder$Json$Xml(params: {

    /**
     * Bokun id
     */
    id: string;
    body?: Bokun
  },
  context?: HttpContext

): Observable<{
}> {

    return this.bokunPrototypeProcessOrder$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunPrototypeProcessOrder$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bokunPrototypeProcessOrder$Json$Javascript$Response(params: {

    /**
     * Bokun id
     */
    id: string;
    body?: Bokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunPrototypeProcessOrder$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bokunPrototypeProcessOrder$Json$Javascript(params: {

    /**
     * Bokun id
     */
    id: string;
    body?: Bokun
  },
  context?: HttpContext

): Observable<{
}> {

    return this.bokunPrototypeProcessOrder$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunPrototypeProcessOrder$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  bokunPrototypeProcessOrder$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Bokun id
     */
    id: string;
    body?: Bokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunPrototypeProcessOrder$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  bokunPrototypeProcessOrder$XWwwFormUrlencoded$Json(params: {

    /**
     * Bokun id
     */
    id: string;
    body?: Bokun
  },
  context?: HttpContext

): Observable<{
}> {

    return this.bokunPrototypeProcessOrder$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunPrototypeProcessOrder$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  bokunPrototypeProcessOrder$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Bokun id
     */
    id: string;
    body?: Bokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunPrototypeProcessOrder$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  bokunPrototypeProcessOrder$XWwwFormUrlencoded$Xml(params: {

    /**
     * Bokun id
     */
    id: string;
    body?: Bokun
  },
  context?: HttpContext

): Observable<{
}> {

    return this.bokunPrototypeProcessOrder$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunPrototypeProcessOrder$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  bokunPrototypeProcessOrder$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Bokun id
     */
    id: string;
    body?: Bokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunPrototypeProcessOrder$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  bokunPrototypeProcessOrder$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Bokun id
     */
    id: string;
    body?: Bokun
  },
  context?: HttpContext

): Observable<{
}> {

    return this.bokunPrototypeProcessOrder$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunPrototypeProcessOrder$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  bokunPrototypeProcessOrder$Xml$Json$Response(params: {

    /**
     * Bokun id
     */
    id: string;
    body?: Bokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunPrototypeProcessOrder$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  bokunPrototypeProcessOrder$Xml$Json(params: {

    /**
     * Bokun id
     */
    id: string;
    body?: Bokun
  },
  context?: HttpContext

): Observable<{
}> {

    return this.bokunPrototypeProcessOrder$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunPrototypeProcessOrder$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  bokunPrototypeProcessOrder$Xml$Xml$Response(params: {

    /**
     * Bokun id
     */
    id: string;
    body?: Bokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunPrototypeProcessOrder$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  bokunPrototypeProcessOrder$Xml$Xml(params: {

    /**
     * Bokun id
     */
    id: string;
    body?: Bokun
  },
  context?: HttpContext

): Observable<{
}> {

    return this.bokunPrototypeProcessOrder$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunPrototypeProcessOrder$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  bokunPrototypeProcessOrder$Xml$Javascript$Response(params: {

    /**
     * Bokun id
     */
    id: string;
    body?: Bokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunPrototypeProcessOrder$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  bokunPrototypeProcessOrder$Xml$Javascript(params: {

    /**
     * Bokun id
     */
    id: string;
    body?: Bokun
  },
  context?: HttpContext

): Observable<{
}> {

    return this.bokunPrototypeProcessOrder$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation bokunPrototypeImport
   */
  static readonly BokunPrototypeImportPath = '/v1/Bokun/{id}/import';

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunPrototypeImport$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  bokunPrototypeImport$Json$Response(params: {

    /**
     * Bokun id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunPrototypeImportPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunPrototypeImport$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  bokunPrototypeImport$Json(params: {

    /**
     * Bokun id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.bokunPrototypeImport$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunPrototypeImport$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  bokunPrototypeImport$Xml$Response(params: {

    /**
     * Bokun id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunPrototypeImportPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunPrototypeImport$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  bokunPrototypeImport$Xml(params: {

    /**
     * Bokun id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.bokunPrototypeImport$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bokunPrototypeImport$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  bokunPrototypeImport$Javascript$Response(params: {

    /**
     * Bokun id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, BokunService.BokunPrototypeImportPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bokunPrototypeImport$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  bokunPrototypeImport$Javascript(params: {

    /**
     * Bokun id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.bokunPrototypeImport$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

}
