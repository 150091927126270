/* tslint:disable */
import {
  Organization,
  ElectronicDevice
} from '../index';

declare var Object: any;
export interface BusinessPremiseInterface {
  "type"?: string;
  "taxNumber"?: string;
  "businessPremiseId": string;
  "premiseType"?: string;
  "cadastralNumber"?: number;
  "buildingNumber"?: number;
  "buildingSectionNumber"?: number;
  "street"?: string;
  "houseNumber"?: string;
  "houseNumberAdditional"?: string;
  "community"?: string;
  "city"?: string;
  "postalCode"?: string;
  "numbers"?: any;
  "provider": string;
  "environment": string;
  "id"?: any;
  "organizationId"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "custom"?: any;
  "deletedAt"?: Date;
  "_isDeleted"?: boolean;
  "deletedId"?: string;
  organization?: Organization;
  electronicDevices?: ElectronicDevice[];
}

export class BusinessPremise implements BusinessPremiseInterface {
  "type": string;
  "taxNumber": string;
  "businessPremiseId": string;
  "premiseType": string;
  "cadastralNumber": number;
  "buildingNumber": number;
  "buildingSectionNumber": number;
  "street": string;
  "houseNumber": string;
  "houseNumberAdditional": string;
  "community": string;
  "city": string;
  "postalCode": string;
  "numbers": any;
  "provider": string;
  "environment": string;
  "id": any;
  "organizationId": any;
  "createdAt": Date;
  "updatedAt": Date;
  "custom": any;
  "deletedAt": Date;
  "_isDeleted": boolean;
  "deletedId": string;
  organization: Organization;
  electronicDevices: ElectronicDevice[];
  constructor(data?: BusinessPremiseInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `BusinessPremise`.
   */
  public static getModelName() {
    return "BusinessPremise";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of BusinessPremise for dynamic purposes.
  **/
  public static factory(data: BusinessPremiseInterface): BusinessPremise{
    return new BusinessPremise(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'BusinessPremise',
      plural: 'BusinessPremises',
      path: 'BusinessPremises',
      idName: 'id',
      properties: {
        "type": {
          name: 'type',
          type: 'string'
        },
        "taxNumber": {
          name: 'taxNumber',
          type: 'string'
        },
        "businessPremiseId": {
          name: 'businessPremiseId',
          type: 'string'
        },
        "premiseType": {
          name: 'premiseType',
          type: 'string'
        },
        "cadastralNumber": {
          name: 'cadastralNumber',
          type: 'number'
        },
        "buildingNumber": {
          name: 'buildingNumber',
          type: 'number'
        },
        "buildingSectionNumber": {
          name: 'buildingSectionNumber',
          type: 'number'
        },
        "street": {
          name: 'street',
          type: 'string'
        },
        "houseNumber": {
          name: 'houseNumber',
          type: 'string'
        },
        "houseNumberAdditional": {
          name: 'houseNumberAdditional',
          type: 'string'
        },
        "community": {
          name: 'community',
          type: 'string'
        },
        "city": {
          name: 'city',
          type: 'string'
        },
        "postalCode": {
          name: 'postalCode',
          type: 'string'
        },
        "numbers": {
          name: 'numbers',
          type: 'any'
        },
        "provider": {
          name: 'provider',
          type: 'string',
          default: 'furs'
        },
        "environment": {
          name: 'environment',
          type: 'string',
          default: 'production'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "organizationId": {
          name: 'organizationId',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        "custom": {
          name: 'custom',
          type: 'any',
          default: <any>null
        },
        "deletedAt": {
          name: 'deletedAt',
          type: 'Date'
        },
        "_isDeleted": {
          name: '_isDeleted',
          type: 'boolean'
        },
        "deletedId": {
          name: 'deletedId',
          type: 'string'
        },
      },
      relations: {
        organization: {
          name: 'organization',
          type: 'Organization',
          model: 'Organization',
          relationType: 'belongsTo',
                  keyFrom: 'organizationId',
          keyTo: 'id'
        },
        electronicDevices: {
          name: 'electronicDevices',
          type: 'ElectronicDevice[]',
          model: 'ElectronicDevice',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'businessPremiseId'
        },
      }
    }
  }
}
