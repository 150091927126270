import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Decimal from 'decimal.js/decimal.js';
import { ToastService } from '../shared/modules/toast/toast.service';
import { LoggerService, LoopBackConfig } from '../shared/sdk';
import {
  DocumentItem,
  DocumentItemTax,
  Item,
  LoopBackFilter,
} from '../shared/sdk/models';
import { ItemApi, OrganizationApi } from '../shared/sdk/services';

Decimal.set({ precision: 13, rounding: 4 });
const Decimal5 = Decimal.clone({ precision: 5, rounding: 4 }); // tslint:disable-line

import * as moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { API_VERSION, BASE_URL } from '../shared/base-url';
import { ApiService } from '../shared/services/api.service';
import { CacheService } from '../shared/services/cache.service';
import { FileUploadService } from '../shared/services/file-upload.service';
import { LanguageService } from '../shared/services/language.service';

@Injectable()
export class ItemService extends ApiService<Item> {
  constructor(
    protected cacheService: CacheService,
    protected log: LoggerService,
    protected toastService: ToastService,
    protected translate: TranslateService,
    private itemApi: ItemApi,
    private fileUploadService: FileUploadService,
    private languageService: LanguageService,
    private orgApi: OrganizationApi,
  ) {
    super(cacheService, log, toastService, translate);
    this.name = 'Item';
    this.plural = 'Items';

    LoopBackConfig.setBaseURL(BASE_URL);
    LoopBackConfig.setApiVersion(API_VERSION);
  }

  calculateTotal(item: Item, dp: number, usePrice = -1): number {
    const isPriceGross = typeof item.priceGross !== 'undefined';

    let priceField: string;
    let itemTotal: Decimal;

    if (
      usePrice === -1 ||
      !item.prices ||
      !item.prices.length ||
      !item.prices[usePrice]
    ) {
      priceField = !isPriceGross ? 'price' : 'priceGross';
      itemTotal = new Decimal(item[priceField] || 0);
    } else {
      itemTotal = new Decimal(item.prices[usePrice].amount || 0);
    }

    let total = new Decimal(0).plus(itemTotal);

    if (!isPriceGross && item.taxIds) {
      item.taxIds.forEach((taxId) => {
        if (taxId !== '' && item.taxes) {
          const tax = item.taxes.find((t) => t.id === taxId);

          if (typeof tax !== 'undefined') {
            const rateLength = tax._taxRates.length;
            const rate = tax._taxRates[rateLength - 1].rate;

            const taxVal = itemTotal
              .times(new Decimal5(rate).div(100))
              .toDP(dp, Decimal.ROUND_HALF_UP);

            total = total.plus(taxVal);
          }
        }
      });
    }

    return total.toDP(dp, Decimal.ROUND_HALF_UP).toNumber();
  }

  count(
    orgId: string,
    filter: any = {},
    force = false,
    toastr = true,
  ): Observable<number> {
    return super.count(orgId, filter, force, toastr, this.orgApi);
  }

  create(orgId: string, data: any, toastr = true): Observable<Item> {
    data = this.format(data);
    return super.create(orgId, data, toastr, this.orgApi);
  }

  delete(id: string, toastr = true): Observable<boolean> {
    return super.delete(id, toastr, this.itemApi);
  }

  deleteWithWhere(orgId: string, where: any): Observable<any> {
    let showedToast = false;

    return this.get(
      orgId,
      {
        where,
      },
      true,
    ).pipe(
      map(({ body }) => body),
      switchMap((items) => {
        const subs = [];

        for (const item of items) {
          subs.push(this.delete(item.id, false));
        }

        if (!subs.length) {
          return of(null);
        }

        return forkJoin(subs);
      }),
      tap(() => {
        if (!showedToast) {
          showedToast = true;
          this.toastService.success(
            this.translate.instant('Items successfully deleted'),
          );
        }
      }),
      catchError((err) => {
        if (!showedToast) {
          showedToast = true;
          this.toastService.error(
            this.translate.instant('Error deleting some items'),
          );
        }

        return err;
      }),
    );
  }

  exportInventory(
    orgId: string,
    date: string | moment.Moment,
    lang: string,
  ): Observable<{ success: boolean }> {
    date = moment(date);
    const dateF = date.format('Y-MM-DD');

    // const url =
    //   `${BASE_URL}/${API_VERSION}/organizations/${orgId}/export?type=inventory` +
    //   `&access_token=${
    //     this.accountApi.getCurrentToken().id
    //   }&lang=${lang}&where[date]=${dateF}`;

    // window.open(url);

    return super.downloadDeferred(
      this.orgApi.export(orgId, 'inventory', {
        date: dateF,
      }),
    );
  }

  format(item: any): Item {
    let priceField: string;

    if (typeof item.price !== 'undefined') {
      priceField = 'price';
    } else {
      priceField = 'priceGross';
    }

    item[priceField] = item[priceField] || 0;
    item[priceField] = this.languageService.unformatNumber(item[priceField]);

    item.taxIds = item.taxIds || [];
    item.purchaseTaxIds = item.purchaseTaxIds || [];

    if (Array.isArray(item._documentItemTaxes)) {
      item._documentItemTaxes.map(
        (tax: DocumentItemTax) => (item.taxIds = [...item.taxIds, tax.taxId]),
      );
    }

    return item;
  }

  formatNumbers(item: any, u = true) {
    if (!item) {
      return item;
    }
    const newI: any = {};

    if (typeof item.price !== 'undefined') {
      newI.price = this.languageService[u ? 'u' : 'f'](item.price);
    }

    if (typeof item.priceGross !== 'undefined') {
      newI.priceGross = this.languageService[u ? 'u' : 'f'](item.priceGross);
    }

    if (typeof item.quantity !== 'undefined') {
      newI.quantity = this.languageService[u ? 'u' : 'f'](item.quantity);
    }

    if (typeof item.discount !== 'undefined') {
      newI.discount = this.languageService[u ? 'u' : 'f'](item.discount);
    }

    if (typeof item.total !== 'undefined') {
      newI.total = this.languageService[u ? 'u' : 'f'](item.total);
    }

    if (typeof item.totalWithTax !== 'undefined') {
      newI.totalWithTax = this.languageService[u ? 'u' : 'f'](
        item.totalWithTax,
      );
    }

    newI._documentItemTaxes = this.formatTaxes(item._documentItemTaxes);

    return newI;
  }

  formatTax(tax: any, u = true) {
    if (!tax || (tax.rate === 0 && tax.tax === 0)) {
      return false;
    }

    const newT: any = {};

    if (typeof tax.rate !== 'undefined') {
      newT.rate = this.languageService[u ? 'u' : 'f'](tax.rate);
    }

    if (typeof tax.tax !== 'undefined') {
      newT.tax = this.languageService[u ? 'u' : 'f'](tax.tax);
    }

    if (typeof tax.base !== 'undefined') {
      newT.base = this.languageService[u ? 'u' : 'f'](tax.base);
    }

    if (typeof tax.totalTax !== 'undefined') {
      newT.totalTax = this.languageService[u ? 'u' : 'f'](tax.totalTax);
    }

    return newT;
  }

  formatTaxes(taxes: any, u = true) {
    if (!taxes) {
      return taxes;
    }
    const _taxes = [];

    for (let i = 0; i < taxes.length; i++) {
      const tax = taxes[i];
      const newT = this.formatTax(taxes[i], u);
      if (newT) {
        _taxes.push(Object.assign(tax, newT));
      }
    }

    return _taxes;
  }

  exportItems(orgId: string, lang: string): Observable<{ success: boolean }> {
    return super.downloadDeferred(this.orgApi.export(orgId, 'item'));
  }

  find(
    orgId: string,
    term: string,
    type: string = null,
  ): Observable<{ body: Item[]; totalCount: number }> {
    this.log.log(`${this.name}Service: find`);

    if (term === '') {
      return of({ body: [], totalCount: 0 });
    }

    return this.orgApi.searchItems(orgId, term, type);
  }

  findInventory(item: Item, warehouseId?: string): number {
    if (!item.trackInventory) {
      return;
    }

    let qty = 0;

    if (Array.isArray(item.inventories)) {
      if (warehouseId) {
        const inventory = item.inventories.find(
          (i) => i.warehouseId === warehouseId,
        );

        qty = inventory ? inventory.amount : 0;
      } else {
        qty = item.inventories.reduce((a, { amount }) => {
          amount = amount || 0;
          return a + amount;
        }, 0);
      }
    }

    return qty;
  }

  get(
    orgId: string,
    filter: any = {},
    force = false,
    toastr = true,
  ): Observable<{ body: Item[]; totalCount: number }> {
    return super.get(orgId, filter, force, toastr, this.orgApi);
  }

  getById(
    id: string,
    filter = {},
    force = true,
    toastr = true,
  ): Observable<Item> {
    return super.getById(id, filter, force, toastr, this.itemApi);
  }

  getInventory(id: string, filter?: LoopBackFilter): Observable<any> {
    this.log.log('getInventory');
    const cacheKey = `${this.name}_${id}_${JSON.stringify(filter)}`;
    return this.cachedReq(cacheKey, this.itemApi.getInventories(id, filter));
  }

  import(orgId: string, file: File): Observable<any> {
    return this.fileUploadService
      .upload(file, `/organizations/${orgId}/import?type=item`)
      .pipe(
        tap((res) => {
          if (!res.success) {
            throw new Error('import failed');
          }

          this.toastService.success(
            this.translate.instant('item-service.import.success'),
          );
        }),
        catchError((err) => {
          this.toastService.error(
            this.translate.instant('item-service.import.error'),
          );
          return err;
        }),
      );
  }

  isSeparator(item: DocumentItem): boolean {
    return !item.price && !item.quantity && !item.unit;
  }

  update(id: string, data: any, toastr = true): Observable<Item> {
    data = this.format(data);
    return super.update(id, data, toastr, this.itemApi);
  }
}
