/* tslint:disable */
import {
  Organization
} from '../index';

declare var Object: any;
export interface DefaultInterface {
  "name": string;
  "value"?: any;
  "description"?: string;
  "id"?: any;
  "organizationId"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "l10n"?: any;
  organization?: Organization;
}

export class Default implements DefaultInterface {
  "name": string;
  "value": any;
  "description": string;
  "id": any;
  "organizationId": any;
  "createdAt": Date;
  "updatedAt": Date;
  "l10n": any;
  organization: Organization;
  constructor(data?: DefaultInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Default`.
   */
  public static getModelName() {
    return "Default";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Default for dynamic purposes.
  **/
  public static factory(data: DefaultInterface): Default{
    return new Default(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Default',
      plural: 'Defaults',
      path: 'Defaults',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "value": {
          name: 'value',
          type: 'any'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "organizationId": {
          name: 'organizationId',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        "l10n": {
          name: 'l10n',
          type: 'any'
        },
      },
      relations: {
        organization: {
          name: 'organization',
          type: 'Organization',
          model: 'Organization',
          relationType: 'belongsTo',
                  keyFrom: 'organizationId',
          keyTo: 'id'
        },
      }
    }
  }
}
