import { Injectable } from '@angular/core';
import { LoopBackConfig, LoggerService, Subscription } from '../shared/sdk';
import { OrganizationApi } from '../shared/sdk/services';

import { BASE_URL, API_VERSION } from '../shared/base-url';
import { ApiService } from '../shared/services/api.service';
import { ToastService } from '../shared/modules/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { CacheService } from '../shared/services/cache.service';
import { map } from 'rxjs/operators';
import { WhiteLabelService } from '../shared/services/whitelabel.service';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable()
export class SubscriptionService extends ApiService<Subscription> {
  name: string;
  plural: string;

  public _active: Subscription;
  private active$: ReplaySubject<Subscription> = new ReplaySubject(1);
  readonly active: Observable<Subscription> = this.active$.asObservable();

  constructor(
    protected cacheService: CacheService,
    protected log: LoggerService,
    protected toastService: ToastService,
    protected translate: TranslateService,
    private organizationApi: OrganizationApi,
    private whiteLabelService: WhiteLabelService,
  ) {
    super(cacheService, log, toastService, translate);
    this.name = 'Subscription';
    this.plural = 'Subscriptions';
    this.ttl = 600000;

    LoopBackConfig.setBaseURL(BASE_URL);
    LoopBackConfig.setApiVersion(API_VERSION);
  }

  getActive(orgId: string): Observable<Subscription> {
    this.log.log(`${this.name}Service: getActive`);
    const cacheKey = `${orgId}_${this.name}_getActive`;

    const method = this.organizationApi.subscription(orgId);

    return this.cachedReq(cacheKey, method, true).pipe(
      map(({ body }) => body),
      map((sub) => {
        this.setActive(sub);
        return sub;
      }),
    );
  }

  private setActive(sub: Subscription): void {
    // Check if whitelabel
    const domain =
      sub && sub.whiteLabel && sub.whiteLabel.domain
        ? sub.whiteLabel.domain
        : undefined;

    this.whiteLabelService.setProperties(
      sub && sub.whiteLabel ? sub.whiteLabel : {},
    );
    this.whiteLabelService.setIsWhiteLabel(domain);

    this._active = sub;
    this.active$.next(this._active);
  }
}
