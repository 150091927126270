/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Shopamine } from '../models/shopamine';

@Injectable({
  providedIn: 'root',
})
export class ShopamineService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation shopamineDeleteById
   */
  static readonly ShopamineDeleteByIdPath = '/v1/Shopamine/{id}';

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopamineDeleteById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopamineDeleteById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopamineDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopamineDeleteById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopamineDeleteById$Json(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopamineDeleteById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopamineDeleteById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopamineDeleteById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopamineDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopamineDeleteById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopamineDeleteById$Xml(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopamineDeleteById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopamineDeleteById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopamineDeleteById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopamineDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopamineDeleteById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopamineDeleteById$Javascript(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopamineDeleteById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation shopaminePrototypePatchAttributes
   */
  static readonly ShopaminePrototypePatchAttributesPath = '/v1/Shopamine/{id}';

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopaminePrototypePatchAttributes$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopaminePrototypePatchAttributes$Json$Json$Response(params: {

    /**
     * Shopamine id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopamine>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopaminePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopamine>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopaminePrototypePatchAttributes$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopaminePrototypePatchAttributes$Json$Json(params: {

    /**
     * Shopamine id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopamine
  },
  context?: HttpContext

): Observable<Shopamine> {

    return this.shopaminePrototypePatchAttributes$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopamine>) => r.body as Shopamine)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopaminePrototypePatchAttributes$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopaminePrototypePatchAttributes$Json$Xml$Response(params: {

    /**
     * Shopamine id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopamine>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopaminePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopamine>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopaminePrototypePatchAttributes$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopaminePrototypePatchAttributes$Json$Xml(params: {

    /**
     * Shopamine id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopamine
  },
  context?: HttpContext

): Observable<Shopamine> {

    return this.shopaminePrototypePatchAttributes$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopamine>) => r.body as Shopamine)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopaminePrototypePatchAttributes$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopaminePrototypePatchAttributes$Json$Javascript$Response(params: {

    /**
     * Shopamine id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopamine>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopaminePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopamine>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopaminePrototypePatchAttributes$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopaminePrototypePatchAttributes$Json$Javascript(params: {

    /**
     * Shopamine id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopamine
  },
  context?: HttpContext

): Observable<Shopamine> {

    return this.shopaminePrototypePatchAttributes$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopamine>) => r.body as Shopamine)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopaminePrototypePatchAttributes$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopaminePrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Shopamine id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopamine>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopaminePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopamine>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopaminePrototypePatchAttributes$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopaminePrototypePatchAttributes$XWwwFormUrlencoded$Json(params: {

    /**
     * Shopamine id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopamine
  },
  context?: HttpContext

): Observable<Shopamine> {

    return this.shopaminePrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopamine>) => r.body as Shopamine)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopaminePrototypePatchAttributes$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopaminePrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Shopamine id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopamine>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopaminePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopamine>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopaminePrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopaminePrototypePatchAttributes$XWwwFormUrlencoded$Xml(params: {

    /**
     * Shopamine id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopamine
  },
  context?: HttpContext

): Observable<Shopamine> {

    return this.shopaminePrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopamine>) => r.body as Shopamine)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopaminePrototypePatchAttributes$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopaminePrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Shopamine id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopamine>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopaminePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopamine>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopaminePrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopaminePrototypePatchAttributes$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Shopamine id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopamine
  },
  context?: HttpContext

): Observable<Shopamine> {

    return this.shopaminePrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopamine>) => r.body as Shopamine)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopaminePrototypePatchAttributes$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopaminePrototypePatchAttributes$Xml$Json$Response(params: {

    /**
     * Shopamine id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopamine>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopaminePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopamine>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopaminePrototypePatchAttributes$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopaminePrototypePatchAttributes$Xml$Json(params: {

    /**
     * Shopamine id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopamine
  },
  context?: HttpContext

): Observable<Shopamine> {

    return this.shopaminePrototypePatchAttributes$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopamine>) => r.body as Shopamine)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopaminePrototypePatchAttributes$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopaminePrototypePatchAttributes$Xml$Xml$Response(params: {

    /**
     * Shopamine id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopamine>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopaminePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopamine>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopaminePrototypePatchAttributes$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopaminePrototypePatchAttributes$Xml$Xml(params: {

    /**
     * Shopamine id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopamine
  },
  context?: HttpContext

): Observable<Shopamine> {

    return this.shopaminePrototypePatchAttributes$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopamine>) => r.body as Shopamine)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopaminePrototypePatchAttributes$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopaminePrototypePatchAttributes$Xml$Javascript$Response(params: {

    /**
     * Shopamine id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopamine>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopaminePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopamine>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopaminePrototypePatchAttributes$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopaminePrototypePatchAttributes$Xml$Javascript(params: {

    /**
     * Shopamine id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Shopamine
  },
  context?: HttpContext

): Observable<Shopamine> {

    return this.shopaminePrototypePatchAttributes$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopamine>) => r.body as Shopamine)
    );
  }

  /**
   * Path part for operation shopaminePrototypeProcessOrder
   */
  static readonly ShopaminePrototypeProcessOrderPath = '/v1/Shopamine/{id}/order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopaminePrototypeProcessOrder$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopaminePrototypeProcessOrder$Json$Json$Response(params: {

    /**
     * Shopamine id
     */
    id: string;
    body?: Shopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopaminePrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopaminePrototypeProcessOrder$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopaminePrototypeProcessOrder$Json$Json(params: {

    /**
     * Shopamine id
     */
    id: string;
    body?: Shopamine
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopaminePrototypeProcessOrder$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopaminePrototypeProcessOrder$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopaminePrototypeProcessOrder$Json$Xml$Response(params: {

    /**
     * Shopamine id
     */
    id: string;
    body?: Shopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopaminePrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopaminePrototypeProcessOrder$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopaminePrototypeProcessOrder$Json$Xml(params: {

    /**
     * Shopamine id
     */
    id: string;
    body?: Shopamine
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopaminePrototypeProcessOrder$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopaminePrototypeProcessOrder$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopaminePrototypeProcessOrder$Json$Javascript$Response(params: {

    /**
     * Shopamine id
     */
    id: string;
    body?: Shopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopaminePrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopaminePrototypeProcessOrder$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopaminePrototypeProcessOrder$Json$Javascript(params: {

    /**
     * Shopamine id
     */
    id: string;
    body?: Shopamine
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopaminePrototypeProcessOrder$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopaminePrototypeProcessOrder$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopaminePrototypeProcessOrder$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Shopamine id
     */
    id: string;
    body?: Shopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopaminePrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopaminePrototypeProcessOrder$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopaminePrototypeProcessOrder$XWwwFormUrlencoded$Json(params: {

    /**
     * Shopamine id
     */
    id: string;
    body?: Shopamine
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopaminePrototypeProcessOrder$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopaminePrototypeProcessOrder$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopaminePrototypeProcessOrder$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Shopamine id
     */
    id: string;
    body?: Shopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopaminePrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopaminePrototypeProcessOrder$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopaminePrototypeProcessOrder$XWwwFormUrlencoded$Xml(params: {

    /**
     * Shopamine id
     */
    id: string;
    body?: Shopamine
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopaminePrototypeProcessOrder$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopaminePrototypeProcessOrder$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopaminePrototypeProcessOrder$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Shopamine id
     */
    id: string;
    body?: Shopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopaminePrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopaminePrototypeProcessOrder$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopaminePrototypeProcessOrder$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Shopamine id
     */
    id: string;
    body?: Shopamine
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopaminePrototypeProcessOrder$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopaminePrototypeProcessOrder$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopaminePrototypeProcessOrder$Xml$Json$Response(params: {

    /**
     * Shopamine id
     */
    id: string;
    body?: Shopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopaminePrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopaminePrototypeProcessOrder$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopaminePrototypeProcessOrder$Xml$Json(params: {

    /**
     * Shopamine id
     */
    id: string;
    body?: Shopamine
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopaminePrototypeProcessOrder$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopaminePrototypeProcessOrder$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopaminePrototypeProcessOrder$Xml$Xml$Response(params: {

    /**
     * Shopamine id
     */
    id: string;
    body?: Shopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopaminePrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopaminePrototypeProcessOrder$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopaminePrototypeProcessOrder$Xml$Xml(params: {

    /**
     * Shopamine id
     */
    id: string;
    body?: Shopamine
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopaminePrototypeProcessOrder$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopaminePrototypeProcessOrder$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopaminePrototypeProcessOrder$Xml$Javascript$Response(params: {

    /**
     * Shopamine id
     */
    id: string;
    body?: Shopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopaminePrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopaminePrototypeProcessOrder$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  shopaminePrototypeProcessOrder$Xml$Javascript(params: {

    /**
     * Shopamine id
     */
    id: string;
    body?: Shopamine
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopaminePrototypeProcessOrder$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation shopaminePrototypeImport
   */
  static readonly ShopaminePrototypeImportPath = '/v1/Shopamine/{id}/import';

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopaminePrototypeImport$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopaminePrototypeImport$Json$Response(params: {

    /**
     * Shopamine id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopaminePrototypeImportPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopaminePrototypeImport$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopaminePrototypeImport$Json(params: {

    /**
     * Shopamine id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopaminePrototypeImport$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopaminePrototypeImport$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopaminePrototypeImport$Xml$Response(params: {

    /**
     * Shopamine id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopaminePrototypeImportPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopaminePrototypeImport$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopaminePrototypeImport$Xml(params: {

    /**
     * Shopamine id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopaminePrototypeImport$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopaminePrototypeImport$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopaminePrototypeImport$Javascript$Response(params: {

    /**
     * Shopamine id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ShopamineService.ShopaminePrototypeImportPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopaminePrototypeImport$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  shopaminePrototypeImport$Javascript(params: {

    /**
     * Shopamine id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.shopaminePrototypeImport$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

}
