import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { LoggerService } from '../shared/sdk';
import { ReferralService } from './referral.service';

@Injectable()
export class ReferralResolver implements Resolve<string> {
  private name = 'ReferralResolver';
  constructor(
    private log: LoggerService,
    private referralService: ReferralService,
  ) { }

  resolve(route: ActivatedRouteSnapshot): string {
    this.log.log(`${this.name} resolve`);

    // On run check if param is present, later checking not needed
    let ref = route.queryParams['ref'];
    this.log.log(this.name, ref);

    this.referralService.init(ref);

    return ref;
  }
}
