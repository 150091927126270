import { Injectable } from '@angular/core';
import { LoopBackConfig, LoggerService } from '../shared/sdk';
import {
  RecurrenceApi,
  OrganizationApi,
  DocumentApi,
} from '../shared/sdk/services';
import { Recurrence } from '../shared/sdk/models';
import { ToastService } from '../shared/modules/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';

import { BASE_URL, API_VERSION } from '../shared/base-url';
import { ApiService } from '../shared/services/api.service';
import { CacheService } from '../shared/services/cache.service';
import { Observable } from 'rxjs';

export const recurrenceTypes = [
  {
    value: 'week',
    name: 'Weekly',
  },
  {
    value: 'month',
    name: 'Monthly',
  },
  {
    value: 'year',
    name: 'Yearly',
  },
];

@Injectable()
export class RecurrenceService extends ApiService<Recurrence> {
  constructor(
    public translate: TranslateService,
    protected cacheService: CacheService,
    protected log: LoggerService,
    protected toastService: ToastService,
    private documentApi: DocumentApi,
    private orgApi: OrganizationApi,
    private recurrenceApi: RecurrenceApi,
  ) {
    super(cacheService, log, toastService, translate);
    this.name = 'Recurrence';
    this.plural = 'Recurrences';

    LoopBackConfig.setBaseURL(BASE_URL);
    LoopBackConfig.setApiVersion(API_VERSION);
  }

  count(
    orgId: string,
    filter: any = {},
    force = false,
    toastr = true,
  ): Observable<number> {
    return super.count(orgId, filter, force, toastr, this.orgApi);
  }

  create(documentId: string, data: any, toastr = true): Observable<Recurrence> {
    return super.create(documentId, data, toastr, this.documentApi, false);
  }

  delete(id: string, toastr = true): Observable<boolean> {
    return super.delete(id, toastr, this.recurrenceApi);
  }

  get(
    orgId: string,
    filter: any = {},
    force = false,
    toastr = true,
  ): Observable<{ body: Recurrence[]; totalCount: number }> {
    return super.get(orgId, filter, force, toastr, this.orgApi);
  }

  getById(
    id: string,
    filter = {},
    force = true,
    toastr = true,
  ): Observable<Recurrence> {
    return super.getById(id, filter, force, toastr, this.recurrenceApi);
  }

  update(id: string, data: any, toastr = true): Observable<Recurrence> {
    return super.update(id, data, toastr, this.recurrenceApi);
  }
}
