/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NewStripeConnectAccountInOrganization } from '../models/new-stripe-connect-account-in-organization';
import { StripeConnectAccount } from '../models/stripe-connect-account';

@Injectable({
  providedIn: 'root',
})
export class OrganizationStripeConnectAccountControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation organizationStripeConnectAccountControllerFind
   */
  static readonly OrganizationStripeConnectAccountControllerFindPath = '/v1/organizations/{id}/stripe-connect/account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params: {
    id: string;
    filter?: any;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<StripeConnectAccount>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationStripeConnectAccountControllerService.OrganizationStripeConnectAccountControllerFindPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StripeConnectAccount>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params: {
    id: string;
    filter?: any;
  },
  context?: HttpContext

): Observable<StripeConnectAccount> {

    return this.find$Response(params,context).pipe(
      map((r: StrictHttpResponse<StripeConnectAccount>) => r.body as StripeConnectAccount)
    );
  }

  /**
   * Path part for operation organizationStripeConnectAccountControllerCreate
   */
  static readonly OrganizationStripeConnectAccountControllerCreatePath = '/v1/organizations/{id}/stripe-connect/account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: {
    id: string;
    body?: NewStripeConnectAccountInOrganization
  },
  context?: HttpContext

): Observable<StrictHttpResponse<StripeConnectAccount>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationStripeConnectAccountControllerService.OrganizationStripeConnectAccountControllerCreatePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StripeConnectAccount>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: {
    id: string;
    body?: NewStripeConnectAccountInOrganization
  },
  context?: HttpContext

): Observable<StripeConnectAccount> {

    return this.create$Response(params,context).pipe(
      map((r: StrictHttpResponse<StripeConnectAccount>) => r.body as StripeConnectAccount)
    );
  }

}
