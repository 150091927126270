import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { StorageService } from './storage.service';
import { Observable, ReplaySubject } from 'rxjs';
// import { MediaMatcher } from '@angular/cdk/layout';
import { WhiteLabelService } from './whitelabel.service';

@Injectable()
export class ThemeService {
  active: string;
  darkObs: any;
  themes: any = {};
  isSafariDark: boolean;
  isSafariLight: boolean;
  isSafariColorNotSpecified: boolean;
  isWL = false;
  _dark = false;
  private dark$: ReplaySubject<boolean> = new ReplaySubject(1);
  readonly dark: Observable<boolean> = this.dark$.asObservable();
  // private safariDarkMatcher: MediaQueryList;
  private storageKey = 'Apollo::dark';

  constructor(
    // private mediaMatcher: MediaMatcher,
    private storageService: StorageService,
    private whiteLabelService: WhiteLabelService,
    @Inject(DOCUMENT) private document,
  ) {
    this.themes.dashkit = this.document.getElementById(
      'theme-dashkit-css',
    ) as HTMLElement;

    this.themes.dashkitDark = this.document.getElementById(
      'theme-dashkit-dark-css',
    ) as HTMLElement;

    // Browser dark mode
    // this.safariDarkMatcher = this.mediaMatcher.matchMedia(
    //   '(prefers-color-scheme: dark)',
    // );

    this._dark = this.storageService.getItem(this.storageKey);

    // if (this._dark === null) {
    //   this._dark = this.safariDarkMatcher.matches;
    // }

    // Init dark value based on loaded data
    this.dark$.next(this._dark);

    this.whiteLabelService.isWhiteLabel.subscribe((isWL) => {
      this.isWL = isWL;

      if (this.isWL && this._dark) {
        this.toggleDark();
      }
    });

    // Browser dark mode listener
    // Disabled as some users were reporing problems
    // this.safariDarkMatcher.addListener(dark => {
    //   if (!this.isWL && dark.matches !== this._dark) {
    //     this.toggleDark(false);
    //   }
    // });
  }

  get() {
    return this.active;
  }

  isDark() {
    return this.dark;
  }

  set(name: string) {
    if (name === 'front') {
      // this.themes[name].disabled = false;
      // this.themes['dashkit'].disabled = true;
      // this.themes['dashkitDark'].disabled = true;
    } else {
      if (this._dark) {
        // this.themes['dashkitDark'].disabled = false; // TODO: fix
        this.themes['dashkit'].disabled = true;
      } else {
        this.themes['dashkit'].disabled = false;
        // this.themes['dashkitDark'].disabled = true; // TODO: fix
      }

      // this.themes['front'].disabled = true;
    }

    this.active = name;
  }

  toggleDark(save = true) {
    this._dark = !this._dark;
    this.dark$.next(this._dark);

    this.set(this.get());

    if (!save) {
      if (this._dark) {
        this.storageService.setItem(this.storageKey, 'true');
      } else {
        this.storageService.removeItem(this.storageKey);
      }
    } else {
      this.storageService.setItem(this.storageKey, String(this._dark));
    }
  }
}
