/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Price } from '../models/price';

@Injectable({
  providedIn: 'root',
})
export class PriceService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation priceDeleteById
   */
  static readonly PriceDeleteByIdPath = '/v1/Prices/{id}';

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceDeleteById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceDeleteById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, PriceService.PriceDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceDeleteById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceDeleteById$Json(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.priceDeleteById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceDeleteById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceDeleteById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, PriceService.PriceDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceDeleteById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceDeleteById$Xml(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.priceDeleteById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `priceDeleteById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceDeleteById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, PriceService.PriceDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `priceDeleteById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  priceDeleteById$Javascript(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.priceDeleteById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation pricePrototypePatchAttributes
   */
  static readonly PricePrototypePatchAttributesPath = '/v1/Prices/{id}';

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pricePrototypePatchAttributes$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pricePrototypePatchAttributes$Json$Json$Response(params: {

    /**
     * Price id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Price
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Price>> {

    const rb = new RequestBuilder(this.rootUrl, PriceService.PricePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Price>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pricePrototypePatchAttributes$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pricePrototypePatchAttributes$Json$Json(params: {

    /**
     * Price id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Price
  },
  context?: HttpContext

): Observable<Price> {

    return this.pricePrototypePatchAttributes$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Price>) => r.body as Price)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pricePrototypePatchAttributes$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pricePrototypePatchAttributes$Json$Xml$Response(params: {

    /**
     * Price id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Price
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Price>> {

    const rb = new RequestBuilder(this.rootUrl, PriceService.PricePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Price>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pricePrototypePatchAttributes$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pricePrototypePatchAttributes$Json$Xml(params: {

    /**
     * Price id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Price
  },
  context?: HttpContext

): Observable<Price> {

    return this.pricePrototypePatchAttributes$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Price>) => r.body as Price)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pricePrototypePatchAttributes$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pricePrototypePatchAttributes$Json$Javascript$Response(params: {

    /**
     * Price id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Price
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Price>> {

    const rb = new RequestBuilder(this.rootUrl, PriceService.PricePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Price>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pricePrototypePatchAttributes$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pricePrototypePatchAttributes$Json$Javascript(params: {

    /**
     * Price id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Price
  },
  context?: HttpContext

): Observable<Price> {

    return this.pricePrototypePatchAttributes$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Price>) => r.body as Price)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pricePrototypePatchAttributes$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  pricePrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Price id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Price
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Price>> {

    const rb = new RequestBuilder(this.rootUrl, PriceService.PricePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Price>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pricePrototypePatchAttributes$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  pricePrototypePatchAttributes$XWwwFormUrlencoded$Json(params: {

    /**
     * Price id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Price
  },
  context?: HttpContext

): Observable<Price> {

    return this.pricePrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Price>) => r.body as Price)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pricePrototypePatchAttributes$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  pricePrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Price id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Price
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Price>> {

    const rb = new RequestBuilder(this.rootUrl, PriceService.PricePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Price>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pricePrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  pricePrototypePatchAttributes$XWwwFormUrlencoded$Xml(params: {

    /**
     * Price id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Price
  },
  context?: HttpContext

): Observable<Price> {

    return this.pricePrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Price>) => r.body as Price)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pricePrototypePatchAttributes$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  pricePrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Price id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Price
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Price>> {

    const rb = new RequestBuilder(this.rootUrl, PriceService.PricePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Price>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pricePrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  pricePrototypePatchAttributes$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Price id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Price
  },
  context?: HttpContext

): Observable<Price> {

    return this.pricePrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Price>) => r.body as Price)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pricePrototypePatchAttributes$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  pricePrototypePatchAttributes$Xml$Json$Response(params: {

    /**
     * Price id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Price
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Price>> {

    const rb = new RequestBuilder(this.rootUrl, PriceService.PricePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Price>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pricePrototypePatchAttributes$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  pricePrototypePatchAttributes$Xml$Json(params: {

    /**
     * Price id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Price
  },
  context?: HttpContext

): Observable<Price> {

    return this.pricePrototypePatchAttributes$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Price>) => r.body as Price)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pricePrototypePatchAttributes$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  pricePrototypePatchAttributes$Xml$Xml$Response(params: {

    /**
     * Price id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Price
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Price>> {

    const rb = new RequestBuilder(this.rootUrl, PriceService.PricePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Price>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pricePrototypePatchAttributes$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  pricePrototypePatchAttributes$Xml$Xml(params: {

    /**
     * Price id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Price
  },
  context?: HttpContext

): Observable<Price> {

    return this.pricePrototypePatchAttributes$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Price>) => r.body as Price)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pricePrototypePatchAttributes$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  pricePrototypePatchAttributes$Xml$Javascript$Response(params: {

    /**
     * Price id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Price
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Price>> {

    const rb = new RequestBuilder(this.rootUrl, PriceService.PricePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Price>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pricePrototypePatchAttributes$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  pricePrototypePatchAttributes$Xml$Javascript(params: {

    /**
     * Price id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Price
  },
  context?: HttpContext

): Observable<Price> {

    return this.pricePrototypePatchAttributes$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Price>) => r.body as Price)
    );
  }

}
