/* tslint:disable */
import {
  Subscription
} from '../index';

declare var Object: any;
export interface WhiteLabelInterface {
  "active"?: boolean;
  "name"?: string;
  "domain"?: string;
  "subdomain"?: string;
  "baseColor"?: string;
  "logo"?: string;
  "separateDashboardIcon"?: boolean;
  "googleAnalyticsId"?: string;
  "chatAppType"?: string;
  "chatAppId"?: string;
  "privacyPolicyLink"?: string;
  "termsOfServiceLink"?: string;
  "websiteURL"?: string;
  "supportEmail"?: string;
  "supportPhone"?: string;
  "blogURL"?: string;
  "hideLoginPage"?: boolean;
  "showSignup"?: boolean;
  "unauthorizedRedirectURL"?: string;
  "passwordResetURL"?: string;
  "signupURL"?: string;
  "allowInPromo"?: boolean;
  "interface"?: any;
  "id"?: any;
  "subscriptionId"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  subscription?: Subscription;
}

export class WhiteLabel implements WhiteLabelInterface {
  "active": boolean;
  "name": string;
  "domain": string;
  "subdomain": string;
  "baseColor": string;
  "logo": string;
  "separateDashboardIcon": boolean;
  "googleAnalyticsId": string;
  "chatAppType": string;
  "chatAppId": string;
  "privacyPolicyLink": string;
  "termsOfServiceLink": string;
  "websiteURL": string;
  "supportEmail": string;
  "supportPhone": string;
  "blogURL": string;
  "hideLoginPage": boolean;
  "showSignup": boolean;
  "unauthorizedRedirectURL": string;
  "passwordResetURL": string;
  "signupURL": string;
  "allowInPromo": boolean;
  "interface": any;
  "id": any;
  "subscriptionId": any;
  "createdAt": Date;
  "updatedAt": Date;
  subscription: Subscription;
  constructor(data?: WhiteLabelInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `WhiteLabel`.
   */
  public static getModelName() {
    return "WhiteLabel";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of WhiteLabel for dynamic purposes.
  **/
  public static factory(data: WhiteLabelInterface): WhiteLabel{
    return new WhiteLabel(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'WhiteLabel',
      plural: 'WhiteLabels',
      path: 'WhiteLabels',
      idName: 'id',
      properties: {
        "active": {
          name: 'active',
          type: 'boolean',
          default: false
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "domain": {
          name: 'domain',
          type: 'string'
        },
        "subdomain": {
          name: 'subdomain',
          type: 'string'
        },
        "baseColor": {
          name: 'baseColor',
          type: 'string'
        },
        "logo": {
          name: 'logo',
          type: 'string'
        },
        "separateDashboardIcon": {
          name: 'separateDashboardIcon',
          type: 'boolean',
          default: false
        },
        "googleAnalyticsId": {
          name: 'googleAnalyticsId',
          type: 'string'
        },
        "chatAppType": {
          name: 'chatAppType',
          type: 'string',
          default: 'Intercom'
        },
        "chatAppId": {
          name: 'chatAppId',
          type: 'string'
        },
        "privacyPolicyLink": {
          name: 'privacyPolicyLink',
          type: 'string'
        },
        "termsOfServiceLink": {
          name: 'termsOfServiceLink',
          type: 'string'
        },
        "websiteURL": {
          name: 'websiteURL',
          type: 'string'
        },
        "supportEmail": {
          name: 'supportEmail',
          type: 'string'
        },
        "supportPhone": {
          name: 'supportPhone',
          type: 'string'
        },
        "blogURL": {
          name: 'blogURL',
          type: 'string'
        },
        "hideLoginPage": {
          name: 'hideLoginPage',
          type: 'boolean',
          default: true
        },
        "showSignup": {
          name: 'showSignup',
          type: 'boolean',
          default: false
        },
        "unauthorizedRedirectURL": {
          name: 'unauthorizedRedirectURL',
          type: 'string'
        },
        "passwordResetURL": {
          name: 'passwordResetURL',
          type: 'string'
        },
        "signupURL": {
          name: 'signupURL',
          type: 'string'
        },
        "allowInPromo": {
          name: 'allowInPromo',
          type: 'boolean',
          default: true
        },
        "interface": {
          name: 'interface',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "subscriptionId": {
          name: 'subscriptionId',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
      },
      relations: {
        subscription: {
          name: 'subscription',
          type: 'Subscription',
          model: 'Subscription',
          relationType: 'belongsTo',
                  keyFrom: 'subscriptionId',
          keyTo: 'id'
        },
      }
    }
  }
}
