import { LoggerService } from '../../shared/sdk/services/custom/logger.service';
import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { AccountService } from '../account.service';
import { EMPTY, Observable } from 'rxjs';
import { LoopBackAuth, SDKToken, Account } from '../../shared/sdk';

@Injectable()
export class IsAuthenticatedResolver implements Resolve<boolean> {
  loginWithToken: string;
  returnUrl: string;
  route: ActivatedRouteSnapshot;

  constructor(
    private accountService: AccountService,
    private log: LoggerService,
    private router: Router,
    private auth: LoopBackAuth,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean> | Observable<never> {
    this.log.log('IsAuthenticatedResolver: resolve');

    return new Observable((obs) => {
      this.route = route;
      // Token forwarded in query params to use as authentication for login
      // Used for linking from Mission Control to Apollo for instance
      this.loginWithToken = route.queryParams['access_token'];
      this.returnUrl = route.queryParams['returnUrl'];

      if (!this.loginWithToken && this.returnUrl) {
        const tokenIdx = this.returnUrl.indexOf('access_token');

        if (tokenIdx !== -1) {
          this.loginWithToken = this.returnUrl.slice(
            tokenIdx + 13,
            tokenIdx + 13 + 64,
          );
        }

        this.returnUrl =
          this.returnUrl.slice(0, tokenIdx - 1) +
          this.returnUrl.slice(tokenIdx + 13 + 64);

        if (
          this.returnUrl.indexOf('?') === -1 &&
          this.returnUrl.indexOf('&') !== -1
        ) {
          this.returnUrl = this.returnUrl.replace('&', '?');
        }
      }

      if (this.loginWithToken) {
        this.log.log('loginWithToken');
        // Update token with one from query param
        this.auth.setToken(
          new SDKToken({
            id: this.loginWithToken,
          }),
        );
        // this.auth.save();

        this.accountService.isAuthorized().subscribe(
          (account: Account) => {
            // If succesfully authorized update token with rest of user data
            // and re-save to storage
            this.auth.setToken(
              new SDKToken({
                id: this.loginWithToken,
                userId: account.id,
              }),
            );
            // this.auth.setRememberMe(true);
            this.auth.setUser(account);
            // this.auth.save();
            this.isAuthenticated(obs);
          },
          () => {
            // Invalid token
            obs.next(false);
            obs.complete();
          },
        );
      } else if (this.accountService.isAuthenticated()) {
        this.isAuthenticated(obs);
      } else {
        this.log.log('Not authenticated');
        obs.next(false);
        obs.complete();
      }
    });
  }

  isAuthenticated(obs) {
    this.log.log('isAuthenticated');

    if (this.returnUrl) {
      this.log.log('navigateByUrl', this.returnUrl);
      this.router.navigateByUrl(this.returnUrl);
      obs.complete();
      return EMPTY;
    }

    this.router.navigate(['/organizations/choose'], {
      queryParams: { l: this.route.queryParams.l },
    });

    obs.complete();
    return EMPTY;
  }
}
