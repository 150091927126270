import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoopBackAuth, LoopBackConfig } from './sdk';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(@Inject(LoopBackAuth) protected auth: LoopBackAuth) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.url.includes('/login') || req.url.includes('/reset')) {
      return next.handle(req);
    }

    if (!req.headers.get('Authorization') && this.auth.getAccessTokenId()) {
      req = req.clone({
        setHeaders: {
          Authorization:
            LoopBackConfig.getAuthPrefix() + this.auth.getAccessTokenId(),
        },
      });
    }

    return next.handle(req);
  }
}
