import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';

import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { environment } from 'src/environments/environment';
import { AccountService } from '../accounts/account.service';
import { ApolloSubscriptionService } from '../apollo-subscriptions/subscription.service';
import { CategoryService } from '../categories/category.service';
import { ClientService } from '../clients/client.service';
import { DocumentCategoryService } from '../documents/document-categories/document-category.service';
import { DocumentService } from '../documents/document.service';
import { HeadMenuService } from '../head/head-menu.service';
import { ItemService } from '../items/item.service';
import { OrganizationService } from '../organizations/organization.service';
import { TicketingHubService } from '../organizations/ticketing-hub.service';
import { PaymentService } from '../payments/payment.service';
import { PriceListService } from '../price-lists/price-list.service';
import { RecurrenceService } from '../recurrences/recurrence.service';
import { ReferralResolver } from '../referrals/referral.resolver';
import { ReferralService } from '../referrals/referral.service';
import { AuthorizationInterceptor } from '../shared/authorization.interceptor';
import { CsvService } from '../shared/csv.service';
import { InvoiceLimitResolver } from '../shared/invoice-limit.resolver';
import { LanguageResolver } from '../shared/language.resolver';
import { ToastService } from '../shared/modules/toast/toast.service';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { AuthenticatedHttpService } from '../shared/services/authenticated-http.service';
import { CacheService } from '../shared/services/cache.service';
import { ClipboardService } from '../shared/services/clipboard.service';
import { CompanyService } from '../shared/services/company.service';
import { CookieService } from '../shared/services/cookie.service';
import { CurrencyService } from '../shared/services/currency.service';
import { DeviceTypeService } from '../shared/services/device-type.service';
import { EmailService } from '../shared/services/email.service';
import { FileUploadService } from '../shared/services/file-upload.service';
import { FormService } from '../shared/services/form.service';
import { HelpdeskService } from '../shared/services/helpdesk.service';
import { LanguageService } from '../shared/services/language.service';
import { LoadingService } from '../shared/services/loading.service';
import { MyDpDateService } from '../shared/services/my-dp-date.service';
import { StorageService } from '../shared/services/storage.service';
import { ThemeService } from '../shared/services/theme.service';
import { TitleService } from '../shared/services/title.service';
import { TrackingService } from '../shared/services/tracking.service';
import { WhiteLabelService } from '../shared/services/whitelabel.service';
import { GlobalSubscriptionService } from '../subscriptions/global-subscription.service';
import { SubscriptionService } from '../subscriptions/subscription.service';
import { TaxService } from '../taxes/tax.service';
import { UnitService } from '../units/unit.service';
import { WarehouseService } from '../warehouses/warehouse.service';
import { WINDOW_PROVIDERS } from '../window.factory';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  exports: [],
  declarations: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticatedHttpService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        logErrors: !environment.production,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    WINDOW_PROVIDERS,
    AccountService,
    AuthGuard,
    CacheService,
    CategoryService,
    ClientService,
    CompanyService,
    CookieService,
    CurrencyService,
    DocumentCategoryService,
    DocumentService,
    EmailService,
    FileUploadService,
    FormService,
    HeadMenuService,
    InvoiceLimitResolver,
    ItemService,
    PriceListService,
    LanguageService,
    LoadingService,
    MyDpDateService,
    OrganizationService,
    PaymentService,
    RecurrenceService,
    StorageService,
    ApolloSubscriptionService,
    SubscriptionService,
    GlobalSubscriptionService,
    TaxService,
    TicketingHubService,
    TitleService,
    ThemeService,
    UnitService,
    WhiteLabelService,
    LanguageResolver,
    ReferralResolver,
    ReferralService,
    WarehouseService,
    TrackingService,
    ClipboardService,
    DeviceTypeService,
    ToastService,
    HelpdeskService,
    CsvService,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only',
      );
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [],
    };
  }
}
