import { Injectable, Inject } from '@angular/core';
import { WINDOW } from '../../window.factory';

@Injectable({
  providedIn: 'root',
})
export class DeviceTypeService {
  constructor(@Inject(WINDOW) private window: Window) {}

  get(): { isMobile: boolean; OS: string } {
    return {
      isMobile: this.isMobile,
      OS: this.OS,
    };
  }

  get isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      this.window.navigator.userAgent,
    );
  }

  get OS(): string {
    const userAgent = this.window.navigator.userAgent;
    const platform = this.window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  }
}
