import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastsComponent } from './toasts/toasts.component';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [ToastsComponent],
  exports: [ToastsComponent],
  imports: [CommonModule, NgbToastModule],
})
export class ToastModule {
  constructor(@Optional() @SkipSelf() parentModule?: ToastModule) {
    if (parentModule) {
      throw new Error(
        'ToastModule is already loaded. Import it in the AppModule only',
      );
    }
  }
}
