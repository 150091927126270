<ngb-toast
  *ngFor="let toast of toasts | async"
  [autohide]="true"
  [delay]="toast.options.delay"
  (hide)="toastService.remove(toast)"
  @fadeInToast
  @fadeOutToast
>
  <ng-template ngbToastHeader>
    <svg
      class="bd-placeholder-img rounded mr-2"
      width="20"
      height="20"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid slice"
      focusable="false"
      role="img"
    >
      <rect width="100%" height="100%" class="{{ toast.options.class }}"></rect>
    </svg>
    <strong class="mr-auto">{{ toast.header }}</strong>
  </ng-template>
  {{ toast.body }}
</ngb-toast>
