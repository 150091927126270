/* tslint:disable */
import {
  Account,
  Organization
} from '../index';

declare var Object: any;
export interface AccountOrganizationInterface {
  "role"?: string;
  "active"?: boolean;
  "disabled"?: boolean;
  "rejected"?: boolean;
  "invitationEmail"?: string;
  "id"?: any;
  "accountId"?: any;
  "organizationId"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "custom"?: any;
  account?: Account;
  organization?: Organization;
}

export class AccountOrganization implements AccountOrganizationInterface {
  "role": string;
  "active": boolean;
  "disabled": boolean;
  "rejected": boolean;
  "invitationEmail": string;
  "id": any;
  "accountId": any;
  "organizationId": any;
  "createdAt": Date;
  "updatedAt": Date;
  "custom": any;
  account: Account;
  organization: Organization;
  constructor(data?: AccountOrganizationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AccountOrganization`.
   */
  public static getModelName() {
    return "AccountOrganization";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AccountOrganization for dynamic purposes.
  **/
  public static factory(data: AccountOrganizationInterface): AccountOrganization{
    return new AccountOrganization(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AccountOrganization',
      plural: 'AccountOrganizations',
      path: 'AccountOrganizations',
      idName: 'id',
      properties: {
        "role": {
          name: 'role',
          type: 'string',
          default: 'admin'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "disabled": {
          name: 'disabled',
          type: 'boolean',
          default: false
        },
        "rejected": {
          name: 'rejected',
          type: 'boolean',
          default: false
        },
        "invitationEmail": {
          name: 'invitationEmail',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "accountId": {
          name: 'accountId',
          type: 'any'
        },
        "organizationId": {
          name: 'organizationId',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        "custom": {
          name: 'custom',
          type: 'any',
          default: <any>null
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'accountId',
          keyTo: 'id'
        },
        organization: {
          name: 'organization',
          type: 'Organization',
          model: 'Organization',
          relationType: 'belongsTo',
                  keyFrom: 'organizationId',
          keyTo: 'id'
        },
      }
    }
  }
}
