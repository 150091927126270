/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Tax } from '../models/tax';
import { TaxRate } from '../models/tax-rate';

@Injectable({
  providedIn: 'root',
})
export class TaxService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation taxPrototypeDestroyByIdTaxRates
   */
  static readonly TaxPrototypeDestroyByIdTaxRatesPath = '/v1/Taxes/{id}/taxRates/{fk}';

  /**
   * Delete a related item by id for taxRates.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxPrototypeDestroyByIdTaxRates()` instead.
   *
   * This method doesn't expect any request body.
   */
  taxPrototypeDestroyByIdTaxRates$Response(params: {

    /**
     * Tax id
     */
    id: string;

    /**
     * Foreign key for taxRates
     */
    fk: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxPrototypeDestroyByIdTaxRatesPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete a related item by id for taxRates.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxPrototypeDestroyByIdTaxRates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  taxPrototypeDestroyByIdTaxRates(params: {

    /**
     * Tax id
     */
    id: string;

    /**
     * Foreign key for taxRates
     */
    fk: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.taxPrototypeDestroyByIdTaxRates$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation taxPrototypeCreateTaxRates
   */
  static readonly TaxPrototypeCreateTaxRatesPath = '/v1/Taxes/{id}/taxRates';

  /**
   * Creates a new instance in taxRates of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxPrototypeCreateTaxRates$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxPrototypeCreateTaxRates$Json$Json$Response(params: {

    /**
     * Tax id
     */
    id: string;
    body?: TaxRate
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TaxRate>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxPrototypeCreateTaxRatesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaxRate>;
      })
    );
  }

  /**
   * Creates a new instance in taxRates of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxPrototypeCreateTaxRates$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxPrototypeCreateTaxRates$Json$Json(params: {

    /**
     * Tax id
     */
    id: string;
    body?: TaxRate
  },
  context?: HttpContext

): Observable<TaxRate> {

    return this.taxPrototypeCreateTaxRates$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<TaxRate>) => r.body as TaxRate)
    );
  }

  /**
   * Creates a new instance in taxRates of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxPrototypeCreateTaxRates$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxPrototypeCreateTaxRates$Json$Xml$Response(params: {

    /**
     * Tax id
     */
    id: string;
    body?: TaxRate
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TaxRate>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxPrototypeCreateTaxRatesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaxRate>;
      })
    );
  }

  /**
   * Creates a new instance in taxRates of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxPrototypeCreateTaxRates$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxPrototypeCreateTaxRates$Json$Xml(params: {

    /**
     * Tax id
     */
    id: string;
    body?: TaxRate
  },
  context?: HttpContext

): Observable<TaxRate> {

    return this.taxPrototypeCreateTaxRates$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<TaxRate>) => r.body as TaxRate)
    );
  }

  /**
   * Creates a new instance in taxRates of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxPrototypeCreateTaxRates$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxPrototypeCreateTaxRates$Json$Javascript$Response(params: {

    /**
     * Tax id
     */
    id: string;
    body?: TaxRate
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TaxRate>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxPrototypeCreateTaxRatesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaxRate>;
      })
    );
  }

  /**
   * Creates a new instance in taxRates of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxPrototypeCreateTaxRates$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxPrototypeCreateTaxRates$Json$Javascript(params: {

    /**
     * Tax id
     */
    id: string;
    body?: TaxRate
  },
  context?: HttpContext

): Observable<TaxRate> {

    return this.taxPrototypeCreateTaxRates$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<TaxRate>) => r.body as TaxRate)
    );
  }

  /**
   * Creates a new instance in taxRates of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxPrototypeCreateTaxRates$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxPrototypeCreateTaxRates$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Tax id
     */
    id: string;
    body?: TaxRate
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TaxRate>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxPrototypeCreateTaxRatesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaxRate>;
      })
    );
  }

  /**
   * Creates a new instance in taxRates of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxPrototypeCreateTaxRates$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxPrototypeCreateTaxRates$XWwwFormUrlencoded$Json(params: {

    /**
     * Tax id
     */
    id: string;
    body?: TaxRate
  },
  context?: HttpContext

): Observable<TaxRate> {

    return this.taxPrototypeCreateTaxRates$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<TaxRate>) => r.body as TaxRate)
    );
  }

  /**
   * Creates a new instance in taxRates of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxPrototypeCreateTaxRates$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxPrototypeCreateTaxRates$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Tax id
     */
    id: string;
    body?: TaxRate
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TaxRate>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxPrototypeCreateTaxRatesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaxRate>;
      })
    );
  }

  /**
   * Creates a new instance in taxRates of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxPrototypeCreateTaxRates$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxPrototypeCreateTaxRates$XWwwFormUrlencoded$Xml(params: {

    /**
     * Tax id
     */
    id: string;
    body?: TaxRate
  },
  context?: HttpContext

): Observable<TaxRate> {

    return this.taxPrototypeCreateTaxRates$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<TaxRate>) => r.body as TaxRate)
    );
  }

  /**
   * Creates a new instance in taxRates of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxPrototypeCreateTaxRates$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxPrototypeCreateTaxRates$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Tax id
     */
    id: string;
    body?: TaxRate
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TaxRate>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxPrototypeCreateTaxRatesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaxRate>;
      })
    );
  }

  /**
   * Creates a new instance in taxRates of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxPrototypeCreateTaxRates$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxPrototypeCreateTaxRates$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Tax id
     */
    id: string;
    body?: TaxRate
  },
  context?: HttpContext

): Observable<TaxRate> {

    return this.taxPrototypeCreateTaxRates$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<TaxRate>) => r.body as TaxRate)
    );
  }

  /**
   * Creates a new instance in taxRates of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxPrototypeCreateTaxRates$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxPrototypeCreateTaxRates$Xml$Json$Response(params: {

    /**
     * Tax id
     */
    id: string;
    body?: TaxRate
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TaxRate>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxPrototypeCreateTaxRatesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaxRate>;
      })
    );
  }

  /**
   * Creates a new instance in taxRates of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxPrototypeCreateTaxRates$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxPrototypeCreateTaxRates$Xml$Json(params: {

    /**
     * Tax id
     */
    id: string;
    body?: TaxRate
  },
  context?: HttpContext

): Observable<TaxRate> {

    return this.taxPrototypeCreateTaxRates$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<TaxRate>) => r.body as TaxRate)
    );
  }

  /**
   * Creates a new instance in taxRates of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxPrototypeCreateTaxRates$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxPrototypeCreateTaxRates$Xml$Xml$Response(params: {

    /**
     * Tax id
     */
    id: string;
    body?: TaxRate
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TaxRate>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxPrototypeCreateTaxRatesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaxRate>;
      })
    );
  }

  /**
   * Creates a new instance in taxRates of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxPrototypeCreateTaxRates$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxPrototypeCreateTaxRates$Xml$Xml(params: {

    /**
     * Tax id
     */
    id: string;
    body?: TaxRate
  },
  context?: HttpContext

): Observable<TaxRate> {

    return this.taxPrototypeCreateTaxRates$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<TaxRate>) => r.body as TaxRate)
    );
  }

  /**
   * Creates a new instance in taxRates of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxPrototypeCreateTaxRates$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxPrototypeCreateTaxRates$Xml$Javascript$Response(params: {

    /**
     * Tax id
     */
    id: string;
    body?: TaxRate
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TaxRate>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxPrototypeCreateTaxRatesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaxRate>;
      })
    );
  }

  /**
   * Creates a new instance in taxRates of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxPrototypeCreateTaxRates$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxPrototypeCreateTaxRates$Xml$Javascript(params: {

    /**
     * Tax id
     */
    id: string;
    body?: TaxRate
  },
  context?: HttpContext

): Observable<TaxRate> {

    return this.taxPrototypeCreateTaxRates$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<TaxRate>) => r.body as TaxRate)
    );
  }

  /**
   * Path part for operation taxFindById
   */
  static readonly TaxFindByIdPath = '/v1/Taxes/{id}';

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxFindById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  taxFindById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxFindById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  taxFindById$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Tax> {

    return this.taxFindById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxFindById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  taxFindById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxFindById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  taxFindById$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Tax> {

    return this.taxFindById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxFindById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  taxFindById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxFindById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  taxFindById$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Tax> {

    return this.taxFindById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Path part for operation taxReplaceByIdPutTaxesId
   */
  static readonly TaxReplaceByIdPutTaxesIdPath = '/v1/Taxes/{id}';

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxReplaceByIdPutTaxesId$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxReplaceByIdPutTaxesId$Json$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxReplaceByIdPutTaxesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxReplaceByIdPutTaxesId$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxReplaceByIdPutTaxesId$Json$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.taxReplaceByIdPutTaxesId$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxReplaceByIdPutTaxesId$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxReplaceByIdPutTaxesId$Json$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxReplaceByIdPutTaxesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxReplaceByIdPutTaxesId$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxReplaceByIdPutTaxesId$Json$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.taxReplaceByIdPutTaxesId$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxReplaceByIdPutTaxesId$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxReplaceByIdPutTaxesId$Json$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxReplaceByIdPutTaxesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxReplaceByIdPutTaxesId$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxReplaceByIdPutTaxesId$Json$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.taxReplaceByIdPutTaxesId$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxReplaceByIdPutTaxesId$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxReplaceByIdPutTaxesId$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxReplaceByIdPutTaxesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxReplaceByIdPutTaxesId$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxReplaceByIdPutTaxesId$XWwwFormUrlencoded$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.taxReplaceByIdPutTaxesId$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxReplaceByIdPutTaxesId$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxReplaceByIdPutTaxesId$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxReplaceByIdPutTaxesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxReplaceByIdPutTaxesId$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxReplaceByIdPutTaxesId$XWwwFormUrlencoded$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.taxReplaceByIdPutTaxesId$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxReplaceByIdPutTaxesId$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxReplaceByIdPutTaxesId$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxReplaceByIdPutTaxesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxReplaceByIdPutTaxesId$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxReplaceByIdPutTaxesId$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.taxReplaceByIdPutTaxesId$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxReplaceByIdPutTaxesId$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxReplaceByIdPutTaxesId$Xml$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxReplaceByIdPutTaxesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxReplaceByIdPutTaxesId$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxReplaceByIdPutTaxesId$Xml$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.taxReplaceByIdPutTaxesId$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxReplaceByIdPutTaxesId$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxReplaceByIdPutTaxesId$Xml$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxReplaceByIdPutTaxesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxReplaceByIdPutTaxesId$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxReplaceByIdPutTaxesId$Xml$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.taxReplaceByIdPutTaxesId$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxReplaceByIdPutTaxesId$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxReplaceByIdPutTaxesId$Xml$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxReplaceByIdPutTaxesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxReplaceByIdPutTaxesId$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxReplaceByIdPutTaxesId$Xml$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.taxReplaceByIdPutTaxesId$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Path part for operation taxDeleteById
   */
  static readonly TaxDeleteByIdPath = '/v1/Taxes/{id}';

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxDeleteById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  taxDeleteById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxDeleteById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  taxDeleteById$Json(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.taxDeleteById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxDeleteById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  taxDeleteById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxDeleteById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  taxDeleteById$Xml(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.taxDeleteById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxDeleteById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  taxDeleteById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxDeleteById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  taxDeleteById$Javascript(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.taxDeleteById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation taxReplaceByIdPostTaxesIdReplace
   */
  static readonly TaxReplaceByIdPostTaxesIdReplacePath = '/v1/Taxes/{id}/replace';

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxReplaceByIdPostTaxesIdReplace$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxReplaceByIdPostTaxesIdReplace$Json$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxReplaceByIdPostTaxesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxReplaceByIdPostTaxesIdReplace$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxReplaceByIdPostTaxesIdReplace$Json$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.taxReplaceByIdPostTaxesIdReplace$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxReplaceByIdPostTaxesIdReplace$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxReplaceByIdPostTaxesIdReplace$Json$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxReplaceByIdPostTaxesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxReplaceByIdPostTaxesIdReplace$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxReplaceByIdPostTaxesIdReplace$Json$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.taxReplaceByIdPostTaxesIdReplace$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxReplaceByIdPostTaxesIdReplace$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxReplaceByIdPostTaxesIdReplace$Json$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxReplaceByIdPostTaxesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxReplaceByIdPostTaxesIdReplace$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxReplaceByIdPostTaxesIdReplace$Json$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.taxReplaceByIdPostTaxesIdReplace$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxReplaceByIdPostTaxesIdReplace$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxReplaceByIdPostTaxesIdReplace$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxReplaceByIdPostTaxesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxReplaceByIdPostTaxesIdReplace$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxReplaceByIdPostTaxesIdReplace$XWwwFormUrlencoded$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.taxReplaceByIdPostTaxesIdReplace$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxReplaceByIdPostTaxesIdReplace$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxReplaceByIdPostTaxesIdReplace$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxReplaceByIdPostTaxesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxReplaceByIdPostTaxesIdReplace$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxReplaceByIdPostTaxesIdReplace$XWwwFormUrlencoded$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.taxReplaceByIdPostTaxesIdReplace$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxReplaceByIdPostTaxesIdReplace$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxReplaceByIdPostTaxesIdReplace$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxReplaceByIdPostTaxesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxReplaceByIdPostTaxesIdReplace$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxReplaceByIdPostTaxesIdReplace$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.taxReplaceByIdPostTaxesIdReplace$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxReplaceByIdPostTaxesIdReplace$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxReplaceByIdPostTaxesIdReplace$Xml$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxReplaceByIdPostTaxesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxReplaceByIdPostTaxesIdReplace$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxReplaceByIdPostTaxesIdReplace$Xml$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.taxReplaceByIdPostTaxesIdReplace$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxReplaceByIdPostTaxesIdReplace$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxReplaceByIdPostTaxesIdReplace$Xml$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxReplaceByIdPostTaxesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxReplaceByIdPostTaxesIdReplace$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxReplaceByIdPostTaxesIdReplace$Xml$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.taxReplaceByIdPostTaxesIdReplace$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxReplaceByIdPostTaxesIdReplace$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxReplaceByIdPostTaxesIdReplace$Xml$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxReplaceByIdPostTaxesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxReplaceByIdPostTaxesIdReplace$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxReplaceByIdPostTaxesIdReplace$Xml$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Tax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.taxReplaceByIdPostTaxesIdReplace$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Path part for operation taxRemoteTaxClauseType
   */
  static readonly TaxRemoteTaxClauseTypePath = '/v1/Taxes/clause-type';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxRemoteTaxClauseType$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxRemoteTaxClauseType$Json$Json$Response(params: {

    /**
     * Body resembling a document.
     */
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxRemoteTaxClauseTypePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'taxClauseType'?: string;
        'issuerCountry'?: {
        };
        'clientCountry'?: {
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxRemoteTaxClauseType$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxRemoteTaxClauseType$Json$Json(params: {

    /**
     * Body resembling a document.
     */
    body: {
}
  },
  context?: HttpContext

): Observable<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}> {

    return this.taxRemoteTaxClauseType$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}>) => r.body as {
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxRemoteTaxClauseType$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxRemoteTaxClauseType$Json$Xml$Response(params: {

    /**
     * Body resembling a document.
     */
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxRemoteTaxClauseTypePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'taxClauseType'?: string;
        'issuerCountry'?: {
        };
        'clientCountry'?: {
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxRemoteTaxClauseType$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxRemoteTaxClauseType$Json$Xml(params: {

    /**
     * Body resembling a document.
     */
    body: {
}
  },
  context?: HttpContext

): Observable<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}> {

    return this.taxRemoteTaxClauseType$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}>) => r.body as {
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxRemoteTaxClauseType$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxRemoteTaxClauseType$Json$Javascript$Response(params: {

    /**
     * Body resembling a document.
     */
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxRemoteTaxClauseTypePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'taxClauseType'?: string;
        'issuerCountry'?: {
        };
        'clientCountry'?: {
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxRemoteTaxClauseType$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  taxRemoteTaxClauseType$Json$Javascript(params: {

    /**
     * Body resembling a document.
     */
    body: {
}
  },
  context?: HttpContext

): Observable<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}> {

    return this.taxRemoteTaxClauseType$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}>) => r.body as {
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxRemoteTaxClauseType$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxRemoteTaxClauseType$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Body resembling a document.
     */
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxRemoteTaxClauseTypePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'taxClauseType'?: string;
        'issuerCountry'?: {
        };
        'clientCountry'?: {
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxRemoteTaxClauseType$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxRemoteTaxClauseType$XWwwFormUrlencoded$Json(params: {

    /**
     * Body resembling a document.
     */
    body: {
}
  },
  context?: HttpContext

): Observable<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}> {

    return this.taxRemoteTaxClauseType$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}>) => r.body as {
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxRemoteTaxClauseType$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxRemoteTaxClauseType$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Body resembling a document.
     */
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxRemoteTaxClauseTypePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'taxClauseType'?: string;
        'issuerCountry'?: {
        };
        'clientCountry'?: {
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxRemoteTaxClauseType$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxRemoteTaxClauseType$XWwwFormUrlencoded$Xml(params: {

    /**
     * Body resembling a document.
     */
    body: {
}
  },
  context?: HttpContext

): Observable<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}> {

    return this.taxRemoteTaxClauseType$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}>) => r.body as {
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxRemoteTaxClauseType$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxRemoteTaxClauseType$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Body resembling a document.
     */
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxRemoteTaxClauseTypePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'taxClauseType'?: string;
        'issuerCountry'?: {
        };
        'clientCountry'?: {
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxRemoteTaxClauseType$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  taxRemoteTaxClauseType$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Body resembling a document.
     */
    body: {
}
  },
  context?: HttpContext

): Observable<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}> {

    return this.taxRemoteTaxClauseType$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}>) => r.body as {
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxRemoteTaxClauseType$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxRemoteTaxClauseType$Xml$Json$Response(params: {

    /**
     * Body resembling a document.
     */
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxRemoteTaxClauseTypePath, 'post');
    if (params) {
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'taxClauseType'?: string;
        'issuerCountry'?: {
        };
        'clientCountry'?: {
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxRemoteTaxClauseType$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxRemoteTaxClauseType$Xml$Json(params: {

    /**
     * Body resembling a document.
     */
    body: {
}
  },
  context?: HttpContext

): Observable<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}> {

    return this.taxRemoteTaxClauseType$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}>) => r.body as {
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxRemoteTaxClauseType$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxRemoteTaxClauseType$Xml$Xml$Response(params: {

    /**
     * Body resembling a document.
     */
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxRemoteTaxClauseTypePath, 'post');
    if (params) {
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'taxClauseType'?: string;
        'issuerCountry'?: {
        };
        'clientCountry'?: {
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxRemoteTaxClauseType$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxRemoteTaxClauseType$Xml$Xml(params: {

    /**
     * Body resembling a document.
     */
    body: {
}
  },
  context?: HttpContext

): Observable<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}> {

    return this.taxRemoteTaxClauseType$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}>) => r.body as {
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taxRemoteTaxClauseType$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxRemoteTaxClauseType$Xml$Javascript$Response(params: {

    /**
     * Body resembling a document.
     */
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, TaxService.TaxRemoteTaxClauseTypePath, 'post');
    if (params) {
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'taxClauseType'?: string;
        'issuerCountry'?: {
        };
        'clientCountry'?: {
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taxRemoteTaxClauseType$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  taxRemoteTaxClauseType$Xml$Javascript(params: {

    /**
     * Body resembling a document.
     */
    body: {
}
  },
  context?: HttpContext

): Observable<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}> {

    return this.taxRemoteTaxClauseType$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
}>) => r.body as {
'taxClauseType'?: string;
'issuerCountry'?: {
};
'clientCountry'?: {
};
})
    );
  }

}
