/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CustomIntegration } from '../models/custom-integration';

@Injectable({
  providedIn: 'root',
})
export class CustomIntegrationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation customIntegrationDeleteById
   */
  static readonly CustomIntegrationDeleteByIdPath = '/v1/CustomIntegrations/{id}';

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationDeleteById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  customIntegrationDeleteById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationDeleteById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customIntegrationDeleteById$Json(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.customIntegrationDeleteById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationDeleteById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  customIntegrationDeleteById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationDeleteById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customIntegrationDeleteById$Xml(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.customIntegrationDeleteById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationDeleteById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  customIntegrationDeleteById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationDeleteById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customIntegrationDeleteById$Javascript(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.customIntegrationDeleteById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation customIntegrationPrototypePatchAttributes
   */
  static readonly CustomIntegrationPrototypePatchAttributesPath = '/v1/CustomIntegrations/{id}';

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationPrototypePatchAttributes$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customIntegrationPrototypePatchAttributes$Json$Json$Response(params: {

    /**
     * CustomIntegration id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CustomIntegration>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomIntegration>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationPrototypePatchAttributes$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customIntegrationPrototypePatchAttributes$Json$Json(params: {

    /**
     * CustomIntegration id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<CustomIntegration> {

    return this.customIntegrationPrototypePatchAttributes$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<CustomIntegration>) => r.body as CustomIntegration)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationPrototypePatchAttributes$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customIntegrationPrototypePatchAttributes$Json$Xml$Response(params: {

    /**
     * CustomIntegration id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CustomIntegration>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomIntegration>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationPrototypePatchAttributes$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customIntegrationPrototypePatchAttributes$Json$Xml(params: {

    /**
     * CustomIntegration id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<CustomIntegration> {

    return this.customIntegrationPrototypePatchAttributes$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<CustomIntegration>) => r.body as CustomIntegration)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationPrototypePatchAttributes$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customIntegrationPrototypePatchAttributes$Json$Javascript$Response(params: {

    /**
     * CustomIntegration id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CustomIntegration>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomIntegration>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationPrototypePatchAttributes$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customIntegrationPrototypePatchAttributes$Json$Javascript(params: {

    /**
     * CustomIntegration id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<CustomIntegration> {

    return this.customIntegrationPrototypePatchAttributes$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<CustomIntegration>) => r.body as CustomIntegration)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationPrototypePatchAttributes$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  customIntegrationPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * CustomIntegration id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CustomIntegration>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomIntegration>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  customIntegrationPrototypePatchAttributes$XWwwFormUrlencoded$Json(params: {

    /**
     * CustomIntegration id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<CustomIntegration> {

    return this.customIntegrationPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<CustomIntegration>) => r.body as CustomIntegration)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationPrototypePatchAttributes$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  customIntegrationPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * CustomIntegration id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CustomIntegration>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomIntegration>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  customIntegrationPrototypePatchAttributes$XWwwFormUrlencoded$Xml(params: {

    /**
     * CustomIntegration id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<CustomIntegration> {

    return this.customIntegrationPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<CustomIntegration>) => r.body as CustomIntegration)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationPrototypePatchAttributes$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  customIntegrationPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * CustomIntegration id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CustomIntegration>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomIntegration>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  customIntegrationPrototypePatchAttributes$XWwwFormUrlencoded$Javascript(params: {

    /**
     * CustomIntegration id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<CustomIntegration> {

    return this.customIntegrationPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<CustomIntegration>) => r.body as CustomIntegration)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationPrototypePatchAttributes$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  customIntegrationPrototypePatchAttributes$Xml$Json$Response(params: {

    /**
     * CustomIntegration id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CustomIntegration>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomIntegration>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationPrototypePatchAttributes$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  customIntegrationPrototypePatchAttributes$Xml$Json(params: {

    /**
     * CustomIntegration id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<CustomIntegration> {

    return this.customIntegrationPrototypePatchAttributes$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<CustomIntegration>) => r.body as CustomIntegration)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationPrototypePatchAttributes$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  customIntegrationPrototypePatchAttributes$Xml$Xml$Response(params: {

    /**
     * CustomIntegration id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CustomIntegration>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomIntegration>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationPrototypePatchAttributes$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  customIntegrationPrototypePatchAttributes$Xml$Xml(params: {

    /**
     * CustomIntegration id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<CustomIntegration> {

    return this.customIntegrationPrototypePatchAttributes$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<CustomIntegration>) => r.body as CustomIntegration)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationPrototypePatchAttributes$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  customIntegrationPrototypePatchAttributes$Xml$Javascript$Response(params: {

    /**
     * CustomIntegration id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CustomIntegration>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomIntegration>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationPrototypePatchAttributes$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  customIntegrationPrototypePatchAttributes$Xml$Javascript(params: {

    /**
     * CustomIntegration id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<CustomIntegration> {

    return this.customIntegrationPrototypePatchAttributes$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<CustomIntegration>) => r.body as CustomIntegration)
    );
  }

  /**
   * Path part for operation customIntegrationPrototypeProcessOrder
   */
  static readonly CustomIntegrationPrototypeProcessOrderPath = '/v1/CustomIntegrations/{id}/order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationPrototypeProcessOrder$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customIntegrationPrototypeProcessOrder$Json$Json$Response(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationPrototypeProcessOrder$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customIntegrationPrototypeProcessOrder$Json$Json(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<{
}> {

    return this.customIntegrationPrototypeProcessOrder$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationPrototypeProcessOrder$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customIntegrationPrototypeProcessOrder$Json$Xml$Response(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationPrototypeProcessOrder$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customIntegrationPrototypeProcessOrder$Json$Xml(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<{
}> {

    return this.customIntegrationPrototypeProcessOrder$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationPrototypeProcessOrder$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customIntegrationPrototypeProcessOrder$Json$Javascript$Response(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationPrototypeProcessOrder$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customIntegrationPrototypeProcessOrder$Json$Javascript(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<{
}> {

    return this.customIntegrationPrototypeProcessOrder$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationPrototypeProcessOrder$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  customIntegrationPrototypeProcessOrder$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationPrototypeProcessOrder$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  customIntegrationPrototypeProcessOrder$XWwwFormUrlencoded$Json(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<{
}> {

    return this.customIntegrationPrototypeProcessOrder$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationPrototypeProcessOrder$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  customIntegrationPrototypeProcessOrder$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationPrototypeProcessOrder$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  customIntegrationPrototypeProcessOrder$XWwwFormUrlencoded$Xml(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<{
}> {

    return this.customIntegrationPrototypeProcessOrder$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationPrototypeProcessOrder$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  customIntegrationPrototypeProcessOrder$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationPrototypeProcessOrder$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  customIntegrationPrototypeProcessOrder$XWwwFormUrlencoded$Javascript(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<{
}> {

    return this.customIntegrationPrototypeProcessOrder$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationPrototypeProcessOrder$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  customIntegrationPrototypeProcessOrder$Xml$Json$Response(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationPrototypeProcessOrder$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  customIntegrationPrototypeProcessOrder$Xml$Json(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<{
}> {

    return this.customIntegrationPrototypeProcessOrder$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationPrototypeProcessOrder$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  customIntegrationPrototypeProcessOrder$Xml$Xml$Response(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationPrototypeProcessOrder$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  customIntegrationPrototypeProcessOrder$Xml$Xml(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<{
}> {

    return this.customIntegrationPrototypeProcessOrder$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationPrototypeProcessOrder$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  customIntegrationPrototypeProcessOrder$Xml$Javascript$Response(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationPrototypeProcessOrderPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationPrototypeProcessOrder$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  customIntegrationPrototypeProcessOrder$Xml$Javascript(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body?: CustomIntegration
  },
  context?: HttpContext

): Observable<{
}> {

    return this.customIntegrationPrototypeProcessOrder$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation customIntegrationPrototypeImport
   */
  static readonly CustomIntegrationPrototypeImportPath = '/v1/CustomIntegrations/{id}/import';

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationPrototypeImport$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  customIntegrationPrototypeImport$Json$Response(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationPrototypeImportPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationPrototypeImport$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  customIntegrationPrototypeImport$Json(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.customIntegrationPrototypeImport$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationPrototypeImport$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  customIntegrationPrototypeImport$Xml$Response(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationPrototypeImportPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationPrototypeImport$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  customIntegrationPrototypeImport$Xml(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.customIntegrationPrototypeImport$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customIntegrationPrototypeImport$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  customIntegrationPrototypeImport$Javascript$Response(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomIntegrationService.CustomIntegrationPrototypeImportPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Import integration data in excel format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customIntegrationPrototypeImport$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  customIntegrationPrototypeImport$Javascript(params: {

    /**
     * CustomIntegration id
     */
    id: string;
    body: {
'type': string;
'importOptions'?: string;
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.customIntegrationPrototypeImport$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

}
