import { Observable, ReplaySubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { LoggerService } from '../shared/sdk';

export interface IMenuDropDownItem {
  name?: string;
  class?: string;
  func?: Function;
  divider?: boolean;
  id?: string;
  header?: boolean;
  hidden?: boolean;
  path?: string | any[];
  namespace?: string;
}

export interface IMenuItem {
  name?: string;
  tooltip?: Observable<string>;
  tooltipDisabled?: boolean;
  class?: string | Observable<string>;
  class_li?: string;
  func?: Function;
  path?: string | string[];
  hidden?: boolean | Function | Observable<boolean>;
  active?: Function;
  namespace?: string;
  id?: string;
  dropdown?: IMenuDropDownItem[];
}

@Injectable()
export class HeadMenuService {
  public _items: any[];
  private items$: ReplaySubject<any[]> = new ReplaySubject(1);
  readonly items: Observable<any[]> = this.items$.asObservable();

  public _namespace: string;
  private namespace$: ReplaySubject<string> = new ReplaySubject(1);
  readonly namespace: Observable<string> = this.namespace$.asObservable();

  constructor(private log: LoggerService) {}

  set(namespace: string, items: IMenuItem[]): void {
    this.log.log('HeadMenuService: set');
    this.namespace$.next(namespace);
    this.items$.next(items);
  }
}
