import { API_VERSION, BASE_URL } from '../shared/base-url';
import { LoggerService, LoopBackConfig } from '../shared/sdk';
import { OrganizationApi, TaxApi } from '../shared/sdk/services';

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, zip } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { ToastService } from '../shared/modules/toast/toast.service';
import { Tax } from '../shared/sdk/models';
import { ApiService } from '../shared/services/api.service';
import { CacheService } from '../shared/services/cache.service';

export interface TaxRate {
  dateValidFrom: Date;
  rate: number;
}

@Injectable()
export class TaxService extends ApiService<Tax> {
  constructor(
    protected cacheService: CacheService,
    protected log: LoggerService,
    protected toastService: ToastService,
    protected translate: TranslateService,
    private taxApi: TaxApi,
    private orgApi: OrganizationApi,
  ) {
    super(cacheService, log, toastService, translate);

    this.name = 'Tax';
    this.plural = 'Taxes';

    LoopBackConfig.setBaseURL(BASE_URL);
    LoopBackConfig.setApiVersion(API_VERSION);

    this.ttl = 3600000;
  }

  count(
    orgId: any,
    where: any = {},
    force = false,
    toastr = true,
  ): Observable<number> {
    return super.count(orgId, where, force, toastr, this.orgApi);
  }

  create(orgId: string, data: any, toastr = true): Observable<Tax> {
    return super.create(orgId, data, toastr, this.orgApi);
  }

  delete(id: any, toastr = true): Observable<boolean> {
    return super.delete(id, toastr, this.taxApi);
  }

  get(
    orgId: any,
    filter: any = { order: '_taxRates.rate DESC' },
    force = false,
    toastr = true,
  ): Observable<{ body: Tax[]; totalCount: number }> {
    return super.get(orgId, filter, force, toastr, this.orgApi);
  }

  getAll(
    orgId: any,
    filter: any = { order: '_taxRates.rate DESC' },
    force = false,
    toastr = true,
  ): Observable<{ body: Tax[]; totalCount: number }> {
    return this.get(orgId, filter, force, toastr).pipe(
      concatMap((res) => {
        const page = 100;
        const max = 500;

        if (res.totalCount > page) {
          let skip = page;
          let requests: Observable<{ body: Tax[]; totalCount: number }>[] = [];

          while (skip < res.totalCount && skip < max) {
            requests.push(this.get(orgId, { ...filter, skip }, force, toastr));
            skip += page;
          }

          return zip(...requests).pipe(
            map((responses) => {
              const final = [...res.body];
              responses.forEach((response) => final.push(...response.body));

              return {
                body: final,
                totalCount: res.totalCount,
              };
            }),
          );
        } else {
          return of(res);
        }
      }),
    );
  }

  getById(
    id: string,
    filter = {},
    force = true,
    toastr = true,
  ): Observable<Tax> {
    return super.getById(id, filter, force, toastr, this.taxApi);
  }

  getClauseType(document: any): Observable<{ taxClauseType: string }> {
    return this.taxApi
      .remoteTaxClauseType(document)
      .pipe(map(({ body }) => body));
  }

  update(id: string, data, toastr = true): Observable<Tax> {
    return super.update(id, data, toastr, this.taxApi);
  }
}
