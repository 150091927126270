export const environment = {
  name: 'testing',
  production: false,
  tracking: false,
  apiUrl: 'https://api-test.spaceinvoices.com',
  selfUrl: 'https://test.getapollo.io',
  supportAccountId: '5d6e43d1a39782e2647a1b21',
  masterId: '5e59abf19566912e672f246a',
  hostname: 'app-test.getapollo.io',
  guestbookUrl: 'https://api.snapguest.pro',
  guestbookApiKey: 'key-dDGmdp7kNFUk2kmHYDPfGMuwfdyLchfb',
  mgDomain: 'sandbox1bfe8e20e4c647d1a23e0b80da435060.mailgun.org',
  version: '8600d767efe1c08b82d1ec9919d2a82307ddedf2',
};

// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
