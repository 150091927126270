/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Component } from '../models/component';
import { Inventory } from '../models/inventory';
import { Item } from '../models/item';

@Injectable({
  providedIn: 'root',
})
export class ItemService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation itemPrototypeUpdateByIdComponents
   */
  static readonly ItemPrototypeUpdateByIdComponentsPath = '/v1/Items/{id}/components/{fk}';

  /**
   * Update a related item by id for components.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemPrototypeUpdateByIdComponents$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemPrototypeUpdateByIdComponents$Json$Json$Response(params: {

    /**
     * Item id
     */
    id: string;

    /**
     * Foreign key for components
     */
    fk: string;
    body?: Component
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Component>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemPrototypeUpdateByIdComponentsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Component>;
      })
    );
  }

  /**
   * Update a related item by id for components.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemPrototypeUpdateByIdComponents$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemPrototypeUpdateByIdComponents$Json$Json(params: {

    /**
     * Item id
     */
    id: string;

    /**
     * Foreign key for components
     */
    fk: string;
    body?: Component
  },
  context?: HttpContext

): Observable<Component> {

    return this.itemPrototypeUpdateByIdComponents$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Component>) => r.body as Component)
    );
  }

  /**
   * Update a related item by id for components.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemPrototypeUpdateByIdComponents$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemPrototypeUpdateByIdComponents$Json$Xml$Response(params: {

    /**
     * Item id
     */
    id: string;

    /**
     * Foreign key for components
     */
    fk: string;
    body?: Component
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Component>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemPrototypeUpdateByIdComponentsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Component>;
      })
    );
  }

  /**
   * Update a related item by id for components.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemPrototypeUpdateByIdComponents$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemPrototypeUpdateByIdComponents$Json$Xml(params: {

    /**
     * Item id
     */
    id: string;

    /**
     * Foreign key for components
     */
    fk: string;
    body?: Component
  },
  context?: HttpContext

): Observable<Component> {

    return this.itemPrototypeUpdateByIdComponents$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Component>) => r.body as Component)
    );
  }

  /**
   * Update a related item by id for components.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemPrototypeUpdateByIdComponents$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemPrototypeUpdateByIdComponents$Json$Javascript$Response(params: {

    /**
     * Item id
     */
    id: string;

    /**
     * Foreign key for components
     */
    fk: string;
    body?: Component
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Component>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemPrototypeUpdateByIdComponentsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Component>;
      })
    );
  }

  /**
   * Update a related item by id for components.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemPrototypeUpdateByIdComponents$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemPrototypeUpdateByIdComponents$Json$Javascript(params: {

    /**
     * Item id
     */
    id: string;

    /**
     * Foreign key for components
     */
    fk: string;
    body?: Component
  },
  context?: HttpContext

): Observable<Component> {

    return this.itemPrototypeUpdateByIdComponents$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Component>) => r.body as Component)
    );
  }

  /**
   * Update a related item by id for components.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemPrototypeUpdateByIdComponents$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemPrototypeUpdateByIdComponents$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Item id
     */
    id: string;

    /**
     * Foreign key for components
     */
    fk: string;
    body?: Component
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Component>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemPrototypeUpdateByIdComponentsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Component>;
      })
    );
  }

  /**
   * Update a related item by id for components.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemPrototypeUpdateByIdComponents$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemPrototypeUpdateByIdComponents$XWwwFormUrlencoded$Json(params: {

    /**
     * Item id
     */
    id: string;

    /**
     * Foreign key for components
     */
    fk: string;
    body?: Component
  },
  context?: HttpContext

): Observable<Component> {

    return this.itemPrototypeUpdateByIdComponents$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Component>) => r.body as Component)
    );
  }

  /**
   * Update a related item by id for components.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemPrototypeUpdateByIdComponents$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemPrototypeUpdateByIdComponents$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Item id
     */
    id: string;

    /**
     * Foreign key for components
     */
    fk: string;
    body?: Component
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Component>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemPrototypeUpdateByIdComponentsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Component>;
      })
    );
  }

  /**
   * Update a related item by id for components.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemPrototypeUpdateByIdComponents$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemPrototypeUpdateByIdComponents$XWwwFormUrlencoded$Xml(params: {

    /**
     * Item id
     */
    id: string;

    /**
     * Foreign key for components
     */
    fk: string;
    body?: Component
  },
  context?: HttpContext

): Observable<Component> {

    return this.itemPrototypeUpdateByIdComponents$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Component>) => r.body as Component)
    );
  }

  /**
   * Update a related item by id for components.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemPrototypeUpdateByIdComponents$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemPrototypeUpdateByIdComponents$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Item id
     */
    id: string;

    /**
     * Foreign key for components
     */
    fk: string;
    body?: Component
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Component>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemPrototypeUpdateByIdComponentsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Component>;
      })
    );
  }

  /**
   * Update a related item by id for components.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemPrototypeUpdateByIdComponents$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemPrototypeUpdateByIdComponents$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Item id
     */
    id: string;

    /**
     * Foreign key for components
     */
    fk: string;
    body?: Component
  },
  context?: HttpContext

): Observable<Component> {

    return this.itemPrototypeUpdateByIdComponents$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Component>) => r.body as Component)
    );
  }

  /**
   * Update a related item by id for components.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemPrototypeUpdateByIdComponents$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemPrototypeUpdateByIdComponents$Xml$Json$Response(params: {

    /**
     * Item id
     */
    id: string;

    /**
     * Foreign key for components
     */
    fk: string;
    body?: Component
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Component>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemPrototypeUpdateByIdComponentsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Component>;
      })
    );
  }

  /**
   * Update a related item by id for components.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemPrototypeUpdateByIdComponents$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemPrototypeUpdateByIdComponents$Xml$Json(params: {

    /**
     * Item id
     */
    id: string;

    /**
     * Foreign key for components
     */
    fk: string;
    body?: Component
  },
  context?: HttpContext

): Observable<Component> {

    return this.itemPrototypeUpdateByIdComponents$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Component>) => r.body as Component)
    );
  }

  /**
   * Update a related item by id for components.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemPrototypeUpdateByIdComponents$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemPrototypeUpdateByIdComponents$Xml$Xml$Response(params: {

    /**
     * Item id
     */
    id: string;

    /**
     * Foreign key for components
     */
    fk: string;
    body?: Component
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Component>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemPrototypeUpdateByIdComponentsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Component>;
      })
    );
  }

  /**
   * Update a related item by id for components.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemPrototypeUpdateByIdComponents$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemPrototypeUpdateByIdComponents$Xml$Xml(params: {

    /**
     * Item id
     */
    id: string;

    /**
     * Foreign key for components
     */
    fk: string;
    body?: Component
  },
  context?: HttpContext

): Observable<Component> {

    return this.itemPrototypeUpdateByIdComponents$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Component>) => r.body as Component)
    );
  }

  /**
   * Update a related item by id for components.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemPrototypeUpdateByIdComponents$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemPrototypeUpdateByIdComponents$Xml$Javascript$Response(params: {

    /**
     * Item id
     */
    id: string;

    /**
     * Foreign key for components
     */
    fk: string;
    body?: Component
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Component>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemPrototypeUpdateByIdComponentsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Component>;
      })
    );
  }

  /**
   * Update a related item by id for components.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemPrototypeUpdateByIdComponents$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemPrototypeUpdateByIdComponents$Xml$Javascript(params: {

    /**
     * Item id
     */
    id: string;

    /**
     * Foreign key for components
     */
    fk: string;
    body?: Component
  },
  context?: HttpContext

): Observable<Component> {

    return this.itemPrototypeUpdateByIdComponents$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Component>) => r.body as Component)
    );
  }

  /**
   * Path part for operation itemPrototypeGetInventories
   */
  static readonly ItemPrototypeGetInventoriesPath = '/v1/Items/{id}/inventories';

  /**
   * Queries inventories of Item.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemPrototypeGetInventories$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemPrototypeGetInventories$Json$Response(params: {

    /**
     * Item id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Inventory>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemPrototypeGetInventoriesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Inventory>>;
      })
    );
  }

  /**
   * Queries inventories of Item.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemPrototypeGetInventories$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemPrototypeGetInventories$Json(params: {

    /**
     * Item id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Inventory>> {

    return this.itemPrototypeGetInventories$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Inventory>>) => r.body as Array<Inventory>)
    );
  }

  /**
   * Queries inventories of Item.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemPrototypeGetInventories$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemPrototypeGetInventories$Xml$Response(params: {

    /**
     * Item id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Inventory>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemPrototypeGetInventoriesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Inventory>>;
      })
    );
  }

  /**
   * Queries inventories of Item.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemPrototypeGetInventories$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemPrototypeGetInventories$Xml(params: {

    /**
     * Item id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Inventory>> {

    return this.itemPrototypeGetInventories$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Inventory>>) => r.body as Array<Inventory>)
    );
  }

  /**
   * Queries inventories of Item.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemPrototypeGetInventories$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemPrototypeGetInventories$Javascript$Response(params: {

    /**
     * Item id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Inventory>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemPrototypeGetInventoriesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Inventory>>;
      })
    );
  }

  /**
   * Queries inventories of Item.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemPrototypeGetInventories$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemPrototypeGetInventories$Javascript(params: {

    /**
     * Item id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Inventory>> {

    return this.itemPrototypeGetInventories$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Inventory>>) => r.body as Array<Inventory>)
    );
  }

  /**
   * Path part for operation itemPrototypeCreateComponents
   */
  static readonly ItemPrototypeCreateComponentsPath = '/v1/Items/{id}/components';

  /**
   * Creates a new instance in components of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemPrototypeCreateComponents$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemPrototypeCreateComponents$Json$Json$Response(params: {

    /**
     * Item id
     */
    id: string;
    body?: Component
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Component>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemPrototypeCreateComponentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Component>;
      })
    );
  }

  /**
   * Creates a new instance in components of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemPrototypeCreateComponents$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemPrototypeCreateComponents$Json$Json(params: {

    /**
     * Item id
     */
    id: string;
    body?: Component
  },
  context?: HttpContext

): Observable<Component> {

    return this.itemPrototypeCreateComponents$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Component>) => r.body as Component)
    );
  }

  /**
   * Creates a new instance in components of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemPrototypeCreateComponents$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemPrototypeCreateComponents$Json$Xml$Response(params: {

    /**
     * Item id
     */
    id: string;
    body?: Component
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Component>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemPrototypeCreateComponentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Component>;
      })
    );
  }

  /**
   * Creates a new instance in components of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemPrototypeCreateComponents$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemPrototypeCreateComponents$Json$Xml(params: {

    /**
     * Item id
     */
    id: string;
    body?: Component
  },
  context?: HttpContext

): Observable<Component> {

    return this.itemPrototypeCreateComponents$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Component>) => r.body as Component)
    );
  }

  /**
   * Creates a new instance in components of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemPrototypeCreateComponents$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemPrototypeCreateComponents$Json$Javascript$Response(params: {

    /**
     * Item id
     */
    id: string;
    body?: Component
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Component>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemPrototypeCreateComponentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Component>;
      })
    );
  }

  /**
   * Creates a new instance in components of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemPrototypeCreateComponents$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemPrototypeCreateComponents$Json$Javascript(params: {

    /**
     * Item id
     */
    id: string;
    body?: Component
  },
  context?: HttpContext

): Observable<Component> {

    return this.itemPrototypeCreateComponents$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Component>) => r.body as Component)
    );
  }

  /**
   * Creates a new instance in components of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemPrototypeCreateComponents$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemPrototypeCreateComponents$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Item id
     */
    id: string;
    body?: Component
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Component>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemPrototypeCreateComponentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Component>;
      })
    );
  }

  /**
   * Creates a new instance in components of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemPrototypeCreateComponents$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemPrototypeCreateComponents$XWwwFormUrlencoded$Json(params: {

    /**
     * Item id
     */
    id: string;
    body?: Component
  },
  context?: HttpContext

): Observable<Component> {

    return this.itemPrototypeCreateComponents$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Component>) => r.body as Component)
    );
  }

  /**
   * Creates a new instance in components of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemPrototypeCreateComponents$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemPrototypeCreateComponents$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Item id
     */
    id: string;
    body?: Component
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Component>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemPrototypeCreateComponentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Component>;
      })
    );
  }

  /**
   * Creates a new instance in components of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemPrototypeCreateComponents$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemPrototypeCreateComponents$XWwwFormUrlencoded$Xml(params: {

    /**
     * Item id
     */
    id: string;
    body?: Component
  },
  context?: HttpContext

): Observable<Component> {

    return this.itemPrototypeCreateComponents$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Component>) => r.body as Component)
    );
  }

  /**
   * Creates a new instance in components of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemPrototypeCreateComponents$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemPrototypeCreateComponents$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Item id
     */
    id: string;
    body?: Component
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Component>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemPrototypeCreateComponentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Component>;
      })
    );
  }

  /**
   * Creates a new instance in components of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemPrototypeCreateComponents$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemPrototypeCreateComponents$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Item id
     */
    id: string;
    body?: Component
  },
  context?: HttpContext

): Observable<Component> {

    return this.itemPrototypeCreateComponents$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Component>) => r.body as Component)
    );
  }

  /**
   * Creates a new instance in components of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemPrototypeCreateComponents$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemPrototypeCreateComponents$Xml$Json$Response(params: {

    /**
     * Item id
     */
    id: string;
    body?: Component
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Component>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemPrototypeCreateComponentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Component>;
      })
    );
  }

  /**
   * Creates a new instance in components of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemPrototypeCreateComponents$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemPrototypeCreateComponents$Xml$Json(params: {

    /**
     * Item id
     */
    id: string;
    body?: Component
  },
  context?: HttpContext

): Observable<Component> {

    return this.itemPrototypeCreateComponents$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Component>) => r.body as Component)
    );
  }

  /**
   * Creates a new instance in components of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemPrototypeCreateComponents$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemPrototypeCreateComponents$Xml$Xml$Response(params: {

    /**
     * Item id
     */
    id: string;
    body?: Component
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Component>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemPrototypeCreateComponentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Component>;
      })
    );
  }

  /**
   * Creates a new instance in components of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemPrototypeCreateComponents$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemPrototypeCreateComponents$Xml$Xml(params: {

    /**
     * Item id
     */
    id: string;
    body?: Component
  },
  context?: HttpContext

): Observable<Component> {

    return this.itemPrototypeCreateComponents$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Component>) => r.body as Component)
    );
  }

  /**
   * Creates a new instance in components of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemPrototypeCreateComponents$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemPrototypeCreateComponents$Xml$Javascript$Response(params: {

    /**
     * Item id
     */
    id: string;
    body?: Component
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Component>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemPrototypeCreateComponentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Component>;
      })
    );
  }

  /**
   * Creates a new instance in components of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemPrototypeCreateComponents$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemPrototypeCreateComponents$Xml$Javascript(params: {

    /**
     * Item id
     */
    id: string;
    body?: Component
  },
  context?: HttpContext

): Observable<Component> {

    return this.itemPrototypeCreateComponents$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Component>) => r.body as Component)
    );
  }

  /**
   * Path part for operation itemFindById
   */
  static readonly ItemFindByIdPath = '/v1/Items/{id}';

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemFindById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemFindById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemFindById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemFindById$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Item> {

    return this.itemFindById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemFindById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemFindById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemFindById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemFindById$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Item> {

    return this.itemFindById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemFindById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemFindById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemFindById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemFindById$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Item> {

    return this.itemFindById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Path part for operation itemReplaceByIdPutItemsId
   */
  static readonly ItemReplaceByIdPutItemsIdPath = '/v1/Items/{id}';

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemReplaceByIdPutItemsId$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemReplaceByIdPutItemsId$Json$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemReplaceByIdPutItemsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemReplaceByIdPutItemsId$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemReplaceByIdPutItemsId$Json$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<Item> {

    return this.itemReplaceByIdPutItemsId$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemReplaceByIdPutItemsId$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemReplaceByIdPutItemsId$Json$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemReplaceByIdPutItemsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemReplaceByIdPutItemsId$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemReplaceByIdPutItemsId$Json$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<Item> {

    return this.itemReplaceByIdPutItemsId$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemReplaceByIdPutItemsId$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemReplaceByIdPutItemsId$Json$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemReplaceByIdPutItemsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemReplaceByIdPutItemsId$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemReplaceByIdPutItemsId$Json$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<Item> {

    return this.itemReplaceByIdPutItemsId$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemReplaceByIdPutItemsId$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemReplaceByIdPutItemsId$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemReplaceByIdPutItemsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemReplaceByIdPutItemsId$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemReplaceByIdPutItemsId$XWwwFormUrlencoded$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<Item> {

    return this.itemReplaceByIdPutItemsId$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemReplaceByIdPutItemsId$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemReplaceByIdPutItemsId$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemReplaceByIdPutItemsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemReplaceByIdPutItemsId$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemReplaceByIdPutItemsId$XWwwFormUrlencoded$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<Item> {

    return this.itemReplaceByIdPutItemsId$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemReplaceByIdPutItemsId$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemReplaceByIdPutItemsId$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemReplaceByIdPutItemsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemReplaceByIdPutItemsId$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemReplaceByIdPutItemsId$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<Item> {

    return this.itemReplaceByIdPutItemsId$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemReplaceByIdPutItemsId$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemReplaceByIdPutItemsId$Xml$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemReplaceByIdPutItemsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemReplaceByIdPutItemsId$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemReplaceByIdPutItemsId$Xml$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<Item> {

    return this.itemReplaceByIdPutItemsId$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemReplaceByIdPutItemsId$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemReplaceByIdPutItemsId$Xml$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemReplaceByIdPutItemsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemReplaceByIdPutItemsId$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemReplaceByIdPutItemsId$Xml$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<Item> {

    return this.itemReplaceByIdPutItemsId$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemReplaceByIdPutItemsId$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemReplaceByIdPutItemsId$Xml$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemReplaceByIdPutItemsIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemReplaceByIdPutItemsId$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemReplaceByIdPutItemsId$Xml$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<Item> {

    return this.itemReplaceByIdPutItemsId$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Path part for operation itemDeleteById
   */
  static readonly ItemDeleteByIdPath = '/v1/Items/{id}';

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemDeleteById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemDeleteById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemDeleteById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemDeleteById$Json(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.itemDeleteById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemDeleteById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemDeleteById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemDeleteById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemDeleteById$Xml(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.itemDeleteById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemDeleteById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemDeleteById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemDeleteById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemDeleteById$Javascript(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.itemDeleteById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation itemReplaceByIdPostItemsIdReplace
   */
  static readonly ItemReplaceByIdPostItemsIdReplacePath = '/v1/Items/{id}/replace';

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemReplaceByIdPostItemsIdReplace$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemReplaceByIdPostItemsIdReplace$Json$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemReplaceByIdPostItemsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemReplaceByIdPostItemsIdReplace$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemReplaceByIdPostItemsIdReplace$Json$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<Item> {

    return this.itemReplaceByIdPostItemsIdReplace$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemReplaceByIdPostItemsIdReplace$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemReplaceByIdPostItemsIdReplace$Json$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemReplaceByIdPostItemsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemReplaceByIdPostItemsIdReplace$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemReplaceByIdPostItemsIdReplace$Json$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<Item> {

    return this.itemReplaceByIdPostItemsIdReplace$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemReplaceByIdPostItemsIdReplace$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemReplaceByIdPostItemsIdReplace$Json$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemReplaceByIdPostItemsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemReplaceByIdPostItemsIdReplace$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itemReplaceByIdPostItemsIdReplace$Json$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<Item> {

    return this.itemReplaceByIdPostItemsIdReplace$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemReplaceByIdPostItemsIdReplace$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemReplaceByIdPostItemsIdReplace$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemReplaceByIdPostItemsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemReplaceByIdPostItemsIdReplace$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemReplaceByIdPostItemsIdReplace$XWwwFormUrlencoded$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<Item> {

    return this.itemReplaceByIdPostItemsIdReplace$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemReplaceByIdPostItemsIdReplace$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemReplaceByIdPostItemsIdReplace$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemReplaceByIdPostItemsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemReplaceByIdPostItemsIdReplace$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemReplaceByIdPostItemsIdReplace$XWwwFormUrlencoded$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<Item> {

    return this.itemReplaceByIdPostItemsIdReplace$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemReplaceByIdPostItemsIdReplace$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemReplaceByIdPostItemsIdReplace$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemReplaceByIdPostItemsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemReplaceByIdPostItemsIdReplace$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  itemReplaceByIdPostItemsIdReplace$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<Item> {

    return this.itemReplaceByIdPostItemsIdReplace$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemReplaceByIdPostItemsIdReplace$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemReplaceByIdPostItemsIdReplace$Xml$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemReplaceByIdPostItemsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemReplaceByIdPostItemsIdReplace$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemReplaceByIdPostItemsIdReplace$Xml$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<Item> {

    return this.itemReplaceByIdPostItemsIdReplace$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemReplaceByIdPostItemsIdReplace$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemReplaceByIdPostItemsIdReplace$Xml$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemReplaceByIdPostItemsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemReplaceByIdPostItemsIdReplace$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemReplaceByIdPostItemsIdReplace$Xml$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<Item> {

    return this.itemReplaceByIdPostItemsIdReplace$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemReplaceByIdPostItemsIdReplace$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemReplaceByIdPostItemsIdReplace$Xml$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, ItemService.ItemReplaceByIdPostItemsIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `itemReplaceByIdPostItemsIdReplace$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  itemReplaceByIdPostItemsIdReplace$Xml$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Item
  },
  context?: HttpContext

): Observable<Item> {

    return this.itemReplaceByIdPostItemsIdReplace$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

}
