/* tslint:disable */
import {
  Tax
} from '../index';

declare var Object: any;
export interface DocumentItemTaxInterface {
  "id"?: string;
  "rate": number;
  "reverseCharged": boolean;
  "name"?: string;
  "abbreviation"?: string;
  "recoverable": boolean;
  "compound": boolean;
  "taxNumber"?: string;
  "taxId"?: any;
  tax?: Tax;
}

export class DocumentItemTax implements DocumentItemTaxInterface {
  "id": string;
  "rate": number;
  "reverseCharged": boolean;
  "name": string;
  "abbreviation": string;
  "recoverable": boolean;
  "compound": boolean;
  "taxNumber": string;
  "taxId": any;
  tax: Tax;
  constructor(data?: DocumentItemTaxInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DocumentItemTax`.
   */
  public static getModelName() {
    return "DocumentItemTax";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of DocumentItemTax for dynamic purposes.
  **/
  public static factory(data: DocumentItemTaxInterface): DocumentItemTax{
    return new DocumentItemTax(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'DocumentItemTax',
      plural: 'DocumentItemTaxes',
      path: 'DocumentItemTaxes',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "rate": {
          name: 'rate',
          type: 'number'
        },
        "reverseCharged": {
          name: 'reverseCharged',
          type: 'boolean',
          default: false
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "abbreviation": {
          name: 'abbreviation',
          type: 'string'
        },
        "recoverable": {
          name: 'recoverable',
          type: 'boolean',
          default: true
        },
        "compound": {
          name: 'compound',
          type: 'boolean',
          default: false
        },
        "taxNumber": {
          name: 'taxNumber',
          type: 'string'
        },
        "taxId": {
          name: 'taxId',
          type: 'any'
        },
      },
      relations: {
        tax: {
          name: 'tax',
          type: 'Tax',
          model: 'Tax',
          relationType: 'belongsTo',
                  keyFrom: 'taxId',
          keyTo: 'id'
        },
      }
    }
  }
}
