import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { LanguageService, languages } from './shared/services/language.service';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  Event as RouterEvent,
} from '@angular/router';

import { Angulartics2GoogleTagManager } from 'angulartics2';
import { ConnectionService } from './shared/services/connection.service';
import { HttpClient } from '@angular/common/http';
import { LoadingService } from './shared/services/loading.service';
import { LoggerService } from './shared/sdk';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ThemeService } from './shared/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { UpdateService } from './shared/services/update.service';
import { WhiteLabelService } from './shared/services/whitelabel.service';
import { environment } from '../environments/environment';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('spinnerElement') spinnerElement: ElementRef;
  deferredPrompt: any;
  hasUpdate = false;
  loading: boolean;
  showAddToHomeButton = false;
  private currentLang: string;
  private onDestroy$: Subject<void> = new Subject();

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e: { preventDefault: () => void }) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    this.deferredPrompt = e;
    this.showAddToHomeButton = true;
    this.cdr.markForCheck();
  }

  constructor(
    angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    ngbModalConfig: NgbModalConfig,
    public themeService: ThemeService,
    private cdr: ChangeDetectorRef,
    private connectionService: ConnectionService,
    private http: HttpClient,
    private languageService: LanguageService,
    private loadingService: LoadingService,
    private log: LoggerService,
    private ngZone: NgZone,
    private renderer: Renderer2,
    private router: Router,
    private translate: TranslateService,
    private updateService: UpdateService,
    private WLs: WhiteLabelService,
  ) {
    ngbModalConfig.backdrop = 'static';
    ngbModalConfig.centered = true;

    this.router.events
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((event: RouterEvent) => {
        this.navigationInterceptor(event);
      });

    angulartics2GoogleTagManager.startTracking();

    // Loading for routes
    this.loadingService.loading.subscribe((loading) => {
      // this.loading = loading;
      this.toggleSpinner(loading);
    });
  }

  ngOnInit(): void {
    this.log.log('AppComponent: ngOnInit');
    this.themeService.set('dashkit');

    console.log('VERSION:', environment.version);

    // Server status check
    this.http.get(environment.apiUrl).subscribe(
      (res) => console.log('API STATUS:', res),
      (err) => console.log(err),
    );

    // i18n
    const languagesA = Object.keys(languages);
    this.translate.addLangs(languagesA);
    this.translate.setDefaultLang(this.languageService.default);

    this.languageService.current
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((current) => {
        this.log.log('LANGUAGE:', current);

        if (
          (!this.currentLang && current !== this.languageService.default) ||
          (this.currentLang && current !== this.currentLang)
        ) {
          this.translate.use(current);
          this.currentLang = current;
        }

        // Not set when app starts
        if (!this.currentLang) {
          this.currentLang = this.languageService.default;
        }

        this.cdr.markForCheck();
      });

    // Monitor online status
    this.connectionService.monitor();

    this.updateService.isUpdateAvailable
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((update) => {
        this.hasUpdate = update && !this.WLs.isWhiteLabel;
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onActivateUpdate() {
    this.updateService.onActivateUpdate();
  }

  onAddToHomeScreen() {
    this.showAddToHomeButton = false;
    this.deferredPrompt.prompt();

    this.deferredPrompt.userChoice.then((choiceResult: { outcome: string }) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }

      this.deferredPrompt = null;
      this.cdr.markForCheck();
    });
  }

  private scrollTop() {
    window.scrollTo(0, 0);
  }

  /**
   *  Shows and hides the loading spinner during RouterEvent changes
   */
  private navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.log.log('ROUTING START', event.url);
      this.loadingService.setLoading();
      this.cdr.markForCheck();
    }

    if (event instanceof NavigationEnd) {
      this.log.log('ROUTING END', event.url);

      this.scrollTop();
      this.loadingService.unsetLoading();
      this.cdr.markForCheck();
    }

    // Set loading state to false in both of the below events to hide the
    // spinner in case a request fails
    if (event instanceof NavigationCancel || event instanceof NavigationError) {
      this.loadingService.unsetLoading();
      this.cdr.markForCheck();
    }
  }

  private toggleSpinner(show: boolean) {
    if (this.spinnerElement) {
      this.ngZone.runOutsideAngular(() => {
        this.renderer[show ? 'addClass' : 'removeClass'](
          this.spinnerElement.nativeElement,
          'visible',
        );
      });

      this.cdr.markForCheck();
    }
  }
}
