/* tslint:disable */
import {
  DocumentItem,
  Organization,
  Document,
  Account,
  PriceList,
  Data
} from '../index';

declare var Object: any;
export interface DocumentHistoryInterface {
  "number"?: string;
  "draft": boolean;
  "date"?: Date;
  "dateService"?: Date;
  "dateServiceTo"?: Date;
  "dateDue"?: Date;
  "dateValidTill"?: Date;
  "datePaid"?: Date;
  "dateReceived"?: Date;
  "reference"?: string;
  "total"?: number;
  "totalConverted"?: number;
  "totalDiscount"?: number;
  "totalWithTax"?: number;
  "totalWithTaxConverted"?: number;
  "decimalPlaces"?: number;
  "note"?: string;
  "taxClause"?: string;
  "omitTaxes"?: boolean;
  "footer"?: string;
  "signature"?: string;
  "type": string;
  "displayName"?: string;
  "canceled": boolean;
  "sentSnailMail": boolean;
  "totalPaid": number;
  "totalDue"?: number;
  "paidInFull": boolean;
  "currencyId": string;
  "_documentTaxes"?: Array<any>;
  "_documentReverseTaxes"?: Array<any>;
  "hasUnit"?: boolean;
  "validateEslog"?: boolean;
  "isValidEslog"?: boolean;
  "uniqueId"?: string;
  "customTotals"?: boolean;
  "incoming"?: boolean;
  "numberInternal"?: string;
  "equalization"?: number;
  "sandbox"?: boolean;
  "issuedAt"?: Date;
  "dateYear"?: number;
  "id"?: any;
  "_documentIssuer"?: any;
  "_documentClient"?: any;
  "_documentItems"?: Array<any>;
  "organizationId"?: any;
  "documentIds"?: Array<any>;
  "accountId"?: any;
  "documentId"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "custom"?: any;
  "l10n"?: any;
  "_furs"?: any;
  "_fina"?: any;
  "_documentConversionRate"?: any;
  "_documentUnit"?: any;
  "priceListId"?: any;
  "_data"?: Array<any>;
  "_bPay"?: any;
  documentIssuer?: any[];
  documentClient?: any[];
  documentItems?: DocumentItem[];
  organization?: Organization;
  documents?: Document[];
  account?: Account;
  document?: Document;
  furs?: any[];
  fina?: any[];
  documentConversionRate?: any[];
  documentUnit?: any[];
  priceList?: PriceList;
  data?: Data[];
  bPay?: any[];
}

export class DocumentHistory implements DocumentHistoryInterface {
  "number": string;
  "draft": boolean;
  "date": Date;
  "dateService": Date;
  "dateServiceTo": Date;
  "dateDue": Date;
  "dateValidTill": Date;
  "datePaid": Date;
  "dateReceived": Date;
  "reference": string;
  "total": number;
  "totalConverted": number;
  "totalDiscount": number;
  "totalWithTax": number;
  "totalWithTaxConverted": number;
  "decimalPlaces": number;
  "note": string;
  "taxClause": string;
  "omitTaxes": boolean;
  "footer": string;
  "signature": string;
  "type": string;
  "displayName": string;
  "canceled": boolean;
  "sentSnailMail": boolean;
  "totalPaid": number;
  "totalDue": number;
  "paidInFull": boolean;
  "currencyId": string;
  "_documentTaxes": Array<any>;
  "_documentReverseTaxes": Array<any>;
  "hasUnit": boolean;
  "validateEslog": boolean;
  "isValidEslog": boolean;
  "uniqueId": string;
  "customTotals": boolean;
  "incoming": boolean;
  "numberInternal": string;
  "equalization": number;
  "sandbox": boolean;
  "issuedAt": Date;
  "dateYear": number;
  "id": any;
  "_documentIssuer": any;
  "_documentClient": any;
  "_documentItems": Array<any>;
  "organizationId": any;
  "documentIds": Array<any>;
  "accountId": any;
  "documentId": any;
  "createdAt": Date;
  "updatedAt": Date;
  "custom": any;
  "l10n": any;
  "_furs": any;
  "_fina": any;
  "_documentConversionRate": any;
  "_documentUnit": any;
  "priceListId": any;
  "_data": Array<any>;
  "_bPay": any;
  documentIssuer: any[];
  documentClient: any[];
  documentItems: DocumentItem[];
  organization: Organization;
  documents: Document[];
  account: Account;
  document: Document;
  furs: any[];
  fina: any[];
  documentConversionRate: any[];
  documentUnit: any[];
  priceList: PriceList;
  data: Data[];
  bPay: any[];
  constructor(data?: DocumentHistoryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DocumentHistory`.
   */
  public static getModelName() {
    return "DocumentHistory";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of DocumentHistory for dynamic purposes.
  **/
  public static factory(data: DocumentHistoryInterface): DocumentHistory{
    return new DocumentHistory(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'DocumentHistory',
      plural: 'DocumentHistories',
      path: 'DocumentHistories',
      idName: 'id',
      properties: {
        "number": {
          name: 'number',
          type: 'string'
        },
        "draft": {
          name: 'draft',
          type: 'boolean',
          default: false
        },
        "date": {
          name: 'date',
          type: 'Date'
        },
        "dateService": {
          name: 'dateService',
          type: 'Date'
        },
        "dateServiceTo": {
          name: 'dateServiceTo',
          type: 'Date'
        },
        "dateDue": {
          name: 'dateDue',
          type: 'Date'
        },
        "dateValidTill": {
          name: 'dateValidTill',
          type: 'Date'
        },
        "datePaid": {
          name: 'datePaid',
          type: 'Date'
        },
        "dateReceived": {
          name: 'dateReceived',
          type: 'Date'
        },
        "reference": {
          name: 'reference',
          type: 'string'
        },
        "total": {
          name: 'total',
          type: 'number'
        },
        "totalConverted": {
          name: 'totalConverted',
          type: 'number'
        },
        "totalDiscount": {
          name: 'totalDiscount',
          type: 'number'
        },
        "totalWithTax": {
          name: 'totalWithTax',
          type: 'number'
        },
        "totalWithTaxConverted": {
          name: 'totalWithTaxConverted',
          type: 'number'
        },
        "decimalPlaces": {
          name: 'decimalPlaces',
          type: 'number',
          default: 4
        },
        "note": {
          name: 'note',
          type: 'string'
        },
        "taxClause": {
          name: 'taxClause',
          type: 'string'
        },
        "omitTaxes": {
          name: 'omitTaxes',
          type: 'boolean'
        },
        "footer": {
          name: 'footer',
          type: 'string'
        },
        "signature": {
          name: 'signature',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string',
          default: 'invoice'
        },
        "displayName": {
          name: 'displayName',
          type: 'string'
        },
        "canceled": {
          name: 'canceled',
          type: 'boolean',
          default: false
        },
        "sentSnailMail": {
          name: 'sentSnailMail',
          type: 'boolean',
          default: false
        },
        "totalPaid": {
          name: 'totalPaid',
          type: 'number',
          default: 0
        },
        "totalDue": {
          name: 'totalDue',
          type: 'number',
          default: 0
        },
        "paidInFull": {
          name: 'paidInFull',
          type: 'boolean',
          default: false
        },
        "currencyId": {
          name: 'currencyId',
          type: 'string'
        },
        "_documentTaxes": {
          name: '_documentTaxes',
          type: 'Array&lt;any&gt;'
        },
        "_documentReverseTaxes": {
          name: '_documentReverseTaxes',
          type: 'Array&lt;any&gt;'
        },
        "hasUnit": {
          name: 'hasUnit',
          type: 'boolean'
        },
        "validateEslog": {
          name: 'validateEslog',
          type: 'boolean'
        },
        "isValidEslog": {
          name: 'isValidEslog',
          type: 'boolean'
        },
        "uniqueId": {
          name: 'uniqueId',
          type: 'string'
        },
        "customTotals": {
          name: 'customTotals',
          type: 'boolean'
        },
        "incoming": {
          name: 'incoming',
          type: 'boolean',
          default: false
        },
        "numberInternal": {
          name: 'numberInternal',
          type: 'string'
        },
        "equalization": {
          name: 'equalization',
          type: 'number'
        },
        "sandbox": {
          name: 'sandbox',
          type: 'boolean'
        },
        "issuedAt": {
          name: 'issuedAt',
          type: 'Date'
        },
        "dateYear": {
          name: 'dateYear',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "_documentIssuer": {
          name: '_documentIssuer',
          type: 'any'
        },
        "_documentClient": {
          name: '_documentClient',
          type: 'any'
        },
        "_documentItems": {
          name: '_documentItems',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "organizationId": {
          name: 'organizationId',
          type: 'any'
        },
        "documentIds": {
          name: 'documentIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "accountId": {
          name: 'accountId',
          type: 'any'
        },
        "documentId": {
          name: 'documentId',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        "custom": {
          name: 'custom',
          type: 'any',
          default: <any>null
        },
        "l10n": {
          name: 'l10n',
          type: 'any'
        },
        "_furs": {
          name: '_furs',
          type: 'any'
        },
        "_fina": {
          name: '_fina',
          type: 'any'
        },
        "_documentConversionRate": {
          name: '_documentConversionRate',
          type: 'any'
        },
        "_documentUnit": {
          name: '_documentUnit',
          type: 'any'
        },
        "priceListId": {
          name: 'priceListId',
          type: 'any'
        },
        "_data": {
          name: '_data',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "_bPay": {
          name: '_bPay',
          type: 'any'
        },
      },
      relations: {
        documentIssuer: {
          name: 'documentIssuer',
          type: 'any[]',
          model: '',
          relationType: 'embedsOne',
                  keyFrom: '_documentIssuer',
          keyTo: 'id'
        },
        documentClient: {
          name: 'documentClient',
          type: 'any[]',
          model: '',
          relationType: 'embedsOne',
                  keyFrom: '_documentClient',
          keyTo: 'id'
        },
        documentItems: {
          name: 'documentItems',
          type: 'DocumentItem[]',
          model: 'DocumentItem',
          relationType: 'embedsMany',
                  keyFrom: '_documentItems',
          keyTo: 'id'
        },
        organization: {
          name: 'organization',
          type: 'Organization',
          model: 'Organization',
          relationType: 'belongsTo',
                  keyFrom: 'organizationId',
          keyTo: 'id'
        },
        documents: {
          name: 'documents',
          type: 'Document[]',
          model: 'Document',
          relationType: 'referencesMany',
                  keyFrom: 'documentIds',
          keyTo: 'id'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'accountId',
          keyTo: 'id'
        },
        document: {
          name: 'document',
          type: 'Document',
          model: 'Document',
          relationType: 'belongsTo',
                  keyFrom: 'documentId',
          keyTo: 'id'
        },
        furs: {
          name: 'furs',
          type: 'any[]',
          model: '',
          relationType: 'embedsOne',
                  keyFrom: '_furs',
          keyTo: 'id'
        },
        fina: {
          name: 'fina',
          type: 'any[]',
          model: '',
          relationType: 'embedsOne',
                  keyFrom: '_fina',
          keyTo: 'id'
        },
        documentConversionRate: {
          name: 'documentConversionRate',
          type: 'any[]',
          model: '',
          relationType: 'embedsOne',
                  keyFrom: '_documentConversionRate',
          keyTo: 'id'
        },
        documentUnit: {
          name: 'documentUnit',
          type: 'any[]',
          model: '',
          relationType: 'embedsOne',
                  keyFrom: '_documentUnit',
          keyTo: 'id'
        },
        priceList: {
          name: 'priceList',
          type: 'PriceList',
          model: 'PriceList',
          relationType: 'belongsTo',
                  keyFrom: 'priceListId',
          keyTo: 'id'
        },
        data: {
          name: 'data',
          type: 'Data[]',
          model: 'Data',
          relationType: 'embedsMany',
                  keyFrom: '_data',
          keyTo: 'id'
        },
        bPay: {
          name: 'bPay',
          type: 'any[]',
          model: '',
          relationType: 'embedsOne',
                  keyFrom: '_bPay',
          keyTo: 'id'
        },
      }
    }
  }
}
