import { Injectable } from '@angular/core';
import { LoopBackConfig, LoggerService } from '../shared/sdk';
import { PaymentApi, OrganizationApi } from '../shared/sdk/services';
import { Payment } from '../shared/sdk/models';
import { ToastService } from '../shared/modules/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { BASE_URL, API_VERSION } from '../shared/base-url';
import { ApiService } from '../shared/services/api.service';
import { CacheService } from '../shared/services/cache.service';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

export const paymentTypes = [
  {
    value: 'bank',
    name: 'Bank',
  },
  {
    value: 'online',
    name: 'Online',
  },
  {
    value: 'paypal',
    name: 'Paypal',
  },
  {
    value: 'cash',
    name: 'Cash',
  },
  {
    value: 'card',
    name: 'Card',
  },
  {
    value: 'coupon',
    name: 'Coupon',
  },
  {
    value: 'crypto',
    name: 'Crypto currency',
  },
  {
    value: 'other',
    name: 'Other',
  },
];

@Injectable()
export class PaymentService extends ApiService<Payment> {
  paymentTypes = paymentTypes;

  constructor(
    protected cacheService: CacheService,
    protected log: LoggerService,
    protected toastService: ToastService,
    protected translate: TranslateService,
    private paymentApi: PaymentApi,
    private orgApi: OrganizationApi,
  ) {
    super(cacheService, log, toastService, translate);
    this.name = 'Payment';
    this.plural = 'Payments';

    LoopBackConfig.setBaseURL(BASE_URL);
    LoopBackConfig.setApiVersion(API_VERSION);
  }

  count(
    orgId: string,
    filter: any = {},
    force = false,
    toastr = true,
  ): Observable<number> {
    return super.count(orgId, filter, force, toastr, this.orgApi);
  }

  create(orgId: string, data: any, toastr = true): Observable<Payment> {
    return super.create(orgId, data, toastr, this.orgApi);
  }

  delete(id: string, toastr = true): Observable<boolean> {
    return super.delete(id, toastr, this.paymentApi);
  }

  // Alternative to delete as it needs to call org api instead of payment api
  destroy(orgId: string, id: string, toastr = true): Observable<boolean> {
    return this.orgApi.destroyByIdPayments(orgId, id).pipe(
      map(() => {
        this.cacheService.clearCache();

        if (toastr) {
          this.toastService.success(
            this.translate.instant(`${this.name} successfully deleted.`),
          );
        }

        return true;
      }),
      catchError((err) => {
        this.toastService.error(
          this.translate.instant(
            `Error deleting ${this.name}, please try again or contact support.`,
          ),
        );

        return throwError(`Error deleting ${this.name}`);
      }),
    );
  }

  get(
    orgId: string,
    filter: any = {},
    force = false,
    toastr = true,
  ): Observable<{ body: Payment[]; totalCount: number }> {
    return super.get(orgId, filter, force, toastr, this.orgApi);
  }

  getById(
    id: string,
    filter = {},
    force = true,
    toastr = true,
  ): Observable<Payment> {
    return super.getById(id, filter, force, toastr, this.paymentApi);
  }

  update(id: string, data: any, toastr = true): Observable<Payment> {
    return super.update(id, data, toastr, this.paymentApi);
  }
}
