/* tslint:disable */
import {
  Organization,
  Account,
  FileMeta,
  Document
} from '../index';

declare var Object: any;
export interface TyplessInterface {
  "pollKey": string;
  "rawResult"?: any;
  "success"?: boolean;
  "message"?: string;
  "messageType"?: string;
  "id"?: any;
  "organizationId"?: any;
  "accountId"?: any;
  "fileMetaId"?: any;
  "documentId"?: any;
  organization?: Organization;
  account?: Account;
  fileMeta?: FileMeta;
  document?: Document;
}

export class Typless implements TyplessInterface {
  "pollKey": string;
  "rawResult": any;
  "success": boolean;
  "message": string;
  "messageType": string;
  "id": any;
  "organizationId": any;
  "accountId": any;
  "fileMetaId": any;
  "documentId": any;
  organization: Organization;
  account: Account;
  fileMeta: FileMeta;
  document: Document;
  constructor(data?: TyplessInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Typless`.
   */
  public static getModelName() {
    return "Typless";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Typless for dynamic purposes.
  **/
  public static factory(data: TyplessInterface): Typless{
    return new Typless(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Typless',
      plural: 'Typless',
      path: 'Typless',
      idName: 'id',
      properties: {
        "pollKey": {
          name: 'pollKey',
          type: 'string'
        },
        "rawResult": {
          name: 'rawResult',
          type: 'any'
        },
        "success": {
          name: 'success',
          type: 'boolean'
        },
        "message": {
          name: 'message',
          type: 'string'
        },
        "messageType": {
          name: 'messageType',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "organizationId": {
          name: 'organizationId',
          type: 'any'
        },
        "accountId": {
          name: 'accountId',
          type: 'any'
        },
        "fileMetaId": {
          name: 'fileMetaId',
          type: 'any'
        },
        "documentId": {
          name: 'documentId',
          type: 'any'
        },
      },
      relations: {
        organization: {
          name: 'organization',
          type: 'Organization',
          model: 'Organization',
          relationType: 'belongsTo',
                  keyFrom: 'organizationId',
          keyTo: 'id'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'accountId',
          keyTo: 'id'
        },
        fileMeta: {
          name: 'fileMeta',
          type: 'FileMeta',
          model: 'FileMeta',
          relationType: 'belongsTo',
                  keyFrom: 'fileMetaId',
          keyTo: 'id'
        },
        document: {
          name: 'document',
          type: 'Document',
          model: 'Document',
          relationType: 'belongsTo',
                  keyFrom: 'documentId',
          keyTo: 'id'
        },
      }
    }
  }
}
