import { Injectable } from '@angular/core';
import { LoopBackConfig, LoggerService } from '../sdk';
import { CompanyApi } from '../sdk/services';
import { Company } from '../sdk/models';

import { BASE_URL, API_VERSION } from '../base-url';
import { of, Observable } from 'rxjs';
import { mergeMap, map, switchMap } from 'rxjs/operators';

@Injectable()
export class CompanyService {
  private companies: any;

  constructor(private companyApi: CompanyApi, private log: LoggerService) {
    LoopBackConfig.setBaseURL(BASE_URL);
    LoopBackConfig.setApiVersion(API_VERSION);

    this.companies = {};
  }

  find(term: string, country = ''): Observable<Company[]> {
    this.log.log('companies.service find');

    return of(!!this.companies[term]).pipe(
      switchMap((useCache: boolean) => {
        if (useCache) {
          this.log.log('CompaniesService: find Loading from cache');
          return of(this.companies[term]);
        } else {
          this.log.log('CompaniesService: find Loading from API');
          return this.companyApi
            .search(term, country)
            .pipe(map(({ body }) => body));
        }
      }),
      map((companies: Company[]) => {
        this.log.log('CompaniesService: find Loaded');
        this.companies[term] = companies;
        return companies;
      }),
    );
  }
}
