/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountOrganization } from '../models/account-organization';
import { Bokun } from '../models/bokun';
import { BusinessPremise } from '../models/business-premise';
import { Category } from '../models/category';
import { Client } from '../models/client';
import { CustomIntegration } from '../models/custom-integration';
import { Default } from '../models/default';
import { Document } from '../models/document';
import { DocumentCategory } from '../models/document-category';
import { ElectronicDevice } from '../models/electronic-device';
import { Item } from '../models/item';
import { Magento1 } from '../models/magento-1';
import { Order } from '../models/order';
import { Organization } from '../models/organization';
import { Payment } from '../models/payment';
import { PriceList } from '../models/price-list';
import { Recurrence } from '../models/recurrence';
import { Shopamine } from '../models/shopamine';
import { Shopify } from '../models/shopify';
import { Stripe } from '../models/stripe';
import { Tax } from '../models/tax';
import { Thrivecart } from '../models/thrivecart';
import { TicketingHub } from '../models/ticketing-hub';
import { Unit } from '../models/unit';
import { Warehouse } from '../models/warehouse';
import { Webhook } from '../models/webhook';
import { Woocommerce } from '../models/woocommerce';
import { NewBokun } from '../models/new-bokun';
import { NewBusinessPremise } from '../models/new-business-premise';
import { NewCategory } from '../models/new-category';
import { NewClient } from '../models/new-client';
import { NewCustomIntegration } from '../models/new-custom-integration';
import { NewDefault } from '../models/new-default';
import { NewDocument } from '../models/new-document';
import { NewElectronicDevice } from '../models/new-electronic-device';
import { NewItem } from '../models/new-item';
import { NewMagento1 } from '../models/new-magento-1';
import { NewPayment } from '../models/new-payment';
import { NewPriceList } from '../models/new-price-list';
import { NewShopamine } from '../models/new-shopamine';
import { NewShopify } from '../models/new-shopify';
import { NewStripe } from '../models/new-stripe';
import { NewTax } from '../models/new-tax';
import { NewThrivecart } from '../models/new-thrivecart';
import { NewTicketingHub } from '../models/new-ticketing-hub';
import { NewUnit } from '../models/new-unit';
import { NewWarehouse } from '../models/new-warehouse';
import { NewWebhook } from '../models/new-webhook';
import { NewWoocommerce } from '../models/new-woocommerce';
import { XAny } from '../models/x-any';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation organizationPrototypeUpdateByIdDefaults
   */
  static readonly OrganizationPrototypeUpdateByIdDefaultsPath = '/v1/Organizations/{id}/defaults/{fk}';

  /**
   * Update a related item by id for _defaults.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeUpdateByIdDefaults$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeUpdateByIdDefaults$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Foreign key for _defaults
     */
    fk: string;
    body?: Default
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeUpdateByIdDefaultsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Update a related item by id for _defaults.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeUpdateByIdDefaults$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeUpdateByIdDefaults$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Foreign key for _defaults
     */
    fk: string;
    body?: Default
  },
  context?: HttpContext

): Observable<Default> {

    return this.organizationPrototypeUpdateByIdDefaults$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Update a related item by id for _defaults.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeUpdateByIdDefaults$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeUpdateByIdDefaults$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Foreign key for _defaults
     */
    fk: string;
    body?: Default
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeUpdateByIdDefaultsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Update a related item by id for _defaults.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeUpdateByIdDefaults$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeUpdateByIdDefaults$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Foreign key for _defaults
     */
    fk: string;
    body?: Default
  },
  context?: HttpContext

): Observable<Default> {

    return this.organizationPrototypeUpdateByIdDefaults$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Update a related item by id for _defaults.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeUpdateByIdDefaults$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeUpdateByIdDefaults$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Foreign key for _defaults
     */
    fk: string;
    body?: Default
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeUpdateByIdDefaultsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Update a related item by id for _defaults.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeUpdateByIdDefaults$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeUpdateByIdDefaults$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Foreign key for _defaults
     */
    fk: string;
    body?: Default
  },
  context?: HttpContext

): Observable<Default> {

    return this.organizationPrototypeUpdateByIdDefaults$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Update a related item by id for _defaults.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeUpdateByIdDefaults$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUpdateByIdDefaults$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Foreign key for _defaults
     */
    fk: string;
    body?: Default
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeUpdateByIdDefaultsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Update a related item by id for _defaults.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeUpdateByIdDefaults$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUpdateByIdDefaults$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Foreign key for _defaults
     */
    fk: string;
    body?: Default
  },
  context?: HttpContext

): Observable<Default> {

    return this.organizationPrototypeUpdateByIdDefaults$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Update a related item by id for _defaults.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeUpdateByIdDefaults$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUpdateByIdDefaults$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Foreign key for _defaults
     */
    fk: string;
    body?: Default
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeUpdateByIdDefaultsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Update a related item by id for _defaults.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeUpdateByIdDefaults$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUpdateByIdDefaults$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Foreign key for _defaults
     */
    fk: string;
    body?: Default
  },
  context?: HttpContext

): Observable<Default> {

    return this.organizationPrototypeUpdateByIdDefaults$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Update a related item by id for _defaults.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeUpdateByIdDefaults$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUpdateByIdDefaults$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Foreign key for _defaults
     */
    fk: string;
    body?: Default
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeUpdateByIdDefaultsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Update a related item by id for _defaults.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeUpdateByIdDefaults$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUpdateByIdDefaults$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Foreign key for _defaults
     */
    fk: string;
    body?: Default
  },
  context?: HttpContext

): Observable<Default> {

    return this.organizationPrototypeUpdateByIdDefaults$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Update a related item by id for _defaults.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeUpdateByIdDefaults$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeUpdateByIdDefaults$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Foreign key for _defaults
     */
    fk: string;
    body?: Default
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeUpdateByIdDefaultsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Update a related item by id for _defaults.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeUpdateByIdDefaults$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeUpdateByIdDefaults$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Foreign key for _defaults
     */
    fk: string;
    body?: Default
  },
  context?: HttpContext

): Observable<Default> {

    return this.organizationPrototypeUpdateByIdDefaults$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Update a related item by id for _defaults.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeUpdateByIdDefaults$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeUpdateByIdDefaults$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Foreign key for _defaults
     */
    fk: string;
    body?: Default
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeUpdateByIdDefaultsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Update a related item by id for _defaults.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeUpdateByIdDefaults$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeUpdateByIdDefaults$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Foreign key for _defaults
     */
    fk: string;
    body?: Default
  },
  context?: HttpContext

): Observable<Default> {

    return this.organizationPrototypeUpdateByIdDefaults$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Update a related item by id for _defaults.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeUpdateByIdDefaults$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeUpdateByIdDefaults$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Foreign key for _defaults
     */
    fk: string;
    body?: Default
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeUpdateByIdDefaultsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('fk', params.fk, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Update a related item by id for _defaults.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeUpdateByIdDefaults$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeUpdateByIdDefaults$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Foreign key for _defaults
     */
    fk: string;
    body?: Default
  },
  context?: HttpContext

): Observable<Default> {

    return this.organizationPrototypeUpdateByIdDefaults$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Path part for operation organizationPrototypeGetDocuments
   */
  static readonly OrganizationPrototypeGetDocumentsPath = '/v1/Organizations/{id}/documents';

  /**
   * Queries documents of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetDocuments$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetDocuments$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Document>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Document>>;
      })
    );
  }

  /**
   * Queries documents of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetDocuments$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetDocuments$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Document>> {

    return this.organizationPrototypeGetDocuments$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Document>>) => r.body as Array<Document>)
    );
  }

  /**
   * Queries documents of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetDocuments$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetDocuments$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Document>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Document>>;
      })
    );
  }

  /**
   * Queries documents of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetDocuments$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetDocuments$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Document>> {

    return this.organizationPrototypeGetDocuments$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Document>>) => r.body as Array<Document>)
    );
  }

  /**
   * Queries documents of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetDocuments$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetDocuments$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Document>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Document>>;
      })
    );
  }

  /**
   * Queries documents of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetDocuments$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetDocuments$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Document>> {

    return this.organizationPrototypeGetDocuments$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Document>>) => r.body as Array<Document>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCreateDocuments
   */
  static readonly OrganizationPrototypeCreateDocumentsPath = '/v1/Organizations/{id}/documents';

  /**
   * Creates a new instance in documents of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateDocuments$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateDocuments$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateDocumentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Creates a new instance in documents of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateDocuments$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateDocuments$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDocument
  },
  context?: HttpContext

): Observable<Document> {

    return this.organizationPrototypeCreateDocuments$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Creates a new instance in documents of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateDocuments$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateDocuments$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateDocumentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Creates a new instance in documents of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateDocuments$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateDocuments$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDocument
  },
  context?: HttpContext

): Observable<Document> {

    return this.organizationPrototypeCreateDocuments$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Creates a new instance in documents of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateDocuments$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateDocuments$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateDocumentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Creates a new instance in documents of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateDocuments$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateDocuments$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDocument
  },
  context?: HttpContext

): Observable<Document> {

    return this.organizationPrototypeCreateDocuments$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Creates a new instance in documents of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateDocuments$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateDocuments$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateDocumentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Creates a new instance in documents of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateDocuments$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateDocuments$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDocument
  },
  context?: HttpContext

): Observable<Document> {

    return this.organizationPrototypeCreateDocuments$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Creates a new instance in documents of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateDocuments$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateDocuments$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateDocumentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Creates a new instance in documents of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateDocuments$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateDocuments$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDocument
  },
  context?: HttpContext

): Observable<Document> {

    return this.organizationPrototypeCreateDocuments$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Creates a new instance in documents of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateDocuments$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateDocuments$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateDocumentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Creates a new instance in documents of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateDocuments$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateDocuments$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDocument
  },
  context?: HttpContext

): Observable<Document> {

    return this.organizationPrototypeCreateDocuments$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Creates a new instance in documents of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateDocuments$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateDocuments$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateDocumentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Creates a new instance in documents of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateDocuments$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateDocuments$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDocument
  },
  context?: HttpContext

): Observable<Document> {

    return this.organizationPrototypeCreateDocuments$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Creates a new instance in documents of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateDocuments$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateDocuments$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateDocumentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Creates a new instance in documents of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateDocuments$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateDocuments$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDocument
  },
  context?: HttpContext

): Observable<Document> {

    return this.organizationPrototypeCreateDocuments$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Creates a new instance in documents of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateDocuments$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateDocuments$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateDocumentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Creates a new instance in documents of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateDocuments$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateDocuments$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDocument
  },
  context?: HttpContext

): Observable<Document> {

    return this.organizationPrototypeCreateDocuments$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountDocuments
   */
  static readonly OrganizationPrototypeCountDocumentsPath = '/v1/Organizations/{id}/documents/count';

  /**
   * Counts documents of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountDocuments$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountDocuments$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts documents of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountDocuments$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountDocuments$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountDocuments$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts documents of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountDocuments$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountDocuments$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts documents of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountDocuments$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountDocuments$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountDocuments$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts documents of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountDocuments$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountDocuments$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts documents of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountDocuments$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountDocuments$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountDocuments$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeGetClients
   */
  static readonly OrganizationPrototypeGetClientsPath = '/v1/Organizations/{id}/clients';

  /**
   * Queries clients of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetClients$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetClients$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Client>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetClientsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Client>>;
      })
    );
  }

  /**
   * Queries clients of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetClients$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetClients$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Client>> {

    return this.organizationPrototypeGetClients$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Client>>) => r.body as Array<Client>)
    );
  }

  /**
   * Queries clients of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetClients$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetClients$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Client>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetClientsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Client>>;
      })
    );
  }

  /**
   * Queries clients of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetClients$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetClients$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Client>> {

    return this.organizationPrototypeGetClients$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Client>>) => r.body as Array<Client>)
    );
  }

  /**
   * Queries clients of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetClients$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetClients$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Client>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetClientsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Client>>;
      })
    );
  }

  /**
   * Queries clients of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetClients$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetClients$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Client>> {

    return this.organizationPrototypeGetClients$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Client>>) => r.body as Array<Client>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCreateClients
   */
  static readonly OrganizationPrototypeCreateClientsPath = '/v1/Organizations/{id}/clients';

  /**
   * Creates a new instance in clients of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateClients$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateClients$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewClient
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateClientsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Creates a new instance in clients of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateClients$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateClients$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewClient
  },
  context?: HttpContext

): Observable<Client> {

    return this.organizationPrototypeCreateClients$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Creates a new instance in clients of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateClients$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateClients$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewClient
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateClientsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Creates a new instance in clients of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateClients$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateClients$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewClient
  },
  context?: HttpContext

): Observable<Client> {

    return this.organizationPrototypeCreateClients$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Creates a new instance in clients of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateClients$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateClients$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewClient
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateClientsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Creates a new instance in clients of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateClients$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateClients$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewClient
  },
  context?: HttpContext

): Observable<Client> {

    return this.organizationPrototypeCreateClients$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Creates a new instance in clients of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateClients$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateClients$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewClient
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateClientsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Creates a new instance in clients of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateClients$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateClients$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewClient
  },
  context?: HttpContext

): Observable<Client> {

    return this.organizationPrototypeCreateClients$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Creates a new instance in clients of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateClients$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateClients$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewClient
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateClientsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Creates a new instance in clients of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateClients$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateClients$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewClient
  },
  context?: HttpContext

): Observable<Client> {

    return this.organizationPrototypeCreateClients$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Creates a new instance in clients of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateClients$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateClients$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewClient
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateClientsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Creates a new instance in clients of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateClients$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateClients$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewClient
  },
  context?: HttpContext

): Observable<Client> {

    return this.organizationPrototypeCreateClients$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Creates a new instance in clients of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateClients$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateClients$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewClient
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateClientsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Creates a new instance in clients of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateClients$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateClients$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewClient
  },
  context?: HttpContext

): Observable<Client> {

    return this.organizationPrototypeCreateClients$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Creates a new instance in clients of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateClients$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateClients$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewClient
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateClientsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Creates a new instance in clients of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateClients$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateClients$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewClient
  },
  context?: HttpContext

): Observable<Client> {

    return this.organizationPrototypeCreateClients$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Creates a new instance in clients of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateClients$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateClients$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewClient
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Client>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateClientsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Client>;
      })
    );
  }

  /**
   * Creates a new instance in clients of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateClients$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateClients$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewClient
  },
  context?: HttpContext

): Observable<Client> {

    return this.organizationPrototypeCreateClients$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>) => r.body as Client)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountClients
   */
  static readonly OrganizationPrototypeCountClientsPath = '/v1/Organizations/{id}/clients/count';

  /**
   * Counts clients of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountClients$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountClients$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountClientsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts clients of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountClients$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountClients$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountClients$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts clients of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountClients$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountClients$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountClientsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts clients of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountClients$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountClients$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountClients$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts clients of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountClients$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountClients$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountClientsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts clients of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountClients$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountClients$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountClients$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeGetItems
   */
  static readonly OrganizationPrototypeGetItemsPath = '/v1/Organizations/{id}/items';

  /**
   * Queries items of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetItems$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetItems$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Item>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetItemsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Item>>;
      })
    );
  }

  /**
   * Queries items of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetItems$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetItems$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Item>> {

    return this.organizationPrototypeGetItems$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Item>>) => r.body as Array<Item>)
    );
  }

  /**
   * Queries items of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetItems$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetItems$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Item>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetItemsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Item>>;
      })
    );
  }

  /**
   * Queries items of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetItems$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetItems$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Item>> {

    return this.organizationPrototypeGetItems$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Item>>) => r.body as Array<Item>)
    );
  }

  /**
   * Queries items of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetItems$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetItems$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Item>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetItemsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Item>>;
      })
    );
  }

  /**
   * Queries items of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetItems$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetItems$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Item>> {

    return this.organizationPrototypeGetItems$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Item>>) => r.body as Array<Item>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCreateItems
   */
  static readonly OrganizationPrototypeCreateItemsPath = '/v1/Organizations/{id}/items';

  /**
   * Creates a new instance in items of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateItems$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateItems$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewItem
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateItemsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Creates a new instance in items of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateItems$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateItems$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewItem
  },
  context?: HttpContext

): Observable<Item> {

    return this.organizationPrototypeCreateItems$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Creates a new instance in items of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateItems$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateItems$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewItem
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateItemsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Creates a new instance in items of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateItems$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateItems$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewItem
  },
  context?: HttpContext

): Observable<Item> {

    return this.organizationPrototypeCreateItems$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Creates a new instance in items of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateItems$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateItems$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewItem
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateItemsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Creates a new instance in items of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateItems$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateItems$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewItem
  },
  context?: HttpContext

): Observable<Item> {

    return this.organizationPrototypeCreateItems$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Creates a new instance in items of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateItems$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateItems$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewItem
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateItemsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Creates a new instance in items of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateItems$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateItems$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewItem
  },
  context?: HttpContext

): Observable<Item> {

    return this.organizationPrototypeCreateItems$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Creates a new instance in items of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateItems$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateItems$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewItem
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateItemsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Creates a new instance in items of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateItems$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateItems$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewItem
  },
  context?: HttpContext

): Observable<Item> {

    return this.organizationPrototypeCreateItems$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Creates a new instance in items of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateItems$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateItems$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewItem
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateItemsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Creates a new instance in items of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateItems$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateItems$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewItem
  },
  context?: HttpContext

): Observable<Item> {

    return this.organizationPrototypeCreateItems$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Creates a new instance in items of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateItems$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateItems$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewItem
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateItemsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Creates a new instance in items of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateItems$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateItems$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewItem
  },
  context?: HttpContext

): Observable<Item> {

    return this.organizationPrototypeCreateItems$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Creates a new instance in items of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateItems$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateItems$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewItem
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateItemsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Creates a new instance in items of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateItems$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateItems$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewItem
  },
  context?: HttpContext

): Observable<Item> {

    return this.organizationPrototypeCreateItems$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Creates a new instance in items of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateItems$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateItems$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewItem
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Item>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateItemsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Item>;
      })
    );
  }

  /**
   * Creates a new instance in items of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateItems$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateItems$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewItem
  },
  context?: HttpContext

): Observable<Item> {

    return this.organizationPrototypeCreateItems$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Item>) => r.body as Item)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountItems
   */
  static readonly OrganizationPrototypeCountItemsPath = '/v1/Organizations/{id}/items/count';

  /**
   * Counts items of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountItems$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountItems$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountItemsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts items of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountItems$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountItems$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountItems$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts items of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountItems$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountItems$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountItemsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts items of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountItems$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountItems$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountItems$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts items of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountItems$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountItems$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountItemsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts items of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountItems$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountItems$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountItems$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeGetTaxes
   */
  static readonly OrganizationPrototypeGetTaxesPath = '/v1/Organizations/{id}/taxes';

  /**
   * Queries taxes of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetTaxes$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetTaxes$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Tax>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetTaxesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Tax>>;
      })
    );
  }

  /**
   * Queries taxes of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetTaxes$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetTaxes$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Tax>> {

    return this.organizationPrototypeGetTaxes$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Tax>>) => r.body as Array<Tax>)
    );
  }

  /**
   * Queries taxes of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetTaxes$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetTaxes$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Tax>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetTaxesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Tax>>;
      })
    );
  }

  /**
   * Queries taxes of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetTaxes$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetTaxes$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Tax>> {

    return this.organizationPrototypeGetTaxes$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Tax>>) => r.body as Array<Tax>)
    );
  }

  /**
   * Queries taxes of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetTaxes$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetTaxes$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Tax>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetTaxesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Tax>>;
      })
    );
  }

  /**
   * Queries taxes of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetTaxes$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetTaxes$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Tax>> {

    return this.organizationPrototypeGetTaxes$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Tax>>) => r.body as Array<Tax>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCreateTaxes
   */
  static readonly OrganizationPrototypeCreateTaxesPath = '/v1/Organizations/{id}/taxes';

  /**
   * Creates a new instance in taxes of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateTaxes$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateTaxes$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateTaxesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Creates a new instance in taxes of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateTaxes$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateTaxes$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.organizationPrototypeCreateTaxes$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Creates a new instance in taxes of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateTaxes$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateTaxes$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateTaxesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Creates a new instance in taxes of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateTaxes$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateTaxes$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.organizationPrototypeCreateTaxes$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Creates a new instance in taxes of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateTaxes$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateTaxes$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateTaxesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Creates a new instance in taxes of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateTaxes$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateTaxes$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.organizationPrototypeCreateTaxes$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Creates a new instance in taxes of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateTaxes$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateTaxes$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateTaxesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Creates a new instance in taxes of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateTaxes$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateTaxes$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.organizationPrototypeCreateTaxes$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Creates a new instance in taxes of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateTaxes$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateTaxes$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateTaxesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Creates a new instance in taxes of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateTaxes$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateTaxes$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.organizationPrototypeCreateTaxes$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Creates a new instance in taxes of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateTaxes$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateTaxes$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateTaxesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Creates a new instance in taxes of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateTaxes$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateTaxes$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.organizationPrototypeCreateTaxes$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Creates a new instance in taxes of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateTaxes$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateTaxes$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateTaxesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Creates a new instance in taxes of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateTaxes$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateTaxes$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.organizationPrototypeCreateTaxes$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Creates a new instance in taxes of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateTaxes$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateTaxes$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateTaxesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Creates a new instance in taxes of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateTaxes$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateTaxes$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.organizationPrototypeCreateTaxes$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Creates a new instance in taxes of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateTaxes$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateTaxes$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTax
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tax>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateTaxesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tax>;
      })
    );
  }

  /**
   * Creates a new instance in taxes of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateTaxes$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateTaxes$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTax
  },
  context?: HttpContext

): Observable<Tax> {

    return this.organizationPrototypeCreateTaxes$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tax>) => r.body as Tax)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountTaxes
   */
  static readonly OrganizationPrototypeCountTaxesPath = '/v1/Organizations/{id}/taxes/count';

  /**
   * Counts taxes of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountTaxes$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountTaxes$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountTaxesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts taxes of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountTaxes$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountTaxes$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountTaxes$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts taxes of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountTaxes$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountTaxes$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountTaxesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts taxes of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountTaxes$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountTaxes$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountTaxes$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts taxes of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountTaxes$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountTaxes$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountTaxesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts taxes of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountTaxes$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountTaxes$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountTaxes$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeGetShopify
   */
  static readonly OrganizationPrototypeGetShopifyPath = '/v1/Organizations/{id}/shopify';

  /**
   * Queries shopify of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetShopify$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetShopify$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Shopify>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetShopifyPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Shopify>>;
      })
    );
  }

  /**
   * Queries shopify of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetShopify$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetShopify$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Shopify>> {

    return this.organizationPrototypeGetShopify$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Shopify>>) => r.body as Array<Shopify>)
    );
  }

  /**
   * Queries shopify of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetShopify$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetShopify$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Shopify>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetShopifyPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Shopify>>;
      })
    );
  }

  /**
   * Queries shopify of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetShopify$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetShopify$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Shopify>> {

    return this.organizationPrototypeGetShopify$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Shopify>>) => r.body as Array<Shopify>)
    );
  }

  /**
   * Queries shopify of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetShopify$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetShopify$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Shopify>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetShopifyPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Shopify>>;
      })
    );
  }

  /**
   * Queries shopify of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetShopify$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetShopify$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Shopify>> {

    return this.organizationPrototypeGetShopify$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Shopify>>) => r.body as Array<Shopify>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCreateShopify
   */
  static readonly OrganizationPrototypeCreateShopifyPath = '/v1/Organizations/{id}/shopify';

  /**
   * Creates a new instance in shopify of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateShopify$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateShopify$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopify>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateShopifyPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopify>;
      })
    );
  }

  /**
   * Creates a new instance in shopify of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateShopify$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateShopify$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopify
  },
  context?: HttpContext

): Observable<Shopify> {

    return this.organizationPrototypeCreateShopify$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopify>) => r.body as Shopify)
    );
  }

  /**
   * Creates a new instance in shopify of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateShopify$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateShopify$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopify>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateShopifyPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopify>;
      })
    );
  }

  /**
   * Creates a new instance in shopify of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateShopify$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateShopify$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopify
  },
  context?: HttpContext

): Observable<Shopify> {

    return this.organizationPrototypeCreateShopify$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopify>) => r.body as Shopify)
    );
  }

  /**
   * Creates a new instance in shopify of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateShopify$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateShopify$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopify>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateShopifyPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopify>;
      })
    );
  }

  /**
   * Creates a new instance in shopify of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateShopify$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateShopify$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopify
  },
  context?: HttpContext

): Observable<Shopify> {

    return this.organizationPrototypeCreateShopify$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopify>) => r.body as Shopify)
    );
  }

  /**
   * Creates a new instance in shopify of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateShopify$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateShopify$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopify>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateShopifyPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopify>;
      })
    );
  }

  /**
   * Creates a new instance in shopify of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateShopify$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateShopify$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopify
  },
  context?: HttpContext

): Observable<Shopify> {

    return this.organizationPrototypeCreateShopify$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopify>) => r.body as Shopify)
    );
  }

  /**
   * Creates a new instance in shopify of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateShopify$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateShopify$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopify>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateShopifyPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopify>;
      })
    );
  }

  /**
   * Creates a new instance in shopify of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateShopify$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateShopify$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopify
  },
  context?: HttpContext

): Observable<Shopify> {

    return this.organizationPrototypeCreateShopify$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopify>) => r.body as Shopify)
    );
  }

  /**
   * Creates a new instance in shopify of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateShopify$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateShopify$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopify>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateShopifyPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopify>;
      })
    );
  }

  /**
   * Creates a new instance in shopify of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateShopify$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateShopify$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopify
  },
  context?: HttpContext

): Observable<Shopify> {

    return this.organizationPrototypeCreateShopify$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopify>) => r.body as Shopify)
    );
  }

  /**
   * Creates a new instance in shopify of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateShopify$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateShopify$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopify>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateShopifyPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopify>;
      })
    );
  }

  /**
   * Creates a new instance in shopify of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateShopify$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateShopify$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopify
  },
  context?: HttpContext

): Observable<Shopify> {

    return this.organizationPrototypeCreateShopify$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopify>) => r.body as Shopify)
    );
  }

  /**
   * Creates a new instance in shopify of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateShopify$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateShopify$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopify>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateShopifyPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopify>;
      })
    );
  }

  /**
   * Creates a new instance in shopify of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateShopify$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateShopify$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopify
  },
  context?: HttpContext

): Observable<Shopify> {

    return this.organizationPrototypeCreateShopify$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopify>) => r.body as Shopify)
    );
  }

  /**
   * Creates a new instance in shopify of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateShopify$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateShopify$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopify
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopify>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateShopifyPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopify>;
      })
    );
  }

  /**
   * Creates a new instance in shopify of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateShopify$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateShopify$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopify
  },
  context?: HttpContext

): Observable<Shopify> {

    return this.organizationPrototypeCreateShopify$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopify>) => r.body as Shopify)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountShopify
   */
  static readonly OrganizationPrototypeCountShopifyPath = '/v1/Organizations/{id}/shopify/count';

  /**
   * Counts shopify of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountShopify$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountShopify$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountShopifyPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts shopify of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountShopify$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountShopify$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountShopify$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts shopify of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountShopify$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountShopify$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountShopifyPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts shopify of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountShopify$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountShopify$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountShopify$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts shopify of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountShopify$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountShopify$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountShopifyPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts shopify of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountShopify$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountShopify$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountShopify$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeGetStripe
   */
  static readonly OrganizationPrototypeGetStripePath = '/v1/Organizations/{id}/stripe';

  /**
   * Queries stripe of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetStripe$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetStripe$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Stripe>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetStripePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Stripe>>;
      })
    );
  }

  /**
   * Queries stripe of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetStripe$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetStripe$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Stripe>> {

    return this.organizationPrototypeGetStripe$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Stripe>>) => r.body as Array<Stripe>)
    );
  }

  /**
   * Queries stripe of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetStripe$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetStripe$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Stripe>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetStripePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Stripe>>;
      })
    );
  }

  /**
   * Queries stripe of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetStripe$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetStripe$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Stripe>> {

    return this.organizationPrototypeGetStripe$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Stripe>>) => r.body as Array<Stripe>)
    );
  }

  /**
   * Queries stripe of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetStripe$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetStripe$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Stripe>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetStripePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Stripe>>;
      })
    );
  }

  /**
   * Queries stripe of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetStripe$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetStripe$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Stripe>> {

    return this.organizationPrototypeGetStripe$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Stripe>>) => r.body as Array<Stripe>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCreateStripe
   */
  static readonly OrganizationPrototypeCreateStripePath = '/v1/Organizations/{id}/stripe';

  /**
   * Creates a new instance in stripe of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateStripe$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateStripe$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewStripe
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Stripe>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateStripePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Stripe>;
      })
    );
  }

  /**
   * Creates a new instance in stripe of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateStripe$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateStripe$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewStripe
  },
  context?: HttpContext

): Observable<Stripe> {

    return this.organizationPrototypeCreateStripe$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Stripe>) => r.body as Stripe)
    );
  }

  /**
   * Creates a new instance in stripe of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateStripe$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateStripe$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewStripe
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Stripe>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateStripePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Stripe>;
      })
    );
  }

  /**
   * Creates a new instance in stripe of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateStripe$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateStripe$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewStripe
  },
  context?: HttpContext

): Observable<Stripe> {

    return this.organizationPrototypeCreateStripe$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Stripe>) => r.body as Stripe)
    );
  }

  /**
   * Creates a new instance in stripe of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateStripe$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateStripe$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewStripe
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Stripe>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateStripePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Stripe>;
      })
    );
  }

  /**
   * Creates a new instance in stripe of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateStripe$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateStripe$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewStripe
  },
  context?: HttpContext

): Observable<Stripe> {

    return this.organizationPrototypeCreateStripe$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Stripe>) => r.body as Stripe)
    );
  }

  /**
   * Creates a new instance in stripe of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateStripe$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateStripe$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewStripe
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Stripe>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateStripePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Stripe>;
      })
    );
  }

  /**
   * Creates a new instance in stripe of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateStripe$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateStripe$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewStripe
  },
  context?: HttpContext

): Observable<Stripe> {

    return this.organizationPrototypeCreateStripe$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Stripe>) => r.body as Stripe)
    );
  }

  /**
   * Creates a new instance in stripe of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateStripe$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateStripe$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewStripe
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Stripe>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateStripePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Stripe>;
      })
    );
  }

  /**
   * Creates a new instance in stripe of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateStripe$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateStripe$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewStripe
  },
  context?: HttpContext

): Observable<Stripe> {

    return this.organizationPrototypeCreateStripe$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Stripe>) => r.body as Stripe)
    );
  }

  /**
   * Creates a new instance in stripe of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateStripe$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateStripe$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewStripe
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Stripe>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateStripePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Stripe>;
      })
    );
  }

  /**
   * Creates a new instance in stripe of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateStripe$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateStripe$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewStripe
  },
  context?: HttpContext

): Observable<Stripe> {

    return this.organizationPrototypeCreateStripe$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Stripe>) => r.body as Stripe)
    );
  }

  /**
   * Creates a new instance in stripe of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateStripe$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateStripe$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewStripe
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Stripe>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateStripePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Stripe>;
      })
    );
  }

  /**
   * Creates a new instance in stripe of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateStripe$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateStripe$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewStripe
  },
  context?: HttpContext

): Observable<Stripe> {

    return this.organizationPrototypeCreateStripe$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Stripe>) => r.body as Stripe)
    );
  }

  /**
   * Creates a new instance in stripe of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateStripe$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateStripe$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewStripe
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Stripe>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateStripePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Stripe>;
      })
    );
  }

  /**
   * Creates a new instance in stripe of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateStripe$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateStripe$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewStripe
  },
  context?: HttpContext

): Observable<Stripe> {

    return this.organizationPrototypeCreateStripe$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Stripe>) => r.body as Stripe)
    );
  }

  /**
   * Creates a new instance in stripe of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateStripe$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateStripe$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewStripe
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Stripe>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateStripePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Stripe>;
      })
    );
  }

  /**
   * Creates a new instance in stripe of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateStripe$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateStripe$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewStripe
  },
  context?: HttpContext

): Observable<Stripe> {

    return this.organizationPrototypeCreateStripe$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Stripe>) => r.body as Stripe)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountStripe
   */
  static readonly OrganizationPrototypeCountStripePath = '/v1/Organizations/{id}/stripe/count';

  /**
   * Counts stripe of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountStripe$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountStripe$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountStripePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts stripe of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountStripe$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountStripe$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountStripe$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts stripe of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountStripe$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountStripe$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountStripePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts stripe of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountStripe$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountStripe$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountStripe$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts stripe of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountStripe$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountStripe$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountStripePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts stripe of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountStripe$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountStripe$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountStripe$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeGetTicketingHub
   */
  static readonly OrganizationPrototypeGetTicketingHubPath = '/v1/Organizations/{id}/ticketingHub';

  /**
   * Queries ticketingHub of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetTicketingHub$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetTicketingHub$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TicketingHub>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetTicketingHubPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TicketingHub>>;
      })
    );
  }

  /**
   * Queries ticketingHub of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetTicketingHub$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetTicketingHub$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<TicketingHub>> {

    return this.organizationPrototypeGetTicketingHub$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TicketingHub>>) => r.body as Array<TicketingHub>)
    );
  }

  /**
   * Queries ticketingHub of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetTicketingHub$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetTicketingHub$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TicketingHub>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetTicketingHubPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TicketingHub>>;
      })
    );
  }

  /**
   * Queries ticketingHub of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetTicketingHub$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetTicketingHub$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<TicketingHub>> {

    return this.organizationPrototypeGetTicketingHub$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TicketingHub>>) => r.body as Array<TicketingHub>)
    );
  }

  /**
   * Queries ticketingHub of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetTicketingHub$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetTicketingHub$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TicketingHub>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetTicketingHubPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TicketingHub>>;
      })
    );
  }

  /**
   * Queries ticketingHub of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetTicketingHub$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetTicketingHub$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<TicketingHub>> {

    return this.organizationPrototypeGetTicketingHub$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TicketingHub>>) => r.body as Array<TicketingHub>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCreateTicketingHub
   */
  static readonly OrganizationPrototypeCreateTicketingHubPath = '/v1/Organizations/{id}/ticketingHub';

  /**
   * Creates a new instance in ticketingHub of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateTicketingHub$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateTicketingHub$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTicketingHub
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TicketingHub>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateTicketingHubPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TicketingHub>;
      })
    );
  }

  /**
   * Creates a new instance in ticketingHub of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateTicketingHub$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateTicketingHub$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTicketingHub
  },
  context?: HttpContext

): Observable<TicketingHub> {

    return this.organizationPrototypeCreateTicketingHub$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<TicketingHub>) => r.body as TicketingHub)
    );
  }

  /**
   * Creates a new instance in ticketingHub of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateTicketingHub$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateTicketingHub$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTicketingHub
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TicketingHub>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateTicketingHubPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TicketingHub>;
      })
    );
  }

  /**
   * Creates a new instance in ticketingHub of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateTicketingHub$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateTicketingHub$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTicketingHub
  },
  context?: HttpContext

): Observable<TicketingHub> {

    return this.organizationPrototypeCreateTicketingHub$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<TicketingHub>) => r.body as TicketingHub)
    );
  }

  /**
   * Creates a new instance in ticketingHub of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateTicketingHub$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateTicketingHub$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTicketingHub
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TicketingHub>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateTicketingHubPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TicketingHub>;
      })
    );
  }

  /**
   * Creates a new instance in ticketingHub of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateTicketingHub$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateTicketingHub$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTicketingHub
  },
  context?: HttpContext

): Observable<TicketingHub> {

    return this.organizationPrototypeCreateTicketingHub$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<TicketingHub>) => r.body as TicketingHub)
    );
  }

  /**
   * Creates a new instance in ticketingHub of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateTicketingHub$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateTicketingHub$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTicketingHub
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TicketingHub>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateTicketingHubPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TicketingHub>;
      })
    );
  }

  /**
   * Creates a new instance in ticketingHub of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateTicketingHub$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateTicketingHub$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTicketingHub
  },
  context?: HttpContext

): Observable<TicketingHub> {

    return this.organizationPrototypeCreateTicketingHub$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<TicketingHub>) => r.body as TicketingHub)
    );
  }

  /**
   * Creates a new instance in ticketingHub of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateTicketingHub$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateTicketingHub$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTicketingHub
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TicketingHub>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateTicketingHubPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TicketingHub>;
      })
    );
  }

  /**
   * Creates a new instance in ticketingHub of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateTicketingHub$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateTicketingHub$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTicketingHub
  },
  context?: HttpContext

): Observable<TicketingHub> {

    return this.organizationPrototypeCreateTicketingHub$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<TicketingHub>) => r.body as TicketingHub)
    );
  }

  /**
   * Creates a new instance in ticketingHub of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateTicketingHub$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateTicketingHub$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTicketingHub
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TicketingHub>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateTicketingHubPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TicketingHub>;
      })
    );
  }

  /**
   * Creates a new instance in ticketingHub of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateTicketingHub$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateTicketingHub$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTicketingHub
  },
  context?: HttpContext

): Observable<TicketingHub> {

    return this.organizationPrototypeCreateTicketingHub$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<TicketingHub>) => r.body as TicketingHub)
    );
  }

  /**
   * Creates a new instance in ticketingHub of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateTicketingHub$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateTicketingHub$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTicketingHub
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TicketingHub>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateTicketingHubPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TicketingHub>;
      })
    );
  }

  /**
   * Creates a new instance in ticketingHub of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateTicketingHub$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateTicketingHub$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTicketingHub
  },
  context?: HttpContext

): Observable<TicketingHub> {

    return this.organizationPrototypeCreateTicketingHub$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<TicketingHub>) => r.body as TicketingHub)
    );
  }

  /**
   * Creates a new instance in ticketingHub of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateTicketingHub$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateTicketingHub$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTicketingHub
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TicketingHub>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateTicketingHubPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TicketingHub>;
      })
    );
  }

  /**
   * Creates a new instance in ticketingHub of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateTicketingHub$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateTicketingHub$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTicketingHub
  },
  context?: HttpContext

): Observable<TicketingHub> {

    return this.organizationPrototypeCreateTicketingHub$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<TicketingHub>) => r.body as TicketingHub)
    );
  }

  /**
   * Creates a new instance in ticketingHub of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateTicketingHub$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateTicketingHub$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTicketingHub
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TicketingHub>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateTicketingHubPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TicketingHub>;
      })
    );
  }

  /**
   * Creates a new instance in ticketingHub of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateTicketingHub$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateTicketingHub$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewTicketingHub
  },
  context?: HttpContext

): Observable<TicketingHub> {

    return this.organizationPrototypeCreateTicketingHub$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<TicketingHub>) => r.body as TicketingHub)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountTicketingHub
   */
  static readonly OrganizationPrototypeCountTicketingHubPath = '/v1/Organizations/{id}/ticketingHub/count';

  /**
   * Counts ticketingHub of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountTicketingHub$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountTicketingHub$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountTicketingHubPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts ticketingHub of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountTicketingHub$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountTicketingHub$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountTicketingHub$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts ticketingHub of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountTicketingHub$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountTicketingHub$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountTicketingHubPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts ticketingHub of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountTicketingHub$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountTicketingHub$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountTicketingHub$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts ticketingHub of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountTicketingHub$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountTicketingHub$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountTicketingHubPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts ticketingHub of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountTicketingHub$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountTicketingHub$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountTicketingHub$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeGetRecurrences
   */
  static readonly OrganizationPrototypeGetRecurrencesPath = '/v1/Organizations/{id}/recurrences';

  /**
   * Queries recurrences of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetRecurrences$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetRecurrences$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Recurrence>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetRecurrencesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Recurrence>>;
      })
    );
  }

  /**
   * Queries recurrences of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetRecurrences$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetRecurrences$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Recurrence>> {

    return this.organizationPrototypeGetRecurrences$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Recurrence>>) => r.body as Array<Recurrence>)
    );
  }

  /**
   * Queries recurrences of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetRecurrences$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetRecurrences$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Recurrence>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetRecurrencesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Recurrence>>;
      })
    );
  }

  /**
   * Queries recurrences of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetRecurrences$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetRecurrences$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Recurrence>> {

    return this.organizationPrototypeGetRecurrences$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Recurrence>>) => r.body as Array<Recurrence>)
    );
  }

  /**
   * Queries recurrences of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetRecurrences$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetRecurrences$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Recurrence>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetRecurrencesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Recurrence>>;
      })
    );
  }

  /**
   * Queries recurrences of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetRecurrences$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetRecurrences$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Recurrence>> {

    return this.organizationPrototypeGetRecurrences$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Recurrence>>) => r.body as Array<Recurrence>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountRecurrences
   */
  static readonly OrganizationPrototypeCountRecurrencesPath = '/v1/Organizations/{id}/recurrences/count';

  /**
   * Counts recurrences of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountRecurrences$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountRecurrences$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountRecurrencesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts recurrences of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountRecurrences$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountRecurrences$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountRecurrences$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts recurrences of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountRecurrences$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountRecurrences$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountRecurrencesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts recurrences of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountRecurrences$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountRecurrences$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountRecurrences$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts recurrences of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountRecurrences$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountRecurrences$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountRecurrencesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts recurrences of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountRecurrences$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountRecurrences$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountRecurrences$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeGetPayments
   */
  static readonly OrganizationPrototypeGetPaymentsPath = '/v1/Organizations/{id}/payments';

  /**
   * Queries payments of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetPayments$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetPayments$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Payment>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetPaymentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Payment>>;
      })
    );
  }

  /**
   * Queries payments of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetPayments$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetPayments$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Payment>> {

    return this.organizationPrototypeGetPayments$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Payment>>) => r.body as Array<Payment>)
    );
  }

  /**
   * Queries payments of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetPayments$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetPayments$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Payment>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetPaymentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Payment>>;
      })
    );
  }

  /**
   * Queries payments of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetPayments$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetPayments$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Payment>> {

    return this.organizationPrototypeGetPayments$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Payment>>) => r.body as Array<Payment>)
    );
  }

  /**
   * Queries payments of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetPayments$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetPayments$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Payment>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetPaymentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Payment>>;
      })
    );
  }

  /**
   * Queries payments of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetPayments$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetPayments$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Payment>> {

    return this.organizationPrototypeGetPayments$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Payment>>) => r.body as Array<Payment>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCreatePayments
   */
  static readonly OrganizationPrototypeCreatePaymentsPath = '/v1/Organizations/{id}/payments';

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreatePayments$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreatePayments$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreatePaymentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreatePayments$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreatePayments$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.organizationPrototypeCreatePayments$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreatePayments$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreatePayments$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreatePaymentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreatePayments$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreatePayments$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.organizationPrototypeCreatePayments$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreatePayments$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreatePayments$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreatePaymentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreatePayments$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreatePayments$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.organizationPrototypeCreatePayments$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreatePayments$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreatePayments$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreatePaymentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreatePayments$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreatePayments$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.organizationPrototypeCreatePayments$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreatePayments$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreatePayments$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreatePaymentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreatePayments$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreatePayments$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.organizationPrototypeCreatePayments$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreatePayments$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreatePayments$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreatePaymentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreatePayments$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreatePayments$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.organizationPrototypeCreatePayments$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreatePayments$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreatePayments$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreatePaymentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreatePayments$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreatePayments$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.organizationPrototypeCreatePayments$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreatePayments$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreatePayments$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreatePaymentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreatePayments$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreatePayments$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.organizationPrototypeCreatePayments$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreatePayments$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreatePayments$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Payment>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreatePaymentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Payment>;
      })
    );
  }

  /**
   * Creates a new instance in payments of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreatePayments$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreatePayments$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPayment
  },
  context?: HttpContext

): Observable<Payment> {

    return this.organizationPrototypeCreatePayments$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Payment>) => r.body as Payment)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountPayments
   */
  static readonly OrganizationPrototypeCountPaymentsPath = '/v1/Organizations/{id}/payments/count';

  /**
   * Counts payments of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountPayments$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountPayments$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountPaymentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts payments of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountPayments$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountPayments$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountPayments$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts payments of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountPayments$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountPayments$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountPaymentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts payments of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountPayments$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountPayments$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountPayments$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts payments of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountPayments$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountPayments$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountPaymentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts payments of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountPayments$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountPayments$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountPayments$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeGetDefaults
   */
  static readonly OrganizationPrototypeGetDefaultsPath = '/v1/Organizations/{id}/defaults';

  /**
   * Queries _defaults of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetDefaults$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetDefaults$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Default>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetDefaultsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Default>>;
      })
    );
  }

  /**
   * Queries _defaults of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetDefaults$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetDefaults$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Default>> {

    return this.organizationPrototypeGetDefaults$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Default>>) => r.body as Array<Default>)
    );
  }

  /**
   * Queries _defaults of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetDefaults$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetDefaults$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Default>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetDefaultsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Default>>;
      })
    );
  }

  /**
   * Queries _defaults of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetDefaults$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetDefaults$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Default>> {

    return this.organizationPrototypeGetDefaults$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Default>>) => r.body as Array<Default>)
    );
  }

  /**
   * Queries _defaults of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetDefaults$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetDefaults$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Default>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetDefaultsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Default>>;
      })
    );
  }

  /**
   * Queries _defaults of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetDefaults$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetDefaults$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Default>> {

    return this.organizationPrototypeGetDefaults$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Default>>) => r.body as Array<Default>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCreateDefaults
   */
  static readonly OrganizationPrototypeCreateDefaultsPath = '/v1/Organizations/{id}/defaults';

  /**
   * Creates a new instance in _defaults of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateDefaults$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateDefaults$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDefault
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateDefaultsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Creates a new instance in _defaults of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateDefaults$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateDefaults$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDefault
  },
  context?: HttpContext

): Observable<Default> {

    return this.organizationPrototypeCreateDefaults$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Creates a new instance in _defaults of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateDefaults$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateDefaults$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDefault
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateDefaultsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Creates a new instance in _defaults of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateDefaults$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateDefaults$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDefault
  },
  context?: HttpContext

): Observable<Default> {

    return this.organizationPrototypeCreateDefaults$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Creates a new instance in _defaults of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateDefaults$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateDefaults$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDefault
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateDefaultsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Creates a new instance in _defaults of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateDefaults$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateDefaults$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDefault
  },
  context?: HttpContext

): Observable<Default> {

    return this.organizationPrototypeCreateDefaults$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Creates a new instance in _defaults of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateDefaults$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateDefaults$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDefault
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateDefaultsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Creates a new instance in _defaults of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateDefaults$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateDefaults$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDefault
  },
  context?: HttpContext

): Observable<Default> {

    return this.organizationPrototypeCreateDefaults$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Creates a new instance in _defaults of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateDefaults$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateDefaults$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDefault
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateDefaultsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Creates a new instance in _defaults of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateDefaults$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateDefaults$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDefault
  },
  context?: HttpContext

): Observable<Default> {

    return this.organizationPrototypeCreateDefaults$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Creates a new instance in _defaults of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateDefaults$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateDefaults$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDefault
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateDefaultsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Creates a new instance in _defaults of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateDefaults$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateDefaults$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDefault
  },
  context?: HttpContext

): Observable<Default> {

    return this.organizationPrototypeCreateDefaults$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Creates a new instance in _defaults of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateDefaults$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateDefaults$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDefault
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateDefaultsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Creates a new instance in _defaults of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateDefaults$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateDefaults$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDefault
  },
  context?: HttpContext

): Observable<Default> {

    return this.organizationPrototypeCreateDefaults$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Creates a new instance in _defaults of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateDefaults$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateDefaults$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDefault
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateDefaultsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Creates a new instance in _defaults of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateDefaults$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateDefaults$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDefault
  },
  context?: HttpContext

): Observable<Default> {

    return this.organizationPrototypeCreateDefaults$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Creates a new instance in _defaults of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateDefaults$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateDefaults$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDefault
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateDefaultsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Creates a new instance in _defaults of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateDefaults$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateDefaults$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewDefault
  },
  context?: HttpContext

): Observable<Default> {

    return this.organizationPrototypeCreateDefaults$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountDefaults
   */
  static readonly OrganizationPrototypeCountDefaultsPath = '/v1/Organizations/{id}/defaults/count';

  /**
   * Counts _defaults of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountDefaults$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountDefaults$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountDefaultsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts _defaults of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountDefaults$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountDefaults$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountDefaults$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts _defaults of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountDefaults$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountDefaults$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountDefaultsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts _defaults of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountDefaults$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountDefaults$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountDefaults$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts _defaults of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountDefaults$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountDefaults$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountDefaultsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts _defaults of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountDefaults$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountDefaults$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountDefaults$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeGetBusinessPremises
   */
  static readonly OrganizationPrototypeGetBusinessPremisesPath = '/v1/Organizations/{id}/businessPremises';

  /**
   * Queries businessPremises of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetBusinessPremises$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetBusinessPremises$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BusinessPremise>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetBusinessPremisesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BusinessPremise>>;
      })
    );
  }

  /**
   * Queries businessPremises of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetBusinessPremises$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetBusinessPremises$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<BusinessPremise>> {

    return this.organizationPrototypeGetBusinessPremises$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BusinessPremise>>) => r.body as Array<BusinessPremise>)
    );
  }

  /**
   * Queries businessPremises of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetBusinessPremises$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetBusinessPremises$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BusinessPremise>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetBusinessPremisesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BusinessPremise>>;
      })
    );
  }

  /**
   * Queries businessPremises of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetBusinessPremises$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetBusinessPremises$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<BusinessPremise>> {

    return this.organizationPrototypeGetBusinessPremises$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BusinessPremise>>) => r.body as Array<BusinessPremise>)
    );
  }

  /**
   * Queries businessPremises of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetBusinessPremises$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetBusinessPremises$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BusinessPremise>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetBusinessPremisesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BusinessPremise>>;
      })
    );
  }

  /**
   * Queries businessPremises of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetBusinessPremises$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetBusinessPremises$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<BusinessPremise>> {

    return this.organizationPrototypeGetBusinessPremises$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BusinessPremise>>) => r.body as Array<BusinessPremise>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCreateBusinessPremises
   */
  static readonly OrganizationPrototypeCreateBusinessPremisesPath = '/v1/Organizations/{id}/businessPremises';

  /**
   * Creates a new instance in businessPremises of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateBusinessPremises$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateBusinessPremises$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBusinessPremise
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BusinessPremise>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateBusinessPremisesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BusinessPremise>;
      })
    );
  }

  /**
   * Creates a new instance in businessPremises of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateBusinessPremises$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateBusinessPremises$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBusinessPremise
  },
  context?: HttpContext

): Observable<BusinessPremise> {

    return this.organizationPrototypeCreateBusinessPremises$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<BusinessPremise>) => r.body as BusinessPremise)
    );
  }

  /**
   * Creates a new instance in businessPremises of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateBusinessPremises$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateBusinessPremises$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBusinessPremise
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BusinessPremise>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateBusinessPremisesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BusinessPremise>;
      })
    );
  }

  /**
   * Creates a new instance in businessPremises of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateBusinessPremises$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateBusinessPremises$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBusinessPremise
  },
  context?: HttpContext

): Observable<BusinessPremise> {

    return this.organizationPrototypeCreateBusinessPremises$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<BusinessPremise>) => r.body as BusinessPremise)
    );
  }

  /**
   * Creates a new instance in businessPremises of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateBusinessPremises$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateBusinessPremises$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBusinessPremise
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BusinessPremise>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateBusinessPremisesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BusinessPremise>;
      })
    );
  }

  /**
   * Creates a new instance in businessPremises of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateBusinessPremises$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateBusinessPremises$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBusinessPremise
  },
  context?: HttpContext

): Observable<BusinessPremise> {

    return this.organizationPrototypeCreateBusinessPremises$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<BusinessPremise>) => r.body as BusinessPremise)
    );
  }

  /**
   * Creates a new instance in businessPremises of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateBusinessPremises$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateBusinessPremises$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBusinessPremise
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BusinessPremise>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateBusinessPremisesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BusinessPremise>;
      })
    );
  }

  /**
   * Creates a new instance in businessPremises of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateBusinessPremises$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateBusinessPremises$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBusinessPremise
  },
  context?: HttpContext

): Observable<BusinessPremise> {

    return this.organizationPrototypeCreateBusinessPremises$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<BusinessPremise>) => r.body as BusinessPremise)
    );
  }

  /**
   * Creates a new instance in businessPremises of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateBusinessPremises$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateBusinessPremises$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBusinessPremise
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BusinessPremise>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateBusinessPremisesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BusinessPremise>;
      })
    );
  }

  /**
   * Creates a new instance in businessPremises of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateBusinessPremises$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateBusinessPremises$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBusinessPremise
  },
  context?: HttpContext

): Observable<BusinessPremise> {

    return this.organizationPrototypeCreateBusinessPremises$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<BusinessPremise>) => r.body as BusinessPremise)
    );
  }

  /**
   * Creates a new instance in businessPremises of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateBusinessPremises$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateBusinessPremises$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBusinessPremise
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BusinessPremise>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateBusinessPremisesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BusinessPremise>;
      })
    );
  }

  /**
   * Creates a new instance in businessPremises of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateBusinessPremises$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateBusinessPremises$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBusinessPremise
  },
  context?: HttpContext

): Observable<BusinessPremise> {

    return this.organizationPrototypeCreateBusinessPremises$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<BusinessPremise>) => r.body as BusinessPremise)
    );
  }

  /**
   * Creates a new instance in businessPremises of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateBusinessPremises$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateBusinessPremises$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBusinessPremise
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BusinessPremise>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateBusinessPremisesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BusinessPremise>;
      })
    );
  }

  /**
   * Creates a new instance in businessPremises of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateBusinessPremises$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateBusinessPremises$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBusinessPremise
  },
  context?: HttpContext

): Observable<BusinessPremise> {

    return this.organizationPrototypeCreateBusinessPremises$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<BusinessPremise>) => r.body as BusinessPremise)
    );
  }

  /**
   * Creates a new instance in businessPremises of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateBusinessPremises$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateBusinessPremises$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBusinessPremise
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BusinessPremise>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateBusinessPremisesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BusinessPremise>;
      })
    );
  }

  /**
   * Creates a new instance in businessPremises of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateBusinessPremises$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateBusinessPremises$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBusinessPremise
  },
  context?: HttpContext

): Observable<BusinessPremise> {

    return this.organizationPrototypeCreateBusinessPremises$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<BusinessPremise>) => r.body as BusinessPremise)
    );
  }

  /**
   * Creates a new instance in businessPremises of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateBusinessPremises$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateBusinessPremises$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBusinessPremise
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BusinessPremise>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateBusinessPremisesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BusinessPremise>;
      })
    );
  }

  /**
   * Creates a new instance in businessPremises of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateBusinessPremises$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateBusinessPremises$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBusinessPremise
  },
  context?: HttpContext

): Observable<BusinessPremise> {

    return this.organizationPrototypeCreateBusinessPremises$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<BusinessPremise>) => r.body as BusinessPremise)
    );
  }

  /**
   * Path part for operation organizationPrototypeGetElectronicDevices
   */
  static readonly OrganizationPrototypeGetElectronicDevicesPath = '/v1/Organizations/{id}/electronicDevices';

  /**
   * Queries electronicDevices of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetElectronicDevices$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetElectronicDevices$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ElectronicDevice>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetElectronicDevicesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ElectronicDevice>>;
      })
    );
  }

  /**
   * Queries electronicDevices of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetElectronicDevices$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetElectronicDevices$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<ElectronicDevice>> {

    return this.organizationPrototypeGetElectronicDevices$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ElectronicDevice>>) => r.body as Array<ElectronicDevice>)
    );
  }

  /**
   * Queries electronicDevices of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetElectronicDevices$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetElectronicDevices$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ElectronicDevice>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetElectronicDevicesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ElectronicDevice>>;
      })
    );
  }

  /**
   * Queries electronicDevices of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetElectronicDevices$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetElectronicDevices$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<ElectronicDevice>> {

    return this.organizationPrototypeGetElectronicDevices$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ElectronicDevice>>) => r.body as Array<ElectronicDevice>)
    );
  }

  /**
   * Queries electronicDevices of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetElectronicDevices$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetElectronicDevices$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ElectronicDevice>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetElectronicDevicesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ElectronicDevice>>;
      })
    );
  }

  /**
   * Queries electronicDevices of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetElectronicDevices$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetElectronicDevices$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<ElectronicDevice>> {

    return this.organizationPrototypeGetElectronicDevices$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ElectronicDevice>>) => r.body as Array<ElectronicDevice>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCreateElectronicDevices
   */
  static readonly OrganizationPrototypeCreateElectronicDevicesPath = '/v1/Organizations/{id}/electronicDevices';

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateElectronicDevices$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateElectronicDevices$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateElectronicDevicesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateElectronicDevices$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateElectronicDevices$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.organizationPrototypeCreateElectronicDevices$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateElectronicDevices$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateElectronicDevices$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateElectronicDevicesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateElectronicDevices$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateElectronicDevices$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.organizationPrototypeCreateElectronicDevices$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateElectronicDevices$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateElectronicDevices$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateElectronicDevicesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateElectronicDevices$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateElectronicDevices$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.organizationPrototypeCreateElectronicDevices$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateElectronicDevices$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateElectronicDevices$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateElectronicDevicesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateElectronicDevices$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateElectronicDevices$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.organizationPrototypeCreateElectronicDevices$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateElectronicDevices$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateElectronicDevices$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateElectronicDevicesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateElectronicDevices$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateElectronicDevices$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.organizationPrototypeCreateElectronicDevices$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateElectronicDevices$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateElectronicDevices$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateElectronicDevicesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateElectronicDevices$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateElectronicDevices$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.organizationPrototypeCreateElectronicDevices$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateElectronicDevices$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateElectronicDevices$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateElectronicDevicesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateElectronicDevices$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateElectronicDevices$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.organizationPrototypeCreateElectronicDevices$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateElectronicDevices$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateElectronicDevices$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateElectronicDevicesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateElectronicDevices$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateElectronicDevices$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.organizationPrototypeCreateElectronicDevices$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateElectronicDevices$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateElectronicDevices$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateElectronicDevicesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Creates a new instance in electronicDevices of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateElectronicDevices$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateElectronicDevices$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.organizationPrototypeCreateElectronicDevices$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Path part for operation organizationPrototypeGetAccountOrganizations
   */
  static readonly OrganizationPrototypeGetAccountOrganizationsPath = '/v1/Organizations/{id}/accountOrganizations';

  /**
   * Queries accountOrganizations of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetAccountOrganizations$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetAccountOrganizations$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AccountOrganization>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetAccountOrganizationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccountOrganization>>;
      })
    );
  }

  /**
   * Queries accountOrganizations of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetAccountOrganizations$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetAccountOrganizations$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<AccountOrganization>> {

    return this.organizationPrototypeGetAccountOrganizations$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AccountOrganization>>) => r.body as Array<AccountOrganization>)
    );
  }

  /**
   * Queries accountOrganizations of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetAccountOrganizations$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetAccountOrganizations$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AccountOrganization>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetAccountOrganizationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccountOrganization>>;
      })
    );
  }

  /**
   * Queries accountOrganizations of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetAccountOrganizations$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetAccountOrganizations$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<AccountOrganization>> {

    return this.organizationPrototypeGetAccountOrganizations$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AccountOrganization>>) => r.body as Array<AccountOrganization>)
    );
  }

  /**
   * Queries accountOrganizations of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetAccountOrganizations$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetAccountOrganizations$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<AccountOrganization>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetAccountOrganizationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccountOrganization>>;
      })
    );
  }

  /**
   * Queries accountOrganizations of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetAccountOrganizations$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetAccountOrganizations$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<AccountOrganization>> {

    return this.organizationPrototypeGetAccountOrganizations$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<AccountOrganization>>) => r.body as Array<AccountOrganization>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountAccountOrganizations
   */
  static readonly OrganizationPrototypeCountAccountOrganizationsPath = '/v1/Organizations/{id}/accountOrganizations/count';

  /**
   * Counts accountOrganizations of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountAccountOrganizations$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountAccountOrganizations$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountAccountOrganizationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts accountOrganizations of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountAccountOrganizations$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountAccountOrganizations$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountAccountOrganizations$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts accountOrganizations of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountAccountOrganizations$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountAccountOrganizations$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountAccountOrganizationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts accountOrganizations of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountAccountOrganizations$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountAccountOrganizations$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountAccountOrganizations$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts accountOrganizations of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountAccountOrganizations$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountAccountOrganizations$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountAccountOrganizationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts accountOrganizations of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountAccountOrganizations$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountAccountOrganizations$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountAccountOrganizations$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeGetUnits
   */
  static readonly OrganizationPrototypeGetUnitsPath = '/v1/Organizations/{id}/units';

  /**
   * Queries units of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetUnits$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetUnits$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Unit>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetUnitsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Unit>>;
      })
    );
  }

  /**
   * Queries units of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetUnits$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetUnits$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Unit>> {

    return this.organizationPrototypeGetUnits$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Unit>>) => r.body as Array<Unit>)
    );
  }

  /**
   * Queries units of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetUnits$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetUnits$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Unit>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetUnitsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Unit>>;
      })
    );
  }

  /**
   * Queries units of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetUnits$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetUnits$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Unit>> {

    return this.organizationPrototypeGetUnits$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Unit>>) => r.body as Array<Unit>)
    );
  }

  /**
   * Queries units of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetUnits$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetUnits$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Unit>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetUnitsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Unit>>;
      })
    );
  }

  /**
   * Queries units of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetUnits$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetUnits$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Unit>> {

    return this.organizationPrototypeGetUnits$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Unit>>) => r.body as Array<Unit>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCreateUnits
   */
  static readonly OrganizationPrototypeCreateUnitsPath = '/v1/Organizations/{id}/units';

  /**
   * Creates a new instance in units of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateUnits$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateUnits$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewUnit
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Unit>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateUnitsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Creates a new instance in units of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateUnits$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateUnits$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewUnit
  },
  context?: HttpContext

): Observable<Unit> {

    return this.organizationPrototypeCreateUnits$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Unit>) => r.body as Unit)
    );
  }

  /**
   * Creates a new instance in units of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateUnits$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateUnits$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewUnit
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Unit>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateUnitsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Creates a new instance in units of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateUnits$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateUnits$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewUnit
  },
  context?: HttpContext

): Observable<Unit> {

    return this.organizationPrototypeCreateUnits$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Unit>) => r.body as Unit)
    );
  }

  /**
   * Creates a new instance in units of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateUnits$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateUnits$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewUnit
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Unit>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateUnitsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Creates a new instance in units of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateUnits$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateUnits$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewUnit
  },
  context?: HttpContext

): Observable<Unit> {

    return this.organizationPrototypeCreateUnits$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Unit>) => r.body as Unit)
    );
  }

  /**
   * Creates a new instance in units of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateUnits$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateUnits$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewUnit
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Unit>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateUnitsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Creates a new instance in units of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateUnits$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateUnits$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewUnit
  },
  context?: HttpContext

): Observable<Unit> {

    return this.organizationPrototypeCreateUnits$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Unit>) => r.body as Unit)
    );
  }

  /**
   * Creates a new instance in units of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateUnits$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateUnits$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewUnit
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Unit>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateUnitsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Creates a new instance in units of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateUnits$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateUnits$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewUnit
  },
  context?: HttpContext

): Observable<Unit> {

    return this.organizationPrototypeCreateUnits$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Unit>) => r.body as Unit)
    );
  }

  /**
   * Creates a new instance in units of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateUnits$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateUnits$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewUnit
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Unit>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateUnitsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Creates a new instance in units of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateUnits$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateUnits$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewUnit
  },
  context?: HttpContext

): Observable<Unit> {

    return this.organizationPrototypeCreateUnits$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Unit>) => r.body as Unit)
    );
  }

  /**
   * Creates a new instance in units of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateUnits$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateUnits$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewUnit
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Unit>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateUnitsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Creates a new instance in units of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateUnits$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateUnits$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewUnit
  },
  context?: HttpContext

): Observable<Unit> {

    return this.organizationPrototypeCreateUnits$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Unit>) => r.body as Unit)
    );
  }

  /**
   * Creates a new instance in units of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateUnits$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateUnits$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewUnit
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Unit>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateUnitsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Creates a new instance in units of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateUnits$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateUnits$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewUnit
  },
  context?: HttpContext

): Observable<Unit> {

    return this.organizationPrototypeCreateUnits$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Unit>) => r.body as Unit)
    );
  }

  /**
   * Creates a new instance in units of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateUnits$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateUnits$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewUnit
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Unit>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateUnitsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Unit>;
      })
    );
  }

  /**
   * Creates a new instance in units of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateUnits$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateUnits$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewUnit
  },
  context?: HttpContext

): Observable<Unit> {

    return this.organizationPrototypeCreateUnits$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Unit>) => r.body as Unit)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountUnits
   */
  static readonly OrganizationPrototypeCountUnitsPath = '/v1/Organizations/{id}/units/count';

  /**
   * Counts units of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountUnits$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountUnits$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountUnitsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts units of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountUnits$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountUnits$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountUnits$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts units of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountUnits$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountUnits$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountUnitsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts units of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountUnits$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountUnits$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountUnits$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts units of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountUnits$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountUnits$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountUnitsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts units of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountUnits$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountUnits$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountUnits$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeGetCategories
   */
  static readonly OrganizationPrototypeGetCategoriesPath = '/v1/Organizations/{id}/categories';

  /**
   * Queries categories of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetCategories$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetCategories$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Category>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetCategoriesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Category>>;
      })
    );
  }

  /**
   * Queries categories of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetCategories$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetCategories$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Category>> {

    return this.organizationPrototypeGetCategories$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Category>>) => r.body as Array<Category>)
    );
  }

  /**
   * Queries categories of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetCategories$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetCategories$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Category>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetCategoriesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Category>>;
      })
    );
  }

  /**
   * Queries categories of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetCategories$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetCategories$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Category>> {

    return this.organizationPrototypeGetCategories$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Category>>) => r.body as Array<Category>)
    );
  }

  /**
   * Queries categories of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetCategories$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetCategories$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Category>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetCategoriesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Category>>;
      })
    );
  }

  /**
   * Queries categories of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetCategories$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetCategories$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Category>> {

    return this.organizationPrototypeGetCategories$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Category>>) => r.body as Array<Category>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCreateCategories
   */
  static readonly OrganizationPrototypeCreateCategoriesPath = '/v1/Organizations/{id}/categories';

  /**
   * Creates a new instance in categories of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateCategories$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateCategories$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCategory
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Category>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateCategoriesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Category>;
      })
    );
  }

  /**
   * Creates a new instance in categories of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateCategories$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateCategories$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCategory
  },
  context?: HttpContext

): Observable<Category> {

    return this.organizationPrototypeCreateCategories$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Category>) => r.body as Category)
    );
  }

  /**
   * Creates a new instance in categories of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateCategories$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateCategories$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCategory
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Category>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateCategoriesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Category>;
      })
    );
  }

  /**
   * Creates a new instance in categories of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateCategories$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateCategories$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCategory
  },
  context?: HttpContext

): Observable<Category> {

    return this.organizationPrototypeCreateCategories$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Category>) => r.body as Category)
    );
  }

  /**
   * Creates a new instance in categories of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateCategories$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateCategories$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCategory
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Category>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateCategoriesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Category>;
      })
    );
  }

  /**
   * Creates a new instance in categories of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateCategories$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateCategories$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCategory
  },
  context?: HttpContext

): Observable<Category> {

    return this.organizationPrototypeCreateCategories$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Category>) => r.body as Category)
    );
  }

  /**
   * Creates a new instance in categories of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateCategories$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateCategories$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCategory
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Category>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateCategoriesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Category>;
      })
    );
  }

  /**
   * Creates a new instance in categories of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateCategories$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateCategories$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCategory
  },
  context?: HttpContext

): Observable<Category> {

    return this.organizationPrototypeCreateCategories$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Category>) => r.body as Category)
    );
  }

  /**
   * Creates a new instance in categories of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateCategories$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateCategories$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCategory
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Category>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateCategoriesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Category>;
      })
    );
  }

  /**
   * Creates a new instance in categories of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateCategories$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateCategories$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCategory
  },
  context?: HttpContext

): Observable<Category> {

    return this.organizationPrototypeCreateCategories$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Category>) => r.body as Category)
    );
  }

  /**
   * Creates a new instance in categories of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateCategories$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateCategories$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCategory
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Category>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateCategoriesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Category>;
      })
    );
  }

  /**
   * Creates a new instance in categories of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateCategories$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateCategories$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCategory
  },
  context?: HttpContext

): Observable<Category> {

    return this.organizationPrototypeCreateCategories$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Category>) => r.body as Category)
    );
  }

  /**
   * Creates a new instance in categories of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateCategories$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateCategories$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCategory
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Category>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateCategoriesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Category>;
      })
    );
  }

  /**
   * Creates a new instance in categories of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateCategories$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateCategories$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCategory
  },
  context?: HttpContext

): Observable<Category> {

    return this.organizationPrototypeCreateCategories$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Category>) => r.body as Category)
    );
  }

  /**
   * Creates a new instance in categories of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateCategories$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateCategories$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCategory
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Category>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateCategoriesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Category>;
      })
    );
  }

  /**
   * Creates a new instance in categories of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateCategories$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateCategories$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCategory
  },
  context?: HttpContext

): Observable<Category> {

    return this.organizationPrototypeCreateCategories$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Category>) => r.body as Category)
    );
  }

  /**
   * Creates a new instance in categories of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateCategories$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateCategories$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCategory
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Category>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateCategoriesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Category>;
      })
    );
  }

  /**
   * Creates a new instance in categories of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateCategories$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateCategories$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCategory
  },
  context?: HttpContext

): Observable<Category> {

    return this.organizationPrototypeCreateCategories$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Category>) => r.body as Category)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountCategories
   */
  static readonly OrganizationPrototypeCountCategoriesPath = '/v1/Organizations/{id}/categories/count';

  /**
   * Counts categories of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountCategories$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountCategories$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountCategoriesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts categories of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountCategories$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountCategories$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountCategories$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts categories of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountCategories$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountCategories$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountCategoriesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts categories of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountCategories$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountCategories$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountCategories$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts categories of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountCategories$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountCategories$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountCategoriesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts categories of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountCategories$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountCategories$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountCategories$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeGetDocumentCategories
   */
  static readonly OrganizationPrototypeGetDocumentCategoriesPath = '/v1/Organizations/{id}/documentCategories';

  /**
   * Queries documentCategories of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetDocumentCategories$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetDocumentCategories$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<DocumentCategory>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetDocumentCategoriesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentCategory>>;
      })
    );
  }

  /**
   * Queries documentCategories of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetDocumentCategories$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetDocumentCategories$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<DocumentCategory>> {

    return this.organizationPrototypeGetDocumentCategories$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<DocumentCategory>>) => r.body as Array<DocumentCategory>)
    );
  }

  /**
   * Queries documentCategories of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetDocumentCategories$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetDocumentCategories$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<DocumentCategory>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetDocumentCategoriesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentCategory>>;
      })
    );
  }

  /**
   * Queries documentCategories of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetDocumentCategories$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetDocumentCategories$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<DocumentCategory>> {

    return this.organizationPrototypeGetDocumentCategories$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<DocumentCategory>>) => r.body as Array<DocumentCategory>)
    );
  }

  /**
   * Queries documentCategories of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetDocumentCategories$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetDocumentCategories$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<DocumentCategory>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetDocumentCategoriesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentCategory>>;
      })
    );
  }

  /**
   * Queries documentCategories of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetDocumentCategories$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetDocumentCategories$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<DocumentCategory>> {

    return this.organizationPrototypeGetDocumentCategories$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<DocumentCategory>>) => r.body as Array<DocumentCategory>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountDocumentCategories
   */
  static readonly OrganizationPrototypeCountDocumentCategoriesPath = '/v1/Organizations/{id}/documentCategories/count';

  /**
   * Counts documentCategories of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountDocumentCategories$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountDocumentCategories$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountDocumentCategoriesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts documentCategories of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountDocumentCategories$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountDocumentCategories$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountDocumentCategories$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts documentCategories of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountDocumentCategories$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountDocumentCategories$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountDocumentCategoriesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts documentCategories of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountDocumentCategories$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountDocumentCategories$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountDocumentCategories$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts documentCategories of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountDocumentCategories$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountDocumentCategories$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountDocumentCategoriesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts documentCategories of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountDocumentCategories$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountDocumentCategories$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountDocumentCategories$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeGetPriceLists
   */
  static readonly OrganizationPrototypeGetPriceListsPath = '/v1/Organizations/{id}/priceLists';

  /**
   * Queries priceLists of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetPriceLists$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetPriceLists$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PriceList>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetPriceListsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PriceList>>;
      })
    );
  }

  /**
   * Queries priceLists of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetPriceLists$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetPriceLists$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<PriceList>> {

    return this.organizationPrototypeGetPriceLists$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PriceList>>) => r.body as Array<PriceList>)
    );
  }

  /**
   * Queries priceLists of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetPriceLists$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetPriceLists$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PriceList>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetPriceListsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PriceList>>;
      })
    );
  }

  /**
   * Queries priceLists of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetPriceLists$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetPriceLists$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<PriceList>> {

    return this.organizationPrototypeGetPriceLists$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PriceList>>) => r.body as Array<PriceList>)
    );
  }

  /**
   * Queries priceLists of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetPriceLists$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetPriceLists$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PriceList>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetPriceListsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PriceList>>;
      })
    );
  }

  /**
   * Queries priceLists of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetPriceLists$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetPriceLists$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<PriceList>> {

    return this.organizationPrototypeGetPriceLists$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PriceList>>) => r.body as Array<PriceList>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCreatePriceLists
   */
  static readonly OrganizationPrototypeCreatePriceListsPath = '/v1/Organizations/{id}/priceLists';

  /**
   * Creates a new instance in priceLists of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreatePriceLists$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreatePriceLists$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPriceList
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PriceList>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreatePriceListsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PriceList>;
      })
    );
  }

  /**
   * Creates a new instance in priceLists of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreatePriceLists$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreatePriceLists$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPriceList
  },
  context?: HttpContext

): Observable<PriceList> {

    return this.organizationPrototypeCreatePriceLists$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<PriceList>) => r.body as PriceList)
    );
  }

  /**
   * Creates a new instance in priceLists of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreatePriceLists$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreatePriceLists$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPriceList
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PriceList>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreatePriceListsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PriceList>;
      })
    );
  }

  /**
   * Creates a new instance in priceLists of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreatePriceLists$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreatePriceLists$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPriceList
  },
  context?: HttpContext

): Observable<PriceList> {

    return this.organizationPrototypeCreatePriceLists$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<PriceList>) => r.body as PriceList)
    );
  }

  /**
   * Creates a new instance in priceLists of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreatePriceLists$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreatePriceLists$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPriceList
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PriceList>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreatePriceListsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PriceList>;
      })
    );
  }

  /**
   * Creates a new instance in priceLists of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreatePriceLists$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreatePriceLists$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPriceList
  },
  context?: HttpContext

): Observable<PriceList> {

    return this.organizationPrototypeCreatePriceLists$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<PriceList>) => r.body as PriceList)
    );
  }

  /**
   * Creates a new instance in priceLists of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreatePriceLists$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreatePriceLists$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPriceList
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PriceList>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreatePriceListsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PriceList>;
      })
    );
  }

  /**
   * Creates a new instance in priceLists of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreatePriceLists$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreatePriceLists$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPriceList
  },
  context?: HttpContext

): Observable<PriceList> {

    return this.organizationPrototypeCreatePriceLists$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<PriceList>) => r.body as PriceList)
    );
  }

  /**
   * Creates a new instance in priceLists of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreatePriceLists$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreatePriceLists$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPriceList
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PriceList>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreatePriceListsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PriceList>;
      })
    );
  }

  /**
   * Creates a new instance in priceLists of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreatePriceLists$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreatePriceLists$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPriceList
  },
  context?: HttpContext

): Observable<PriceList> {

    return this.organizationPrototypeCreatePriceLists$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<PriceList>) => r.body as PriceList)
    );
  }

  /**
   * Creates a new instance in priceLists of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreatePriceLists$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreatePriceLists$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPriceList
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PriceList>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreatePriceListsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PriceList>;
      })
    );
  }

  /**
   * Creates a new instance in priceLists of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreatePriceLists$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreatePriceLists$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPriceList
  },
  context?: HttpContext

): Observable<PriceList> {

    return this.organizationPrototypeCreatePriceLists$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<PriceList>) => r.body as PriceList)
    );
  }

  /**
   * Creates a new instance in priceLists of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreatePriceLists$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreatePriceLists$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPriceList
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PriceList>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreatePriceListsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PriceList>;
      })
    );
  }

  /**
   * Creates a new instance in priceLists of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreatePriceLists$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreatePriceLists$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPriceList
  },
  context?: HttpContext

): Observable<PriceList> {

    return this.organizationPrototypeCreatePriceLists$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<PriceList>) => r.body as PriceList)
    );
  }

  /**
   * Creates a new instance in priceLists of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreatePriceLists$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreatePriceLists$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPriceList
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PriceList>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreatePriceListsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PriceList>;
      })
    );
  }

  /**
   * Creates a new instance in priceLists of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreatePriceLists$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreatePriceLists$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPriceList
  },
  context?: HttpContext

): Observable<PriceList> {

    return this.organizationPrototypeCreatePriceLists$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<PriceList>) => r.body as PriceList)
    );
  }

  /**
   * Creates a new instance in priceLists of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreatePriceLists$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreatePriceLists$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPriceList
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PriceList>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreatePriceListsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PriceList>;
      })
    );
  }

  /**
   * Creates a new instance in priceLists of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreatePriceLists$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreatePriceLists$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewPriceList
  },
  context?: HttpContext

): Observable<PriceList> {

    return this.organizationPrototypeCreatePriceLists$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<PriceList>) => r.body as PriceList)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountPriceLists
   */
  static readonly OrganizationPrototypeCountPriceListsPath = '/v1/Organizations/{id}/priceLists/count';

  /**
   * Counts priceLists of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountPriceLists$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountPriceLists$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountPriceListsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts priceLists of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountPriceLists$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountPriceLists$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountPriceLists$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts priceLists of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountPriceLists$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountPriceLists$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountPriceListsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts priceLists of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountPriceLists$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountPriceLists$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountPriceLists$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts priceLists of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountPriceLists$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountPriceLists$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountPriceListsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts priceLists of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountPriceLists$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountPriceLists$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountPriceLists$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeGetWarehouses
   */
  static readonly OrganizationPrototypeGetWarehousesPath = '/v1/Organizations/{id}/warehouses';

  /**
   * Queries warehouses of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetWarehouses$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetWarehouses$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Warehouse>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetWarehousesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Warehouse>>;
      })
    );
  }

  /**
   * Queries warehouses of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetWarehouses$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetWarehouses$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Warehouse>> {

    return this.organizationPrototypeGetWarehouses$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Warehouse>>) => r.body as Array<Warehouse>)
    );
  }

  /**
   * Queries warehouses of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetWarehouses$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetWarehouses$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Warehouse>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetWarehousesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Warehouse>>;
      })
    );
  }

  /**
   * Queries warehouses of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetWarehouses$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetWarehouses$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Warehouse>> {

    return this.organizationPrototypeGetWarehouses$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Warehouse>>) => r.body as Array<Warehouse>)
    );
  }

  /**
   * Queries warehouses of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetWarehouses$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetWarehouses$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Warehouse>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetWarehousesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Warehouse>>;
      })
    );
  }

  /**
   * Queries warehouses of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetWarehouses$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetWarehouses$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Warehouse>> {

    return this.organizationPrototypeGetWarehouses$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Warehouse>>) => r.body as Array<Warehouse>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCreateWarehouses
   */
  static readonly OrganizationPrototypeCreateWarehousesPath = '/v1/Organizations/{id}/warehouses';

  /**
   * Creates a new instance in warehouses of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWarehouses$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateWarehouses$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWarehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWarehousesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Creates a new instance in warehouses of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWarehouses$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateWarehouses$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWarehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.organizationPrototypeCreateWarehouses$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Creates a new instance in warehouses of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWarehouses$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateWarehouses$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWarehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWarehousesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Creates a new instance in warehouses of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWarehouses$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateWarehouses$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWarehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.organizationPrototypeCreateWarehouses$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Creates a new instance in warehouses of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWarehouses$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateWarehouses$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWarehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWarehousesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Creates a new instance in warehouses of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWarehouses$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateWarehouses$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWarehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.organizationPrototypeCreateWarehouses$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Creates a new instance in warehouses of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWarehouses$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateWarehouses$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWarehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWarehousesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Creates a new instance in warehouses of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWarehouses$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateWarehouses$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWarehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.organizationPrototypeCreateWarehouses$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Creates a new instance in warehouses of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWarehouses$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateWarehouses$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWarehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWarehousesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Creates a new instance in warehouses of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWarehouses$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateWarehouses$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWarehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.organizationPrototypeCreateWarehouses$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Creates a new instance in warehouses of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWarehouses$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateWarehouses$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWarehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWarehousesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Creates a new instance in warehouses of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWarehouses$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateWarehouses$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWarehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.organizationPrototypeCreateWarehouses$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Creates a new instance in warehouses of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWarehouses$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateWarehouses$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWarehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWarehousesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Creates a new instance in warehouses of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWarehouses$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateWarehouses$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWarehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.organizationPrototypeCreateWarehouses$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Creates a new instance in warehouses of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWarehouses$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateWarehouses$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWarehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWarehousesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Creates a new instance in warehouses of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWarehouses$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateWarehouses$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWarehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.organizationPrototypeCreateWarehouses$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Creates a new instance in warehouses of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWarehouses$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateWarehouses$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWarehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWarehousesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Creates a new instance in warehouses of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWarehouses$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateWarehouses$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWarehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.organizationPrototypeCreateWarehouses$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountWarehouses
   */
  static readonly OrganizationPrototypeCountWarehousesPath = '/v1/Organizations/{id}/warehouses/count';

  /**
   * Counts warehouses of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountWarehouses$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountWarehouses$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountWarehousesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts warehouses of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountWarehouses$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountWarehouses$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountWarehouses$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts warehouses of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountWarehouses$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountWarehouses$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountWarehousesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts warehouses of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountWarehouses$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountWarehouses$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountWarehouses$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts warehouses of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountWarehouses$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountWarehouses$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountWarehousesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts warehouses of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountWarehouses$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountWarehouses$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountWarehouses$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeGetWebhooks
   */
  static readonly OrganizationPrototypeGetWebhooksPath = '/v1/Organizations/{id}/webhooks';

  /**
   * Queries webhooks of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetWebhooks$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetWebhooks$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Webhook>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetWebhooksPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Webhook>>;
      })
    );
  }

  /**
   * Queries webhooks of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetWebhooks$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetWebhooks$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Webhook>> {

    return this.organizationPrototypeGetWebhooks$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Webhook>>) => r.body as Array<Webhook>)
    );
  }

  /**
   * Queries webhooks of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetWebhooks$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetWebhooks$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Webhook>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetWebhooksPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Webhook>>;
      })
    );
  }

  /**
   * Queries webhooks of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetWebhooks$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetWebhooks$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Webhook>> {

    return this.organizationPrototypeGetWebhooks$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Webhook>>) => r.body as Array<Webhook>)
    );
  }

  /**
   * Queries webhooks of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetWebhooks$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetWebhooks$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Webhook>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetWebhooksPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Webhook>>;
      })
    );
  }

  /**
   * Queries webhooks of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetWebhooks$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetWebhooks$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Webhook>> {

    return this.organizationPrototypeGetWebhooks$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Webhook>>) => r.body as Array<Webhook>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCreateWebhooks
   */
  static readonly OrganizationPrototypeCreateWebhooksPath = '/v1/Organizations/{id}/webhooks';

  /**
   * Creates a new instance in webhooks of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWebhooks$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateWebhooks$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWebhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWebhooksPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Creates a new instance in webhooks of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWebhooks$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateWebhooks$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWebhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.organizationPrototypeCreateWebhooks$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Creates a new instance in webhooks of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWebhooks$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateWebhooks$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWebhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWebhooksPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Creates a new instance in webhooks of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWebhooks$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateWebhooks$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWebhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.organizationPrototypeCreateWebhooks$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Creates a new instance in webhooks of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWebhooks$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateWebhooks$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWebhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWebhooksPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Creates a new instance in webhooks of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWebhooks$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateWebhooks$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWebhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.organizationPrototypeCreateWebhooks$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Creates a new instance in webhooks of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWebhooks$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateWebhooks$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWebhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWebhooksPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Creates a new instance in webhooks of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWebhooks$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateWebhooks$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWebhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.organizationPrototypeCreateWebhooks$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Creates a new instance in webhooks of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWebhooks$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateWebhooks$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWebhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWebhooksPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Creates a new instance in webhooks of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWebhooks$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateWebhooks$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWebhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.organizationPrototypeCreateWebhooks$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Creates a new instance in webhooks of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWebhooks$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateWebhooks$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWebhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWebhooksPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Creates a new instance in webhooks of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWebhooks$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateWebhooks$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWebhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.organizationPrototypeCreateWebhooks$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Creates a new instance in webhooks of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWebhooks$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateWebhooks$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWebhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWebhooksPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Creates a new instance in webhooks of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWebhooks$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateWebhooks$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWebhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.organizationPrototypeCreateWebhooks$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Creates a new instance in webhooks of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWebhooks$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateWebhooks$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWebhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWebhooksPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Creates a new instance in webhooks of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWebhooks$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateWebhooks$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWebhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.organizationPrototypeCreateWebhooks$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Creates a new instance in webhooks of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWebhooks$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateWebhooks$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWebhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWebhooksPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Creates a new instance in webhooks of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWebhooks$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateWebhooks$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWebhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.organizationPrototypeCreateWebhooks$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountWebhooks
   */
  static readonly OrganizationPrototypeCountWebhooksPath = '/v1/Organizations/{id}/webhooks/count';

  /**
   * Counts webhooks of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountWebhooks$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountWebhooks$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountWebhooksPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts webhooks of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountWebhooks$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountWebhooks$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountWebhooks$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts webhooks of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountWebhooks$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountWebhooks$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountWebhooksPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts webhooks of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountWebhooks$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountWebhooks$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountWebhooks$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts webhooks of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountWebhooks$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountWebhooks$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountWebhooksPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts webhooks of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountWebhooks$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountWebhooks$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountWebhooks$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeGetOrders
   */
  static readonly OrganizationPrototypeGetOrdersPath = '/v1/Organizations/{id}/orders';

  /**
   * Queries orders of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetOrders$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetOrders$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Order>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetOrdersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Order>>;
      })
    );
  }

  /**
   * Queries orders of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetOrders$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetOrders$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Order>> {

    return this.organizationPrototypeGetOrders$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Order>>) => r.body as Array<Order>)
    );
  }

  /**
   * Queries orders of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetOrders$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetOrders$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Order>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetOrdersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Order>>;
      })
    );
  }

  /**
   * Queries orders of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetOrders$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetOrders$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Order>> {

    return this.organizationPrototypeGetOrders$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Order>>) => r.body as Array<Order>)
    );
  }

  /**
   * Queries orders of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetOrders$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetOrders$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Order>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetOrdersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Order>>;
      })
    );
  }

  /**
   * Queries orders of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetOrders$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetOrders$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Order>> {

    return this.organizationPrototypeGetOrders$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Order>>) => r.body as Array<Order>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountOrders
   */
  static readonly OrganizationPrototypeCountOrdersPath = '/v1/Organizations/{id}/orders/count';

  /**
   * Counts orders of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountOrders$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountOrders$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountOrdersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts orders of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountOrders$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountOrders$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountOrders$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts orders of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountOrders$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountOrders$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountOrdersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts orders of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountOrders$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountOrders$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountOrders$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts orders of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountOrders$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountOrders$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountOrdersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts orders of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountOrders$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountOrders$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountOrders$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeGetMagento1
   */
  static readonly OrganizationPrototypeGetMagento1Path = '/v1/Organizations/{id}/magento1';

  /**
   * Queries magento1 of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetMagento1$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetMagento1$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Magento1>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetMagento1Path, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Magento1>>;
      })
    );
  }

  /**
   * Queries magento1 of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetMagento1$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetMagento1$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Magento1>> {

    return this.organizationPrototypeGetMagento1$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Magento1>>) => r.body as Array<Magento1>)
    );
  }

  /**
   * Queries magento1 of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetMagento1$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetMagento1$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Magento1>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetMagento1Path, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Magento1>>;
      })
    );
  }

  /**
   * Queries magento1 of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetMagento1$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetMagento1$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Magento1>> {

    return this.organizationPrototypeGetMagento1$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Magento1>>) => r.body as Array<Magento1>)
    );
  }

  /**
   * Queries magento1 of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetMagento1$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetMagento1$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Magento1>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetMagento1Path, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Magento1>>;
      })
    );
  }

  /**
   * Queries magento1 of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetMagento1$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetMagento1$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Magento1>> {

    return this.organizationPrototypeGetMagento1$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Magento1>>) => r.body as Array<Magento1>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCreateMagento1
   */
  static readonly OrganizationPrototypeCreateMagento1Path = '/v1/Organizations/{id}/magento1';

  /**
   * Creates a new instance in magento1 of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateMagento1$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateMagento1$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewMagento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Magento1>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateMagento1Path, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magento1>;
      })
    );
  }

  /**
   * Creates a new instance in magento1 of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateMagento1$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateMagento1$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewMagento1
  },
  context?: HttpContext

): Observable<Magento1> {

    return this.organizationPrototypeCreateMagento1$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Magento1>) => r.body as Magento1)
    );
  }

  /**
   * Creates a new instance in magento1 of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateMagento1$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateMagento1$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewMagento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Magento1>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateMagento1Path, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magento1>;
      })
    );
  }

  /**
   * Creates a new instance in magento1 of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateMagento1$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateMagento1$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewMagento1
  },
  context?: HttpContext

): Observable<Magento1> {

    return this.organizationPrototypeCreateMagento1$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Magento1>) => r.body as Magento1)
    );
  }

  /**
   * Creates a new instance in magento1 of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateMagento1$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateMagento1$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewMagento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Magento1>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateMagento1Path, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magento1>;
      })
    );
  }

  /**
   * Creates a new instance in magento1 of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateMagento1$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateMagento1$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewMagento1
  },
  context?: HttpContext

): Observable<Magento1> {

    return this.organizationPrototypeCreateMagento1$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Magento1>) => r.body as Magento1)
    );
  }

  /**
   * Creates a new instance in magento1 of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateMagento1$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateMagento1$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewMagento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Magento1>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateMagento1Path, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magento1>;
      })
    );
  }

  /**
   * Creates a new instance in magento1 of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateMagento1$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateMagento1$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewMagento1
  },
  context?: HttpContext

): Observable<Magento1> {

    return this.organizationPrototypeCreateMagento1$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Magento1>) => r.body as Magento1)
    );
  }

  /**
   * Creates a new instance in magento1 of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateMagento1$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateMagento1$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewMagento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Magento1>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateMagento1Path, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magento1>;
      })
    );
  }

  /**
   * Creates a new instance in magento1 of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateMagento1$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateMagento1$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewMagento1
  },
  context?: HttpContext

): Observable<Magento1> {

    return this.organizationPrototypeCreateMagento1$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Magento1>) => r.body as Magento1)
    );
  }

  /**
   * Creates a new instance in magento1 of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateMagento1$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateMagento1$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewMagento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Magento1>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateMagento1Path, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magento1>;
      })
    );
  }

  /**
   * Creates a new instance in magento1 of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateMagento1$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateMagento1$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewMagento1
  },
  context?: HttpContext

): Observable<Magento1> {

    return this.organizationPrototypeCreateMagento1$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Magento1>) => r.body as Magento1)
    );
  }

  /**
   * Creates a new instance in magento1 of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateMagento1$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateMagento1$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewMagento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Magento1>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateMagento1Path, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magento1>;
      })
    );
  }

  /**
   * Creates a new instance in magento1 of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateMagento1$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateMagento1$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewMagento1
  },
  context?: HttpContext

): Observable<Magento1> {

    return this.organizationPrototypeCreateMagento1$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Magento1>) => r.body as Magento1)
    );
  }

  /**
   * Creates a new instance in magento1 of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateMagento1$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateMagento1$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewMagento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Magento1>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateMagento1Path, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magento1>;
      })
    );
  }

  /**
   * Creates a new instance in magento1 of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateMagento1$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateMagento1$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewMagento1
  },
  context?: HttpContext

): Observable<Magento1> {

    return this.organizationPrototypeCreateMagento1$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Magento1>) => r.body as Magento1)
    );
  }

  /**
   * Creates a new instance in magento1 of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateMagento1$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateMagento1$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewMagento1
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Magento1>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateMagento1Path, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Magento1>;
      })
    );
  }

  /**
   * Creates a new instance in magento1 of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateMagento1$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateMagento1$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewMagento1
  },
  context?: HttpContext

): Observable<Magento1> {

    return this.organizationPrototypeCreateMagento1$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Magento1>) => r.body as Magento1)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountMagento1
   */
  static readonly OrganizationPrototypeCountMagento1Path = '/v1/Organizations/{id}/magento1/count';

  /**
   * Counts magento1 of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountMagento1$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountMagento1$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountMagento1Path, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts magento1 of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountMagento1$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountMagento1$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountMagento1$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts magento1 of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountMagento1$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountMagento1$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountMagento1Path, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts magento1 of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountMagento1$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountMagento1$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountMagento1$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts magento1 of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountMagento1$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountMagento1$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountMagento1Path, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts magento1 of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountMagento1$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountMagento1$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountMagento1$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeGetWoocommerce
   */
  static readonly OrganizationPrototypeGetWoocommercePath = '/v1/Organizations/{id}/woocommerce';

  /**
   * Queries woocommerce of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetWoocommerce$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetWoocommerce$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Woocommerce>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetWoocommercePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Woocommerce>>;
      })
    );
  }

  /**
   * Queries woocommerce of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetWoocommerce$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetWoocommerce$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Woocommerce>> {

    return this.organizationPrototypeGetWoocommerce$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Woocommerce>>) => r.body as Array<Woocommerce>)
    );
  }

  /**
   * Queries woocommerce of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetWoocommerce$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetWoocommerce$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Woocommerce>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetWoocommercePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Woocommerce>>;
      })
    );
  }

  /**
   * Queries woocommerce of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetWoocommerce$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetWoocommerce$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Woocommerce>> {

    return this.organizationPrototypeGetWoocommerce$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Woocommerce>>) => r.body as Array<Woocommerce>)
    );
  }

  /**
   * Queries woocommerce of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetWoocommerce$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetWoocommerce$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Woocommerce>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetWoocommercePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Woocommerce>>;
      })
    );
  }

  /**
   * Queries woocommerce of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetWoocommerce$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetWoocommerce$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Woocommerce>> {

    return this.organizationPrototypeGetWoocommerce$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Woocommerce>>) => r.body as Array<Woocommerce>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCreateWoocommerce
   */
  static readonly OrganizationPrototypeCreateWoocommercePath = '/v1/Organizations/{id}/woocommerce';

  /**
   * Creates a new instance in woocommerce of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWoocommerce$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateWoocommerce$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWoocommerce
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Woocommerce>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWoocommercePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Woocommerce>;
      })
    );
  }

  /**
   * Creates a new instance in woocommerce of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWoocommerce$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateWoocommerce$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWoocommerce
  },
  context?: HttpContext

): Observable<Woocommerce> {

    return this.organizationPrototypeCreateWoocommerce$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Woocommerce>) => r.body as Woocommerce)
    );
  }

  /**
   * Creates a new instance in woocommerce of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWoocommerce$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateWoocommerce$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWoocommerce
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Woocommerce>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWoocommercePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Woocommerce>;
      })
    );
  }

  /**
   * Creates a new instance in woocommerce of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWoocommerce$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateWoocommerce$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWoocommerce
  },
  context?: HttpContext

): Observable<Woocommerce> {

    return this.organizationPrototypeCreateWoocommerce$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Woocommerce>) => r.body as Woocommerce)
    );
  }

  /**
   * Creates a new instance in woocommerce of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWoocommerce$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateWoocommerce$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWoocommerce
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Woocommerce>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWoocommercePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Woocommerce>;
      })
    );
  }

  /**
   * Creates a new instance in woocommerce of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWoocommerce$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateWoocommerce$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWoocommerce
  },
  context?: HttpContext

): Observable<Woocommerce> {

    return this.organizationPrototypeCreateWoocommerce$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Woocommerce>) => r.body as Woocommerce)
    );
  }

  /**
   * Creates a new instance in woocommerce of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWoocommerce$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateWoocommerce$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWoocommerce
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Woocommerce>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWoocommercePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Woocommerce>;
      })
    );
  }

  /**
   * Creates a new instance in woocommerce of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWoocommerce$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateWoocommerce$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWoocommerce
  },
  context?: HttpContext

): Observable<Woocommerce> {

    return this.organizationPrototypeCreateWoocommerce$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Woocommerce>) => r.body as Woocommerce)
    );
  }

  /**
   * Creates a new instance in woocommerce of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWoocommerce$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateWoocommerce$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWoocommerce
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Woocommerce>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWoocommercePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Woocommerce>;
      })
    );
  }

  /**
   * Creates a new instance in woocommerce of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWoocommerce$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateWoocommerce$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWoocommerce
  },
  context?: HttpContext

): Observable<Woocommerce> {

    return this.organizationPrototypeCreateWoocommerce$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Woocommerce>) => r.body as Woocommerce)
    );
  }

  /**
   * Creates a new instance in woocommerce of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWoocommerce$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateWoocommerce$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWoocommerce
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Woocommerce>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWoocommercePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Woocommerce>;
      })
    );
  }

  /**
   * Creates a new instance in woocommerce of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWoocommerce$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateWoocommerce$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWoocommerce
  },
  context?: HttpContext

): Observable<Woocommerce> {

    return this.organizationPrototypeCreateWoocommerce$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Woocommerce>) => r.body as Woocommerce)
    );
  }

  /**
   * Creates a new instance in woocommerce of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWoocommerce$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateWoocommerce$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWoocommerce
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Woocommerce>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWoocommercePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Woocommerce>;
      })
    );
  }

  /**
   * Creates a new instance in woocommerce of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWoocommerce$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateWoocommerce$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWoocommerce
  },
  context?: HttpContext

): Observable<Woocommerce> {

    return this.organizationPrototypeCreateWoocommerce$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Woocommerce>) => r.body as Woocommerce)
    );
  }

  /**
   * Creates a new instance in woocommerce of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWoocommerce$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateWoocommerce$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWoocommerce
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Woocommerce>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWoocommercePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Woocommerce>;
      })
    );
  }

  /**
   * Creates a new instance in woocommerce of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWoocommerce$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateWoocommerce$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWoocommerce
  },
  context?: HttpContext

): Observable<Woocommerce> {

    return this.organizationPrototypeCreateWoocommerce$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Woocommerce>) => r.body as Woocommerce)
    );
  }

  /**
   * Creates a new instance in woocommerce of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateWoocommerce$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateWoocommerce$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWoocommerce
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Woocommerce>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateWoocommercePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Woocommerce>;
      })
    );
  }

  /**
   * Creates a new instance in woocommerce of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateWoocommerce$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateWoocommerce$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewWoocommerce
  },
  context?: HttpContext

): Observable<Woocommerce> {

    return this.organizationPrototypeCreateWoocommerce$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Woocommerce>) => r.body as Woocommerce)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountWoocommerce
   */
  static readonly OrganizationPrototypeCountWoocommercePath = '/v1/Organizations/{id}/woocommerce/count';

  /**
   * Counts woocommerce of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountWoocommerce$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountWoocommerce$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountWoocommercePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts woocommerce of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountWoocommerce$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountWoocommerce$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountWoocommerce$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts woocommerce of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountWoocommerce$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountWoocommerce$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountWoocommercePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts woocommerce of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountWoocommerce$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountWoocommerce$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountWoocommerce$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts woocommerce of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountWoocommerce$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountWoocommerce$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountWoocommercePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts woocommerce of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountWoocommerce$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountWoocommerce$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountWoocommerce$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeGetCustomIntegrations
   */
  static readonly OrganizationPrototypeGetCustomIntegrationsPath = '/v1/Organizations/{id}/customIntegrations';

  /**
   * Queries customIntegrations of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetCustomIntegrations$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetCustomIntegrations$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CustomIntegration>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetCustomIntegrationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CustomIntegration>>;
      })
    );
  }

  /**
   * Queries customIntegrations of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetCustomIntegrations$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetCustomIntegrations$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<CustomIntegration>> {

    return this.organizationPrototypeGetCustomIntegrations$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CustomIntegration>>) => r.body as Array<CustomIntegration>)
    );
  }

  /**
   * Queries customIntegrations of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetCustomIntegrations$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetCustomIntegrations$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CustomIntegration>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetCustomIntegrationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CustomIntegration>>;
      })
    );
  }

  /**
   * Queries customIntegrations of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetCustomIntegrations$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetCustomIntegrations$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<CustomIntegration>> {

    return this.organizationPrototypeGetCustomIntegrations$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CustomIntegration>>) => r.body as Array<CustomIntegration>)
    );
  }

  /**
   * Queries customIntegrations of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetCustomIntegrations$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetCustomIntegrations$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CustomIntegration>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetCustomIntegrationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CustomIntegration>>;
      })
    );
  }

  /**
   * Queries customIntegrations of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetCustomIntegrations$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetCustomIntegrations$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<CustomIntegration>> {

    return this.organizationPrototypeGetCustomIntegrations$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CustomIntegration>>) => r.body as Array<CustomIntegration>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCreateCustomIntegrations
   */
  static readonly OrganizationPrototypeCreateCustomIntegrationsPath = '/v1/Organizations/{id}/customIntegrations';

  /**
   * Creates a new instance in customIntegrations of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateCustomIntegrations$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateCustomIntegrations$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CustomIntegration>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateCustomIntegrationsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomIntegration>;
      })
    );
  }

  /**
   * Creates a new instance in customIntegrations of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateCustomIntegrations$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateCustomIntegrations$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCustomIntegration
  },
  context?: HttpContext

): Observable<CustomIntegration> {

    return this.organizationPrototypeCreateCustomIntegrations$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<CustomIntegration>) => r.body as CustomIntegration)
    );
  }

  /**
   * Creates a new instance in customIntegrations of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateCustomIntegrations$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateCustomIntegrations$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CustomIntegration>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateCustomIntegrationsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomIntegration>;
      })
    );
  }

  /**
   * Creates a new instance in customIntegrations of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateCustomIntegrations$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateCustomIntegrations$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCustomIntegration
  },
  context?: HttpContext

): Observable<CustomIntegration> {

    return this.organizationPrototypeCreateCustomIntegrations$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<CustomIntegration>) => r.body as CustomIntegration)
    );
  }

  /**
   * Creates a new instance in customIntegrations of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateCustomIntegrations$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateCustomIntegrations$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CustomIntegration>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateCustomIntegrationsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomIntegration>;
      })
    );
  }

  /**
   * Creates a new instance in customIntegrations of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateCustomIntegrations$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateCustomIntegrations$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCustomIntegration
  },
  context?: HttpContext

): Observable<CustomIntegration> {

    return this.organizationPrototypeCreateCustomIntegrations$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<CustomIntegration>) => r.body as CustomIntegration)
    );
  }

  /**
   * Creates a new instance in customIntegrations of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateCustomIntegrations$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateCustomIntegrations$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CustomIntegration>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateCustomIntegrationsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomIntegration>;
      })
    );
  }

  /**
   * Creates a new instance in customIntegrations of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateCustomIntegrations$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateCustomIntegrations$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCustomIntegration
  },
  context?: HttpContext

): Observable<CustomIntegration> {

    return this.organizationPrototypeCreateCustomIntegrations$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<CustomIntegration>) => r.body as CustomIntegration)
    );
  }

  /**
   * Creates a new instance in customIntegrations of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateCustomIntegrations$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateCustomIntegrations$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CustomIntegration>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateCustomIntegrationsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomIntegration>;
      })
    );
  }

  /**
   * Creates a new instance in customIntegrations of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateCustomIntegrations$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateCustomIntegrations$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCustomIntegration
  },
  context?: HttpContext

): Observable<CustomIntegration> {

    return this.organizationPrototypeCreateCustomIntegrations$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<CustomIntegration>) => r.body as CustomIntegration)
    );
  }

  /**
   * Creates a new instance in customIntegrations of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateCustomIntegrations$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateCustomIntegrations$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CustomIntegration>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateCustomIntegrationsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomIntegration>;
      })
    );
  }

  /**
   * Creates a new instance in customIntegrations of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateCustomIntegrations$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateCustomIntegrations$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCustomIntegration
  },
  context?: HttpContext

): Observable<CustomIntegration> {

    return this.organizationPrototypeCreateCustomIntegrations$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<CustomIntegration>) => r.body as CustomIntegration)
    );
  }

  /**
   * Creates a new instance in customIntegrations of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateCustomIntegrations$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateCustomIntegrations$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CustomIntegration>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateCustomIntegrationsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomIntegration>;
      })
    );
  }

  /**
   * Creates a new instance in customIntegrations of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateCustomIntegrations$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateCustomIntegrations$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCustomIntegration
  },
  context?: HttpContext

): Observable<CustomIntegration> {

    return this.organizationPrototypeCreateCustomIntegrations$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<CustomIntegration>) => r.body as CustomIntegration)
    );
  }

  /**
   * Creates a new instance in customIntegrations of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateCustomIntegrations$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateCustomIntegrations$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CustomIntegration>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateCustomIntegrationsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomIntegration>;
      })
    );
  }

  /**
   * Creates a new instance in customIntegrations of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateCustomIntegrations$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateCustomIntegrations$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCustomIntegration
  },
  context?: HttpContext

): Observable<CustomIntegration> {

    return this.organizationPrototypeCreateCustomIntegrations$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<CustomIntegration>) => r.body as CustomIntegration)
    );
  }

  /**
   * Creates a new instance in customIntegrations of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateCustomIntegrations$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateCustomIntegrations$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCustomIntegration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CustomIntegration>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateCustomIntegrationsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomIntegration>;
      })
    );
  }

  /**
   * Creates a new instance in customIntegrations of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateCustomIntegrations$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateCustomIntegrations$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewCustomIntegration
  },
  context?: HttpContext

): Observable<CustomIntegration> {

    return this.organizationPrototypeCreateCustomIntegrations$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<CustomIntegration>) => r.body as CustomIntegration)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountCustomIntegrations
   */
  static readonly OrganizationPrototypeCountCustomIntegrationsPath = '/v1/Organizations/{id}/customIntegrations/count';

  /**
   * Counts customIntegrations of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountCustomIntegrations$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountCustomIntegrations$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountCustomIntegrationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts customIntegrations of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountCustomIntegrations$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountCustomIntegrations$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountCustomIntegrations$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts customIntegrations of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountCustomIntegrations$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountCustomIntegrations$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountCustomIntegrationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts customIntegrations of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountCustomIntegrations$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountCustomIntegrations$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountCustomIntegrations$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts customIntegrations of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountCustomIntegrations$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountCustomIntegrations$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountCustomIntegrationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts customIntegrations of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountCustomIntegrations$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountCustomIntegrations$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountCustomIntegrations$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeGetThrivecart
   */
  static readonly OrganizationPrototypeGetThrivecartPath = '/v1/Organizations/{id}/thrivecart';

  /**
   * Queries thrivecart of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetThrivecart$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetThrivecart$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Thrivecart>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetThrivecartPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Thrivecart>>;
      })
    );
  }

  /**
   * Queries thrivecart of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetThrivecart$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetThrivecart$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Thrivecart>> {

    return this.organizationPrototypeGetThrivecart$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Thrivecart>>) => r.body as Array<Thrivecart>)
    );
  }

  /**
   * Queries thrivecart of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetThrivecart$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetThrivecart$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Thrivecart>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetThrivecartPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Thrivecart>>;
      })
    );
  }

  /**
   * Queries thrivecart of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetThrivecart$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetThrivecart$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Thrivecart>> {

    return this.organizationPrototypeGetThrivecart$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Thrivecart>>) => r.body as Array<Thrivecart>)
    );
  }

  /**
   * Queries thrivecart of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetThrivecart$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetThrivecart$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Thrivecart>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetThrivecartPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Thrivecart>>;
      })
    );
  }

  /**
   * Queries thrivecart of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetThrivecart$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetThrivecart$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Thrivecart>> {

    return this.organizationPrototypeGetThrivecart$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Thrivecart>>) => r.body as Array<Thrivecart>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCreateThrivecart
   */
  static readonly OrganizationPrototypeCreateThrivecartPath = '/v1/Organizations/{id}/thrivecart';

  /**
   * Creates a new instance in thrivecart of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateThrivecart$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateThrivecart$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewThrivecart
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Thrivecart>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateThrivecartPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Thrivecart>;
      })
    );
  }

  /**
   * Creates a new instance in thrivecart of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateThrivecart$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateThrivecart$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewThrivecart
  },
  context?: HttpContext

): Observable<Thrivecart> {

    return this.organizationPrototypeCreateThrivecart$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Thrivecart>) => r.body as Thrivecart)
    );
  }

  /**
   * Creates a new instance in thrivecart of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateThrivecart$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateThrivecart$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewThrivecart
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Thrivecart>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateThrivecartPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Thrivecart>;
      })
    );
  }

  /**
   * Creates a new instance in thrivecart of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateThrivecart$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateThrivecart$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewThrivecart
  },
  context?: HttpContext

): Observable<Thrivecart> {

    return this.organizationPrototypeCreateThrivecart$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Thrivecart>) => r.body as Thrivecart)
    );
  }

  /**
   * Creates a new instance in thrivecart of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateThrivecart$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateThrivecart$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewThrivecart
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Thrivecart>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateThrivecartPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Thrivecart>;
      })
    );
  }

  /**
   * Creates a new instance in thrivecart of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateThrivecart$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateThrivecart$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewThrivecart
  },
  context?: HttpContext

): Observable<Thrivecart> {

    return this.organizationPrototypeCreateThrivecart$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Thrivecart>) => r.body as Thrivecart)
    );
  }

  /**
   * Creates a new instance in thrivecart of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateThrivecart$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateThrivecart$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewThrivecart
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Thrivecart>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateThrivecartPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Thrivecart>;
      })
    );
  }

  /**
   * Creates a new instance in thrivecart of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateThrivecart$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateThrivecart$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewThrivecart
  },
  context?: HttpContext

): Observable<Thrivecart> {

    return this.organizationPrototypeCreateThrivecart$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Thrivecart>) => r.body as Thrivecart)
    );
  }

  /**
   * Creates a new instance in thrivecart of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateThrivecart$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateThrivecart$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewThrivecart
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Thrivecart>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateThrivecartPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Thrivecart>;
      })
    );
  }

  /**
   * Creates a new instance in thrivecart of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateThrivecart$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateThrivecart$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewThrivecart
  },
  context?: HttpContext

): Observable<Thrivecart> {

    return this.organizationPrototypeCreateThrivecart$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Thrivecart>) => r.body as Thrivecart)
    );
  }

  /**
   * Creates a new instance in thrivecart of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateThrivecart$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateThrivecart$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewThrivecart
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Thrivecart>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateThrivecartPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Thrivecart>;
      })
    );
  }

  /**
   * Creates a new instance in thrivecart of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateThrivecart$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateThrivecart$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewThrivecart
  },
  context?: HttpContext

): Observable<Thrivecart> {

    return this.organizationPrototypeCreateThrivecart$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Thrivecart>) => r.body as Thrivecart)
    );
  }

  /**
   * Creates a new instance in thrivecart of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateThrivecart$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateThrivecart$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewThrivecart
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Thrivecart>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateThrivecartPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Thrivecart>;
      })
    );
  }

  /**
   * Creates a new instance in thrivecart of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateThrivecart$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateThrivecart$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewThrivecart
  },
  context?: HttpContext

): Observable<Thrivecart> {

    return this.organizationPrototypeCreateThrivecart$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Thrivecart>) => r.body as Thrivecart)
    );
  }

  /**
   * Creates a new instance in thrivecart of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateThrivecart$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateThrivecart$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewThrivecart
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Thrivecart>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateThrivecartPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Thrivecart>;
      })
    );
  }

  /**
   * Creates a new instance in thrivecart of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateThrivecart$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateThrivecart$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewThrivecart
  },
  context?: HttpContext

): Observable<Thrivecart> {

    return this.organizationPrototypeCreateThrivecart$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Thrivecart>) => r.body as Thrivecart)
    );
  }

  /**
   * Creates a new instance in thrivecart of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateThrivecart$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateThrivecart$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewThrivecart
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Thrivecart>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateThrivecartPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Thrivecart>;
      })
    );
  }

  /**
   * Creates a new instance in thrivecart of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateThrivecart$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateThrivecart$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewThrivecart
  },
  context?: HttpContext

): Observable<Thrivecart> {

    return this.organizationPrototypeCreateThrivecart$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Thrivecart>) => r.body as Thrivecart)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountThrivecart
   */
  static readonly OrganizationPrototypeCountThrivecartPath = '/v1/Organizations/{id}/thrivecart/count';

  /**
   * Counts thrivecart of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountThrivecart$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountThrivecart$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountThrivecartPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts thrivecart of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountThrivecart$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountThrivecart$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountThrivecart$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts thrivecart of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountThrivecart$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountThrivecart$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountThrivecartPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts thrivecart of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountThrivecart$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountThrivecart$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountThrivecart$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts thrivecart of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountThrivecart$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountThrivecart$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountThrivecartPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts thrivecart of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountThrivecart$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountThrivecart$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountThrivecart$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeGetBokun
   */
  static readonly OrganizationPrototypeGetBokunPath = '/v1/Organizations/{id}/bokun';

  /**
   * Queries bokun of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetBokun$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetBokun$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Bokun>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetBokunPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Bokun>>;
      })
    );
  }

  /**
   * Queries bokun of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetBokun$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetBokun$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Bokun>> {

    return this.organizationPrototypeGetBokun$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Bokun>>) => r.body as Array<Bokun>)
    );
  }

  /**
   * Queries bokun of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetBokun$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetBokun$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Bokun>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetBokunPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Bokun>>;
      })
    );
  }

  /**
   * Queries bokun of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetBokun$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetBokun$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Bokun>> {

    return this.organizationPrototypeGetBokun$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Bokun>>) => r.body as Array<Bokun>)
    );
  }

  /**
   * Queries bokun of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetBokun$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetBokun$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Bokun>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetBokunPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Bokun>>;
      })
    );
  }

  /**
   * Queries bokun of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetBokun$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetBokun$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Bokun>> {

    return this.organizationPrototypeGetBokun$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Bokun>>) => r.body as Array<Bokun>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCreateBokun
   */
  static readonly OrganizationPrototypeCreateBokunPath = '/v1/Organizations/{id}/bokun';

  /**
   * Creates a new instance in bokun of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateBokun$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateBokun$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Bokun>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateBokunPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Bokun>;
      })
    );
  }

  /**
   * Creates a new instance in bokun of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateBokun$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateBokun$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBokun
  },
  context?: HttpContext

): Observable<Bokun> {

    return this.organizationPrototypeCreateBokun$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Bokun>) => r.body as Bokun)
    );
  }

  /**
   * Creates a new instance in bokun of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateBokun$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateBokun$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Bokun>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateBokunPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Bokun>;
      })
    );
  }

  /**
   * Creates a new instance in bokun of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateBokun$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateBokun$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBokun
  },
  context?: HttpContext

): Observable<Bokun> {

    return this.organizationPrototypeCreateBokun$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Bokun>) => r.body as Bokun)
    );
  }

  /**
   * Creates a new instance in bokun of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateBokun$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateBokun$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Bokun>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateBokunPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Bokun>;
      })
    );
  }

  /**
   * Creates a new instance in bokun of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateBokun$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateBokun$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBokun
  },
  context?: HttpContext

): Observable<Bokun> {

    return this.organizationPrototypeCreateBokun$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Bokun>) => r.body as Bokun)
    );
  }

  /**
   * Creates a new instance in bokun of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateBokun$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateBokun$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Bokun>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateBokunPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Bokun>;
      })
    );
  }

  /**
   * Creates a new instance in bokun of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateBokun$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateBokun$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBokun
  },
  context?: HttpContext

): Observable<Bokun> {

    return this.organizationPrototypeCreateBokun$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Bokun>) => r.body as Bokun)
    );
  }

  /**
   * Creates a new instance in bokun of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateBokun$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateBokun$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Bokun>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateBokunPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Bokun>;
      })
    );
  }

  /**
   * Creates a new instance in bokun of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateBokun$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateBokun$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBokun
  },
  context?: HttpContext

): Observable<Bokun> {

    return this.organizationPrototypeCreateBokun$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Bokun>) => r.body as Bokun)
    );
  }

  /**
   * Creates a new instance in bokun of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateBokun$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateBokun$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Bokun>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateBokunPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Bokun>;
      })
    );
  }

  /**
   * Creates a new instance in bokun of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateBokun$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateBokun$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBokun
  },
  context?: HttpContext

): Observable<Bokun> {

    return this.organizationPrototypeCreateBokun$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Bokun>) => r.body as Bokun)
    );
  }

  /**
   * Creates a new instance in bokun of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateBokun$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateBokun$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Bokun>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateBokunPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Bokun>;
      })
    );
  }

  /**
   * Creates a new instance in bokun of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateBokun$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateBokun$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBokun
  },
  context?: HttpContext

): Observable<Bokun> {

    return this.organizationPrototypeCreateBokun$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Bokun>) => r.body as Bokun)
    );
  }

  /**
   * Creates a new instance in bokun of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateBokun$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateBokun$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Bokun>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateBokunPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Bokun>;
      })
    );
  }

  /**
   * Creates a new instance in bokun of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateBokun$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateBokun$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBokun
  },
  context?: HttpContext

): Observable<Bokun> {

    return this.organizationPrototypeCreateBokun$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Bokun>) => r.body as Bokun)
    );
  }

  /**
   * Creates a new instance in bokun of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateBokun$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateBokun$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBokun
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Bokun>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateBokunPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Bokun>;
      })
    );
  }

  /**
   * Creates a new instance in bokun of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateBokun$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateBokun$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewBokun
  },
  context?: HttpContext

): Observable<Bokun> {

    return this.organizationPrototypeCreateBokun$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Bokun>) => r.body as Bokun)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountBokun
   */
  static readonly OrganizationPrototypeCountBokunPath = '/v1/Organizations/{id}/bokun/count';

  /**
   * Counts bokun of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountBokun$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountBokun$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountBokunPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts bokun of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountBokun$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountBokun$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountBokun$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts bokun of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountBokun$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountBokun$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountBokunPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts bokun of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountBokun$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountBokun$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountBokun$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts bokun of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountBokun$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountBokun$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountBokunPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts bokun of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountBokun$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountBokun$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountBokun$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeGetShopamine
   */
  static readonly OrganizationPrototypeGetShopaminePath = '/v1/Organizations/{id}/shopamine';

  /**
   * Queries shopamine of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetShopamine$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetShopamine$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Shopamine>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetShopaminePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Shopamine>>;
      })
    );
  }

  /**
   * Queries shopamine of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetShopamine$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetShopamine$Json(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Shopamine>> {

    return this.organizationPrototypeGetShopamine$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Shopamine>>) => r.body as Array<Shopamine>)
    );
  }

  /**
   * Queries shopamine of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetShopamine$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetShopamine$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Shopamine>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetShopaminePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Shopamine>>;
      })
    );
  }

  /**
   * Queries shopamine of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetShopamine$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetShopamine$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Shopamine>> {

    return this.organizationPrototypeGetShopamine$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Shopamine>>) => r.body as Array<Shopamine>)
    );
  }

  /**
   * Queries shopamine of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeGetShopamine$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetShopamine$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Shopamine>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeGetShopaminePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Shopamine>>;
      })
    );
  }

  /**
   * Queries shopamine of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeGetShopamine$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeGetShopamine$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Shopamine>> {

    return this.organizationPrototypeGetShopamine$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Shopamine>>) => r.body as Array<Shopamine>)
    );
  }

  /**
   * Path part for operation organizationPrototypeCreateShopamine
   */
  static readonly OrganizationPrototypeCreateShopaminePath = '/v1/Organizations/{id}/shopamine';

  /**
   * Creates a new instance in shopamine of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateShopamine$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateShopamine$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopamine>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateShopaminePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopamine>;
      })
    );
  }

  /**
   * Creates a new instance in shopamine of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateShopamine$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateShopamine$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopamine
  },
  context?: HttpContext

): Observable<Shopamine> {

    return this.organizationPrototypeCreateShopamine$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopamine>) => r.body as Shopamine)
    );
  }

  /**
   * Creates a new instance in shopamine of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateShopamine$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateShopamine$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopamine>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateShopaminePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopamine>;
      })
    );
  }

  /**
   * Creates a new instance in shopamine of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateShopamine$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateShopamine$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopamine
  },
  context?: HttpContext

): Observable<Shopamine> {

    return this.organizationPrototypeCreateShopamine$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopamine>) => r.body as Shopamine)
    );
  }

  /**
   * Creates a new instance in shopamine of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateShopamine$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateShopamine$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopamine>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateShopaminePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopamine>;
      })
    );
  }

  /**
   * Creates a new instance in shopamine of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateShopamine$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypeCreateShopamine$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopamine
  },
  context?: HttpContext

): Observable<Shopamine> {

    return this.organizationPrototypeCreateShopamine$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopamine>) => r.body as Shopamine)
    );
  }

  /**
   * Creates a new instance in shopamine of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateShopamine$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateShopamine$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopamine>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateShopaminePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopamine>;
      })
    );
  }

  /**
   * Creates a new instance in shopamine of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateShopamine$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateShopamine$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopamine
  },
  context?: HttpContext

): Observable<Shopamine> {

    return this.organizationPrototypeCreateShopamine$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopamine>) => r.body as Shopamine)
    );
  }

  /**
   * Creates a new instance in shopamine of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateShopamine$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateShopamine$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopamine>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateShopaminePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopamine>;
      })
    );
  }

  /**
   * Creates a new instance in shopamine of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateShopamine$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateShopamine$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopamine
  },
  context?: HttpContext

): Observable<Shopamine> {

    return this.organizationPrototypeCreateShopamine$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopamine>) => r.body as Shopamine)
    );
  }

  /**
   * Creates a new instance in shopamine of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateShopamine$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateShopamine$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopamine>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateShopaminePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopamine>;
      })
    );
  }

  /**
   * Creates a new instance in shopamine of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateShopamine$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeCreateShopamine$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopamine
  },
  context?: HttpContext

): Observable<Shopamine> {

    return this.organizationPrototypeCreateShopamine$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopamine>) => r.body as Shopamine)
    );
  }

  /**
   * Creates a new instance in shopamine of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateShopamine$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateShopamine$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopamine>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateShopaminePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopamine>;
      })
    );
  }

  /**
   * Creates a new instance in shopamine of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateShopamine$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateShopamine$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopamine
  },
  context?: HttpContext

): Observable<Shopamine> {

    return this.organizationPrototypeCreateShopamine$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopamine>) => r.body as Shopamine)
    );
  }

  /**
   * Creates a new instance in shopamine of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateShopamine$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateShopamine$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopamine>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateShopaminePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopamine>;
      })
    );
  }

  /**
   * Creates a new instance in shopamine of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateShopamine$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateShopamine$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopamine
  },
  context?: HttpContext

): Observable<Shopamine> {

    return this.organizationPrototypeCreateShopamine$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopamine>) => r.body as Shopamine)
    );
  }

  /**
   * Creates a new instance in shopamine of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCreateShopamine$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateShopamine$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopamine
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Shopamine>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCreateShopaminePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shopamine>;
      })
    );
  }

  /**
   * Creates a new instance in shopamine of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCreateShopamine$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypeCreateShopamine$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: NewShopamine
  },
  context?: HttpContext

): Observable<Shopamine> {

    return this.organizationPrototypeCreateShopamine$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Shopamine>) => r.body as Shopamine)
    );
  }

  /**
   * Path part for operation organizationPrototypeCountShopamine
   */
  static readonly OrganizationPrototypeCountShopaminePath = '/v1/Organizations/{id}/shopamine/count';

  /**
   * Counts shopamine of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountShopamine$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountShopamine$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountShopaminePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts shopamine of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountShopamine$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountShopamine$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountShopamine$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts shopamine of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountShopamine$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountShopamine$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountShopaminePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts shopamine of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountShopamine$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountShopamine$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountShopamine$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Counts shopamine of Organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCountShopamine$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountShopamine$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'count'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCountShopaminePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'count'?: number;
        }>;
      })
    );
  }

  /**
   * Counts shopamine of Organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCountShopamine$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCountShopamine$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Criteria to match model instances
     */
    where?: string;
  },
  context?: HttpContext

): Observable<{
'count'?: number;
}> {

    return this.organizationPrototypeCountShopamine$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'count'?: number;
}>) => r.body as {
'count'?: number;
})
    );
  }

  /**
   * Path part for operation organizationFindById
   */
  static readonly OrganizationFindByIdPath = '/v1/Organizations/{id}';

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationFindById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationFindById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationFindById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationFindById$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Organization> {

    return this.organizationFindById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationFindById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationFindById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationFindById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationFindById$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Organization> {

    return this.organizationFindById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationFindById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationFindById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationFindById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationFindById$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Organization> {

    return this.organizationFindById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Path part for operation organizationPrototypePatchAttributes
   */
  static readonly OrganizationPrototypePatchAttributesPath = '/v1/Organizations/{id}';

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypePatchAttributes$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypePatchAttributes$Json$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Organization
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypePatchAttributes$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypePatchAttributes$Json$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Organization
  },
  context?: HttpContext

): Observable<Organization> {

    return this.organizationPrototypePatchAttributes$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypePatchAttributes$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypePatchAttributes$Json$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Organization
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypePatchAttributes$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypePatchAttributes$Json$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Organization
  },
  context?: HttpContext

): Observable<Organization> {

    return this.organizationPrototypePatchAttributes$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypePatchAttributes$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypePatchAttributes$Json$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Organization
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypePatchAttributes$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationPrototypePatchAttributes$Json$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Organization
  },
  context?: HttpContext

): Observable<Organization> {

    return this.organizationPrototypePatchAttributes$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypePatchAttributes$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Organization
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypePatchAttributes$XWwwFormUrlencoded$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Organization
  },
  context?: HttpContext

): Observable<Organization> {

    return this.organizationPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypePatchAttributes$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Organization
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypePatchAttributes$XWwwFormUrlencoded$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Organization
  },
  context?: HttpContext

): Observable<Organization> {

    return this.organizationPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypePatchAttributes$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Organization
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypePatchAttributes$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Organization
  },
  context?: HttpContext

): Observable<Organization> {

    return this.organizationPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypePatchAttributes$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypePatchAttributes$Xml$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Organization
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypePatchAttributes$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypePatchAttributes$Xml$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Organization
  },
  context?: HttpContext

): Observable<Organization> {

    return this.organizationPrototypePatchAttributes$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypePatchAttributes$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypePatchAttributes$Xml$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Organization
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypePatchAttributes$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypePatchAttributes$Xml$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Organization
  },
  context?: HttpContext

): Observable<Organization> {

    return this.organizationPrototypePatchAttributes$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypePatchAttributes$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypePatchAttributes$Xml$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Organization
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Organization>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Organization>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypePatchAttributes$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationPrototypePatchAttributes$Xml$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Organization
  },
  context?: HttpContext

): Observable<Organization> {

    return this.organizationPrototypePatchAttributes$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Organization>) => r.body as Organization)
    );
  }

  /**
   * Path part for operation organizationPrototypeReqToggleActivePostOrganizationsIdActivate
   */
  static readonly OrganizationPrototypeReqToggleActivePostOrganizationsIdActivatePath = '/v1/Organizations/{id}/activate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeReqToggleActivePostOrganizationsIdActivate$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeReqToggleActivePostOrganizationsIdActivate$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeReqToggleActivePostOrganizationsIdActivatePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeReqToggleActivePostOrganizationsIdActivate$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeReqToggleActivePostOrganizationsIdActivate$Json(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.organizationPrototypeReqToggleActivePostOrganizationsIdActivate$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeReqToggleActivePostOrganizationsIdActivate$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeReqToggleActivePostOrganizationsIdActivate$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeReqToggleActivePostOrganizationsIdActivatePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeReqToggleActivePostOrganizationsIdActivate$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeReqToggleActivePostOrganizationsIdActivate$Xml(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.organizationPrototypeReqToggleActivePostOrganizationsIdActivate$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeReqToggleActivePostOrganizationsIdActivate$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeReqToggleActivePostOrganizationsIdActivate$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeReqToggleActivePostOrganizationsIdActivatePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeReqToggleActivePostOrganizationsIdActivate$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeReqToggleActivePostOrganizationsIdActivate$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.organizationPrototypeReqToggleActivePostOrganizationsIdActivate$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeReqToggleActivePostOrganizationsIdDeactivate
   */
  static readonly OrganizationPrototypeReqToggleActivePostOrganizationsIdDeactivatePath = '/v1/Organizations/{id}/deactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeReqToggleActivePostOrganizationsIdDeactivate$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeReqToggleActivePostOrganizationsIdDeactivate$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeReqToggleActivePostOrganizationsIdDeactivatePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeReqToggleActivePostOrganizationsIdDeactivate$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeReqToggleActivePostOrganizationsIdDeactivate$Json(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.organizationPrototypeReqToggleActivePostOrganizationsIdDeactivate$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeReqToggleActivePostOrganizationsIdDeactivate$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeReqToggleActivePostOrganizationsIdDeactivate$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeReqToggleActivePostOrganizationsIdDeactivatePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeReqToggleActivePostOrganizationsIdDeactivate$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeReqToggleActivePostOrganizationsIdDeactivate$Xml(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.organizationPrototypeReqToggleActivePostOrganizationsIdDeactivate$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeReqToggleActivePostOrganizationsIdDeactivate$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeReqToggleActivePostOrganizationsIdDeactivate$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeReqToggleActivePostOrganizationsIdDeactivatePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeReqToggleActivePostOrganizationsIdDeactivate$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeReqToggleActivePostOrganizationsIdDeactivate$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.organizationPrototypeReqToggleActivePostOrganizationsIdDeactivate$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeIsUniqueDocNumber
   */
  static readonly OrganizationPrototypeIsUniqueDocNumberPath = '/v1/Organizations/{id}/is-unique-doc-number';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeIsUniqueDocNumber$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeIsUniqueDocNumber$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * The numberTax subject to check
     */
    number: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Id of document to ignore. Used when editing a document.
     */
    ignoreId?: string;

    /**
     * Check incoming documents.
     */
    incoming?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'unique'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeIsUniqueDocNumberPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('number', params.number, {});
      rb.query('type', params.type, {});
      rb.query('ignoreId', params.ignoreId, {});
      rb.query('incoming', params.incoming, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'unique'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeIsUniqueDocNumber$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeIsUniqueDocNumber$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * The numberTax subject to check
     */
    number: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Id of document to ignore. Used when editing a document.
     */
    ignoreId?: string;

    /**
     * Check incoming documents.
     */
    incoming?: boolean;
  },
  context?: HttpContext

): Observable<{
'unique'?: boolean;
}> {

    return this.organizationPrototypeIsUniqueDocNumber$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'unique'?: boolean;
}>) => r.body as {
'unique'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeIsUniqueDocNumber$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeIsUniqueDocNumber$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * The numberTax subject to check
     */
    number: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Id of document to ignore. Used when editing a document.
     */
    ignoreId?: string;

    /**
     * Check incoming documents.
     */
    incoming?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'unique'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeIsUniqueDocNumberPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('number', params.number, {});
      rb.query('type', params.type, {});
      rb.query('ignoreId', params.ignoreId, {});
      rb.query('incoming', params.incoming, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'unique'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeIsUniqueDocNumber$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeIsUniqueDocNumber$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * The numberTax subject to check
     */
    number: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Id of document to ignore. Used when editing a document.
     */
    ignoreId?: string;

    /**
     * Check incoming documents.
     */
    incoming?: boolean;
  },
  context?: HttpContext

): Observable<{
'unique'?: boolean;
}> {

    return this.organizationPrototypeIsUniqueDocNumber$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'unique'?: boolean;
}>) => r.body as {
'unique'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeIsUniqueDocNumber$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeIsUniqueDocNumber$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * The numberTax subject to check
     */
    number: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Id of document to ignore. Used when editing a document.
     */
    ignoreId?: string;

    /**
     * Check incoming documents.
     */
    incoming?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'unique'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeIsUniqueDocNumberPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('number', params.number, {});
      rb.query('type', params.type, {});
      rb.query('ignoreId', params.ignoreId, {});
      rb.query('incoming', params.incoming, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'unique'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeIsUniqueDocNumber$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeIsUniqueDocNumber$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * The numberTax subject to check
     */
    number: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Id of document to ignore. Used when editing a document.
     */
    ignoreId?: string;

    /**
     * Check incoming documents.
     */
    incoming?: boolean;
  },
  context?: HttpContext

): Observable<{
'unique'?: boolean;
}> {

    return this.organizationPrototypeIsUniqueDocNumber$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'unique'?: boolean;
}>) => r.body as {
'unique'?: boolean;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeLastDocNumber
   */
  static readonly OrganizationPrototypeLastDocNumberPath = '/v1/Organizations/{id}/last-doc-number';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeLastDocNumber$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeLastDocNumber$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'number'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeLastDocNumberPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('incoming', params.incoming, {});
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'number'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeLastDocNumber$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeLastDocNumber$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<{
'number'?: string;
}> {

    return this.organizationPrototypeLastDocNumber$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'number'?: string;
}>) => r.body as {
'number'?: string;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeLastDocNumber$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeLastDocNumber$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'number'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeLastDocNumberPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('incoming', params.incoming, {});
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'number'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeLastDocNumber$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeLastDocNumber$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<{
'number'?: string;
}> {

    return this.organizationPrototypeLastDocNumber$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'number'?: string;
}>) => r.body as {
'number'?: string;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeLastDocNumber$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeLastDocNumber$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'number'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeLastDocNumberPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('incoming', params.incoming, {});
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'number'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeLastDocNumber$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeLastDocNumber$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<{
'number'?: string;
}> {

    return this.organizationPrototypeLastDocNumber$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'number'?: string;
}>) => r.body as {
'number'?: string;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeNextDocNumber
   */
  static readonly OrganizationPrototypeNextDocNumberPath = '/v1/Organizations/{id}/next-doc-number';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeNextDocNumber$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeNextDocNumber$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Fiscal verification strategy
     */
    fvStrategy?: string;

    /**
     * Business premise ID
     */
    BPid?: string;

    /**
     * Electronic device ID
     */
    EDid?: string;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'number'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeNextDocNumberPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('incoming', params.incoming, {});
      rb.query('fvStrategy', params.fvStrategy, {});
      rb.query('BPid', params.BPid, {});
      rb.query('EDid', params.EDid, {});
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'number'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeNextDocNumber$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeNextDocNumber$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Fiscal verification strategy
     */
    fvStrategy?: string;

    /**
     * Business premise ID
     */
    BPid?: string;

    /**
     * Electronic device ID
     */
    EDid?: string;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<{
'number'?: string;
}> {

    return this.organizationPrototypeNextDocNumber$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'number'?: string;
}>) => r.body as {
'number'?: string;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeNextDocNumber$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeNextDocNumber$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Fiscal verification strategy
     */
    fvStrategy?: string;

    /**
     * Business premise ID
     */
    BPid?: string;

    /**
     * Electronic device ID
     */
    EDid?: string;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'number'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeNextDocNumberPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('incoming', params.incoming, {});
      rb.query('fvStrategy', params.fvStrategy, {});
      rb.query('BPid', params.BPid, {});
      rb.query('EDid', params.EDid, {});
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'number'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeNextDocNumber$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeNextDocNumber$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Fiscal verification strategy
     */
    fvStrategy?: string;

    /**
     * Business premise ID
     */
    BPid?: string;

    /**
     * Electronic device ID
     */
    EDid?: string;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<{
'number'?: string;
}> {

    return this.organizationPrototypeNextDocNumber$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'number'?: string;
}>) => r.body as {
'number'?: string;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeNextDocNumber$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeNextDocNumber$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Fiscal verification strategy
     */
    fvStrategy?: string;

    /**
     * Business premise ID
     */
    BPid?: string;

    /**
     * Electronic device ID
     */
    EDid?: string;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'number'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeNextDocNumberPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('incoming', params.incoming, {});
      rb.query('fvStrategy', params.fvStrategy, {});
      rb.query('BPid', params.BPid, {});
      rb.query('EDid', params.EDid, {});
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'number'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeNextDocNumber$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeNextDocNumber$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * The type of document
     */
    type: string;

    /**
     * Incoming document
     */
    incoming?: boolean;

    /**
     * Fiscal verification strategy
     */
    fvStrategy?: string;

    /**
     * Business premise ID
     */
    BPid?: string;

    /**
     * Electronic device ID
     */
    EDid?: string;

    /**
     * Date if not in current year
     */
    date?: string;
  },
  context?: HttpContext

): Observable<{
'number'?: string;
}> {

    return this.organizationPrototypeNextDocNumber$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'number'?: string;
}>) => r.body as {
'number'?: string;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeSubscription
   */
  static readonly OrganizationPrototypeSubscriptionPath = '/v1/Organizations/{id}/subscription';

  /**
   * Get currenct subscription.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeSubscription$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSubscription$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeSubscriptionPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Get currenct subscription.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeSubscription$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSubscription$Json(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.organizationPrototypeSubscription$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Get currenct subscription.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeSubscription$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSubscription$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeSubscriptionPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Get currenct subscription.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeSubscription$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSubscription$Xml(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.organizationPrototypeSubscription$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Get currenct subscription.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeSubscription$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSubscription$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeSubscriptionPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Get currenct subscription.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeSubscription$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSubscription$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.organizationPrototypeSubscription$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation organizationPrototypeSearchDocuments
   */
  static readonly OrganizationPrototypeSearchDocumentsPath = '/v1/Organizations/{id}/search-documents';

  /**
   * DEPRECATED: Search documents endpoint.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeSearchDocuments$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchDocuments$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Type of document
     */
    type?: string;
    term: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<XAny>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeSearchDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('term', params.term, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<XAny>>;
      })
    );
  }

  /**
   * DEPRECATED: Search documents endpoint.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeSearchDocuments$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchDocuments$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Type of document
     */
    type?: string;
    term: string;
  },
  context?: HttpContext

): Observable<Array<XAny>> {

    return this.organizationPrototypeSearchDocuments$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<XAny>>) => r.body as Array<XAny>)
    );
  }

  /**
   * DEPRECATED: Search documents endpoint.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeSearchDocuments$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchDocuments$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Type of document
     */
    type?: string;
    term: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<XAny>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeSearchDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('term', params.term, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<XAny>>;
      })
    );
  }

  /**
   * DEPRECATED: Search documents endpoint.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeSearchDocuments$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchDocuments$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Type of document
     */
    type?: string;
    term: string;
  },
  context?: HttpContext

): Observable<Array<XAny>> {

    return this.organizationPrototypeSearchDocuments$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<XAny>>) => r.body as Array<XAny>)
    );
  }

  /**
   * DEPRECATED: Search documents endpoint.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeSearchDocuments$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchDocuments$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Type of document
     */
    type?: string;
    term: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<XAny>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeSearchDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('term', params.term, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<XAny>>;
      })
    );
  }

  /**
   * DEPRECATED: Search documents endpoint.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeSearchDocuments$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchDocuments$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Type of document
     */
    type?: string;
    term: string;
  },
  context?: HttpContext

): Observable<Array<XAny>> {

    return this.organizationPrototypeSearchDocuments$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<XAny>>) => r.body as Array<XAny>)
    );
  }

  /**
   * Path part for operation organizationPrototypeUploadImagePostOrganizationsIdUploadImage
   */
  static readonly OrganizationPrototypeUploadImagePostOrganizationsIdUploadImagePath = '/v1/Organizations/{id}/upload-image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeUploadImagePostOrganizationsIdUploadImage$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUploadImagePostOrganizationsIdUploadImage$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeUploadImagePostOrganizationsIdUploadImagePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeUploadImagePostOrganizationsIdUploadImage$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUploadImagePostOrganizationsIdUploadImage$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<boolean> {

    return this.organizationPrototypeUploadImagePostOrganizationsIdUploadImage$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeUploadImagePostOrganizationsIdUploadImage$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUploadImagePostOrganizationsIdUploadImage$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeUploadImagePostOrganizationsIdUploadImagePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeUploadImagePostOrganizationsIdUploadImage$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUploadImagePostOrganizationsIdUploadImage$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<boolean> {

    return this.organizationPrototypeUploadImagePostOrganizationsIdUploadImage$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeUploadImagePostOrganizationsIdUploadImage$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUploadImagePostOrganizationsIdUploadImage$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeUploadImagePostOrganizationsIdUploadImagePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeUploadImagePostOrganizationsIdUploadImage$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUploadImagePostOrganizationsIdUploadImage$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<boolean> {

    return this.organizationPrototypeUploadImagePostOrganizationsIdUploadImage$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation organizationPrototypeDownloadImageGetOrganizationsIdImages
   */
  static readonly OrganizationPrototypeDownloadImageGetOrganizationsIdImagesPath = '/v1/Organizations/{id}/images';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeDownloadImageGetOrganizationsIdImages$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDownloadImageGetOrganizationsIdImages$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeDownloadImageGetOrganizationsIdImagesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeDownloadImageGetOrganizationsIdImages$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDownloadImageGetOrganizationsIdImages$Json(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<string> {

    return this.organizationPrototypeDownloadImageGetOrganizationsIdImages$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeDownloadImageGetOrganizationsIdImages$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDownloadImageGetOrganizationsIdImages$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeDownloadImageGetOrganizationsIdImagesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeDownloadImageGetOrganizationsIdImages$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDownloadImageGetOrganizationsIdImages$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<string> {

    return this.organizationPrototypeDownloadImageGetOrganizationsIdImages$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeDownloadImageGetOrganizationsIdImages$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDownloadImageGetOrganizationsIdImages$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeDownloadImageGetOrganizationsIdImagesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeDownloadImageGetOrganizationsIdImages$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDownloadImageGetOrganizationsIdImages$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<string> {

    return this.organizationPrototypeDownloadImageGetOrganizationsIdImages$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation organizationPrototypeUploadImagePostOrganizationsIdImages
   */
  static readonly OrganizationPrototypeUploadImagePostOrganizationsIdImagesPath = '/v1/Organizations/{id}/images';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeUploadImagePostOrganizationsIdImages$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUploadImagePostOrganizationsIdImages$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeUploadImagePostOrganizationsIdImagesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeUploadImagePostOrganizationsIdImages$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUploadImagePostOrganizationsIdImages$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<boolean> {

    return this.organizationPrototypeUploadImagePostOrganizationsIdImages$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeUploadImagePostOrganizationsIdImages$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUploadImagePostOrganizationsIdImages$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeUploadImagePostOrganizationsIdImagesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeUploadImagePostOrganizationsIdImages$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUploadImagePostOrganizationsIdImages$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<boolean> {

    return this.organizationPrototypeUploadImagePostOrganizationsIdImages$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeUploadImagePostOrganizationsIdImages$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUploadImagePostOrganizationsIdImages$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeUploadImagePostOrganizationsIdImagesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeUploadImagePostOrganizationsIdImages$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUploadImagePostOrganizationsIdImages$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<boolean> {

    return this.organizationPrototypeUploadImagePostOrganizationsIdImages$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation organizationPrototypeDeleteImageDeleteOrganizationsIdImages
   */
  static readonly OrganizationPrototypeDeleteImageDeleteOrganizationsIdImagesPath = '/v1/Organizations/{id}/images';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeDeleteImageDeleteOrganizationsIdImages$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDeleteImageDeleteOrganizationsIdImages$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeDeleteImageDeleteOrganizationsIdImagesPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeDeleteImageDeleteOrganizationsIdImages$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDeleteImageDeleteOrganizationsIdImages$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.organizationPrototypeDeleteImageDeleteOrganizationsIdImages$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeDeleteImageDeleteOrganizationsIdImages$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDeleteImageDeleteOrganizationsIdImages$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeDeleteImageDeleteOrganizationsIdImagesPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeDeleteImageDeleteOrganizationsIdImages$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDeleteImageDeleteOrganizationsIdImages$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.organizationPrototypeDeleteImageDeleteOrganizationsIdImages$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeDeleteImageDeleteOrganizationsIdImages$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDeleteImageDeleteOrganizationsIdImages$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeDeleteImageDeleteOrganizationsIdImagesPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeDeleteImageDeleteOrganizationsIdImages$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDeleteImageDeleteOrganizationsIdImages$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.organizationPrototypeDeleteImageDeleteOrganizationsIdImages$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation organizationPrototypeDeleteImageDeleteOrganizationsIdDeleteImage
   */
  static readonly OrganizationPrototypeDeleteImageDeleteOrganizationsIdDeleteImagePath = '/v1/Organizations/{id}/delete-image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeDeleteImageDeleteOrganizationsIdDeleteImage$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDeleteImageDeleteOrganizationsIdDeleteImage$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeDeleteImageDeleteOrganizationsIdDeleteImagePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeDeleteImageDeleteOrganizationsIdDeleteImage$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDeleteImageDeleteOrganizationsIdDeleteImage$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.organizationPrototypeDeleteImageDeleteOrganizationsIdDeleteImage$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeDeleteImageDeleteOrganizationsIdDeleteImage$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDeleteImageDeleteOrganizationsIdDeleteImage$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeDeleteImageDeleteOrganizationsIdDeleteImagePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeDeleteImageDeleteOrganizationsIdDeleteImage$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDeleteImageDeleteOrganizationsIdDeleteImage$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.organizationPrototypeDeleteImageDeleteOrganizationsIdDeleteImage$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeDeleteImageDeleteOrganizationsIdDeleteImage$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDeleteImageDeleteOrganizationsIdDeleteImage$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeDeleteImageDeleteOrganizationsIdDeleteImagePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeDeleteImageDeleteOrganizationsIdDeleteImage$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDeleteImageDeleteOrganizationsIdDeleteImage$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Type of image to delete
     */
    type: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.organizationPrototypeDeleteImageDeleteOrganizationsIdDeleteImage$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation organizationPrototypeDownloadImageGetOrganizationsIdDownloadImage
   */
  static readonly OrganizationPrototypeDownloadImageGetOrganizationsIdDownloadImagePath = '/v1/Organizations/{id}/download-image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeDownloadImageGetOrganizationsIdDownloadImage$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDownloadImageGetOrganizationsIdDownloadImage$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeDownloadImageGetOrganizationsIdDownloadImagePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeDownloadImageGetOrganizationsIdDownloadImage$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDownloadImageGetOrganizationsIdDownloadImage$Json(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<string> {

    return this.organizationPrototypeDownloadImageGetOrganizationsIdDownloadImage$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeDownloadImageGetOrganizationsIdDownloadImage$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDownloadImageGetOrganizationsIdDownloadImage$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeDownloadImageGetOrganizationsIdDownloadImagePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeDownloadImageGetOrganizationsIdDownloadImage$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDownloadImageGetOrganizationsIdDownloadImage$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<string> {

    return this.organizationPrototypeDownloadImageGetOrganizationsIdDownloadImage$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeDownloadImageGetOrganizationsIdDownloadImage$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDownloadImageGetOrganizationsIdDownloadImage$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeDownloadImageGetOrganizationsIdDownloadImagePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeDownloadImageGetOrganizationsIdDownloadImage$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDownloadImageGetOrganizationsIdDownloadImage$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;
  },
  context?: HttpContext

): Observable<string> {

    return this.organizationPrototypeDownloadImageGetOrganizationsIdDownloadImage$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation organizationPrototypeDownloadLogo
   */
  static readonly OrganizationPrototypeDownloadLogoPath = '/v1/Organizations/{id}/images/logo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeDownloadLogo$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDownloadLogo$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeDownloadLogoPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeDownloadLogo$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDownloadLogo$Json(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<string> {

    return this.organizationPrototypeDownloadLogo$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeDownloadLogo$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDownloadLogo$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeDownloadLogoPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeDownloadLogo$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDownloadLogo$Xml(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<string> {

    return this.organizationPrototypeDownloadLogo$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeDownloadLogo$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDownloadLogo$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeDownloadLogoPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeDownloadLogo$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDownloadLogo$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<string> {

    return this.organizationPrototypeDownloadLogo$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation organizationPrototypeSearchClientsGetOrganizationsIdSearchClients
   */
  static readonly OrganizationPrototypeSearchClientsGetOrganizationsIdSearchClientsPath = '/v1/Organizations/{id}/search-clients';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeSearchClientsGetOrganizationsIdSearchClients$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchClientsGetOrganizationsIdSearchClients$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to customer or supplier
     */
    type?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<XAny>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeSearchClientsGetOrganizationsIdSearchClientsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('term', params.term, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<XAny>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeSearchClientsGetOrganizationsIdSearchClients$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchClientsGetOrganizationsIdSearchClients$Json(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to customer or supplier
     */
    type?: string;
  },
  context?: HttpContext

): Observable<Array<XAny>> {

    return this.organizationPrototypeSearchClientsGetOrganizationsIdSearchClients$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<XAny>>) => r.body as Array<XAny>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeSearchClientsGetOrganizationsIdSearchClients$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchClientsGetOrganizationsIdSearchClients$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to customer or supplier
     */
    type?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<XAny>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeSearchClientsGetOrganizationsIdSearchClientsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('term', params.term, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<XAny>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeSearchClientsGetOrganizationsIdSearchClients$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchClientsGetOrganizationsIdSearchClients$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to customer or supplier
     */
    type?: string;
  },
  context?: HttpContext

): Observable<Array<XAny>> {

    return this.organizationPrototypeSearchClientsGetOrganizationsIdSearchClients$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<XAny>>) => r.body as Array<XAny>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeSearchClientsGetOrganizationsIdSearchClients$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchClientsGetOrganizationsIdSearchClients$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to customer or supplier
     */
    type?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<XAny>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeSearchClientsGetOrganizationsIdSearchClientsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('term', params.term, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<XAny>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeSearchClientsGetOrganizationsIdSearchClients$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchClientsGetOrganizationsIdSearchClients$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to customer or supplier
     */
    type?: string;
  },
  context?: HttpContext

): Observable<Array<XAny>> {

    return this.organizationPrototypeSearchClientsGetOrganizationsIdSearchClients$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<XAny>>) => r.body as Array<XAny>)
    );
  }

  /**
   * Path part for operation organizationPrototypeSearchClientsGetOrganizationsIdClientsSearch
   */
  static readonly OrganizationPrototypeSearchClientsGetOrganizationsIdClientsSearchPath = '/v1/Organizations/{id}/clients/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeSearchClientsGetOrganizationsIdClientsSearch$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchClientsGetOrganizationsIdClientsSearch$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to customer or supplier
     */
    type?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<XAny>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeSearchClientsGetOrganizationsIdClientsSearchPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('term', params.term, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<XAny>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeSearchClientsGetOrganizationsIdClientsSearch$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchClientsGetOrganizationsIdClientsSearch$Json(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to customer or supplier
     */
    type?: string;
  },
  context?: HttpContext

): Observable<Array<XAny>> {

    return this.organizationPrototypeSearchClientsGetOrganizationsIdClientsSearch$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<XAny>>) => r.body as Array<XAny>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeSearchClientsGetOrganizationsIdClientsSearch$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchClientsGetOrganizationsIdClientsSearch$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to customer or supplier
     */
    type?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<XAny>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeSearchClientsGetOrganizationsIdClientsSearchPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('term', params.term, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<XAny>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeSearchClientsGetOrganizationsIdClientsSearch$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchClientsGetOrganizationsIdClientsSearch$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to customer or supplier
     */
    type?: string;
  },
  context?: HttpContext

): Observable<Array<XAny>> {

    return this.organizationPrototypeSearchClientsGetOrganizationsIdClientsSearch$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<XAny>>) => r.body as Array<XAny>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeSearchClientsGetOrganizationsIdClientsSearch$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchClientsGetOrganizationsIdClientsSearch$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to customer or supplier
     */
    type?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<XAny>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeSearchClientsGetOrganizationsIdClientsSearchPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('term', params.term, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<XAny>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeSearchClientsGetOrganizationsIdClientsSearch$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchClientsGetOrganizationsIdClientsSearch$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to customer or supplier
     */
    type?: string;
  },
  context?: HttpContext

): Observable<Array<XAny>> {

    return this.organizationPrototypeSearchClientsGetOrganizationsIdClientsSearch$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<XAny>>) => r.body as Array<XAny>)
    );
  }

  /**
   * Path part for operation organizationPrototypeSearchItemsGetOrganizationsIdSearchItems
   */
  static readonly OrganizationPrototypeSearchItemsGetOrganizationsIdSearchItemsPath = '/v1/Organizations/{id}/search-items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeSearchItemsGetOrganizationsIdSearchItems$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchItemsGetOrganizationsIdSearchItems$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to incoming or outgoing
     */
    type?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<XAny>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeSearchItemsGetOrganizationsIdSearchItemsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('term', params.term, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<XAny>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeSearchItemsGetOrganizationsIdSearchItems$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchItemsGetOrganizationsIdSearchItems$Json(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to incoming or outgoing
     */
    type?: string;
  },
  context?: HttpContext

): Observable<Array<XAny>> {

    return this.organizationPrototypeSearchItemsGetOrganizationsIdSearchItems$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<XAny>>) => r.body as Array<XAny>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeSearchItemsGetOrganizationsIdSearchItems$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchItemsGetOrganizationsIdSearchItems$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to incoming or outgoing
     */
    type?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<XAny>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeSearchItemsGetOrganizationsIdSearchItemsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('term', params.term, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<XAny>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeSearchItemsGetOrganizationsIdSearchItems$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchItemsGetOrganizationsIdSearchItems$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to incoming or outgoing
     */
    type?: string;
  },
  context?: HttpContext

): Observable<Array<XAny>> {

    return this.organizationPrototypeSearchItemsGetOrganizationsIdSearchItems$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<XAny>>) => r.body as Array<XAny>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeSearchItemsGetOrganizationsIdSearchItems$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchItemsGetOrganizationsIdSearchItems$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to incoming or outgoing
     */
    type?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<XAny>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeSearchItemsGetOrganizationsIdSearchItemsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('term', params.term, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<XAny>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeSearchItemsGetOrganizationsIdSearchItems$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchItemsGetOrganizationsIdSearchItems$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to incoming or outgoing
     */
    type?: string;
  },
  context?: HttpContext

): Observable<Array<XAny>> {

    return this.organizationPrototypeSearchItemsGetOrganizationsIdSearchItems$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<XAny>>) => r.body as Array<XAny>)
    );
  }

  /**
   * Path part for operation organizationPrototypeSearchItemsGetOrganizationsIdItemsSearch
   */
  static readonly OrganizationPrototypeSearchItemsGetOrganizationsIdItemsSearchPath = '/v1/Organizations/{id}/items/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeSearchItemsGetOrganizationsIdItemsSearch$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchItemsGetOrganizationsIdItemsSearch$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to incoming or outgoing
     */
    type?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<XAny>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeSearchItemsGetOrganizationsIdItemsSearchPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('term', params.term, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<XAny>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeSearchItemsGetOrganizationsIdItemsSearch$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchItemsGetOrganizationsIdItemsSearch$Json(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to incoming or outgoing
     */
    type?: string;
  },
  context?: HttpContext

): Observable<Array<XAny>> {

    return this.organizationPrototypeSearchItemsGetOrganizationsIdItemsSearch$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<XAny>>) => r.body as Array<XAny>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeSearchItemsGetOrganizationsIdItemsSearch$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchItemsGetOrganizationsIdItemsSearch$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to incoming or outgoing
     */
    type?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<XAny>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeSearchItemsGetOrganizationsIdItemsSearchPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('term', params.term, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<XAny>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeSearchItemsGetOrganizationsIdItemsSearch$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchItemsGetOrganizationsIdItemsSearch$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to incoming or outgoing
     */
    type?: string;
  },
  context?: HttpContext

): Observable<Array<XAny>> {

    return this.organizationPrototypeSearchItemsGetOrganizationsIdItemsSearch$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<XAny>>) => r.body as Array<XAny>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeSearchItemsGetOrganizationsIdItemsSearch$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchItemsGetOrganizationsIdItemsSearch$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to incoming or outgoing
     */
    type?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<XAny>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeSearchItemsGetOrganizationsIdItemsSearchPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('term', params.term, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<XAny>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeSearchItemsGetOrganizationsIdItemsSearch$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeSearchItemsGetOrganizationsIdItemsSearch$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    term: string;

    /**
     * Optionally limit search to incoming or outgoing
     */
    type?: string;
  },
  context?: HttpContext

): Observable<Array<XAny>> {

    return this.organizationPrototypeSearchItemsGetOrganizationsIdItemsSearch$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<XAny>>) => r.body as Array<XAny>)
    );
  }

  /**
   * Path part for operation organizationPrototypeBusinessStats
   */
  static readonly OrganizationPrototypeBusinessStatsPath = '/v1/Organizations/{id}/business-stats';

  /**
   * Provides business statistics about the given organization for a particular date range.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeBusinessStats$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeBusinessStats$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Optional from date to generate report for
     */
    from?: string;

    /**
     * Optional to date to generate report for
     */
    to?: string;

    /**
     * Optional id of account to limit report for
     */
    accountId?: string;

    /**
     * Optional id of unit to limit report for
     */
    unitId?: string;

    /**
     * Optional if a text report should be created
     */
    text?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeBusinessStatsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
      rb.query('accountId', params.accountId, {});
      rb.query('unitId', params.unitId, {});
      rb.query('text', params.text, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Provides business statistics about the given organization for a particular date range.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeBusinessStats$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeBusinessStats$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Optional from date to generate report for
     */
    from?: string;

    /**
     * Optional to date to generate report for
     */
    to?: string;

    /**
     * Optional id of account to limit report for
     */
    accountId?: string;

    /**
     * Optional id of unit to limit report for
     */
    unitId?: string;

    /**
     * Optional if a text report should be created
     */
    text?: boolean;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.organizationPrototypeBusinessStats$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Provides business statistics about the given organization for a particular date range.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeBusinessStats$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeBusinessStats$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Optional from date to generate report for
     */
    from?: string;

    /**
     * Optional to date to generate report for
     */
    to?: string;

    /**
     * Optional id of account to limit report for
     */
    accountId?: string;

    /**
     * Optional id of unit to limit report for
     */
    unitId?: string;

    /**
     * Optional if a text report should be created
     */
    text?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeBusinessStatsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
      rb.query('accountId', params.accountId, {});
      rb.query('unitId', params.unitId, {});
      rb.query('text', params.text, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Provides business statistics about the given organization for a particular date range.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeBusinessStats$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeBusinessStats$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Optional from date to generate report for
     */
    from?: string;

    /**
     * Optional to date to generate report for
     */
    to?: string;

    /**
     * Optional id of account to limit report for
     */
    accountId?: string;

    /**
     * Optional id of unit to limit report for
     */
    unitId?: string;

    /**
     * Optional if a text report should be created
     */
    text?: boolean;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.organizationPrototypeBusinessStats$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Provides business statistics about the given organization for a particular date range.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeBusinessStats$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeBusinessStats$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Optional from date to generate report for
     */
    from?: string;

    /**
     * Optional to date to generate report for
     */
    to?: string;

    /**
     * Optional id of account to limit report for
     */
    accountId?: string;

    /**
     * Optional id of unit to limit report for
     */
    unitId?: string;

    /**
     * Optional if a text report should be created
     */
    text?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeBusinessStatsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
      rb.query('accountId', params.accountId, {});
      rb.query('unitId', params.unitId, {});
      rb.query('text', params.text, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Provides business statistics about the given organization for a particular date range.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeBusinessStats$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeBusinessStats$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Optional from date to generate report for
     */
    from?: string;

    /**
     * Optional to date to generate report for
     */
    to?: string;

    /**
     * Optional id of account to limit report for
     */
    accountId?: string;

    /**
     * Optional id of unit to limit report for
     */
    unitId?: string;

    /**
     * Optional if a text report should be created
     */
    text?: boolean;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.organizationPrototypeBusinessStats$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation organizationPrototypeTotalStats
   */
  static readonly OrganizationPrototypeTotalStatsPath = '/v1/Organizations/{id}/total-stats';

  /**
   * Provides financial statistics about the given organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeTotalStats$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeTotalStats$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Optional value to use as today's date
     */
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeTotalStatsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Provides financial statistics about the given organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeTotalStats$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeTotalStats$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Optional value to use as today's date
     */
    date?: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.organizationPrototypeTotalStats$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Provides financial statistics about the given organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeTotalStats$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeTotalStats$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Optional value to use as today's date
     */
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeTotalStatsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Provides financial statistics about the given organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeTotalStats$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeTotalStats$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Optional value to use as today's date
     */
    date?: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.organizationPrototypeTotalStats$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Provides financial statistics about the given organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeTotalStats$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeTotalStats$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Optional value to use as today's date
     */
    date?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeTotalStatsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Provides financial statistics about the given organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeTotalStats$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeTotalStats$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Optional value to use as today's date
     */
    date?: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.organizationPrototypeTotalStats$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation organizationPrototypeDocumentTotalStats
   */
  static readonly OrganizationPrototypeDocumentTotalStatsPath = '/v1/Organizations/{id}/document-total-stats';

  /**
   * Get organization's document stats.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeDocumentTotalStats$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDocumentTotalStats$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Document type
     */
    type: string;
    from: string;
    to: string;

    /**
     * Dist of data ie. day, week, month, year
     */
    dist: string;

    /**
     * If set to true, it will calculate the prices with gross values otherwise with net values
     */
    gross?: boolean;

    /**
     * If incoming document statistics should be returned.
     */
    incoming?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeDocumentTotalStatsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
      rb.query('dist', params.dist, {});
      rb.query('gross', params.gross, {});
      rb.query('incoming', params.incoming, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'stats'?: Array<{
        }>;
        'total'?: Array<{
        }>;
        }>;
      })
    );
  }

  /**
   * Get organization's document stats.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeDocumentTotalStats$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDocumentTotalStats$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Document type
     */
    type: string;
    from: string;
    to: string;

    /**
     * Dist of data ie. day, week, month, year
     */
    dist: string;

    /**
     * If set to true, it will calculate the prices with gross values otherwise with net values
     */
    gross?: boolean;

    /**
     * If incoming document statistics should be returned.
     */
    incoming?: boolean;
  },
  context?: HttpContext

): Observable<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
}> {

    return this.organizationPrototypeDocumentTotalStats$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
}>) => r.body as {
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
})
    );
  }

  /**
   * Get organization's document stats.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeDocumentTotalStats$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDocumentTotalStats$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Document type
     */
    type: string;
    from: string;
    to: string;

    /**
     * Dist of data ie. day, week, month, year
     */
    dist: string;

    /**
     * If set to true, it will calculate the prices with gross values otherwise with net values
     */
    gross?: boolean;

    /**
     * If incoming document statistics should be returned.
     */
    incoming?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeDocumentTotalStatsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
      rb.query('dist', params.dist, {});
      rb.query('gross', params.gross, {});
      rb.query('incoming', params.incoming, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'stats'?: Array<{
        }>;
        'total'?: Array<{
        }>;
        }>;
      })
    );
  }

  /**
   * Get organization's document stats.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeDocumentTotalStats$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDocumentTotalStats$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Document type
     */
    type: string;
    from: string;
    to: string;

    /**
     * Dist of data ie. day, week, month, year
     */
    dist: string;

    /**
     * If set to true, it will calculate the prices with gross values otherwise with net values
     */
    gross?: boolean;

    /**
     * If incoming document statistics should be returned.
     */
    incoming?: boolean;
  },
  context?: HttpContext

): Observable<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
}> {

    return this.organizationPrototypeDocumentTotalStats$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
}>) => r.body as {
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
})
    );
  }

  /**
   * Get organization's document stats.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeDocumentTotalStats$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDocumentTotalStats$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Document type
     */
    type: string;
    from: string;
    to: string;

    /**
     * Dist of data ie. day, week, month, year
     */
    dist: string;

    /**
     * If set to true, it will calculate the prices with gross values otherwise with net values
     */
    gross?: boolean;

    /**
     * If incoming document statistics should be returned.
     */
    incoming?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeDocumentTotalStatsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
      rb.query('dist', params.dist, {});
      rb.query('gross', params.gross, {});
      rb.query('incoming', params.incoming, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'stats'?: Array<{
        }>;
        'total'?: Array<{
        }>;
        }>;
      })
    );
  }

  /**
   * Get organization's document stats.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeDocumentTotalStats$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeDocumentTotalStats$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Document type
     */
    type: string;
    from: string;
    to: string;

    /**
     * Dist of data ie. day, week, month, year
     */
    dist: string;

    /**
     * If set to true, it will calculate the prices with gross values otherwise with net values
     */
    gross?: boolean;

    /**
     * If incoming document statistics should be returned.
     */
    incoming?: boolean;
  },
  context?: HttpContext

): Observable<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
}> {

    return this.organizationPrototypeDocumentTotalStats$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
}>) => r.body as {
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeCategoryTotalStats
   */
  static readonly OrganizationPrototypeCategoryTotalStatsPath = '/v1/Organizations/{id}/category-total-stats';

  /**
   * Get organization's categories stats.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCategoryTotalStats$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCategoryTotalStats$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    from: string;
    to: string;

    /**
     * Dist of data ie. day, week, month, year
     */
    dist: string;

    /**
     * Optional id of category to limit results to
     */
    categoryId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCategoryTotalStatsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
      rb.query('dist', params.dist, {});
      rb.query('categoryId', params.categoryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'stats'?: Array<{
        }>;
        'total'?: Array<{
        }>;
        }>;
      })
    );
  }

  /**
   * Get organization's categories stats.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCategoryTotalStats$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCategoryTotalStats$Json(params: {

    /**
     * Organization id
     */
    id: string;
    from: string;
    to: string;

    /**
     * Dist of data ie. day, week, month, year
     */
    dist: string;

    /**
     * Optional id of category to limit results to
     */
    categoryId?: string;
  },
  context?: HttpContext

): Observable<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
}> {

    return this.organizationPrototypeCategoryTotalStats$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
}>) => r.body as {
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
})
    );
  }

  /**
   * Get organization's categories stats.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCategoryTotalStats$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCategoryTotalStats$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    from: string;
    to: string;

    /**
     * Dist of data ie. day, week, month, year
     */
    dist: string;

    /**
     * Optional id of category to limit results to
     */
    categoryId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCategoryTotalStatsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
      rb.query('dist', params.dist, {});
      rb.query('categoryId', params.categoryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'stats'?: Array<{
        }>;
        'total'?: Array<{
        }>;
        }>;
      })
    );
  }

  /**
   * Get organization's categories stats.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCategoryTotalStats$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCategoryTotalStats$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    from: string;
    to: string;

    /**
     * Dist of data ie. day, week, month, year
     */
    dist: string;

    /**
     * Optional id of category to limit results to
     */
    categoryId?: string;
  },
  context?: HttpContext

): Observable<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
}> {

    return this.organizationPrototypeCategoryTotalStats$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
}>) => r.body as {
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
})
    );
  }

  /**
   * Get organization's categories stats.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeCategoryTotalStats$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCategoryTotalStats$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    from: string;
    to: string;

    /**
     * Dist of data ie. day, week, month, year
     */
    dist: string;

    /**
     * Optional id of category to limit results to
     */
    categoryId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeCategoryTotalStatsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
      rb.query('dist', params.dist, {});
      rb.query('categoryId', params.categoryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'stats'?: Array<{
        }>;
        'total'?: Array<{
        }>;
        }>;
      })
    );
  }

  /**
   * Get organization's categories stats.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeCategoryTotalStats$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeCategoryTotalStats$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    from: string;
    to: string;

    /**
     * Dist of data ie. day, week, month, year
     */
    dist: string;

    /**
     * Optional id of category to limit results to
     */
    categoryId?: string;
  },
  context?: HttpContext

): Observable<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
}> {

    return this.organizationPrototypeCategoryTotalStats$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
}>) => r.body as {
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
})
    );
  }

  /**
   * Path part for operation organizationPrototypePaymentTotalStats
   */
  static readonly OrganizationPrototypePaymentTotalStatsPath = '/v1/Organizations/{id}/payment-total-stats';

  /**
   * Get organization's payments stats.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypePaymentTotalStats$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypePaymentTotalStats$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    from: string;
    to: string;

    /**
     * Dist of data ie. day, week, month, year
     */
    dist: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
'customColors'?: Array<{
}>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypePaymentTotalStatsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
      rb.query('dist', params.dist, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'stats'?: Array<{
        }>;
        'total'?: Array<{
        }>;
        'customColors'?: Array<{
        }>;
        }>;
      })
    );
  }

  /**
   * Get organization's payments stats.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypePaymentTotalStats$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypePaymentTotalStats$Json(params: {

    /**
     * Organization id
     */
    id: string;
    from: string;
    to: string;

    /**
     * Dist of data ie. day, week, month, year
     */
    dist: string;
  },
  context?: HttpContext

): Observable<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
'customColors'?: Array<{
}>;
}> {

    return this.organizationPrototypePaymentTotalStats$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
'customColors'?: Array<{
}>;
}>) => r.body as {
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
'customColors'?: Array<{
}>;
})
    );
  }

  /**
   * Get organization's payments stats.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypePaymentTotalStats$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypePaymentTotalStats$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    from: string;
    to: string;

    /**
     * Dist of data ie. day, week, month, year
     */
    dist: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
'customColors'?: Array<{
}>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypePaymentTotalStatsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
      rb.query('dist', params.dist, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'stats'?: Array<{
        }>;
        'total'?: Array<{
        }>;
        'customColors'?: Array<{
        }>;
        }>;
      })
    );
  }

  /**
   * Get organization's payments stats.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypePaymentTotalStats$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypePaymentTotalStats$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    from: string;
    to: string;

    /**
     * Dist of data ie. day, week, month, year
     */
    dist: string;
  },
  context?: HttpContext

): Observable<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
'customColors'?: Array<{
}>;
}> {

    return this.organizationPrototypePaymentTotalStats$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
'customColors'?: Array<{
}>;
}>) => r.body as {
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
'customColors'?: Array<{
}>;
})
    );
  }

  /**
   * Get organization's payments stats.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypePaymentTotalStats$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypePaymentTotalStats$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    from: string;
    to: string;

    /**
     * Dist of data ie. day, week, month, year
     */
    dist: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
'customColors'?: Array<{
}>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypePaymentTotalStatsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
      rb.query('dist', params.dist, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'stats'?: Array<{
        }>;
        'total'?: Array<{
        }>;
        'customColors'?: Array<{
        }>;
        }>;
      })
    );
  }

  /**
   * Get organization's payments stats.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypePaymentTotalStats$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypePaymentTotalStats$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    from: string;
    to: string;

    /**
     * Dist of data ie. day, week, month, year
     */
    dist: string;
  },
  context?: HttpContext

): Observable<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
'customColors'?: Array<{
}>;
}> {

    return this.organizationPrototypePaymentTotalStats$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
'customColors'?: Array<{
}>;
}>) => r.body as {
'stats'?: Array<{
}>;
'total'?: Array<{
}>;
'customColors'?: Array<{
}>;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeThisMonthInvoices
   */
  static readonly OrganizationPrototypeThisMonthInvoicesPath = '/v1/Organizations/{id}/this-month-invoices';

  /**
   * Returns number of invoices issued by organization in this month.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeThisMonthInvoices$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeThisMonthInvoices$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'number'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeThisMonthInvoicesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'number'?: number;
        }>;
      })
    );
  }

  /**
   * Returns number of invoices issued by organization in this month.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeThisMonthInvoices$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeThisMonthInvoices$Json(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'number'?: number;
}> {

    return this.organizationPrototypeThisMonthInvoices$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'number'?: number;
}>) => r.body as {
'number'?: number;
})
    );
  }

  /**
   * Returns number of invoices issued by organization in this month.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeThisMonthInvoices$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeThisMonthInvoices$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'number'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeThisMonthInvoicesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'number'?: number;
        }>;
      })
    );
  }

  /**
   * Returns number of invoices issued by organization in this month.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeThisMonthInvoices$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeThisMonthInvoices$Xml(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'number'?: number;
}> {

    return this.organizationPrototypeThisMonthInvoices$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'number'?: number;
}>) => r.body as {
'number'?: number;
})
    );
  }

  /**
   * Returns number of invoices issued by organization in this month.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeThisMonthInvoices$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeThisMonthInvoices$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'number'?: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeThisMonthInvoicesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'number'?: number;
        }>;
      })
    );
  }

  /**
   * Returns number of invoices issued by organization in this month.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeThisMonthInvoices$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeThisMonthInvoices$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
'number'?: number;
}> {

    return this.organizationPrototypeThisMonthInvoices$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'number'?: number;
}>) => r.body as {
'number'?: number;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeExport
   */
  static readonly OrganizationPrototypeExportPath = '/v1/Organizations/{id}/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeExport$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeExport$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;
    where?: string;
    email?: boolean;
    lang?: string;
    options?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;

/**
 * Link to file which will be available once the export is generated
 */
'link'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeExportPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('where', params.where, {});
      rb.query('email', params.email, {});
      rb.query('lang', params.lang, {});
      rb.query('options', params.options, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        
        /**
         * Link to file which will be available once the export is generated
         */
        'link'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeExport$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeExport$Json(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;
    where?: string;
    email?: boolean;
    lang?: string;
    options?: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;

/**
 * Link to file which will be available once the export is generated
 */
'link'?: string;
}> {

    return this.organizationPrototypeExport$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;

/**
 * Link to file which will be available once the export is generated
 */
'link'?: string;
}>) => r.body as {
'success'?: boolean;

/**
 * Link to file which will be available once the export is generated
 */
'link'?: string;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeExport$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeExport$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;
    where?: string;
    email?: boolean;
    lang?: string;
    options?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;

/**
 * Link to file which will be available once the export is generated
 */
'link'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeExportPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('where', params.where, {});
      rb.query('email', params.email, {});
      rb.query('lang', params.lang, {});
      rb.query('options', params.options, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        
        /**
         * Link to file which will be available once the export is generated
         */
        'link'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeExport$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeExport$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;
    where?: string;
    email?: boolean;
    lang?: string;
    options?: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;

/**
 * Link to file which will be available once the export is generated
 */
'link'?: string;
}> {

    return this.organizationPrototypeExport$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;

/**
 * Link to file which will be available once the export is generated
 */
'link'?: string;
}>) => r.body as {
'success'?: boolean;

/**
 * Link to file which will be available once the export is generated
 */
'link'?: string;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeExport$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeExport$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;
    where?: string;
    email?: boolean;
    lang?: string;
    options?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;

/**
 * Link to file which will be available once the export is generated
 */
'link'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeExportPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('where', params.where, {});
      rb.query('email', params.email, {});
      rb.query('lang', params.lang, {});
      rb.query('options', params.options, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        
        /**
         * Link to file which will be available once the export is generated
         */
        'link'?: string;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeExport$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeExport$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;
    where?: string;
    email?: boolean;
    lang?: string;
    options?: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;

/**
 * Link to file which will be available once the export is generated
 */
'link'?: string;
}> {

    return this.organizationPrototypeExport$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;

/**
 * Link to file which will be available once the export is generated
 */
'link'?: string;
}>) => r.body as {
'success'?: boolean;

/**
 * Link to file which will be available once the export is generated
 */
'link'?: string;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeUploadCertificate
   */
  static readonly OrganizationPrototypeUploadCertificatePath = '/v1/Organizations/{id}/upload-certificate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeUploadCertificate$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUploadCertificate$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body: {

/**
 * Type of certificate ie. furs
 */
'type': string;

/**
 * Environment certificate is intended for, default is `production`.
 */
'environment'?: string;

/**
 * Type of certificate ie. furs
 */
'passphrase'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeUploadCertificatePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeUploadCertificate$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUploadCertificate$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body: {

/**
 * Type of certificate ie. furs
 */
'type': string;

/**
 * Environment certificate is intended for, default is `production`.
 */
'environment'?: string;

/**
 * Type of certificate ie. furs
 */
'passphrase'?: string;
}
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.organizationPrototypeUploadCertificate$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeUploadCertificate$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUploadCertificate$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body: {

/**
 * Type of certificate ie. furs
 */
'type': string;

/**
 * Environment certificate is intended for, default is `production`.
 */
'environment'?: string;

/**
 * Type of certificate ie. furs
 */
'passphrase'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeUploadCertificatePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeUploadCertificate$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUploadCertificate$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body: {

/**
 * Type of certificate ie. furs
 */
'type': string;

/**
 * Environment certificate is intended for, default is `production`.
 */
'environment'?: string;

/**
 * Type of certificate ie. furs
 */
'passphrase'?: string;
}
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.organizationPrototypeUploadCertificate$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeUploadCertificate$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUploadCertificate$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body: {

/**
 * Type of certificate ie. furs
 */
'type': string;

/**
 * Environment certificate is intended for, default is `production`.
 */
'environment'?: string;

/**
 * Type of certificate ie. furs
 */
'passphrase'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeUploadCertificatePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeUploadCertificate$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeUploadCertificate$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body: {

/**
 * Type of certificate ie. furs
 */
'type': string;

/**
 * Environment certificate is intended for, default is `production`.
 */
'environment'?: string;

/**
 * Type of certificate ie. furs
 */
'passphrase'?: string;
}
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.organizationPrototypeUploadCertificate$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeInvite
   */
  static readonly OrganizationPrototypeInvitePath = '/v1/Organizations/{id}/invite';

  /**
   * Invite user to / give access to organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeInvite$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeInvite$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: {

/**
 * Id of account being invited, optional if email is provided
 */
'accountId'?: string;

/**
 * Email of user to invite, optional if inviteeId is provided
 */
'email'?: string;

/**
 * Indicates if invitee must accept the invitation before getting access.
 * Only verified top level Space Invoices accounts can set this to false.
 */
'requireConfirmation'?: boolean;

/**
 * Should an email be sent to the invitee.
 * Can only be turned off for invitations that don't require confirmation.
 */
'sendEmail'?: boolean;

/**
 * Role of invitee, 'default' for normal, 'readonly' for limited and 'admin' for full access. Defaults to 'default'.
 */
'role'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeInvitePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Invite user to / give access to organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeInvite$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeInvite$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body?: {

/**
 * Id of account being invited, optional if email is provided
 */
'accountId'?: string;

/**
 * Email of user to invite, optional if inviteeId is provided
 */
'email'?: string;

/**
 * Indicates if invitee must accept the invitation before getting access.
 * Only verified top level Space Invoices accounts can set this to false.
 */
'requireConfirmation'?: boolean;

/**
 * Should an email be sent to the invitee.
 * Can only be turned off for invitations that don't require confirmation.
 */
'sendEmail'?: boolean;

/**
 * Role of invitee, 'default' for normal, 'readonly' for limited and 'admin' for full access. Defaults to 'default'.
 */
'role'?: string;
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.organizationPrototypeInvite$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Invite user to / give access to organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeInvite$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeInvite$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: {

/**
 * Id of account being invited, optional if email is provided
 */
'accountId'?: string;

/**
 * Email of user to invite, optional if inviteeId is provided
 */
'email'?: string;

/**
 * Indicates if invitee must accept the invitation before getting access.
 * Only verified top level Space Invoices accounts can set this to false.
 */
'requireConfirmation'?: boolean;

/**
 * Should an email be sent to the invitee.
 * Can only be turned off for invitations that don't require confirmation.
 */
'sendEmail'?: boolean;

/**
 * Role of invitee, 'default' for normal, 'readonly' for limited and 'admin' for full access. Defaults to 'default'.
 */
'role'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeInvitePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Invite user to / give access to organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeInvite$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeInvite$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body?: {

/**
 * Id of account being invited, optional if email is provided
 */
'accountId'?: string;

/**
 * Email of user to invite, optional if inviteeId is provided
 */
'email'?: string;

/**
 * Indicates if invitee must accept the invitation before getting access.
 * Only verified top level Space Invoices accounts can set this to false.
 */
'requireConfirmation'?: boolean;

/**
 * Should an email be sent to the invitee.
 * Can only be turned off for invitations that don't require confirmation.
 */
'sendEmail'?: boolean;

/**
 * Role of invitee, 'default' for normal, 'readonly' for limited and 'admin' for full access. Defaults to 'default'.
 */
'role'?: string;
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.organizationPrototypeInvite$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Invite user to / give access to organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeInvite$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeInvite$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body?: {

/**
 * Id of account being invited, optional if email is provided
 */
'accountId'?: string;

/**
 * Email of user to invite, optional if inviteeId is provided
 */
'email'?: string;

/**
 * Indicates if invitee must accept the invitation before getting access.
 * Only verified top level Space Invoices accounts can set this to false.
 */
'requireConfirmation'?: boolean;

/**
 * Should an email be sent to the invitee.
 * Can only be turned off for invitations that don't require confirmation.
 */
'sendEmail'?: boolean;

/**
 * Role of invitee, 'default' for normal, 'readonly' for limited and 'admin' for full access. Defaults to 'default'.
 */
'role'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeInvitePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Invite user to / give access to organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeInvite$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeInvite$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body?: {

/**
 * Id of account being invited, optional if email is provided
 */
'accountId'?: string;

/**
 * Email of user to invite, optional if inviteeId is provided
 */
'email'?: string;

/**
 * Indicates if invitee must accept the invitation before getting access.
 * Only verified top level Space Invoices accounts can set this to false.
 */
'requireConfirmation'?: boolean;

/**
 * Should an email be sent to the invitee.
 * Can only be turned off for invitations that don't require confirmation.
 */
'sendEmail'?: boolean;

/**
 * Role of invitee, 'default' for normal, 'readonly' for limited and 'admin' for full access. Defaults to 'default'.
 */
'role'?: string;
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.organizationPrototypeInvite$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation organizationPrototypeHasCertificate
   */
  static readonly OrganizationPrototypeHasCertificatePath = '/v1/Organizations/{id}/has-certificate';

  /**
   * Check if organization has certificate of a type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeHasCertificate$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeHasCertificate$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;

    /**
     * Environment certificate is intended for, default is `production`.
     */
    environment?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'exists'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeHasCertificatePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('environment', params.environment, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'exists'?: boolean;
        }>;
      })
    );
  }

  /**
   * Check if organization has certificate of a type.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeHasCertificate$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeHasCertificate$Json(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;

    /**
     * Environment certificate is intended for, default is `production`.
     */
    environment?: string;
  },
  context?: HttpContext

): Observable<{
'exists'?: boolean;
}> {

    return this.organizationPrototypeHasCertificate$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'exists'?: boolean;
}>) => r.body as {
'exists'?: boolean;
})
    );
  }

  /**
   * Check if organization has certificate of a type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeHasCertificate$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeHasCertificate$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;

    /**
     * Environment certificate is intended for, default is `production`.
     */
    environment?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'exists'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeHasCertificatePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('environment', params.environment, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'exists'?: boolean;
        }>;
      })
    );
  }

  /**
   * Check if organization has certificate of a type.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeHasCertificate$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeHasCertificate$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;

    /**
     * Environment certificate is intended for, default is `production`.
     */
    environment?: string;
  },
  context?: HttpContext

): Observable<{
'exists'?: boolean;
}> {

    return this.organizationPrototypeHasCertificate$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'exists'?: boolean;
}>) => r.body as {
'exists'?: boolean;
})
    );
  }

  /**
   * Check if organization has certificate of a type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeHasCertificate$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeHasCertificate$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;

    /**
     * Environment certificate is intended for, default is `production`.
     */
    environment?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'exists'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeHasCertificatePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('type', params.type, {});
      rb.query('environment', params.environment, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'exists'?: boolean;
        }>;
      })
    );
  }

  /**
   * Check if organization has certificate of a type.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeHasCertificate$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeHasCertificate$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    type: string;

    /**
     * Environment certificate is intended for, default is `production`.
     */
    environment?: string;
  },
  context?: HttpContext

): Observable<{
'exists'?: boolean;
}> {

    return this.organizationPrototypeHasCertificate$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'exists'?: boolean;
}>) => r.body as {
'exists'?: boolean;
})
    );
  }

  /**
   * Path part for operation organizationMailgunRoute
   */
  static readonly OrganizationMailgunRoutePath = '/v1/Organizations/mailgun-route';

  /**
   * Redirect mailgun to correct model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationMailgunRoute$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationMailgunRoute$Json$Response(params: {

    /**
     * Mailgun object
     */
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationMailgunRoutePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Redirect mailgun to correct model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationMailgunRoute$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationMailgunRoute$Json(params: {

    /**
     * Mailgun object
     */
    body: {
}
  },
  context?: HttpContext

): Observable<void> {

    return this.organizationMailgunRoute$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Redirect mailgun to correct model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationMailgunRoute$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationMailgunRoute$XWwwFormUrlencoded$Response(params: {

    /**
     * Mailgun object
     */
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationMailgunRoutePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Redirect mailgun to correct model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationMailgunRoute$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationMailgunRoute$XWwwFormUrlencoded(params: {

    /**
     * Mailgun object
     */
    body: {
}
  },
  context?: HttpContext

): Observable<void> {

    return this.organizationMailgunRoute$XWwwFormUrlencoded$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Redirect mailgun to correct model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationMailgunRoute$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationMailgunRoute$Xml$Response(params: {

    /**
     * Mailgun object
     */
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationMailgunRoutePath, 'post');
    if (params) {
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Redirect mailgun to correct model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationMailgunRoute$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  organizationMailgunRoute$Xml(params: {

    /**
     * Mailgun object
     */
    body: {
}
  },
  context?: HttpContext

): Observable<void> {

    return this.organizationMailgunRoute$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation organizationPrototypeRemoteDocumentPdFs
   */
  static readonly OrganizationPrototypeRemoteDocumentPdFsPath = '/v1/Organizations/{id}/documents/pdfs';

  /**
   * Get multiple document PDF files in ZIP.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeRemoteDocumentPdFs$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteDocumentPdFs$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Array of document ids to fetch as PDFs
     */
    ids: string;

    /**
     * Should an email with the link be sent to the requester
     */
    email?: boolean;
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
'link'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeRemoteDocumentPdFsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('ids', params.ids, {});
      rb.query('email', params.email, {});
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'link'?: string;
        }>;
      })
    );
  }

  /**
   * Get multiple document PDF files in ZIP.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeRemoteDocumentPdFs$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteDocumentPdFs$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Array of document ids to fetch as PDFs
     */
    ids: string;

    /**
     * Should an email with the link be sent to the requester
     */
    email?: boolean;
    lang?: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
'link'?: string;
}> {

    return this.organizationPrototypeRemoteDocumentPdFs$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'link'?: string;
}>) => r.body as {
'success'?: boolean;
'link'?: string;
})
    );
  }

  /**
   * Get multiple document PDF files in ZIP.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeRemoteDocumentPdFs$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteDocumentPdFs$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Array of document ids to fetch as PDFs
     */
    ids: string;

    /**
     * Should an email with the link be sent to the requester
     */
    email?: boolean;
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
'link'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeRemoteDocumentPdFsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('ids', params.ids, {});
      rb.query('email', params.email, {});
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'link'?: string;
        }>;
      })
    );
  }

  /**
   * Get multiple document PDF files in ZIP.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeRemoteDocumentPdFs$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteDocumentPdFs$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Array of document ids to fetch as PDFs
     */
    ids: string;

    /**
     * Should an email with the link be sent to the requester
     */
    email?: boolean;
    lang?: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
'link'?: string;
}> {

    return this.organizationPrototypeRemoteDocumentPdFs$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'link'?: string;
}>) => r.body as {
'success'?: boolean;
'link'?: string;
})
    );
  }

  /**
   * Get multiple document PDF files in ZIP.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeRemoteDocumentPdFs$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteDocumentPdFs$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Array of document ids to fetch as PDFs
     */
    ids: string;

    /**
     * Should an email with the link be sent to the requester
     */
    email?: boolean;
    lang?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
'link'?: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeRemoteDocumentPdFsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('ids', params.ids, {});
      rb.query('email', params.email, {});
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        'link'?: string;
        }>;
      })
    );
  }

  /**
   * Get multiple document PDF files in ZIP.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeRemoteDocumentPdFs$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteDocumentPdFs$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Array of document ids to fetch as PDFs
     */
    ids: string;

    /**
     * Should an email with the link be sent to the requester
     */
    email?: boolean;
    lang?: string;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
'link'?: string;
}> {

    return this.organizationPrototypeRemoteDocumentPdFs$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
'link'?: string;
}>) => r.body as {
'success'?: boolean;
'link'?: string;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeRemoteParseImage
   */
  static readonly OrganizationPrototypeRemoteParseImagePath = '/v1/Organizations/{id}/documents/parse-image';

  /**
   * Takes an image or PDF file and puts in into processing queue to create document from.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeRemoteParseImage$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteParseImage$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Is image made by mobile phone.
     */
    mobile?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeRemoteParseImagePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('mobile', params.mobile, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Takes an image or PDF file and puts in into processing queue to create document from.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeRemoteParseImage$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteParseImage$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Is image made by mobile phone.
     */
    mobile?: boolean;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.organizationPrototypeRemoteParseImage$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Takes an image or PDF file and puts in into processing queue to create document from.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeRemoteParseImage$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteParseImage$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Is image made by mobile phone.
     */
    mobile?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeRemoteParseImagePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('mobile', params.mobile, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Takes an image or PDF file and puts in into processing queue to create document from.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeRemoteParseImage$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteParseImage$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Is image made by mobile phone.
     */
    mobile?: boolean;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.organizationPrototypeRemoteParseImage$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Takes an image or PDF file and puts in into processing queue to create document from.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeRemoteParseImage$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteParseImage$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Is image made by mobile phone.
     */
    mobile?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeRemoteParseImagePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('mobile', params.mobile, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Takes an image or PDF file and puts in into processing queue to create document from.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeRemoteParseImage$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteParseImage$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * Is image made by mobile phone.
     */
    mobile?: boolean;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.organizationPrototypeRemoteParseImage$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation organizationPrototypeImport
   */
  static readonly OrganizationPrototypeImportPath = '/v1/Organizations/{id}/import';

  /**
   * Import CSV data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeImport$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeImport$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{

/**
 * Operation status
 */
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeImportPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Operation status
         */
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * Import CSV data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeImport$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeImport$Json(params: {

    /**
     * Organization id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<{

/**
 * Operation status
 */
'success'?: boolean;
}> {

    return this.organizationPrototypeImport$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{

/**
 * Operation status
 */
'success'?: boolean;
}>) => r.body as {

/**
 * Operation status
 */
'success'?: boolean;
})
    );
  }

  /**
   * Import CSV data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeImport$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeImport$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{

/**
 * Operation status
 */
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeImportPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Operation status
         */
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * Import CSV data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeImport$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeImport$Xml(params: {

    /**
     * Organization id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<{

/**
 * Operation status
 */
'success'?: boolean;
}> {

    return this.organizationPrototypeImport$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{

/**
 * Operation status
 */
'success'?: boolean;
}>) => r.body as {

/**
 * Operation status
 */
'success'?: boolean;
})
    );
  }

  /**
   * Import CSV data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeImport$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeImport$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{

/**
 * Operation status
 */
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeImportPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Operation status
         */
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * Import CSV data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeImport$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  organizationPrototypeImport$Javascript(params: {

    /**
     * Organization id
     */
    id: string;
    body: {
'type': string;
}
  },
  context?: HttpContext

): Observable<{

/**
 * Operation status
 */
'success'?: boolean;
}> {

    return this.organizationPrototypeImport$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{

/**
 * Operation status
 */
'success'?: boolean;
}>) => r.body as {

/**
 * Operation status
 */
'success'?: boolean;
})
    );
  }

  /**
   * Path part for operation organizationPrototypeRemoteProfitAndLoss
   */
  static readonly OrganizationPrototypeRemoteProfitAndLossPath = '/v1/Organizations/{id}/reports/profit-and-loss';

  /**
   * Generate category profit and loss report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeRemoteProfitAndLoss$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteProfitAndLoss$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * from date
     */
    from: string;

    /**
     * from date
     */
    to: string;

    /**
     * Additional report generation options
     */
    options?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<XAny>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeRemoteProfitAndLossPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
      rb.query('options', params.options, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<XAny>>;
      })
    );
  }

  /**
   * Generate category profit and loss report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeRemoteProfitAndLoss$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteProfitAndLoss$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * from date
     */
    from: string;

    /**
     * from date
     */
    to: string;

    /**
     * Additional report generation options
     */
    options?: string;
  },
  context?: HttpContext

): Observable<Array<XAny>> {

    return this.organizationPrototypeRemoteProfitAndLoss$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<XAny>>) => r.body as Array<XAny>)
    );
  }

  /**
   * Generate category profit and loss report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeRemoteProfitAndLoss$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteProfitAndLoss$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * from date
     */
    from: string;

    /**
     * from date
     */
    to: string;

    /**
     * Additional report generation options
     */
    options?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<XAny>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeRemoteProfitAndLossPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
      rb.query('options', params.options, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<XAny>>;
      })
    );
  }

  /**
   * Generate category profit and loss report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeRemoteProfitAndLoss$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteProfitAndLoss$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * from date
     */
    from: string;

    /**
     * from date
     */
    to: string;

    /**
     * Additional report generation options
     */
    options?: string;
  },
  context?: HttpContext

): Observable<Array<XAny>> {

    return this.organizationPrototypeRemoteProfitAndLoss$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<XAny>>) => r.body as Array<XAny>)
    );
  }

  /**
   * Generate category profit and loss report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeRemoteProfitAndLoss$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteProfitAndLoss$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * from date
     */
    from: string;

    /**
     * from date
     */
    to: string;

    /**
     * Additional report generation options
     */
    options?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<XAny>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeRemoteProfitAndLossPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
      rb.query('options', params.options, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<XAny>>;
      })
    );
  }

  /**
   * Generate category profit and loss report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeRemoteProfitAndLoss$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteProfitAndLoss$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * from date
     */
    from: string;

    /**
     * from date
     */
    to: string;

    /**
     * Additional report generation options
     */
    options?: string;
  },
  context?: HttpContext

): Observable<Array<XAny>> {

    return this.organizationPrototypeRemoteProfitAndLoss$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<XAny>>) => r.body as Array<XAny>)
    );
  }

  /**
   * Path part for operation organizationPrototypeRemoteBalanceSheet
   */
  static readonly OrganizationPrototypeRemoteBalanceSheetPath = '/v1/Organizations/{id}/reports/balance-sheet';

  /**
   * Generate balance sheet balance sheet.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeRemoteBalanceSheet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteBalanceSheet$Json$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * from date
     */
    from: string;

    /**
     * from date
     */
    to: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<XAny>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeRemoteBalanceSheetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<XAny>>;
      })
    );
  }

  /**
   * Generate balance sheet balance sheet.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeRemoteBalanceSheet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteBalanceSheet$Json(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * from date
     */
    from: string;

    /**
     * from date
     */
    to: string;
  },
  context?: HttpContext

): Observable<Array<XAny>> {

    return this.organizationPrototypeRemoteBalanceSheet$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<XAny>>) => r.body as Array<XAny>)
    );
  }

  /**
   * Generate balance sheet balance sheet.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeRemoteBalanceSheet$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteBalanceSheet$Xml$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * from date
     */
    from: string;

    /**
     * from date
     */
    to: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<XAny>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeRemoteBalanceSheetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<XAny>>;
      })
    );
  }

  /**
   * Generate balance sheet balance sheet.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeRemoteBalanceSheet$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteBalanceSheet$Xml(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * from date
     */
    from: string;

    /**
     * from date
     */
    to: string;
  },
  context?: HttpContext

): Observable<Array<XAny>> {

    return this.organizationPrototypeRemoteBalanceSheet$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<XAny>>) => r.body as Array<XAny>)
    );
  }

  /**
   * Generate balance sheet balance sheet.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationPrototypeRemoteBalanceSheet$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteBalanceSheet$Javascript$Response(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * from date
     */
    from: string;

    /**
     * from date
     */
    to: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<XAny>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.OrganizationPrototypeRemoteBalanceSheetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<XAny>>;
      })
    );
  }

  /**
   * Generate balance sheet balance sheet.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationPrototypeRemoteBalanceSheet$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationPrototypeRemoteBalanceSheet$Javascript(params: {

    /**
     * Organization id
     */
    id: string;

    /**
     * from date
     */
    from: string;

    /**
     * from date
     */
    to: string;
  },
  context?: HttpContext

): Observable<Array<XAny>> {

    return this.organizationPrototypeRemoteBalanceSheet$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<XAny>>) => r.body as Array<XAny>)
    );
  }

}
