/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Subscription } from '../models/subscription';
import { WhiteLabel } from '../models/white-label';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation subscriptionPrototypeCreateWhiteLabel
   */
  static readonly SubscriptionPrototypeCreateWhiteLabelPath = '/v1/Subscriptions/{id}/whiteLabel';

  /**
   * Creates a new instance in whiteLabel of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPrototypeCreateWhiteLabel$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionPrototypeCreateWhiteLabel$Json$Json$Response(params: {

    /**
     * Subscription id
     */
    id: string;
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionPrototypeCreateWhiteLabelPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Creates a new instance in whiteLabel of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionPrototypeCreateWhiteLabel$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionPrototypeCreateWhiteLabel$Json$Json(params: {

    /**
     * Subscription id
     */
    id: string;
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.subscriptionPrototypeCreateWhiteLabel$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Creates a new instance in whiteLabel of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPrototypeCreateWhiteLabel$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionPrototypeCreateWhiteLabel$Json$Xml$Response(params: {

    /**
     * Subscription id
     */
    id: string;
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionPrototypeCreateWhiteLabelPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Creates a new instance in whiteLabel of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionPrototypeCreateWhiteLabel$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionPrototypeCreateWhiteLabel$Json$Xml(params: {

    /**
     * Subscription id
     */
    id: string;
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.subscriptionPrototypeCreateWhiteLabel$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Creates a new instance in whiteLabel of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPrototypeCreateWhiteLabel$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionPrototypeCreateWhiteLabel$Json$Javascript$Response(params: {

    /**
     * Subscription id
     */
    id: string;
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionPrototypeCreateWhiteLabelPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Creates a new instance in whiteLabel of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionPrototypeCreateWhiteLabel$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscriptionPrototypeCreateWhiteLabel$Json$Javascript(params: {

    /**
     * Subscription id
     */
    id: string;
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.subscriptionPrototypeCreateWhiteLabel$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Creates a new instance in whiteLabel of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPrototypeCreateWhiteLabel$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  subscriptionPrototypeCreateWhiteLabel$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Subscription id
     */
    id: string;
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionPrototypeCreateWhiteLabelPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Creates a new instance in whiteLabel of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionPrototypeCreateWhiteLabel$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  subscriptionPrototypeCreateWhiteLabel$XWwwFormUrlencoded$Json(params: {

    /**
     * Subscription id
     */
    id: string;
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.subscriptionPrototypeCreateWhiteLabel$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Creates a new instance in whiteLabel of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPrototypeCreateWhiteLabel$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  subscriptionPrototypeCreateWhiteLabel$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Subscription id
     */
    id: string;
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionPrototypeCreateWhiteLabelPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Creates a new instance in whiteLabel of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionPrototypeCreateWhiteLabel$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  subscriptionPrototypeCreateWhiteLabel$XWwwFormUrlencoded$Xml(params: {

    /**
     * Subscription id
     */
    id: string;
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.subscriptionPrototypeCreateWhiteLabel$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Creates a new instance in whiteLabel of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPrototypeCreateWhiteLabel$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  subscriptionPrototypeCreateWhiteLabel$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Subscription id
     */
    id: string;
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionPrototypeCreateWhiteLabelPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Creates a new instance in whiteLabel of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionPrototypeCreateWhiteLabel$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  subscriptionPrototypeCreateWhiteLabel$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Subscription id
     */
    id: string;
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.subscriptionPrototypeCreateWhiteLabel$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Creates a new instance in whiteLabel of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPrototypeCreateWhiteLabel$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  subscriptionPrototypeCreateWhiteLabel$Xml$Json$Response(params: {

    /**
     * Subscription id
     */
    id: string;
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionPrototypeCreateWhiteLabelPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Creates a new instance in whiteLabel of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionPrototypeCreateWhiteLabel$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  subscriptionPrototypeCreateWhiteLabel$Xml$Json(params: {

    /**
     * Subscription id
     */
    id: string;
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.subscriptionPrototypeCreateWhiteLabel$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Creates a new instance in whiteLabel of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPrototypeCreateWhiteLabel$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  subscriptionPrototypeCreateWhiteLabel$Xml$Xml$Response(params: {

    /**
     * Subscription id
     */
    id: string;
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionPrototypeCreateWhiteLabelPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Creates a new instance in whiteLabel of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionPrototypeCreateWhiteLabel$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  subscriptionPrototypeCreateWhiteLabel$Xml$Xml(params: {

    /**
     * Subscription id
     */
    id: string;
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.subscriptionPrototypeCreateWhiteLabel$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Creates a new instance in whiteLabel of this model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPrototypeCreateWhiteLabel$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  subscriptionPrototypeCreateWhiteLabel$Xml$Javascript$Response(params: {

    /**
     * Subscription id
     */
    id: string;
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionPrototypeCreateWhiteLabelPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Creates a new instance in whiteLabel of this model.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionPrototypeCreateWhiteLabel$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  subscriptionPrototypeCreateWhiteLabel$Xml$Javascript(params: {

    /**
     * Subscription id
     */
    id: string;
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.subscriptionPrototypeCreateWhiteLabel$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Path part for operation subscriptionFind
   */
  static readonly SubscriptionFindPath = '/v1/Subscriptions';

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionFind$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionFind$Json$Response(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Subscription>>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Subscription>>;
      })
    );
  }

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionFind$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionFind$Json(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Subscription>> {

    return this.subscriptionFind$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Subscription>>) => r.body as Array<Subscription>)
    );
  }

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionFind$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionFind$Xml$Response(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Subscription>>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Subscription>>;
      })
    );
  }

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionFind$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionFind$Xml(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Subscription>> {

    return this.subscriptionFind$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Subscription>>) => r.body as Array<Subscription>)
    );
  }

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionFind$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionFind$Javascript$Response(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Subscription>>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Subscription>>;
      })
    );
  }

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionFind$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionFind$Javascript(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<Subscription>> {

    return this.subscriptionFind$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Subscription>>) => r.body as Array<Subscription>)
    );
  }

  /**
   * Path part for operation subscriptionClientToken
   */
  static readonly SubscriptionClientTokenPath = '/v1/Subscriptions/client-token';

  /**
   * Get Braintree client token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionClientToken$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionClientToken$Json$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionClientTokenPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get Braintree client token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionClientToken$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionClientToken$Json(params?: {
  },
  context?: HttpContext

): Observable<string> {

    return this.subscriptionClientToken$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Get Braintree client token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionClientToken$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionClientToken$Xml$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionClientTokenPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get Braintree client token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionClientToken$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionClientToken$Xml(params?: {
  },
  context?: HttpContext

): Observable<string> {

    return this.subscriptionClientToken$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Get Braintree client token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionClientToken$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionClientToken$Javascript$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionClientTokenPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get Braintree client token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionClientToken$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionClientToken$Javascript(params?: {
  },
  context?: HttpContext

): Observable<string> {

    return this.subscriptionClientToken$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation subscriptionRemoteCron
   */
  static readonly SubscriptionRemoteCronPath = '/v1/Subscriptions/cron';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionRemoteCron$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionRemoteCron$Json$Response(params?: {
    date?: string;

    /**
     * If provided limits cron to one subscription
     */
    subscriptionId?: string;

    /**
     * Runs without sending email to customer
     */
    skipSendingEmails?: boolean;

    /**
     * Runs without issuing invoice for usage
     */
    skipIssuingInvoices?: boolean;

    /**
     * Checks usage but does not commit data or send emails
     */
    dryRun?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionRemoteCronPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
      rb.query('subscriptionId', params.subscriptionId, {});
      rb.query('skipSendingEmails', params.skipSendingEmails, {});
      rb.query('skipIssuingInvoices', params.skipIssuingInvoices, {});
      rb.query('dryRun', params.dryRun, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionRemoteCron$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionRemoteCron$Json(params?: {
    date?: string;

    /**
     * If provided limits cron to one subscription
     */
    subscriptionId?: string;

    /**
     * Runs without sending email to customer
     */
    skipSendingEmails?: boolean;

    /**
     * Runs without issuing invoice for usage
     */
    skipIssuingInvoices?: boolean;

    /**
     * Checks usage but does not commit data or send emails
     */
    dryRun?: boolean;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.subscriptionRemoteCron$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionRemoteCron$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionRemoteCron$Xml$Response(params?: {
    date?: string;

    /**
     * If provided limits cron to one subscription
     */
    subscriptionId?: string;

    /**
     * Runs without sending email to customer
     */
    skipSendingEmails?: boolean;

    /**
     * Runs without issuing invoice for usage
     */
    skipIssuingInvoices?: boolean;

    /**
     * Checks usage but does not commit data or send emails
     */
    dryRun?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionRemoteCronPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
      rb.query('subscriptionId', params.subscriptionId, {});
      rb.query('skipSendingEmails', params.skipSendingEmails, {});
      rb.query('skipIssuingInvoices', params.skipIssuingInvoices, {});
      rb.query('dryRun', params.dryRun, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionRemoteCron$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionRemoteCron$Xml(params?: {
    date?: string;

    /**
     * If provided limits cron to one subscription
     */
    subscriptionId?: string;

    /**
     * Runs without sending email to customer
     */
    skipSendingEmails?: boolean;

    /**
     * Runs without issuing invoice for usage
     */
    skipIssuingInvoices?: boolean;

    /**
     * Checks usage but does not commit data or send emails
     */
    dryRun?: boolean;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.subscriptionRemoteCron$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionRemoteCron$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionRemoteCron$Javascript$Response(params?: {
    date?: string;

    /**
     * If provided limits cron to one subscription
     */
    subscriptionId?: string;

    /**
     * Runs without sending email to customer
     */
    skipSendingEmails?: boolean;

    /**
     * Runs without issuing invoice for usage
     */
    skipIssuingInvoices?: boolean;

    /**
     * Checks usage but does not commit data or send emails
     */
    dryRun?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
'success'?: boolean;
}>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionRemoteCronPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
      rb.query('subscriptionId', params.subscriptionId, {});
      rb.query('skipSendingEmails', params.skipSendingEmails, {});
      rb.query('skipIssuingInvoices', params.skipIssuingInvoices, {});
      rb.query('dryRun', params.dryRun, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'success'?: boolean;
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionRemoteCron$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionRemoteCron$Javascript(params?: {
    date?: string;

    /**
     * If provided limits cron to one subscription
     */
    subscriptionId?: string;

    /**
     * Runs without sending email to customer
     */
    skipSendingEmails?: boolean;

    /**
     * Runs without issuing invoice for usage
     */
    skipIssuingInvoices?: boolean;

    /**
     * Checks usage but does not commit data or send emails
     */
    dryRun?: boolean;
  },
  context?: HttpContext

): Observable<{
'success'?: boolean;
}> {

    return this.subscriptionRemoteCron$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
'success'?: boolean;
}>) => r.body as {
'success'?: boolean;
})
    );
  }

  /**
   * Path part for operation subscriptionPrototypeGetPaymentMethod
   */
  static readonly SubscriptionPrototypeGetPaymentMethodPath = '/v1/Subscriptions/{id}/payment-method';

  /**
   * Get Payment method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPrototypeGetPaymentMethod$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionPrototypeGetPaymentMethod$Json$Response(params: {

    /**
     * Subscription id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionPrototypeGetPaymentMethodPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Get Payment method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionPrototypeGetPaymentMethod$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionPrototypeGetPaymentMethod$Json(params: {

    /**
     * Subscription id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.subscriptionPrototypeGetPaymentMethod$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Get Payment method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPrototypeGetPaymentMethod$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionPrototypeGetPaymentMethod$Xml$Response(params: {

    /**
     * Subscription id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionPrototypeGetPaymentMethodPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Get Payment method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionPrototypeGetPaymentMethod$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionPrototypeGetPaymentMethod$Xml(params: {

    /**
     * Subscription id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.subscriptionPrototypeGetPaymentMethod$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Get Payment method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPrototypeGetPaymentMethod$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionPrototypeGetPaymentMethod$Javascript$Response(params: {

    /**
     * Subscription id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionPrototypeGetPaymentMethodPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Get Payment method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionPrototypeGetPaymentMethod$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionPrototypeGetPaymentMethod$Javascript(params: {

    /**
     * Subscription id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.subscriptionPrototypeGetPaymentMethod$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation subscriptionPrototypeSetPaymentMethod
   */
  static readonly SubscriptionPrototypeSetPaymentMethodPath = '/v1/Subscriptions/{id}/payment-method';

  /**
   * Add/Update payment method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPrototypeSetPaymentMethod$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  subscriptionPrototypeSetPaymentMethod$Json$Response(params: {

    /**
     * Subscription id
     */
    id: string;
    body: {
'paymentMethod': string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionPrototypeSetPaymentMethodPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Add/Update payment method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionPrototypeSetPaymentMethod$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  subscriptionPrototypeSetPaymentMethod$Json(params: {

    /**
     * Subscription id
     */
    id: string;
    body: {
'paymentMethod': string;
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.subscriptionPrototypeSetPaymentMethod$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Add/Update payment method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPrototypeSetPaymentMethod$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  subscriptionPrototypeSetPaymentMethod$Xml$Response(params: {

    /**
     * Subscription id
     */
    id: string;
    body: {
'paymentMethod': string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionPrototypeSetPaymentMethodPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Add/Update payment method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionPrototypeSetPaymentMethod$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  subscriptionPrototypeSetPaymentMethod$Xml(params: {

    /**
     * Subscription id
     */
    id: string;
    body: {
'paymentMethod': string;
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.subscriptionPrototypeSetPaymentMethod$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Add/Update payment method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPrototypeSetPaymentMethod$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  subscriptionPrototypeSetPaymentMethod$Javascript$Response(params: {

    /**
     * Subscription id
     */
    id: string;
    body: {
'paymentMethod': string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionService.SubscriptionPrototypeSetPaymentMethodPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Add/Update payment method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscriptionPrototypeSetPaymentMethod$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  subscriptionPrototypeSetPaymentMethod$Javascript(params: {

    /**
     * Subscription id
     */
    id: string;
    body: {
'paymentMethod': string;
}
  },
  context?: HttpContext

): Observable<{
}> {

    return this.subscriptionPrototypeSetPaymentMethod$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

}
