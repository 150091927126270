import { Observable, ReplaySubject, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ApolloSubscriptionService } from '../apollo-subscriptions/subscription.service';
import { GlobalSubscription } from './global-subscription.interface';
import { ISubscription } from '../apollo-subscriptions/subscription';
import { Injectable } from '@angular/core';
import { Subscription } from '../shared/sdk';
import { SubscriptionService } from './subscription.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GlobalSubscriptionService {
  public _active: GlobalSubscription;
  private active$: ReplaySubject<GlobalSubscription> = new ReplaySubject(1);
  readonly active: Observable<GlobalSubscription> = this.active$.asObservable();

  constructor(
    private apolloSubscriptionService: ApolloSubscriptionService,
    private subscriptionService: SubscriptionService,
  ) {}

  getActive(orgId: string): Observable<GlobalSubscription> {
    return this.subscriptionService.getActive(orgId).pipe(
      switchMap((subscription) => {
        let obs: Observable<Subscription | ISubscription>;
        let type: string;

        // Interface should honor SI subscription
        if (
          subscription?._subscriptionPlan &&
          subscription?.accountId !== environment.masterId
        ) {
          type = 'SI';
          obs = of(subscription);
        } else {
          type = 'Apollo';
          // Must call after checking SI subscription to prevent
          // Apollo subscription initialising unnecessarily
          obs = this.apolloSubscriptionService.getActive(orgId);
        }

        return obs.pipe(
          map((sub) => ({
            subscription: sub,
            type,
          })),
        );
      }),
      map(
        ({
          subscription,
          type,
        }: {
          subscription: Subscription | ISubscription;
          type: 'SI' | 'Apollo';
        }) => {
          const gs: GlobalSubscription = {
            type,
            features: {
              documentLimit: 0, // if no subscription
            },
            trial: false,
            subscription,
          };

          if (type === 'SI') {
            gs.features = this.allFeatures;

            if (subscription._subscriptionPlan.tier < 2) {
              gs.features.additionalUsers = 3;
              gs.features.documentLimit = 50;
            }
          } else {
            // Apollo
            const sub = subscription as ISubscription;

            if (sub) {
              gs.dateEnd = sub.dateEnd;
              gs.features = sub.additions || {};
              gs.trial =
                sub._subscriptionPlan && sub._subscriptionPlan.tier === 0;

              // Document limit
              let documentLimit: number;

              switch (sub._subscriptionPlan?.tier) {
                case 1:
                  documentLimit = 1;
                  break;

                case 2:
                  documentLimit = 10;
                  break;

                case 0:
                case 3:
                  documentLimit = 10000;
                  break;
              }

              gs.features.documentLimit = documentLimit;
            }
          }

          this._active = gs;
          this.active$.next(this._active);

          return gs;
        },
      ),
    );
  }

  has(feature: string): Observable<boolean | number> {
    return this.active.pipe(
      map((subs) => {
        if (!subs?.features || !subs.features[feature]) {
          return false;
        }

        return subs.features[feature];
      }),
    );
  }

  get allFeatures(): any {
    return {
      integrations: true,
      furs: true,
      fina: true,
      inventory: true,
      units: true,
      additionalUsers: 99,
      documentLimit: 29999999,
    };
  }
}
