import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ToastService } from '../shared/modules/toast/toast.service';
import { LoggerService, LoopBackConfig } from '../shared/sdk';
import {
  Account,
  AccountOrganization,
  Default,
  Organization,
  Shopify,
  TicketingHub,
} from '../shared/sdk/models';
import {
  AccountApi,
  AccountOrganizationApi,
  OrganizationApi,
  ShopifyApi,
} from '../shared/sdk/services';

import { HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { ISubscription } from '../apollo-subscriptions/subscription';
import { API_VERSION, BASE_URL } from '../shared/base-url';
import { ApiService } from '../shared/services/api.service';
import { CacheService } from '../shared/services/cache.service';
import { TrackingService } from '../shared/services/tracking.service';
import { GlobalSubscription } from '../subscriptions/global-subscription.interface';
import { GlobalSubscriptionService } from '../subscriptions/global-subscription.service';

@Injectable()
export class OrganizationService extends ApiService<Organization> {
  public _active: Organization;
  private active$: ReplaySubject<Organization> = new ReplaySubject(1);
  readonly active: Observable<Organization> = this.active$.asObservable();

  constructor(
    protected cacheService: CacheService,
    protected log: LoggerService,
    protected toastService: ToastService,
    protected language: TranslateService,
    private accountApi: AccountApi,
    private aoApi: AccountOrganizationApi,
    private orgApi: OrganizationApi,
    private shopifyApi: ShopifyApi,
    private trackingService: TrackingService,
    private globalSubscriptionService: GlobalSubscriptionService,
  ) {
    super(cacheService, log, toastService, language);

    this.name = 'Organization';
    this.plural = 'Organizations';
    this.ttl = 600000; // 10 min

    LoopBackConfig.setBaseURL(BASE_URL);
    LoopBackConfig.setApiVersion(API_VERSION);

    // TODO: Store/Load selected/default organization

    this.clearCache();
  }

  clearCache(): void {
    this.cacheService.clearCache();
    this.setActive(null).subscribe();
  }

  create(
    accountId = this.accountApi.getCurrentToken().userId,
    data: any,
    toastr = true,
  ): Observable<Organization> {
    return super.create(accountId, data, toastr, this.accountApi);
  }

  findDefault(org: Organization, defaultName: string): Default {
    if (!org?._defaults?.length) {
      return new Default();
    }

    return org._defaults.find((d) => d.name === defaultName);
  }

  get(
    accountId = this.accountApi.getCurrentToken().userId,
    filter = {},
    force = false,
    toastr = true,
  ): Observable<{ body: Organization[]; totalCount: number }> {
    return super.get(accountId, filter, force, toastr, this.accountApi);
  }

  // getById(
  //   accountId: string,
  //   id: string,
  //   filter = {},
  //   force = true,
  //   toastr = true,
  // ): Observable<Organization> {
  //   return super.getById(accountId, id, filter, force, toastr, this.orgApi);
  // }

  getDocumentValueStats(
    id: any,
    type: string,
    from: string,
    to: string,
    dist: string,
    gross: boolean,
    incoming: boolean,
    force = false,
  ): Observable<{ total: any; stats: any; customColors: any }> {
    return this.cachedReq(
      `dts_${id}_${type}_${from}_${to}_${dist}_${gross}_${incoming}`,
      this.orgApi.documentTotalStats(
        id,
        type,
        from,
        to,
        dist,
        gross,
        incoming,
        (headers: HttpHeaders) => {
          return headers.set(
            'lang',
            this.language.currentLang || this.language.defaultLang,
          );
        },
      ),
      force,
      false,
    ).pipe(map(({ body }) => body));
  }

  getCategoryValueStats(
    id: any,
    from: string,
    to: string,
    dist: string,
    categoryId?: string,
    force = false,
  ): Observable<{ total: any; stats: any; customColors: any }> {
    return this.cachedReq(
      `cts_${id}_${from}_${to}_${dist}_${categoryId || 'all'}`,
      this.orgApi.categoryTotalStats(id, from, to, dist, categoryId),
      force,
      false,
    ).pipe(map(({ body }) => body));
  }

  getPaymentValueStats(
    id: any,
    from: string,
    to: string,
    dist: string,
    force = false,
  ): Observable<{ total: any; stats: any; customColors: any }> {
    return this.cachedReq(
      `${id}_${from}_${to}_${dist}`,
      this.orgApi.paymentTotalStats(id, from, to, dist),
      force,
      false,
    ).pipe(map(({ body }) => body));
  }

  getTotalStats(id: any, force = false): Observable<{ stats: any }> {
    this.log.log('OrganizationService: getTotalStats');
    return this.cachedReq(
      `ts_${id}`,
      this.orgApi.totalStats(id),
      force,
      false,
    ).pipe(map(({ body }) => body));
  }

  getBusinessStats(
    id: string,
    from = moment().format('Y-MM-DD'),
    to = moment().format('Y-MM-DD'),
    accountId: string,
    unitId: string,
    text = true,
  ): Observable<any> {
    return this.orgApi
      .businessStats(
        id,
        from,
        to,
        accountId,
        unitId,
        text,
        (headers: HttpHeaders) => {
          return headers.append(
            'lang',
            this.language.currentLang || this.language.defaultLang,
          );
        },
      )
      .pipe(map(({ body }) => body));
  }

  getAccountOrganizations(
    orgId: string,
    filter: any,
  ): Observable<{ body: AccountOrganization[]; totalCount: number }> {
    return this.orgApi.getAccountOrganizations(orgId, filter);
  }

  getBalanceSheet(
    id: any,
    from: any = moment().format('Y-MM'),
    to: any = moment().format('Y-MM'),
    _: object,
    force = false,
  ): Observable<any[]> {
    this.log.log('OrganizationService: getBalanceSheet');

    return this.cachedReq(
      `pal_${id}_${from}_${to}`,
      this.orgApi.remoteBalanceSheet(id, from, to),
      force,
      false,
    ).pipe(map(({ body }) => body));
  }

  getProfitAndLoss(
    id: any,
    from: any = moment().format('Y-MM'),
    to: any = moment().format('Y-MM'),
    options: {
      categorized?: boolean;
      drilldown?: boolean;
      deferred?: boolean;
    } = {},
    force = false,
  ): Observable<any[]> {
    this.log.log('OrganizationService: getProfitAndLoss');

    return this.cachedReq(
      `pal_${id}_${from}_${to}_${JSON.stringify(options)}`,
      this.orgApi.remoteProfitAndLoss(id, from, to, options),
      force,
      false,
    ).pipe(map(({ body }) => body));
  }

  countAccountOrganizations(orgId: string, where: any): Observable<number> {
    return this.orgApi
      .countAccountOrganizations(orgId, where)
      .pipe(map(({ body }) => body.count));
  }

  changeAoRole(id: string, role: string) {
    return this.aoApi.changeRole(id, role).pipe(
      map(({ body }) => body),
      map((ao: AccountOrganization) => {
        this.toastService.success(
          this.translate.instant('Role successfully updated'),
        );

        return ao;
      }),
      catchError((err) => {
        this.toastService.error(this.translate.instant('Error updating role'));

        return throwError(err);
      }),
    );
  }

  inviteAO(
    orgId: string,
    accountId?: string,
    email?: string,
    role = 'default',
    requireConfirmation = true,
    sendEmail = true,
  ) {
    return this.orgApi
      .invite(orgId, accountId, email, requireConfirmation, sendEmail, role)
      .pipe(
        map(({ body }) => body),
        map((ao: AccountOrganization) => {
          this.toastService.success(
            this.translate.instant('User successfully invited'),
          );

          return ao;
        }),
        catchError((err) => {
          this.toastService.error(
            this.translate.instant('Error inviting user'),
          );

          return throwError(err);
        }),
      );
  }

  removeAO(orgId: string, id: string) {
    return this.aoApi.deleteById(id).pipe(
      map(({ body }) => body),
      map((res) => {
        this.toastService.success(
          this.translate.instant('Access successfully removed'),
        );

        return res;
      }),
      catchError((err) => {
        this.toastService.error(
          this.translate.instant('Error removing access'),
        );

        return throwError(err);
      }),
    );
  }

  enableAO(id: string) {
    return this.aoApi.enable(id).pipe(
      map(({ body }) => body),
      map((res) => {
        this.toastService.success(
          this.translate.instant('User successfully enabled'),
        );

        return res;
      }),
      catchError((err) => {
        this.toastService.error(
          this.translate.instant('Error enabling access'),
        );

        return throwError(err);
      }),
    );
  }

  disableAO(id: string) {
    return this.aoApi.disable(id).pipe(
      map(({ body }) => body),
      map((res) => {
        this.toastService.success(
          this.translate.instant('Access successfully disabled'),
        );

        return res;
      }),
      catchError((err) => {
        this.toastService.error(
          this.translate.instant('Error disabling access'),
        );

        return throwError(err);
      }),
    );
  }

  acceptAO(id: string) {
    return this.aoApi.accept(id).pipe(
      map(({ body }) => body),
      map((res) => {
        this.toastService.success(
          this.translate.instant('Access successfully accepted'),
        );

        return res;
      }),
      catchError((err) => {
        this.toastService.error(
          this.translate.instant('Error accepting access'),
        );

        return throwError(err);
      }),
    );
  }

  rejectAO(id: string) {
    return this.aoApi.reject(id).pipe(
      map(({ body }) => body),
      map((res) => {
        this.toastService.success(
          this.translate.instant('Access successfully rejected'),
        );

        return res;
      }),
      catchError((err) => {
        this.toastService.error(
          this.translate.instant('Error rejecting access'),
        );

        return throwError(err);
      }),
    );
  }

  isMultiTax(country: string): boolean {
    let isMultiTax: boolean;

    switch (country) {
      // TODO: Improve country switcher multilang
      case 'United States':
      case 'USA':
      case 'United States of America':
        isMultiTax = true;
        break;

      default:
        isMultiTax = false;
    }

    return isMultiTax;
  }

  setActive(org: Organization): Observable<Organization> {
    this.log.log('OrganizationService: setActive', org);

    return of(org).pipe(
      switchMap((o) => {
        if (!o) {
          return of(o);
        } else {
          return this.globalSubscriptionService.getActive(org.id);
        }
      }),
      // map((res: Organization | [Subscription, ISubscription]) => {
      map((subs: Organization | GlobalSubscription) => {
        this._active = org;
        this.active$.next(this._active);

        subs = subs as GlobalSubscription;

        if (this._active && subs.type === 'Apollo') {
          const account = new Account();
          account.id = this.accountApi.getCurrentToken().userId;

          this.trackingService.setOrganization(account, org);
          this.trackingService.setSubscription(
            account,
            subs.subscription as ISubscription,
          );

          if (org._defaults) {
            const pdfTemplateDef = this.findDefault(org, 'pdf_template');

            if (pdfTemplateDef) {
              this.trackingService.setUserData(account, {
                pdf_template: pdfTemplateDef.value || 'classic',
              });
            }
          }
        }

        return this._active;
      }),
    );
  }

  setActiveById(id: string, force = false): Observable<Organization> {
    this.log.log('OrganizationService: setActiveById');

    return this.get(undefined, undefined, force).pipe(
      switchMap(({ body: orgs }: { body: Organization[] }) => {
        const org = orgs.find((o) => o.id === id);
        this.log.info('OrganizationService: setActiveById: find ', org);
        return this.setActive(org);
      }),
    );
  }

  patch(id: string, data: any, toastr = true, updateActiveOrg = true) {
    this.log.log('OrganizationService: patch');

    return super.patch(id, data, toastr, this.orgApi).pipe(
      map((org: Organization) => {
        if (updateActiveOrg && org.id === this._active.id) {
          this.setActive(org);
        }

        return org;
      }),
    );
  }

  createDefault(
    org: Organization,
    data: Default,
    toastr: boolean = true,
    updateActiveOrg = true,
  ): Observable<Default> {
    this.log.log('OrganizationService: createDefault');

    return this.orgApi.create_defaults(org.id, data).pipe(
      map(({ body: def }: { body: Default }) => {
        const index = org._defaults.findIndex((d: Default) => d.id === def.id);

        org._defaults = [...org._defaults, def];

        // TODO: Update org in cache
        if (updateActiveOrg && org.id === this._active.id) {
          this.setActive(org);
        }

        if (toastr) {
          this.toastService.success(
            this.translate.instant(`Organization settings successfully saved`),
          );
        }

        return def;
      }),
      catchError((err) => {
        if (toastr) {
          this.toastService.error(
            this.translate.instant(
              `Error updating organization settings, please try again or contact support`,
            ),
          );
        }

        return throwError(err);
      }),
    );
  }

  createShopify(orgId: string, data: any): Observable<Shopify> {
    this.log.log('OrganizationService: createShopify');

    return this.orgApi.createShopify(orgId, data).pipe(
      map((res) => {
        this.toastService.success(
          this.translate.instant('Successfully enabled Shopify'),
        );

        return res;
      }),
      catchError((err) => {
        this.toastService.error(
          this.translate.instant('Error enabling Shopify'),
        );

        return throwError(err);
      }),
    );
  }

  updateDefault(
    org: Organization,
    data: Default,
    toastr: boolean = true,
    updateActiveOrg = true,
  ): Observable<Default> {
    this.log.log('OrganizationService: updateDefault');

    return this.orgApi.updateById_defaults(org.id, data.id, data).pipe(
      map((def: Default) => {
        if (!org._defaults?.length) return;

        const index = org._defaults.findIndex((d: Default) => d.id === def.id);

        org._defaults = [
          ...org._defaults.slice(0, index),
          def,
          ...org._defaults.slice(index + 1, org._defaults.length),
        ];

        // TODO: Update org in cache
        if (updateActiveOrg && org.id === this._active.id) {
          this.setActive(org);
        }

        if (toastr) {
          this.toastService.success(
            this.translate.instant(`Organization setting successfully updated`),
          );
        }

        return def;
      }),
      catchError((err) => {
        if (toastr) {
          this.toastService.error(
            this.translate.instant(
              `Error updating organization settings, please try again or contact support`,
            ),
          );
        }

        return throwError(err);
      }),
    );
  }

  getShopify(
    id: string,
    filter: any = {},
    force = false,
    toastr = true,
  ): Observable<{ body: Shopify[]; totalCount: number }> {
    return this.orgApi.getShopify(id);
  }

  getTicketingHub(
    id: string,
    filter: any = {},
    force = false,
    toastr = true,
  ): Observable<{ body: TicketingHub[]; totalCount: number }> {
    return this.orgApi.getTicketingHub(id);
  }

  updateShopify(id: string, data: Shopify): Observable<Shopify> {
    return this.shopifyApi.updateAttributes(id, data).pipe(
      map((data) => {
        return data;
      }),
    );
  }

  deleteImage(id: string, type: string) {
    return this.orgApi.deleteImage(id, type).pipe(map(({ body }) => body));
  }
}
