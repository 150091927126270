/* tslint:disable */
import {
  Organization
} from '../index';

declare var Object: any;
export interface TaxInterface {
  "classification"?: string;
  "country"?: string;
  "countryAlpha2Code"?: string;
  "name"?: string;
  "abbreviation"?: string;
  "recoverable": boolean;
  "compound": boolean;
  "taxNumber"?: string;
  "id"?: any;
  "_taxRates"?: Array<any>;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "deletedAt"?: Date;
  "_isDeleted"?: boolean;
  "deletedId"?: string;
  "custom"?: any;
  "organizationId"?: any;
  taxRates?: any[];
  organization?: Organization;
}

export class Tax implements TaxInterface {
  "classification": string;
  "country": string;
  "countryAlpha2Code": string;
  "name": string;
  "abbreviation": string;
  "recoverable": boolean;
  "compound": boolean;
  "taxNumber": string;
  "id": any;
  "_taxRates": Array<any>;
  "createdAt": Date;
  "updatedAt": Date;
  "deletedAt": Date;
  "_isDeleted": boolean;
  "deletedId": string;
  "custom": any;
  "organizationId": any;
  taxRates: any[];
  organization: Organization;
  constructor(data?: TaxInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Tax`.
   */
  public static getModelName() {
    return "Tax";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Tax for dynamic purposes.
  **/
  public static factory(data: TaxInterface): Tax{
    return new Tax(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Tax',
      plural: 'Taxes',
      path: 'Taxes',
      idName: 'id',
      properties: {
        "classification": {
          name: 'classification',
          type: 'string'
        },
        "country": {
          name: 'country',
          type: 'string'
        },
        "countryAlpha2Code": {
          name: 'countryAlpha2Code',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "abbreviation": {
          name: 'abbreviation',
          type: 'string'
        },
        "recoverable": {
          name: 'recoverable',
          type: 'boolean',
          default: true
        },
        "compound": {
          name: 'compound',
          type: 'boolean',
          default: false
        },
        "taxNumber": {
          name: 'taxNumber',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "_taxRates": {
          name: '_taxRates',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        "deletedAt": {
          name: 'deletedAt',
          type: 'Date'
        },
        "_isDeleted": {
          name: '_isDeleted',
          type: 'boolean'
        },
        "deletedId": {
          name: 'deletedId',
          type: 'string'
        },
        "custom": {
          name: 'custom',
          type: 'any',
          default: <any>null
        },
        "organizationId": {
          name: 'organizationId',
          type: 'any'
        },
      },
      relations: {
        taxRates: {
          name: 'taxRates',
          type: 'any[]',
          model: '',
          relationType: 'embedsMany',
                  keyFrom: '_taxRates',
          keyTo: 'id'
        },
        organization: {
          name: 'organization',
          type: 'Organization',
          model: 'Organization',
          relationType: 'belongsTo',
                  keyFrom: 'organizationId',
          keyTo: 'id'
        },
      }
    }
  }
}
