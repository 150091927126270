import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from '@angular/router';

import { AccountService } from '../../accounts/account.service';
import { take, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private accountService: AccountService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Observable<boolean> {
    return this.check(state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Observable<boolean> {
    return this.check(state);
  }

  private check(state: RouterStateSnapshot): boolean | Observable<boolean> {
    if (this.accountService.isAuthenticated()) {
      return true;
    }

    this.goToLogin(state);
    return false;
  }

  private goToLogin(state: RouterStateSnapshot) {
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
  }
}
