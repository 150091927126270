/* tslint:disable */
import {
  Organization,
  Document,
  Item
} from '../index';

declare var Object: any;
export interface WarehouseInterface {
  "name": string;
  "default"?: boolean;
  "id"?: any;
  "organizationId"?: any;
  "deletedAt"?: Date;
  "_isDeleted"?: boolean;
  "deletedId"?: string;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "custom"?: any;
  organization?: Organization;
  inventories?: any[];
  documents?: Document[];
  items?: Item[];
}

export class Warehouse implements WarehouseInterface {
  "name": string;
  "default": boolean;
  "id": any;
  "organizationId": any;
  "deletedAt": Date;
  "_isDeleted": boolean;
  "deletedId": string;
  "createdAt": Date;
  "updatedAt": Date;
  "custom": any;
  organization: Organization;
  inventories: any[];
  documents: Document[];
  items: Item[];
  constructor(data?: WarehouseInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Warehouse`.
   */
  public static getModelName() {
    return "Warehouse";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Warehouse for dynamic purposes.
  **/
  public static factory(data: WarehouseInterface): Warehouse{
    return new Warehouse(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Warehouse',
      plural: 'Warehouses',
      path: 'Warehouses',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "default": {
          name: 'default',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "organizationId": {
          name: 'organizationId',
          type: 'any'
        },
        "deletedAt": {
          name: 'deletedAt',
          type: 'Date'
        },
        "_isDeleted": {
          name: '_isDeleted',
          type: 'boolean'
        },
        "deletedId": {
          name: 'deletedId',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        "custom": {
          name: 'custom',
          type: 'any',
          default: <any>null
        },
      },
      relations: {
        organization: {
          name: 'organization',
          type: 'Organization',
          model: 'Organization',
          relationType: 'belongsTo',
                  keyFrom: 'organizationId',
          keyTo: 'id'
        },
        inventories: {
          name: 'inventories',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'warehouseId'
        },
        documents: {
          name: 'documents',
          type: 'Document[]',
          model: 'Document',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'warehouseId'
        },
        items: {
          name: 'items',
          type: 'Item[]',
          model: 'Item',
          relationType: 'hasMany',
          modelThrough: 'Inventory',
          keyThrough: 'itemId',
          keyFrom: 'id',
          keyTo: 'warehouseId'
        },
      }
    }
  }
}
