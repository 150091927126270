/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Webhook } from '../models/webhook';

@Injectable({
  providedIn: 'root',
})
export class WebhookService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation webhookFindById
   */
  static readonly WebhookFindByIdPath = '/v1/Webhooks/{id}';

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookFindById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhookFindById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookFindById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhookFindById$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookFindById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookFindById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhookFindById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookFindById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhookFindById$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookFindById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookFindById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhookFindById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookFindById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhookFindById$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookFindById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Path part for operation webhookReplaceByIdPutWebhooksId
   */
  static readonly WebhookReplaceByIdPutWebhooksIdPath = '/v1/Webhooks/{id}';

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookReplaceByIdPutWebhooksId$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookReplaceByIdPutWebhooksId$Json$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookReplaceByIdPutWebhooksIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookReplaceByIdPutWebhooksId$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookReplaceByIdPutWebhooksId$Json$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookReplaceByIdPutWebhooksId$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookReplaceByIdPutWebhooksId$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookReplaceByIdPutWebhooksId$Json$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookReplaceByIdPutWebhooksIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookReplaceByIdPutWebhooksId$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookReplaceByIdPutWebhooksId$Json$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookReplaceByIdPutWebhooksId$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookReplaceByIdPutWebhooksId$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookReplaceByIdPutWebhooksId$Json$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookReplaceByIdPutWebhooksIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookReplaceByIdPutWebhooksId$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookReplaceByIdPutWebhooksId$Json$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookReplaceByIdPutWebhooksId$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookReplaceByIdPutWebhooksId$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  webhookReplaceByIdPutWebhooksId$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookReplaceByIdPutWebhooksIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookReplaceByIdPutWebhooksId$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  webhookReplaceByIdPutWebhooksId$XWwwFormUrlencoded$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookReplaceByIdPutWebhooksId$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookReplaceByIdPutWebhooksId$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  webhookReplaceByIdPutWebhooksId$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookReplaceByIdPutWebhooksIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookReplaceByIdPutWebhooksId$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  webhookReplaceByIdPutWebhooksId$XWwwFormUrlencoded$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookReplaceByIdPutWebhooksId$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookReplaceByIdPutWebhooksId$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  webhookReplaceByIdPutWebhooksId$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookReplaceByIdPutWebhooksIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookReplaceByIdPutWebhooksId$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  webhookReplaceByIdPutWebhooksId$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookReplaceByIdPutWebhooksId$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookReplaceByIdPutWebhooksId$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  webhookReplaceByIdPutWebhooksId$Xml$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookReplaceByIdPutWebhooksIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookReplaceByIdPutWebhooksId$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  webhookReplaceByIdPutWebhooksId$Xml$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookReplaceByIdPutWebhooksId$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookReplaceByIdPutWebhooksId$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  webhookReplaceByIdPutWebhooksId$Xml$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookReplaceByIdPutWebhooksIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookReplaceByIdPutWebhooksId$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  webhookReplaceByIdPutWebhooksId$Xml$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookReplaceByIdPutWebhooksId$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookReplaceByIdPutWebhooksId$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  webhookReplaceByIdPutWebhooksId$Xml$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookReplaceByIdPutWebhooksIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookReplaceByIdPutWebhooksId$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  webhookReplaceByIdPutWebhooksId$Xml$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookReplaceByIdPutWebhooksId$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Path part for operation webhookDeleteById
   */
  static readonly WebhookDeleteByIdPath = '/v1/Webhooks/{id}';

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookDeleteById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhookDeleteById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookDeleteById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhookDeleteById$Json(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.webhookDeleteById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookDeleteById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhookDeleteById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookDeleteById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhookDeleteById$Xml(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.webhookDeleteById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookDeleteById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhookDeleteById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookDeleteById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhookDeleteById$Javascript(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.webhookDeleteById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation webhookPrototypePatchAttributes
   */
  static readonly WebhookPrototypePatchAttributesPath = '/v1/Webhooks/{id}';

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookPrototypePatchAttributes$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookPrototypePatchAttributes$Json$Json$Response(params: {

    /**
     * Webhook id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookPrototypePatchAttributes$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookPrototypePatchAttributes$Json$Json(params: {

    /**
     * Webhook id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookPrototypePatchAttributes$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookPrototypePatchAttributes$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookPrototypePatchAttributes$Json$Xml$Response(params: {

    /**
     * Webhook id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookPrototypePatchAttributes$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookPrototypePatchAttributes$Json$Xml(params: {

    /**
     * Webhook id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookPrototypePatchAttributes$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookPrototypePatchAttributes$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookPrototypePatchAttributes$Json$Javascript$Response(params: {

    /**
     * Webhook id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookPrototypePatchAttributes$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookPrototypePatchAttributes$Json$Javascript(params: {

    /**
     * Webhook id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookPrototypePatchAttributes$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookPrototypePatchAttributes$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  webhookPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Webhook id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  webhookPrototypePatchAttributes$XWwwFormUrlencoded$Json(params: {

    /**
     * Webhook id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookPrototypePatchAttributes$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  webhookPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Webhook id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  webhookPrototypePatchAttributes$XWwwFormUrlencoded$Xml(params: {

    /**
     * Webhook id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookPrototypePatchAttributes$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  webhookPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Webhook id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  webhookPrototypePatchAttributes$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Webhook id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookPrototypePatchAttributes$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  webhookPrototypePatchAttributes$Xml$Json$Response(params: {

    /**
     * Webhook id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookPrototypePatchAttributes$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  webhookPrototypePatchAttributes$Xml$Json(params: {

    /**
     * Webhook id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookPrototypePatchAttributes$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookPrototypePatchAttributes$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  webhookPrototypePatchAttributes$Xml$Xml$Response(params: {

    /**
     * Webhook id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookPrototypePatchAttributes$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  webhookPrototypePatchAttributes$Xml$Xml(params: {

    /**
     * Webhook id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookPrototypePatchAttributes$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookPrototypePatchAttributes$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  webhookPrototypePatchAttributes$Xml$Javascript$Response(params: {

    /**
     * Webhook id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookPrototypePatchAttributes$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  webhookPrototypePatchAttributes$Xml$Javascript(params: {

    /**
     * Webhook id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookPrototypePatchAttributes$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Path part for operation webhookReplaceByIdPostWebhooksIdReplace
   */
  static readonly WebhookReplaceByIdPostWebhooksIdReplacePath = '/v1/Webhooks/{id}/replace';

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookReplaceByIdPostWebhooksIdReplace$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookReplaceByIdPostWebhooksIdReplace$Json$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookReplaceByIdPostWebhooksIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookReplaceByIdPostWebhooksIdReplace$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookReplaceByIdPostWebhooksIdReplace$Json$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookReplaceByIdPostWebhooksIdReplace$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookReplaceByIdPostWebhooksIdReplace$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookReplaceByIdPostWebhooksIdReplace$Json$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookReplaceByIdPostWebhooksIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookReplaceByIdPostWebhooksIdReplace$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookReplaceByIdPostWebhooksIdReplace$Json$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookReplaceByIdPostWebhooksIdReplace$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookReplaceByIdPostWebhooksIdReplace$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookReplaceByIdPostWebhooksIdReplace$Json$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookReplaceByIdPostWebhooksIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookReplaceByIdPostWebhooksIdReplace$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookReplaceByIdPostWebhooksIdReplace$Json$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookReplaceByIdPostWebhooksIdReplace$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookReplaceByIdPostWebhooksIdReplace$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  webhookReplaceByIdPostWebhooksIdReplace$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookReplaceByIdPostWebhooksIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookReplaceByIdPostWebhooksIdReplace$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  webhookReplaceByIdPostWebhooksIdReplace$XWwwFormUrlencoded$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookReplaceByIdPostWebhooksIdReplace$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookReplaceByIdPostWebhooksIdReplace$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  webhookReplaceByIdPostWebhooksIdReplace$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookReplaceByIdPostWebhooksIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookReplaceByIdPostWebhooksIdReplace$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  webhookReplaceByIdPostWebhooksIdReplace$XWwwFormUrlencoded$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookReplaceByIdPostWebhooksIdReplace$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookReplaceByIdPostWebhooksIdReplace$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  webhookReplaceByIdPostWebhooksIdReplace$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookReplaceByIdPostWebhooksIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookReplaceByIdPostWebhooksIdReplace$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  webhookReplaceByIdPostWebhooksIdReplace$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookReplaceByIdPostWebhooksIdReplace$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookReplaceByIdPostWebhooksIdReplace$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  webhookReplaceByIdPostWebhooksIdReplace$Xml$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookReplaceByIdPostWebhooksIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookReplaceByIdPostWebhooksIdReplace$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  webhookReplaceByIdPostWebhooksIdReplace$Xml$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookReplaceByIdPostWebhooksIdReplace$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookReplaceByIdPostWebhooksIdReplace$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  webhookReplaceByIdPostWebhooksIdReplace$Xml$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookReplaceByIdPostWebhooksIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookReplaceByIdPostWebhooksIdReplace$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  webhookReplaceByIdPostWebhooksIdReplace$Xml$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookReplaceByIdPostWebhooksIdReplace$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookReplaceByIdPostWebhooksIdReplace$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  webhookReplaceByIdPostWebhooksIdReplace$Xml$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Webhook>> {

    const rb = new RequestBuilder(this.rootUrl, WebhookService.WebhookReplaceByIdPostWebhooksIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Webhook>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `webhookReplaceByIdPostWebhooksIdReplace$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  webhookReplaceByIdPostWebhooksIdReplace$Xml$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Webhook
  },
  context?: HttpContext

): Observable<Webhook> {

    return this.webhookReplaceByIdPostWebhooksIdReplace$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Webhook>) => r.body as Webhook)
    );
  }

}
