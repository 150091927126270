/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Default } from '../models/default';

@Injectable({
  providedIn: 'root',
})
export class DefaultService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation defaultFindById
   */
  static readonly DefaultFindByIdPath = '/v1/Defaults/{id}';

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `defaultFindById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  defaultFindById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, DefaultService.DefaultFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `defaultFindById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  defaultFindById$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Default> {

    return this.defaultFindById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `defaultFindById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  defaultFindById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, DefaultService.DefaultFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `defaultFindById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  defaultFindById$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Default> {

    return this.defaultFindById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `defaultFindById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  defaultFindById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, DefaultService.DefaultFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `defaultFindById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  defaultFindById$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Default> {

    return this.defaultFindById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Path part for operation defaultDeleteById
   */
  static readonly DefaultDeleteByIdPath = '/v1/Defaults/{id}';

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `defaultDeleteById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  defaultDeleteById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, DefaultService.DefaultDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `defaultDeleteById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  defaultDeleteById$Json(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.defaultDeleteById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `defaultDeleteById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  defaultDeleteById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, DefaultService.DefaultDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `defaultDeleteById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  defaultDeleteById$Xml(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.defaultDeleteById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `defaultDeleteById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  defaultDeleteById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, DefaultService.DefaultDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `defaultDeleteById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  defaultDeleteById$Javascript(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.defaultDeleteById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation defaultPrototypePatchAttributes
   */
  static readonly DefaultPrototypePatchAttributesPath = '/v1/Defaults/{id}';

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `defaultPrototypePatchAttributes$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  defaultPrototypePatchAttributes$Json$Json$Response(params: {

    /**
     * Default id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Default
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, DefaultService.DefaultPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `defaultPrototypePatchAttributes$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  defaultPrototypePatchAttributes$Json$Json(params: {

    /**
     * Default id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Default
  },
  context?: HttpContext

): Observable<Default> {

    return this.defaultPrototypePatchAttributes$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `defaultPrototypePatchAttributes$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  defaultPrototypePatchAttributes$Json$Xml$Response(params: {

    /**
     * Default id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Default
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, DefaultService.DefaultPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `defaultPrototypePatchAttributes$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  defaultPrototypePatchAttributes$Json$Xml(params: {

    /**
     * Default id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Default
  },
  context?: HttpContext

): Observable<Default> {

    return this.defaultPrototypePatchAttributes$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `defaultPrototypePatchAttributes$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  defaultPrototypePatchAttributes$Json$Javascript$Response(params: {

    /**
     * Default id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Default
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, DefaultService.DefaultPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `defaultPrototypePatchAttributes$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  defaultPrototypePatchAttributes$Json$Javascript(params: {

    /**
     * Default id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Default
  },
  context?: HttpContext

): Observable<Default> {

    return this.defaultPrototypePatchAttributes$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `defaultPrototypePatchAttributes$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  defaultPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Default id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Default
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, DefaultService.DefaultPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `defaultPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  defaultPrototypePatchAttributes$XWwwFormUrlencoded$Json(params: {

    /**
     * Default id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Default
  },
  context?: HttpContext

): Observable<Default> {

    return this.defaultPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `defaultPrototypePatchAttributes$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  defaultPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Default id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Default
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, DefaultService.DefaultPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `defaultPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  defaultPrototypePatchAttributes$XWwwFormUrlencoded$Xml(params: {

    /**
     * Default id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Default
  },
  context?: HttpContext

): Observable<Default> {

    return this.defaultPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `defaultPrototypePatchAttributes$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  defaultPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Default id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Default
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, DefaultService.DefaultPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `defaultPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  defaultPrototypePatchAttributes$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Default id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Default
  },
  context?: HttpContext

): Observable<Default> {

    return this.defaultPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `defaultPrototypePatchAttributes$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  defaultPrototypePatchAttributes$Xml$Json$Response(params: {

    /**
     * Default id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Default
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, DefaultService.DefaultPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `defaultPrototypePatchAttributes$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  defaultPrototypePatchAttributes$Xml$Json(params: {

    /**
     * Default id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Default
  },
  context?: HttpContext

): Observable<Default> {

    return this.defaultPrototypePatchAttributes$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `defaultPrototypePatchAttributes$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  defaultPrototypePatchAttributes$Xml$Xml$Response(params: {

    /**
     * Default id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Default
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, DefaultService.DefaultPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `defaultPrototypePatchAttributes$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  defaultPrototypePatchAttributes$Xml$Xml(params: {

    /**
     * Default id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Default
  },
  context?: HttpContext

): Observable<Default> {

    return this.defaultPrototypePatchAttributes$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `defaultPrototypePatchAttributes$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  defaultPrototypePatchAttributes$Xml$Javascript$Response(params: {

    /**
     * Default id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Default
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Default>> {

    const rb = new RequestBuilder(this.rootUrl, DefaultService.DefaultPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Default>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `defaultPrototypePatchAttributes$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  defaultPrototypePatchAttributes$Xml$Javascript(params: {

    /**
     * Default id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Default
  },
  context?: HttpContext

): Observable<Default> {

    return this.defaultPrototypePatchAttributes$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Default>) => r.body as Default)
    );
  }

}
