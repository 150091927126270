/* tslint:disable */
import {
  Document,
  Organization,
  Shopify,
  Magento1,
  Woocommerce,
  CustomIntegration,
  Bokun,
  Shopamine
} from '../index';

declare var Object: any;
export interface OrderInterface {
  "status": string;
  "date": Date;
  "orderId"?: string;
  "number"?: string;
  "currencyId"?: string;
  "paymentType": string;
  "paymentTag"?: string;
  "paid": boolean;
  "paidAt"?: Date;
  "fulfilled"?: boolean;
  "fulfilledAt"?: Date;
  "cancelled"?: boolean;
  "cancelledAt"?: Date;
  "complete"?: boolean;
  "completedAt"?: Date;
  "refunded"?: boolean;
  "refundedAt"?: Date;
  "locale"?: string;
  "expectedTotalWithTax"?: number;
  "payloads": any;
  "taxClause"?: string;
  "id"?: any;
  "_billingAddress"?: any;
  "_shippingAddress"?: any;
  "invoiceId"?: any;
  "estimateId"?: any;
  "organizationId"?: any;
  "shopifyId"?: any;
  "deletedAt"?: Date;
  "_isDeleted"?: boolean;
  "deletedId"?: string;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "magento1Id"?: any;
  "woocommerceId"?: any;
  "customIntegrationId"?: any;
  "customId"?: any;
  "_items"?: Array<any>;
  "_transactions"?: Array<any>;
  "bokunId"?: any;
  "shopamineId"?: any;
  billingAddress?: any[];
  shippingAddress?: any[];
  invoice?: Document;
  estimate?: Document;
  organization?: Organization;
  shopify?: Shopify;
  magento1?: Magento1;
  woocommerce?: Woocommerce;
  custom?: CustomIntegration;
  items?: any[];
  transactions?: any[];
  bokun?: Bokun;
  shopamine?: Shopamine;
}

export class Order implements OrderInterface {
  "status": string;
  "date": Date;
  "orderId": string;
  "number": string;
  "currencyId": string;
  "paymentType": string;
  "paymentTag": string;
  "paid": boolean;
  "paidAt": Date;
  "fulfilled": boolean;
  "fulfilledAt": Date;
  "cancelled": boolean;
  "cancelledAt": Date;
  "complete": boolean;
  "completedAt": Date;
  "refunded": boolean;
  "refundedAt": Date;
  "locale": string;
  "expectedTotalWithTax": number;
  "payloads": any;
  "taxClause": string;
  "id": any;
  "_billingAddress": any;
  "_shippingAddress": any;
  "invoiceId": any;
  "estimateId": any;
  "organizationId": any;
  "shopifyId": any;
  "deletedAt": Date;
  "_isDeleted": boolean;
  "deletedId": string;
  "createdAt": Date;
  "updatedAt": Date;
  "magento1Id": any;
  "woocommerceId": any;
  "customIntegrationId": any;
  "customId": any;
  "_items": Array<any>;
  "_transactions": Array<any>;
  "bokunId": any;
  "shopamineId": any;
  billingAddress: any[];
  shippingAddress: any[];
  invoice: Document;
  estimate: Document;
  organization: Organization;
  shopify: Shopify;
  magento1: Magento1;
  woocommerce: Woocommerce;
  custom: CustomIntegration;
  items: any[];
  transactions: any[];
  bokun: Bokun;
  shopamine: Shopamine;
  constructor(data?: OrderInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Order`.
   */
  public static getModelName() {
    return "Order";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Order for dynamic purposes.
  **/
  public static factory(data: OrderInterface): Order{
    return new Order(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Order',
      plural: 'Orders',
      path: 'Orders',
      idName: 'id',
      properties: {
        "status": {
          name: 'status',
          type: 'string'
        },
        "date": {
          name: 'date',
          type: 'Date'
        },
        "orderId": {
          name: 'orderId',
          type: 'string'
        },
        "number": {
          name: 'number',
          type: 'string'
        },
        "currencyId": {
          name: 'currencyId',
          type: 'string'
        },
        "paymentType": {
          name: 'paymentType',
          type: 'string'
        },
        "paymentTag": {
          name: 'paymentTag',
          type: 'string'
        },
        "paid": {
          name: 'paid',
          type: 'boolean',
          default: true
        },
        "paidAt": {
          name: 'paidAt',
          type: 'Date'
        },
        "fulfilled": {
          name: 'fulfilled',
          type: 'boolean'
        },
        "fulfilledAt": {
          name: 'fulfilledAt',
          type: 'Date'
        },
        "cancelled": {
          name: 'cancelled',
          type: 'boolean'
        },
        "cancelledAt": {
          name: 'cancelledAt',
          type: 'Date'
        },
        "complete": {
          name: 'complete',
          type: 'boolean'
        },
        "completedAt": {
          name: 'completedAt',
          type: 'Date'
        },
        "refunded": {
          name: 'refunded',
          type: 'boolean'
        },
        "refundedAt": {
          name: 'refundedAt',
          type: 'Date'
        },
        "locale": {
          name: 'locale',
          type: 'string'
        },
        "expectedTotalWithTax": {
          name: 'expectedTotalWithTax',
          type: 'number'
        },
        "payloads": {
          name: 'payloads',
          type: 'any'
        },
        "taxClause": {
          name: 'taxClause',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "_billingAddress": {
          name: '_billingAddress',
          type: 'any'
        },
        "_shippingAddress": {
          name: '_shippingAddress',
          type: 'any'
        },
        "invoiceId": {
          name: 'invoiceId',
          type: 'any'
        },
        "estimateId": {
          name: 'estimateId',
          type: 'any'
        },
        "organizationId": {
          name: 'organizationId',
          type: 'any'
        },
        "shopifyId": {
          name: 'shopifyId',
          type: 'any'
        },
        "deletedAt": {
          name: 'deletedAt',
          type: 'Date'
        },
        "_isDeleted": {
          name: '_isDeleted',
          type: 'boolean'
        },
        "deletedId": {
          name: 'deletedId',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        "magento1Id": {
          name: 'magento1Id',
          type: 'any'
        },
        "woocommerceId": {
          name: 'woocommerceId',
          type: 'any'
        },
        "customIntegrationId": {
          name: 'customIntegrationId',
          type: 'any'
        },
        "customId": {
          name: 'customId',
          type: 'any'
        },
        "_items": {
          name: '_items',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "_transactions": {
          name: '_transactions',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "bokunId": {
          name: 'bokunId',
          type: 'any'
        },
        "shopamineId": {
          name: 'shopamineId',
          type: 'any'
        },
      },
      relations: {
        billingAddress: {
          name: 'billingAddress',
          type: 'any[]',
          model: '',
          relationType: 'embedsOne',
                  keyFrom: '_billingAddress',
          keyTo: 'id'
        },
        shippingAddress: {
          name: 'shippingAddress',
          type: 'any[]',
          model: '',
          relationType: 'embedsOne',
                  keyFrom: '_shippingAddress',
          keyTo: 'id'
        },
        invoice: {
          name: 'invoice',
          type: 'Document',
          model: 'Document',
          relationType: 'belongsTo',
                  keyFrom: 'invoiceId',
          keyTo: 'id'
        },
        estimate: {
          name: 'estimate',
          type: 'Document',
          model: 'Document',
          relationType: 'belongsTo',
                  keyFrom: 'estimateId',
          keyTo: 'id'
        },
        organization: {
          name: 'organization',
          type: 'Organization',
          model: 'Organization',
          relationType: 'belongsTo',
                  keyFrom: 'organizationId',
          keyTo: 'id'
        },
        shopify: {
          name: 'shopify',
          type: 'Shopify',
          model: 'Shopify',
          relationType: 'belongsTo',
                  keyFrom: 'shopifyId',
          keyTo: 'id'
        },
        magento1: {
          name: 'magento1',
          type: 'Magento1',
          model: 'Magento1',
          relationType: 'belongsTo',
                  keyFrom: 'magento1Id',
          keyTo: 'id'
        },
        woocommerce: {
          name: 'woocommerce',
          type: 'Woocommerce',
          model: 'Woocommerce',
          relationType: 'belongsTo',
                  keyFrom: 'woocommerceId',
          keyTo: 'id'
        },
        custom: {
          name: 'custom',
          type: 'CustomIntegration',
          model: 'CustomIntegration',
          relationType: 'belongsTo',
                  keyFrom: 'customId',
          keyTo: 'id'
        },
        items: {
          name: 'items',
          type: 'any[]',
          model: '',
          relationType: 'embedsMany',
                  keyFrom: '_items',
          keyTo: 'id'
        },
        transactions: {
          name: 'transactions',
          type: 'any[]',
          model: '',
          relationType: 'embedsMany',
                  keyFrom: '_transactions',
          keyTo: 'id'
        },
        bokun: {
          name: 'bokun',
          type: 'Bokun',
          model: 'Bokun',
          relationType: 'belongsTo',
                  keyFrom: 'bokunId',
          keyTo: 'id'
        },
        shopamine: {
          name: 'shopamine',
          type: 'Shopamine',
          model: 'Shopamine',
          relationType: 'belongsTo',
                  keyFrom: 'shopamineId',
          keyTo: 'id'
        },
      }
    }
  }
}
