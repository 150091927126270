import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class HelpdeskService {
  private data: any = {
    integrations: {
      woocommerce: {
        add: {
          link: {
            en:
              'https://www.notion.so/studiofourzerofour/How-to-connect-WooCommerce-7526a17e612f47c0958b079a60f42644',
            sl:
              'https://www.notion.so/studiofourzerofour/Kako-pove-em-WooCommerce-7e62874edde24835ab412f1ca851e9ca',
          },
        },
      },
    },
  };

  constructor() {}

  get(path: string, fallback = 'en'): string {
    const result = _.get(this.data, path);

    return result ? result : _.get(this.data, path.replace(/\w{2}$/, fallback));
  }
}
