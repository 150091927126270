import { Injectable } from '@angular/core';
import { LoggerService } from '../sdk';
import * as moment from 'moment';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class MyDpDateService {
  constructor(private log: LoggerService) {}

  // @date {Moment}
  generate(date): NgbDateStruct {
    // this.log.log('MyDpDateService#generate', date);
    date = moment(date);

    const d = {
      year: date.year(),
      month: date.month() + 1,
      day: date.date(),
    };

    return d;
  }

  parse(date, type = 'format'): any {
    // this.log.log('MyDpDateService#parse', date, type);
    if (!date) {
      return date;
    }

    date = JSON.parse(JSON.stringify(date));

    if (typeof date !== 'string') {
      date.date = date.day;
      delete date.day;
      date.month = date.month - 1;
    }

    const d = moment.utc().set(date);

    if (type === 'format') {
      return d.format('Y-MM-DD');
    } else if (type === 'date') {
      return d.toDate();
    }

    return d;
  }
}
