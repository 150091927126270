/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Warehouse } from '../models/warehouse';

@Injectable({
  providedIn: 'root',
})
export class WarehouseService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation warehouseFindById
   */
  static readonly WarehouseFindByIdPath = '/v1/Warehouses/{id}';

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseFindById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  warehouseFindById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseFindById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  warehouseFindById$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehouseFindById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseFindById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  warehouseFindById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseFindById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  warehouseFindById$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehouseFindById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseFindById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  warehouseFindById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Find a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseFindById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  warehouseFindById$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehouseFindById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Path part for operation warehouseReplaceByIdPutWarehousesId
   */
  static readonly WarehouseReplaceByIdPutWarehousesIdPath = '/v1/Warehouses/{id}';

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseReplaceByIdPutWarehousesId$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  warehouseReplaceByIdPutWarehousesId$Json$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseReplaceByIdPutWarehousesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseReplaceByIdPutWarehousesId$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  warehouseReplaceByIdPutWarehousesId$Json$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehouseReplaceByIdPutWarehousesId$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseReplaceByIdPutWarehousesId$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  warehouseReplaceByIdPutWarehousesId$Json$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseReplaceByIdPutWarehousesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseReplaceByIdPutWarehousesId$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  warehouseReplaceByIdPutWarehousesId$Json$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehouseReplaceByIdPutWarehousesId$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseReplaceByIdPutWarehousesId$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  warehouseReplaceByIdPutWarehousesId$Json$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseReplaceByIdPutWarehousesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseReplaceByIdPutWarehousesId$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  warehouseReplaceByIdPutWarehousesId$Json$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehouseReplaceByIdPutWarehousesId$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseReplaceByIdPutWarehousesId$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  warehouseReplaceByIdPutWarehousesId$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseReplaceByIdPutWarehousesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseReplaceByIdPutWarehousesId$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  warehouseReplaceByIdPutWarehousesId$XWwwFormUrlencoded$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehouseReplaceByIdPutWarehousesId$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseReplaceByIdPutWarehousesId$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  warehouseReplaceByIdPutWarehousesId$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseReplaceByIdPutWarehousesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseReplaceByIdPutWarehousesId$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  warehouseReplaceByIdPutWarehousesId$XWwwFormUrlencoded$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehouseReplaceByIdPutWarehousesId$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseReplaceByIdPutWarehousesId$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  warehouseReplaceByIdPutWarehousesId$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseReplaceByIdPutWarehousesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseReplaceByIdPutWarehousesId$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  warehouseReplaceByIdPutWarehousesId$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehouseReplaceByIdPutWarehousesId$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseReplaceByIdPutWarehousesId$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  warehouseReplaceByIdPutWarehousesId$Xml$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseReplaceByIdPutWarehousesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseReplaceByIdPutWarehousesId$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  warehouseReplaceByIdPutWarehousesId$Xml$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehouseReplaceByIdPutWarehousesId$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseReplaceByIdPutWarehousesId$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  warehouseReplaceByIdPutWarehousesId$Xml$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseReplaceByIdPutWarehousesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseReplaceByIdPutWarehousesId$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  warehouseReplaceByIdPutWarehousesId$Xml$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehouseReplaceByIdPutWarehousesId$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseReplaceByIdPutWarehousesId$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  warehouseReplaceByIdPutWarehousesId$Xml$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseReplaceByIdPutWarehousesIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseReplaceByIdPutWarehousesId$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  warehouseReplaceByIdPutWarehousesId$Xml$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehouseReplaceByIdPutWarehousesId$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Path part for operation warehouseDeleteById
   */
  static readonly WarehouseDeleteByIdPath = '/v1/Warehouses/{id}';

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseDeleteById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  warehouseDeleteById$Json$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseDeleteById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  warehouseDeleteById$Json(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.warehouseDeleteById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseDeleteById$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  warehouseDeleteById$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseDeleteById$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  warehouseDeleteById$Xml(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.warehouseDeleteById$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseDeleteById$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  warehouseDeleteById$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Delete a model instance by {{id}} from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseDeleteById$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  warehouseDeleteById$Javascript(params: {

    /**
     * Model id
     */
    id: string;
  },
  context?: HttpContext

): Observable<{
}> {

    return this.warehouseDeleteById$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation warehousePrototypePatchAttributes
   */
  static readonly WarehousePrototypePatchAttributesPath = '/v1/Warehouses/{id}';

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehousePrototypePatchAttributes$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  warehousePrototypePatchAttributes$Json$Json$Response(params: {

    /**
     * Warehouse id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehousePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehousePrototypePatchAttributes$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  warehousePrototypePatchAttributes$Json$Json(params: {

    /**
     * Warehouse id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehousePrototypePatchAttributes$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehousePrototypePatchAttributes$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  warehousePrototypePatchAttributes$Json$Xml$Response(params: {

    /**
     * Warehouse id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehousePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehousePrototypePatchAttributes$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  warehousePrototypePatchAttributes$Json$Xml(params: {

    /**
     * Warehouse id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehousePrototypePatchAttributes$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehousePrototypePatchAttributes$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  warehousePrototypePatchAttributes$Json$Javascript$Response(params: {

    /**
     * Warehouse id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehousePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehousePrototypePatchAttributes$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  warehousePrototypePatchAttributes$Json$Javascript(params: {

    /**
     * Warehouse id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehousePrototypePatchAttributes$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehousePrototypePatchAttributes$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  warehousePrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Warehouse id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehousePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehousePrototypePatchAttributes$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  warehousePrototypePatchAttributes$XWwwFormUrlencoded$Json(params: {

    /**
     * Warehouse id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehousePrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehousePrototypePatchAttributes$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  warehousePrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Warehouse id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehousePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehousePrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  warehousePrototypePatchAttributes$XWwwFormUrlencoded$Xml(params: {

    /**
     * Warehouse id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehousePrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehousePrototypePatchAttributes$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  warehousePrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Warehouse id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehousePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehousePrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  warehousePrototypePatchAttributes$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Warehouse id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehousePrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehousePrototypePatchAttributes$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  warehousePrototypePatchAttributes$Xml$Json$Response(params: {

    /**
     * Warehouse id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehousePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehousePrototypePatchAttributes$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  warehousePrototypePatchAttributes$Xml$Json(params: {

    /**
     * Warehouse id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehousePrototypePatchAttributes$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehousePrototypePatchAttributes$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  warehousePrototypePatchAttributes$Xml$Xml$Response(params: {

    /**
     * Warehouse id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehousePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehousePrototypePatchAttributes$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  warehousePrototypePatchAttributes$Xml$Xml(params: {

    /**
     * Warehouse id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehousePrototypePatchAttributes$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehousePrototypePatchAttributes$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  warehousePrototypePatchAttributes$Xml$Javascript$Response(params: {

    /**
     * Warehouse id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehousePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehousePrototypePatchAttributes$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  warehousePrototypePatchAttributes$Xml$Javascript(params: {

    /**
     * Warehouse id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehousePrototypePatchAttributes$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Path part for operation warehouseReplaceByIdPostWarehousesIdReplace
   */
  static readonly WarehouseReplaceByIdPostWarehousesIdReplacePath = '/v1/Warehouses/{id}/replace';

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseReplaceByIdPostWarehousesIdReplace$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  warehouseReplaceByIdPostWarehousesIdReplace$Json$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseReplaceByIdPostWarehousesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseReplaceByIdPostWarehousesIdReplace$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  warehouseReplaceByIdPostWarehousesIdReplace$Json$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehouseReplaceByIdPostWarehousesIdReplace$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseReplaceByIdPostWarehousesIdReplace$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  warehouseReplaceByIdPostWarehousesIdReplace$Json$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseReplaceByIdPostWarehousesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseReplaceByIdPostWarehousesIdReplace$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  warehouseReplaceByIdPostWarehousesIdReplace$Json$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehouseReplaceByIdPostWarehousesIdReplace$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseReplaceByIdPostWarehousesIdReplace$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  warehouseReplaceByIdPostWarehousesIdReplace$Json$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseReplaceByIdPostWarehousesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseReplaceByIdPostWarehousesIdReplace$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  warehouseReplaceByIdPostWarehousesIdReplace$Json$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehouseReplaceByIdPostWarehousesIdReplace$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseReplaceByIdPostWarehousesIdReplace$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  warehouseReplaceByIdPostWarehousesIdReplace$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseReplaceByIdPostWarehousesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseReplaceByIdPostWarehousesIdReplace$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  warehouseReplaceByIdPostWarehousesIdReplace$XWwwFormUrlencoded$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehouseReplaceByIdPostWarehousesIdReplace$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseReplaceByIdPostWarehousesIdReplace$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  warehouseReplaceByIdPostWarehousesIdReplace$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseReplaceByIdPostWarehousesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseReplaceByIdPostWarehousesIdReplace$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  warehouseReplaceByIdPostWarehousesIdReplace$XWwwFormUrlencoded$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehouseReplaceByIdPostWarehousesIdReplace$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseReplaceByIdPostWarehousesIdReplace$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  warehouseReplaceByIdPostWarehousesIdReplace$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseReplaceByIdPostWarehousesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseReplaceByIdPostWarehousesIdReplace$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  warehouseReplaceByIdPostWarehousesIdReplace$XWwwFormUrlencoded$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehouseReplaceByIdPostWarehousesIdReplace$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseReplaceByIdPostWarehousesIdReplace$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  warehouseReplaceByIdPostWarehousesIdReplace$Xml$Json$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseReplaceByIdPostWarehousesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseReplaceByIdPostWarehousesIdReplace$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  warehouseReplaceByIdPostWarehousesIdReplace$Xml$Json(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehouseReplaceByIdPostWarehousesIdReplace$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseReplaceByIdPostWarehousesIdReplace$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  warehouseReplaceByIdPostWarehousesIdReplace$Xml$Xml$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseReplaceByIdPostWarehousesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseReplaceByIdPostWarehousesIdReplace$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  warehouseReplaceByIdPostWarehousesIdReplace$Xml$Xml(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehouseReplaceByIdPostWarehousesIdReplace$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `warehouseReplaceByIdPostWarehousesIdReplace$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  warehouseReplaceByIdPostWarehousesIdReplace$Xml$Javascript$Response(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Warehouse>> {

    const rb = new RequestBuilder(this.rootUrl, WarehouseService.WarehouseReplaceByIdPostWarehousesIdReplacePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Warehouse>;
      })
    );
  }

  /**
   * Replace attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `warehouseReplaceByIdPostWarehousesIdReplace$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  warehouseReplaceByIdPostWarehousesIdReplace$Xml$Javascript(params: {

    /**
     * Model id
     */
    id: string;

    /**
     * Model instance data
     */
    body?: Warehouse
  },
  context?: HttpContext

): Observable<Warehouse> {

    return this.warehouseReplaceByIdPostWarehousesIdReplace$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Warehouse>) => r.body as Warehouse)
    );
  }

}
