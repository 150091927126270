/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ElectronicDevice } from '../models/electronic-device';

@Injectable({
  providedIn: 'root',
})
export class ElectronicDeviceService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation electronicDevicePrototypePatchAttributes
   */
  static readonly ElectronicDevicePrototypePatchAttributesPath = '/v1/ElectronicDevices/{id}';

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `electronicDevicePrototypePatchAttributes$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  electronicDevicePrototypePatchAttributes$Json$Json$Response(params: {

    /**
     * ElectronicDevice id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: ElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, ElectronicDeviceService.ElectronicDevicePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `electronicDevicePrototypePatchAttributes$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  electronicDevicePrototypePatchAttributes$Json$Json(params: {

    /**
     * ElectronicDevice id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: ElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.electronicDevicePrototypePatchAttributes$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `electronicDevicePrototypePatchAttributes$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  electronicDevicePrototypePatchAttributes$Json$Xml$Response(params: {

    /**
     * ElectronicDevice id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: ElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, ElectronicDeviceService.ElectronicDevicePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `electronicDevicePrototypePatchAttributes$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  electronicDevicePrototypePatchAttributes$Json$Xml(params: {

    /**
     * ElectronicDevice id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: ElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.electronicDevicePrototypePatchAttributes$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `electronicDevicePrototypePatchAttributes$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  electronicDevicePrototypePatchAttributes$Json$Javascript$Response(params: {

    /**
     * ElectronicDevice id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: ElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, ElectronicDeviceService.ElectronicDevicePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `electronicDevicePrototypePatchAttributes$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  electronicDevicePrototypePatchAttributes$Json$Javascript(params: {

    /**
     * ElectronicDevice id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: ElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.electronicDevicePrototypePatchAttributes$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `electronicDevicePrototypePatchAttributes$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  electronicDevicePrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * ElectronicDevice id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: ElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, ElectronicDeviceService.ElectronicDevicePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `electronicDevicePrototypePatchAttributes$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  electronicDevicePrototypePatchAttributes$XWwwFormUrlencoded$Json(params: {

    /**
     * ElectronicDevice id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: ElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.electronicDevicePrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `electronicDevicePrototypePatchAttributes$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  electronicDevicePrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * ElectronicDevice id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: ElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, ElectronicDeviceService.ElectronicDevicePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `electronicDevicePrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  electronicDevicePrototypePatchAttributes$XWwwFormUrlencoded$Xml(params: {

    /**
     * ElectronicDevice id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: ElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.electronicDevicePrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `electronicDevicePrototypePatchAttributes$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  electronicDevicePrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * ElectronicDevice id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: ElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, ElectronicDeviceService.ElectronicDevicePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `electronicDevicePrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  electronicDevicePrototypePatchAttributes$XWwwFormUrlencoded$Javascript(params: {

    /**
     * ElectronicDevice id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: ElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.electronicDevicePrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `electronicDevicePrototypePatchAttributes$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  electronicDevicePrototypePatchAttributes$Xml$Json$Response(params: {

    /**
     * ElectronicDevice id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: ElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, ElectronicDeviceService.ElectronicDevicePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `electronicDevicePrototypePatchAttributes$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  electronicDevicePrototypePatchAttributes$Xml$Json(params: {

    /**
     * ElectronicDevice id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: ElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.electronicDevicePrototypePatchAttributes$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `electronicDevicePrototypePatchAttributes$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  electronicDevicePrototypePatchAttributes$Xml$Xml$Response(params: {

    /**
     * ElectronicDevice id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: ElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, ElectronicDeviceService.ElectronicDevicePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `electronicDevicePrototypePatchAttributes$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  electronicDevicePrototypePatchAttributes$Xml$Xml(params: {

    /**
     * ElectronicDevice id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: ElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.electronicDevicePrototypePatchAttributes$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `electronicDevicePrototypePatchAttributes$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  electronicDevicePrototypePatchAttributes$Xml$Javascript$Response(params: {

    /**
     * ElectronicDevice id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: ElectronicDevice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ElectronicDevice>> {

    const rb = new RequestBuilder(this.rootUrl, ElectronicDeviceService.ElectronicDevicePrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ElectronicDevice>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `electronicDevicePrototypePatchAttributes$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  electronicDevicePrototypePatchAttributes$Xml$Javascript(params: {

    /**
     * ElectronicDevice id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: ElectronicDevice
  },
  context?: HttpContext

): Observable<ElectronicDevice> {

    return this.electronicDevicePrototypePatchAttributes$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<ElectronicDevice>) => r.body as ElectronicDevice)
    );
  }

}
