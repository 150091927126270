/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { WhiteLabel } from '../models/white-label';
import { NewWhiteLabel } from '../models/new-white-label';

@Injectable({
  providedIn: 'root',
})
export class WhiteLabelService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation whiteLabelFind
   */
  static readonly WhiteLabelFindPath = '/v1/WhiteLabels';

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `whiteLabelFind$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  whiteLabelFind$Json$Response(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<WhiteLabel>>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteLabelService.WhiteLabelFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WhiteLabel>>;
      })
    );
  }

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `whiteLabelFind$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  whiteLabelFind$Json(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<WhiteLabel>> {

    return this.whiteLabelFind$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<WhiteLabel>>) => r.body as Array<WhiteLabel>)
    );
  }

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `whiteLabelFind$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  whiteLabelFind$Xml$Response(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<WhiteLabel>>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteLabelService.WhiteLabelFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WhiteLabel>>;
      })
    );
  }

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `whiteLabelFind$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  whiteLabelFind$Xml(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<WhiteLabel>> {

    return this.whiteLabelFind$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<WhiteLabel>>) => r.body as Array<WhiteLabel>)
    );
  }

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `whiteLabelFind$Javascript()` instead.
   *
   * This method doesn't expect any request body.
   */
  whiteLabelFind$Javascript$Response(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<WhiteLabel>>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteLabelService.WhiteLabelFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WhiteLabel>>;
      })
    );
  }

  /**
   * Find all instances of the model matched by filter from the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `whiteLabelFind$Javascript$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  whiteLabelFind$Javascript(params?: {

    /**
     * Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (`{"where":{"something":"value"}}`).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     */
    filter?: string;
  },
  context?: HttpContext

): Observable<Array<WhiteLabel>> {

    return this.whiteLabelFind$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<WhiteLabel>>) => r.body as Array<WhiteLabel>)
    );
  }

  /**
   * Path part for operation whiteLabelCreate
   */
  static readonly WhiteLabelCreatePath = '/v1/WhiteLabels';

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `whiteLabelCreate$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  whiteLabelCreate$Json$Json$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewWhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteLabelService.WhiteLabelCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `whiteLabelCreate$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  whiteLabelCreate$Json$Json(params?: {

    /**
     * Model instance data
     */
    body?: NewWhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.whiteLabelCreate$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `whiteLabelCreate$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  whiteLabelCreate$Json$Xml$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewWhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteLabelService.WhiteLabelCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `whiteLabelCreate$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  whiteLabelCreate$Json$Xml(params?: {

    /**
     * Model instance data
     */
    body?: NewWhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.whiteLabelCreate$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `whiteLabelCreate$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  whiteLabelCreate$Json$Javascript$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewWhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteLabelService.WhiteLabelCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `whiteLabelCreate$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  whiteLabelCreate$Json$Javascript(params?: {

    /**
     * Model instance data
     */
    body?: NewWhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.whiteLabelCreate$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `whiteLabelCreate$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  whiteLabelCreate$XWwwFormUrlencoded$Json$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewWhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteLabelService.WhiteLabelCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `whiteLabelCreate$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  whiteLabelCreate$XWwwFormUrlencoded$Json(params?: {

    /**
     * Model instance data
     */
    body?: NewWhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.whiteLabelCreate$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `whiteLabelCreate$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  whiteLabelCreate$XWwwFormUrlencoded$Xml$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewWhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteLabelService.WhiteLabelCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `whiteLabelCreate$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  whiteLabelCreate$XWwwFormUrlencoded$Xml(params?: {

    /**
     * Model instance data
     */
    body?: NewWhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.whiteLabelCreate$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `whiteLabelCreate$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  whiteLabelCreate$XWwwFormUrlencoded$Javascript$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewWhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteLabelService.WhiteLabelCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `whiteLabelCreate$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  whiteLabelCreate$XWwwFormUrlencoded$Javascript(params?: {

    /**
     * Model instance data
     */
    body?: NewWhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.whiteLabelCreate$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `whiteLabelCreate$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  whiteLabelCreate$Xml$Json$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewWhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteLabelService.WhiteLabelCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `whiteLabelCreate$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  whiteLabelCreate$Xml$Json(params?: {

    /**
     * Model instance data
     */
    body?: NewWhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.whiteLabelCreate$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `whiteLabelCreate$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  whiteLabelCreate$Xml$Xml$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewWhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteLabelService.WhiteLabelCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `whiteLabelCreate$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  whiteLabelCreate$Xml$Xml(params?: {

    /**
     * Model instance data
     */
    body?: NewWhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.whiteLabelCreate$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `whiteLabelCreate$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  whiteLabelCreate$Xml$Javascript$Response(params?: {

    /**
     * Model instance data
     */
    body?: NewWhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteLabelService.WhiteLabelCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Create a new instance of the model and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `whiteLabelCreate$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  whiteLabelCreate$Xml$Javascript(params?: {

    /**
     * Model instance data
     */
    body?: NewWhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.whiteLabelCreate$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Path part for operation whiteLabelPrototypePatchAttributes
   */
  static readonly WhiteLabelPrototypePatchAttributesPath = '/v1/WhiteLabels/{id}';

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `whiteLabelPrototypePatchAttributes$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  whiteLabelPrototypePatchAttributes$Json$Json$Response(params: {

    /**
     * WhiteLabel id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteLabelService.WhiteLabelPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `whiteLabelPrototypePatchAttributes$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  whiteLabelPrototypePatchAttributes$Json$Json(params: {

    /**
     * WhiteLabel id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.whiteLabelPrototypePatchAttributes$Json$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `whiteLabelPrototypePatchAttributes$Json$Xml()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  whiteLabelPrototypePatchAttributes$Json$Xml$Response(params: {

    /**
     * WhiteLabel id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteLabelService.WhiteLabelPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `whiteLabelPrototypePatchAttributes$Json$Xml$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  whiteLabelPrototypePatchAttributes$Json$Xml(params: {

    /**
     * WhiteLabel id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.whiteLabelPrototypePatchAttributes$Json$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `whiteLabelPrototypePatchAttributes$Json$Javascript()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  whiteLabelPrototypePatchAttributes$Json$Javascript$Response(params: {

    /**
     * WhiteLabel id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteLabelService.WhiteLabelPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `whiteLabelPrototypePatchAttributes$Json$Javascript$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  whiteLabelPrototypePatchAttributes$Json$Javascript(params: {

    /**
     * WhiteLabel id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.whiteLabelPrototypePatchAttributes$Json$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `whiteLabelPrototypePatchAttributes$XWwwFormUrlencoded$Json()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  whiteLabelPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params: {

    /**
     * WhiteLabel id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteLabelService.WhiteLabelPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `whiteLabelPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  whiteLabelPrototypePatchAttributes$XWwwFormUrlencoded$Json(params: {

    /**
     * WhiteLabel id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.whiteLabelPrototypePatchAttributes$XWwwFormUrlencoded$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `whiteLabelPrototypePatchAttributes$XWwwFormUrlencoded$Xml()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  whiteLabelPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params: {

    /**
     * WhiteLabel id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteLabelService.WhiteLabelPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `whiteLabelPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  whiteLabelPrototypePatchAttributes$XWwwFormUrlencoded$Xml(params: {

    /**
     * WhiteLabel id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.whiteLabelPrototypePatchAttributes$XWwwFormUrlencoded$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `whiteLabelPrototypePatchAttributes$XWwwFormUrlencoded$Javascript()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  whiteLabelPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params: {

    /**
     * WhiteLabel id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteLabelService.WhiteLabelPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `whiteLabelPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  whiteLabelPrototypePatchAttributes$XWwwFormUrlencoded$Javascript(params: {

    /**
     * WhiteLabel id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.whiteLabelPrototypePatchAttributes$XWwwFormUrlencoded$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `whiteLabelPrototypePatchAttributes$Xml$Json()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  whiteLabelPrototypePatchAttributes$Xml$Json$Response(params: {

    /**
     * WhiteLabel id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteLabelService.WhiteLabelPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `whiteLabelPrototypePatchAttributes$Xml$Json$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  whiteLabelPrototypePatchAttributes$Xml$Json(params: {

    /**
     * WhiteLabel id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.whiteLabelPrototypePatchAttributes$Xml$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `whiteLabelPrototypePatchAttributes$Xml$Xml()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  whiteLabelPrototypePatchAttributes$Xml$Xml$Response(params: {

    /**
     * WhiteLabel id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteLabelService.WhiteLabelPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `whiteLabelPrototypePatchAttributes$Xml$Xml$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  whiteLabelPrototypePatchAttributes$Xml$Xml(params: {

    /**
     * WhiteLabel id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.whiteLabelPrototypePatchAttributes$Xml$Xml$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `whiteLabelPrototypePatchAttributes$Xml$Javascript()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  whiteLabelPrototypePatchAttributes$Xml$Javascript$Response(params: {

    /**
     * WhiteLabel id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WhiteLabel>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteLabelService.WhiteLabelPrototypePatchAttributesPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'text/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/javascript',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteLabel>;
      })
    );
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `whiteLabelPrototypePatchAttributes$Xml$Javascript$Response()` instead.
   *
   * This method sends `text/xml` and handles request body of type `text/xml`.
   */
  whiteLabelPrototypePatchAttributes$Xml$Javascript(params: {

    /**
     * WhiteLabel id
     */
    id: string;

    /**
     * An object of model property name/value pairs
     */
    body?: WhiteLabel
  },
  context?: HttpContext

): Observable<WhiteLabel> {

    return this.whiteLabelPrototypePatchAttributes$Xml$Javascript$Response(params,context).pipe(
      map((r: StrictHttpResponse<WhiteLabel>) => r.body as WhiteLabel)
    );
  }

}
