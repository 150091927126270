/* tslint:disable */
import {
  Organization,
  Client,
  Item,
  Price
} from '../index';

declare var Object: any;
export interface PriceListInterface {
  "name": string;
  "active": boolean;
  "activeFrom"?: Date;
  "activeTo"?: Date;
  "type": string;
  "id"?: any;
  "organizationId"?: any;
  "clientId"?: any;
  "deletedAt"?: Date;
  "_isDeleted"?: boolean;
  "deletedId"?: string;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "custom"?: any;
  organization?: Organization;
  client?: Client;
  items?: Item[];
  prices?: Price[];
}

export class PriceList implements PriceListInterface {
  "name": string;
  "active": boolean;
  "activeFrom": Date;
  "activeTo": Date;
  "type": string;
  "id": any;
  "organizationId": any;
  "clientId": any;
  "deletedAt": Date;
  "_isDeleted": boolean;
  "deletedId": string;
  "createdAt": Date;
  "updatedAt": Date;
  "custom": any;
  organization: Organization;
  client: Client;
  items: Item[];
  prices: Price[];
  constructor(data?: PriceListInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PriceList`.
   */
  public static getModelName() {
    return "PriceList";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of PriceList for dynamic purposes.
  **/
  public static factory(data: PriceListInterface): PriceList{
    return new PriceList(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PriceList',
      plural: 'PriceLists',
      path: 'PriceLists',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "activeFrom": {
          name: 'activeFrom',
          type: 'Date'
        },
        "activeTo": {
          name: 'activeTo',
          type: 'Date'
        },
        "type": {
          name: 'type',
          type: 'string',
          default: 'sale'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "organizationId": {
          name: 'organizationId',
          type: 'any'
        },
        "clientId": {
          name: 'clientId',
          type: 'any'
        },
        "deletedAt": {
          name: 'deletedAt',
          type: 'Date'
        },
        "_isDeleted": {
          name: '_isDeleted',
          type: 'boolean'
        },
        "deletedId": {
          name: 'deletedId',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        "custom": {
          name: 'custom',
          type: 'any',
          default: <any>null
        },
      },
      relations: {
        organization: {
          name: 'organization',
          type: 'Organization',
          model: 'Organization',
          relationType: 'belongsTo',
                  keyFrom: 'organizationId',
          keyTo: 'id'
        },
        client: {
          name: 'client',
          type: 'Client',
          model: 'Client',
          relationType: 'belongsTo',
                  keyFrom: 'clientId',
          keyTo: 'id'
        },
        items: {
          name: 'items',
          type: 'Item[]',
          model: 'Item',
          relationType: 'hasMany',
          modelThrough: 'Price',
          keyThrough: 'itemId',
          keyFrom: 'id',
          keyTo: 'priceListId'
        },
        prices: {
          name: 'prices',
          type: 'Price[]',
          model: 'Price',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'priceListId'
        },
      }
    }
  }
}
