import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { LanguageService } from './services/language.service';
import { LoggerService } from './sdk';

@Injectable()
export class LanguageResolver implements Resolve<string> {
  constructor(
    private languageService: LanguageService,
    private log: LoggerService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): string {
    this.log.log('LanguageResolver: resolve');

    let l = route.queryParams.l;

    if (!l) {
      l = this.languageService.readFromStorage();
    }

    this.log.log('LanguageResolver: ', l);

    if (!l) {
      return;
    } else {
      this.languageService.setCurrent(l);
      return l;
    }
  }
}
